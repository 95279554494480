import {
  Breadcrumbs,
  Button,
  Grid,
  Hidden,
  IconButton,
  Link,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  ChevronDoubleLeft,
  ChevronDoubleRight,
} from "mdi-material-ui";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  ITimeEntry,
  ITimeEntrySearch,
  IProfessionalHourStats,
  IFilter,
  IKeyValue,
  IConfirmDialog,
  ITimeFilter
} from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import moment from "moment";
import {
  useUnload,
  removeNulls,
  convertToFixed,
  numberFormat,
  currencyFormat,
  getToken,
  getFileName,
  onlyUnique,
  getSubDomain,
  hoursFormat,
  getCeilValueForTotalCount,
} from "../../services/UtilService";
import ManageTimeEntryDialog from "./ManageTimeEntryDialog";
import RuleFailedDialog from "./RuleFailedDialog";
import LogDialog from "./LogDialog";
import {
  editTimeSheet,
  getTimeEntriesOfTimesheet,
  timesheetReviewed,
  approveTimesheets,
  getAllProfessionalLevels,
  getAllCategories,
  getFirmEmployeeListDetails,
  getTimesheetFiltersCount,
  getAllWorkTypes,
  bulkEditTimeEntries,
  disApproveEntriesAPI,
  getAllApprovedListForTimeEntry
} from "../../services/TimesheetService";
import Loading from "../common/Loading";
import {
  API_URL,
  DESCRIPTION_FILTER_ENTRY,
  styles,
  TIMESHEET_STATUS,
  TIME_ENTRY_KEY_WORDS,
  MAX_PAGINATION_OPTIONS,
} from "../../Constant";
import TimeSheetHeader from "./TimeSheetHeader";
import Filter from "../common/Filter";
import CustomDrawer from "../common/CustomDrawer";
import ExportDialog from "../common/ExportDialog";
import ManageNotesDialog from "../notes/ManageNotesDialog";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import ViewTimesheetTable from "./ViewTimesheetTable";
import ManageSplitTimeEntryDialog from "./ManageSplitTimeEntryDialog";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import ToggleFilterSection from "../dashboard/ToggleFilter";
import { getAllNotes } from "../../services/NoteService";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

var timesheetsSummarySearchObj: any = {};

export interface ViewTimesheetMainProps extends IConfirmDialog { }

const ViewTimesheetMain: React.FC<ViewTimesheetMainProps> = ({ confirmDialog }) => {
  const [isLoading, setLoading] = React.useState(false);
  const [isFilterOpen, setFilterOpen] = React.useState(true);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  // const { timesheetId } = useParams<{ timesheetId: any }>();
  const history = useHistory();
  const { showToast } = React.useContext(ToastContext);
  const [professionalLevelList, setProfessionalLevels] = React.useState([]);
  const [categoriesList, setCategories] = React.useState([]);
  const [
    initialProfessionalLevels,
    setInitialProfessionalLevels,
  ] = React.useState({} as IKeyValue<{ label: string; value: string }>);
  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialApprovedBy, setInitialApprovedBy] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [noteList, setNoteList] = React.useState(
    [] as Array<{ label: string; value: string }>
  );
  const [keyWordsList, setKeyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [firmEmployeeDetails, setFirmEmployeeDetails] = React.useState(
    [] as Array<IProfessionalHourStats>
  );
  const [actionAnchorEl, setActionAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});
  const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

  const [state, setState] = React.useState({
    timeEntries: [],
    selectedTimeEntries: [],
    unselectedTimeEntries: [],
    manageTimeEntryDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
      isBulkEdit: false
    },
    manageRuleFailedDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    viewLogsDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    previousItems: [],
    nextItems: [],
    currentItem: {},
    startDate: "",
    endDate: "",
    employeeName: "",
    firmName: "",
    hasAllSelectionChanged: false,
    isAllSelected: false,
    status: [],
    rowsList: [10],
    timesheetCount: 0,
    searchObj: {
      from: 0,
      size: 10,
      firmId: undefined,
      personId: undefined,
      status: [],
      keyword: [],
      categoryCode: [],
      workType: [],
      professionalLevel: [],
      startDate: moment("2017-01-01", "YYYY-MM-DD")
        .subtract(1, "year")
        .toDate(),
      endDate: moment().toDate(),
      min: "",
      max: "",
      minWordCount: "",
      maxWordCount: "",
      showDisapproved: false,
      firms: [],
      persons: [],
      statuses: [],
      selectedFirms: [],
      selectedPersons: [],
      descriptionSearchFilters: [],
      sortBy: "serviceDate",
      sortOrder: "asc",
      approvedBy: [],
      showOnlyDisapproved: false,
      noteId: "",
    }
  } as { selectedTimeEntries: number[], unselectedTimeEntries: number[], timesheetCount: number; rowsList: number[]; isAllSelected: boolean; hasAllSelectionChanged: boolean; timeEntries: ITimeEntry[]; manageTimeEntryDialog: { isOpen: boolean; data?: ITimeEntry; index?: number, isBulkEdit: boolean }; manageRuleFailedDialog: { isOpen: boolean; data?: any; index?: number }; viewLogsDialog: { isOpen: boolean; data?: any; index?: number }; previousItems: any[]; nextItems: any[]; currentItem: any; startDate: string; endDate: string; status: string[]; searchObj: ITimeEntrySearch; employeeName: string; firmName: string; });
  const [manageNoteDialog, setManageNoteDialog] = React.useState({
    isOpen: false,
    timeEntryIds: [],
    isAddToNote: false,
  } as {
    isOpen: boolean;
    timeEntryIds: string[];
    isAddToNote: boolean
  });

  const fromManagementDashboard = React.useRef(false);
  const [loadedFilter, setLoadedFilter] = React.useState(undefined as ITimeFilter);
  const [manageSplitDialog, setManageSplitDialog] = React.useState({
    isOpen: false,
    index: undefined,
    data: undefined,
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [lastPageSize, setLastPageSize] = React.useState(state.searchObj.size || 10);
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] = React.useState({
    minDate: undefined,
    maxDate: undefined
  } as {
    minDate?: Date;
    maxDate?: Date
  });

  React.useEffect(() => {
    const fetchTimesheet = async () => {
      setLoading(true);
      let professionalLevelsDic = await getAllProfessionalLevelsList();
      let categoriesCodeDic = await getAllCategoriesList();
      let workTypesDic = await getAllWorkTypesList();
      let approvedByDic = await getAllApprovedByList();
      let noteList = await getAllNoteList();
      let previous = [];
      let next = [];
      let a: any = localStorage.getItem("nextTimesheets");
      if (a) {
        let items = JSON.parse(a);
        // assigning it a variable so that while pushing from this component to timesheet summary component we can send the size as it is needed
        timesheetsSummarySearchObj = items[0];
        localStorage.removeItem("nextTimesheets");
        let nextItems = [...items];
        nextItems.splice(0, 1);
        // await getAllTimeEntries(items[0], nextItems, []);
        await getFirmEmployeeDetails(items[0]);
        let search: ITimeEntrySearch = {
          ...state.searchObj,
          firmId: items[0].firmId,
          personId: items[0].personId,
          status: items[0].status || state.searchObj.status,
          startDate: items[0].startDate || state.searchObj.startDate,
          endDate: items[0].endDate || state.searchObj.endDate,
          firms: items[0].firmId ? [items[0].firmId] : [],
          persons: items[0].personId ? [items[0].personId] : [],
          statuses: items[0].status || state.searchObj.statuses,
          professionalLevel:
            items[0].professionalLevel || state.searchObj.professionalLevel,
          categoryCode: items[0].categoryCode || state.searchObj.categoryCode,
          workType: items[0].workType || state.searchObj.workType,
          keyword: items[0].keyword || state.searchObj.keyword,
          min: items[0].min || state.searchObj.min,
          max: items[0].max || state.searchObj.max,
          minWordCount: items[0].minWordCount || state.searchObj.minWordCount,
          maxWordCount: items[0].maxWordCount || state.searchObj.maxWordCount,
          showDisapproved: items[0].showDisapproved || state.searchObj.showDisapproved,
          descriptionSearchFilters: items[0].descriptionSearchFilters || state.searchObj.descriptionSearchFilters,
          selectedFirms:
            items[0].selectedFirms || state.searchObj.selectedFirms,
          selectedPersons:
            items[0].selectedPersons || state.searchObj.selectedPersons,
          approvedBy: items[0].approvedBy || state.searchObj.approvedBy,
          showOnlyDisapproved: items[0].showOnlyDisapproved || state.searchObj.showOnlyDisapproved,
          noteId: items[0].noteId || state.searchObj.noteId
        };
        fromManagementDashboard.current = items[0].isFromManagementDashboard || state.searchObj.isFromManagementDashboard
        await getTimeEntryFilters(search, undefined, {
          professionalLevelsDic,
          categoriesCodeDic,
          workTypesDic,
          approvedByDic,
          noteList
        });
      } else {
        history.push("/timesheets-summary");
      }
    };
    fetchTimesheet();
  }, []);

  const getTimeEntryFilters = async (
    search?: any,
    changedProperty?: any,
    professionCategoriesData?: any,
    resetSelectAll?: boolean,
    shouldBeSameSize?: boolean
  ) => {
    let tempSearch = { ...state.searchObj };
    if (search) {
      tempSearch = { ...state.searchObj, ...search };
      if (changedProperty === "load-filters") {
        tempSearch = { ...search, from: 0, size: tempSearch.size };
      }
    }
    let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
    if (professionCategoriesData?.professionalLevelsDic) {
      initialProfessionalLevelTemp = {
        ...initialProfessionalLevels,
        ...professionCategoriesData.professionalLevelsDic,
      };
    }
    let initialCategoryCodeTemp = { ...initialCategoryCodes };
    if (professionCategoriesData?.categoriesCodeDic) {
      initialCategoryCodeTemp = {
        ...initialCategoryCodes,
        ...professionCategoriesData.categoriesCodeDic,
      };
    }
    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (professionCategoriesData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...professionCategoriesData.workTypesDic,
      };
    }
    let initialApprovedByTemp = { ...initialApprovedBy };
    if (professionCategoriesData?.approvedByDic) {
      initialApprovedByTemp = {
        ...initialApprovedBy,
        ...professionCategoriesData.approvedByDic,
      };
    }
    let initialNoteListTemp = [...noteList];
    if (professionCategoriesData?.noteList) {
      initialNoteListTemp = [
        ...initialNoteListTemp,
        ...professionCategoriesData.noteList,
      ];
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }

    let keyWords = [...keyWordsList];
    let searchForFilter = { ...tempSearch };
    setLoading(true);
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {
        firmCounts: {},
        statusCounts: {},
        professionalCounts: {},
        categoryCodeCounts: {},
        workTypeCounts: {},
        keywordCounts: {},
        approvedByCounts: {}
      } as any,
    };
    if (changedProperty !== "add-descriptions") {
      await getFirmEmployeeDetails(searchForFilter);
    }
    if (isCountsLoaded === false || changedProperty === "clear-filters") {
      result = await getTimesheetFiltersCount(searchForFilter);
    }
    setLoading(false);
    if (result?.isSuccess) {
      let filter: IFilter[] = [];
      let data = { ...result.data };
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty === "clear-filters") {
        let keyParams = {
          categoryCodeCounts: "categoryCode",
          workTypeCounts: "workType",
          firmCounts: "firmCode",
          keywordCounts: "keyword",
          statusCounts: "status",
          professionalCounts: "professionalLevel",
          approvedByCounts: "timeApprovedByCode"
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            categoryCodeCounts: {},
            workTypeCounts: {},
            firmCounts: {},
            keywordCounts: {},
            statusCounts: {},
            professionalCounts: {},
            approvedByCounts: {}
          }
        );
        setFilterCountsDic(filterCountDic);
      }
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });

      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      if (!tempSearch.max && data?.maxTimeSpentHours) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      let filterOrder = [
        "descriptionSearchFilters",
        "workTypeCounts",
        "time",
        "statusCounts",
        "showDisapproved",
        "showOnlyDisapproved",
        "firmCounts",
        "personCounts",
        "professionalCounts",
        "categoryCodeCounts",
        "approvedBy",
        "noteId",
        "keyword",
        "min-max",
        "word-counts",
      ];
      let customFilters = ["time", "keyword", "min-max", "descriptionSearchFilters", "word-counts", "showDisapproved", "showOnlyDisapproved"];

      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time").isHidden) ||
                  false,
                items: [
                  {
                    type: 'date-range',
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: 'selection'
                      }
                    ],
                    label: ""
                  }
                ]
                // items: [
                //   {
                //     value: tempSearch.startDate,
                //     type: "date",
                //     label: "Start Date",
                //     name: "startDate",
                //   },
                //   {
                //     value: tempSearch.endDate,
                //     type: "date",
                //     label: "End Date",
                //     name: "endDate",
                //   },
                // ],
              });
              break;
            case "keyword":
              filter.push({
                header: "Keywords",
                name: "keyword",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "keyword").isHidden) ||
                  false,
                items: keyWords.map((ele) => {
                  return {
                    isSelected: tempSearch.keyword?.includes(ele.value)||false,
                    label: ele.label,
                    type: "checkbox",
                    name: ele.value,
                    // count:
                    //   changedProperty !== undefined &&
                    //   changedProperty === ele.label &&
                    //   tempSearch.statuses.length > 0
                    //     ? filterData
                    //         .find((ed) => ed.name === 'professionalLevel')
                    //         ?.items.find((ed) => ed.name === ele.label)?.count || 0
                    //     : statusCounts.find((e) => e.status === eds)?.count || 0,
                  };
                }),
              });
              break;
            case "min-max":
              filter.push({
                header: "Total Time Spent for Day",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max").isHidden) ||
                  false,
                items: [
                  {
                    label: "Min",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  },
                  {
                    label: "Max",
                    type: "number",
                    name: "max",
                    value: tempSearch.max,
                  },
                ],
              });
              break;
            case "descriptionSearchFilters":
              filter.push({
                header: "Descriptions",
                name: "descriptionSearchFilters",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "descriptionSearchFilters").isHidden) ||
                  false,
                items: tempSearch.descriptionSearchFilters.length > 0 ? tempSearch.descriptionSearchFilters.map((d) => {
                  let itemObj = {
                    type: "text-checkbox",
                    value: d.search,
                    isSelected: d.isExcluded,
                    name: "description",
                    label: ""
                  };
                  return itemObj;
                }) : [{ ...DESCRIPTION_FILTER_ENTRY }],
              });
              break;
            case "word-counts":
              filter.push({
                header: "Word Count",
                name: "word-counts",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "word-counts")?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Min Words",
                    type: "number",
                    name: "minWordCount",
                    value: tempSearch.minWordCount,
                  },
                  {
                    label: "Max Words",
                    type: "number",
                    name: "maxWordCount",
                    value: tempSearch.maxWordCount,
                  },
                ],
              });
              break;
            case "showDisapproved":
              filter.push({
                className: "disapproved-section",
                header: "",
                name: "showDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showDisapproved")?.isHidden) ||
                  false,
                items: [{
                  isSelected: tempSearch.showDisapproved,
                  label: "Show Disapproved",
                  type: "switch",
                  name: "showDisapproved",
                  isDisabled: tempSearch.showOnlyDisapproved
                }],
              });
              break;
            case "showOnlyDisapproved":
              filter.push({
                className: "only-disapproved-section",
                header: "",
                name: "showOnlyDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showOnlyDisapproved")?.isHidden) ||
                  false,
                items: [{
                  isSelected: tempSearch.showOnlyDisapproved,
                  label: "Show Only Disapproved",
                  type: "switch",
                  name: "showOnlyDisapproved",
                }],
              });
              break;
            default:
              break;
          }
        }
        else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses?.includes(eds)||false,
                  label: TIMESHEET_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                  isDisabled: tempSearch.showOnlyDisapproved
                });
              });
              break;
            case "professionalCounts":
              a.header = "Professional Level";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let professionalCounts = data["professionalCounts"] || [];
              Object.keys(initialProfessionalLevelTemp).forEach((element) => {
                a.items.push({
                  label: initialProfessionalLevelTemp[element]
                    ? initialProfessionalLevelTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.professionalLevel.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === element)?.count || 0
                        : professionalCounts.find(
                          (e) => e.professionalLevel === element
                        )?.count || 0
                      : filterCountDic?.["professionalCounts"]?.[element]
                        ?.count || 0,
                  name: element,
                  isSelected: tempSearch.professionalLevel?.includes(element)||false,
                });
              });
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.workTypesDic[element]
                    ? professionCategoriesData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : workTypeCounts.find(
                          (e) => e.workType === element
                        )?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.workType?.includes(element)||false,
                });
              });
              break;
            case "categoryCodeCounts":
              a.header = "Time Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let categoryCodeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp).sort((a, b) => initialCategoryCodeTemp[a].label > initialCategoryCodeTemp[b].label ? 1 : -1).forEach((element) => {
                a.items.push({
                  label: initialCategoryCodeTemp[element]
                    ? initialCategoryCodeTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.categoryCode.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : categoryCodeCounts.find(
                          (e) => e.categoryCode === element
                        )?.count || 0
                      : filterCountDic?.["categoryCodeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.categoryCode?.includes(element)||false,
                });
              });
              break;
            case "approvedBy":
              a.header = "Approved By";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let approvedByCounts = data["approvedByCounts"] || [];
              Object.keys(initialApprovedByTemp).map((element) => {
                a.items.push({
                  label: initialApprovedByTemp[element]
                    ? initialApprovedByTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.approvedBy.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : approvedByCounts.find(
                          (e) => e.approvedBy === element
                        )?.count || 0
                      : filterCountDic?.["approvedByCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.approvedBy?.includes(element)||false,
                });
              });
              break;
            case "noteId":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let noteOptions = [];
              let selectedNoteItems = [];
              initialNoteListTemp.map((ele) => {
                if (tempSearch.noteId === ele.value) {
                  selectedNoteItems.push(ele);
                }
                noteOptions.push(ele);
              });
              a.items = [
                {
                  label: "Notes",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedNoteItems || [],
                  options:
                    changedProperty !== undefined &&
                      changedProperty === ele &&
                      tempSearch.noteId
                      ? filterData.find((ed) => ed.name === ele)?.items[0].options
                      : noteOptions,
                  isMulti: false
                },
              ];
              break;
            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });

      setCountsLoaded(true);
      setFilterData(filter);
      if (changedProperty !== "add-descriptions") {
        await searchAllTimeEntries(tempSearch, resetSelectAll, shouldBeSameSize, changedProperty, filter);
      }
    } else {
      showToast(
        result?.message || "Error while fetching timesheets counts",
        "error"
      );
    }
  };

  const getAllProfessionalLevelsList = async () => {
    const result = await getAllProfessionalLevels();
    let professionalLevelDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return {
            label: x.professionalLevelName,
            value: x.professionalLevelCode,
          };
        });
        professionalLevelDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setProfessionalLevels(data || []);
        setInitialProfessionalLevels({ ...professionalLevelDic });
      }
    } else {
      showToast(
        result.message || "Error while fetching professional levels",
        "error"
      );
    }
    return professionalLevelDic;
  };
  const getAllCategoriesList = async () => {
    const result = await getAllCategories();
    let categoryCodeDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeCategoryName, value: x.timeCategoryCode };
        });
        categoryCodeDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setCategories(data || []);
        setInitialCategoryCodes({ ...categoryCodeDic });
      }
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
    return categoryCodeDic;
  };

  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(workTypesDic);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };

  const getAllApprovedByList = async () => {
    const result = await getAllApprovedListForTimeEntry();
    let approvedByDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeApprovedByName, value: x.timeApprovedByCode };
        });
        approvedByDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialApprovedBy(approvedByDic);
      }
    } else {
      showToast(result.message || "Error while fetching approved by", "error");
    }
    return approvedByDic;
  };

  const getAllNoteList = async () => {
    const result = await getAllNotes({});
    let noteList = [];
    if (result?.isSuccess) {
      if (result?.data) {
        noteList = result.data.results.map((x) => {
          return { label: x.description, value: x.id };
        });
        setNoteList(noteList);
      }
    } else {
      showToast(result.message || "Error while fetching notes", "error");
    }
    return noteList;
  };

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = "";
  });

  const searchAllTimeEntries = async (searchObj?: ITimeEntrySearch, resetSelectAll?: boolean, shouldBeSameSize?: boolean, changedProperty?: string, filter?: IFilter[], isAllSelectedFromRowsPerPage?: boolean) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...searchObj };
    }
    if (shouldBeSameSize) {
      tempSearch.size = rowsPerPage;
    }
    tempSearch = removeNulls(tempSearch);
    setLoading(true);
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    // if size and total data count is same then changing the size to nearest value multiply by 10
    // if (!isAllSelectedFromRowsPerPage && searchObj.size === state.timesheetCount) {
    if (!isAllSelectedFromRowsPerPage && searchObj.size === getCeilValueForTotalCount(state.timesheetCount)) {
      // let updatedSize = Math.round(searchObj.size / 10) * 10;
      // if (updatedSize === 0) updatedSize = 10;
      // searchObj.size = updatedSize;
      // tempSearch.size = updatedSize;
      // last saved size from another variable
      searchObj.size = lastPageSize;
      tempSearch.size = lastPageSize;
    }
    // if (isAllSelectedFromRowsPerPage) {
    //   setLastPageSize(state.searchObj.size);
    // }
    const result = await getTimeEntriesOfTimesheet(tempSearch);
    if (result?.isSuccess) {
      let isAllSelected = state.isAllSelected;
      let selectedTimeEntries = [...state.selectedTimeEntries];
      let unselectedTimeEntries = [...state.unselectedTimeEntries];
      let hasAllSelectionChanged = state.hasAllSelectionChanged;
      if (hasAllSelectionChanged) {
        selectedTimeEntries = [...selectedTimeEntries, ...result.data.results.filter(x => state.unselectedTimeEntries.indexOf(x.timeCommonBenefitId) === -1).map(x => x.timeCommonBenefitId)];
      }
      if (resetSelectAll) {
        isAllSelected = false;
        hasAllSelectionChanged = false;
        selectedTimeEntries = []
        unselectedTimeEntries = [];
      }
      let menuItems = [];
      for (let index = 10; index <= result.data.count; index = index + 10) {
        menuItems.push(index);
      }
      if (menuItems[menuItems.length - 1] < result.data.count) {
        menuItems.push(getCeilValueForTotalCount(result.data.count));
        // menuItems.push(result.data.count);
      }
      if (menuItems.length > MAX_PAGINATION_OPTIONS) {
        menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
      }
      // else {
      //   let totalCount = menuItems.pop();
      //   if (totalCount) {
      //     menuItems.unshift({ label: "All", value: totalCount });
      //   }
      // }
      // checking is the size is more than the data count
      if (tempSearch.size > result.data.count) {
        setLastPageSize(tempSearch.size);
        tempSearch.size = getCeilValueForTotalCount(result.data.count) || 10;
        // tempSearch.size = result.data.count || 10;
      }
      if ((changedProperty === "noteId") && result.data.statuses && tempSearch.noteId) {
        tempSearch.statuses = result.data.statuses.filter(x => x !== "disapproved");
        let statusIndexInFilter = filter.findIndex(x => x.name === "statusCounts");
        if (statusIndexInFilter > -1) {
          filter[statusIndexInFilter].items.forEach(item => {
            item.isSelected = tempSearch.statuses ? tempSearch.statuses.includes(item.name) : false
          })
        }
        if (result.data.statuses.includes("disapproved") && !tempSearch.showDisapproved) {
          let showDisapprovedIndexInFilter = filter.findIndex(x => x.name === "showDisapproved");
          if (showDisapprovedIndexInFilter > -1) {
            filter[showDisapprovedIndexInFilter].items[0].isSelected = result.data.statuses ? result.data.statuses.includes("disapproved") : false
          }
          tempSearch.showDisapproved = true;
          confirmDialog.show("Disapproved", "Show Disapproved enabled", undefined, undefined, undefined, undefined, true);
        }
        setFilterData(filter);
      }
      setState({
        ...state,
        isAllSelected,
        hasAllSelectionChanged,
        selectedTimeEntries: selectedTimeEntries.filter(onlyUnique),
        unselectedTimeEntries,
        timesheetCount: result.data.count,
        rowsList: menuItems,
        timeEntries: result.data.results.map((ele) => {
          ele.isEdit = false;
          if (hasAllSelectionChanged && unselectedTimeEntries.indexOf(ele.timeCommonBenefitId) === -1) {
            ele.isSelected = true;
          }
          if (selectedTimeEntries.indexOf(ele.timeCommonBenefitId) > -1) {
            ele.isSelected = true;
          }
          return ele;
        }),
        employeeName: state.employeeName
          ? state.employeeName
          : `${result?.data.results[0]?.firstName || ""} ${result?.data.results[0]?.lastName || ""
          } ${result?.data.results[0]?.nameSuffix || ""}`,
        firmName: state.firmName
          ? state.firmName
          : `${result?.data.results[0]?.firmName || ""}`,
        searchObj: searchObj
          ? {
            ...state.searchObj,
            ...searchObj,
          }
          : state.searchObj,
        manageTimeEntryDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
          isBulkEdit: false
        },
        manageRuleFailedDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
        },
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching timesheet entries",
        "error"
      );
    }
    setLoading(false);
  };

  const getFirmEmployeeDetails = async (searchObj) => {
    setLoading(true);
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...state.searchObj, ...searchObj };
    }
    let obj = {
      ...tempSearch,
      firmId: searchObj.firmId,
      personId: searchObj.personId,
      status: searchObj.status || tempSearch.status,
    };
    const result = await getFirmEmployeeListDetails(obj);
    if (result?.isSuccess) {
      setFirmEmployeeDetails(result.data);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching firm resource details",
        "error"
      );
    }
  };

  const reviewTimesheet = async () => {
    handleCloseActionMenu();
    let timeEntries = [...state.timeEntries];
    let tempSearch = { ...state.searchObj };

    tempSearch = removeNulls(tempSearch);


    let selectedEntries = [...state.selectedTimeEntries];
    if (selectedEntries.length > 0) {
      let obj = { ...tempSearch, isSelectedAll: state.hasAllSelectionChanged, timesheetIds: state.hasAllSelectionChanged ? [...state.unselectedTimeEntries] : [...state.selectedTimeEntries] };
      setLoading(true);
      const result = await timesheetReviewed(obj);
      setLoading(false);
      if (result?.isSuccess) {
        if (result?.isReadOnly) {
          showToast(result.message, "error");
          updateStatus(result.isReadOnly);
        } else {
          showToast(`Timesheet reviewed Successfully`, "success");
          timeEntries.forEach((ele) => {
            if (ele.isSelected) {
              ele.status = "reviewed";
              ele.isSelected = false;
              ele.version = ele.version + 1;
            }
          });
          setState({ ...state, timeEntries: timeEntries });
          await getTimeEntryFilters(undefined, "clear-filters", {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes }
          }, true, true);
        }
      } else {
        showToast(
          result?.message || `Error occurred while reviewing timesheet`,
          "error"
        );
      }
    } else {
      showToast(`Please select a time entry to review`, "error");
    }
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number, isBulkEdit = false) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = state.timeEntries[editIndex];
    }
    handleCloseActionMenu();
    setState({
      ...state,
      manageTimeEntryDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
        isBulkEdit
      },
    });
  };

  const disApproveEntries = async () => {
    handleCloseActionMenu();
    confirmDialog.show("Are you sure", "Do you want to disapprove?", async () => {
      let tempSearch = { ...state.searchObj };
      tempSearch = removeNulls(tempSearch);
      let requestObj = {
        ...tempSearch,
        firmEmployeesEdits: [{
          lawFirmCode: state.timeEntries[0].firmCode,
          resourceName: state.timeEntries[0].personId,
        }],
        isSelectedAll: state.hasAllSelectionChanged,
        timesheetIds: state.hasAllSelectionChanged ? [...state.unselectedTimeEntries] : [...state.selectedTimeEntries],
        isDetailedPage: true
      }
      setLoading(true);
      let result = await disApproveEntriesAPI(requestObj);
      if (result?.isSuccess) {
        if (result?.isReadOnly) {
          showToast(result.message, "error");
          updateStatus(result.isReadOnly);
        } else {
          showToast("Timesheet disapproved successfully", "success");
          setCountsLoaded(false);
          await getTimeEntryFilters(undefined, "clear-filters", {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes }
          }, true, true);
        }
      } else {
        showToast("Error while disapproving timesheet", "error");
      }
      setLoading(false);
    });
  }
  const handleApproveTimesheet = async (index) => {
    handleCloseActionMenu();
    confirmDialog.show("Are you sure", "Do you want to approve?", async () => {
      let tempSearch = { ...state.searchObj };
      tempSearch = removeNulls(tempSearch);
      let requestObj = {
        ...tempSearch,
        firmEmployeesEdits: [{
          lawFirmCode: state.timeEntries[index].firmCode,
          resourceName: state.timeEntries[index].personName,
        }],
        isSelectedAll: false,
        timesheetIds: [state.timeEntries[index].timeCommonBenefitId],
        isDetailedPage: true
      }
      setLoading(true);
      let result = await approveTimesheets(requestObj);
      if (result?.isSuccess) {
        if (result?.isReadOnly) {
          showToast(result.message, "error");
          updateStatus(result.isReadOnly);
        } else {
          showToast("Timesheet approved successfully", "success");
          setCountsLoaded(false);
          await getTimeEntryFilters(undefined, "clear-filters", {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes }
          }, true, true);
        }
      } else {
        showToast("Error while approving timesheet", "error");
      }
      setLoading(false);
    });
  }
  const handleDisApproveTimesheet = async (index) => {
    handleCloseActionMenu();
    confirmDialog.show("Are you sure", "Do you want to disapprove?", async () => {
      let tempSearch = { ...state.searchObj };
      tempSearch = removeNulls(tempSearch);
      let requestObj = {
        ...tempSearch,
        firmEmployeesEdits: [{
          lawFirmCode: state.timeEntries[index].firmCode,
          resourceName: state.timeEntries[index].personName,
        }],
        isSelectedAll: false,
        timesheetIds: [state.timeEntries[index].timeCommonBenefitId],
        isDetailedPage: true
      }
      setLoading(true);
      let result = await disApproveEntriesAPI(requestObj);
      if (result?.isSuccess) {
        if (result?.isReadOnly) {
          showToast(result.message, "error");
          updateStatus(result.isReadOnly);
        } else {
          showToast("Timesheet disapproved successfully", "success");
          setCountsLoaded(false);
          await getTimeEntryFilters(undefined, "clear-filters", {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes }
          }, true, true);
        }
      } else {
        showToast("Error while disapproving timesheet", "error");
      }
      setLoading(false);
    });
  }

  const editTimeEntryApiCall = async (data) => {
    setLoading(true);
    let result = await editTimeSheet(data);
    if (result?.isSuccess) {
      if (result?.isReadOnly) {
        showToast(result.message, "error");
        updateStatus(result.isReadOnly);
      } else {
        // timeEntriesList[state.manageTimeEntryDialog.index] = result?.data;
        showToast("Entry updated successfully", "success");
        setCountsLoaded(false);
        await getTimeEntryFilters(undefined, "clear-filters", {
          professionalLevelsDic: { ...initialProfessionalLevels },
          categoriesCodeDic: { ...initialCategoryCodes },
          workTypesDic: { ...initialWorkTypes }
        }, true, true);
      }
    } else {
      showToast("Entry not updated as no value changed", "warn");
    }
  }


  const handleAddDialogClose = async (data?: ITimeEntry) => {
    // let timeEntriesList = [...state.timeEntries];
    setLoading(true);
    if (data) {
      if (state.manageTimeEntryDialog.isBulkEdit) {
        let tempSearch = { ...state.searchObj };
        tempSearch = removeNulls(tempSearch);
        data = removeNulls(data);
        let requestObj = {
          ...tempSearch,
          firmEmployeesEdits: [{
            lawFirmCode: state.timeEntries[0].firmCode,
            resourceName: state.timeEntries[0].personId,
          }],
          timeEntryEditDetails: data,
          isSelectedAll: state.hasAllSelectionChanged,
          timesheetIds: state.hasAllSelectionChanged ? [...state.unselectedTimeEntries] : [...state.selectedTimeEntries],
          isDetailedPage: true
        }
        let result = await bulkEditTimeEntries(requestObj);
        if (result?.isSuccess) {
          if (result?.isReadOnly) {
            showToast(result.message, "error");
            updateStatus(result.isReadOnly);
          } else {
            showToast("Updated successfully", "success");
            setCountsLoaded(false);
            await getTimeEntryFilters(undefined, "clear-filters", {
              professionalLevelsDic: { ...initialProfessionalLevels },
              categoriesCodeDic: { ...initialCategoryCodes },
              workTypesDic: { ...initialWorkTypes }
            }, true, true);
          }
        } else {
          showToast("Error while bulk edit", "error");
        }
      } else {
        if (state.manageTimeEntryDialog.index !== undefined) {
          if (data.isEdit) {
            let obj = {
              status: "adjusted",
              timesheetsInfo: data,
            };
            // showing confirmation dialog based on status
            if (['approved', 'disapproved', 'submitted', 'granted', 'not-granted'].includes(data.status)) {
              confirmDialog.show("Do you want change entry status to adjusted?", "", async () => {
                await editTimeEntryApiCall({ ...data, isStatusChange: true });
              }, async () => {
                await editTimeEntryApiCall({ ...data, isStatusChange: false });
              }, "Yes", "No");
            } else {
              await editTimeEntryApiCall(data);
            }
            // let result = await editTimeSheet(data);
            // if (result?.isSuccess) {
            //   if (result?.isReadOnly) {
            //     showToast(result.message, "error");
            //     updateStatus(result.isReadOnly);
            //   } else {
            //     // timeEntriesList[state.manageTimeEntryDialog.index] = result?.data;
            //     showToast("Entry updated successfully", "success");
            //     setCountsLoaded(false);
            //     await getTimeEntryFilters(undefined, "clear-filters", {
            //       professionalLevelsDic: { ...initialProfessionalLevels },
            //       categoriesCodeDic: { ...initialCategoryCodes },
            //       workTypesDic: { ...initialWorkTypes }
            //     }, true, true);
            //   }
            // } else {
            //   showToast("Entry not updated as no value changed", "warn");
            // }
          }
        } else {
          // timeEntriesList.unshift(data);
          showToast("Entry added successfully", "success");
        }
      }
    } else {
      setState({
        ...state,
        manageTimeEntryDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
          isBulkEdit: false
        },
      });
    }

    setLoading(false);
  };

  const handleViewLogsDialog = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (isOpen) {
      let timeEntry = { ...state.timeEntries[editIndex as number] };
      if (editIndex !== undefined) {
        // data = {
        //   StartDate: state.startDate,
        //   EndDate: state.endDate,
        //   FirmId: timeEntry.firmCode as number,
        //   PersonId: timeEntry.personId as number,
        //   Type: timeEntry.type as string,
        //   TimeCommonBenefitIds: [timeEntry.timeCommonBenefitId as number],
        // };
        data = timeEntry.timeCommonBenefitId;
      }
    }
    setState({
      ...state,
      viewLogsDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };
  const handleRuleFailedDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    let timeEntries = [...state.timeEntries];
    if (editIndex !== undefined) {
      data = timeEntries[editIndex];
    }
    setState({
      ...state,
      manageRuleFailedDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };

  const handleRuleFailedDialogClose = async (isDetailsUpdated: boolean) => {
    if (isDetailsUpdated) {
      await getTimeEntryFilters(undefined, "clear-filters", {
        professionalLevelsDic: { ...initialProfessionalLevels },
        categoriesCodeDic: { ...initialCategoryCodes },
        workTypesDic: { ...initialWorkTypes }
      }, false, true);
    } else {
      setState({
        ...state,
        manageRuleFailedDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
        }
      });
    }
  };

  const handleEditTimesheet = async (status: string) => {
    handleCloseActionMenu();
    let timeEntries = [...state.timeEntries];
    let tempSearch = { ...state.searchObj };
    tempSearch = removeNulls(tempSearch);

    if (state.selectedTimeEntries.length > 0) {
      let obj = { ...tempSearch, isSelectedAll: state.hasAllSelectionChanged, timesheetIds: state.hasAllSelectionChanged ? [...state.unselectedTimeEntries] : [...state.selectedTimeEntries] };
      setLoading(true);
      const result = await approveTimesheets(obj);
      setLoading(false);
      if (result?.isSuccess) {
        if (result?.isReadOnly) {
          showToast(result.message, "error");
          updateStatus(result.isReadOnly);
        } else {
          showToast(
            `Timesheet ${status === "approved" ? "approved" : "saved"
            } successfully`,
            "success"
          );
          timeEntries.map((ele) => {
            if (ele.isSelected) {
              ele.status = "approved";
              ele.isSelected = false;
              ele.version = ele.version + 1;
            }
          });
          setState({ ...state, timeEntries, isAllSelected: false, selectedTimeEntries: [], unselectedTimeEntries: [] });
          await getTimeEntryFilters(undefined, "clear-filters", {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes }
          }, true, true);
        }
      } else {
        showToast(
          result?.message ||
          `Error while ${status === "approved" ? "approving" : "saving"
          } timesheet`,
          "error"
        );
      }
    } else {
      showToast(`Please select a timesheet to approve`, "error");
    }
  };

  const selectAll = () => {
    let timesheetsList = [...state.timeEntries];
    timesheetsList.map((ele) => {
      if (state.isAllSelected === false) {
        ele.isSelected = true;
      } else {
        ele.isSelected = false;
      }
      return ele;
    });
    let selectedTimeEntries = [];
    if (!state.isAllSelected) {
      selectedTimeEntries = state.timeEntries.map(x => x.timeCommonBenefitId);
    }
    setState({
      ...state,
      hasAllSelectionChanged: !state.isAllSelected,
      selectedTimeEntries: selectedTimeEntries.filter(onlyUnique),
      unselectedTimeEntries: [],
      timeEntries: timesheetsList,
      isAllSelected: !state.isAllSelected,
    });
    const ids = timesheetsList.filter((d) => d.isSelected).map((d) => String(d.timeCommonBenefitId));
    console.log(ids);
    setManageNoteDialog({ ...manageNoteDialog, timeEntryIds: ids })
  };

  const handleSelectChange = (index: number) => {
    let timesheetsList = state.timeEntries;
    let timesheet = timesheetsList[index];
    timesheet.isSelected = !timesheet.isSelected;
    timesheetsList[index] = timesheet;
    let selectedTimeEntries = [...state.selectedTimeEntries];
    let unselectedTimeEntries = [...state.unselectedTimeEntries];
    let isSelectedIndex = selectedTimeEntries.indexOf(timesheetsList[index].timeCommonBenefitId);
    if (timesheet.isSelected) {
      selectedTimeEntries.push(timesheetsList[index].timeCommonBenefitId);
      let unSelectedIndex = unselectedTimeEntries.indexOf(timesheetsList[index].timeCommonBenefitId);
      if (unSelectedIndex > -1) {
        unselectedTimeEntries.splice(unSelectedIndex, 1);
      }
    } else {
      if (isSelectedIndex > -1) {
        selectedTimeEntries.splice(isSelectedIndex, 1);
      }
      let unSelectedIndex = unselectedTimeEntries.indexOf(timesheetsList[index].timeCommonBenefitId);
      if (unSelectedIndex === -1 && state.hasAllSelectionChanged) {
        unselectedTimeEntries.push(timesheetsList[index].timeCommonBenefitId);
      }
    }

    let isAllSelected = state.isAllSelected;
    if (isAllSelected && unselectedTimeEntries.length) {
      isAllSelected = false;
    }

    if (selectedTimeEntries.length === state.timesheetCount) {
      isAllSelected = true
    }

    setState({ ...state, selectedTimeEntries: selectedTimeEntries.filter(onlyUnique), unselectedTimeEntries, timeEntries: timesheetsList, isAllSelected });
    const ids = timesheetsList.filter((d) => d.isSelected).map((d) => String(d.timeCommonBenefitId));
    console.log(ids);
    setManageNoteDialog({ ...manageNoteDialog, timeEntryIds: ids });
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    if (changedProperty === "clear-filters") {
      setLoadedFilter(undefined);
    }
    if (changedProperty === "load-filters") {
      let details = JSON.parse(JSON.stringify(data));
      setLoadedFilter(details);
      await getTimeEntryFilters(details.filters, changedProperty, {
        professionalLevelsDic: { ...initialProfessionalLevels },
        categoriesCodeDic: { ...initialCategoryCodes },
        workTypesDic: { ...initialWorkTypes }
      }, true);
    } else {
      let search = { ...state.searchObj };
      let statusArray =
        data.find((ele) => ele.name === "statusCounts")?.items || [];
      let professionalLevelArray =
        data.find((ele) => ele.name === "professionalCounts")?.items || [];
      let categoryCodeArray =
        data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
      let workTypesArray =
        data.find((ele) => ele.name === "workTypeCounts")?.items || [];
      let keywordsArray = data.find((ele) => ele.name === "keyword")?.items || [];
      let approvedByArray = data.find((ele) => ele.name === "approvedBy")?.items || [];
      let noteArray =
        data.find((ele) => ele.name === "noteId")?.items || [];
      let timeArray = data.find((ele) => ele.name === "time")?.items || [];
      let descriptionArray = data.find((ele) => ele.name === "descriptionSearchFilters")?.items || [];
      search.startDate =
        timeArray[0].value[0].startDate || undefined;
      // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
      search.endDate =
        timeArray[0].value[0].endDate || undefined;
      // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;
      search.professionalLevel = professionalLevelArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.categoryCode = categoryCodeArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.workType = workTypesArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.keyword = keywordsArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.status = statusArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.statuses = statusArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.approvedBy = approvedByArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.noteId =
        noteArray.length > 0
          ? noteArray[0].selectedItems.length > 0
            ? noteArray[0].selectedItems[0].value
            : ""
          : "";
      let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
      search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
      search.max = minMaxArray.find((ele) => ele.name === "max")?.value || "";

      let minMaxWordCountArray = data.find((ele) => ele.name === "word-counts")?.items || [];
      search.minWordCount = minMaxWordCountArray.find((ele) => ele.name === "minWordCount")?.value || "";
      search.maxWordCount = minMaxWordCountArray.find((ele) => ele.name === "maxWordCount")?.value || "";

      search.descriptionSearchFilters = descriptionArray
        // .filter((ele) => ele.value)
        .map((ele) => {
          let d = {
            search: ele.value || "",
            isExcluded: ele.isSelected
          };
          return d;
        })
      let disapprovedStatus = data.find((ele) => ele.name === "showDisapproved")?.items || [];
      search.showDisapproved = disapprovedStatus.find(ele => ele.name === "showDisapproved").isSelected;
      let onlyDisapprovedStatus = data.find((ele) => ele.name === "showOnlyDisapproved")?.items || [];
      search.showOnlyDisapproved = onlyDisapprovedStatus.find(ele => ele.name === "showOnlyDisapproved").isSelected;
      // if show 'only dis approved' is true we need to make status array empty and 'dis approved' false
      if (search.showOnlyDisapproved) {
        search.showDisapproved = false;
        search.statuses = [];
        search.status = [];
      }

      if (search.min && search.max && search.min > search.max) {
        showToast("Max value cannot be less than min value", "error");
        return;
      }

      if (search.minWordCount && search.maxWordCount && search.minWordCount > search.maxWordCount) {
        showToast("Max word count value cannot be less than min word count value", "error");
        return;
      }

      await getTimeEntryFilters(search, changedProperty, {
        professionalLevelsDic: { ...initialProfessionalLevels },
        categoriesCodeDic: { ...initialCategoryCodes },
        workTypesDic: { ...initialWorkTypes }
      }, true);
    }
  };

  const handleDownload = async (includeChangeLog?: boolean) => {
    let searchObj = { ...state.searchObj };
    let obj = {
      timesheetFilters: {
        ...searchObj,
        firmId: searchObj.firmId,
        personId: searchObj.personId,
        status: [...searchObj.statuses],
        keyword: [...searchObj.keyword],
        categoryCode: [...searchObj.categoryCode],
        workType: searchObj.workType,
        professionalLevel: [...searchObj.professionalLevel],
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        minWordCount: searchObj?.minWordCount || undefined,
        maxWordCount: searchObj?.maxWordCount || undefined,
        descriptionSearchFilters: searchObj?.descriptionSearchFilters || [],
        showDisapproved: searchObj?.showDisapproved || false,
        showOnlyDisapproved: searchObj?.showOnlyDisapproved || false,
        noteId: searchObj.noteId
      },
      employeeDetails: {
        name: `${state.timeEntries[0]?.firstName || ""} ${state.timeEntries[0]?.lastName || ""
          }`,
        firmName: `${state.timeEntries[0]?.firmName || "-"}`,
        totalFees:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.totalFees) ||
          0,
        avgHourlyRate:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.avgHourlyRate) ||
          0,
        highHourlyRate:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.highHourlyRate) ||
          0,
        lowHourlyRate:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.lowHourlyRate) ||
          0,
        totalHours:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.totalHours) ||
          0,
        avgHours:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.avgHours) ||
          0,
        highHours:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.highHours) ||
          0,
        lowHours:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.lowHours) ||
          0,
        timeEntries:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.timeEntries) ||
          0,
        categoryList: searchObj.categoryCode.map(
          (ele) => initialCategoryCodes[ele].label
        ),
      },
      workType: searchObj.workType,
      includeChangeLog: includeChangeLog ? true : false,
      localTime: moment().format("YYYYMMDDHHmmss")
    };
    obj = removeNulls(obj);
    let result = await fetch(`${API_URL}timesheets/generate-pdf`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], { type: "application/pdf" });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "pdf", "case", state.firmName);
    a.click();
  };
  const handleDownloadAsCsv = async (includeChangeLog?: boolean) => {
    let searchObj = { ...state.searchObj };
    let obj = {
      timesheetFilters: {
        ...searchObj,
        firmId: searchObj.firmId,
        personId: searchObj.personId,
        status: [...searchObj.statuses],
        keyword: [...searchObj.keyword],
        categoryCode: [...searchObj.categoryCode],
        workType: searchObj.workType,
        professionalLevel: [...searchObj.professionalLevel],
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        minWordCount: searchObj?.minWordCount || undefined,
        maxWordCount: searchObj?.maxWordCount || undefined,
        descriptionSearchFilters: searchObj?.descriptionSearchFilters || undefined,
        showDisapproved: searchObj?.showDisapproved || false,
        showOnlyDisapproved: searchObj?.showOnlyDisapproved || false,
        noteId: searchObj.noteId
      },
      employeeDetails: {
        name: `${state.timeEntries[0]?.firstName || ""} ${state.timeEntries[0]?.lastName || ""
          }`,
        firmName: `${state.timeEntries[0]?.firmName || "-"}`,
        totalFees:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.totalFees) ||
          0,
        avgHourlyRate:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.avgHourlyRate) ||
          0,
        highHourlyRate:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.highHourlyRate) ||
          0,
        lowHourlyRate:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.lowHourlyRate) ||
          0,
        totalHours:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.totalHours) ||
          0,
        avgHours:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.avgHours) ||
          0,
        highHours:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.highHours) ||
          0,
        lowHours:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.lowHours) ||
          0,
        timeEntries:
          (firmEmployeeDetails?.length > 0 &&
            firmEmployeeDetails[0]?.timeEntries) ||
          0,
        categoryList: searchObj.categoryCode.map(
          (ele) => initialCategoryCodes[ele].label
        ),
      },
      workType: searchObj.workType,
      includeChangeLog: includeChangeLog ? true : false,
      localTime: moment().format("YYYYMMDDHHmmss")
    };
    obj = removeNulls(obj);
    let result = await fetch(`${API_URL}timesheets/generate-csv`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "csv", "case", state.firmName);
    // pdf.type === "application/zip"
    //   ? `Detailed Time Entries.zip`
    //   : `Detailed Time Entries.csv`;
    a.click();
  };

  const handleOpenExportDialog = () => {
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.includeChangeLog);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.includeChangeLog);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpen(false);
  };

  const navigateToTimeSheets = () => {
    history.push({
      pathname: `/timesheets-summary`,
      state: {
        statuses: state.searchObj.statuses,
        startDate: state.searchObj.startDate,
        endDate: state.searchObj.endDate,
        persons: state.searchObj.selectedPersons,
        selectedFirm: state.searchObj.selectedFirms,
        professionalLevel: state.searchObj.professionalLevel,
        categoryCode: state.searchObj.categoryCode,
        workType: state.searchObj.workType,
        keyword: state.searchObj.keyword,
        min: state.searchObj.min,
        max: state.searchObj.max,
        minWordCount: state.searchObj.minWordCount,
        maxWordCount: state.searchObj.maxWordCount,
        descriptionSearchFilters: state.searchObj.descriptionSearchFilters,
        showDisapproved: state.searchObj.showDisapproved,
        size: timesheetsSummarySearchObj?.size || 10,
        approvedBy: state.searchObj.approvedBy,
        noteId: state.searchObj.noteId
      },
    });
  };
  const handleOpenActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setActionAnchorEl(null);
  };

  const handleSplitDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = state.timeEntries[editIndex];
    }
    setManageSplitDialog({
      isOpen: isOpen,
      data: data,
      index: editIndex,
    });
  };

  const handleSplitDialogClose = async (data: boolean) => {
    setLoading(true);
    if (data) {
      setManageSplitDialog({
        isOpen: false,
        data: undefined,
        index: undefined,
      });
      if (data) {
        setCountsLoaded(false);
        await getTimeEntryFilters(undefined, "clear-filters", {
          professionalLevelsDic: { ...initialProfessionalLevels },
          categoriesCodeDic: { ...initialCategoryCodes },
          workTypesDic: { ...initialWorkTypes }
        }, false, true);
      } else {
        // timeEntriesList.unshift(data);
        showToast("Entry added successfully", "success");
      }
    }
    setManageSplitDialog({
      isOpen: false,
      data: undefined,
      index: undefined,
    });
    setLoading(false);
  };

  const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className="d-flex">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <ChevronDoubleLeft />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <ChevronRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <ChevronDoubleRight />
        </IconButton>
      </div>
    );
  };

  const onSortChange = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.sortBy) {
      search.sortOrder =
        search.sortOrder === "asc" ? "desc" : "asc";
    } else {
      search.sortBy = sortBy;
      search.sortOrder = "asc";
    }
    search.from = 0;
    await searchAllTimeEntries(search);
  };

  const onSearch = async (searchObj?: ITimeEntrySearch, isAllSelectedFromRowsPerPage?: boolean) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...state.searchObj, ...searchObj };
    }
    await searchAllTimeEntries(tempSearch, undefined, undefined, undefined, undefined, isAllSelectedFromRowsPerPage);
  };
  const classes = styles();
  return (
    <React.Fragment>
      {isLoading && <Loading message={loadingMessage} />}
      <section className="p-16">
        <div className="text-center">
          <Typography variant="h5">Detailed Time Entries</Typography>
        </div>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<ChevronRight fontSize="small" />}
              aria-label="breadcrumb"
            >
              {fromManagementDashboard.current ? (
                <Link
                  color="primary"
                  className="pointer"
                  onClick={() => {
                    history.push("/management-dashboard");
                  }}
                >
                  Management Dashboard
                </Link>
              ) : (
                <Link
                  color="primary"
                  className="pointer"
                  onClick={() => {
                    navigateToTimeSheets();
                    // history.push("/timesheets");
                  }}
                >
                  Timesheets
                </Link>
              )}
              <Typography color="textPrimary">Detailed Time Entries</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <section className="px-16">
              <Grid container spacing={2}>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                  <TimeSheetHeader
                    label="Resource"
                    value={`${state.employeeName || "-"}`}
                  ></TimeSheetHeader>
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                  <TimeSheetHeader
                    label="Firm"
                    value={`${state.firmName || "-"}`}
                  ></TimeSheetHeader>
                </Grid>
                {firmEmployeeDetails?.length > 0 && (
                  <React.Fragment>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Total Fees"
                        value={`${currencyFormat(
                          convertToFixed(
                            firmEmployeeDetails[0]?.totalFees || "-"
                          )
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Avg Hourly Rate"
                        value={`${currencyFormat(
                          convertToFixed(
                            firmEmployeeDetails[0]?.avgHourlyRate || "-"
                          )
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="High Hourly Rate"
                        value={`${currencyFormat(
                          convertToFixed(
                            firmEmployeeDetails[0]?.highHourlyRate || "-"
                          )
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Low Hourly Rate"
                        value={`${currencyFormat(
                          convertToFixed(
                            firmEmployeeDetails[0]?.lowHourlyRate || "-"
                          )
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Total Hours"
                        value={`${hoursFormat(
                          firmEmployeeDetails[0]?.totalHours || "-"
                        )}`}
                      // value={`${numberFormat(
                      //   convertToFixed(
                      //     firmEmployeeDetails[0]?.totalHours || "-"
                      //   )
                      // )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Avg Hours"
                        value={`${hoursFormat(
                          firmEmployeeDetails[0]?.avgHours || "-"
                        )}`}
                      // value={`${numberFormat(
                      //   convertToFixed(
                      //     firmEmployeeDetails[0]?.avgHours || "-"
                      //   )
                      // )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="High Hours"
                        value={`${hoursFormat(
                          firmEmployeeDetails[0]?.highHours || "-"
                        )}`}
                      // value={`${numberFormat(
                      //   convertToFixed(
                      //     firmEmployeeDetails[0]?.highHours || "-"
                      //   )
                      // )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Low Hours"
                        value={`${hoursFormat(
                          firmEmployeeDetails[0]?.lowHours || "-"
                        )}`}
                      // value={`${numberFormat(
                      //   convertToFixed(
                      //     firmEmployeeDetails[0]?.lowHours || "-"
                      //   )
                      // )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Time Entries"
                        value={`${numberFormat(
                          convertToFixed(
                            firmEmployeeDetails[0]?.timeEntries || "-"
                          )
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </section>
          </Grid>
          <Grid item lg={12}>
            <Grid className={`${classes.main8}`}>
              <Hidden mdDown>
                <Grid className={`time-entries-filters-height view-timesheet-filters ${isFilterOpen ? classes.filterOpenContainer : classes.filterCloseContainer}`}>
                  {filterData.length > 0 && (
                    <Filter
                      data={[...filterData]}
                      options={[]}
                      onChange={(data: IFilter[], changedProperty?: string) => {
                        onFilterChange(data, changedProperty);
                      }}
                      isFromManagementDashboard={state.searchObj.isFromManagementDashboard}
                      typeOfFilters="timesheets"
                      selectedFilters={state.searchObj}
                      loadedFilter={loadedFilter}
                      dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                    />
                  )}
                </Grid>
              </Hidden>
              <ToggleFilterSection showFilterClass='toggle-filters-timesheet-entries-container' classes={classes} toggleFilter={() => setFilterOpen(!isFilterOpen)} isFilterOpen={isFilterOpen} />
              <Grid className={`${isFilterOpen ? classes.mainOpenContainer : classes.mainCloseContainer}`}>
                <Grid container spacing={2} justify="flex-end">
                  <Hidden lgUp>
                    <Grid item>
                      {filterData.length > 0 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setFilterDialogOpen(true)}
                        >
                          Filters
                        </Button>
                      )}
                    </Grid>
                  </Hidden>
                  {state.timeEntries && state.timeEntries.length > 0 && (
                    <Grid item>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleOpenExportDialog();
                        }}
                      >
                        Export
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      color="primary"
                      onClick={handleOpenActionMenu}
                      disabled={
                        state.selectedTimeEntries.length === 0
                      }
                    >
                      Action  <ChevronDown />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={actionAnchorEl}
                      keepMounted
                      open={Boolean(actionAnchorEl)}
                      onClose={handleCloseActionMenu}
                    >
                      <MenuItem disabled={isReadOnly} onClick={() => {
                        setManageNoteDialog({ ...manageNoteDialog, isOpen: true, isAddToNote: false });
                        handleCloseActionMenu();
                      }}>
                        Create Note
                      </MenuItem>
                      <MenuItem disabled={isReadOnly} onClick={() => {
                        setManageNoteDialog({ ...manageNoteDialog, isOpen: true, isAddToNote: true });
                        handleCloseActionMenu();
                      }}>
                        Add to Note
                      </MenuItem>
                      <MenuItem disabled={isReadOnly} onClick={() => handleAddDialogOpen(true, undefined, true)}>
                        Bulk Edit
                      </MenuItem>
                      <MenuItem disabled={isReadOnly} onClick={() => reviewTimesheet()}>
                        Review
                      </MenuItem>
                      <MenuItem disabled={isReadOnly} onClick={() => handleEditTimesheet("approved")}>
                        Approve
                      </MenuItem>
                      <MenuItem disabled={isReadOnly} onClick={() => disApproveEntries()}>
                        Disapprove
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
                <br />
                <ViewTimesheetTable
                  from="timesheets"
                  timeEntries={state.timeEntries}
                  handleAddDialogOpen={handleAddDialogOpen}
                  handleRuleFailedDialogOpen={handleRuleFailedDialogOpen}
                  handleSelectChange={handleSelectChange}
                  handleViewLogsDialog={handleViewLogsDialog}
                  isAllSelected={state.isAllSelected}
                  selectedTimeEntries={state.selectedTimeEntries}
                  unselectedTimeEntries={state.unselectedTimeEntries}
                  selectAll={selectAll}
                  handleSplitDialogOpen={handleSplitDialogOpen}
                  canSortHeader={true}
                  sortBy={state.searchObj.sortBy}
                  sortOrder={state.searchObj.sortOrder}
                  onSort={(sortBy) => onSortChange(sortBy)}
                  handleApproveTimesheet={handleApproveTimesheet}
                  handleDisApproveTimesheet={handleDisApproveTimesheet}
                />

                <TablePagination
                  rowsPerPageOptions={state.rowsList}
                  component="div"
                  count={state.timesheetCount}
                  rowsPerPage={state.searchObj.size}
                  page={
                    state.searchObj.from === 0
                      ? 0
                      : state.searchObj.from / state.searchObj.size
                  }
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={(event: any, newPage: number) => {
                    let searchObj = { ...state.searchObj };
                    searchObj.from =
                      newPage === 0
                        ? 0
                        : (Number(newPage) *
                          state.searchObj.size)
                    onSearch({ ...searchObj });
                  }}
                  onRowsPerPageChange={(event: any) => {
                    let value = event.target.value;
                    let search = { ...state.searchObj };
                    search.size = value ? Number(value) : 10;
                    // set to a different state
                    setRowsPerPage(search.size);
                    search.from = 0;
                    onSearch(search, search.size === getCeilValueForTotalCount(state.timesheetCount));
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      {state.manageTimeEntryDialog.isOpen && (
        <ManageTimeEntryDialog
          isBulkEdit={state.manageTimeEntryDialog.isBulkEdit}
          data={state.manageTimeEntryDialog.data}
          handleDialogClose={(data?: ITimeEntry) => handleAddDialogClose(data)}
        />
      )}
      {state.manageRuleFailedDialog.isOpen && (
        <RuleFailedDialog
          timeEntryData={state.manageRuleFailedDialog.data}
          handleDialogClose={(isDetailsUpdated: boolean) =>
            handleRuleFailedDialogClose(isDetailsUpdated)
          }
          updateStatus={updateStatus}
        />
      )}
      {state.viewLogsDialog.isOpen && (
        <LogDialog
          timesheetId={state.viewLogsDialog.data}
          handleDialogClose={() => handleViewLogsDialog(false)}
        />
      )}
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              isFromManagementDashboard={state.searchObj.isFromManagementDashboard}
              typeOfFilters="timesheets"
              selectedFilters={state.searchObj}
              loadedFilter={loadedFilter}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
      {isExportDialogOpen && (
        <ExportDialog
          exportFor={'detailedTimesheetOrExpense'}
          handleDialogClose={(data?) => handleCloseExportDialog(data)}
        />
      )}
      {manageNoteDialog.isOpen && (
        <ManageNotesDialog
          type="timesheets"
          isAddToNote={manageNoteDialog.isAddToNote}
          filters={removeNulls({ ...state.searchObj })}
          isSelectedAll={state.hasAllSelectionChanged}
          entries={state.hasAllSelectionChanged ? [...state.unselectedTimeEntries.map(x => x.toString())] : [...state.selectedTimeEntries.map(x => x.toString())]}
          handleDialogClose={(isAdded) => {
            setManageNoteDialog({ ...manageNoteDialog, isOpen: false, isAddToNote: false });
            if (isAdded) {
              setState({
                ...state,
                timeEntries: state.timeEntries.map(timeEntry => {
                  timeEntry.isSelected = false;
                  return timeEntry
                }),
                isAllSelected: false,
                hasAllSelectionChanged: false,
                selectedTimeEntries: [],
                unselectedTimeEntries: []
              })
            }
          }}
          updateStatus={updateStatus}
        />
      )}
      {manageSplitDialog.isOpen && (
        <ManageSplitTimeEntryDialog
          data={manageSplitDialog.data}
          handleDialogClose={(data) => handleSplitDialogClose(data)}
          updateStatus={updateStatus}
        />
      )}
    </React.Fragment>
  );
};

export default withConfirmDialogContext(ViewTimesheetMain);
