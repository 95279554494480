import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import ViewToSubmitToCourtDialog from "./ViewToSubmitToCourtDialog";
import { IFilter, ISubmissions } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import ViewOnSubmissionDialog from "./ViewOnSubmissionDialog";
import Filter from "../common/Filter";

export interface CourtSubmissionsMainProps { }

const CourtSubmissionsMain: React.FC<CourtSubmissionsMainProps> = () => {
  const history = useHistory();
  const { showToast } = React.useContext(ToastContext);

  const [state, setState] = React.useState({
    filterData: [
      {
        header: "Time Period",
        items: [{ value: new Date(), type: "date", label: "Month" }],
      },
      {
        header: "Firm",
        items: [
          { label: "Boucher", type: "checkbox", isSelected: false },
          {
            label: "California Law",
            type: "checkbox",
            isSelected: false,
          },
          { label: "Parris", type: "checkbox", isSelected: false },
        ],
      },
      {
        header: "Timesheet",
        items: [
          {
            label: "Do not show submitted",
            type: "checkbox",
            isSelected: false,
          },
        ],
      },
      {
        header: "Expense",
        items: [
          {
            label: "Do not show submitted",
            type: "checkbox",
            isSelected: false,
          },
        ],
      },
    ],
    isLoading: false,
    submissionsList: [
      {
        case: "South California Gas Leak",
        date: new Date(),
        firm: "Boucher",
        isExpenseSubmitted: false,
        isTimesheetSubmitted: false,
        id: 1,
      },
      {
        case: "South California Gas Leak",
        date: new Date(),
        firm: "California Law",
        isExpenseSubmitted: false,
        isTimesheetSubmitted: false,
        id: 2,
      },
      {
        case: "South California Gas Leak",
        date: new Date(),
        firm: "Parris",
        isExpenseSubmitted: false,
        isTimesheetSubmitted: false,
        id: 3,
      },
      {
        case: "South California Gas Leak",
        date: new Date(),
        firm: "Boucher",
        isExpenseSubmitted: true,
        isTimesheetSubmitted: true,
        id: 4,
      },
      {
        case: "South California Gas Leak",
        date: new Date(),
        firm: "California Law",
        isExpenseSubmitted: true,
        isTimesheetSubmitted: true,
        id: 5,
      },
      {
        case: "South California Gas Leak",
        date: new Date(),
        firm: "Parris",
        isExpenseSubmitted: true,
        isTimesheetSubmitted: true,
        id: 6,
      },
      {
        case: "South California Gas Leak",
        date: new Date(),
        firm: "Boucher",
        isExpenseSubmitted: true,
        isTimesheetSubmitted: true,
        id: 7,
      },
      {
        case: "South California Gas Leak",
        date: new Date(),
        firm: "California Law",
        isExpenseSubmitted: true,
        isTimesheetSubmitted: true,
        id: 8,
      },
      {
        case: "South California Gas Leak",
        date: new Date(),
        firm: "Parris",
        isExpenseSubmitted: true,
        isTimesheetSubmitted: true,
        id: 9,
      },
    ],
    manageTimesheetSubmitDialog: {
      isOpen: false,
      data: undefined,
      type: undefined,
      index: undefined,
    },
    manageOnSubmissionDialog: {
      isOpen: false,
      data: undefined,
      index: undefined,
    },
  } as {
    isLoading: boolean;
    submissionsList: ISubmissions[];
    manageTimesheetSubmitDialog: {
      isOpen: boolean;
      data?: any;
      type?: string;
      index?: number;
    };
    manageOnSubmissionDialog: {
      isOpen: boolean;
      data?: any;
      index?: number;
    };
    filterData: IFilter[];
  });

  const handleViewSubmitDialogOpen = (
    isOpen: boolean,
    type?: string,
    index?: number
  ) => {
    setState({
      ...state,
      manageTimesheetSubmitDialog: {
        isOpen,
        data: undefined,
        type,
        index: index,
      },
    });
  };

  const handleViewSubmitDialogClose = (data?: any) => {
    let manageOnSubmissionDialog = { ...state.manageOnSubmissionDialog };
    if (data) {
      manageOnSubmissionDialog = {
        isOpen: true,
        index: state.manageTimesheetSubmitDialog.index,
        data,
      };
    }
    setState({
      ...state,
      manageOnSubmissionDialog,
      manageTimesheetSubmitDialog: {
        isOpen: false,
        data: undefined,
        type: undefined,
        index: undefined,
      },
    });
  };

  const handleViewOnSubmissionClose = () => {
    let submissionsList = [...state.submissionsList];
    if (state.manageOnSubmissionDialog.index !== undefined) {
      if (state.manageOnSubmissionDialog.data.type === "timesheet") {
        submissionsList[
          state.manageOnSubmissionDialog.index
        ].isTimesheetSubmitted = true;
      }
      if (state.manageOnSubmissionDialog.data.type === "expense") {
        submissionsList[
          state.manageOnSubmissionDialog.index
        ].isExpenseSubmitted = true;
      }
      showToast("Submission updated successfully", "success");
    }
    setState({
      ...state,
      submissionsList,
      manageOnSubmissionDialog: {
        isOpen: false,
        index: undefined,
        data: undefined,
      },
    });
  };

  const onFilterChange = (data: IFilter[]) => {
    console.log("data", data);
  };

  return (
    <React.Fragment>
      <section className="p-16">
        <Typography variant="h5" align="center">
          Submit To Court
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} lg={2}>
            <Hidden only="xs">
              <Filter
                data={state.filterData}
                onChange={(data: IFilter[]) => {
                  onFilterChange(data);
                }}
              />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={9} lg={10}>
            <br />
            <TableContainer className="moz-table-pb">
              <Table size="small" className="custom-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Month</StyledTableCell>
                    <StyledTableCell>Firm</StyledTableCell>
                    <StyledTableCell>Time Sheet</StyledTableCell>
                    <StyledTableCell>Expense</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.submissionsList.length > 0 ? (
                    state.submissionsList.map((ele, index: number) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          {moment(ele.date).format("MMMM YYYY")}
                        </StyledTableCell>
                        <StyledTableCell>{ele.firm || ""}</StyledTableCell>
                        <StyledTableCell>
                          {ele.isTimesheetSubmitted ? (
                            <Button variant="contained" color="primary">
                              Submitted
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                handleViewSubmitDialogOpen(
                                  true,
                                  "timesheet",
                                  index
                                );
                              }}
                            >
                              Submit
                            </Button>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {ele.isExpenseSubmitted ? (
                            <Button variant="contained" color="secondary">
                              Submitted
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => {
                                handleViewSubmitDialogOpen(
                                  true,
                                  "expense",
                                  index
                                );
                              }}
                            >
                              Submit
                            </Button>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        No submissions found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </section>
      {state.manageTimesheetSubmitDialog.isOpen && (
        <ViewToSubmitToCourtDialog
          handleDialogClose={(data?: any) => handleViewSubmitDialogClose(data)}
          data={state.manageTimesheetSubmitDialog.data}
          type={state.manageTimesheetSubmitDialog.type}
        />
      )}
      {state.manageOnSubmissionDialog.isOpen && (
        <ViewOnSubmissionDialog
          handleDialogClose={() => handleViewOnSubmissionClose()}
          data={state.manageOnSubmissionDialog.data}
        />
      )}
    </React.Fragment>
  );
};

export default CourtSubmissionsMain;
