import React from "react";
import { IResource, ITimeFilter } from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Button,
    Grid, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import { getCommitteeReportFilters } from "../../services/ManagementDashboardService";
import CustomDialogDrawer from "../common/CustomDialogDrawer";

const TableHeaderSort = (props: any) => {
    return (
        <StyledTableCell>
            <TableSortLabel
                active={props.sortBy === props.name}
                direction={props.sortOrder}
                onClick={() => {
                    props.onSort(props.name);
                }}
            >
                {props.label}
            </TableSortLabel>
        </StyledTableCell>
    )
}
export interface ILoadFiltersDialogProps {
    data?: IResource;
    handleDialogClose: any;
}

const LoadCommitteeFilter: React.FC<ILoadFiltersDialogProps> = ({
    data,
    handleDialogClose
}) => {
    // state
    const [state, setState] = React.useState({
        filters: [],
        allFilters: [],
        search: "",
        sortBy: 'name',
        sortOrder: 'asc'
    } as {
        filters: ITimeFilter[];
        allFilters: ITimeFilter[];
        search: string;
        sortBy: string;
        sortOrder: 'asc' | 'desc';
    });

    const [isLoading, setLoading] = React.useState(false);

    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    React.useEffect(() => {
        const fetchDetails = async () => {
            await getFilters();
        };
        fetchDetails();
    }, []);

    const getFilters = async () => {
        setLoading(true)
        const result = await getCommitteeReportFilters();
        if (result?.isSuccess) {
            let filters = applySorting(result.data, state.sortBy, state.sortOrder);
            setState({
                ...state,
                filters,
                allFilters: result.data,
            });
        } else {
            showToast(
                result?.message ? result.message : "Error while getting saved filters",
                "error"
            );
        }
        setLoading(false);
    };

    const onSearchByFilterName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        let allFilters = [...state.allFilters];
        let filters = allFilters.filter((d) => d.name.toLowerCase().includes(value.toLowerCase()));
        filters = applySorting(filters, state.sortBy, state.sortOrder);
        setState({ ...state, filters, search: value });
    };

    const onSort = (newSortBy: string) => {
        let sortOrder = state.sortOrder;
        let sortBy = state.sortBy;
        if (newSortBy === sortBy) {
            sortOrder = sortOrder === "asc" ? "desc" : "asc";
        } else {
            sortBy = newSortBy;
            sortOrder = "asc";
        }

        let filters = [...state.filters];
        filters = applySorting(filters, sortBy, sortOrder);
        setState({ ...state, filters, sortBy, sortOrder });
    };

    const applySorting = (filters: ITimeFilter[], sortBy: string, sortOrder: "asc" | "desc") => {
        filters = filters.sort((a, b) => sortOrder === "asc" ? a[sortBy] > b[sortBy] ? 1 : -1 : b[sortBy] > a[sortBy] ? 1 : -1);
        return filters;
    }

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                title={"Load Report Configuration"}
                onClose={() => handleDialogClose()}
                handleOpen={true}
            >
                <section>

                    <Grid container direction="column" spacing={2}>
                        <Grid item lg={12} md={12} sm={12}>
                            <Grid container spacing={1} justify="flex-end">
                                <Grid item xs={12} sm={8} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        value={state.search}
                                        onChange={onSearchByFilterName}
                                        placeholder="Search by name"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                            <TableContainer className="moz-table-pb">
                                <Table stickyHeader size="small" className="custom-table management-dashboard">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeaderSort name='name' label='Name' sortBy={state.sortBy} sortOrder={state.sortOrder} onSort={onSort} />
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.filters.length > 0 ? (
                                            state.filters.map((ele, index: number) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell>{ele.name}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <Button variant="text"
                                                            onClick={() => {
                                                                let filters = JSON.parse(ele.filters);
                                                                handleDialogClose({
                                                                    ...ele,
                                                                    filters
                                                                })
                                                            }}
                                                            color="primary"
                                                        >
                                                            Load
                                                        </Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell align="center" colSpan={3}>
                                                    No data found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </section>

            </CustomDialogDrawer>
        </React.Fragment >
    );
};

export default LoadCommitteeFilter;
