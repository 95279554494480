import { IFilter, IStandardAPIResponse, IUserDashboard } from "../vm";
import { httpClient } from "./UtilService";

export async function getExpenseTotalAmountFeeReport(
    searchObj: any
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`reports/expenses-total-amount`, "POST", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
export async function getExpenseStatusCountReport(
    searchObj: any
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`reports/expenses-status-count`, "POST", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
export async function getExpenseCategoryAmountStatsDetails(
    searchObj: any
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`reports/category-expenses-amount`, "POST", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
export async function getExpenseTopFirmsCategory(
    searchObj: any
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`reports/top-expenses-firms-category`, "POST", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}