import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pencil } from "mdi-material-ui";
import * as React from "react";
import {
  currencyFormat,
  StyledTableCell,
  StyledTableRow,
} from "../../services/UtilService";
import { IExpense } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import moment from "moment";
import {
  editExpense,
  getExpensesAttentionDetails,
} from "../../services/ExpenseService";
import { useHistory } from "react-router-dom";
import { ToastContext } from "../common/ToastProvider";
import ManageExpenseDialog from "./ManageExpenseDialog";
import Loading from "../common/Loading";

export interface RuleFailedDialogProps {
  timeEntryData?: IExpense;
  handleDialogClose: Function;
}

const RuleFailedDialog: React.FC<RuleFailedDialogProps> = ({
  timeEntryData,
  handleDialogClose,
}) => {
  const [state, setState] = React.useState({
    manageExpenseDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
  } as {
    manageExpenseDialog: {
      isOpen: boolean;
      data?: IExpense;
      index?: number;
    };
  });

  const [attentionList, setAttentionList] = React.useState([] as IExpense[]);
  const [isLoading, setLoading] = React.useState(false);
  const { showToast } = React.useContext(ToastContext);

  React.useEffect(() => {
    const fetch = async () => {
      await getAttentionDetails();
    };
    fetch();
  }, []);

  const getAttentionDetails = async () => {
    setLoading(true);
    let data = {
      expenseEntryId: timeEntryData?.expenseEntryId || "",
      expenseDate: timeEntryData?.expenseDate || "",
      lawFirmCode: timeEntryData?.lawFirmCode || "",
    };
    let result = await getExpensesAttentionDetails(data);
    if (result?.isSuccess) {
      setAttentionList(result?.data || []);
    } else {
      showToast(
        result.message || "Error while fetching attention details",
        "error"
      );
    }
    setLoading(false);
  };

  const closeAddDialog = () => {
    handleDialogClose();
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = attentionList[editIndex];
    }
    setState({
      ...state,
      manageExpenseDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };

  const handleAddDialogClose = async (data?: IExpense) => {
    setLoading(true);
    if (data) {
      if (state.manageExpenseDialog.index !== undefined) {
        if (data.isEdit) {
          let result = await editExpense(data);
          if (result?.isSuccess) {
            showToast("Entry updated successfully", "success");
            await getAttentionDetails();
          }
        } else {
          showToast("Entry not updated as no value changed", "success");
        }
      }
    }
    setState({
      ...state,
      manageExpenseDialog: {
        isOpen: false,
        data: undefined,
        index: undefined,
      },
    });
    setLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDrawer
        title={"Review Attention Expense Summary"}
        fullWidth={true}
        onClose={() => closeAddDialog()}
      >
        <section className="p-16">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <br />
              <TableContainer className="moz-table-pb">
                <Table size="small" className="custom-table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Attention Name</StyledTableCell>
                      <StyledTableCell>Attention Description</StyledTableCell>
                      <StyledTableCell>Expense Date</StyledTableCell>
                      <StyledTableCell>Category Name</StyledTableCell>
                      <StyledTableCell>
                        Detailed Description of Work performed
                      </StyledTableCell>
                      <StyledTableCell align="center">Class Work</StyledTableCell>
                      <StyledTableCell>Approved by</StyledTableCell>
                      <StyledTableCell align="center">Amount</StyledTableCell>
                      <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attentionList.length > 0 ? (
                      attentionList.map((ele, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {ele?.attentionName || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele?.attentionDescription || ""}
                          </StyledTableCell>

                          <StyledTableCell>
                            {ele.expenseDate
                              ? moment(ele.expenseDate).format("MM/DD/YYYY")
                              : ""}
                          </StyledTableCell>

                          <StyledTableCell>
                            {ele?.expenseCategoryName || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele.detailedDescription}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {ele.classActionWorkIndicator}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele.expenseApprovedBy}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {currencyFormat(ele?.expenseAmount || 0)}
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              size="small"
                              onClick={() => handleAddDialogOpen(true, index)}
                            >
                              <Pencil />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={11}>
                          No Entries found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} justify="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => closeAddDialog()}
                  >
                    Close
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onUpdate()}
                  >
                    Update
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </section>
      </CustomDrawer>
      {state.manageExpenseDialog.isOpen && (
        <ManageExpenseDialog
          data={state.manageExpenseDialog.data}
          handleDialogClose={(data?: IExpense) => handleAddDialogClose(data)}
        />
      )}
    </React.Fragment>
  );
};

export default RuleFailedDialog;
