import React from "react";
import { ITimeFilter } from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Button,
    Grid,
    // Table,
    // TableBody,
    // TableCell,
    // TableContainer,
    // TableHead,
    // TableRow,
    // Checkbox,
    FormControl,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
// import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import { addOrRemoveCMOFilters, getManagementRuleFilters } from "../../services/ManagementDashboardService";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import Select from "react-select";
import { CMO_DIC } from "../../Constant";

export interface IAddFilterDialogProps {
    data: ITimeFilter[];
    handleDialogClose: any;
    type: string;
    isRemovedFilter?: boolean;
    updateStatus: Function;
}

interface IAddTimeFilter extends ITimeFilter {
    isSelected: boolean;
    // isChecked: boolean;
}

const AddFilterDialog: React.FC<IAddFilterDialogProps> = ({
    data,
    handleDialogClose,
    type,
    isRemovedFilter = false,
    updateStatus
}) => {
    // state
    const [state, setState] = React.useState({
        filters: [],
    } as {
        filters: IAddTimeFilter[];
    });
    const [isLoading, setLoading] = React.useState(false);

    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    React.useEffect(() => {
        const fetchDetails = async () => {
            await getFilters();
        };
        if (isRemovedFilter) {
            const list = data.map((d: IAddTimeFilter) => {
                d.isSelected = true;
                return d;
            });
            setState({ ...state, filters: list });
        } else {
            fetchDetails();
        }
    }, []);

    const getFilters = async () => {
        setLoading(true)
        const result = await getManagementRuleFilters(undefined, undefined, true);
        if (result?.isSuccess) {
            let selectedFilterIds = data && data.length > 0 ? data.map((d) => d.timeFilterId) : [];
            let filters: IAddTimeFilter[] = result.data.map((d: IAddTimeFilter) => {
                if (selectedFilterIds.includes(d.id)) d.isSelected = true;
                else d.isSelected = false;
                // if (selectedFilterIds.includes(d.id)) d.isChecked = true;
                // else d.isChecked = false;
                return d;
            })
            setState({
                ...state,
                filters,
            });
        } else {
            showToast(
                result?.message ? result.message : "Error while getting saved filters",
                "error"
            );
        }
        setLoading(false);
    };

    // const selectAll = () => {
    //     let list = [...state.filters];
    //     const selectedFilters = list.filter((d) => d.isChecked);
    //     if (selectedFilters.length === list.length) {
    //         list = list.map((d) => {
    //             d.isChecked = false;
    //             return d;
    //         })
    //     } else {
    //         list = list.map((d) => {
    //             d.isChecked = true;
    //             return d;
    //         })
    //     }
    //     setState({ ...state, filters: list });
    // };

    const handleSelectChange = (index: number) => {
        let list = [...state.filters];
        list[index].isSelected = !list[index].isSelected;
        setState({ ...state, filters: list });
    };

    const handleDropdownChange = (valueArr: any) => {
        if (isRemovedFilter && !valueArr) valueArr = [];
        if (valueArr) {
            let list = [...state.filters];
            list = list.map((d) => {
                const find = valueArr.find((ele) => (ele.value === d.timeFilterId || ele.value === d.id));
                if (find) d.isSelected = true;
                else if (isRemovedFilter) d.isSelected = false;
                return d;
            })
            // list[index].isChecked = !list[index].isChecked;
            setState({ ...state, filters: list });
        }
    };

    const onAddSavedFilter = async () => {
        const selectedFilter = state.filters.filter((d) => d.isSelected);


        setLoading(true)
        const result = await addOrRemoveCMOFilters({ name: CMO_DIC[type], timeFilterId: selectedFilter.map(x => x.timeFilterId || x.id) });
        if (result?.isSuccess) {
            if (result?.isReadOnly) {
                showToast(result.message, "error");
                handleDialogClose();
                updateStatus(result.isReadOnly);
            } else {
                handleDialogClose(selectedFilter);
            }
        } else {
            showToast(
                result?.message ? result.message : "Error while getting saved filters",
                "error"
            );
            setLoading(false);
        }
    };


    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                title={`${isRemovedFilter ? "Remove" : "Add"} Saved Filters`}
                onClose={() => handleDialogClose()}
                handleOpen={true}
            >
                <section style={{ height: 'calc(100vh - 94px)' }}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} style={{ height: 'calc(100vh - 127px)', overflowY: "auto" }}>
                            <Grid container spacing={2}>
                                {state.filters.map((cmoFilter, index) => (
                                    <Grid item lg={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    checked={cmoFilter.isSelected ? true : false}
                                                    onChange={() => {
                                                        handleSelectChange(index)
                                                    }}
                                                />
                                            }
                                            label={isRemovedFilter ? cmoFilter.timeFilterName : cmoFilter.name}
                                        />
                                    </Grid>
                                ))}

                            </Grid>

                            {/* <FormControl size="small" fullWidth>
                                <Select
                                    value={state.filters.filter((d) => d.isSelected).map((d) => ({ label: isRemovedFilter ? d.timeFilterName : d.name, value: d.timeFilterId ? d.timeFilterId : d.id }))}
                                    className="react-select"
                                    placeholder="Saved Filters"
                                    isMulti={true}
                                    isClearable={isRemovedFilter ? true : false}
                                    options={state.filters.map((d) => ({ value: d.timeFilterId ? d.timeFilterId : d.id, label: isRemovedFilter ? d.timeFilterName : d.name }))}
                                    onChange={(newValue) => {
                                        handleDropdownChange(newValue)
                                    }}
                                />
                            </FormControl> */}
                        </Grid>
                        {/* <Grid item lg={12} md={12} sm={12}>
                            <TableContainer className="moz-table-pb">
                                <Table stickyHeader size="small" className="custom-table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                <Checkbox
                                                    size="small"
                                                    color='secondary'
                                                    checked={state.filters.length > 0 && state.filters.filter((d) => d.isChecked).length === state.filters.length}
                                                    onClick={() => {
                                                        selectAll();
                                                    }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Rule Name</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.filters.length > 0 ? (
                                            state.filters.map((ele, index: number) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell>
                                                        <Checkbox
                                                            size="small"
                                                            checked={ele.isChecked}
                                                            onClick={() => {
                                                                handleSelectChange(index);
                                                            }}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>{ele.name}</StyledTableCell>
                                                    <StyledTableCell>{ele.timeManagementDashboardName}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell align="center" colSpan={2}>
                                                    No data found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid> */}
                        <Grid item lg={12} md={12} sm={12} style={{ paddingTop: 16 }}>
                            <Grid container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button type="button" variant="outlined"
                                        onClick={() => handleDialogClose()}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" type="button" color="primary" disabled={isLoading || (state.filters.filter((d) => d.isSelected).length === 0 && !isRemovedFilter)} onClick={onAddSavedFilter}>
                                        {isRemovedFilter ? 'Save' : 'Add'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>

            </CustomDialogDrawer>
        </React.Fragment >
    );
};

export default AddFilterDialog;
