import { ICmoProtocols, IManagementDashboardResponse, IStandardAPIResponse, ITimeFilter } from "../vm";
import { httpClient } from "./UtilService";

export async function getManagementDashBoardDetails(): Promise<IStandardAPIResponse<IManagementDashboardResponse[]>> {
  try {
    let res = await httpClient<IManagementDashboardResponse[]>(`management-dashboard`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getManagementRuleFilters(timeManagementDashboardId?: number, saveFilterId?: number, sendAll?: boolean): Promise<IStandardAPIResponse<ITimeFilter[]>> {
  try {
    let res = await httpClient<ITimeFilter[]>(`management-dashboard/load-filters`, "POST", {
      timeManagementDashboardId,
      saveFilterId,
      sendAll
    });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function approveOrDisapproveRule(managementDashboardId, status: "approved" | "not-approved"): Promise<IStandardAPIResponse<ITimeFilter[]>> {
  try {
    let res = await httpClient<ITimeFilter[]>(`management-dashboard/update-rule-status?id=${managementDashboardId}&status=${status}`, "PATCH", {});
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getManagementDashboardCmoStats(
  searchObj: any
): Promise<IStandardAPIResponse<ICmoProtocols[]>> {
  try {
    let res = await httpClient<ICmoProtocols[]>(`management-dashboard/cmo-stats`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getManagementDashboardCmoFilters(): Promise<IStandardAPIResponse<any[]>> {
  try {
    let res = await httpClient<any[]>(`management-dashboard/cmo-filtes`, "GET",);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function addOrRemoveCMOFilters(data: { name: string, timeFilterId: number[] }): Promise<IStandardAPIResponse<any[]>> {
  try {
    let res = await httpClient<any[]>(`management-dashboard/cmo-filtes`, "POST", data, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getManagementDashboardCmoText(protoColName): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`management-dashboard/cmo-text`, "GET", { text: protoColName });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getCommitteeReportFilters(): Promise<IStandardAPIResponse<ITimeFilter[]>> {
  try {
    let res = await httpClient<ITimeFilter[]>(`burst-reports/load-exhibits-report-filters`, "POST", {
    });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}