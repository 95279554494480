import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import * as React from "react";
import { IReactSelect, ITimeEntry } from "../../vm";
import moment from "moment";
import {
    getAllProfessionalLevels,
    getAllCategories,
    getAllApprovedListForTimeEntry,
    getAllWorkTypes
} from "../../services/TimesheetService";
import { ToastContext } from "../common/ToastProvider";
import DatePicker from "react-date-picker";
export interface ManageTimeEntryProps {
    data?: ITimeEntry;
    selectedIndex?: number;
    isDataChanged?: number;
    handleDialogClose: Function;
    editType?: "timeEntry" | "bulkEdit" | "splitTime";
}

const ManageTimeEntry: React.FC<ManageTimeEntryProps> = ({
    data,
    handleDialogClose,
    editType = "timeEntry",
    selectedIndex,
    isDataChanged
}) => {
    const [state, setState] = React.useState({
        timeEntry: {
            approvedBy: "",
            // billingRate: "",
            categoryCode: "",
            // categoryName: "",
            // detailedDescription: "",
            // professionalLevel: "",
            // reason: "",
            serviceDate: undefined,
            // timeSpent: "",
            // feesTotal: "",
            // isEdit: false,
            comments: "",
            resourceName: "",
            classActionWork: "",
            // escapeAttentionValidation: false,
            ...data,
            classActionWorkIndicator: editType === "bulkEdit" ? undefined : (data?.classActionWorkIndicator === "Y" ? data?.classActionWorkIndicator : "N")
        },
    } as {
        timeEntry: ITimeEntry;
    });
    const { showToast } = React.useContext(ToastContext);
    const [isLoading, setLoading] = React.useState(false);
    const [professionalLevelList, setProfessionalLevels] = React.useState([]);
    const [approversList, setApproversList] = React.useState(
        [] as Array<IReactSelect>
    );
    const [initialWorkTypes, setInitialWorkTypes] = React.useState(
        [] as { label: string; value: string }[]
    );
    const [categoriesList, setCategories] = React.useState([]);
    React.useEffect(() => {
        if (isDataChanged) {
            setState({
                ...state,
                timeEntry: {
                    serviceDate: undefined,
                    comments: "",
                    resourceName: "",
                    classActionWork: "",
                    ...data,
                    classActionWorkIndicator: editType === "bulkEdit" ? undefined : (data?.classActionWorkIndicator === "Y" ? data?.classActionWorkIndicator : "N")
                },
            })
        }
    }, [isDataChanged]);
    React.useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            await getAllProfessionalLevelsList();
            await getAllCategoriesList();
            await getAllApprovedList();
            await getAllWorkTypesList();
            setLoading(false);
        };
        fetch();
    }, []);

    const getAllApprovedList = async () => {
        const result = await getAllApprovedListForTimeEntry();
        if (result?.isSuccess) {
            let approversList: Array<IReactSelect> = [];
            if (result?.data) {
                approversList = result.data.map((x) => {
                    return {
                        label: x.timeApprovedByName,
                        value: x.timeApprovedByCode,
                    };
                });
            }
            if (state.timeEntry.approvedBy) {
                let isTimeEntryAvailable = approversList.find(
                    (x) => x.value === state.timeEntry.approvedBy
                );
                if (!isTimeEntryAvailable) {
                    setState({
                        ...state,
                        timeEntry: {
                            ...state.timeEntry,
                            approvedBy: undefined,
                        },
                    });
                }
            }
            setApproversList(approversList || []);
        } else {
            showToast(
                result.message || "Error while fetching professional levels",
                "error"
            );
        }
    };

    const getAllWorkTypesList = async () => {
        const result = await getAllWorkTypes();
        let workTypesDic = {};
        if (result?.isSuccess) {
            if (result?.data) {
                let data = result.data.map((x) => {
                    return { label: x.workTypeLongDescription, value: x.workTypeCode };
                });
                workTypesDic = data.reduce((acc: any, ele) => {
                    acc[ele.value] = ele;
                    return acc;
                }, {});
                setInitialWorkTypes(data);
            }
        } else {
            showToast(result.message || "Error while fetching work types", "error");
        }
        return workTypesDic;
    };

    const getAllProfessionalLevelsList = async () => {
        const result = await getAllProfessionalLevels();
        if (result?.isSuccess) {
            setProfessionalLevels(result?.data || []);
        } else {
            showToast(
                result.message || "Error while fetching professional levels",
                "error"
            );
        }
    };
    const getAllCategoriesList = async () => {
        const result = await getAllCategories();
        if (result?.isSuccess) {
            setCategories(result?.data || []);
        } else {
            showToast(result.message || "Error while fetching categories", "error");
        }
    };

    const closeAddDialog = (data?: ITimeEntry) => {
        handleDialogClose(data);
    };

    return (
        <React.Fragment>
            <section className="p-16">
                <Formik
                    enableReinitialize
                    initialValues={{ ...state.timeEntry }}
                    validate={(values) => {
                        let errors: FormikErrors<ITimeEntry> = {};
                        if (editType !== "bulkEdit") {
                            if (!values.serviceDate) {
                                errors.serviceDate = "Required";
                            }
                            if (!values.professionalLevel) {
                                errors.professionalLevel = "Required";
                            }
                            if (!values.categoryCode) {
                                errors.categoryCode = "Required";
                            }
                            if (!values.detailedDescription) {
                                errors.detailedDescription = "Required";
                            }
                            if (!values.approvedBy) {
                                errors.approvedBy = "Required";
                            }
                            if (!values.timeSpent) {
                                errors.timeSpent = "Required";
                            }
                            // if (!values.billingRate) {
                            //     errors.billingRate = "Required";
                            // }
                            // if (!values.classActionWork) {
                            //   errors.classActionWork = "Required";
                            // }
                            if (!values.classActionWorkIndicator) {
                                errors.classActionWorkIndicator = "Required";
                            }
                        }
                        if (!values.comments) {
                            errors.comments = "Required";
                        }
                        return errors;
                    }}
                    onSubmit={async (values: ITimeEntry, { setSubmitting }) => {
                        const obj = { ...values };
                        let previousData = { ...data };
                        let reason = obj.comments || "";
                        delete obj.comments;
                        delete previousData.comments;
                        if (editType !== "bulkEdit") {
                            obj.isEdit = false;
                            let valuesToCompare = [
                                "serviceDate",
                                "professionalLevel",
                                "categoryCode",
                                "detailedDescription",
                                "approvedBy",
                                "timeSpent",
                                "billingRate",
                                "classActionWorkIndicator",
                                // "classActionWork"
                                // "escapeAttentionValidation"
                            ];
                            valuesToCompare.forEach((prop) => {
                                if (obj[prop] !== previousData[prop]) {
                                    obj.isEdit = true;
                                }
                            });
                        }
                        if (obj.lawFirmCode) {
                            obj.firmCode = obj.lawFirmCode;
                        }
                        if (obj.timeEntryId) {
                            obj.timeCommonBenefitId = obj.timeEntryId;
                        }
                        if (obj.isEdit) {
                            obj.feesTotal = ((obj.timeSpent as any) * (obj.billingRate as any));
                        }
                        if (editType === "splitTime") {
                            if (obj.professionalLevel) {
                                let professionalLevelSelected = professionalLevelList.find(x => x.professionalLevelCode === obj.professionalLevel);
                                if (professionalLevelSelected) {
                                    obj.professionalLevelName = professionalLevelSelected.professionalLevelName;
                                } else {
                                    obj.professionalLevelName = "";
                                }
                            } else {
                                obj.professionalLevelName = "";
                            }
                        }
                        obj.comments = reason;
                        console.log("Values", obj);
                        closeAddDialog(obj);
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={1}>
                                {/* {values.resourceName && (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="resourceName"
                                                name="resourceName"
                                                disabled
                                                label="Resource Name"
                                                onChange={handleChange}
                                                error={
                                                    errors.resourceName && touched.resourceName
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.resourceName &&
                                                    touched.resourceName &&
                                                    errors.resourceName
                                                }
                                                onBlur={handleBlur}
                                                value={values.resourceName}
                                            />
                                        </FormControl>
                                    </Grid>
                                )} */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl margin="normal" required fullWidth className="position-relative">
                                        {/* <InputLabel className="custom-react-select-label date-label" htmlFor="role">
                                            Service Date
                                        </InputLabel>
                                        <DatePicker
                                            className={errors.serviceDate && touched.serviceDate ? 'react-date-error' : ''}
                                            dayPlaceholder="DD"
                                            monthPlaceholder="MM"
                                            yearPlaceholder="YYYY"
                                            value={values.serviceDate ? moment(values.serviceDate).toDate() : undefined}
                                            onChange={(newValue: any) => {
                                            setFieldValue("serviceDate", newValue);
                                            }}
                                        />
                                        {errors.serviceDate && touched.serviceDate && (
                                            <FormHelperText className="text-danger">{errors.serviceDate}</FormHelperText>
                                        )} */}
                                        <label className="form-label">Service Date</label>
                                        <TextField
                                            id="serviceDate"
                                            name="serviceDate"
                                            type="date"
                                            onChange={handleChange}
                                            error={
                                                errors.serviceDate && touched.serviceDate
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.serviceDate &&
                                                touched.serviceDate &&
                                                errors.serviceDate
                                            }
                                            onBlur={handleBlur}
                                            value={values.serviceDate ? moment(values.serviceDate).format("YYYY-MM-DD") : ""}
                                            inputProps={{ max: moment().format("YYYY-MM-DD") }}
                                        />
                                    </FormControl>
                                </Grid>
                                {editType !== "bulkEdit" && (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel className="select-label" htmlFor="role">
                                                Professional Level
                                            </InputLabel>
                                            <Select
                                                value={values.professionalLevel}
                                                name="professionalLevel"
                                                error={
                                                    errors && errors.professionalLevel ? true : false
                                                }
                                                onChange={(event: any) => {
                                                    let value = event.target.value;
                                                    setFieldValue("professionalLevel", value);
                                                }}
                                                disabled={true}
                                                onBlur={handleBlur}
                                            >
                                                {/* {editType === "bulkEdit" && (
                                                    <MenuItem value={undefined}>N/A</MenuItem>
                                                )} */}
                                                {professionalLevelList.map((ele, index) => (
                                                    <MenuItem
                                                        value={ele.professionalLevelCode}
                                                        key={index}
                                                    >
                                                        {ele.professionalLevelName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors && errors.professionalLevel && (
                                                <FormHelperText className="text-danger">
                                                    {errors.professionalLevel}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                )}
                                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          id="categoryCode"
                          name="categoryCode"
                          label="Category Code"
                          onChange={handleChange}
                          error={
                            errors.categoryCode && touched.categoryCode
                              ? true
                              : false
                          }
                          helperText={
                            errors.categoryCode &&
                            touched.categoryCode &&
                            errors.categoryCode
                          }
                          onBlur={handleBlur}
                          value={values.categoryCode}
                        />
                      </FormControl>
                    </Grid> */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {/* <FormControl margin="normal" required fullWidth>
                        <TextField
                          id="categoryName"
                          name="categoryName"
                          label="Category Name"
                          onChange={handleChange}
                          error={
                            errors.categoryName && touched.categoryName
                              ? true
                              : false
                          }
                          helperText={
                            errors.categoryName &&
                            touched.categoryName &&
                            errors.categoryName
                          }
                          onBlur={handleBlur}
                          value={values.categoryName}
                        />
                      </FormControl> */}
                                    <FormControl margin="normal" fullWidth>
                                        <InputLabel className="select-label" htmlFor="role">
                                            Category
                                        </InputLabel>
                                        <Select
                                            value={values.categoryCode}
                                            name="categoryCode"
                                            error={errors && errors.categoryCode ? true : false}
                                            onChange={(event: any) => {
                                                let value = event.target.value;
                                                const unKnownCategory = categoriesList.find((d) => d.timeCategoryCode == value)?.timeCategoryName || "";
                                                // if (values.categoryName && unKnownCategory.toLowerCase() === 'unknown') {
                                                if (unKnownCategory.toLowerCase() === 'unknown') {
                                                    showToast(`You can't change it to ${unKnownCategory}`, "error");
                                                } else {
                                                    setFieldValue("categoryCode", value);
                                                    setFieldValue(
                                                        "categoryName",
                                                        categoriesList.find(
                                                            (ele) => ele.timeCategoryCode === value
                                                        )?.timeCategoryName || ""
                                                    );
                                                }
                                            }}
                                            onBlur={handleBlur}
                                        >
                                            {editType === "bulkEdit" && (
                                                <MenuItem value={undefined}>N/A</MenuItem>
                                            )}
                                            {categoriesList.map((ele, index) => (
                                                <MenuItem value={ele.timeCategoryCode} key={index}>
                                                    {ele.timeCategoryName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors && errors.categoryCode && (
                                            <FormHelperText className="text-danger">
                                                {errors.categoryCode}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            id="description"
                                            name="detailedDescription"
                                            label="Detailed Description"
                                            onChange={handleChange}
                                            error={
                                                errors.detailedDescription &&
                                                    touched.detailedDescription
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.detailedDescription &&
                                                touched.detailedDescription &&
                                                errors.detailedDescription
                                            }
                                            onBlur={handleBlur}
                                            value={values.detailedDescription}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl margin="normal" fullWidth>
                                        <InputLabel className="select-label" htmlFor="role">
                                            Approved By
                                        </InputLabel>
                                        <Select
                                            value={values.approvedBy || ""}
                                            name="approvedBy"
                                            error={errors && errors.approvedBy ? true : false}
                                            onChange={(event: any) => {
                                                let value = event.target.value;
                                                const unKnownApprovedBy = approversList.find((d) => d.value == value)?.label || "";
                                                // if (values.approvedBy && unKnownApprovedBy.toLowerCase() === 'unknown') {
                                                if (unKnownApprovedBy.toLowerCase() === 'unknown') {
                                                    showToast(`You can't change it to ${unKnownApprovedBy}`, "error");
                                                } else {
                                                    setFieldValue("approvedBy", value);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                        >
                                            {editType === "bulkEdit" && (
                                                <MenuItem value={undefined}>N/A</MenuItem>
                                            )}
                                            {approversList.map((ele, index) => (
                                                <MenuItem
                                                    value={ele.value}
                                                    key={`approved_by_${index}`}
                                                >
                                                    {ele.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors && errors.approvedBy && (
                                            <FormHelperText className="text-danger">
                                                {errors.approvedBy}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    {/* <FormControl margin="normal" required fullWidth>
                        <TextField
                          id="approvedBy"
                          name="approvedBy"
                          label="Approved By"
                          onChange={handleChange}
                          error={
                            errors.approvedBy && touched.approvedBy ? true : false
                          }
                          helperText={
                            errors.approvedBy &&
                            touched.approvedBy &&
                            errors.approvedBy
                          }
                          onBlur={handleBlur}
                          value={values.approvedBy}
                        />
                      </FormControl> */}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            id="timeSpent"
                                            name="timeSpent"
                                            label="Time Spent"
                                            type="number"
                                            onChange={(event) => {
                                                let value: string | number = event.target.value;
                                                if (value.includes(".")) {
                                                    value = Math.floor(Number(value) * 10) / 10;
                                                };
                                                if (Number(value) < 0) {
                                                    value = Number(value) * -1;
                                                }
                                                setFieldValue('timeSpent', value);
                                            }}
                                            error={
                                                errors.timeSpent && touched.timeSpent ? true : false
                                            }
                                            helperText={
                                                errors.timeSpent &&
                                                touched.timeSpent &&
                                                errors.timeSpent
                                            }
                                            onBlur={handleBlur}
                                            value={values.timeSpent}
                                            // value={Math.floor(Number(values.timeSpent) * 10) / 10}
                                            inputProps={{ min: 0, step: "0.1" }}
                                        />
                                    </FormControl>
                                </Grid>
                                {editType !== "bulkEdit" && (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="billingRate"
                                                name="billingRate"
                                                label="Billing Rate"
                                                type="number"
                                                onChange={handleChange}
                                                error={
                                                    errors.billingRate && touched.billingRate
                                                        ? true
                                                        : false
                                                }
                                                disabled={true}
                                                helperText={
                                                    errors.billingRate &&
                                                    touched.billingRate &&
                                                    errors.billingRate
                                                }
                                                onBlur={handleBlur}
                                                value={values.billingRate}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                                < Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl margin="normal" fullWidth>
                                        <InputLabel className="select-label" htmlFor="role">
                                            Class Action Work Indicator
                                        </InputLabel>
                                        <Select
                                            value={values.classActionWorkIndicator || ""}
                                            name="Class Action Work Indicator"
                                            error={errors && errors.classActionWorkIndicator ? true : false}
                                            onChange={(event: any) => {
                                                let value = event.target.value;
                                                setFieldValue("classActionWorkIndicator", value);
                                            }}
                                            onBlur={handleBlur}
                                        >
                                            {editType === "bulkEdit" && (
                                                <MenuItem value={undefined}>N/A</MenuItem>
                                            )}
                                            {initialWorkTypes.map((ele, index) => (
                                                <MenuItem
                                                    value={ele.value}
                                                    key={`workTypeCode${index}`}
                                                >
                                                    {ele.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors && errors.classActionWorkIndicator && (
                                            <FormHelperText className="text-danger">
                                                {errors.classActionWorkIndicator}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          id="classActionWork"
                          name="classActionWork"
                          label="Class Action Work"
                          onChange={handleChange}
                          error={
                            errors.classActionWork  && touched.classActionWork  ? true : false
                          }
                          helperText={
                            errors.classActionWork  && touched.classActionWork  && errors.classActionWork 
                          }
                          onBlur={handleBlur}
                          value={values.classActionWork }
                        />
                      </FormControl>
                    </Grid> */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            id="reason"
                                            name="comments"
                                            label="Reason"
                                            multiline
                                            rows={4}
                                            onChange={handleChange}
                                            error={
                                                errors.comments && touched.comments ? true : false
                                            }
                                            helperText={
                                                errors.comments && touched.comments && errors.comments
                                            }
                                            onBlur={handleBlur}
                                            value={values.comments}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                      <InputLabel className="select-label" style={{ marginBottom: 8 }} htmlFor="role">
                        Escape Attention Validation
                      </InputLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.escapeAttentionValidation}
                            onChange={(event: any) =>
                              setFieldValue("escapeAttentionValidation", event.target.checked)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={values.escapeAttentionValidation ? "Enabled" : "Not Enabled"}
                      />
                    </Grid> */}
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justify="flex-end">
                                        {editType === "splitTime" ? (
                                            <React.Fragment>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        {selectedIndex !== -1 ? "Update" : "Add"}
                                                    </Button>
                                                </Grid>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Grid item>
                                                    <Button variant="text" onClick={() => closeAddDialog()}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        {editType === "bulkEdit" ? "Bulk " : ""} Update
                                                    </Button>
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </section>
        </React.Fragment >
    );
};

export default ManageTimeEntry;
