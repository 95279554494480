import { createTheme } from "@material-ui/core/styles";

export const THEME = createTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "Rubik",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      size: "small",
    },
    MuiFormControl: {
      variant: "outlined",
      size: "small",
    },
  },
  palette: {
    // type: "dark",
    // primary: {
    //   500: "#c70c1d",
    // } as any,
    // secondary: {
    //     main: "#fba304",
    //     contrastText:"#fff"
    //   } as any,
  },
});

export const GS = () => ({
  p8: {
    padding: 8,
  },
});

// export const RS_THEME = (theme: Theme) => ({
//   ...theme,
//   colors: {
//     ...theme.colors,
//     primary: '#f4511e', // 600
//     primary75: '#ff7043', // 400
//     primary50: '#ffab91', // 200
//     primary25: '#ffccbc', // 100
//   },
// });
