import * as React from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import Loading from "../common/Loading";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Home from "mdi-material-ui/Home";
import { Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { verifyUserAccount } from "../../services/auth/AuthService";
import { removeToken } from "../../services/UtilService";
import { ToastContext } from "../common/ToastProvider";

const MyLink = (props: any) => <Link to="/" {...props} />;
export interface VerifyAccountMainProps {}

const VerifyAccountMain: React.FC<VerifyAccountMainProps> = (props) => {
  const history = useHistory();
  const routeMatch = useRouteMatch<{ token: string }>();
  const [isLoading, setLoading] = React.useState(true);
  const [isVerifying, setVerifying] = React.useState(true);
  const { showToast } = React.useContext(ToastContext);
  const classes = useStyles();

  React.useEffect(() => {
    verifyAccount();
  }, []);

  const verifyAccount = async () => {
    console.log("history", history);
    console.log("props", routeMatch.params.token);
    let isVerified = false;

    let tokenParams = window.location.href.split("?")?.[1];

    if (!tokenParams) {
      setVerifying(false);
      setLoading(false);
      return;
    }
    let token = tokenParams.split("=");
    const result = await verifyUserAccount(token[1]);
    if (result?.isSuccess) {
      isVerified = true;
      removeToken();
      showToast("Your account verified", "success");
      setTimeout(() => {
        setLoading(false);
        history.push("/");
      }, 3000);
    }

    if (isVerified === false) {
      setVerifying(false);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Paper className={classes.root}>
        <Typography variant="h5" gutterBottom>
          {isVerifying ? "Please wait..." : "Verification Failed"}
        </Typography>
        <br />
        <Fab component={MyLink} color="primary" aria-label="home">
          <Home />
        </Fab>
      </Paper>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    padding: 16,
    width: "400px",
    margin: "16px auto",
  },
});

export default VerifyAccountMain;
