import {
  IStandardAPIResponse,
  ITimeSheetSubmitToCourt,
  ITimeEntry,
  ITimesheetObj,
  IProfessionalHourStats,
  IPerson,
  IApproveListTimeEntry,
  ITimeEntryResponse,
  IProfessionLevelManagement,
  ITimeCategoryManagement,
  IKeywordManagement,
  IAttentionTimesheetsDetailsResponse,
} from "../vm";
import { httpClient, httpDownloadCSV, httpDownloadPDF } from "./UtilService";
import moment from "moment";

export async function getAllTimesheets(
  searchObj: any
): Promise<IStandardAPIResponse<ITimesheetObj>> {
  try {
    let res = await httpClient<ITimesheetObj>(`timesheets`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getTimeEntriesOfTimesheet(
  searchObj: any
): Promise<IStandardAPIResponse<ITimeEntryResponse>> {
  try {
    let res = await httpClient<ITimeEntryResponse>(
      `timesheets/timesheet
    `,
      "POST",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getTimeDetailsOfTimesheet(
  searchObj: any
): Promise<IStandardAPIResponse<ITimeEntryResponse>> {
  try {
    let res = await httpClient<ITimeEntryResponse>(
      `timesheets/timesheet-details`,
      "POST",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function editTimeSheet(
  obj: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(
      `timesheets/timesheet-edit`,
      "PATCH",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getTimesheetLogs(
  timesheetId: number
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(
      `timesheets/timesheet-logs/${timesheetId}`,
      "GET"
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function timesheetReviewed(
  obj: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(
      `timesheets/timesheet-reviewed`,
      "PATCH",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function approveTimesheets(
  obj: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(
      `timesheets/timesheet-approved`,
      "PATCH",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getAllProfessionalLevels(): Promise<
  IStandardAPIResponse<any>
> {
  try {
    let res = await httpClient<any>(`timesheets/professional-level`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getAllWorkTypes(): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`timesheets/work-type`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getAllCategories(): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`timesheets/category`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getTimesheetFiltersCount(
  obj: any,
  isGrantedNotGranted?: boolean,
  extraProps?: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let requestObj = {
      from: 0,
      size: 10,
      statuses: isGrantedNotGranted ? [...obj.statuses] : [],
      firms: obj.firmId ? [obj.firmId] : [],
      lawFirmCode: [],
      persons: obj.personId ? [obj.personId] : [],
      startDate: undefined,
      endDate: undefined,
      sortBy: "",
      sortOrder: "asc",
      keyword: [],
      min: "",
      max: "",
      amount: "",
      professionalLevel: [],
      categoryCodeForTime: [],
      categoryCodeForExpense: [],
      workType: [],
    };
    if (isGrantedNotGranted && requestObj.statuses.length < 3) {
      requestObj.statuses = [];
    }
    if (extraProps) {
      requestObj = { ...requestObj, ...extraProps }
    }
    let res = await httpClient<any>(`timesheets/counts`, "POST", requestObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getTimeEntriesFiltersCount(
  obj: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`timesheets/timesheet/counts`, "POST", obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getTimesheetPersons(): Promise<
  IStandardAPIResponse<Array<IPerson>>
> {
  try {
    let res = await httpClient<Array<IPerson>>(`timesheets/persons`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getFirmEmployeeListDetails(
  searchObj: any
): Promise<IStandardAPIResponse<Array<IProfessionalHourStats>>> {
  try {
    let res = await httpClient<Array<IProfessionalHourStats>>(
      `timesheets/employee-hours`,
      "POST",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export async function getAllEmployeeListDetails(
  searchObj: any
): Promise<IStandardAPIResponse<IProfessionalHourStats>> {
  try {
    let res = await httpClient<IProfessionalHourStats>(
      `timesheets/all-employee-hours`,
      "POST",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getTimeSheetToSubmitCourt(
  searchObj: any
): Promise<IStandardAPIResponse<ITimeSheetSubmitToCourt>> {
  try {
    let res = await httpClient<ITimeSheetSubmitToCourt>(
      `timesheets/timesheet-submit-court`,
      "POST",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function submitTimeSheetEntriesCourt(
  searchObj: any
): Promise<IStandardAPIResponse<ITimeSheetSubmitToCourt>> {
  try {
    let res = await httpClient<ITimeSheetSubmitToCourt>(
      `timesheets/submit-to-court`,
      "PATCH",
      searchObj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function submitGrantedNotGrantedTimeSheetEntriesCourt(
  searchObj: any
): Promise<IStandardAPIResponse<ITimeSheetSubmitToCourt>> {
  try {
    let res = await httpClient<ITimeSheetSubmitToCourt>(
      `timesheets/timesheet-granted`,
      "PATCH",
      searchObj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getAllApprovedListForTimeEntry(): Promise<
  IStandardAPIResponse<Array<IApproveListTimeEntry>>
> {
  try {
    let res = await httpClient<Array<IApproveListTimeEntry>>(
      `timesheets/approved-by`,
      "GET"
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getTimesheetAttentionDetails(
  data: any
): Promise<IStandardAPIResponse<ITimeEntry[]>> {
  try {
    let res = await httpClient<ITimeEntry[]>(
      `timesheets/attention-details`,
      "POST",
      data
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function generateExportForSubmitForCourt(
  timesheetFilters: any,
  timesheetIds: any,
  includeChangeLog: boolean,
  includeCSVExport: boolean
): Promise<IStandardAPIResponse<ITimeEntry[]>> {
  try {
    let res;
    // if (includeCSVExport) {
    res = await httpDownloadCSV(
      `timesheets/generate-csv-submit`,
      "POST",
      {
        isSaveOnServer: true,
        timesheetIds,
        includeChangeLog,
        includeCSVExport,
        timesheetFilters,
        isZip: includeCSVExport,
        type: "submitted",
        localTime: moment().format("YYYYMMDDHHmmss"),
      },
      false
    );
    // }
    // res = await httpDownloadPDF<ITimeEntry[]>(`timesheets/generate-pdf-submit`, "POST", {
    //   isSaveOnServer: true,
    //   timesheetIds,
    //   includeChangeLog,
    //   includeCSVExport,
    //   timesheetFilters,
    //   localTime:moment().format("YYYYMMDDHHmmss")
    // }, false);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getTimesheetFileNameForExport(data: {
  fileType: string;
  type: string;
  localTime: string;
  firmName?: string;
}): Promise<IStandardAPIResponse<any>> {
  try {
    if (!data.firmName) {
      delete data.firmName;
    }
    let res = await httpClient<any>(`timesheets/filename`, "GET", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getReportFileNameForExport(reportFileName: string): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`timesheets/exhibit-report-filename`, "GET", { reportFileName });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function bulkEditTimeEntries(
  obj: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`timesheets/bulk-edit`, "POST", obj, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function splitTimeEntries(
  requestObj
): Promise<IStandardAPIResponse<Array<ITimeEntry[]>>> {
  try {
    let res = await httpClient<Array<ITimeEntry[]>>(
      `timesheets/${requestObj.timeCommonBenefitId}/split-entries`,
      "POST",
      requestObj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function disApproveEntriesAPI(
  obj: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`timesheets/disapprove`, "POST", obj, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function createAProfessionalLevel(
  obj: IProfessionLevelManagement
): Promise<IStandardAPIResponse<IProfessionLevelManagement>> {
  try {
    let res = await httpClient<IProfessionLevelManagement>(
      `timesheets/professional-level`,
      "POST",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function updateAProfessionalLevel(
  obj: IProfessionLevelManagement
): Promise<IStandardAPIResponse<IProfessionLevelManagement>> {
  try {
    let res = await httpClient<IProfessionLevelManagement>(
      `timesheets/professional-level/${obj.id}`,
      "PATCH",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function removeAProfessionalLevel(
  id: number
): Promise<IStandardAPIResponse<IProfessionLevelManagement>> {
  try {
    let res = await httpClient<IProfessionLevelManagement>(
      `timesheets/professional-level/${id}`,
      "DELETE",
      undefined,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function createATimeCategory(
  obj: ITimeCategoryManagement
): Promise<IStandardAPIResponse<ITimeCategoryManagement>> {
  try {
    let res = await httpClient<ITimeCategoryManagement>(
      `timesheets/category`,
      "POST",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function updateATimeCategory(
  obj: ITimeCategoryManagement
): Promise<IStandardAPIResponse<ITimeCategoryManagement>> {
  try {
    let res = await httpClient<ITimeCategoryManagement>(
      `timesheets/category/${obj.id}`,
      "PATCH",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function removeATimeCategory(
  id: number
): Promise<IStandardAPIResponse<ITimeCategoryManagement>> {
  try {
    let res = await httpClient<ITimeCategoryManagement>(
      `timesheets/category/${id}`,
      "DELETE",
      undefined,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function createATimeApprovedByManagement(
  obj: IApproveListTimeEntry
): Promise<IStandardAPIResponse<IApproveListTimeEntry>> {
  try {
    let res = await httpClient<IApproveListTimeEntry>(
      `timesheets/approved-by`,
      "POST",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function updateATimeApprovedByManagement(
  obj: IApproveListTimeEntry
): Promise<IStandardAPIResponse<IApproveListTimeEntry>> {
  try {
    let res = await httpClient<IApproveListTimeEntry>(
      `timesheets/approved-by/${obj.id}`,
      "PATCH",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function removeATimeApprovedByManagement(
  id: number
): Promise<IStandardAPIResponse<IApproveListTimeEntry>> {
  try {
    let res = await httpClient<IApproveListTimeEntry>(
      `timesheets/approved-by/${id}`,
      "DELETE",
      undefined,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getAllKeywordList(): Promise<
  IStandardAPIResponse<Array<IKeywordManagement>>
> {
  try {
    let res = await httpClient<Array<IKeywordManagement>>(
      `timesheets/keywords`,
      "GET"
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function createAKeyword(
  obj: IKeywordManagement
): Promise<IStandardAPIResponse<IKeywordManagement>> {
  try {
    let res = await httpClient<IKeywordManagement>(
      `timesheets/keywords`,
      "POST",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function updateAKeyword(
  obj: IKeywordManagement
): Promise<IStandardAPIResponse<IKeywordManagement>> {
  try {
    let res = await httpClient<IKeywordManagement>(
      `timesheets/keywords/${obj.id}`,
      "PATCH",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function removeAKeyword(
  id: number
): Promise<IStandardAPIResponse<IKeywordManagement>> {
  try {
    let res = await httpClient<IKeywordManagement>(
      `timesheets/keywords/${id}`,
      "DELETE",
      undefined,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getAttentionTimeDetailsOfTimesheet(searchObj: any): Promise<IStandardAPIResponse<IAttentionTimesheetsDetailsResponse>> {
  try {
    let res = await httpClient<IAttentionTimesheetsDetailsResponse>(`timesheets/attention-timesheet-details`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}