import {
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    // TablePagination,
    TableRow,
    // TextField,
    Typography,
} from "@material-ui/core";
// import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import {
    // ChevronDoubleLeft,
    // ChevronDoubleRight,
    // ChevronLeft,
    // ChevronRight,
    DotsVertical,
} from "mdi-material-ui";
// import moment from "moment";
import * as React from "react";
import { getAllCategories, removeATimeCategory } from "../../services/TimesheetService";
import {
    StyledTableCell,
    StyledTableRow,
} from "../../services/UtilService";
import { IConfirmDialog, ITimeCategoryManagement } from "../../vm";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import AddTimeCategoryDialog from "./AddTimeCategoryDialog";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

export interface TimeCategoryManagementProps extends IConfirmDialog { }

const TimeCategoryManagement: React.FC<TimeCategoryManagementProps> = ({
    confirmDialog,
}) => {
    const [state, setState] = React.useState({
        timeCategoryList: [{}],
        selectedItemIndex: -1,
        anchorElMenu: null,
        manageTimeCategoryDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
        },
        rowsList: [10],
        levelsCount: 0,
    } as {
        timeCategoryList: ITimeCategoryManagement[];
        selectedItemIndex: number;
        anchorElMenu: any;
        manageTimeCategoryDialog: {
            isOpen: boolean;
            index?: number;
            data?: ITimeCategoryManagement;
        };
        rowsList: number[];
        levelsCount: number;
    });
    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);
    const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

    React.useEffect(() => {
        const fetchDetails = async () => {
            await fetchTimeCategories();
        };
        fetchDetails();
    }, []);


    const fetchTimeCategories = async () => {
        setLoading(true);
        const result = await getAllCategories();
        if (result?.isSuccess) {
            // let menuItems = [];
            // for (let index = 10; index <= result.data.count; index = index + 10) {
            //     menuItems.push(index);
            // }
            // if (menuItems[menuItems.length - 1] < result.data.count) {
            //     menuItems.push(result.data.count);
            // }
            setState({
                ...state,
                // levelsCount: result.data?.count || 0,
                timeCategoryList: result.data || [],
                // rowsList: [...menuItems],
            });
        } else {
            showToast(
                result?.message ? result.message : "Error while fetching time category list",
                "error"
            );
        }
        setLoading(false);
    };

    const handleClickMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        index: number
    ) => {
        setState({
            ...state,
            selectedItemIndex: index,
            anchorElMenu: event.currentTarget,
        });
    };

    const handleCloseMenu = () => {
        setState({ ...state, selectedItemIndex: -1, anchorElMenu: null });
    };


    const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
        let data: any = undefined;
        if (editIndex !== undefined) {
            data = state.timeCategoryList[editIndex];
        }
        setState({
            ...state,
            manageTimeCategoryDialog: {
                isOpen: isOpen,
                data: data,
                index: editIndex,
            },
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
    };


    const handleAddDialogClose = (data?: ITimeCategoryManagement) => {
        if (data) {
            fetchTimeCategories();
        } else {
            setState({
                ...state,
                manageTimeCategoryDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined
                },
            });
        }
    };

    const handleDelete = (index: number) => {
        confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
            setLoading(true);
            let list = [...state.timeCategoryList];
            const result = await removeATimeCategory(list[index].id as number);
            if (result?.isSuccess) {
                if (result?.isReadOnly) {
                    showToast(result.message, "error");
                    updateStatus(result.isReadOnly);
                } else {
                    list.splice(index, 1);
                    setState({
                        ...state,
                        timeCategoryList: list,
                        selectedItemIndex: -1,
                        anchorElMenu: null,
                    });
                    showToast("Time category deleted successfully", "success");
                }
            } else {
                showToast(result?.message || "Error while removing time category", "error");
            }
            setLoading(false);
        });
    };

    // const onSearch = async (searchObj?: any) => {
    //     let tempSearch = { ...state.search };
    //     if (searchObj) {
    //         tempSearch = { ...state.search, ...searchObj };
    //     }
    //     await fetchTimeCategories(tempSearch);
    // };


    // const onKeyDown = async (e: any) => {
    //     if (e.keyCode === 13) {
    //         await onSearch({ ...state.search, from: 0 });
    //     }
    // };

    // const TablePaginationActions = (props: TablePaginationActionsProps) => {
    //     const { count, page, rowsPerPage, onPageChange } = props;

    //     const handleFirstPageButtonClick = (
    //         event: React.MouseEvent<HTMLButtonElement>
    //     ) => {
    //         onPageChange(event, 0);
    //     };

    //     const handleBackButtonClick = (
    //         event: React.MouseEvent<HTMLButtonElement>
    //     ) => {
    //         onPageChange(event, page - 1);
    //     };

    //     const handleNextButtonClick = (
    //         event: React.MouseEvent<HTMLButtonElement>
    //     ) => {
    //         onPageChange(event, page + 1);
    //     };

    //     const handleLastPageButtonClick = (
    //         event: React.MouseEvent<HTMLButtonElement>
    //     ) => {
    //         onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    //     };

    //     return (
    //         <div className="d-flex">
    //             <IconButton
    //                 onClick={handleFirstPageButtonClick}
    //                 disabled={page === 0}
    //                 aria-label="first page"
    //             >
    //                 <ChevronDoubleLeft />
    //             </IconButton>
    //             <IconButton
    //                 onClick={handleBackButtonClick}
    //                 disabled={page === 0}
    //                 aria-label="previous page"
    //             >
    //                 <ChevronLeft />
    //             </IconButton>
    //             <IconButton
    //                 onClick={handleNextButtonClick}
    //                 disabled={page >= Math.ceil(count / rowsPerPage) - 1}
    //                 aria-label="next page"
    //             >
    //                 <ChevronRight />
    //             </IconButton>
    //             <IconButton
    //                 onClick={handleLastPageButtonClick}
    //                 disabled={page >= Math.ceil(count / rowsPerPage) - 1}
    //                 aria-label="last page"
    //             >
    //                 <ChevronDoubleRight />
    //             </IconButton>
    //         </div>
    //     );
    // };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">Time Category Management</Typography>
                </div>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="flex-end">
                            {/* <Grid item lg={6}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item lg={5}>
                                        <TextField fullWidth
                                            className="flex-grow"
                                            name="searchName"
                                            value={state.search.searchName}
                                            onChange={(event) => {
                                                let value = event.target.value;
                                                setState({
                                                    ...state,
                                                    search: { ...state.search, searchName: value },
                                                });
                                            }}
                                            onKeyDown={(e) => {
                                                onKeyDown(e);
                                            }}
                                            placeholder="Search"
                                        />
                                    </Grid>
                                    <Grid item lg={2}>
                                        <IconButton
                                            onClick={() => onSearch({ ...state.search, from: 0 })}
                                        >
                                            <Magnify />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isReadOnly}
                                    onClick={() => {
                                        handleAddDialogOpen(true, undefined)
                                    }}
                                >
                                    Add new Category
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TableContainer className="time-category-table-container moz-table-pb">
                                            <Table stickyHeader size="small" className="custom-table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Name</StyledTableCell>
                                                        <StyledTableCell>Code</StyledTableCell>
                                                        <StyledTableCell>Actions</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {state.timeCategoryList.length > 0 ? (
                                                        state.timeCategoryList.map((ele, index: number) => (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell>{ele.timeCategoryName}</StyledTableCell>
                                                                <StyledTableCell>{ele.timeCategoryCode || ""}</StyledTableCell>
                                                                <StyledTableCell>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={(e) => handleClickMenu(e, index)}
                                                                    >
                                                                        <DotsVertical />
                                                                    </IconButton>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={3}>
                                                                No time category found
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <TablePagination
                                            rowsPerPageOptions={state.rowsList}
                                            colSpan={5}
                                            component="div"
                                            count={state.levelsCount}
                                            rowsPerPage={state.search.size}
                                            page={
                                                state.search.from === 0
                                                    ? 0
                                                    : state.search.from / state.search.size
                                            }
                                            SelectProps={{
                                                inputProps: { "aria-label": "rows per page" },
                                                native: true,
                                            }}
                                            onPageChange={(event: any, newPage: number) => {
                                                console.log("event", event);
                                                console.log("newPage", newPage);
                                                let search = { ...state.search };
                                                search.from =
                                                    newPage === 0
                                                        ? 0
                                                        : (Number(state.rowsList[newPage - 1]) *
                                                            state.search.size) /
                                                        10;
                                                onSearch(search);
                                            }}
                                            onRowsPerPageChange={(event: any) => {
                                                let value = event.target.value;
                                                let search = { ...state.search };
                                                search.size = value ? Number(value) : 10;
                                                search.from = 0;
                                                onSearch(search);
                                            }}
                                            ActionsComponent={TablePaginationActions}
                                        /> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            <Menu
                id="simple-menu"
                anchorEl={state.anchorElMenu}
                keepMounted
                open={Boolean(state.anchorElMenu)}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    disabled={isReadOnly}
                    onClick={() => {
                        handleAddDialogOpen(true, state.selectedItemIndex);
                    }}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    disabled={isReadOnly}
                    onClick={() => {
                        handleDelete(state.selectedItemIndex);
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
            {state.manageTimeCategoryDialog.isOpen && (
                <AddTimeCategoryDialog
                    data={state.manageTimeCategoryDialog.data}
                    handleDialogClose={(data?: ITimeCategoryManagement) => handleAddDialogClose(data)}
                    updateStatus={updateStatus}
                />
            )}
        </React.Fragment>
    );
};

export default withConfirmDialogContext(TimeCategoryManagement);
