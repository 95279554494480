import React from "react";
import { IFirmDetails, IImportConfigure, IReactSelect } from "../../vm";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import Select from "react-select";
import { Button, FormControl, Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import { getAllFirms, getSelectedFileNameTabsAPI, uploadTimeSheets, uploadTimeSheetWithSheetNames } from "../../services/FirmsService";
import { FIRM_CONFIGURE_TYPES, FIRM_CONFIGURE__REPORT_TYPES } from "../../Constant";
import "./import.css"
import { Close } from "mdi-material-ui";
import { FirmContext } from "../common/FirmProvider";
export interface IManageImportDialogProps {
    data?: IFirmDetails;
    handleDialogClose: any;
    updateStatus: any;
}

const ManageImportDialog: React.FC<IManageImportDialogProps> = ({
    data,
    handleDialogClose,
    updateStatus
}) => {
    // state
    const [state, setState] = React.useState({
        lawFirmCode: undefined,
        type: { label: FIRM_CONFIGURE_TYPES.timesheet, value: "timesheet" },
        timeReport: { label: FIRM_CONFIGURE__REPORT_TYPES[0], value: FIRM_CONFIGURE__REPORT_TYPES[0] },
        files: [],
        selectedSheetsForFile: {}
    } as IImportConfigure);
    const [isLoading, setLoading] = React.useState(false);
    const [firmList, setFirmList] = React.useState([]);
    const [selectedFileDetails, setSelectedFileDetails] = React.useState([]);
    const firmProvider = React.useContext(FirmContext);

    React.useEffect(() => {
        const fetchFirms = async () => {
            await getAllFirmsList();
        };
        fetchFirms();
    }, []);
    React.useEffect(() => {
        let fileUploadEle: any = document.querySelector("#import_file_upload");
        if (fileUploadEle) {
            fileUploadEle.value = ""
        }
    }, [state.files]);

    const getAllFirmsList = async () => {
        setLoading(true);
        let userFirms = firmProvider.userFirms;
        if (userFirms.length === 0) {
            userFirms = await firmProvider.getFirmsList();
        }
        let list: any = [];
        userFirms.forEach((ele) => {
            let firm = { value: ele.lawFirmCode, label: ele.lawFirmShortName };
            list.push(firm);
        });
        setFirmList(list);
        setLoading(false);
        // setLoading(true);
        // let result = await getAllFirms();
        // setLoading(false);
        // if (result?.isSuccess) {
        //     let list: any = [];
        //     result.data.forEach((ele) => {
        //         let firm = { value: ele.lawFirmCode, label: ele.lawFirmShortName };
        //         list.push(firm);
        //     });
        //     setFirmList(list);

        // } else {
        //     showToast(result.message || "Error while getting firms", "error")
        // }
    };

    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    const closeAddDialog = (data?: boolean) => {
        handleDialogClose(data);
    };

    const updateSearchFiled = (field, value) => {
        setState({
            ...state,
            [field]: value
        })
    }

    const uploadDetails = async () => {
        let isError = false;
        let filesDic = Object.values(state.files).reduce((acc, ele) => {
            acc[ele.name] = ele;
            return acc;
        }, {})
        if (!state.lawFirmCode) {
            showToast("Please select firm", "error");
            isError = true;
        } else if (selectedFileDetails.length === 0) {
            showToast("Please select files to upload", "error");
            isError = true;
        }
        if (!isError) {
            selectedFileDetails.forEach(file => {
                if ((!state.selectedSheetsForFile[file.fileName] || state.selectedSheetsForFile[file.fileName].length === 0) && !isError) {
                    showToast("Please select sheets for " + file.fileName, "error");
                    isError = true;
                }
            })
        }
        if (!isError) {
            console.log(state)
            let formData = new FormData();
            selectedFileDetails.forEach(file => {
                formData.append("files", filesDic[file.fileName]);
            })
            setLoading(true);
            let result = await uploadTimeSheets(state, formData);
            if (result?.isSuccess) {
                if (result?.isReadOnly) {
                    showToast(result.message, "error");
                    handleDialogClose();
                    updateStatus(result.isReadOnly);
                } else {
                    let fileNameIdDic = {}
                    Object.keys(result.data).forEach(key => {
                        fileNameIdDic[result.data[key]] = key;
                    });
                    let requestObj = {};
                    Object.keys(state.selectedSheetsForFile).forEach(fileName => {
                        requestObj[fileNameIdDic[fileName]] = {
                            fileName: fileName,
                            sheetNames: state.selectedSheetsForFile[fileName].map(x => x.value).join(",")
                        }
                    });
                    result = await uploadTimeSheetWithSheetNames(requestObj);
                    if (result?.isSuccess) {
                        handleDialogClose(true);
                    } else {
                        showToast(result.message || "Error while uploading", "error");
                        setLoading(false);
                    }
                }
            } else {
                showToast(result.message || "Error while uploading", "error");
                setLoading(false);
            }
        }
    }

    const getSelectedFileNameTabs = async (selectedFiles: File[]) => {
        let fileSelected = { ...selectedFiles }
        let formData = new FormData();
        Array.from(selectedFiles).forEach(file => {
            formData.append("files", file);
        })
        setLoading(true);
        let result = await getSelectedFileNameTabsAPI(formData);
        setLoading(false);
        if (result?.isSuccess) {

            let fileDetails = Object.keys(result.data).map(fileName => {
                return {
                    fileName: fileName,
                    sheetNames: result.data[fileName]
                }
            });
            setSelectedFileDetails(fileDetails);
            setState({
                ...state,
                files: fileSelected
            })
        } else {
            setSelectedFileDetails([]);
            showToast(result.message || "Error while getting file names", "error")
        }
    }

    const deleteFile = (index) => {
        let files = [...selectedFileDetails];
        files.splice(index, 1);
        setSelectedFileDetails(files);
    }

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                title={data && data.id ? "Edit Import" : "Add Import"}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <Grid container direction="column" spacing={2} className="firm-configure-dialog">
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Select
                                    className="react-select"
                                    // isClearable={true}
                                    placeholder="Firm"
                                    value={state.lawFirmCode}
                                    onChange={(data) =>
                                        updateSearchFiled("lawFirmCode", data)
                                    }
                                    isDisabled={data && data.id ? true : false}
                                    options={firmList}
                                />
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Select
                                    className="react-select"
                                    placeholder="Type"
                                    value={state.type}
                                    onChange={(data) =>
                                        updateSearchFiled("type", data)
                                    }
                                    options={Object.keys(FIRM_CONFIGURE_TYPES).map(key => {
                                        return { label: FIRM_CONFIGURE_TYPES[key], value: key } as any
                                    })}
                                    isDisabled={data && data.id ? true : false}
                                />
                            </Grid>
                            <Grid item lg={5} md={5} sm={6} xs={12}>
                                <Select
                                    className="react-select"
                                    placeholder="Time Report"
                                    value={state.timeReport}
                                    onChange={(data) =>
                                        updateSearchFiled("timeReport", data)
                                    }
                                    options={FIRM_CONFIGURE__REPORT_TYPES.map(key => {
                                        return { label: key, value: key } as any
                                    })}
                                    isDisabled={data && data.id ? true : false}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <FormControl margin="normal" required fullWidth>
                                    <TextField
                                        onChange={(e) => {
                                            let event = { ...e }
                                            if (event.target["files"].length) {
                                                updateSearchFiled("files", e.target["files"]);
                                                getSelectedFileNameTabs(event.target["files"]);
                                            }
                                        }}
                                        id="import_file_upload"
                                        type="file"
                                        inputProps={{
                                            multiple: true,
                                            accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {selectedFileDetails.length > 0 && (
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        {selectedFileDetails.map((file, index) => (
                                            <Grid item lg={12} key={`files_selected_${index}`}>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Grid container spacing={2} justify="space-between" alignItems="center">
                                                            <Grid item>
                                                                <strong>{file.fileName}</strong>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Delete" placement="bottom">
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => deleteFile(index)}
                                                                    >
                                                                        <Close />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Select
                                                            className="react-select"
                                                            placeholder="Sheets"
                                                            value={state.selectedSheetsForFile[file.fileName] || []}
                                                            isMulti={true}
                                                            onChange={(data) => {
                                                                let details = { ...state };
                                                                details.selectedSheetsForFile[file.fileName] = data as IReactSelect[];
                                                                setState(details);
                                                                console.log(data);
                                                            }}
                                                            options={file.sheetNames.map(sheet => {
                                                                return { label: sheet, value: sheet } as any
                                                            })}
                                                            isDisabled={data && data.id ? true : false}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container justify="flex-end">
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        <Button
                                            className="w-100"
                                            color="secondary"
                                            variant="outlined"
                                            onClick={uploadDetails}
                                        >
                                            Upload
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CustomDialogDrawer>
        </React.Fragment>
    );
};

export default ManageImportDialog;
