import {
  Button,
  Grid,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {
  IFilter,
  IFirmDetails,
  ITimesheets,
  IKeyValue,
  IPerson,
} from "../../vm";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
  OpenInNew,
} from "mdi-material-ui";
import { useHistory, useLocation } from "react-router-dom";
import Filter from "../common/Filter";
import { ToastContext } from "../common/ToastProvider";
import { getAllProfessionalLevels } from "../../services/TimesheetService";
import {
  getExpensePersons,
  getAllExpenses,
  getExpensesFiltersCount,
} from "../../services/ExpenseService";

import Loading from "../common/Loading";
import { FirmContext } from "../common/FirmProvider";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import {
  getCeilValueForTotalCount,
  getFileName,
  getSubDomain,
  getToken,
  isUserAllowed,
  StyledTableCell,
  StyledTableRow,
} from "../../services/UtilService";
import {
  API_URL,
  styles,
  TIMESHEET_STATUS,
  TIME_ENTRY_KEY_WORDS,
  MAX_PAGINATION_OPTIONS,
} from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import ExportDialog from "../common/ExportDialog";
import moment from "moment";
import ToggleFilterSection from "../dashboard/ToggleFilter";

export interface ExpensesMainProps { }

const ExpensesMain: React.FC<ExpensesMainProps> = () => {
  const history = useHistory();
  const location = useLocation();
  let searchObj: any = {};
  if (location.state) {
    let searchState = { ...(location.state as any) };
    if (searchState.status) {
      searchObj.statuses = [searchState.status];
    }
    if (searchState.statuses) {
      searchObj.statuses = searchState.statuses;
    }
    // if (searchState.professionalLevel) {
    //   searchObj.professionalLevel = [searchState.professionalLevel];
    // }
    // if (searchState.categoryCode) {
    //   searchObj.categoryCode = [searchState.categoryCode];
    // }
    if (searchState.startDate) {
      searchObj.startDate = searchState.startDate;
    }
    if (searchState.endDate) {
      searchObj.endDate = searchState.endDate;
    }
    if (searchState.selectedFirm?.length > 0) {
      searchObj.firms = searchState.selectedFirm;
    }
    if (searchState.professionalLevel?.length > 0) {
      searchObj.professionalLevel = searchState.professionalLevel;
    }
    if (searchState.categoryCode?.length > 0) {
      searchObj.categoryCode = searchState.categoryCode;
    }
    if (searchState.workType?.length > 0) {
      searchObj.workType = searchState.workType;
    }
    if (searchState.keyword?.length > 0) {
      searchObj.keyword = searchState.keyword;
    }
    if (searchState.min) {
      searchObj.min = searchState.min;
    }
    if (searchState.max) {
      searchObj.max = searchState.max;
    }
  }
  const [state, setState] = React.useState({
    expensesList: [],
    search: {
      from: 0,
      size: 10,
      statuses: searchObj?.statuses ? searchObj.statuses : [],
      firms: searchObj.firms ? searchObj.firms : [],
      persons: searchObj.persons ? searchObj.persons : [],
      startDate: searchObj.startDate ? searchObj.startDate : undefined,
      endDate: searchObj.endDate ? searchObj.endDate : undefined,
      sortBy: "",
      sortOrder: "asc",
      keyword: searchObj?.keyword || [],
      min: searchObj.min || "",
      max: searchObj.max || "",
      categoryCode: searchObj?.categoryCode ? searchObj.categoryCode : [],
      workType: searchObj?.workType ? searchObj.workType : [],
      professionalLevel: searchObj?.professionalLevel
        ? searchObj.professionalLevel
        : [],
    },
    isAllSelected: false,
    totalExpensesCount: 0,
    filterData: [],
    rowsList: [10],
  } as {
    search: {
      from: number;
      size: number;
      firms: string[];
      persons: string[];
      statuses: string[];
      startDate?: Date;
      endDate?: Date;
      sortBy?:
      | ""
      | "firm"
      | "person"
      | "new"
      | "reviewed"
      | "adjusted"
      | "attention"
      | "approved"
      | "submitted"
      | "granted"
      | "not-granted";
      sortOrder?: "asc" | "desc";
      keyword: string[];
      categoryCode: any;
      workType: any;
      professionalLevel: any;
      min?: any;
      max?: any;
    };
    expensesList: ITimesheets[];
    totalExpensesCount: number;
    isAllSelected: boolean;
    filterData: IFilter[];
    rowsList: number[];
    options: { value: string; label: string }[];
  });

  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isFilterOpen, setFilterOpen] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [firmsDic, setFirmsDic] = React.useState({} as IKeyValue<IFirmDetails>);

  const [personsDic, setPersonsDic] = React.useState({} as IKeyValue<IPerson>);
  const { showToast } = React.useContext(ToastContext);
  const firmProvider = React.useContext(FirmContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [initialFirms, setInitialFirms] = React.useState(
    {} as IKeyValue<IFirmDetails>
  );
  const [
    initialProfessionalLevels,
    setInitialProfessionalLevels,
  ] = React.useState({} as IKeyValue<{ label: string; value: string }>);
  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [, setProfessionalLevels] = React.useState([]);
  const [, setCategories] = React.useState([]);
  const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] = React.useState({
    minDate: undefined,
    maxDate: undefined
  } as {
    minDate?: Date;
    maxDate?: Date
  });

  React.useEffect(() => {
    if (isUserAllowed()) {
      getAllRecordsFromStarting();
    } else {
      history.push("/not-authorized");
    }
  }, []);

  const getAllRecordsFromStarting = async (search?) => {
    setLoading(true);
    let professionalLevelsDic = {};
    let categoriesCodeDic = await getAllCategoriesList();
    let workTypesDic = await getAllWorkTypesList();
    await getUserFirms({ professionalLevelsDic, categoriesCodeDic, search, workTypesDic });
    setLoading(false);
  };

  const getAllCategoriesList = async () => {
    let categories = firmProvider.categoriesList;
    if (categories.length === 0) {
      categories = await firmProvider.getCategoriesListForExpenses();
    }
    let categoryCodeDic = {};
    let data = categories.map((x) => {
      return { label: x.expenseCategoryName, value: x.expenseCategoryCode };
    });
    categoryCodeDic = data.reduce((acc: any, ele) => {
      acc[ele.value] = ele;
      return acc;
    }, {});
    setInitialCategoryCodes(categoryCodeDic);
    setCategories(data || []);
    return categoryCodeDic;
  };

  const getAllWorkTypesList = async () => {
    let workTypesList = firmProvider.workTypesList;
    if (workTypesList.length === 0) {
      workTypesList = await firmProvider.getWorkTypesListForExpenses();
    }
    let workTypesDic = {};
    let data = workTypesList.map((x) => {
      return { label: x.workTypeLongDescription, value: x.workTypeCode };
    });
    workTypesDic = data.reduce((acc: any, ele) => {
      acc[ele.value] = ele;
      return acc;
    }, {});
    setInitialWorkTypes(workTypesDic);
    return workTypesDic;;
  };

  const getExpensesFilters = async (
    search?: any,
    firmsDic?: any,
    personsDic?: any,
    changedProperty?: any,
    professionCategoriesData?: any
  ) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let initialFirmsTemp = { ...firmsDic };
    if (firmsDic) {
      initialFirmsTemp = {
        ...initialFirms,
        ...firmsDic,
      };
    }
    let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
    if (professionCategoriesData?.professionalLevelsDic) {
      initialProfessionalLevelTemp = {
        ...initialProfessionalLevels,
        ...professionCategoriesData.professionalLevelsDic,
      };
    }
    let initialCategoryCodeTemp = { ...initialCategoryCodes };
    if (professionCategoriesData?.categoriesCodeDic) {
      initialCategoryCodeTemp = {
        ...initialCategoryCodes,
        ...professionCategoriesData.categoriesCodeDic,
      };
    }
    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (professionCategoriesData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...professionCategoriesData.workTypesDic,
      };
    }
    let searchForFilter = { ...tempSearch };
    let keyWords = [...keyWordsList];
    // if (changedProperty) {
    //   switch (changedProperty) {
    //     case "firmCounts":
    //       searchForFilter.statuses = [];
    //       break;
    //     case "statusCounts":
    //       searchForFilter.firms = [];
    //       break;
    //     default:
    //       break;
    //   }
    // }
    delete tempSearch.size;
    delete tempSearch.from;
    setLoading(true);
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {
        firmCounts: {},
        statusCounts: {},
        categoryCodeCounts: {},
        workTypeCounts: {},
        keywordCounts: {},
      } as any,
    };
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      result = await getExpensesFiltersCount(searchForFilter);
    }
    setLoading(false);
    if (result?.isSuccess) {
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          categoryCodeCounts: "categoryCode",
          workTypeCounts: "workType",
          firmCounts: "firmCode",
          keywordCounts: "keyword",
          statusCounts: "status",
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            categoryCodeCounts: {},
            workTypeCounts: {},
            firmCounts: {},
            keywordCounts: {},
            statusCounts: {},
          }
        );
        // if(result.data?.startDate){
        //   tempSearch.startDate = result.data.startDate
        // }
        // if(result.data?.endDate){
        //   tempSearch.endDate = result.data.endDate
        // }
        setFilterCountsDic(filterCountDic);
      }
      console.log("result", result.data);
      let data = { ...result.data };
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });

      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      if (!tempSearch.max && data?.maxTimeSpentHours) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      let filter: IFilter[] = [];
      let filterOrder = [
        "workTypeCounts",
        "time",
        "statusCounts",
        "firmCounts",
        "categoryCodeCounts",
        "keyword",
        "min-max",
      ];
      let customFilters = ["time", "keyword", "min-max"];
      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time").isHidden) ||
                  false,
                items: [
                  {
                    type: 'date-range',
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: 'selection'
                      }
                    ],
                    label: ""
                  }
                ]
                // items: [
                //   {
                //     value: tempSearch.startDate,
                //     type: "date",
                //     label: "Start Date",
                //     name: "startDate",
                //   },
                //   {
                //     value: tempSearch.endDate,
                //     type: "date",
                //     label: "End Date",
                //     name: "endDate",
                //   },
                // ],
              });
              break;
            case "keyword":
              break;
            case "min-max":
              filter.push({
                header: "Expense Amount Greater Than",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max").isHidden) ||
                  false,
                items: [
                  {
                    label: "Amount",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  }
                ],
              });
              break;
            default:
              break;
          }
        }
        else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).map((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses?tempSearch.statuses.includes(eds):false,
                  label: TIMESHEET_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                });
              });
              break;
            case "firmCounts":
              a.header = "Firm";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let firmCounts = data["firmCounts"] || [];
              Object.keys(initialFirmsTemp).map((element) => {
                a.items.push({
                  label: firmsDic[element]
                    ? firmsDic[element].lawFirmShortName
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.firms.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : firmCounts.find((e) => e.firmCode === element)?.count ||
                        0
                      : filterCountDic?.["firmCounts"]?.[element]?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.firms?tempSearch.firms.includes(element):false,
                });
              });
              // sorting based on label i.e. firm short name
              a.items.sort((a, b) => a.label > b.label ? 1 : -1);
              break;
            case "professionalCounts":
              a.header = "Professional Level";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let professionalCounts = data["professionalCounts"] || [];
              Object.keys(initialProfessionalLevelTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.professionalLevelsDic[element]
                    ? professionCategoriesData.professionalLevelsDic[element]
                      .label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.professionalLevel.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === element)?.count || 0
                        : professionalCounts.find(
                          (e) => e.professionalLevel === element
                        )?.count || 0
                      : filterCountDic?.["professionalCounts"]?.[element]
                        ?.count || 0,
                  name: element,
                  isSelected: tempSearch.professionalLevel?tempSearch.professionalLevel.includes(element):false,
                });
              });
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.workTypesDic[element]
                    ? professionCategoriesData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : workTypeCounts.find(
                          (e) => e.workType === element
                        )?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.workType?tempSearch.workType.includes(element):false,
                });
              });
              break;
            case "categoryCodeCounts":
              a.header = "Expense Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let categoryCodeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.categoriesCodeDic[element]
                    ? professionCategoriesData.categoriesCodeDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.categoryCode.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : categoryCodeCounts.find(
                          (e) => e.categoryCode === element
                        )?.count || 0
                      : filterCountDic?.["categoryCodeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.categoryCode?tempSearch.categoryCode.includes(element):false,
                });
              });
              break;
            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });

      // filter.push({
      //   header: "Keywords",
      //   name: "keyword",
      //   isHidden:
      //     (filterData.length > 0 &&
      //       filterData.find((e) => e.name === "keyword").isHidden) ||
      //     false,
      //   items: keyWords.map((ele) => {
      //     return {
      //       isSelected: tempSearch.keyword.includes(ele.value),
      //       label: ele.label,
      //       type: "checkbox",
      //       name: ele.value,
      //     };
      //   }),
      // });

      setCountsLoaded(true);
      setFilterData(filter);
      // setInitialFirms(initialFirmsTemp);
      // setInitialProfessionalLevels(initialProfessionalLevelTemp);
      // setInitialCategoryCodes(initialCategoryCodeTemp);
      tempSearch.from = 0;
      tempSearch.size = 10;

      await getExpensesList(tempSearch);
    } else {
      showToast(
        result.message || "Error while fetching expenses counts",
        "error"
      );
    }
  };

  const getUserFirms = async (professionCategoriesData) => {
    let userFirms = firmProvider.userFirms;
    if (userFirms.length === 0) {
      userFirms = await firmProvider.getFirmsList();
    }
    let firmsDic = userFirms.reduce((acc: any, ele) => {
      acc[ele.lawFirmCode] = ele;
      return acc;
    }, {});
    setFirmsDic(firmsDic);
    setInitialFirms(firmsDic);
    // await getPersonsList(firmsDic, professionCategoriesData);
    await getExpensesFilters(
      professionCategoriesData?.search || undefined,
      firmsDic,
      {},
      undefined,
      professionCategoriesData
    );
  };

  const getExpensesList = async (search?: any) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let searchObj = { ...tempSearch };
    setLoading(true);
    const result = await getAllExpenses(searchObj);
    if (result?.isSuccess) {
      let menuItems = [];
      for (let index = 10; index <= result.data.count; index = index + 10) {
        menuItems.push(index);
      }
      if (menuItems[menuItems.length - 1] < result.data.count) {
        menuItems.push(getCeilValueForTotalCount(result.data.count));
        // menuItems.push(result.data.count);
      }
      if (menuItems.length > MAX_PAGINATION_OPTIONS) {
        menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
      }
      // else {
      //   let totalCount = menuItems.pop();
      //   if (totalCount) {
      //     menuItems.unshift({ label: "All", value: totalCount });
      //   }
      // }
      setState({
        ...state,
        totalExpensesCount: result.data.count,
        expensesList: [...result.data.expensesSummaryVMs],
        rowsList: [...menuItems],
        search: { ...state.search, ...tempSearch },
      });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching expenses",
        "error"
      );
    }
    setLoading(false);
  };

  const onSearch = async (searchObj?: any) => {
    let tempSearch = { ...state.search };
    if (searchObj) {
      tempSearch = { ...state.search, ...searchObj };
    }
    await getExpensesList(tempSearch);
  };

  const isExpenseSelected = () => {
    let selected = false;
    if (state.isAllSelected === true) {
      return true;
    } else {
      state.expensesList.forEach((ele) => {
        if (ele.isSelected === true) {
          selected = true;
        }
      });
      return selected;
    }
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    let search = { ...state.search };
    search.from = 0;
    let statusArray =
      data.find((ele) => ele.name === "statusCounts")?.items || [];
    let professionalLevelArray =
      data.find((ele) => ele.name === "professionalCounts")?.items || [];
    let categoryCodeArray =
      data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
    let workTypesArray =
      data.find((ele) => ele.name === "workTypeCounts")?.items || [];
    let personArray =
      data.find((ele) => ele.name === "personCounts")?.items || [];
    let firmArray = data.find((ele) => ele.name === "firmCounts")?.items || [];
    let timeArray = data.find((ele) => ele.name === "time")?.items || [];
    let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
    search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
    search.startDate =
      timeArray[0].value[0].startDate || undefined;
    // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
    search.endDate =
      timeArray[0].value[0].endDate || undefined;
    // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;
    search.statuses = statusArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.persons =
      personArray.length > 0
        ? personArray[0].selectedItems.length > 0
          ? personArray[0].selectedItems.map((ele) => ele.value)
          : []
        : [];
    search.firms = firmArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        if (e.name) {
          return e.name;
        }
      });
    search.professionalLevel = professionalLevelArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.categoryCode = categoryCodeArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.workType = workTypesArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    let keywordsArray = data.find((ele) => ele.name === "keyword")?.items || [];
    search.keyword = keywordsArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    await getExpensesFilters(search, firmsDic, personsDic, changedProperty, {
      professionalLevelsDic: { ...initialProfessionalLevels },
      categoriesCodeDic: { ...initialCategoryCodes },
      workTypesDic: { ...initialWorkTypes }
    });

    console.log("search", search);
  };

  const setNextExpenses = (data: any) => {
    let dataToSet = JSON.stringify(data);
    localStorage.setItem("nextExpenses", dataToSet);
  };

  const viewExpense = async (expense: ITimesheets, status?: string) => {
    let data = {
      startDate: state.search.startDate,
      endDate: state.search.endDate,
      lawFirmCode: expense.lawFirmCode,
      personId: expense.personId,
      status: status ? [status] : state.search.statuses,
      professionalLevel: state.search.professionalLevel,
      categoryCode: state.search.categoryCode,
      workType: state.search.workType,
      keyword: state.search.keyword,
      min: state.search.min,
      max: state.search.max,
      selectedFirms: state.search.firms,
      selectedPersons: state.search.persons,
    };
    await setNextExpenses([data]);
    history.push(`/expenses/expense`);
  };

  const viewSelectedExpenses = async () => {
    let expenses = [...state.expensesList];
    let selectedExpenses: any = [];
    expenses.forEach((ele) => {
      if (ele.isSelected === true) {
        selectedExpenses.push({
          startDate: state.search.startDate,
          endDate: state.search.endDate,
          lawFirmCode: ele.lawFirmCode,
          personId: ele.personId,
        });
      }
    });
    await setNextExpenses(selectedExpenses);
    history.push(`/expenses/expense`);
  };

  const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className="d-flex">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <ChevronDoubleLeft />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <ChevronRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <ChevronDoubleRight />
        </IconButton>
      </div>
    );
  };

  const onSort = async (sortBy) => {
    let search = { ...state.search };
    if (sortBy === search.sortBy) {
      search.sortOrder = search.sortOrder === "asc" ? "desc" : "asc";
    } else {
      search.sortBy = sortBy;
      search.sortOrder = "asc";
    }
    search.from = 0;
    await getExpensesList(search);
  };

  const handleDownload = async (isZip?: boolean) => {
    let searchObj = { ...state.search };
    let obj = {
      searchQuery: {
        ...searchObj,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        firms: searchObj.firms,
        statuses: searchObj.statuses,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        keyword: searchObj.keyword,
        from: 0,
        size: state.totalExpensesCount,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        sortBy: searchObj.sortBy,
        sortOrder: searchObj.sortOrder,
        showMultipleBillingRates: false,
        isShowAll: true,
      },
      categoryList: searchObj.categoryCode.map((ele) => initialCategoryCodes[ele].label),
      workType: searchObj.workType,
      firmList: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
      isZip: isZip ? true : false,
      isSaveOnServer: false,
      localTime: moment().format("YYYYMMDDHHmmss")
    };
    let result = await fetch(`${API_URL}timesheets/generate-pdf/expense-summary`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/pdf",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "pdf", "case");
    // pdf.type === "application/zip"
    //   ? `Expenses Summary.zip`
    //   : `Expenses Summary.pdf`;
    a.click();
  };
  const handleDownloadAsCsv = async (isZip?: boolean) => {
    let searchObj = { ...state.search };
    let obj = {
      searchQuery: {
        ...searchObj,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        firms: searchObj.firms,
        statuses: searchObj.statuses,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        keyword: searchObj.keyword,
        from: 0,
        size: state.totalExpensesCount,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        sortBy: searchObj.sortBy,
        sortOrder: searchObj.sortOrder,
        showMultipleBillingRates: false,
        isShowAll: true,
      },
      categoryList: searchObj.categoryCode.map((ele) => initialCategoryCodes[ele].label),
      workType: searchObj.workType,
      firmList: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
      isZip: isZip ? true : false,
      isSaveOnServer: false,
      localTime: moment().format("YYYYMMDDHHmmss")
    };
    let result = await fetch(`${API_URL}timesheets/generate-csv/expense-summary`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "csv", "case");
    // pdf.type === "application/zip"
    //   ? `Expense Summary.zip`
    //   : `Expense Summary.csv`;
    a.click();
  };

  const handleOpenExportDialog = () => {
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpen(false);
  };
  const classes = styles();

  return (
    <React.Fragment>
      {isLoading && <Loading message={loadingMessage} />}
      <section className="p-16">
        <div className="text-center">
          <Typography variant="h5">Expenses Summary</Typography>
        </div>
        <br />
        <Grid className={`${classes.main8}`}>
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={2}
              className={`timesheet-filters-height ${isFilterOpen ? classes.filterOpenContainer : classes.filterCloseContainer}`}
            >
              {filterData.length > 0 && (
                <Filter
                  data={[...filterData]}
                  options={state.options || []}
                  onChange={(data: IFilter[], changedProperty?: string) => {
                    onFilterChange(data, changedProperty);
                  }}
                  dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                />
              )}
            </Grid>
          </Hidden>
          <ToggleFilterSection showFilterClass='toggle-filters-timesheet-container' classes={classes} toggleFilter={() => setFilterOpen(!isFilterOpen)} isFilterOpen={isFilterOpen} />
          <Grid className={`${isFilterOpen ? classes.mainOpenContainer : classes.mainCloseContainer}`}>

            <Grid container spacing={2} justify="flex-end">
              <Hidden lgUp>
                <Grid item>
                  {filterData.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setFilterDialogOpen(true)}
                    >
                      Filters
                    </Button>
                  )}
                </Grid>
              </Hidden>
              {state.expensesList && state.expensesList.length > 0 && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleOpenExportDialog();
                    }}
                  >
                    Export
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {isExpenseSelected() && (
                  <Grid container spacing={2} justify="flex-start">
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => viewSelectedExpenses()}
                      >
                        Open Selected Expenses
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <TableContainer className="timesheets-table-container moz-table-pb">
                  <Table stickyHeader size="small" className="custom-table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell>
                          <Checkbox
                            size="small"
                            checked={state.isAllSelected}
                            value={state.isAllSelected}
                            onClick={() => {
                              selectAll();
                            }}
                          />
                        </StyledTableCell> */}
                        <StyledTableCell sortDirection="desc">
                          <TableSortLabel
                            active={state.search.sortBy === "firm"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("firm");
                            }}
                          >
                            Firm
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TableSortLabel
                            active={state.search.sortBy === "new"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("new");
                            }}
                          >
                            New
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TableSortLabel
                            active={state.search.sortBy === "attention"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("attention");
                            }}
                          >
                            Attention
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TableSortLabel
                            active={state.search.sortBy === "reviewed"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("reviewed");
                            }}
                          >
                            Reviewed
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TableSortLabel
                            active={state.search.sortBy === "adjusted"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("adjusted");
                            }}
                          >
                            Adjusted
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TableSortLabel
                            active={state.search.sortBy === "approved"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("approved");
                            }}
                          >
                            Approved
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TableSortLabel
                            active={state.search.sortBy === "submitted"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("submitted");
                            }}
                          >
                            Submitted
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TableSortLabel
                            active={state.search.sortBy === "granted"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("granted");
                            }}
                          >
                            Granted
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TableSortLabel
                            active={state.search.sortBy === "not-granted"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("not-granted");
                            }}
                          >
                            Not Granted
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Actions
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.expensesList.length > 0 ? (
                        state.expensesList.map((ele, index: number) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              {firmsDic[ele.lawFirmCode]
                                ? firmsDic[ele.lawFirmCode].lawFirmShortName
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.newStatus > 0 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  align="center"
                                  component="a"
                                  color="primary"
                                  onClick={() => viewExpense(ele, "new")}
                                >
                                  {ele.newStatus || 0}
                                </Typography>
                              ) : (
                                ele.newStatus || 0
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.attentionStatus > 0 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  align="center"
                                  component="a"
                                  color="primary"
                                  onClick={() => viewExpense(ele, "attention")}
                                >
                                  {ele.attentionStatus || 0}
                                </Typography>
                              ) : (
                                ele.attentionStatus || 0
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.reviewedStatus > 0 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  align="center"
                                  component="a"
                                  color="primary"
                                  onClick={() => viewExpense(ele, "reviewed")}
                                >
                                  {ele.reviewedStatus || 0}
                                </Typography>
                              ) : (
                                ele.reviewedStatus || 0
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.adjustedStatus > 0 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  align="center"
                                  component="a"
                                  color="primary"
                                  onClick={() => viewExpense(ele, "adjusted")}
                                >
                                  {ele.adjustedStatus || 0}
                                </Typography>
                              ) : (
                                ele.adjustedStatus || 0
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.approvedStatus > 0 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  align="center"
                                  component="a"
                                  color="primary"
                                  onClick={() => viewExpense(ele, "approved")}
                                >
                                  {ele.approvedStatus || 0}
                                </Typography>
                              ) : (
                                ele.approvedStatus || 0
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.submittedStatus > 0 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  align="center"
                                  component="a"
                                  color="primary"
                                  onClick={() => viewExpense(ele, "submitted")}
                                >
                                  {ele.submittedStatus || 0}
                                </Typography>
                              ) : (
                                ele.submittedStatus || 0
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.grantedStatus > 0 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  align="center"
                                  component="a"
                                  color="primary"
                                  onClick={() => viewExpense(ele, "granted")}
                                >
                                  {ele.grantedStatus || 0}
                                </Typography>
                              ) : (
                                ele.grantedStatus || 0
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.notGrantedStatus > 0 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  align="center"
                                  component="a"
                                  color="primary"
                                  onClick={() => viewExpense(ele, "not-granted")}
                                >
                                  {ele.notGrantedStatus || 0}
                                </Typography>
                              ) : (
                                ele.notGrantedStatus || 0
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Grid container spacing={1} justify="center">
                                <Grid item>
                                  <Tooltip
                                    title="View Detailed Report"
                                    placement="bottom"
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() => viewExpense(ele)}
                                    >
                                      <OpenInNew />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                {/* <Grid item>
                              <IconButton>
                                <DotsVertical />
                              </IconButton>
                            </Grid> */}
                                {/* <Grid item>
                              <IconButton>
                                <History />
                              </IconButton>
                            </Grid> */}
                                {/* <Grid item>
                              <IconButton>
                                <ExclamationThick />
                              </IconButton>
                            </Grid> */}
                              </Grid>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <React.Fragment>
                          {!isLoading && (
                            <TableRow>
                              <TableCell align="center" colSpan={10}>
                                {" "}
                                No expenses found{" "}
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={state.rowsList}
                  component="div"
                  count={state.totalExpensesCount}
                  rowsPerPage={state.search.size}
                  page={
                    state.search.from === 0
                      ? 0
                      : state.search.from / state.search.size
                  }
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={(event: any, newPage: number) => {
                    console.log("event", event);
                    console.log("newPage", newPage);
                    let search = { ...state.search };
                    search.from =
                      newPage === 0
                        ? 0
                        : (Number(newPage) *
                          state.search.size);
                    onSearch(search);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    let value = event.target.value;
                    let search = { ...state.search };
                    search.size = value ? Number(value) : 10;
                    search.from = 0;
                    onSearch(search);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
      {isExportDialogOpen && (
        <ExportDialog
          exportFor={'timesheetOrExpense'}
          handleDialogClose={(data?) => handleCloseExportDialog(data)}
        />
      )}
    </React.Fragment>
  );
};

export default ExpensesMain;
