import {
  IStandardAPIResponse,
  INote,
  INoteResponse,
  INoteEntriesResponse
} from "../vm";
import { httpClient } from "./UtilService";

export async function getAllNotes(
  searchObj: any
): Promise<IStandardAPIResponse<INoteResponse>> {
  try {
    let res = await httpClient<INoteResponse>(`notes`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function addNote(
  obj: any
): Promise<IStandardAPIResponse<INote>> {
  try {
    let res = await httpClient<INote>(
      `notes`,
      "PUT",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getNoteEntriesList(
  noteId: number, type: string
): Promise<IStandardAPIResponse<INoteEntriesResponse>> {
  try {
    let res = await httpClient<INoteEntriesResponse>(
      `notes/${noteId}`,
      "GET",
      { type }
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function deleteNote(id: number): Promise<IStandardAPIResponse<INote>> {
  try {
    let res = await httpClient<INote>(
      `notes/${id}`,
      "DELETE",
      undefined,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function addEntriesToNote(
  obj: any
): Promise<IStandardAPIResponse<INote>> {
  try {
    let res = await httpClient<INote>(
      `notes`,
      "PATCH",
      obj,
      false,
      true
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}