import React from "react";
import { IResource, ITimeFilter } from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Button,
    FormControl,
    Grid,
    TextField
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import CustomDrawer from "./CustomDrawer";
import { saveTimeFilter } from "../../services/FilterService";
export interface ISaveFilterDialogProps {
    data?: ITimeFilter;
    handleDialogClose: any;
    isSaveFilter: boolean;
    selectedFilters: any
    updateStatus: Function;
    isCommitteeReport?:boolean
}

const SaveFilterDialog: React.FC<ISaveFilterDialogProps> = ({
    data,
    handleDialogClose,
    isSaveFilter,
    selectedFilters,
    updateStatus,
    isCommitteeReport
}) => {
    // state
    const [state, setState] = React.useState({
        details: {
            name: "",
            ...data
        },
    } as {
        details: {
            name: string;
        };
    });

    const [isLoading, setLoading] = React.useState(false);

    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    const closeAddDialog = (data?: boolean) => {
        handleDialogClose(data);
    };


    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDrawer
                title={`${isCommitteeReport?'Save Report Configuration':(isSaveFilter ? "Save filter" : "Save as filter")}`}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <section className="p-24">

                    <Grid container direction="column" spacing={2}>
                        <Grid item lg={12} md={12} sm={12}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={state.details}
                                validate={(values) => {
                                    let errors: FormikErrors<{ name: string }> = {};
                                    if (!values.name || !values.name.replace(/\s+/g, "")) {
                                        errors.name = "Required";
                                    }
                                    return errors;
                                }}
                                onSubmit={async (values: any, { setSubmitting }) => {
                                    setLoading(true);
                                    let filtersSelected = JSON.parse(JSON.stringify(selectedFilters));
                                    delete filtersSelected.from;
                                    delete filtersSelected.size;
                                    let details = {
                                        ...values,
                                        name: values.name.trim(),
                                        filters: JSON.stringify(filtersSelected)
                                    }
                                    const result = await saveTimeFilter(details,isCommitteeReport);
                                    if (result?.isSuccess) {
                                        if (result?.isReadOnly && !isCommitteeReport) {
                                            showToast(result.message, "error");
                                            handleDialogClose();
                                            updateStatus(result.isReadOnly);
                                        } else {
                                            showToast(`${isCommitteeReport?'Report Config':'Filter'} saved successfully`, "success");
                                            handleDialogClose(true)
                                        }
                                    } else {
                                        showToast(result?.message || `Error while saving ${isCommitteeReport?'report config':'filter'}`, "error");
                                        setLoading(false);
                                    }
                                    setSubmitting(false);

                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    isSubmitting,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={12}>
                                                <FormControl margin="normal" required fullWidth>
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        label={isCommitteeReport?'Report Config Name':'Filter Name'}
                                                        onChange={handleChange}
                                                        error={
                                                            errors.name && touched.name ? true : false
                                                        }
                                                        helperText={
                                                            errors.name &&
                                                            touched.name &&
                                                            errors.name
                                                        }
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justify="flex-end">
                                                    <Grid item>
                                                        <Button variant="outlined"
                                                            onClick={() => handleDialogClose()}
                                                            color="primary"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button disabled={isSubmitting} variant="contained" type="submit" color="primary">
                                                            Save
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </section>

            </CustomDrawer>
        </React.Fragment >
    );
};

export default SaveFilterDialog;
