import React from "react";
import { IResource, IResourceAliasAssign } from "../../vm";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Typography
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import { getResourceDetailsAPI, removeAliasFromList } from "../../services/ResourceService";
import ReactSelect from "react-select";
import { RESOURCE_APPLICABLE_STATUS } from "../../Constant";
import AddAliasDialog from "./AddAliasDialog";
import "./resources.css"
import AddResourceAliasDetails from "./AddResourceAliasDetails";
export interface IAddResourceAliasProps {
    data?: IResource;
    handleDialogClose: any;
    confirmDialog: any;
    updateStatus: any
}

const DeleteResourceConfirmDialog: React.FC<IAddResourceAliasProps> = ({
    data,
    handleDialogClose,
    confirmDialog,
    updateStatus
}) => {
    // state
    const [state, setState] = React.useState({
        details: {
            resource: null,
            applicableStatues: [RESOURCE_APPLICABLE_STATUS[0]]
        },
        resourceDetails: undefined,
        manageEditAliasDialog: {
            isOpen: false,
            data: undefined,
        },

    } as {
        details: any;
        resourceDetails: IResource;
        manageEditAliasDialog: {
            isOpen: boolean;
            data?: any;
        };
    });

    React.useEffect(() => {
        getResourceDetails(data.resourceName, data.lawFirmCode);
    }, [])

    const [isLoading, setLoading] = React.useState(false);

    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    const closeAddDialog = (data?: boolean) => {
        handleDialogClose(data);
    };

    const getResourceDetails = async (resourceName, lawFirmCode) => {
        setLoading(true);
        let result = await getResourceDetailsAPI(resourceName, lawFirmCode);
        console.log(result);
        if (result?.isSuccess) {
            let details = result.data;
            setState({
                ...state,
                resourceDetails: {
                    ...details
                },
                manageEditAliasDialog: {
                    isOpen: false,
                    data: undefined,
                },
            });

        } else {
            showToast(result.message || "Error while getting resource details", "error")
        }
        setLoading(false)
    }

    const handleEditResource = (data) => {
        if (data) {
            setState({
                ...state,
                manageEditAliasDialog: {
                    isOpen: true,
                    data: state.resourceDetails,
                },
            });
        }
    }

    const handleAddDialogClose = (isUpdated?: boolean) => {
        if (isUpdated) {
            getResourceDetails(state.resourceDetails.resourceName, state.resourceDetails.lawFirmCode)
        } else {
            setState({
                ...state,
                manageEditAliasDialog: {
                    isOpen: false,
                    data: undefined,
                },
            });
        }
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                variant="medium"
                title={"Confirm Alias Details"}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <Grid container direction="column" spacing={2} className="firm-configure-dialog">
                    <Grid item lg={12} md={12} sm={12}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={state.details}
                            validate={(values) => {
                                let errors: FormikErrors<any> = {};
                                if (!values.applicableStatues || values.applicableStatues.length === 0) {
                                    errors["applicableStatues"] = "Required";
                                }
                                return errors;
                            }}
                            onSubmit={async (values: any, { setSubmitting }) => {
                                confirmDialog.show("Are you sure", "Do you want to remove alias?", async () => {
                                    setLoading(true);
                                    let obj: IResourceAliasAssign = {
                                        lawFirmCode: data.lawFirmCode,
                                        prevResourceName: data.previousResourceName,
                                        newResourceName: data.resourceAliasName,
                                        applicableStatues: values.applicableStatues.map(status => status.value).join("")
                                    }

                                    const result = await removeAliasFromList(obj);
                                    if (result?.isSuccess) {
                                        if (result?.isReadOnly) {
                                            showToast(result.message, "error");
                                            handleDialogClose()
                                            updateStatus(result.isReadOnly);
                                        } else {
                                            showToast("Alias deleted successfully", "success");
                                            handleDialogClose(true)
                                        }
                                    } else {
                                        showToast(result?.message || "Error while deleting alias", "error");
                                        setLoading(false);
                                    }
                                });
                                setSubmitting(false);

                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12}>
                                            <FormControl margin="normal" required fullWidth>
                                                <Typography variant="subtitle1">
                                                    <strong>Please select the status to which entries you want to update.</strong>
                                                </Typography>
                                                <br />
                                                <ReactSelect
                                                    className={`react-select ${errors && errors.applicableStatues ? 'react-select-field-error' : ''}`}
                                                    placeholder="Applicable Status"
                                                    value={values.applicableStatues}
                                                    onChange={(data) => {
                                                        if (data && data.length > 1) {
                                                            let isAllEntriesSelected = data.find(x => x.value === "12345");
                                                            if (isAllEntriesSelected) {
                                                                showToast("You cannot select all entries and others at a time", "error")
                                                            } else {
                                                                setFieldValue("applicableStatues", data);
                                                            }
                                                        } else {
                                                            setFieldValue("applicableStatues", data);
                                                        }
                                                    }}
                                                    isMulti
                                                    options={RESOURCE_APPLICABLE_STATUS}
                                                    error={
                                                        errors && errors.applicableStatues ? true : false
                                                    }
                                                />
                                                {errors && errors.applicableStatues && (
                                                    <FormHelperText className="text-danger">
                                                        {errors.applicableStatues}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        {state.resourceDetails && (
                                            <Grid item lg={12} className="resource-details-container">
                                                <AddResourceAliasDetails data={state.resourceDetails}
                                                    handleDialogClose={handleEditResource}
                                                    isNewResource={false}
                                                    isReadOnly={true}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} justify="flex-end">
                                                <Grid item>
                                                    {" "}
                                                    <Button variant="outlined"
                                                        onClick={() => handleDialogClose()}
                                                        color="primary"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" type="submit" color="primary">
                                                        Remove Alias
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </CustomDialogDrawer>
            {
                (state.manageEditAliasDialog.isOpen) && (
                    <AddAliasDialog
                        isNewResource={false}
                        data={{
                            ...state.manageEditAliasDialog.data,
                            professionalCharacterizationHistories: state.manageEditAliasDialog.data.professionalCharacterizationHistories && state.manageEditAliasDialog.data.professionalCharacterizationHistories.length > 0 ? state.manageEditAliasDialog.data.professionalCharacterizationHistories : [{
                                timeProfessionalCharacterizationCode: "",
                                timeProfessionalCharacterizationStartDate: undefined,
                                timeProfessionalCharacterizationEndDate: undefined,
                                timeProfessionalCharacterizationCurrentIndicator: "n"
                            }]
                        }}
                        handleDialogClose={(data?: any) => handleAddDialogClose(data)}
                        updateStatus={updateStatus}
                    />
                )
            }
        </React.Fragment >
    );
};

export default DeleteResourceConfirmDialog;
