import React from "react";
import { IConfirmDialog, IProfessionalCharacterization, IReactSelect, IResource } from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
    ,
} from "@material-ui/core";
import { FieldArray, Formik, FormikErrors } from "formik";
import { addNewAlias, getResourceDetailsAPI } from "../../services/ResourceService";
import ReactSelect from "react-select";
import moment from "moment";
import { getAllFirms, getAllProfessionalCharacterizationAPI } from "../../services/FirmsService";
import DatePicker from "react-date-picker";
import { Plus, TrashCanOutline } from "mdi-material-ui";
import "./resources.css";
import { RESOURCE_APPLICABLE_STATUS } from "../../Constant";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import ConfirmViewAliasDialog from "./ConfirmViewAliasDialog";
import { FirmContext } from "../common/FirmProvider";
export interface IAddResourceAliasFormProps extends IConfirmDialog {
    data?: IResource;
    handleDialogClose: any;
    isNewResource: boolean;
    isReadOnly?: boolean;
    handleViewAlias?: Function;
    updateStatus: Function;
}

const AddResourceAliasForm: React.FC<IAddResourceAliasFormProps> = ({
    data,
    handleDialogClose,
    isNewResource,
    isReadOnly,
    confirmDialog,
    handleViewAlias,
    updateStatus
}) => {
    // state
    const [state, setState] = React.useState({
        details: {
            lawFirmCode: "",
            lawFirmCodeDetails: undefined,
            nameSuffix: "",
            firstName: "",
            lastName: "",
            jobTitleClass: "",
            jobTitleListClass: "",
            professionalCharacterizationHistories: [{
                timeProfessionalCharacterizationCurrentIndicator: "n",
                timeProfessionalCharacterizationCode: "",
                timeProfessionalCharacterizationStartDate: undefined,
                timeProfessionalCharacterizationEndDate: undefined,
            }],
            partnerEligibleDate: undefined,
            contractTransitionDate: undefined,
            pscMemberCode: "",
            authorizedCbDiscDocRevProd: "",
            barAdmissionDate: undefined,
            applicableStatues: [RESOURCE_APPLICABLE_STATUS[0]],
            isViewAliasDialog: false,
            ...data
        },
        mangeViewAliasConfirmDialog: {
            isOpen: false,
            formData: undefined,
            formikSubmitMethod: undefined
        },

    } as {
        details: IResource;
        mangeViewAliasConfirmDialog: {
            isOpen: boolean,
            formData: IResource;
            formikSubmitMethod: Function
        },
    });
    const [firmList, setFirmList] = React.useState([]);
    const [professionalCharacterizationList, setProfessionalCharacterizationList] = React.useState([] as IProfessionalCharacterization[]);
    const [isLoading, setLoading] = React.useState(false);
    const [initialResourceData, setInitialResourceData] = React.useState({ ...data });
    const firmProvider = React.useContext(FirmContext);

    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    React.useEffect(() => {
        if (data) {
            setState({
                ...state,
                details: {
                    ...state.details,
                    ...data
                }
            });
            setInitialResourceData({ ...state.details, ...data });
        }
    }, [data]);

    React.useEffect(() => {
        const fetchFirms = async () => {
            setLoading(true);
            await getAllFirmsList(true);
            await getAllProfessionalCharacterization(true);
            if (data && data.resourceName && !data.professionalCharacterizationHistories) {
                await getResourceDetails(true);
            }
            setLoading(false);
        };
        fetchFirms();
    }, []);

    const getResourceDetails = async (isDefault = false) => {
        if (!isDefault) {
            setLoading(true);
        }
        let result = await getResourceDetailsAPI(data.resourceName, data.lawFirmCode);
        console.log(result);
        if (result?.isSuccess) {
            let details = result.data;
            if (!details.professionalCharacterizationHistories || details.professionalCharacterizationHistories.length === 0) {
                details.professionalCharacterizationHistories = [{
                    timeProfessionalCharacterizationCurrentIndicator: "n",
                    timeProfessionalCharacterizationCode: "",
                    timeProfessionalCharacterizationStartDate: undefined,
                    timeProfessionalCharacterizationEndDate: undefined,
                }]
            }
            setState({
                ...state,
                details: {
                    ...state.details,
                    ...details
                }
            });
            setInitialResourceData({
                ...state.details,
                ...details
            });
        } else {
            showToast(result.message || "Error while getting resource details", "error")
        }
        if (!isDefault) {
            setLoading(false)
        }
    }

    const getAllProfessionalCharacterization = async (isDefault = false) => {
        if (!isDefault) {
            setLoading(true)
        }
        let result = await getAllProfessionalCharacterizationAPI();
        console.log(result);
        if (result?.isSuccess) {
            setProfessionalCharacterizationList(result.data);

        } else {
            showToast(result.message || "Error while getting professional characterization list", "error")
        }
        if (!isDefault) {
            setLoading(false)
        }
    }

    const getAllFirmsList = async (isDefault = false) => {
        if (!isDefault) {
            setLoading(true)
        }
        let userFirms = firmProvider.userFirms;
        if (userFirms.length === 0) {
            userFirms = await firmProvider.getFirmsList();
        }
        let list: any = [];
        userFirms.forEach((ele) => {
            let firm = { value: ele.lawFirmCode, label: ele.lawFirmShortName };
            list.push(firm);
        });
        let details = state.details;
        if (details.lawFirmCode) {
            let selectedLawFirmCode = list.find(firm => firm.value === details.lawFirmCode)
            if (selectedLawFirmCode) {
                details.lawFirmCodeDetails = selectedLawFirmCode;
            }
        }
        setState({
            ...state,
            details: details
        });
        setInitialResourceData(details);
        setFirmList(list);

        // let result = await getAllFirms();
        // console.log(data);
        // if (result?.isSuccess) {
        //     let list: any = [];
        //     result.data.forEach((ele) => {
        //         let firm = { value: ele.lawFirmCode, label: ele.lawFirmShortName };
        //         list.push(firm);
        //     });
        //     let details = state.details;
        //     if (details.lawFirmCode) {
        //         let selectedLawFirmCode = list.find(firm => firm.value === details.lawFirmCode)
        //         if (selectedLawFirmCode) {
        //             details.lawFirmCodeDetails = selectedLawFirmCode;
        //         }
        //     }
        //     setState({
        //         ...state,
        //         details: details
        //     });
        //     setInitialResourceData(details);
        //     setFirmList(list);

        // } else {
        //     showToast(result.message || "Error while getting firms", "error")
        // }
        if (!isDefault) {
            setLoading(false)
        }
    };

    const saveResourceDetails = async (obj: IResource, values?: IResource) => {
        setLoading(true);
        let result = await addNewAlias(obj, isNewResource);
        setLoading(false);
        if (result?.isSuccess) {
            if (result?.isReadOnly) {
                showToast(result.message, "error");
                handleDialogClose();
                updateStatus(result.isReadOnly);
            } else {
                showToast(`Resource ${isNewResource ? 'added' : 'updated'} successfully`, "success");
                if (obj.isViewAliasDialog) {
                    setInitialResourceData({ ...initialResourceData, ...values });
                    handleViewAlias();
                } else {
                    handleDialogClose(true);
                }
            }
        } else {
            showToast(
                result.message || `Error while ${isNewResource ? 'adding' : 'updating'} resource`,
                "error"
            );
        }
        setState({
            ...state,
            mangeViewAliasConfirmDialog: {
                isOpen: false,
                formData: undefined,
                formikSubmitMethod: undefined,
            }
        })
        setLoading(false);
    }



    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <Grid container direction="column" spacing={2} className="firm-configure-dialog">
                <Grid item lg={12} md={12} sm={12}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={state.details}
                        validate={(values) => {
                            let errors: FormikErrors<IResource> = {};
                            if (!values.lawFirmCodeDetails) {
                                errors.lawFirmCode = "Required";
                            }

                            if (!values.firstName) {
                                errors.firstName = "Required";
                            }
                            if (!values.lastName) {
                                errors.lastName = "Required";
                            }
                            // if (!values.jobTitleClass) {
                            //     errors.jobTitleClass = "Required";
                            // }
                            // if (["E", "C"].indexOf(values.jobTitleClass) > -1) {
                            //     // if (!values.partnerEligibleDate) {
                            //     //     errors.partnerEligibleDate = "Required";
                            //     // }
                            //     if (!values.barAdmissionDate) {
                            //         errors.barAdmissionDate = "Required";
                            //     }
                            // }
                            if (values.barAdmissionDate) {
                                if (values.partnerEligibleDate) {
                                    if (moment(values.partnerEligibleDate, "YYYY-MM-DD").startOf("day") < moment(values.barAdmissionDate, "YYYY-MM-DD").startOf("day")) {
                                        errors.partnerEligibleDate = "Partner Eligible Date cannot be less than Admission Date"
                                    }
                                }
                                // if (values.contractTransitionDate) {
                                //     if (moment(values.contractTransitionDate, "YYYY-MM-DD").startOf("day") < moment(values.barAdmissionDate, "YYYY-MM-DD").startOf("day")) {
                                //         errors.contractTransitionDate = "Transition Date cannot be less than Admission Date"
                                //     }
                                // }
                            }
                            // if (!values.jobTitleClass) {
                            //     errors.jobTitleClass = "Required";
                            // }

                            let isErrorAssigned = false;
                            var dateErrorMessage = "Please check the dates";
                            if (values.professionalCharacterizationHistories.length > 0) {
                                let isAllProfessionalCharacterizationSelected = values.professionalCharacterizationHistories.find(x => !x.timeProfessionalCharacterizationCode);
                                let isAllProfessionalStartDateSelected = values.professionalCharacterizationHistories.find(x => !x.timeProfessionalCharacterizationStartDate);
                                let isAllProfessionalEndDateSelected = values.professionalCharacterizationHistories.find(x => !x.timeProfessionalCharacterizationEndDate && x.timeProfessionalCharacterizationCurrentIndicator === "n");
                                if (isAllProfessionalCharacterizationSelected) {
                                    isErrorAssigned = true;
                                    errors.jobTitleListClass = "Please select the Professional Characterization."
                                }
                                if (isAllProfessionalStartDateSelected && !isErrorAssigned) {
                                    isErrorAssigned = true;
                                    errors.jobTitleListClass = "Please select the start date."
                                }
                                if (isAllProfessionalEndDateSelected && !isErrorAssigned) {
                                    isErrorAssigned = true;
                                    errors.jobTitleListClass = "Please select the end date."
                                }
                                let historyDates = JSON.parse(JSON.stringify(values.professionalCharacterizationHistories)).map(x => {
                                    if (!x.timeProfessionalCharacterizationEndDate) {
                                        x.timeProfessionalCharacterizationEndDate = "9999-12-31"
                                    }
                                    return x;
                                });
                                if (historyDates.length > 1 && !isErrorAssigned) {
                                    historyDates.forEach((ele, index) => {
                                        let jobHistoryStart = moment(ele.timeProfessionalCharacterizationStartDate);
                                        let jobHistoryEnd;
                                        if (ele.timeProfessionalCharacterizationEndDate) {
                                            jobHistoryEnd = moment(ele.timeProfessionalCharacterizationEndDate);
                                        }
                                        for (var j = index + 1; j < historyDates.length; j++) {
                                            let jobToCheck = historyDates[j];
                                            if (jobHistoryStart.isBetween(jobToCheck.timeProfessionalCharacterizationStartDate, jobToCheck.timeProfessionalCharacterizationEndDate) && !isErrorAssigned) {
                                                isErrorAssigned = true;
                                                errors.jobTitleListClass = dateErrorMessage
                                            }
                                            if (!isErrorAssigned && jobHistoryEnd) {
                                                if (jobHistoryEnd.isBetween(jobToCheck.timeProfessionalCharacterizationStartDate, jobToCheck.timeProfessionalCharacterizationEndDate) && !isErrorAssigned) {
                                                    isErrorAssigned = true;
                                                    errors.jobTitleListClass = dateErrorMessage
                                                }
                                            }
                                            if (!isErrorAssigned && jobHistoryStart && jobHistoryEnd) {
                                                if (moment(jobToCheck.timeProfessionalCharacterizationStartDate).isBetween(jobHistoryStart, jobHistoryEnd)) {
                                                    isErrorAssigned = true;
                                                    errors.jobTitleListClass = dateErrorMessage
                                                }
                                                if (!isErrorAssigned && moment(jobToCheck.timeProfessionalCharacterizationEndDate).isBetween(jobHistoryStart, jobHistoryEnd)) {
                                                    isErrorAssigned = true;
                                                    errors.jobTitleListClass = dateErrorMessage
                                                }
                                            }
                                        }
                                    });
                                }
                            } else {
                                errors.jobTitleListClass = "At least one Professional Categorization is required";
                            }
                            let shouldSelectBarAdmissionDate = values.professionalCharacterizationHistories.find(history => ["E"].indexOf(history.timeProfessionalCharacterizationCode) > -1);
                            if (shouldSelectBarAdmissionDate && !values.barAdmissionDate) {
                                errors.barAdmissionDate = "Required";
                            }
                            if (!isNewResource) {
                                if ((data.partnerEligibleDate !== values.partnerEligibleDate || data.barAdmissionDate !== values.barAdmissionDate) && (!values.applicableStatues || values.applicableStatues.length === 0)) {
                                    errors.applicableStatues = "Please select applicable status";
                                }
                            }
                            return errors;
                        }}
                        onSubmit={async (values: IResource, { setSubmitting }) => {
                            // setLoading(true);
                            let obj = { ...values };
                            let presentIdex = obj.professionalCharacterizationHistories.findIndex(x => x.timeProfessionalCharacterizationCurrentIndicator === "y");
                            if (presentIdex > -1) {
                                obj.professionalCharacterizationHistories[presentIdex].timeProfessionalCharacterizationEndDate = undefined;
                            }
                            let jobTitleClass = "";
                            if (obj.professionalCharacterizationHistories && obj.professionalCharacterizationHistories.length > 0) {
                                obj.professionalCharacterizationHistories.forEach(jobTitle => {
                                    if (jobTitle.timeProfessionalCharacterizationStartDate) {
                                        jobTitle.timeProfessionalCharacterizationStartDate = moment(jobTitle.timeProfessionalCharacterizationStartDate).format("YYYY-MM-DD")
                                    }
                                    if (jobTitle.timeProfessionalCharacterizationEndDate) {
                                        jobTitle.timeProfessionalCharacterizationEndDate = moment(jobTitle.timeProfessionalCharacterizationEndDate).format("YYYY-MM-DD")
                                    }
                                    if (jobTitle.timeProfessionalCharacterizationCurrentIndicator === "y") {
                                        jobTitleClass = jobTitle.timeProfessionalCharacterizationCode
                                    }
                                })
                            }
                            if (!jobTitleClass) {
                                jobTitleClass = obj.professionalCharacterizationHistories[obj.professionalCharacterizationHistories.length - 1].timeProfessionalCharacterizationCode
                            }
                            obj.jobTitleClass = jobTitleClass;
                            ["barAdmissionDate", "partnerEligibleDate", "contractTransitionDate"].forEach(dateField => {
                                if (obj[dateField]) {
                                    obj[dateField] = moment(obj[dateField]).format("YYYY-MM-DD");
                                }
                            })
                            obj.lawFirmCode = obj.lawFirmCodeDetails.value;
                            obj.applicableStatues = (values.applicableStatues.map(x => x.value).join("") as any);
                            let shouldAdmissionDateSelect = values.professionalCharacterizationHistories.find(history => ["E", "C"].indexOf(history.timeProfessionalCharacterizationCode) > -1);
                            if (values.isViewAliasDialog) {
                                await saveResourceDetails(obj, values);
                                setSubmitting(false);
                            } else {
                                if (shouldAdmissionDateSelect && !obj.barAdmissionDate) {
                                    confirmDialog.show("Are you sure", "Do you want to continue with out admission date?", async () => {
                                        await saveResourceDetails(obj);
                                        setSubmitting(false);
                                    })
                                } else {
                                    await saveResourceDetails(obj);
                                    setSubmitting(false);
                                }
                            }

                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1}>
                                    {isReadOnly && (
                                        <Grid item xs={12}>
                                            <Grid container justify="flex-end">
                                                <Grid item>
                                                    <Button variant="outlined" type="button" color="primary" onClick={() => handleDialogClose(true)}>
                                                        Edit Details
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} className="position-relative">
                                        <InputLabel className="custom-react-select-label">
                                            Firm
                                        </InputLabel>
                                        <ReactSelect
                                            className={`react-select ${errors && errors.lawFirmCode && touched.lawFirmCodeDetails ? 'react-select-field-error' : ''}`}
                                            placeholder="Firm"
                                            value={values.lawFirmCodeDetails}
                                            onChange={(data) =>
                                                setFieldValue("lawFirmCodeDetails", data)
                                            }
                                            isDisabled={isReadOnly ? true : isNewResource ? false : true}
                                            options={firmList}
                                        />
                                        {errors && errors.lawFirmCode && touched.lawFirmCodeDetails && (
                                            <FormHelperText className="text-danger">
                                                {errors.lawFirmCode}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="firstName"
                                                name="firstName"
                                                type="text"
                                                label="First Name"
                                                disabled={isReadOnly}
                                                onChange={handleChange}
                                                error={
                                                    errors.firstName && touched.firstName ? true : false
                                                }
                                                helperText={
                                                    errors.firstName &&
                                                    touched.firstName &&
                                                    errors.firstName
                                                }
                                                onBlur={handleBlur}
                                                value={values.firstName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="lastName"
                                                name="lastName"
                                                type="text"
                                                label="Last Name"
                                                disabled={isReadOnly}
                                                onChange={handleChange}
                                                error={
                                                    errors.lastName && touched.lastName ? true : false
                                                }
                                                helperText={
                                                    errors.lastName &&
                                                    touched.lastName &&
                                                    errors.lastName
                                                }
                                                onBlur={handleBlur}
                                                value={values.lastName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="nameSuffix"
                                                name="nameSuffix"
                                                type="text"
                                                label="Name Suffix"
                                                disabled={isReadOnly}
                                                onChange={handleChange}
                                                error={
                                                    errors.nameSuffix && touched.nameSuffix ? true : false
                                                }
                                                helperText={
                                                    errors.nameSuffix &&
                                                    touched.nameSuffix &&
                                                    errors.nameSuffix
                                                }
                                                onBlur={handleBlur}
                                                value={values.nameSuffix}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-error-label">Professional Characterization(As Of 2016 July)</InputLabel>
                                            <Select
                                                label="Professional Characterization(As Of 2016 July)"
                                                name="jobTitleClass"
                                                value={values.jobTitleClass}
                                                onChange={handleChange}
                                                error={errors.jobTitleClass && touched.jobTitleClass ? true : false}
                                            >
                                                <MenuItem value=""></MenuItem>
                                                {professionalCharacterizationList.map(category =>
                                                    <MenuItem value={category.timeProfessionalCharacterizationCode}>{category.timeProfessionalCharacterizationName}</MenuItem>
                                                )}
                                            </Select>
                                            {errors.jobTitleClass && touched.jobTitleClass && (
                                                <FormHelperText className="text-danger">{errors.jobTitleClass}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item lg={12} md={12} sm={12} xs={12} className={`${errors.jobTitleListClass && touched.jobTitleListClass ? 'custom-error-field' : ''}`}>
                                        <FieldArray
                                            name="professionalCharacterizationHistories"
                                            render={({ insert, remove, push }) => (
                                                values.professionalCharacterizationHistories && values.professionalCharacterizationHistories.map((jobTitle, index) => (
                                                    <Grid container className="professional-characterization-container" spacing={1} alignItems="center">
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={jobTitle.timeProfessionalCharacterizationCurrentIndicator === "y" ? true : false}
                                                                        onChange={() => {
                                                                            let isAlreadySelected = values.professionalCharacterizationHistories[index].timeProfessionalCharacterizationCurrentIndicator === "y";
                                                                            let history = [...values.professionalCharacterizationHistories].map(x => {
                                                                                x.timeProfessionalCharacterizationCurrentIndicator = "n";
                                                                                return x;
                                                                            });
                                                                            if (!isAlreadySelected) {
                                                                                history[index].timeProfessionalCharacterizationCurrentIndicator = "y";
                                                                            } else {
                                                                                history[index].timeProfessionalCharacterizationEndDate = undefined;
                                                                            }
                                                                            setFieldValue("professionalCharacterizationHistories", history);
                                                                        }}
                                                                    />
                                                                }
                                                                label="Current"
                                                            />
                                                        </Grid>
                                                        <Grid item className="job-history-professional">
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel id="demo-simple-select-error-label">Professional Characterization</InputLabel>
                                                                <Select
                                                                    label="Professional Characterization"
                                                                    name={`professionalCharacterizationHistories.${index}.timeProfessionalCharacterizationCode`}
                                                                    value={jobTitle.timeProfessionalCharacterizationCode}
                                                                    disabled={isReadOnly}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        // if (index === 0) {
                                                                        //     setFieldValue("jobTitleClass", e.target.value)
                                                                        // }
                                                                    }}
                                                                >
                                                                    <MenuItem value=""></MenuItem>
                                                                    {professionalCharacterizationList.map(category =>
                                                                        <MenuItem value={category.timeProfessionalCharacterizationCode}>{category.timeProfessionalCharacterizationName}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item className="date-field">
                                                            <FormControl margin="normal" required fullWidth className="position-relative">
                                                                <InputLabel className="custom-react-select-label date-label" htmlFor="role">
                                                                    Start Date
                                                                </InputLabel>
                                                                <DatePicker
                                                                    format="MM/dd/yyyy"
                                                                    dayPlaceholder="DD"
                                                                    monthPlaceholder="MM"
                                                                    yearPlaceholder="YYYY"
                                                                    disabled={isReadOnly}
                                                                    maxDate={jobTitle.timeProfessionalCharacterizationEndDate ? moment(jobTitle.timeProfessionalCharacterizationEndDate).toDate() : undefined}
                                                                    className={`${jobTitle.timeProfessionalCharacterizationStartDate ? 'calendar-with-clear' : ''}`}
                                                                    value={jobTitle.timeProfessionalCharacterizationStartDate ? moment(jobTitle.timeProfessionalCharacterizationStartDate).toDate() : undefined}
                                                                    onChange={(newValue: any) => {
                                                                        setFieldValue(`professionalCharacterizationHistories.${index}.timeProfessionalCharacterizationStartDate`, newValue);
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item className="date-field">
                                                            {jobTitle.timeProfessionalCharacterizationCurrentIndicator === "y" ? (
                                                                <strong> - Present</strong>
                                                            ) : (
                                                                <FormControl margin="normal" required fullWidth className="position-relative">
                                                                    <InputLabel className="custom-react-select-label date-label" htmlFor="role">
                                                                        End Date
                                                                    </InputLabel>
                                                                    <DatePicker
                                                                        format="MM/dd/yyyy"
                                                                        dayPlaceholder="DD"
                                                                        monthPlaceholder="MM"
                                                                        yearPlaceholder="YYYY"
                                                                        minDate={jobTitle.timeProfessionalCharacterizationStartDate ? moment(jobTitle.timeProfessionalCharacterizationStartDate).toDate() : undefined}
                                                                        disabled={isReadOnly}
                                                                        className={`${jobTitle.timeProfessionalCharacterizationEndDate ? 'calendar-with-clear' : ''}`}
                                                                        value={jobTitle.timeProfessionalCharacterizationEndDate ? moment(jobTitle.timeProfessionalCharacterizationEndDate).toDate() : undefined}
                                                                        onChange={(newValue: any) => {
                                                                            setFieldValue(`professionalCharacterizationHistories.${index}.timeProfessionalCharacterizationEndDate`, newValue);
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        </Grid>
                                                        {!isReadOnly && (

                                                            <Grid item className="job-history-action">
                                                                <Tooltip title="Add" placement="bottom">
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() =>
                                                                            push({
                                                                                timeProfessionalCharacterizationCurrentIndicator: "n",
                                                                                timeProfessionalCharacterizationCode: "",
                                                                                timeProfessionalCharacterizationStartDate: undefined,
                                                                                timeProfessionalCharacterizationEndDate: undefined,
                                                                            })
                                                                        }
                                                                    >
                                                                        <Plus />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {values.professionalCharacterizationHistories.length > 1 && (
                                                                    <Tooltip title="Delete" placement="bottom">
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() =>
                                                                                remove(index)
                                                                            }
                                                                        >
                                                                            <TrashCanOutline />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                )
                                                ))}
                                        />
                                    </Grid>
                                    <Grid item lg={12}>
                                        {errors.jobTitleListClass && touched.jobTitleListClass && (
                                            <FormHelperText className="text-danger">{errors.jobTitleListClass}</FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl margin="normal" fullWidth className="position-relative">
                                            <InputLabel className="custom-react-select-label date-label" htmlFor="role">
                                                Admission Date
                                            </InputLabel>
                                            <DatePicker
                                                className={`${values.barAdmissionDate ? 'calendar-with-clear' : ''} ${errors.barAdmissionDate && touched.barAdmissionDate ? 'react-date-error' : ''}`}
                                                format="MM/dd/yyyy"
                                                dayPlaceholder="DD"
                                                monthPlaceholder="MM"
                                                yearPlaceholder="YYYY"
                                                value={values.barAdmissionDate ? moment(values.barAdmissionDate).toDate() : undefined}
                                                onChange={(selectedDate: any) => {
                                                    if (!selectedDate) {
                                                        setFieldValue("partnerEligibleDate", "", true);
                                                        // setFieldValue("contractTransitionDate", "");
                                                        let ele: any = document.getElementById("partnerEligibleDate");
                                                        if (ele) {
                                                            ele.value = ""
                                                        }
                                                    }
                                                    setFieldValue("barAdmissionDate", selectedDate);
                                                }}
                                            />
                                            {errors.barAdmissionDate && touched.barAdmissionDate && (
                                                <FormHelperText className="text-danger">{errors.barAdmissionDate}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    {!isNewResource && (
                                        <Grid item lg={12}>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel className="custom-react-select-label react-select-label">
                                                    Applicable Status
                                                </InputLabel>
                                                <ReactSelect
                                                    className={`react-select ${errors && errors.applicableStatues ? 'react-select-field-error' : ''}`}
                                                    placeholder="Applicable Status"
                                                    value={values.applicableStatues}
                                                    onChange={(data: IReactSelect[]) => {
                                                        if (data && data.length > 1) {
                                                            let isAllEntriesSelected = data.find(x => x.value === "12345");
                                                            if (isAllEntriesSelected) {
                                                                showToast("You cannot select all entries and others at a time", "error")
                                                            } else {
                                                                setFieldValue("applicableStatues", data);
                                                            }
                                                        } else {
                                                            setFieldValue("applicableStatues", data);
                                                        }
                                                    }}
                                                    isMulti={true}
                                                    options={RESOURCE_APPLICABLE_STATUS}
                                                    error={
                                                        errors && errors.applicableStatues ? true : false
                                                    }
                                                />
                                                {errors && errors.applicableStatues && (
                                                    <FormHelperText className="text-danger">
                                                        {errors.applicableStatues}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl margin="normal" required fullWidth className="position-relative">
                                            <InputLabel className="custom-react-select-label date-label" htmlFor="role">
                                                Partner Eligible Date
                                            </InputLabel>
                                            <DatePicker
                                                format="MM/dd/yyyy"
                                                dayPlaceholder="DD"
                                                monthPlaceholder="MM"
                                                yearPlaceholder="YYYY"
                                                className={`${values.partnerEligibleDate ? 'calendar-with-clear' : ''}  ${errors.partnerEligibleDate && touched.partnerEligibleDate ? 'react-date-error' : ''}`}
                                                value={values.partnerEligibleDate ? moment(values.partnerEligibleDate).toDate() : undefined}
                                                onChange={(newValue: any) => {
                                                    setFieldValue("partnerEligibleDate", newValue);
                                                }}
                                            />
                                            {errors.partnerEligibleDate && touched.partnerEligibleDate && (
                                                <FormHelperText className="text-danger">{errors.partnerEligibleDate}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl margin="normal" required fullWidth className="position-relative">
                                            <InputLabel className="custom-react-select-label date-label" htmlFor="role">
                                                Transition Date
                                            </InputLabel>
                                            <DatePicker
                                                format="MM/dd/yyyy"
                                                dayPlaceholder="DD"
                                                monthPlaceholder="MM"
                                                yearPlaceholder="YYYY"
                                                className={`${values.contractTransitionDate?'calendar-with-clear':''} ${errors.contractTransitionDate && touched.contractTransitionDate ? 'react-date-error' : ''}`}
                                                value={values.contractTransitionDate ? moment(values.contractTransitionDate).toDate() : undefined}
                                                onChange={(newValue: any) => {
                                                    setFieldValue("contractTransitionDate", newValue);
                                                }}
                                            />
                                            {errors.contractTransitionDate && touched.contractTransitionDate && (
                                                <FormHelperText className="text-danger">{errors.contractTransitionDate}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel>
                                                Committee Membership
                                            </InputLabel>
                                            <Select
                                                value={values.pscMemberCode}
                                                label="Committee Membership"
                                                name="pscMemberCode"
                                                disabled={isReadOnly}
                                                onChange={handleChange}
                                                error={errors.pscMemberCode ? true : false}
                                            >
                                                <MenuItem value="">N/A</MenuItem>
                                                <MenuItem value="CATPSC">CATPSC</MenuItem>
                                                <MenuItem value="IATPSC">IATPSC</MenuItem>
                                                <MenuItem value="Common Benefit">Common Benefit</MenuItem>
                                            </Select>
                                            {errors.pscMemberCode && touched.pscMemberCode && (
                                                <FormHelperText className="text-danger">{errors.pscMemberCode}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel>
                                                Authorized Doc Review/Production
                                            </InputLabel>
                                            <Select
                                                value={values.authorizedCbDiscDocRevProd}
                                                label="Authorized Doc Review/Production"
                                                name="authorizedCbDiscDocRevProd"
                                                disabled={isReadOnly}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="">N/A</MenuItem>
                                                <MenuItem value="Y">Yes</MenuItem>
                                                <MenuItem value="N">No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item lg={12} md={12} sm={12} xs={12} className="position-relative">
                                            <br/>
                                            <InputLabel className="custom-react-select-label">
                                                Admission State Cb
                                            </InputLabel>
                                            <ReactSelect
                                               className={`react-select ${errors && errors.barAdmissionState ? 'react-select-field-error' : ''}`}
                                                placeholder="Admission State Cb"
                                                value={values.barAdmissionStateDetails}
                                                onChange={(data) =>
                                                    setFieldValue("barAdmissionStateDetails", data)
                                                }
                                                options={RESOURCE_STATE.map(state=>{
                                                    return { label:state, value: state }
                                                })}
                                            />
                                            {errors && errors.barAdmissionState && (
                                                <FormHelperText className="text-danger">
                                                    {errors.barAdmissionState}
                                                </FormHelperText>
                                            )}
                                        </Grid> */}
                                    {!isReadOnly && (
                                        <Grid item xs={12}>
                                            <br />
                                            <Grid container spacing={2} justify="flex-end">
                                                {!isNewResource && (
                                                    <Grid item>
                                                        <Button type="button" variant="outlined"
                                                            onClick={() => {
                                                                setFieldValue("isViewAliasDialog", true);
                                                                if (JSON.stringify(values) === JSON.stringify(initialResourceData)) {
                                                                    handleViewAlias();
                                                                } else {
                                                                    setState({
                                                                        ...state,
                                                                        mangeViewAliasConfirmDialog: {
                                                                            isOpen: true,
                                                                            formData: values,
                                                                            formikSubmitMethod: handleSubmit
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                            color="primary"
                                                        >
                                                            View Alias
                                                        </Button>
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Button type="button" variant="outlined"
                                                        onClick={() => handleDialogClose()}
                                                        color="primary"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" type="button" color="primary" disabled={isSubmitting} onClick={() => {
                                                        setFieldValue("isViewAliasDialog", false);
                                                        handleSubmit();
                                                    }}>
                                                        {isNewResource ? "Add" : "Update"}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
            {(state.mangeViewAliasConfirmDialog.isOpen) && (
                <ConfirmViewAliasDialog handleDialogClose={(async (data) => {
                    if (data) {
                        state.mangeViewAliasConfirmDialog.formikSubmitMethod();
                    }
                    else if (data === false) {
                        handleViewAlias();
                    }
                    setState({
                        ...state,
                        mangeViewAliasConfirmDialog: {
                            isOpen: false,
                            formData: undefined,
                            formikSubmitMethod: undefined
                        }
                    })
                })} />
            )}
        </React.Fragment >
    );
};

export default withConfirmDialogContext(AddResourceAliasForm);
