import React from "react";
import { IResource } from "../../vm";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import "./resources.css";
import AddResourceAliasForm from "./AddResourceAliasForm";
export interface IAddAliasDialogProps {
    data?: IResource;
    handleDialogClose: any;
    isNewResource: boolean;
    isReadOnly?: boolean;
    handleViewAlias?: Function;
    updateStatus: Function
}

const AddAliasDialog: React.FC<IAddAliasDialogProps> = ({
    data,
    handleDialogClose,
    isNewResource,
    isReadOnly,
    handleViewAlias,
    updateStatus
}) => {

    const closeAddDialog = (data?: boolean) => {
        handleDialogClose(data);
    };



    return (
        <React.Fragment>
            <CustomDialogDrawer
                variant="medium"
                title={`${isNewResource ? "Add" : "Update"} Resource`}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <AddResourceAliasForm data={data}
                    handleDialogClose={handleDialogClose}
                    isNewResource={isNewResource}
                    isReadOnly={isReadOnly}
                    handleViewAlias={handleViewAlias}
                    updateStatus={updateStatus}
                />
            </CustomDialogDrawer>
        </React.Fragment >
    );
};

export default AddAliasDialog;
