import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import { ILog, ITimesheetLogsSearchObj } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import moment from "moment";
import { getTimesheetLogs } from "../../services/TimesheetService";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";

export interface LogDialogProps {
  timesheetId?: number;
  handleDialogClose: Function;
}

const LogDialog: React.FC<LogDialogProps> = ({
  timesheetId,
  handleDialogClose,
}) => {
  const [state, setState] = React.useState({
    logs: [],
    searchObj: {
      // StartDate: moment(moment(data?.StartDate).format("M/DD/YYYY")).format("DD/MM/YYYY"),
      // EndDate: moment(moment(data?.EndDate).format("M/DD/YYYY")).format("DD/MM/YYYY"),
      // StartDate: data?.StartDate,
      // EndDate: data?.EndDate,
      // FirmId: data?.FirmId,
      // PersonId: data?.PersonId,
      // TimeCommonBenefitIds: data?.TimeCommonBenefitIds,
      // Type: data?.Type,
    },
  } as {
    logs: ILog[];
    searchObj: ITimesheetLogsSearchObj;
  });

  const [isLoading, setLoading] = React.useState(false);
  const { showToast } = React.useContext(ToastContext);

  const closeAddDialog = () => {
    handleDialogClose();
  };

  React.useEffect(() => {
    const fetch = async () => {
      await fetchTimesheetLogs();
    };
    fetch();
  }, []);
  const fetchTimesheetLogs = async () => {
    setLoading(true);
    let result = await getTimesheetLogs(timesheetId);
    setLoading(false);
    if (result.isSuccess) {
      setState({ ...state, logs: result.data });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching change logs",
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDrawer
        title={""}
        fullWidth={true}
        onClose={() => closeAddDialog()}
      >
        <section className="p-16">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Change Log
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12}>
              <TableContainer className="moz-table-pb">
                <Table size="small" className="custom-table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Version</StyledTableCell>
                      <StyledTableCell>Column Name</StyledTableCell>
                      <StyledTableCell>Modified by</StyledTableCell>
                      <StyledTableCell>Modified on</StyledTableCell>
                      <StyledTableCell>Original Value</StyledTableCell>
                      {/* <StyledTableCell>Old Entry</StyledTableCell> */}
                      <StyledTableCell>Updated/New Value</StyledTableCell>
                      {/* <StyledTableCell>Updated Entry</StyledTableCell> */}
                      <StyledTableCell>Reason</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.logs.length > 0 ? (
                      state.logs.map((ele, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {ele.version || "0"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele.columnName || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele.modifiedByName || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {moment(ele.modifiedDate).format("MM/DD/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele.oldEntry || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele.updatedEntry || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele.comments || ""}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          No logs found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <br />
              <br />
              <Grid container spacing={1} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => closeAddDialog()}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default LogDialog;
