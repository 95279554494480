import {
  Breadcrumbs,
  Button,
  Checkbox,
  Grid,
  Hidden,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ChevronRight, ExclamationThick, Pencil } from "mdi-material-ui";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  IExpense,
  ITimeEntrySearch,
  IProfessionalHourStats,
  IFilter,
  IKeyValue,
} from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import moment from "moment";
import {
  StyledTableCell,
  StyledTableRow,
  useUnload,
  removeNulls,
  getToken,
  getFileName,
  currencyFormat,
  isUserAllowed,
  getSubDomain
} from "../../services/UtilService";
import ManageExpenseDialog from "./ManageExpenseDialog";
import RuleFailedDialog from "./RuleFailedDialog";
import LogDialog from "./LogDialog";
import { getAllProfessionalLevels, getAllWorkTypes } from "../../services/TimesheetService";
import {
  editExpense,
  getExpensesOfExpense,
  expensesReviewed,
  approveExpenses,
  getExpenseFirmEmployeeListDetails,
  getExpensesFiltersCount,
} from "../../services/ExpenseService";
import Loading from "../common/Loading";
import {
  API_URL,
  TIMESHEET_STATUS,
  TIME_ENTRY_KEY_WORDS,
  RECEIPT_TYPES
} from "../../Constant";
import TimeSheetHeader from "./../timesheets/TimeSheetHeader";
import Filter from "../common/Filter";
import CustomDrawer from "../common/CustomDrawer";
import ExportDialog from "../common/ExportDialog";
import { FirmContext } from "../common/FirmProvider";
import ManageNotesDialog from "../notes/ManageNotesDialog";
import ViewExpenseTable from "./ViewExpenseTable";

export interface ViewExpenseMainProps { }

const ViewExpenseMain: React.FC<ViewExpenseMainProps> = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const history = useHistory();
  const { showToast } = React.useContext(ToastContext);
  const [, setProfessionalLevels] = React.useState([]);
  const [, setCategories] = React.useState([]);
  const [
    initialProfessionalLevels,
    setInitialProfessionalLevels,
  ] = React.useState({} as IKeyValue<{ label: string; value: string }>);
  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [firmEmployeeDetails, setFirmEmployeeDetails] = React.useState(
    [] as Array<IProfessionalHourStats>
  );
  const [] = React.useState<null | HTMLElement>(null);
  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});

  const firmProvider = React.useContext(FirmContext);

  const [manageNoteDialog, setManageNoteDialog] = React.useState({
    isOpen: false,
    expenseIds: [],
  } as {
    isOpen: boolean;
    expenseIds: string[];
  });

  const [state, setState] = React.useState({
    expensesList: [],
    manageExpenseEntryDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    manageRuleFailedDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    viewLogsDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    previousItems: [],
    nextItems: [],
    currentItem: {},
    startDate: "",
    endDate: "",
    employeeName: "",
    firmName: "",
    isAllSelected: false,
    status: [],
    searchObj: {
      firmId: undefined,
      personId: undefined,
      status: [],
      keyword: [],
      categoryCode: [],
      workType: [],
      professionalLevel: [],
      startDate: moment("2017-01-01", "YYYY-MM-DD")
        .subtract(1, "year")
        .toDate(),
      endDate: moment().toDate(),
      min: "",
      max: "",
      firms: [],
      persons: [],
      statuses: [],
      selectedFirms: [],
      selectedPersons: [],
    },
  } as { isAllSelected: boolean; expensesList: IExpense[]; manageExpenseEntryDialog: { isOpen: boolean; data?: IExpense; index?: number }; manageRuleFailedDialog: { isOpen: boolean; data?: any; index?: number }; viewLogsDialog: { isOpen: boolean; data?: any; index?: number }; previousItems: any[]; nextItems: any[]; currentItem: any; startDate: string; endDate: string; status: string[]; searchObj: ITimeEntrySearch; employeeName: string; firmName: string });
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] = React.useState({
    minDate: undefined,
    maxDate: undefined
  } as {
    minDate?: Date;
    maxDate?: Date
  })

  React.useEffect(() => {
    if (isUserAllowed()) {
      const fetchExpenses = async () => {
        setLoading(true);
        let professionalLevelsDic = {};
        let categoriesCodeDic = await getAllCategoriesList();
        let workTypesDic = await getAllWorkTypesList();
        setLoading(false);
        let a: any = localStorage.getItem("nextExpenses");
        if (a) {
          let items = JSON.parse(a);
          localStorage.removeItem("nextExpenses");
          let nextItems = [...items];
          nextItems.splice(0, 1);
          let search: ITimeEntrySearch = {
            ...state.searchObj,
            lawFirmCode: items[0].lawFirmCode,
            personId: items[0].personId,
            status: items[0].status || state.searchObj.status,
            startDate: items[0].startDate || state.searchObj.startDate,
            endDate: items[0].endDate || state.searchObj.endDate,
            persons: items[0].personId ? [items[0].personId] : [],
            statuses: items[0].status || state.searchObj.statuses,
            professionalLevel:
              items[0].professionalLevel || state.searchObj.professionalLevel,
            categoryCode: items[0].categoryCode || state.searchObj.categoryCode,
            workType: items[0].workType || state.searchObj.workType,
            keyword: items[0].keyword || state.searchObj.keyword,
            min: items[0].min || state.searchObj.min,
            max: items[0].max || state.searchObj.max,
            selectedFirms:
              items[0].selectedFirms || state.searchObj.selectedFirms,
            selectedPersons:
              items[0].selectedPersons || state.searchObj.selectedPersons,
          };
          await getExpenseFilters(search, undefined, {
            professionalLevelsDic,
            categoriesCodeDic,
            workTypesDic
          });
        } else {
          history.push("/expenses");
        }
      };
      fetchExpenses();
    } else {
      history.push("/not-authorized");
    }
  }, []);

  const getExpenseFilters = async (
    search?: any,
    changedProperty?: any,
    professionCategoriesData?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (search) {
      tempSearch = { ...state.searchObj, ...search };
    }
    let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
    if (professionCategoriesData?.professionalLevelsDic) {
      initialProfessionalLevelTemp = {
        ...initialProfessionalLevels,
        ...professionCategoriesData.professionalLevelsDic,
      };
    }
    let initialCategoryCodeTemp = { ...initialCategoryCodes };
    if (professionCategoriesData?.categoriesCodeDic) {
      initialCategoryCodeTemp = {
        ...initialCategoryCodes,
        ...professionCategoriesData.categoriesCodeDic,
      };
    }
    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (professionCategoriesData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...professionCategoriesData.workTypesDic,
      };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    // let professionalLevels = professionCategoriesData
    //   ? professionCategoriesData.professionalLevels
    //   : [...professionalLevelList];
    // let categoriesItems = professionCategoriesData
    //   ? professionCategoriesData.categoriesData
    //   : [...categoriesList];
    let keyWords = [...keyWordsList];
    let searchForFilter = { ...tempSearch };
    // if (changedProperty) {
    //   switch (changedProperty) {
    //     case "statusCounts":
    //       searchForFilter.status = [];
    //       break;
    //     case "statusCounts":
    //       searchForFilter.status = [];
    //       break;
    //     default:
    //       break;
    //   }
    // }
    setLoading(true);
    searchForFilter["firms"] = [searchForFilter.lawFirmCode];
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {
        firmCounts: {},
        statusCounts: {},
        categoryCodeCounts: {},
        workTypeCounts: {},
        keywordCounts: {},
      } as any,
    };
    await getFirmEmployeeDetails(searchForFilter);
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      result = await getExpensesFiltersCount(searchForFilter);
    }
    setLoading(false);
    if (result?.isSuccess) {
      let filter: IFilter[] = [];
      let data = { ...result.data };
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          categoryCodeCounts: "categoryCode",
          workTypeCounts: "workType",
          firmCounts: "firmCode",
          keywordCounts: "keyword",
          statusCounts: "status",
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            categoryCodeCounts: {},
            workTypeCounts: {},
            firmCounts: {},
            keywordCounts: {},
            statusCounts: {},
          }
        );
        // if(data?.startDate){
        //   tempSearch.startDate = data.startDate
        // }
        // if(data?.endDate){
        //   tempSearch.endDate = data.endDate
        // }
        setFilterCountsDic(filterCountDic);
      }
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });
      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      if (!tempSearch.max && data?.maxTimeSpentHours) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      let filterOrder = [
        "workTypeCounts",
        "time",
        "statusCounts",
        "firmCounts",
        "categoryCodeCounts",
        "keyword",
        "min-max",
      ];
      let customFilters = ["time", "keyword", "min-max"];
      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time").isHidden) ||
                  false,
                items: [
                  {
                    type: 'date-range',
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: 'selection'
                      }
                    ],
                    label: ""
                  }
                ]
                // items: [
                //   {
                //     value: tempSearch.startDate,
                //     type: "date",
                //     label: "Start Date",
                //     name: "startDate",
                //   },
                //   {
                //     value: tempSearch.endDate,
                //     type: "date",
                //     label: "End Date",
                //     name: "endDate",
                //   },
                // ],
              });
              break;
            case "keyword":
              break;
            case "min-max":
              filter.push({
                header: "Expense Amount Greater Than",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max").isHidden) ||
                  false,
                items: [
                  {
                    label: "Amount",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  }
                ],
              });
              break;
            default:
              break;
          }
        }
        else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses?.includes(eds)||false,
                  label: TIMESHEET_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                });
              });
              break;
            case "professionalCounts":
              a.header = "Professional Level";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let professionalCounts = data["professionalCounts"] || [];
              Object.keys(initialProfessionalLevelTemp).forEach((element) => {
                a.items.push({
                  label: initialProfessionalLevelTemp[element]
                    ? initialProfessionalLevelTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.professionalLevel.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === element)?.count || 0
                        : professionalCounts.find(
                          (e) => e.professionalLevel === element
                        )?.count || 0
                      : filterCountDic?.["professionalCounts"]?.[element]
                        ?.count || 0,
                  name: element,
                  isSelected: tempSearch.professionalLevel?.includes(element)||false,
                });
              });
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.workTypesDic[element]
                    ? professionCategoriesData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : workTypeCounts.find(
                          (e) => e.workType === element
                        )?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.workType?.includes(element)||false,
                });
              });
              break;
            case "categoryCodeCounts":
              a.header = "Expense Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let categoryCodeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp).forEach((element) => {
                a.items.push({
                  label: initialCategoryCodeTemp[element]
                    ? initialCategoryCodeTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.categoryCode.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : categoryCodeCounts.find(
                          (e) => e.categoryCode === element
                        )?.count || 0
                      : filterCountDic?.["categoryCodeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.categoryCode?.includes(element)||false,
                });
              });
              break;
            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });

      // filter.push({
      //   header: "Keywords",
      //   name: "keyword",
      //   isHidden:
      //     (filterData.length > 0 &&
      //       filterData.find((e) => e.name === "keyword").isHidden) ||
      //     false,
      //   items: keyWords.map((ele) => {
      //     return {
      //       isSelected: tempSearch.keyword.includes(ele.value),
      //       label: ele.label,
      //       type: "checkbox",
      //       name: ele.value,
      //     };
      //   }),
      // });

      setCountsLoaded(true);
      setFilterData(filter);
      // setInitialProfessionalLevels(initialProfessionalLevelTemp);
      // setInitialCategoryCodes(initialCategoryCodeTemp);
      await searchAllExpenses(tempSearch);
    } else {
      showToast(
        result?.message || "Error while fetching expenses counts",
        "error"
      );
    }
  };

  const getAllCategoriesList = async () => {
    let categories = firmProvider.categoriesList;
    if (categories.length === 0) {
      categories = await firmProvider.getCategoriesListForExpenses();
    }
    let categoryCodeDic = {};
    let data = categories.map((x) => {
      return { label: x.expenseCategoryName, value: x.expenseCategoryCode };
    });
    categoryCodeDic = data.reduce((acc: any, ele) => {
      acc[ele.value] = ele;
      return acc;
    }, {});
    setCategories(data || []);
    setInitialCategoryCodes({ ...categoryCodeDic });
    return categoryCodeDic;
  };

  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(workTypesDic);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = "";
  });

  const searchAllExpenses = async (searchObj?: ITimeEntrySearch) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...searchObj };
    }
    tempSearch = removeNulls(tempSearch);
    setLoading(true);
    const result = await getExpensesOfExpense(tempSearch);
    if (result?.isSuccess) {
      setState({
        ...state,
        expensesList: result.data.map((ele) => {
          ele.isEdit = false;
          return ele;
        }),
        employeeName: state.employeeName
          ? state.employeeName
          : `${result?.data[0]?.firstName || ""} ${result?.data[0]?.lastName || ""
          }`,
        firmName: state.firmName
          ? state.firmName
          : `${result?.data[0]?.lawFirmShortName || ""}`,
        searchObj: searchObj
          ? {
            ...state.searchObj,
            ...searchObj,
          }
          : state.searchObj,
        manageExpenseEntryDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
        },
        manageRuleFailedDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
        },
      });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching expenses",
        "error"
      );
    }
    setLoading(false);
  };

  const getFirmEmployeeDetails = async (searchObj) => {
    setLoading(true);
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...state.searchObj, ...searchObj };
    }
    let obj = {
      ...tempSearch,
      firmId: searchObj.firmId,
      personId: searchObj.personId,
      status: searchObj.status || tempSearch.status,
    };
    // delete obj.startDate;
    // delete obj.endDate;
    // delete obj.professionalLevel;
    // delete obj.categoryCode;
    // delete obj.keyword;
    const result = await getExpenseFirmEmployeeListDetails(obj);
    if (result?.isSuccess) {
      setFirmEmployeeDetails(result.data);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching firm resource details",
        "error"
      );
    }
  };
  const getAllExpensesList = async (
    data: any,
    next?: any[],
    previous?: any[]
  ) => {
    setLoading(true);
    let tempSearch = { ...state.searchObj };
    let obj = {
      ...tempSearch,
      firmId: data.firmId,
      personId: data.personId,
      status: data.status || state.searchObj.status,
      startDate: data.startDate || state.searchObj.startDate,
      endDate: data.emdDate || state.searchObj.endDate,
    };
    const result = await getExpensesOfExpense(obj);
    if (result?.isSuccess) {
      setState({
        ...state,
        expensesList: result.data.map((ele) => {
          ele.isEdit = false;
          return ele;
        }),
        nextItems: next || [],
        previousItems: previous || [],
        currentItem: data,
        searchObj: {
          ...obj,
          professionalLevel: tempSearch.professionalLevel,
          categoryCode: tempSearch.categoryCode,
        },
      });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching expenses",
        "error"
      );
    }
    setLoading(false);
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = state.expensesList[editIndex];
    }
    setState({
      ...state,
      manageExpenseEntryDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };

  const handleAddDialogClose = async (data?: IExpense) => {
    setLoading(true);
    if (data) {
      if (state.manageExpenseEntryDialog.index !== undefined) {
        if (data.isEdit) {
          let result = await editExpense(data);
          if (result?.isSuccess) {
            showToast("Entry updated successfully", "success");
            setCountsLoaded(false)
            await searchAllExpenses();
            await getExpenseFilters(undefined, "clear-filters", {
              professionalLevelsDic: { ...initialProfessionalLevels },
              categoriesCodeDic: { ...initialCategoryCodes },
              workTypesDic: { ...initialWorkTypes }
            });
          }
        } else {
          showToast("Entry not updated as no value changed", "warn");
        }
      } else {
        // timeEntriesList.unshift(data);
        showToast("Entry added successfully", "success");
      }
    } else {
      setState({
        ...state,
        manageExpenseEntryDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
        },
      });
    }

    setLoading(false);
  };

  const handleViewLogsDialog = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (isOpen) {
      let expense = { ...state.expensesList[editIndex as number] };
      if (editIndex !== undefined) {
        data = expense.expenseEntryId;
      }
    }
    setState({
      ...state,
      viewLogsDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };
  const handleRuleFailedDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    let expenses = [...state.expensesList];
    if (editIndex !== undefined) {
      data = expenses[editIndex];
    }
    setState({
      ...state,
      manageRuleFailedDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };

  const handleRuleFailedDialogClose = async () => {
    // await searchAllExpenses();
    await getExpenseFilters(undefined, "clear-filters", {
      professionalLevelsDic: { ...initialProfessionalLevels },
      categoriesCodeDic: { ...initialCategoryCodes },
      workTypesDic: { ...initialWorkTypes }
    });
  };

  const handleEditExpense = async (status: string) => {
    let expenses = [...state.expensesList];
    let selectedEntries = expenses
      .filter((x) => x.isSelected)
      .map((x) => x.expenseEntryId);
    if (selectedEntries.length > 0) {
      let obj = { expensesIds: [...selectedEntries] };
      setLoading(true);
      const result = await approveExpenses(obj);
      setLoading(false);
      if (result?.isSuccess) {
        showToast(
          `Expense ${status === "approved" ? "approved" : "saved"
          } successfully`,
          "success"
        );
        expenses.forEach((ele) => {
          if (ele.isSelected) {
            ele.status = "approved";
            ele.isSelected = false;
            ele.version = ele.version + 1;
          }
        });
        setState({ ...state, expensesList: expenses, isAllSelected: false });
        await getExpenseFilters(undefined, "clear-filters", {
          professionalLevelsDic: { ...initialProfessionalLevels },
          categoriesCodeDic: { ...initialCategoryCodes },
          workTypesDic: { ...initialWorkTypes }
        });
      } else {
        showToast(
          result?.message ||
          `Error occurred while ${status === "approved" ? "approving" : "saving"
          } expense`,
          "error"
        );
      }
    } else {
      showToast(`Please select a expense to approve`, "error");
    }
  };
  const reviewExpenses = async () => {
    let expenses = [...state.expensesList];
    let selectedEntries: any = expenses
      .filter((x) => x.isSelected);

    let isEntriesCannotBeReviewedSelected = selectedEntries.find(x => ["granted", "not-granted", "reviewed"].indexOf(x.status) > -1);

    if (isEntriesCannotBeReviewedSelected) {
      showToast(`These entries cannot be reviewed. Only entries with status other than  ${TIMESHEET_STATUS.reviewed}, ${TIMESHEET_STATUS.granted} and ${TIMESHEET_STATUS["not-granted"]} can be reviewed`, "error");
      return;
    }

    selectedEntries = selectedEntries.map((x) => x.expenseEntryId);
    if (selectedEntries.length > 0) {
      let obj = { expensesIds: [...selectedEntries] };
      setLoading(true);
      const result = await expensesReviewed(obj);
      setLoading(false);
      if (result?.isSuccess) {
        showToast(`Expenses Reviewed Successfully`, "success");
        expenses.forEach((ele) => {
          if (ele.isSelected) {
            ele.status = "reviewed";
            ele.isSelected = false;
            ele.version = ele.version + 1;
          }
        });
        setState({ ...state, expensesList: expenses, isAllSelected: false });
        await getExpenseFilters(undefined, "clear-filters", {
          professionalLevelsDic: { ...initialProfessionalLevels },
          categoriesCodeDic: { ...initialCategoryCodes },
          workTypesDic: { ...initialWorkTypes }
        });
      } else {
        showToast(
          result?.message || `Error occurred while reviewing expenses`,
          "error"
        );
      }
    } else {
      showToast(`Please select a expense to approve`, "error");
    }
  };

  const selectAll = () => {
    let expenses = [...state.expensesList];
    expenses.map((ele) => {
      if (state.isAllSelected === false) {
        ele.isSelected = true;
      } else {
        ele.isSelected = false;
      }
      return ele;
    });
    setState({
      ...state,
      expensesList: expenses,
      isAllSelected: !state.isAllSelected,
    });
    const ids = expenses.filter((d) => d.isSelected).map((d) => String(d.expenseEntryId));
    setManageNoteDialog({ ...manageNoteDialog, expenseIds: ids })
  };

  const handleSelectChange = (index: number) => {
    let timesheetsList = state.expensesList;
    let timesheet = timesheetsList[index];
    timesheet.isSelected = !timesheet.isSelected;
    timesheetsList[index] = timesheet;
    let isAllSelected = true;
    if (timesheet.isSelected === false) {
      isAllSelected = false;
    } else {
      timesheetsList.forEach((ele) => {
        if (!ele.isSelected) {
          isAllSelected = false;
        }
      });
    }
    setState({ ...state, expensesList: timesheetsList, isAllSelected });
    const ids = timesheetsList.filter((d) => d.isSelected).map((d) => String(d.expenseEntryId));
    setManageNoteDialog({ ...manageNoteDialog, expenseIds: ids });
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    let search = { ...state.searchObj };
    let statusArray =
      data.find((ele) => ele.name === "statusCounts")?.items || [];
    let professionalLevelArray =
      data.find((ele) => ele.name === "professionalCounts")?.items || [];
    let categoryCodeArray =
      data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
    let workTypesArray =
      data.find((ele) => ele.name === "workTypeCounts")?.items || [];
    let keywordsArray = data.find((ele) => ele.name === "keyword")?.items || [];
    let timeArray = data.find((ele) => ele.name === "time")?.items || [];
    search.startDate =
      timeArray[0].value[0].startDate || undefined;
    // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
    search.endDate =
      timeArray[0].value[0].endDate || undefined;
    // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;
    search.professionalLevel = professionalLevelArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.categoryCode = categoryCodeArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.workType = workTypesArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.keyword = keywordsArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.status = statusArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.statuses = statusArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
    search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";

    await getExpenseFilters(search, changedProperty, {
      professionalLevelsDic: { ...initialProfessionalLevels },
      categoriesCodeDic: { ...initialCategoryCodes },
      workTypesDic: { ...initialWorkTypes }
    });
  };

  const handleDownload = async (includeChangeLog?: boolean) => {
    let searchObj = { ...state.searchObj };
    let obj = {
      searchQuery: {
        ...searchObj,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        lawFirmCode: searchObj.lawFirmCode,
        statuses: searchObj.statuses,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        keyword: searchObj.keyword,
        min: searchObj?.min || undefined,
        includeChangeLog: includeChangeLog ? true : false
      },
      isSaveOnServer: false,
      isIncludeChangeLog: includeChangeLog ? true : false,
      workType: searchObj.workType,
      localTime: moment().format("YYYYMMDDHHmmss")
    };
    let result = await fetch(`${API_URL}timesheets/generate-pdf/expense-detailed`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], { type: "application/pdf" });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "pdf", "case", state.firmName);
    a.click();
  };
  const handleDownloadAsCsv = async (includeChangeLog?: boolean) => {
    let searchObj = { ...state.searchObj };
    let obj = {
      searchQuery: {
        ...searchObj,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        lawFirmCode: searchObj.lawFirmCode,
        statuses: searchObj.statuses,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        keyword: searchObj.keyword,
        min: searchObj?.min || undefined,
        includeChangeLog: includeChangeLog ? true : false
      },
      isSaveOnServer: false,
      workType: searchObj.workType,
      isIncludeChangeLog: includeChangeLog ? true : false,
      localTime: moment().format("YYYYMMDDHHmmss")
    };
    let result = await fetch(`${API_URL}timesheets/generate-csv/expense-detailed`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], { type: "application/csv" });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "csv", "case", state.firmName);
    a.click();
  };

  const handleOpenExportDialog = () => {
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.includeChangeLog);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.includeChangeLog);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpen(false);
  };

  const navigateToExpenses = () => {
    history.push({
      pathname: `/expenses`,
      state: {
        statuses: state.searchObj.statuses,
        startDate: state.searchObj.startDate,
        endDate: state.searchObj.endDate,
        persons: state.searchObj.selectedPersons,
        selectedFirm: state.searchObj.selectedFirms,
        professionalLevel: state.searchObj.professionalLevel,
        categoryCode: state.searchObj.categoryCode,
        workType: state.searchObj.workType,
        keyword: state.searchObj.keyword,
        min: state.searchObj.min,
        max: state.searchObj.max,
      },
    });
  };

  return (
    <React.Fragment>
      {isLoading && <Loading message={loadingMessage} />}
      <section className="p-16">
        <div className="text-center">
          <Typography variant="h5">Detailed Expenses</Typography>
        </div>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<ChevronRight fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                color="primary"
                className="pointer"
                onClick={() => {
                  navigateToExpenses();
                }}
              >
                Expense
              </Link>
              <Typography color="textPrimary">Detailed Expenses</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <section className="px-16">
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TimeSheetHeader
                    label="Firm"
                    value={`${state.firmName || "-"}`}
                  ></TimeSheetHeader>
                </Grid>
              </Grid>
            </section>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} lg={2} className="expense-view-filters-height">
              {filterData.length > 0 && (
                <Filter
                  data={[...filterData]}
                  options={[]}
                  onChange={(data: IFilter[], changedProperty?: string) => {
                    onFilterChange(data, changedProperty);
                  }}
                  dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                />
              )}
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={10}>
            <Grid container spacing={2} justify="flex-end">
              <Hidden lgUp>
                <Grid item>
                  {filterData.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setFilterDialogOpen(true)}
                    >
                      Filters
                    </Button>
                  )}
                </Grid>
              </Hidden>
              {state.expensesList && state.expensesList.length > 0 && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleOpenExportDialog();
                    }}
                  >
                    Export
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  disabled={
                    state.expensesList.findIndex(
                      (ele) => ele.isSelected === true
                    ) === -1
                      ? true
                      : false
                  }
                  onClick={() =>
                    setManageNoteDialog({ ...manageNoteDialog, isOpen: true })
                  }
                >
                  Create Note
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={
                    state.expensesList.findIndex(
                      (ele) => ele.isSelected === true
                    ) === -1
                      ? true
                      : false
                  }
                  color="primary"
                  onClick={() => {
                    handleEditExpense("approved");
                  }}
                >
                  Approve
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={
                    state.expensesList.findIndex(
                      (ele) => ele.isSelected === true
                    ) === -1
                      ? true
                      : false
                  }
                  color="primary"
                  onClick={() => {
                    reviewExpenses();
                  }}
                >
                  Review
                </Button>
              </Grid>
            </Grid>
            <br />
            <ViewExpenseTable
              from="expenses"
              expensesList={state.expensesList}
              handleAddDialogOpen={handleAddDialogOpen}
              handleRuleFailedDialogOpen={handleRuleFailedDialogOpen}
              handleSelectChange={handleSelectChange}
              handleViewLogsDialog={handleViewLogsDialog}
              isAllSelected={state.isAllSelected}
              selectAll={selectAll}
            />
          </Grid>
        </Grid>
      </section>
      {state.manageExpenseEntryDialog.isOpen && (
        <ManageExpenseDialog
          data={state.manageExpenseEntryDialog.data}
          handleDialogClose={(data?: IExpense) => handleAddDialogClose(data)}
        />
      )}
      {state.manageRuleFailedDialog.isOpen && (
        <RuleFailedDialog
          timeEntryData={state.manageRuleFailedDialog.data}
          handleDialogClose={() => handleRuleFailedDialogClose()}
        />
      )}
      {state.viewLogsDialog.isOpen && (
        <LogDialog
          timesheetId={state.viewLogsDialog.data}
          handleDialogClose={() => handleViewLogsDialog(false)}
        />
      )}
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
      {isExportDialogOpen && (
        <ExportDialog
          exportFor={'detailedTimesheetOrExpense'}
          handleDialogClose={(data?) => handleCloseExportDialog(data)}
        />
      )}
      {manageNoteDialog.isOpen && (
        <ManageNotesDialog
          isSelectedAll={false}
          type="expenses"
          entries={manageNoteDialog.expenseIds}
          handleDialogClose={(isAdded) => {
            setManageNoteDialog({ ...manageNoteDialog, isOpen: false });
            if (isAdded) {
              setState({
                ...state,
                expensesList: state.expensesList.map(expense => {
                  expense.isSelected = false;
                  return expense
                }),
                isAllSelected: false
              })
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ViewExpenseMain;
