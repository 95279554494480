import React from "react";
import { IProfessionalCharacterization, IResource } from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getResourceDetailsAPI } from "../../services/ResourceService";
import moment from "moment";
import { getAllFirms, getAllProfessionalCharacterizationAPI } from "../../services/FirmsService";
import TimeSheetHeader from "../timesheets/TimeSheetHeader";
import "./resources.css";
export interface IAddResourceAliasDetailsProps {
    data?: IResource;
    handleDialogClose: any;
    isNewResource: boolean;
    isReadOnly?: boolean;
}

const AddResourceAliasDetails: React.FC<IAddResourceAliasDetailsProps> = ({
    data,
    handleDialogClose,
    isNewResource,
    isReadOnly
}) => {
    // state
    const [state, setState] = React.useState({
        details: {
            lawFirmCode: "",
            lawFirmCodeDetails: undefined,
            nameSuffix: "",
            firstName: "",
            lastName: "",
            jobTitleClass: "",
            professionalCharacterizationHistories: [],
            partnerEligibleDate: undefined,
            contractTransitionDate: undefined,
            pscMemberCode: "",
            authorizedCbDiscDocRevProd: "",
            barAdmissionDate: undefined,
            ...data
        }

    } as {
        details: IResource
    });
    const [firmDic, setFirmDic] = React.useState({});
    const [professionalCharacterizationDic, setProfessionalCharacterizationDic] = React.useState({});
    const [isLoading, setLoading] = React.useState(false);

    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    React.useEffect(() => {
        const fetchFirms = async () => {
            setLoading(true);
            await getAllFirmsList(true);
            await getAllProfessionalCharacterization(true);
            if (data && data.resourceName && !data.professionalCharacterizationHistories) {
                await getResourceDetails(true);
            }
            setLoading(false);
        };
        fetchFirms();
    }, []);

    const getResourceDetails = async (isDefault = false) => {
        if (!isDefault) {
            setLoading(true);
        }
        let result = await getResourceDetailsAPI(data.resourceName,data.lawFirmCode);
        console.log(result);
        if (result?.isSuccess) {
            setState({
                ...state,
                details: {
                    ...state.details,
                    ...result.data
                }
            });

        } else {
            showToast(result.message || "Error while getting resource details", "error")
        }
        if (!isDefault) {
            setLoading(false)
        }
    }

    const getAllProfessionalCharacterization = async (isDefault = false) => {
        if (!isDefault) {
            setLoading(true)
        }
        let result = await getAllProfessionalCharacterizationAPI();
        console.log(result);
        if (result?.isSuccess) {
            let dic = result.data.reduce((acc,ele)=>{
                acc[ele.timeProfessionalCharacterizationCode] = ele.timeProfessionalCharacterizationName;
                return acc;
            },{});
            setProfessionalCharacterizationDic(dic);

        } else {
            showToast(result.message || "Error while getting professional characterization list", "error")
        }
        if (!isDefault) {
            setLoading(false)
        }
    }

    const getAllFirmsList = async (isDefault = false) => {
        if (!isDefault) {
            setLoading(true)
        }
        let result = await getAllFirms();
        console.log(data);
        if (result?.isSuccess) {
            let details = state.details;
            setState({
                details: details
            });
            let dic = result.data.reduce((acc,ele)=>{
                acc[ele.lawFirmCode] = ele.lawFirmShortName
                return acc;
            },{})
            setFirmDic(dic);
        } else {
            showToast(result.message || "Error while getting firms", "error")
        }
        if (!isDefault) {
            setLoading(false)
        }
    };



    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <Grid container direction="column" spacing={2}>
                <Grid item lg={12} md={12} sm={12}>
                    <Grid container spacing={1}>
                        {isReadOnly && (
                            <Grid item xs={12}>
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <Button variant="outlined" type="button" color="primary" onClick={() => handleDialogClose(true)}>
                                            Edit Details
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TimeSheetHeader
                                label="Firm"
                                value={`${data.lawFirmName || "-"}`}
                            ></TimeSheetHeader>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TimeSheetHeader
                                label="Name"
                                value={`${((data.nameSuffix ? (data.nameSuffix + " ") : "") + (data.firstName ? (data.firstName + " ") : "") + (data.lastName ? (data.lastName || " ") : "")) || "-"}`}
                            ></TimeSheetHeader>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TimeSheetHeader
                                label="Admission Date"
                                value={data.barAdmissionDate ? moment(data.barAdmissionDate).format("MM/DD/YYYY") : "N/A"}
                            ></TimeSheetHeader>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TimeSheetHeader
                                label="Partner Eligible Date"
                                value={data.partnerEligibleDate ? moment(data.partnerEligibleDate).format("MM/DD/YYYY") : "N/A"}
                            ></TimeSheetHeader>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TimeSheetHeader
                                label="Transition Date"
                                value={data.contractTransitionDate ? moment(data.contractTransitionDate).format("MM/DD/YYYY") : "N/A"}
                            ></TimeSheetHeader>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TimeSheetHeader
                                label="Committee Membership"
                                value={data.pscMemberCode || "N/A"}
                            ></TimeSheetHeader>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TimeSheetHeader
                                label="Authorized Doc Review/Production"
                                value={data.authorizedCbDiscDocRevProd ? (data.authorizedCbDiscDocRevProd === "Y" ? "Yes" : "No") : "N/A"}
                            ></TimeSheetHeader>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {data.professionalCharacterizationHistories && data.professionalCharacterizationHistories.length > 0 && (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Professional Characterization</TableCell>
                                            <TableCell>Start Date</TableCell>
                                            <TableCell>End Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.professionalCharacterizationHistories.map((professionalCharacterization,index) => (
                                            <TableRow
                                                key={`${professionalCharacterization.timeProfessionalCharacterizationCode}_${index}`}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {professionalCharacterizationDic[professionalCharacterization.timeProfessionalCharacterizationCode]}
                                                </TableCell>
                                                <TableCell>{professionalCharacterization.timeProfessionalCharacterizationStartDate ? moment(professionalCharacterization.timeProfessionalCharacterizationStartDate).format("MM/DD/YYYY") : "N/A"}</TableCell>
                                                <TableCell>{professionalCharacterization.timeProfessionalCharacterizationEndDate ? moment(professionalCharacterization.timeProfessionalCharacterizationEndDate).format("MM/DD/YYYY") : "N/A"}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment >
    );
};

export default AddResourceAliasDetails;
