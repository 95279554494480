import React, { MouseEventHandler } from "react";
import {
    Button,
    Card,
    Grid,
    Hidden,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Popover,
    InputLabel,
    Switch,
    FormControlLabel
} from "@material-ui/core";
import {
    IFilter,
    IFirmDetails,
    ITimesheets,
    IKeyValue,
    IPerson,
    ISelectedFirms,
    ITimeEntry,
    IConfirmDialog,
    ITimeFilter,
    IProfessionalHourStats,
    ICategoryHourStats,
    IReportChart,
    IReactSelect,
    IReportResponse,
    IReportDetails,
    IRportGroupBy,
    IReportHeader,
    ITimeSummaryReport,
    ITimeSummaryCrossTableReport
} from "../../vm";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Filter from "../common/Filter";
import { ToastContext } from "../common/ToastProvider";
import {
    getTimesheetPersons,
    getAllTimesheets,
    getTimesheetFiltersCount,
    getAllProfessionalLevels,
    getAllCategories,
    getAllWorkTypes,
    getAllApprovedListForTimeEntry,
} from "../../services/TimesheetService";
import Loading from "../common/Loading";
import { FirmContext } from "../common/FirmProvider";
import {
    StyledTableCell,
    StyledTableRow,
    convertToFixed,
    getFileName,
    getSubDomain,
    getToken,
    onlyUnique,
    removeNulls,
} from "../../services/UtilService";
import {
    BURST_REPORT_DICT,
    DESCRIPTION_FILTER_ENTRY,
    METRICS_DICT,
    TIME_DETAIL_REPORT_METRICS_DICT,
    REPORTS_DICT,
    REPORT_GROUP_BY_NAME,
    REPORT_OUTPUT_DICT,
    TIMESHEET_STATUS,
    TIME_ENTRY_KEY_WORDS,
    styles,
    // BURST_REPORT_TO_METADATA_DICT,
    REPORT_METADATA_DICT,
    SUMMARY_VISUALIZATION_COLUMN_METADATA_DICT,
    SUMMARY_VISUALIZATION_METADATA_DICT,
    API_URL,
    SORT_ORDER_DICT,
    TIME_SUMMARY_CROSS_TABLE_REPORT_METRICS_DICT,
    TIME_SUMMARY_CROSS_TABLE_REPORT_DICT,
    TIME_DETAIL_REPORT_METADATA_DICT,
    IMPORTED_TIME_ENTRIES_REPORT_METADATA_DICT,
    IMPORTED_TIME_ENTRIES_REPORT_METRICS_DICT,
    IMPORTED_TIME_ENTRIES_BURST_REPORT_DICT,
    CHANGED_TIME_ENTRIES_COLUMN_METADATA_DICT,
    CHANGED_TIME_ENTRIES_METADATA_DICT,
    CHANGED_TIME_ENTRIES_BURST_REPORT_DICT,
    CHANGED_TIME_ENTRIES_SHOW_ENTRIES_DICT,
    CHANGED_TIME_ENTRY_COLUMN_MAP_DIC,
    SPLIT_ENTRIES_COLUMN_METADATA_DICT,
    SPLIT_ENTRIES_METADATA_DICT,
    SPLIT_ENTRIES_BURST_REPORT_DICT,
    SPLIT_ENTRIES_SHOW_ENTRIES_DICT,
    SPLIT_ENTRIES_COLUMN_MAP_DICT,
    TIME_DETAIL_BURST_REPORT_DICT,
    CHANGED_TIME_ENTRIES_SHOW_ENTRIES_MAP_WITH_SHOW_METRICS,
    SPLIT_TIME_ENTRIES_SHOW_ENTRIES_MAP_WITH_SHOW_METRICS,
    // METADATA_TO_BURST_REPORT_DICT,
} from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import { getManagementRuleFilters } from "../../services/ManagementDashboardService";
// import BarChart from "../common/charts/BarChart";
// import HeatMap from "../common/charts/HeatMap";
// import { getCategoryHourStatsDetails, getProfessionalLevelHourDetails } from "../../services/DasahboardService";
import moment from "moment";
import { getAllReportAPIDetails, getTimeDetailReportDetails, getGraphDataForFilter, getTimeSummaryReportDetails, getTimeSummaryCrossTabDetails, getChangedTimeEntriesReportDetails, getImportedTimeEntryReportDetails, getSplitEntriesReportDetails } from "../../services/ReportsService";
// import Select, { components } from "react-select";
// import PieChart from "../common/charts/PieChart";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, addYears, endOfDay, endOfWeek, endOfYear, isSameDay, startOfWeek, startOfYear } from 'date-fns';
// import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import "./report.css"
import ToggleFilterSection from "../dashboard/ToggleFilter";
import TimeDetailedReport from "./TimeDetailReport";
import TimeSummaryReportView from "./TimeSummaryReport";
import Select, {
    components,
    // MultiValueGenericProps,
    MultiValueProps,
    // OnChangeValue,
    Props,
    StylesConfig,
} from 'react-select';
import {
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortEndHandler,
    SortableHandle,
} from 'react-sortable-hoc';
import SelectedFilters from "../common/selected-filters/SelectedFilters";
import TimeSummaryCrossTableReport from "./TimeSummaryCrossTableReport";
import { generateRandomUUID } from "../../services/UtilService";
import { getAllNotes } from "../../services/NoteService";
import SummaryVisualizationReport from "./SummaryVisualization";
import ImportedTimeEntriesReport from "./ImportedTimeEntriesReport";
// import { Cancel } from "mdi-material-ui";

const SHOW_COLUMN_DICT = {
    1: TIME_DETAIL_REPORT_METADATA_DICT,
    2: REPORT_METADATA_DICT,
    5: TIME_SUMMARY_CROSS_TABLE_REPORT_DICT,
    6: SUMMARY_VISUALIZATION_COLUMN_METADATA_DICT,
    3: IMPORTED_TIME_ENTRIES_REPORT_METADATA_DICT,
    7: CHANGED_TIME_ENTRIES_COLUMN_METADATA_DICT,
    8: SPLIT_ENTRIES_COLUMN_METADATA_DICT,
};

const SHOW_METRICS_DICT = {
    1: TIME_DETAIL_REPORT_METRICS_DICT,
    2: METRICS_DICT,
    3: IMPORTED_TIME_ENTRIES_REPORT_METRICS_DICT,
    4: {},
    5: TIME_SUMMARY_CROSS_TABLE_REPORT_METRICS_DICT,
    6: SUMMARY_VISUALIZATION_METADATA_DICT,
    7: CHANGED_TIME_ENTRIES_METADATA_DICT,
    8: SPLIT_ENTRIES_METADATA_DICT,
};

const BURST_REPORTS_DICT_BY_REPORT_ID = {
    1: TIME_DETAIL_BURST_REPORT_DICT,
    // 1: BURST_REPORT_DICT,
    2: BURST_REPORT_DICT,
    5: BURST_REPORT_DICT, // don't have burst report
    3: IMPORTED_TIME_ENTRIES_BURST_REPORT_DICT,
    6: BURST_REPORT_DICT,
    7: CHANGED_TIME_ENTRIES_BURST_REPORT_DICT,
    8: SPLIT_ENTRIES_BURST_REPORT_DICT,
};

const SHOW_ENTRIES_WHEN = {
    7: CHANGED_TIME_ENTRIES_SHOW_ENTRIES_DICT,
    8: SPLIT_ENTRIES_SHOW_ENTRIES_DICT
};

const SHOW_ENTRIES_WHEN_MAP_WITH_SHOW_METRICS = {
    7: CHANGED_TIME_ENTRIES_SHOW_ENTRIES_MAP_WITH_SHOW_METRICS,
    8: SPLIT_TIME_ENTRIES_SHOW_ENTRIES_MAP_WITH_SHOW_METRICS
}

// const SORT_BY_DICT = {
//     1: { ...REPORT_METADATA_DICT, ...TIME_DETAIL_REPORT_METRICS_DICT },
//     2: { ...REPORT_METADATA_DICT, ...METRICS_DICT },
//     3: {},
//     4: {},
//     6: {...SUMMARY_VISUALIZATION_COLUMN_METADATA_DICT, ...SUMMARY_VISUALIZATION_METADATA_DICT}
// }

// const INITIAL_SELECTED_COLUMN = ['Firms', 'hours', 'fees'];

const INITIAL_SELECTED_METRICS_DICT = {
    1: ['all'],
    2: ['fees', 'hours'],
    5: ['Hours', "Fees"],
    3: ['hours', 'rate'],
    6: ['fees', 'hours'],
    7: ["all"],
    8: ["all"],
};

const INITIAL_SELECTED_ROW_DICT = {
    1: ['Firms', 'Professional Level', 'Resource'],
    2: ['Firms'],
    3: ['Firms'],
    4: ['Firms'],
    5: ['Firms'],
    6: ['Firms'],
    7: ['Firms'],
};

const INITIAL_SELECTED_COLUMN_DICT = {
    1: ['Firms'],
    2: ['Firms'],
    3: ['Firms'],
    // 3: ['Firms', 'Source', 'Service Date', 'Resource', 'Professional Level', 'Time Category'],
    4: ['Firms'],
    5: ['Status'],
    6: ['Firms'],
    7: ['Firms'],
    // 7: [
    //     "Firms",
    //     "Import Source",
    //     "Imported Resource",
    //     "Imported Class Work",
    //     "Current Service Date",
    //     "Imported Professional Level",
    //     "Imported Service Date"
    // ],
    8: [
        "Firms",
        "Original Entry Service Date",
        "Original Entry Resource",
        "Original Entry Professional Level",
        "Original Entry Time Category",
        "Split Entry Service Date",
        "Split Entry Resource",
        "Split Entry Professional Level",
        "Split Entry Time Category",
    ]
};

const INITIAL_SELECTED_SHOW_ENTRIES_DICT = {
    7: ["Any Change"],
    8: ["All Split Entries"],
}

function arrayMove<T>(array: readonly T[], from: number, to: number) {
    const slicedArray = array.slice();
    slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0]
    );
    return slicedArray;
};

const SortableMultiValue = SortableElement(
    (props: MultiValueProps<IReactSelect>) => {
        // this prevents the menu from being opened/closed when the user clicks
        // on a value to begin dragging it. ideally, detecting a click (instead of
        // a drag) would still focus the control and toggle the menu, but that
        // requires some magic with refs that are out of scope for this example
        const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const innerProps = { ...props.innerProps, onMouseDown };
        return <components.MultiValue {...props} innerProps={innerProps} />;
    }
);

const SortableMultiValueLabel = SortableHandle(
    (props: any) => {
        // if (props.data.isFixed) {
        //     return (
        //         <components.MultiValueRemove {...props}>
        //             <Cancel />
        //         </components.MultiValueRemove>
        //     )
        // }
        return <components.MultiValueLabel {...props} />
    }
);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
    Props<IReactSelect, true> & SortableContainerProps
>;

const reactSelectStyles: StylesConfig<any, true> = {
    // multiValue: (base, state) => {
    //     return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    // },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, paddingRight: 6 }
            : base;
    },
    // multiValueRemove: (base, state) => {
    //     return state.data.isFixed ? { ...base, display: 'none' } : base;
    // },
};

export interface ReportDetailsViewProps extends IConfirmDialog { }

const ReportDetailsView: React.FC<ReportDetailsViewProps> = ({ confirmDialog }) => {
    let colorIndex = -1
    const location = useLocation();
    const searchParams: { reportId: string } = useParams();
    const fromManagementDashboard = React.useRef(false);
    const managementDashboardId = React.useRef(undefined);
    const [isFilterOpen, setFilterOpen] = React.useState(true);
    const [loadedFilter, setLoadedFilter] = React.useState(undefined as ITimeFilter)
    let searchObj: any = {};
    if (location.state) {
        let searchState = { ...(location.state as any) };
        if (searchState.status) {
            searchObj.statuses = Array.isArray(searchObj.status) ? searchObj.status : [searchState.status];
        }
        if (searchState.statuses) {
            searchObj.statuses = searchState.statuses;
        }
        // if (searchState.professionalLevel) {
        //   searchObj.professionalLevel = [searchState.professionalLevel];
        // }
        // if (searchState.categoryCode) {
        //   searchObj.categoryCode = [searchState.categoryCode];
        // }
        if (searchState.startDate) {
            searchObj.startDate = searchState.startDate;
        }
        if (searchState.endDate) {
            searchObj.endDate = searchState.endDate;
        }
        if (searchState.selectedFirm?.length > 0) {
            searchObj.firms = searchState.selectedFirm;
        }
        if (searchState.firms?.length > 0) {
            searchObj.firms = searchState.firms;
        }
        if (searchState.professionalLevel?.length > 0) {
            searchObj.professionalLevel = searchState.professionalLevel;
        }
        if (searchState.categoryCode?.length > 0) {
            searchObj.categoryCode = searchState.categoryCode;
        }
        if (searchState.workType?.length > 0) {
            searchObj.workType = searchState.workType;
        }
        if (searchState.keyword?.length > 0) {
            searchObj.keyword = searchState.keyword;
        }
        if (searchState.min) {
            searchObj.min = searchState.min;
        }
        if (searchState.max) {
            searchObj.max = searchState.max;
        }
        if (searchState.minWordCount) {
            searchObj.minWordCount = searchState.minWordCount;
        }
        if (searchState.maxWordCount) {
            searchObj.maxWordCount = searchState.maxWordCount;
        }
        if (searchState.descriptionSearchFilters && searchState.descriptionSearchFilters.length > 0) {
            searchObj.descriptionSearchFilters = searchState.descriptionSearchFilters;
        }
        if (searchState.showDisapproved) {
            searchObj.showDisapproved = searchState.showDisapproved;
        }
        if (searchState.persons && searchState.persons.length > 0) {
            searchObj.persons = searchState.persons;
        }
        if (searchState.isFromManagementDashboard) {
            fromManagementDashboard.current = true;
            managementDashboardId.current = searchState.timeManagementDashboardId;
        }
        if (searchState.showMultipleBillingRates) {
            searchObj.showMultipleBillingRates = true;
        }
        if (searchState.approvedBy?.length > 0) {
            searchObj.approvedBy = searchState.approvedBy;
        }
        if (searchState.showOnlyDisapproved) {
            searchObj.showOnlyDisapproved = searchState.showOnlyDisapproved;
        }
        if (searchState.noteId) {
            searchObj.noteId = searchState.noteId;
        }
    }
    const initialSearch = {
        from: 0,
        size: 10,
        statuses: searchObj?.statuses ? searchObj.statuses : [],
        firms: searchObj.firms ? searchObj.firms : [],
        persons: searchObj.persons ? searchObj.persons : [],
        // startDate: searchObj.startDate
        //   ? searchObj.startDate
        //   : moment("2017-01-01", "YYYY-MM-DD").subtract(1, "year").toDate(),
        // endDate: searchObj.endDate ? searchObj.endDate : moment().toDate(),
        startDate: searchObj.startDate ? searchObj.startDate : undefined,
        endDate: searchObj.endDate ? searchObj.endDate : undefined,
        burstReportSortBy: ["Firms"],
        sortOrder: "asc",
        keyword: searchObj?.keyword || [],
        min: searchObj.min || "",
        max: searchObj.max || "",
        minWordCount: searchObj.minWordCount || "",
        maxWordCount: searchObj.maxWordCount || "",
        showDisapproved: searchObj.showDisapproved ? searchObj.showDisapproved : searchParams.reportId === "8" ? true : false,
        descriptionSearchFilters: searchObj.descriptionSearchFilters || [],
        categoryCode: searchObj?.categoryCode ? searchObj.categoryCode : [],
        workType: searchObj?.workType ? searchObj.workType : [],
        professionalLevel: searchObj?.professionalLevel
            ? searchObj.professionalLevel
            : [],
        showMultipleBillingRates: searchObj.showMultipleBillingRates || false,
        reportId: undefined,
        approvedBy: searchObj?.approvedBy ? searchObj.approvedBy : [],
        hideDetails: false,
        selectedBurstReports: ["Do Not Burst"],
        selectedColumns: INITIAL_SELECTED_COLUMN_DICT[searchParams.reportId],
        showEntriesWhen: INITIAL_SELECTED_SHOW_ENTRIES_DICT[searchParams.reportId],
        selectedRows: INITIAL_SELECTED_ROW_DICT[searchParams.reportId],
        selectedBins: "20",
        selectedMetrics: INITIAL_SELECTED_METRICS_DICT[searchParams.reportId],
        showOnlyDisapproved: searchObj.showOnlyDisapproved || false,
        noteId: searchObj.noteId || "",
    };

    const reactShowColumnsSelectStyles: StylesConfig<any, true> = {
        multiValueLabel: (base, uState) => {
            let burstReports = state.search.selectedBurstReports.map((d) => ({ value: d, label: BURST_REPORTS_DICT_BY_REPORT_ID[searchParams.reportId][d] }));
            let addPadding = burstReports.find(report => (report.value === uState.data.value) && !["Firms", "Firm Long Name"].includes(uState.data.value));
            return addPadding
                ? { ...base, paddingRight: 10 }
                : base;
        },
        multiValueRemove: (base, uState) => {
            let burstReports = state.search.selectedBurstReports.map((d) => ({ value: d, label: BURST_REPORTS_DICT_BY_REPORT_ID[searchParams.reportId][d] }));
            let cantRemove = burstReports.find(report => (report.value === uState.data.value) && !["Firms", "Firm Long Name"].includes(uState.data.value));
            return cantRemove ? { ...base, display: 'none' } : base;
        },
    };

    const reactShowEntriesWhenSelectStyles: StylesConfig<any, true> = {
        multiValueLabel: (base, uState) => {
            return ['Any Change', 'All Split Entries'].includes(uState.data.value) ? { ...base, paddingRight: 10 } : base;
        },
        multiValueRemove: (base, uState) => {
            return ['Any Change', 'All Split Entries'].includes(uState.data.value) ? { ...base, display: 'none' } : base;
        },
    };

    const [state, setState] = React.useState({
        timesheetsList: [],
        selectedTimeEntries: [],
        unselectedTimeEntries: [],
        selectedFirmDetails: [],
        hasAllSelectionChanged: false,
        isAllSelected: false,
        manageTimeEntryDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
            isBulkEdit: false
        },
        search: {
            ...initialSearch,
        },
        timesheetCount: 0,
        filterData: [],
        rowsList: [10],
    } as {
        search: {
            from: number;
            size: number;
            firms: string[];
            persons: string[];
            statuses: string[];
            startDate?: Date;
            endDate?: Date;
            burstReportSortBy: string[];
            sortOrder?: "asc" | "desc";
            keyword: string[];
            categoryCode: any;
            workType: any;
            professionalLevel: any;
            min?: any;
            max?: any;
            minWordCount?: any;
            maxWordCount?: any;
            showDisapproved?: boolean;
            descriptionSearchFilters: Array<{ search: string, isExcluded: boolean }>
            showMultipleBillingRates: boolean;
            reportId: string;
            approvedBy: string[];
            hideDetails: boolean;
            selectedBurstReports: string[];
            selectedColumns: string[];
            showEntriesWhen: string[];
            selectedRows?: string[];
            selectedBins?: string;
            selectedMetrics?: string[];
            showOnlyDisapproved?: boolean;
            noteId: any; // can be number or string
        };
        timesheetsList: ITimesheets[];
        selectedTimeEntries: string[];
        unselectedTimeEntries: string[];
        hasAllSelectionChanged: boolean;
        isAllSelected: boolean;
        selectedFirmDetails: ISelectedFirms[];
        manageTimeEntryDialog: { isOpen: boolean; data?: ITimeEntry; index?: number, isBulkEdit: boolean }
        timesheetCount: number;
        filterData: IFilter[];
        rowsList: number[];
        options: { value: string; label: string }[];
    });
    const colorCodes = ["#97e3d5", "#f0db3b", "#f47560", "#e8c1a0", "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22"]
    const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = React.useState("");
    const [firmsDic, setFirmsDic] = React.useState({} as IKeyValue<IFirmDetails>);

    const [personsDic, setPersonsDic] = React.useState({} as IKeyValue<IPerson>);
    const { showToast } = React.useContext(ToastContext);
    const firmProvider = React.useContext(FirmContext);
    const [filterData, setFilterData] = React.useState([] as IFilter[]);
    const [allChartsData, setAllChartsData] = React.useState([] as IReportChart[]);
    const [initialFirms, setInitialFirms] = React.useState(
        {} as IKeyValue<IFirmDetails>
    );
    const [
        initialProfessionalLevels,
        setInitialProfessionalLevels,
    ] = React.useState({} as IKeyValue<{ label: string; value: string }>);
    const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
        {} as IKeyValue<{ label: string; value: string }>
    );
    const [initialWorkTypes, setInitialWorkTypes] = React.useState(
        {} as IKeyValue<{ label: string; value: string }>
    );
    const [initialApprovedBy, setInitialApprovedBy] = React.useState(
        {} as IKeyValue<{ label: string; value: string }>
    );
    const [noteList, setNoteList] = React.useState(
        [] as Array<{ label: string; value: string }>
    );
    const [, setProfessionalLevels] = React.useState([]);
    const [, setCategories] = React.useState([]);
    const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);
    const [isCountsLoaded, setCountsLoaded] = React.useState(false);
    const [filterCountsDic, setFilterCountsDic] = React.useState({});
    const [
        professionalHoursStatsData,
        setProfessionalHoursStatsData,
    ] = React.useState([] as Array<IProfessionalHourStats>);
    const [categoryHoursStatsData, setCategoryHoursStatsData] = React.useState(
        [] as Array<ICategoryHourStats>
    );
    const [fessArray, setFeesArray] = React.useState([]);
    const [heatMapData, setHeatMapData] = React.useState([]);
    const [selectedMetrics, setSelectedMetrics] = React.useState(INITIAL_SELECTED_METRICS_DICT[searchParams.reportId] as string[]);
    const [selectedColumns, setSelectedColumn] = React.useState(INITIAL_SELECTED_COLUMN_DICT[searchParams.reportId] as string[]);
    const [showEntriesWhen, setShowEntriesWhen] = React.useState(INITIAL_SELECTED_SHOW_ENTRIES_DICT[searchParams.reportId] as string[]);
    const [selectedRows, setSelectedRow] = React.useState(INITIAL_SELECTED_ROW_DICT[searchParams.reportId] as string[]);
    const [selectedBurstReports, setSelectedBurstReports] = React.useState(["Do Not Burst"] as string[]);
    const [selectedReportOutput, setSelectedReportOutput] = React.useState("web" as string);
    const [tableData, setTableData] = React.useState([] as IReportResponse[]);
    const [timeSummaryReportData, setTimeSummaryReportData] = React.useState([] as IReportResponse[]);
    const [changedTimeEntries, setChangedTimeEntries] = React.useState([] as IReportResponse[]);
    const [splitEntriesReportData, setSplitEntriesReportData] = React.useState([] as IReportResponse[]);
    const [timeSummaryCrossTableReportData, setTimeSummaryCrossTableReportData] = React.useState(
        {
            columns: [],
            rows: [],
            columnHeaders: []
        } as ITimeSummaryCrossTableReport);
    const [importedTimeReportData, setImportedTimeReportData] = React.useState([] as IReportResponse[]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [dateRange, setDateRange] = React.useState([
        {
            startDate: startOfWeek(new Date()),
            endDate: endOfWeek(new Date()),
            key: 'selection'
        }
    ] as any[]);
    // ] as Array<{ startDate: Date; endDate: Date, key: string }>);
    const [isDataLoaded, setDataLoaded] = React.useState(false as boolean);
    const [lastRowPerPageDict, setLastRowPerPageDict] = React.useState({} as any);
    const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] = React.useState({
        minDate: undefined,
        maxDate: undefined
    } as {
        minDate?: Date;
        maxDate?: Date
    })

    React.useEffect(() => {
        // set data loaded state to false so that it don't show the ui/table
        setDataLoaded(false);
        setLastRowPerPageDict({});
        // update the search obj as we are using a different approach, initial values are not changing when report id changed
        let search: any = { ...initialSearch };
        search.selectedColumns = INITIAL_SELECTED_COLUMN_DICT[searchParams.reportId];
        search.showEntriesWhen = INITIAL_SELECTED_SHOW_ENTRIES_DICT[searchParams.reportId];
        search.selectedRows = INITIAL_SELECTED_ROW_DICT[searchParams.reportId];
        search.selectedBins = "20";
        search.selectedMetrics = INITIAL_SELECTED_METRICS_DICT[searchParams.reportId];

        setState({ ...state, search });

        getAllRecordsFromStarting(search);
        // getAllReportsBasedOnFilter(state.search)
    }, [searchParams.reportId]);

    const getAllRecordsFromStarting = async (search?) => {
        setLoading(true);
        let professionalLevelsDic = await getAllProfessionalLevelsList();
        let categoriesCodeDic = await getAllCategoriesList();
        let workTypesDic = await getAllWorkTypesList();
        let approvedByDic = await getAllApprovedByList();
        let noteList = await getAllNoteList();
        await getUserFirms({ professionalLevelsDic, categoriesCodeDic, search, workTypesDic, approvedByDic, noteList });
        if (managementDashboardId.current) {
            await getCurrentSelectedFilter()
        }
        setLoading(false);
    };

    const getCurrentSelectedFilter = async () => {
        const result = await getManagementRuleFilters(managementDashboardId.current);
        if (result?.isSuccess && result.data?.length > 0) {
            try {
                let details = result.data[0];
                let filterSelected = JSON.parse(details.filters);
                setLoadedFilter({ ...details, filters: filterSelected });
            } catch (error) {
                showToast("Error while loading the management dashboard filter.", "error");
            }
        } else {
            showToast(
                result.message || "Error while loading the management dashboard filter.",
                "error"
            );
        }
    }

    const getAllProfessionalLevelsList = async () => {
        const result = await getAllProfessionalLevels();
        let professionalLevelDic = {};
        if (result?.isSuccess) {
            if (result?.data) {
                let data = result.data.map((x) => {
                    return {
                        label: x.professionalLevelName,
                        value: x.professionalLevelCode,
                    };
                });
                professionalLevelDic = data.reduce((acc: any, ele) => {
                    acc[ele.value] = ele;
                    return acc;
                }, {});
                setInitialProfessionalLevels(professionalLevelDic);
                setProfessionalLevels(data || []);
            }
        } else {
            showToast(
                result.message || "Error while fetching professional levels",
                "error"
            );
        }
        return professionalLevelDic;
    };
    const getAllCategoriesList = async () => {
        const result = await getAllCategories();
        let categoryCodeDic = {};
        if (result?.isSuccess) {
            if (result?.data) {
                let data = result.data.map((x) => {
                    return { label: x.timeCategoryName, value: x.timeCategoryCode };
                });
                categoryCodeDic = data.reduce((acc: any, ele) => {
                    acc[ele.value] = ele;
                    return acc;
                }, {});
                setInitialCategoryCodes(categoryCodeDic);
                setCategories(data || []);
            }
        } else {
            showToast(result.message || "Error while fetching categories", "error");
        }
        return categoryCodeDic;
    };
    const getAllWorkTypesList = async () => {
        const result = await getAllWorkTypes();
        let workTypesDic = {};
        if (result?.isSuccess) {
            if (result?.data) {
                let data = result.data.map((x) => {
                    return { label: x.workTypeLongDescription, value: x.workTypeCode };
                });
                workTypesDic = data.reduce((acc: any, ele) => {
                    acc[ele.value] = ele;
                    return acc;
                }, {});
                setInitialWorkTypes(workTypesDic);
            }
        } else {
            showToast(result.message || "Error while fetching work types", "error");
        }
        return workTypesDic;
    };

    const getAllApprovedByList = async () => {
        const result = await getAllApprovedListForTimeEntry();
        let approvedByDic = {};
        if (result?.isSuccess) {
            if (result?.data) {
                let data = result.data.map((x) => {
                    return { label: x.timeApprovedByName, value: x.timeApprovedByCode };
                });
                approvedByDic = data.reduce((acc: any, ele) => {
                    acc[ele.value] = ele;
                    return acc;
                }, {});
                setInitialApprovedBy(approvedByDic);
            }
        } else {
            showToast(result.message || "Error while fetching approved by", "error");
        }
        return approvedByDic;
    };

    const getAllNoteList = async () => {
        const result = await getAllNotes({});
        let noteList = [];
        if (result?.isSuccess) {
            if (result?.data) {
                noteList = result.data.results.map((x) => {
                    return { label: x.description, value: x.id };
                });
                setNoteList(noteList);
            }
        } else {
            showToast(result.message || "Error while fetching notes", "error");
        }
        return noteList;
    };

    const getTimesheetFilters = async (
        search?: any,
        firmsDic?: any,
        personsDic?: any,
        changedProperty?: any,
        professionCategoriesData?: any,
        resetSelectAll?: boolean
    ) => {
        let tempSearch = { ...state.search };
        if (search) {
            tempSearch = { ...state.search, ...search };
            if (changedProperty === "load-filters") {
                tempSearch = { ...search, from: 0, size: tempSearch.size };
            }
        }
        if (!tempSearch.endDate) {
            delete tempSearch.endDate;
        }
        if (!tempSearch.startDate) {
            delete tempSearch.startDate;
        }
        let initialFirmsTemp = { ...firmsDic };
        if (firmsDic) {
            initialFirmsTemp = {
                ...initialFirms,
                ...firmsDic,
            };
        }
        let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
        if (professionCategoriesData?.professionalLevelsDic) {
            initialProfessionalLevelTemp = {
                ...initialProfessionalLevels,
                ...professionCategoriesData.professionalLevelsDic,
            };
        }
        let initialCategoryCodeTemp = { ...initialCategoryCodes };
        if (professionCategoriesData?.categoriesCodeDic) {
            initialCategoryCodeTemp = {
                ...initialCategoryCodes,
                ...professionCategoriesData.categoriesCodeDic,
            };
        }
        let initialWorkTypesTemp = { ...initialWorkTypes };
        if (professionCategoriesData?.workTypesDic) {
            initialWorkTypesTemp = {
                ...initialWorkTypes,
                ...professionCategoriesData.workTypesDic,
            };
        }
        let initialApprovedByTemp = { ...initialApprovedBy };
        if (professionCategoriesData?.approvedByDic) {
            initialApprovedByTemp = {
                ...initialApprovedBy,
                ...professionCategoriesData.approvedByDic,
            };
        }
        let initialNoteListTemp = [...noteList];
        if (professionCategoriesData?.noteList) {
            initialNoteListTemp = [
                ...initialNoteListTemp,
                ...professionCategoriesData.noteList,
            ];
        }
        // assigning start and end date for time detail report as we have data only for this range
        // if (["1", "3", "6", "7"].includes(searchParams.reportId)) {
        //     if (!tempSearch.startDate) tempSearch.startDate = "2022-10-01T00:00:00" as any;
        //     if (!tempSearch.endDate) tempSearch.endDate = "2023-02-12T00:00:00" as any;
        // } else if (searchParams.reportId === "8") {
        //     if (!tempSearch.startDate) tempSearch.startDate = "2022-01-01T00:00:00" as any;
        //     if (!tempSearch.endDate) tempSearch.endDate = moment().format("YYYY-MM-DD") as any;
        // };
        let searchForFilter = { ...tempSearch };
        let keyWords = [...keyWordsList];
        // if (changedProperty) {
        //   switch (changedProperty) {
        //     case "firmCounts":
        //       searchForFilter.statuses = [];
        //       break;
        //     case "statusCounts":
        //       searchForFilter.firms = [];
        //       break;
        //     default:
        //       break;
        //   }
        // }
        delete tempSearch.size;
        delete tempSearch.from;
        setLoading(true);
        let result = {
            isSuccess: true,
            success: true,
            message: "",
            data: {
                firmCounts: {},
                statusCounts: {},
                professionalCounts: {},
                categoryCodeCounts: {},
                workTypeCounts: {},
                keywordCounts: {},
                personCounts: {},
                approvedByCounts: {}
            } as any,
        };
        if (isCountsLoaded === false || changedProperty == "clear-filters" || searchParams.reportId !== state.search.reportId) {
            result = await getTimesheetFiltersCount(searchForFilter);
        }
        setLoading(false);
        if (result?.isSuccess) {
            let filterCountDic = filterCountsDic;
            if (isCountsLoaded === false || changedProperty == "clear-filters" || searchParams.reportId !== state.search.reportId) {
                let keyParams = {
                    workTypeCounts: "workType",
                    categoryCodeCounts: "categoryCode",
                    firmCounts: "firmCode",
                    keywordCounts: "keyword",
                    statusCounts: "status",
                    professionalCounts: "professionalLevel",
                    personCounts: "personId",
                    approvedByCounts: "timeApprovedByCode"
                };
                filterCountDic = Object.keys(result.data).reduce(
                    (acc, key) => {
                        if (acc[key]) {
                            result.data[key].forEach((data) => {
                                acc[key][data[keyParams[key]]] = data;
                            });
                        }
                        return acc;
                    },
                    {
                        workTypeCounts: {},
                        categoryCodeCounts: {},
                        firmCounts: {},
                        keywordCounts: {},
                        statusCounts: {},
                        professionalCounts: {},
                        personCounts: {},
                        approvedByCounts: {}
                    }
                );
                // if(result.data?.startDate){
                //   tempSearch.startDate = result.data.startDate
                // }
                // if(result.data?.endDate){
                //   tempSearch.endDate = result.data.endDate
                // }
                setFilterCountsDic(filterCountDic);
            }
            let data = { ...result.data };
            // updating state as it will use as min, max for date range picker
            setDateRangePickerMinMaxObj({
                minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
                maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
            });

            if (!tempSearch.startDate) {
                tempSearch.startDate = data?.startDate || tempSearch.startDate;
            }
            if (!tempSearch.endDate) {
                tempSearch.endDate = data?.endDate || tempSearch.endDate;
            }
            if (!tempSearch.max && data?.maxTimeSpentHours) {
                tempSearch.max = data?.maxTimeSpentHours;
            }
            let filterOrder = [
                "descriptionSearchFilters",
                "workTypeCounts",
                "time",
                "statusCounts",
                "showDisapproved",
                "showOnlyDisapproved",
                "firmCounts",
                "personCounts",
                "professionalCounts",
                "categoryCodeCounts",
                "approvedBy",
                "noteId",
                "keyword",
                "min-max",
                "word-counts",
            ];
            let customFilters = ["time", "keyword", "min-max", "descriptionSearchFilters", "word-counts", "showDisapproved", "showOnlyDisapproved"];
            let filter: IFilter[] = [];

            filterOrder.forEach((filterType) => {
                if (customFilters.indexOf(filterType) > -1) {
                    switch (filterType) {
                        case "time":
                            filter.push({
                                header: "Time Period",
                                name: "time",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "time")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        type: 'date-range',
                                        value: [
                                            {
                                                startDate: tempSearch.startDate,
                                                endDate: tempSearch.endDate,
                                                key: 'selection'
                                            }
                                        ],
                                        label: ""
                                    }
                                ]
                                // items: [
                                //     {
                                //         value: tempSearch.startDate,
                                //         type: "date",
                                //         label: "Start Date",
                                //         name: "startDate",

                                //     },
                                //     {
                                //         value: tempSearch.endDate,
                                //         type: "date",
                                //         label: "End Date",
                                //         name: "endDate",
                                //     },
                                // ],
                            });
                            break;
                        case "keyword":
                            filter.push({
                                header: "Keywords",
                                name: "keyword",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "keyword")?.isHidden) ||
                                    false,
                                items: keyWords.map((ele) => {
                                    return {
                                        isSelected: tempSearch.keyword?.includes(ele.value)||false,
                                        label: ele.label,
                                        type: "checkbox",
                                        name: ele.value,
                                        // count:
                                        //   changedProperty !== undefined &&
                                        //   changedProperty === ele.label &&
                                        //   tempSearch.statuses.length > 0
                                        //     ? filterData
                                        //         .find((ed) => ed.name === 'professionalLevel')
                                        //         ?.items.find((ed) => ed.name === ele.label)?.count || 0
                                        //     : statusCounts.find((e) => e.status === eds)?.count || 0,
                                    };
                                }),
                            });
                            break;
                        case "min-max":
                            filter.push({
                                header: "Total Time Spent for Day",
                                name: "min-max",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "min-max")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        label: "Min",
                                        type: "number",
                                        name: "min",
                                        value: tempSearch.min,
                                    },
                                    {
                                        label: "Max",
                                        type: "number",
                                        name: "max",
                                        value: tempSearch.max,
                                    },
                                ],
                            });
                            break;
                        case "descriptionSearchFilters":
                            filter.push({
                                header: "Descriptions",
                                name: "descriptionSearchFilters",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "descriptionSearchFilters").isHidden) ||
                                    false,
                                items: tempSearch.descriptionSearchFilters.length > 0 ? tempSearch.descriptionSearchFilters.map((d) => {
                                    let itemObj = {
                                        type: "text-checkbox",
                                        value: d.search,
                                        isSelected: d.isExcluded,
                                        name: "description",
                                        label: ""
                                    };
                                    return itemObj;
                                }) : [{ ...DESCRIPTION_FILTER_ENTRY }],
                            });
                            break;
                        case "word-counts":
                            filter.push({
                                header: "Word Count",
                                name: "word-counts",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "word-counts")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        label: "Min Words",
                                        type: "number",
                                        name: "minWordCount",
                                        value: tempSearch.minWordCount,
                                    },
                                    {
                                        label: "Max Words",
                                        type: "number",
                                        name: "maxWordCount",
                                        value: tempSearch.maxWordCount,
                                    },
                                ],
                            });
                            break;
                        case "showDisapproved":
                            filter.push({
                                className: "disapproved-section",
                                header: "",
                                name: "showDisapproved",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "showDisapproved")?.isHidden) ||
                                    false,
                                items: [{
                                    isSelected: tempSearch.showDisapproved,
                                    label: "Show Disapproved",
                                    type: "switch",
                                    name: "showDisapproved",
                                    isDisabled: tempSearch.showOnlyDisapproved
                                }],
                            });
                            break;
                        case "showOnlyDisapproved":
                            filter.push({
                                className: "only-disapproved-section",
                                header: "",
                                name: "showOnlyDisapproved",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "showOnlyDisapproved")?.isHidden) ||
                                    false,
                                items: [{
                                    isSelected: tempSearch.showOnlyDisapproved,
                                    label: "Show Only Disapproved",
                                    type: "switch",
                                    name: "showOnlyDisapproved",
                                }],
                            });
                            break;
                        default:
                            break;
                    }
                }
                else {
                    let ele = filterType;
                    let a: IFilter = { header: "", items: [], name: ele };
                    switch (ele) {
                        case "statusCounts":
                            a.header = "Status";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let statusCounts = data["statusCounts"] || [];
                            Object.keys(TIMESHEET_STATUS).map((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.statuses?.includes(eds)||false,
                                    label: TIMESHEET_STATUS[eds],
                                    type: "checkbox",
                                    name: eds,
                                    count:
                                        isCountsLoaded === false
                                            ? changedProperty !== undefined &&
                                                changedProperty === ele &&
                                                tempSearch.statuses.length > 0
                                                ? filterData
                                                    .find((ed) => ed.name === ele)
                                                    ?.items.find((ed) => ed.name === eds)?.count || 0
                                                : statusCounts.find((e) => e.status === eds)?.count || 0
                                            : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                                    isDisabled: tempSearch.showOnlyDisapproved
                                });
                            });
                            break;
                        case "firmCounts":
                            a.header = "Firm";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let firmCounts = data["firmCounts"] || [];
                            Object.keys(initialFirmsTemp).map((element) => {
                                a.items.push({
                                    label: firmsDic[element]
                                        ? firmsDic[element].lawFirmShortName
                                        : "",
                                    type: "checkbox",
                                    count:
                                        isCountsLoaded === false
                                            ? changedProperty !== undefined &&
                                                changedProperty === ele &&
                                                tempSearch.firms.length > 0
                                                ? filterData
                                                    .find((ed) => ed.name === ele)
                                                    ?.items.find((ed) => ed.name === String(element))
                                                    ?.count || 0
                                                : firmCounts.find((e) => e.firmCode === element)?.count ||
                                                0
                                            : filterCountDic?.["firmCounts"]?.[element]?.count || 0,
                                    name: String(element),
                                    isSelected: tempSearch.firms?.includes(element)||false,
                                });
                            });
                            // sorting based on label i.e. firm short name
                            a.items.sort((a, b) => a.label > b.label ? 1 : -1);
                            break;
                        case "personCounts":
                            a.header = "";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let personsCount: Array<any> = filterCountDic?.["personCounts"] ? Object.values(filterCountDic?.["personCounts"]) : [];
                            let options = [];
                            let selectedItems = [];
                            personsCount.map((ele) => {
                                if (
                                    tempSearch.persons.length > 0 &&
                                    tempSearch.persons.includes(ele.personId)
                                ) {
                                    selectedItems.push({
                                        value: ele.personId,
                                        label: personsDic[ele.personId]?.name,
                                    });
                                }
                                options.push({
                                    value: ele.personId,
                                    label: personsDic[ele.personId]?.name,
                                });
                            });
                            a.items = [
                                {
                                    label: "Resources",
                                    type: "dropdown",
                                    name: ele,
                                    selectedItems: selectedItems || [],
                                    options:
                                        changedProperty !== undefined &&
                                            changedProperty === ele &&
                                            tempSearch.persons.length > 0
                                            ? filterData.find((ed) => ed.name === ele)?.items[0].options
                                            : options,
                                },
                            ];
                            break;
                        case "professionalCounts":
                            a.header = "Professional Level";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let professionalCounts = data["professionalCounts"] || [];
                            Object.keys(initialProfessionalLevelTemp).map((element) => {
                                a.items.push({
                                    label: professionCategoriesData.professionalLevelsDic[element]
                                        ? professionCategoriesData.professionalLevelsDic[element]
                                            .label
                                        : "",
                                    type: "checkbox",
                                    count:
                                        isCountsLoaded === false
                                            ? changedProperty !== undefined &&
                                                changedProperty === ele &&
                                                tempSearch.professionalLevel.length > 0
                                                ? filterData
                                                    .find((ed) => ed.name === ele)
                                                    ?.items.find((ed) => ed.name === element)?.count || 0
                                                : professionalCounts.find(
                                                    (e) => e.professionalLevel === element
                                                )?.count || 0
                                            : filterCountDic?.["professionalCounts"]?.[element]
                                                ?.count || 0,
                                    name: element,
                                    isSelected: tempSearch.professionalLevel?.includes(element)||false,
                                });
                            });
                            break;
                        case "workTypeCounts":
                            a.header = "Work Types";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let workTypeCounts = data["workTypeCounts"] || [];
                            Object.keys(initialWorkTypesTemp).map((element) => {
                                a.items.push({
                                    label: professionCategoriesData.workTypesDic[element]
                                        ? professionCategoriesData.workTypesDic[element].label
                                        : "",
                                    type: "checkbox",
                                    count:
                                        isCountsLoaded === false
                                            ? changedProperty !== undefined &&
                                                changedProperty === ele &&
                                                tempSearch.workType.length > 0
                                                ? filterData
                                                    .find((ed) => ed.name === ele)
                                                    ?.items.find((ed) => ed.name === String(element))
                                                    ?.count || 0
                                                : workTypeCounts.find(
                                                    (e) => e.workType === element
                                                )?.count || 0
                                            : filterCountDic?.["workTypeCounts"]?.[element]
                                                ?.count || 0,
                                    name: String(element),
                                    isSelected: tempSearch.workType?.includes(element)||false,
                                });
                            });
                            break;
                        case "categoryCodeCounts":
                            a.header = "Time Category";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let categoryCodeCounts = data["categoryCodeCounts"] || [];
                            Object.keys(initialCategoryCodeTemp).sort((a, b) => initialCategoryCodeTemp[a].label > initialCategoryCodeTemp[b].label ? 1 : -1).map((element) => {
                                a.items.push({
                                    label: professionCategoriesData.categoriesCodeDic[element]
                                        ? professionCategoriesData.categoriesCodeDic[element].label
                                        : "",
                                    type: "checkbox",
                                    count:
                                        isCountsLoaded === false
                                            ? changedProperty !== undefined &&
                                                changedProperty === ele &&
                                                tempSearch.categoryCode.length > 0
                                                ? filterData
                                                    .find((ed) => ed.name === ele)
                                                    ?.items.find((ed) => ed.name === String(element))
                                                    ?.count || 0
                                                : categoryCodeCounts.find(
                                                    (e) => e.categoryCode === element
                                                )?.count || 0
                                            : filterCountDic?.["categoryCodeCounts"]?.[element]
                                                ?.count || 0,
                                    name: String(element),
                                    isSelected: tempSearch.categoryCode?.includes(element)||false,
                                });
                            });
                            break;
                        case "approvedBy":
                            a.header = "Approved By";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let approvedByCounts = data["approvedByCounts"] || [];
                            Object.keys(initialApprovedByTemp).map((element) => {
                                a.items.push({
                                    label: initialApprovedByTemp[element]
                                        ? initialApprovedByTemp[element].label
                                        : "",
                                    type: "checkbox",
                                    count:
                                        isCountsLoaded === false
                                            ? changedProperty !== undefined &&
                                                changedProperty === ele &&
                                                tempSearch.approvedBy.length > 0
                                                ? filterData
                                                    .find((ed) => ed.name === ele)
                                                    ?.items.find((ed) => ed.name === String(element))
                                                    ?.count || 0
                                                : approvedByCounts.find(
                                                    (e) => e.approvedBy === element
                                                )?.count || 0
                                            : filterCountDic?.["approvedByCounts"]?.[element]
                                                ?.count || 0,
                                    name: String(element),
                                    isSelected: tempSearch.approvedBy?.includes(element)||false,
                                });
                            });
                            break;
                        case "noteId":
                            a.header = "";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let noteOptions = [];
                            let selectedNoteItems = [];
                            let noteDict = initialNoteListTemp.reduce((acc, d) => {
                                acc[d.value] = d.label;
                                return acc;
                            }, {});
                            Object.keys(noteDict).map((key: any) => {
                                key = !isNaN(key) ? Number(key) : key;
                                if (tempSearch.noteId === key) {
                                    selectedNoteItems.push({ value: key, label: noteDict[key] });
                                }
                                noteOptions.push({ value: key, label: noteDict[key] });
                            });
                            a.items = [
                                {
                                    label: "Notes",
                                    type: "dropdown",
                                    name: ele,
                                    selectedItems: selectedNoteItems || [],
                                    options:
                                        changedProperty !== undefined &&
                                            changedProperty === ele &&
                                            tempSearch.noteId
                                            ? filterData.find((ed) => ed.name === ele)?.items[0].options
                                            : noteOptions,
                                    isMulti: false
                                },
                            ];
                            break;
                        default:
                            a = undefined;
                            break;
                    }
                    if (a) {
                        filter.push(a);
                    }
                }
            })

            // filter.push({
            //     header: "",
            //     name: "extra-filters",
            //     isHidden: false,
            //     items: [
            //         {
            //             isSelected: tempSearch.showMultipleBillingRates,
            //             label: "Show Multiple Bill Rates",
            //             type: "checkbox",
            //             name: "showMultipleBillingRates",
            //         },
            //     ],
            // });
            setCountsLoaded(true);
            setFilterData(filter);
            // setInitialFirms(initialFirmsTemp);
            // setInitialProfessionalLevels(initialProfessionalLevelTemp);
            // setInitialCategoryCodes(initialCategoryCodeTemp);
            if (changedProperty !== "add-descriptions") {
                tempSearch.from = 0;
                tempSearch.size = 10;
                // await getProfessionalLevelDetails(tempSearch);
                // await getCategoryHourDetails(tempSearch);
                // generateFeesMonthYearData();
                await getAllReportsBasedOnFilter(tempSearch)
                // await getTimesheetsList(tempSearch, resetSelectAll);
            }
        } else {
            showToast(
                result.message || "Error while fetching timesheets counts",
                "error"
            );
        }
    };

    const getRequiredThingDoneBeforeCall = (searchObj: any) => {
        // changed from Resource to Resource Name
        searchObj.selectedColumns = searchObj.selectedColumns.map((d) => d === "Resource" ? 'Resource Name' : d);

        // changed from Resource to Resource Name & assign label for metrics instead of value as it is needed by backend
        const metaDataArray = Object.keys(SHOW_COLUMN_DICT[searchParams.reportId]);
        searchObj.burstReportSortBy = searchObj.burstReportSortBy.map((d) => d === "Resource" ? 'Resource Name' : !metaDataArray.includes(d) ? SHOW_METRICS_DICT[searchParams.reportId][d] : d);

        return searchObj;
    }

    const getAllReportsBasedOnFilter = async (searchObj) => {
        let tempSearch: any = { ...state.search };
        if (searchObj) {
            tempSearch = { ...tempSearch, ...searchObj };
        }
        tempSearch.reportId = searchParams.reportId;

        // storing selected column, selected metrics and sort by props to a different state
        const originalShowColumn = tempSearch.selectedColumns;
        const originalShowRow = tempSearch.selectedRows;
        const originalShowMetrics = tempSearch.selectedMetrics;
        const originalBurstReportSortBy = tempSearch.burstReportSortBy || [];

        // updating selected column & selected metrics state here from search object variable
        setSelectedColumn(tempSearch.selectedColumns);
        setShowEntriesWhen(tempSearch.showEntriesWhen);
        setSelectedRow(tempSearch.selectedRows);
        setSelectedMetrics(tempSearch.selectedMetrics);
        setSelectedBurstReports(tempSearch.selectedBurstReports);

        // updating tempSearch as it is required
        tempSearch = getRequiredThingDoneBeforeCall(tempSearch);


        // removing selected metrics from search object as it's not needed for backend
        // if (searchParams.reportId !== "5") {
        //     delete tempSearch.selectedMetrics
        // }

        setDataLoaded(false);
        switch (searchParams.reportId) {
            case "2":
            case "6":
                setLoading(true);
                tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
                const burstReportDetails = await getTimeDetailReportDetails(removeNulls({ ...tempSearch }));
                tempSearch.selectedColumns = originalShowColumn;
                tempSearch.selectedRows = originalShowRow;
                tempSearch.selectedMetrics = originalShowMetrics;
                tempSearch.burstReportSortBy = originalBurstReportSortBy;

                if (burstReportDetails?.isSuccess) {
                    let data = burstReportDetails.data || [];
                    setTableData(data);
                } else {
                    showToast(
                        burstReportDetails?.message || "Error while fetching report details",
                        "error"
                    );
                }
                break;
            case "1":
                setLoading(true);
                tempSearch.sortBy = "PROFESSIONAL_LEVEL";
                tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
                const response = await getTimeSummaryReportDetails(removeNulls({ ...tempSearch }));

                // assigning original selected column ans metrics
                tempSearch.selectedColumns = originalShowColumn;
                tempSearch.selectedRows = originalShowRow;
                tempSearch.selectedMetrics = originalShowMetrics;
                tempSearch.burstReportSortBy = originalBurstReportSortBy;

                if (response?.isSuccess) {
                    let data = response.data || [];
                    console.log("DATA", data);
                    data = data.map(record => {
                        record.data = record.data?.map(row => {
                            row = updatedUUID(row);
                            return row;
                        }) || [];
                        return record;
                    })
                    setTimeSummaryReportData(data);
                } else {
                    showToast(
                        response?.message || "Error while fetching report details",
                        "error"
                    );
                }
                break;
            case "5":
                setLoading(true);
                tempSearch.sortBy = "PROFESSIONAL_LEVEL";
                tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);

                const crossTabResp = await getTimeSummaryCrossTabDetails(removeNulls({
                    ...tempSearch,
                    selectedMetrics: originalShowMetrics.includes('all') ? Object.values(SHOW_METRICS_DICT[searchParams.reportId]).filter((d) => d !== "All") : originalShowMetrics
                }));

                // assigning original selected column ans metrics
                tempSearch.selectedColumns = originalShowColumn;
                tempSearch.selectedRows = originalShowRow;
                tempSearch.selectedMetrics = originalShowMetrics;
                tempSearch.burstReportSortBy = originalBurstReportSortBy;

                if (crossTabResp?.isSuccess) {
                    let data = crossTabResp.data;
                    setTimeSummaryCrossTableReportData(data);
                } else {
                    showToast(
                        crossTabResp?.message || "Error while fetching report details",
                        "error"
                    );
                }
                break;
            case "3":
                if (tempSearch.hideDetails && tempSearch.selectedMetrics.indexOf("description") > -1) {
                    showToast(
                        "You cannot select the description with hide details",
                        "error"
                    );
                    setDataLoaded(true)
                } else {
                    setLoading(true);
                    // tempSearch.sortBy = "PROFESSIONAL_LEVEL";
                    tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
                    const importedEntriesResp = await getImportedTimeEntryReportDetails(removeNulls({ ...tempSearch }));

                    // assigning original selected column ans metrics
                    tempSearch.selectedColumns = originalShowColumn;
                    tempSearch.selectedRows = originalShowRow;
                    tempSearch.selectedMetrics = originalShowMetrics;
                    tempSearch.burstReportSortBy = originalBurstReportSortBy;

                    if (importedEntriesResp?.isSuccess) {
                        let data = importedEntriesResp.data || [];
                        console.log("DATA", data);
                        data = data.map(record => {
                            record.data = record.data?.map(row => {
                                row = updatedUUID(row);
                                return row;
                            }) || [];
                            return record;
                        })
                        setImportedTimeReportData(data);
                    } else {
                        showToast(
                            importedEntriesResp?.message || "Error while fetching report details",
                            "error"
                        );
                    }
                }
                break;
            case "7":
                setLoading(true);
                tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
                const changedTimeEntriesResponse = await getChangedTimeEntriesReportDetails(removeNulls({
                    ...tempSearch,
                }));
                tempSearch.selectedColumns = originalShowColumn;
                tempSearch.selectedRows = originalShowRow;
                tempSearch.selectedMetrics = originalShowMetrics;
                tempSearch.burstReportSortBy = originalBurstReportSortBy;

                if (changedTimeEntriesResponse?.isSuccess) {
                    let data = changedTimeEntriesResponse.data || [];
                    console.log("DATA", data);
                    data = data.map(record => {
                        if (!record.burstReportHeaders) {
                            let valueKeyDic = Object.keys(CHANGED_TIME_ENTRY_COLUMN_MAP_DIC).reduce((acc, ele) => {
                                acc[CHANGED_TIME_ENTRY_COLUMN_MAP_DIC[ele]] = ele
                                return acc;
                            }, {});
                            record.burstReportHeaders = state.search.selectedColumns.map(selectedColumn => {
                                return {
                                    headerName: selectedColumn,
                                    headerProperty: valueKeyDic[selectedColumn]
                                }
                            })
                        }
                        return record;
                    })
                    setChangedTimeEntries(data);
                } else {
                    showToast(
                        changedTimeEntriesResponse?.message || "Error while fetching report details",
                        "error"
                    );
                }
                break;
            case "8":
                setLoading(true);
                tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
                // const splitEntriesResponse = { isSuccess: true, data: [], message: "" }
                const splitEntriesResponse = await getSplitEntriesReportDetails(removeNulls({
                    ...tempSearch,
                }));
                tempSearch.selectedColumns = originalShowColumn;
                tempSearch.selectedRows = originalShowRow;
                tempSearch.selectedMetrics = originalShowMetrics;
                tempSearch.burstReportSortBy = originalBurstReportSortBy;

                if (splitEntriesResponse?.isSuccess) {
                    let data = splitEntriesResponse.data || [];
                    console.log("DATA", data);
                    data = data.map(record => {
                        if (!record.burstReportHeaders) {
                            let valueKeyDic = Object.keys(SPLIT_ENTRIES_COLUMN_MAP_DICT).reduce((acc, ele) => {
                                acc[CHANGED_TIME_ENTRY_COLUMN_MAP_DIC[ele]] = ele
                                return acc;
                            }, {});
                            record.burstReportHeaders = state.search.selectedColumns.map(selectedColumn => {
                                return {
                                    headerName: selectedColumn,
                                    headerProperty: valueKeyDic[selectedColumn]
                                }
                            })
                        }
                        return record;
                    })
                    data = data.map(record => {
                        record.data = record.data?.map(row => {
                            row = updatedUUID(row);
                            return row;
                        }) || [];
                        return record;
                    })
                    setSplitEntriesReportData(data);
                } else {
                    showToast(
                        splitEntriesResponse?.message || "Error while fetching report details",
                        "error"
                    );
                }
                break;
            default:
                break;
        }
        setDataLoaded(true);
        setState({
            ...state,
            search: tempSearch
        })

        // const result = await getAllReportAPIDetails(searchParams.reportId);
        // if (result?.isSuccess) {
        //     let fetChURLs = []
        //     for (let i = 0; i < result.data.length; i++) {
        //         fetChURLs.push(getGraphDataForFilter(result.data[i].apiCall, tempSearch, result.data[i].httpType));
        //     }
        //     await Promise.all(fetChURLs).then(responses => {
        //         let chartsData = [];
        //         responses.forEach(response => {
        //             chartsData = [...chartsData, ...response.data];
        //         });
        //         colorIndex = -1;
        //         // setAllChartsData(chartsData);
        //     });
        // } else {
        //     showToast(
        //         result?.message || "Error while fetching report filters",
        //         "error"
        //     );
        // }
        setLoading(false);
    }

    const updatedUUID = (data: IRportGroupBy) => {
        if (data.groupByProp) {
            data.values = data.values.map(x => {
                x = updatedUUID(x);
                return x
            });
        }
        data.id = generateRandomUUID()
        return data;

    }

    const getUserFirms = async (professionCategoriesData) => {
        let userFirms = firmProvider.userFirms;
        if (userFirms.length === 0) {
            userFirms = await firmProvider.getFirmsList();
        }
        let firmsDic = userFirms.reduce((acc: any, ele) => {
            acc[ele.lawFirmCode] = ele;
            return acc;
        }, {});
        setFirmsDic(firmsDic);
        setInitialFirms(firmsDic);
        await getPersonsList(firmsDic, professionCategoriesData);
    };

    const getPersonsList = async (firmsDic, professionCategoriesData) => {
        let result = await getTimesheetPersons();
        if (result?.isSuccess) {
            if (result.data.length > 0) {
                let personsDic = result.data.reduce((acc: any, ele: any) => {
                    acc[ele.resourceName] = {
                        personId: ele.resourceName,
                        name: (ele.firstName || "") + " " + (ele.lastName || ""),
                    };
                    return acc;
                }, {});
                setPersonsDic(personsDic);
                await getTimesheetFilters(
                    professionCategoriesData?.search || undefined,
                    firmsDic,
                    personsDic,
                    undefined,
                    professionCategoriesData
                );
            }
        }
    };

    const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
        if (changedProperty === "clear-filters") {
            setLoadedFilter(undefined);
            managementDashboardId.current = undefined
        }
        if (changedProperty === "load-filters") {
            let details = JSON.parse(JSON.stringify(data));
            setLoadedFilter(details);
            let rightSideFilterProps = ["showEntriesWhen", "selectedColumns", "selectedBins", "selectedRows", "selectedMetrics", "selectedBurstReports", "burstReportSortBy"];
            let loadedFilterDetails = details.filters;
            rightSideFilterProps.forEach(key => {
                loadedFilterDetails[key] = state.search[key];
            });
            await getTimesheetFilters(loadedFilterDetails, firmsDic, personsDic, changedProperty, {
                professionalLevelsDic: { ...initialProfessionalLevels },
                categoriesCodeDic: { ...initialCategoryCodes },
                workTypesDic: { ...initialWorkTypes }
            }, true);
        } else {
            let search = { ...state.search };
            search.from = 0;
            let statusArray =
                data.find((ele) => ele.name === "statusCounts")?.items || [];
            let professionalLevelArray =
                data.find((ele) => ele.name === "professionalCounts")?.items || [];
            let categoryCodeArray =
                data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
            let workTypesArray =
                data.find((ele) => ele.name === "workTypeCounts")?.items || [];
            let personArray =
                data.find((ele) => ele.name === "personCounts")?.items || [];
            let noteArray =
                data.find((ele) => ele.name === "noteId")?.items || [];
            let firmArray = data.find((ele) => ele.name === "firmCounts")?.items || [];
            let approvedByArray = data.find((ele) => ele.name === "approvedBy")?.items || [];
            let timeArray = data.find((ele) => ele.name === "time")?.items || [];
            let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
            search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
            search.max = minMaxArray.find((ele) => ele.name === "max")?.value || "";
            let minMaxWordCountArray = data.find((ele) => ele.name === "word-counts")?.items || [];
            search.minWordCount = minMaxWordCountArray.find((ele) => ele.name === "minWordCount")?.value || "";
            search.maxWordCount = minMaxWordCountArray.find((ele) => ele.name === "maxWordCount")?.value || "";
            let descriptionArray = data.find((ele) => ele.name === "descriptionSearchFilters")?.items || [];
            search.descriptionSearchFilters = descriptionArray
                // .filter((ele) => ele.value)
                .map((ele) => {
                    let d = {
                        search: ele.value || "",
                        isExcluded: ele.isSelected
                    };
                    return d;
                })
            let disapprovedStatus = data.find((ele) => ele.name === "showDisapproved")?.items || [];
            search.showDisapproved = disapprovedStatus.find(ele => ele.name === "showDisapproved").isSelected;
            let onlyDisapprovedStatus = data.find((ele) => ele.name === "showOnlyDisapproved")?.items || [];
            search.showOnlyDisapproved = onlyDisapprovedStatus.find(ele => ele.name === "showOnlyDisapproved").isSelected;
            if (search.min && search.max && search.min > search.max) {
                showToast("Max value cannot be less than min value", "error");
                return;
            }
            if (search.minWordCount && search.maxWordCount && search.minWordCount > search.maxWordCount) {
                showToast("Max word count value cannot be less than min word count value", "error");
                return;
            }
            let extraFilters =
                data.find((ele) => ele.name === "extra-filters")?.items || [];
            search.startDate =
                timeArray[0].value[0].startDate || undefined;
            // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
            search.endDate =
                timeArray[0].value[0].endDate || undefined;
            // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;
            search.statuses = statusArray
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            // if show 'only dis approved' is true we need to make status array empty and 'dis approved' false
            if (search.showOnlyDisapproved) {
                search.showDisapproved = false;
                search.statuses = [];
            }
            search.persons =
                personArray.length > 0
                    ? personArray[0].selectedItems.length > 0
                        ? personArray[0].selectedItems.map((ele) => ele.value)
                        : []
                    : [];
            search.firms = firmArray
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    if (e.name) {
                        return e.name;
                    }
                });
            search.professionalLevel = professionalLevelArray
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            search.categoryCode = categoryCodeArray
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            search.workType = workTypesArray
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let keywordsArray = data.find((ele) => ele.name === "keyword")?.items || [];
            search.keyword = keywordsArray
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            search.approvedBy = approvedByArray
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            search.noteId =
                noteArray.length > 0
                    ? noteArray[0].selectedItems.length > 0
                        ? noteArray[0].selectedItems[0].value
                        : ""
                    : "";
            if (extraFilters?.length > 0) {
                search.showMultipleBillingRates = extraFilters[0].isSelected;
            }
            await getTimesheetFilters(search, firmsDic, personsDic, changedProperty, {
                professionalLevelsDic: { ...initialProfessionalLevels },
                categoriesCodeDic: { ...initialCategoryCodes },
                workTypesDic: { ...initialWorkTypes }
            }, true);
        }
    };

    const doBackendCall = async () => {
        if (selectedReportOutput !== 'web') {
            confirmDialog.show(`Do you want to export as ${searchParams.reportId === '5' && selectedReportOutput === 'csv' ? 'Excel' : REPORT_OUTPUT_DICT[selectedReportOutput]}?`, "",
                async () => {
                    await getAllReportsBasedOnFilter({ ...state.search });
                    await onExport({ ...state.search });
                },
                async () => {
                    await getAllReportsBasedOnFilter({ ...state.search });
                },
            );
        } else {
            await getAllReportsBasedOnFilter({ ...state.search });
        }
    }

    const onApplyFilter = async () => {
        await doBackendCall();
        // if (state.search.selectedColumns.includes('all')) {
        //     await doBackendCall();
        // } else {
        //     let isFoundMetrics = false;
        //     let haveIssue = false;
        //     const metaColumn = Object.keys(REPORT_METADATA);
        //     state.search.selectedColumns.forEach((d) => {
        //         if (metaColumn.includes(d)) {
        //             if (isFoundMetrics) haveIssue = true;
        //         } else {
        //             isFoundMetrics = true;
        //         }
        //     });
        //     if (haveIssue) {
        //         showToast("Meta column can't be there after metrics column", "error");
        //     } else {
        //         await doBackendCall();
        //     }
        // };
    };

    const isApplyFilterBtnDisabled = () => {
        let isDisabled = false;

        if (state.search.selectedColumns.length === 0) {
            isDisabled = true;
        } else if (state.search.selectedMetrics?.length === 0) {
            isDisabled = true;
        } else if (searchParams.reportId === "5" && state.search.selectedRows?.length === 0) {
            isDisabled = true;
        }
        else if (searchParams.reportId === "6" && (state.search.selectedColumns.length === 0 || state.search.selectedColumns.length > 2)) {
            isDisabled = true;
        }
        else if (["7", "8"].includes(searchParams.reportId) && (state.search.showEntriesWhen?.length === 0)) {
            isDisabled = true;
        }
        else if (!state.search.selectedBurstReports.includes("Do Not Burst") && state.search.selectedColumns.length <= state.search.selectedBurstReports.length) {
            if (searchParams.reportId === "1" && state.search.selectedColumns.length !== Object.keys(SHOW_COLUMN_DICT[searchParams.reportId]).length - 2) isDisabled = true;
            if (["2", "5", "3", "6", "7"].includes(searchParams.reportId) && state.search.selectedColumns.length !== Object.keys(SHOW_COLUMN_DICT[searchParams.reportId]).length - 1) isDisabled = true;
        };

        // if (!state.search.selectedColumns.includes('all')) {
        //     const metaDataArray = Object.keys(REPORT_METADATA_DICT);
        //     const selectedMetadataInShowColumns = state.search.selectedColumns.filter((d) => metaDataArray.includes(d));
        //     if (state.search.selectedColumns.length === 0) {
        //         isDisabled = true;
        //     }
        //      else if (state.search.selectedBurstReports[0] === "Do Not Burst") {
        //         if (selectedMetadataInShowColumns.length === 0) isDisabled = true;
        //     } 
        //     else if (state.search.selectedColumns.length < state.search.selectedBurstReports.length) {
        //         isDisabled = true;
        //     } else if (selectedMetadataInShowColumns.length === state.search.selectedBurstReports.length && selectedMetadataInShowColumns.length < 4) {
        //         isDisabled = true;
        //     }
        // }

        return isDisabled;
    };

    const isBurstOptionDisabled = (key: string) => {
        let isDisabled = false;
        if (key === 'Firms' && state.search.selectedBurstReports.includes('Firm Long Name')) isDisabled = true;
        else if (key === 'Firm Long Name' && state.search.selectedBurstReports.includes('Firms')) isDisabled = true;

        return isDisabled;
    }

    const isShowColumnDisabled = (key: string) => {
        let isDisabled = false;
        if (key === 'Firms' && state.search.selectedColumns.includes('Firm Long Name')) isDisabled = true;
        else if (key === 'Firm Long Name' && state.search.selectedColumns.includes('Firms')) isDisabled = true;

        return isDisabled;
    }

    const MultiValueRemove = (props) => {
        if (props.data.isFixed) {
            return null;
        }
        return <components.MultiValueRemove {...props} />;
    };

    const onShowColumnChange = (data) => {
        data = data || [];
        let newColumns = data.map((d) => d.value);

        const metadata = Object.keys(SHOW_COLUMN_DICT[searchParams.reportId]);
        state.search.selectedBurstReports.forEach((d) => {
            if (!['Do Not Burst', 'Firms', 'Firm Long Name'].includes(d) && !newColumns.includes(d)) {
                newColumns = state.search.selectedColumns;
                showToast("You can't remove the burst report column", "error");
            } else if (['Firms', 'Firm Long Name'].includes(d) && !newColumns.includes('Firms') && !newColumns.includes('Firm Long Name')) {
                let selectedColumnList = state.search.selectedColumns;
                let key = 'Firms';
                let isIncludedFirms = selectedColumnList.includes(key);
                if (!isIncludedFirms) key = 'Firm Long Name';

                const findIndex = selectedColumnList.findIndex((d) => d === key);
                selectedColumnList[findIndex] = isIncludedFirms ? 'Firm Long Name' : 'Firms';
                newColumns = selectedColumnList;
            };
        })
        // if (state.search.selectedBurstReports[0] !== 'Do Not Burst') {
        //     if (newColumns.length <= state.search.selectedBurstReports.length) {
        //         // extra item that need to push
        //         const itemsNotPresentInSelectedColumn = metadata.filter((d) => !newColumns.includes(d));
        //         if (itemsNotPresentInSelectedColumn.length > 0) newColumns.push(itemsNotPresentInSelectedColumn[0]);
        //     }
        // }

        // check that selected column is already selected in row or not for report id 5
        if (searchParams.reportId === "5") {
            newColumns.forEach((d) => {
                if (state.search.selectedRows.includes(d)) {
                    newColumns = state.search.selectedColumns;
                    showToast("You can't select this column as it is selected as row", "error");
                }
            })
        }

        // check selected sort by present in show columns or not
        let selectedSortByArray = state.search.burstReportSortBy || [];
        if (selectedSortByArray.length > 0 && metadata.includes(selectedSortByArray[0]) && !newColumns.includes(selectedSortByArray[0])) {
            selectedSortByArray = newColumns.length > 0 ? [newColumns[0]] : [];
        }
        if (selectedSortByArray.length === 0) selectedSortByArray = newColumns.length > 0 ? [newColumns[0]] : [];

        setState({
            ...state,
            search: {
                ...state.search,
                selectedColumns: newColumns,
                burstReportSortBy: selectedSortByArray
            }
        });
        // setLastRowPerPageDict({});
    };

    const onShowRowChange = (data) => {
        data = data || [];
        let newRows = data.map((d) => d.value);

        newRows.forEach((d) => {
            if (state.search.selectedColumns.includes(d)) {
                newRows = state.search.selectedRows;
                showToast("You can't select this row as it is selected as column", "error");
            }
        })

        setState({
            ...state,
            search: {
                ...state.search,
                selectedRows: newRows,
            }
        });
    };
    const onShowEntriesChange = (data) => {
        data = data || [];
        let newRows = data.map((d) => d.value);
        let selectedMetrics = [];

        const initialValue = INITIAL_SELECTED_SHOW_ENTRIES_DICT[searchParams.reportId][0];
        const initialMetricsValueArray = INITIAL_SELECTED_METRICS_DICT[searchParams.reportId];
        if (newRows.length > 0) {
            if (!state.search.showEntriesWhen.includes(initialValue)) {
                if (newRows.includes(initialValue)) newRows = [initialValue];
            } else if (newRows.length > 1) {
                newRows = newRows.filter((d) => d !== initialValue);
            }
            // add metrics related to when entries
            newRows.forEach((d) => {
                const mapValues = SHOW_ENTRIES_WHEN_MAP_WITH_SHOW_METRICS[searchParams.reportId][d]
                if (mapValues && mapValues?.length > 0) {
                    selectedMetrics = [...selectedMetrics, ...mapValues];
                }
            })
            // check is the metrics array empty
            if (selectedMetrics.length === 0) {
                selectedMetrics = [...initialMetricsValueArray];
            }
        } else {
            newRows = [initialValue];
            selectedMetrics = [...initialMetricsValueArray];
        }

        if (searchParams.reportId === "7") {
            setState({
                ...state,
                search: {
                    ...state.search,
                    showEntriesWhen: newRows,
                    selectedMetrics: selectedMetrics
                }
            });
        } else {
            setState({
                ...state,
                search: {
                    ...state.search,
                    showEntriesWhen: newRows,
                    // selectedMetrics: selectedMetrics
                }
            });
        }
        // setLastRowPerPageDict({});
    };

    const onSelectedBinsChange = (data) => {
        setState({
            ...state,
            search: {
                ...state.search,
                selectedBins: data ? data.value : "20",
            }
        });
    };

    // react select with drag and drop option
    const onSortEndShowColumn: SortEndHandler = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(state.search.selectedColumns, oldIndex, newIndex);
        setState({ ...state, search: { ...state.search, selectedColumns: newValue } });
        console.log(
            'Values sorted:',
            newValue.map((i) => i)
            // newValue.map((i) => i.value)
        );
    };
    const onSortEndShowEntries: SortEndHandler = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(state.search.showEntriesWhen, oldIndex, newIndex);
        setState({ ...state, search: { ...state.search, showEntriesWhen: newValue } });
        console.log(
            'Values sorted:',
            newValue.map((i) => i)
            // newValue.map((i) => i.value)
        );
    };

    const onShowMetricsChange = (data) => {
        let newMetrics = [];
        if (data && data.length > 0) {
            newMetrics = data.map((d) => d.value);
            if (!state.search.selectedMetrics.includes('all')) {
                if (newMetrics.includes('all')) newMetrics = ['all'];
            } else {
                newMetrics = newMetrics.filter((d) => d !== "all");
            }
            // setSelectedMetrics(newMetrics);
        }
        // else {
        //     newMetrics = INITIAL_SELECTED_METRICS_DICT[searchParams.reportId];
        //     // setSelectedMetrics(['all']);
        // }

        // check selected sort by present in show metrics or not
        const metadata = Object.keys(SHOW_COLUMN_DICT[searchParams.reportId]);
        let selectedSortByArray = state.search.burstReportSortBy || [];
        if (!newMetrics.includes('all') && selectedSortByArray.length > 0 && !metadata.includes(selectedSortByArray[0]) && !newMetrics.includes(selectedSortByArray[0])) {
            selectedSortByArray = [newMetrics[0]];
        }
        if (selectedSortByArray.length === 0) selectedSortByArray = newMetrics.length > 0 ? [newMetrics[0]] : [];

        setState({
            ...state,
            search: {
                ...state.search,
                selectedMetrics: newMetrics,
                burstReportSortBy: selectedSortByArray
            }
        });
    };

    // react select with drag and drop option
    const onSortEndShowMetrics: SortEndHandler = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(state.search.selectedMetrics, oldIndex, newIndex);
        setState({ ...state, search: { ...state.search, selectedMetrics: newValue } });
        console.log(
            'Values sorted:',
            newValue.map((i) => i)
            // newValue.map((i) => i.value)
        );
    };

    const onBurstReportChange = (data) => {
        let tempSearch = { ...state.search };
        const reportMetaDataArray = Object.keys(SHOW_COLUMN_DICT[searchParams.reportId]);
        if (data && data.length > 0) {
            let newBurstReports = data.map((d) => d.value);
            if (!state.search.selectedBurstReports.includes('Do Not Burst')) {
                if (newBurstReports.includes('Do Not Burst')) newBurstReports = ['Do Not Burst'];
            } else if (newBurstReports.length > 1) {
                newBurstReports = newBurstReports.filter((d) => d !== "Do Not Burst");
            }
            tempSearch.selectedBurstReports = newBurstReports;

            // if (!tempSearch.selectedColumns.includes('all')) {
            // let selectedColumns = tempSearch.selectedColumns.filter((d) => !reportMetaDataArray.includes(d));
            let selectedColumns = [];
            let lastIndex = -1;
            newBurstReports.forEach((d, index) => {
                if (d === 'Do Not Burst') {
                    selectedColumns = INITIAL_SELECTED_COLUMN_DICT[searchParams.reportId] && INITIAL_SELECTED_COLUMN_DICT[searchParams.reportId]?.length > 0 ? [...INITIAL_SELECTED_COLUMN_DICT[searchParams.reportId]] : [];
                    // selectedColumns = [...INITIAL_SELECTED_COLUMN];
                } else {
                    // const relatedShowColumn = BURST_REPORT_TO_METADATA_DICT[d];
                    // if (!selectedColumns.includes(d)) selectedColumns.push(d);
                    if (!selectedColumns.includes(d)) selectedColumns.splice(index, 0, d);
                    if (newBurstReports.length - 1 === index) lastIndex = index;
                }
            })
            if (newBurstReports[0] !== 'Do Not Burst') {
                // extra item that need to push
                let itemsNotPresentInSelectedColumn = reportMetaDataArray.filter((d) => d !== 'Firm Long Name' && !selectedColumns.includes(d));
                if (newBurstReports.includes('Firm Long Name')) itemsNotPresentInSelectedColumn = itemsNotPresentInSelectedColumn.filter((d) => d !== 'Firms')
                if (itemsNotPresentInSelectedColumn.length > 0) selectedColumns.splice(lastIndex + 1, 0, itemsNotPresentInSelectedColumn[0]);
                // if (itemsNotPresentInSelectedColumn.length > 0) selectedColumns.push(itemsNotPresentInSelectedColumn[0]);
            }

            // check if firm long name is selected in show columns and if yes then it should be there
            if (!(!state.search.selectedBurstReports.includes('Firms') && newBurstReports.includes('Firms'))) {
                selectedColumns = updateSelectedColumnOnBurstReportChange(selectedColumns);
            }

            // setSelectedMetrics(selectedColumns);
            tempSearch.selectedColumns = selectedColumns;
            // }
        } else {
            tempSearch.selectedBurstReports = ['Do Not Burst'];
            // check if firm long name is selected in show columns and if yes then it should be there
            let selectedColumnList = updateSelectedColumnOnBurstReportChange(INITIAL_SELECTED_COLUMN_DICT[searchParams.reportId] || []);
            tempSearch.selectedColumns = selectedColumnList;
            // if (!tempSearch.selectedColumns.includes('all')) {
            //     // setSelectedMetrics([...INITIAL_SELECTED_COLUMN]);
            //     tempSearch.selectedColumns = [...INITIAL_SELECTED_COLUMN];
            // }
        } setLastRowPerPageDict({});
        // check selected sort by present in show columns or not
        let selectedSortByArray = state.search.burstReportSortBy || [];
        if (selectedSortByArray.length > 0 && reportMetaDataArray.includes(selectedSortByArray[0]) && !tempSearch.selectedColumns.includes(selectedSortByArray[0])) {
            selectedSortByArray = tempSearch.selectedColumns.length > 0 ? [tempSearch.selectedColumns[0]] : [];
        }
        if (selectedSortByArray.length === 0) selectedSortByArray = tempSearch.selectedColumns.length > 0 ? [tempSearch.selectedColumns[0]] : [];
        tempSearch.burstReportSortBy = selectedSortByArray;
        setState({
            ...state,
            search: tempSearch
        });

        // getAllReportsBasedOnFilter(tempSearch)
    };

    const updateSelectedColumnOnBurstReportChange = (selectedColumnList: string[]) => {
        if (state.search.selectedColumns.includes('Firm Long Name') && !selectedColumnList.includes('Firm Long Name')) {
            let index = selectedColumnList.findIndex((d) => d === 'Firms');
            if (index !== -1) {
                selectedColumnList[index] = 'Firm Long Name';
            } else {
                selectedColumnList = [...selectedColumnList, 'Firm Long Name'];
            }
        }
        return selectedColumnList;
    }

    const handleDownloadAsCsv = async (search: any, isZip?: boolean) => {
        let searchObj = { ...state.search };
        if (search) {
            searchObj = { ...searchObj, ...search };
        }
        // updating searchObj as it is required
        searchObj = getRequiredThingDoneBeforeCall(searchObj);

        let obj = {
            ...searchObj,
            selectedMetrics: searchObj.selectedMetrics.includes('all') ? Object.values(SHOW_METRICS_DICT[searchParams.reportId]).filter((d) => d != "All") : searchObj.selectedMetrics.map((d) => SHOW_METRICS_DICT[searchParams.reportId][d]),
            isZip: isZip ? true : false,
            localTime: moment().format("YYYYMMDDHHmmss")
        };
        obj = removeNulls(obj);
        let endPoint = "csv-burst-report"
        switch (searchParams.reportId) {
            case "1":
                endPoint = "csv-detail-burst-report";
                break;
            case "5":
                endPoint = "cross-tab-report"
                break;
            case "3":
                endPoint = "csv-imported-detail-burst-report"
                break;
            case "7":
                endPoint = "csv-changed-time-entries-report";
                break;
            case "8":
                endPoint = "csv-split-time-entries-report";
                break;
            default:
                break;
        }
        // endPoint = searchParams.reportId === "1" ? 'csv-detail-burst-report' : searchParams.reportId === '5' ? 'cross-tab-report' : 'csv-burst-report';

        let result = await fetch(
            `${API_URL}timesheets/${endPoint}`,
            {
                method: "POST",
                body: JSON.stringify(obj),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: "Bearer " + (getToken() || ""),
                    dbName: getSubDomain(),
                },
            }
        );
        let pdf = await result.blob();
        const pdfFile = await new Blob([pdf], {
            type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
        });
        const pdfFileURL = await URL.createObjectURL(pdfFile);
        var a: any = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display:none";
        a.href = pdfFileURL;
        a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "csv", "case");
        a.click();

    };
    const handleDownload = async (search: any, isZip?: boolean) => {
        let searchObj = { ...state.search };
        if (search) {
            searchObj = { ...searchObj, ...search };
        }

        // updating searchObj as it is required
        searchObj = getRequiredThingDoneBeforeCall(searchObj);

        let obj = {
            ...searchObj,
            selectedMetrics: searchObj.selectedMetrics.includes('all') ? Object.values(SHOW_METRICS_DICT[searchParams.reportId]).filter((d) => d != "All") : searchObj.selectedMetrics.map((d) => SHOW_METRICS_DICT[searchParams.reportId][d]),
            isZip: isZip ? true : false,
            localTime: moment().format("YYYYMMDDHHmmss")
        };
        obj = removeNulls(obj);

        let endPoint = "pdf-burst-report";
        switch (searchParams.reportId) {
            case "1":
                endPoint = "pdf-detail-burst-report";
                break;
            case "3":
                endPoint = "pdf-imported-time-details-report";
                break;
            case "7":
                endPoint = "pdf-changed-time-entries-report";
                break;
            case "8":
                endPoint = "pdf-split-time-entries-report";
                break;
            default:
                break;
        }

        let result = await fetch(
            `${API_URL}timesheets/${endPoint}`,
            {
                method: "POST",
                body: JSON.stringify(obj),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: "Bearer " + (getToken() || ""),
                    dbName: getSubDomain(),
                },
            }
        );
        let pdf = await result.blob();
        const pdfFile = await new Blob([pdf], {
            type: pdf.type === "application/zip" ? "octet/stream" : "application/pdf",
        });
        const pdfFileURL = await URL.createObjectURL(pdfFile);
        var a: any = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display:none";
        a.href = pdfFileURL;
        a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "pdf", "case");
        a.click();
    };

    const onExport = async (search: any) => {
        if (selectedReportOutput === "csv") {
            // setLoading(true);
            // setLoadingMessage("Exporting data. Please stand by..");
            await handleDownloadAsCsv(search);
            // setLoadingMessage("");
            // setLoading(false);
        } else {
            // setLoading(true);
            // setLoadingMessage("Exporting data. Please stand by..");
            await handleDownload(search);
            // setLoadingMessage("");
            // setLoading(false);
        }
    };

    const onTimeSummaryReportSort = async (sortBy) => {
        let search = { ...state.search };
        if (search.burstReportSortBy?.length > 0 && sortBy === search.burstReportSortBy[0]) {
            search.sortOrder = search.sortOrder === "asc" ? "desc" : "asc";
        } else {
            search.burstReportSortBy = [sortBy];
            search.sortOrder = "asc";
        }
        search.from = 0;
        await getAllReportsBasedOnFilter(search);
    };

    const classes = styles();

    return (
        <React.Fragment>
            {isLoading && <Loading message={loadingMessage} />}
            <section style={{ padding: '16px 16px 16px 16px' }}>
                <div className="text-center">
                    <Typography variant="h5">{REPORTS_DICT[searchParams.reportId]}</Typography>
                </div>
                <br />
                <Grid className={`${classes.main8}`}>
                    <Hidden mdDown>
                        <Grid
                            item
                            className={`timesheet-filters-height ${isFilterOpen ? classes.filterOpenContainer : classes.filterCloseContainer}`}
                        >
                            {filterData.length > 0 && (
                                <Filter
                                    data={[...filterData]}
                                    options={state.options || []}
                                    onChange={(data: IFilter[], changedProperty?: string) => {
                                        onFilterChange(data, changedProperty);
                                    }}
                                    isFromManagementDashboard={fromManagementDashboard.current}
                                    managementDashboardId={loadedFilter?.timeManagementDashboardId && managementDashboardId.current && loadedFilter.timeManagementDashboardId === managementDashboardId.current ? managementDashboardId.current : undefined}
                                    typeOfFilters="timesheets"
                                    selectedFilters={state.search}
                                    loadedFilter={loadedFilter}
                                    dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                                />
                            )}
                        </Grid>
                    </Hidden>
                    <ToggleFilterSection showFilterClass='toggle-filters-report-container' classes={classes} toggleFilter={() => setFilterOpen(!isFilterOpen)} isFilterOpen={isFilterOpen} />
                    <Grid className={`${isFilterOpen ? classes.mainOpenContainer : classes.mainCloseContainer} report-right-side-container`}>
                        <Grid container spacing={2} justifyContent="flex-start">
                            {["7", "8"].includes(searchParams.reportId) && <Grid item xs={12} sm={6} md={3} lg={3} className="position-relative report-react-select">
                                <InputLabel className="custom-react-select-label">
                                    Show Entries When
                                </InputLabel>
                                <SortableSelect
                                    useDragHandle
                                    axis="xy"
                                    onSortEnd={onSortEndShowEntries}
                                    distance={4}
                                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                                    isMulti={true}
                                    placeholder="Show Entries When"
                                    options={Object.keys(SHOW_ENTRIES_WHEN[searchParams.reportId]).map((key) => {
                                        return { label: SHOW_ENTRIES_WHEN[searchParams.reportId][key], value: key, isDisabled: isShowColumnDisabled(key) } as any
                                    })}
                                    // options={Object.keys(CHANGED_TIME_ENTRIES_SHOW_ENTRIES_DICT).map((key) => {
                                    //     return { label: CHANGED_TIME_ENTRIES_SHOW_ENTRIES_DICT[key], value: key, isDisabled: isShowColumnDisabled(key) } as any
                                    // })}
                                    value={state.search.showEntriesWhen ? state.search.showEntriesWhen.map((d) => ({ value: d, label: SHOW_ENTRIES_WHEN[searchParams.reportId][d] })) : []}
                                    onChange={onShowEntriesChange}
                                    components={{
                                        // @ts-ignore We're failing to provide a required index prop to SortableElement
                                        MultiValue: SortableMultiValue,
                                        MultiValueLabel: SortableMultiValueLabel,
                                    }}
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                    styles={reactShowEntriesWhenSelectStyles}
                                />
                            </Grid>}
                            <Grid item xs={12} sm={6} md={3} lg={["7", "8"].includes(searchParams.reportId) ? 9 : 3} className="position-relative report-react-select">
                                <InputLabel className="custom-react-select-label">
                                    Show Columns
                                </InputLabel>
                                <SortableSelect
                                    useDragHandle
                                    // react-sortable-hoc props:
                                    axis="xy"
                                    onSortEnd={onSortEndShowColumn}
                                    distance={4}
                                    // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                                    // react-select props:
                                    isMulti={true}
                                    placeholder="Select Columns"
                                    // className="react-select"
                                    options={Object.keys(SHOW_COLUMN_DICT[searchParams.reportId]).map((key) => {
                                        return { label: SHOW_COLUMN_DICT[searchParams.reportId][key], value: key, isDisabled: isShowColumnDisabled(key) } as any
                                    })}
                                    value={state.search.selectedColumns.map((d) => ({ value: d, label: SHOW_COLUMN_DICT[searchParams.reportId][d] }))}
                                    onChange={onShowColumnChange}
                                    components={{
                                        // @ts-ignore We're failing to provide a required index prop to SortableElement
                                        MultiValue: SortableMultiValue,
                                        MultiValueLabel: SortableMultiValueLabel,
                                    }}
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                    styles={reactShowColumnsSelectStyles}
                                />
                                {/* <Select
                                    className="react-select"
                                    placeholder="Select Metrics"
                                    value={state.search.selectedColumns.map((d) => {
                                        let obj = { value: d, label: SHOW_COLUMN_DICT[searchParams.reportId][d], isFixed: false };
                                        // const relatedBurstReport = METADATA_TO_BURST_REPORT_DICT[d];
                                        if (d && state.search.selectedBurstReports.includes(d)) obj.isFixed = true;
                                        // if (d === "firmName" && state.search.selectedBurstReports.includes('Do Not Burst')) obj.isFixed = true;
                                        return obj;
                                    })}
                                    isMulti={true}
                                    onChange={(data) => {
                                        let newMetrics = [];
                                        if (data && data.length > 0) {
                                            newMetrics = data.map((d) => d.value);
                                            if (!state.search.selectedColumns.includes('all')) {
                                                if (newMetrics.includes('all')) newMetrics = ['all'];
                                            } else if (newMetrics.length > 1) {
                                                newMetrics = newMetrics.filter((d) => d !== "all");
                                                // check is the all selected burst report is present in show columns
                                                const metadata = Object.keys(REPORT_METADATA);
                                                state.search.selectedBurstReports.forEach((d) => {
                                                    if (d === 'Do Not Burst') {
                                                        const metadataPresentInNewMetrics = newMetrics.filter((d) => metadata.includes(d));
                                                        if (metadataPresentInNewMetrics.length === 0) {
                                                            newMetrics = [...newMetrics, metadata[0]];
                                                        }
                                                    } else {
                                                        // const relatedShowColumn = BURST_REPORT_TO_METADATA_DICT[d];
                                                        if (!newMetrics.includes(d)) newMetrics.push(d);
                                                    }
                                                })
                                                if (state.search.selectedBurstReports[0] !== 'Do Not Burst') {
                                                    const metadataPresentInNewMetrics = newMetrics.filter((d) => metadata.includes(d));
                                                    if (metadataPresentInNewMetrics.length <= state.search.selectedBurstReports.length) {
                                                        // extra item that need to push
                                                        const itemsNotPresentInSelectedColumn = metadata.filter((d) => !newMetrics.includes(d));
                                                        if (itemsNotPresentInSelectedColumn.length > 0) newMetrics.push(itemsNotPresentInSelectedColumn[0]);
                                                    }
                                                }
                                            }
                                            // setSelectedMetrics(newMetrics);
                                        } else {
                                            newMetrics = ['all'];
                                            // setSelectedMetrics(['all']);
                                        }
                                        setState({
                                            ...state,
                                            search: {
                                                ...state.search,
                                                selectedColumns: newMetrics
                                            }
                                        });
                                    }}
                                    options={Object.keys(SHOW_COLUMN_DICT[searchParams.reportId]).map((key) => {
                                        return { label: SHOW_COLUMN_DICT[searchParams.reportId][key], value: key } as any
                                    })}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                    components={{ MultiValueRemove }}
                                /> */}
                            </Grid>
                            {searchParams.reportId === "6" && <Grid item xs={12} sm={6} md={3} lg={3} className="position-relative report-react-select">
                                <InputLabel className="custom-react-select-label">
                                    No of Bins
                                </InputLabel>
                                <SortableSelect
                                    axis="xy"
                                    distance={4}
                                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                                    placeholder="No of Bins"
                                    options={new Array(50).fill(0).map((key, index) => {
                                        return { label: (index + 1).toString(), value: (index + 1).toString() } as any
                                    })}
                                    value={{ label: state.search.selectedBins, value: state.search.selectedBins }}
                                    onChange={onSelectedBinsChange}
                                    components={{
                                        // @ts-ignore We're failing to provide a required index prop to SortableElement
                                        MultiValue: SortableMultiValue,
                                        MultiValueLabel: SortableMultiValueLabel,
                                    }}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                />
                            </Grid>}
                            {searchParams.reportId === "5" && <Grid item xs={12} sm={6} md={3} lg={3} className="position-relative report-react-select">
                                <InputLabel className="custom-react-select-label">
                                    Show Rows
                                </InputLabel>
                                <SortableSelect
                                    useDragHandle
                                    axis="xy"
                                    onSortEnd={onSortEndShowColumn}
                                    distance={4}
                                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                                    isMulti={true}
                                    placeholder="Select Rows"
                                    options={Object.keys(searchParams.reportId !== "5" ? REPORT_METADATA_DICT : TIME_SUMMARY_CROSS_TABLE_REPORT_DICT).map((key) => {
                                        return { label: searchParams.reportId !== "5" ? REPORT_METADATA_DICT[key] : TIME_SUMMARY_CROSS_TABLE_REPORT_DICT[key], value: key, isDisabled: isShowColumnDisabled(key) } as any
                                    })}
                                    value={state.search.selectedRows.map((d) => ({ value: d, label: searchParams.reportId !== "5" ? REPORT_METADATA_DICT[d] : TIME_SUMMARY_CROSS_TABLE_REPORT_DICT[d] }))}
                                    onChange={onShowRowChange}
                                    components={{
                                        // @ts-ignore We're failing to provide a required index prop to SortableElement
                                        MultiValue: SortableMultiValue,
                                        MultiValueLabel: SortableMultiValueLabel,
                                    }}
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                />
                            </Grid>}
                            <Grid item xs={12} sm={6} md={3} lg={3} className="position-relative report-react-select">
                                <InputLabel className="custom-react-select-label">
                                    Show Metrics
                                </InputLabel>
                                <SortableSelect
                                    useDragHandle
                                    // react-sortable-hoc props:
                                    axis="xy"
                                    onSortEnd={onSortEndShowMetrics}
                                    distance={4}
                                    // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                                    // react-select props:
                                    isMulti={true}
                                    placeholder="Select Metrics"
                                    // className="react-select"
                                    options={Object.keys(SHOW_METRICS_DICT[searchParams.reportId]).map((key) => {
                                        return { label: SHOW_METRICS_DICT[searchParams.reportId][key], value: key } as any
                                    })}
                                    value={state.search.selectedMetrics?.length > 0 ? state.search.selectedMetrics.map((d) => ({ value: d, label: SHOW_METRICS_DICT[searchParams.reportId][d] })) : []}
                                    onChange={onShowMetricsChange}
                                    components={{
                                        // @ts-ignore We're failing to provide a required index prop to SortableElement
                                        MultiValue: SortableMultiValue,
                                        MultiValueLabel: SortableMultiValueLabel,
                                    }}
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                />
                            </Grid>
                            {searchParams.reportId !== "5" && <Grid item xs={12} sm={6} md={3} lg={3} className="position-relative report-react-select">
                                <InputLabel className="custom-react-select-label">
                                    Burst Reports
                                </InputLabel>
                                <Select
                                    className="react-select"
                                    placeholder="Burst Report"
                                    value={state.search.selectedBurstReports.map((d) => ({ value: d, label: BURST_REPORTS_DICT_BY_REPORT_ID[searchParams.reportId][d], isFixed: d === 'Do Not Burst' ? true : false }))}
                                    isMulti={true}
                                    onChange={onBurstReportChange}
                                    options={Object.keys(BURST_REPORTS_DICT_BY_REPORT_ID[searchParams.reportId]).map((key) => {
                                        return searchParams.reportId === "6" ? undefined : { label: BURST_REPORTS_DICT_BY_REPORT_ID[searchParams.reportId][key], value: key, isDisabled: isBurstOptionDisabled(key) } as any
                                        // return { label: BURST_REPORTS_DICT_BY_REPORT_ID[searchParams.reportId][key], value: key, isDisabled: isBurstOptionDisabled(key) } as any
                                    }).filter(x => x)}
                                    isClearable={false}
                                    components={{ MultiValueRemove }}
                                    styles={reactSelectStyles}
                                />
                            </Grid>}
                            {searchParams.reportId === '3' && (
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <FormControlLabel
                                        control={
                                            <Switch size="small"
                                                checked={state.search.hideDetails ? true : false}
                                                onChange={() => {

                                                    setState({
                                                        ...state,
                                                        search: {
                                                            ...state.search,
                                                            hideDetails: !state.search.hideDetails
                                                        }
                                                    })
                                                }}
                                            />
                                        }
                                        label={"Hide Details"}
                                    />
                                </Grid>
                            )}
                            {/* {searchParams.reportId !== "5" && */}
                            <Grid item xs={12} sm={6} md={3} lg={3} className="position-relative report-react-select">
                                <InputLabel className="custom-react-select-label">
                                    Report Output
                                </InputLabel>
                                <Select
                                    name="reportOutput"
                                    className="react-select"
                                    placeholder="Report Output"
                                    value={{ label: searchParams.reportId === '5' && selectedReportOutput === 'csv' ? 'Excel' : REPORT_OUTPUT_DICT[selectedReportOutput] || "", value: selectedReportOutput }}
                                    onChange={(data) => {
                                        setSelectedReportOutput(data.value);
                                    }}
                                    options={Object.keys(REPORT_OUTPUT_DICT).filter((key) => searchParams.reportId === '5' ? key !== 'pdf' : true).map((key) => {
                                        return { label: searchParams.reportId === '5' && key === 'csv' ? 'Excel' : REPORT_OUTPUT_DICT[key], value: key } as any
                                    })}
                                // isClearable={true}
                                />
                            </Grid>
                            {/* } */}
                            {/* {searchParams.reportId !== "5" && <Grid item xs={12} sm={6} md={3} lg={3} className="position-relative report-react-select">
                                <InputLabel className="custom-react-select-label">
                                    Sort By
                                </InputLabel>
                                <Select
                                    name="burstReportSortBy"
                                    className="react-select"
                                    placeholder="Sort By"
                                    value={{ label: SORT_BY_DICT[searchParams.reportId][state.search.burstReportSortBy.length > 0 ? state.search.burstReportSortBy[0] : ""] || "", value: state.search.burstReportSortBy.length > 0 ? state.search.burstReportSortBy[0] : "" }}
                                    onChange={(data) => {
                                        setState({ ...state, search: { ...state.search, burstReportSortBy: [data.value] } });
                                    }}
                                    options={Object.keys(SORT_BY_DICT[searchParams.reportId]).filter((d) => {
                                        let selectedItems = [];
                                        if (state.search.selectedMetrics.includes('all')) {
                                            selectedItems = [...state.search.selectedColumns, ...Object.keys(SHOW_METRICS_DICT[searchParams.reportId]).filter((d) => d !== 'all')];
                                        } else {
                                            selectedItems = [...state.search.selectedColumns, ...state.search.selectedMetrics];
                                        }
                                        return selectedItems.includes(d);
                                    }).map((key) => {
                                        return { label: SORT_BY_DICT[searchParams.reportId][key], value: key } as any
                                    })}
                                // isClearable={true}
                                />
                            </Grid>}
                            {searchParams.reportId !== "5" && <Grid item xs={12} sm={6} md={2} lg={2} className="position-relative report-react-select">
                                <InputLabel className="custom-react-select-label">
                                    Sort Order
                                </InputLabel>
                                <Select
                                    name="sortOrder"
                                    className="react-select"
                                    placeholder="Sort Order"
                                    value={{ label: SORT_ORDER_DICT[state.search.sortOrder] || "", value: state.search.sortOrder }}
                                    onChange={(data) => {
                                        setState({ ...state, search: { ...state.search, sortOrder: data.value } });
                                    }}
                                    options={Object.keys(SORT_ORDER_DICT).map((key) => {
                                        return { label: SORT_ORDER_DICT[key], value: key } as any
                                    })}
                                // isClearable={true}
                                />
                            </Grid>} */}
                            <Grid item xs={12} sm={6} md={12} lg={12}>
                                <Grid container justify="flex-end" spacing={2}>
                                    {/* <Grid item>
                                        <Button variant="contained" color="primary" onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
                                            {`${moment(dateRange[0].startDate).format("YYYY-MM-DD")} - ${moment(dateRange[0].endDate).format("YYYY-MM-DD")}`}
                                        </Button>
                                        <Popover
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={() => setAnchorEl(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <DateRangePicker
                                                onChange={(item) => {
                                                    setDateRange([item.selection]);
                                                    // setAnchorEl(null)
                                                }}
                                                // showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                // months={2}
                                                ranges={dateRange}
                                                direction="horizontal"
                                                staticRanges={[
                                                    ...defaultStaticRanges,
                                                    {
                                                        label: "This Year",
                                                        range: () => ({
                                                            startDate: startOfYear(new Date()),
                                                            endDate: endOfYear(new Date())
                                                        }),
                                                        isSelected(range) {
                                                            const definedRange = this.range();
                                                            return (
                                                                isSameDay(range.startDate, definedRange.startDate) &&
                                                                isSameDay(range.endDate, definedRange.endDate)
                                                            );
                                                        }
                                                    },
                                                    {
                                                        label: "Last Year",
                                                        range: () => ({
                                                            startDate: startOfYear(addYears(new Date(), -1)),
                                                            endDate: endOfYear(addYears(new Date(), -1))
                                                        }),
                                                        isSelected(range) {
                                                            const definedRange = this.range();
                                                            return (
                                                                isSameDay(range.startDate, definedRange.startDate) &&
                                                                isSameDay(range.endDate, definedRange.endDate)
                                                            );
                                                        }
                                                    }
                                                ]}
                                                inputRanges={[]}
                                            />
                                        </Popover>
                                    </Grid> */}
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                onApplyFilter();
                                            }}
                                            disabled={isApplyFilterBtnDisabled()}
                                        >
                                            Run Report
                                        </Button>
                                    </Grid>
                                    <Hidden lgUp>
                                        <Grid item>
                                            {filterData.length > 0 && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => setFilterDialogOpen(true)}
                                                >
                                                    Filters
                                                </Button>
                                            )}
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <br /> */}
                        {!isLoading && (
                            <SelectedFilters
                                searchObject={state.search}
                                firmDict={firmsDic}
                                personDict={personsDic}
                                categoryDict={initialCategoryCodes}
                                workTypeDict={initialWorkTypes}
                                approvedByDict={initialApprovedBy}
                                professionalLevelDict={initialProfessionalLevels}
                                keywordDict={TIME_ENTRY_KEY_WORDS.reduce((acc, d) => {
                                    acc[d.value] = d.label;
                                    return acc;
                                }, {})}
                                statusDict={TIMESHEET_STATUS}
                                noteList={noteList}
                            />
                        )}
                        {/* <br /> */}
                        <Grid className="report-details-container">
                            {searchParams.reportId === "1" && isDataLoaded && (
                                <TimeSummaryReportView reportId={searchParams.reportId} tableData={timeSummaryReportData} selectedMetrics={selectedMetrics} selectedColumns={selectedColumns} selectedBurstReports={selectedBurstReports} lastRowPerPageDict={lastRowPerPageDict} setLastRowPerPageDict={setLastRowPerPageDict} />
                            )}
                            {searchParams.reportId === "2" && isDataLoaded && (
                                <TimeDetailedReport tableData={tableData} selectedMetrics={selectedMetrics} selectedColumns={selectedColumns} selectedBurstReports={selectedBurstReports} sortBy={state.search.burstReportSortBy?.length > 0 ? state.search.burstReportSortBy[0] : ""} sortOrder={state.search.sortOrder} onSort={onTimeSummaryReportSort} />
                            )}
                            {searchParams.reportId === "5" && isDataLoaded && (
                                <TimeSummaryCrossTableReport selectedMetrics={selectedMetrics} timeSummaryCrossTableReportData={timeSummaryCrossTableReportData} />
                            )}
                            {searchParams.reportId === "6" && isDataLoaded && (
                                <SummaryVisualizationReport tableData={tableData} selectedMetrics={selectedMetrics} selectedColumns={selectedColumns} selectedBurstReports={selectedBurstReports} />
                            )}
                            {searchParams.reportId === "3" && isDataLoaded && (
                                <ImportedTimeEntriesReport hideDetails={state.search.hideDetails} tableData={importedTimeReportData} selectedMetrics={selectedMetrics} selectedColumns={selectedColumns} selectedBurstReports={selectedBurstReports} lastRowPerPageDict={lastRowPerPageDict} setLastRowPerPageDict={setLastRowPerPageDict} />
                            )}
                            {searchParams.reportId === "7" && isDataLoaded && (
                                <React.Fragment>
                                    <TimeSummaryReportView reportId={searchParams.reportId} tableData={changedTimeEntries} selectedMetrics={selectedMetrics} selectedColumns={selectedColumns} selectedBurstReports={selectedBurstReports} lastRowPerPageDict={lastRowPerPageDict} setLastRowPerPageDict={setLastRowPerPageDict} />
                                </React.Fragment>
                            )}
                            {searchParams.reportId === "8" && isDataLoaded && (
                                <React.Fragment>
                                    <TimeSummaryReportView reportId={searchParams.reportId} tableData={splitEntriesReportData} selectedMetrics={selectedMetrics} selectedColumns={selectedColumns} selectedBurstReports={selectedBurstReports} lastRowPerPageDict={lastRowPerPageDict} setLastRowPerPageDict={setLastRowPerPageDict} />
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            {isFilterDialogOpen && (
                <CustomDrawer
                    title={"Filter"}
                    onClose={() => setFilterDialogOpen(false)}
                >
                    <section className="p-24">
                        <Filter
                            isPopup={true}
                            data={[...filterData]}
                            onChange={(data: IFilter[], changedProperty?: string) => {
                                onFilterChange(data, changedProperty);
                            }}
                            typeOfFilters="timesheets"
                            isFromManagementDashboard={fromManagementDashboard.current}
                            managementDashboardId={loadedFilter?.timeManagementDashboardId && managementDashboardId.current && loadedFilter.timeManagementDashboardId === managementDashboardId.current ? managementDashboardId.current : undefined}
                            selectedFilters={state.search}
                            loadedFilter={loadedFilter}
                            dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                        />
                    </section>
                </CustomDrawer>
            )}
        </React.Fragment>
    );
};

export default withConfirmDialogContext(ReportDetailsView);