import * as React from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableSortLabel
} from "@material-ui/core";
import {
    IReportDetails,
    IReportHeader,
    IReportResponse,
    IRportGroupBy
} from "../../vm";
import {
    StyledTableCell, StyledTableRow, convertToFixed, currencyFormat, hoursFormat, numberFormat,
} from "../../services/UtilService";
import {
    BURST_REPORTS_MAP_WITH_VALUE,
    METRICS_DICT,
    REPORT_GROUP_BY_NAME, REPORT_METADATA_DICT, TIME_SUMMARY_METRICS_MAP_WITH_VALUE,
} from "../../Constant";
import PieChart from "../common/charts/PieChart";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./report.css";

const rightAlignMetrics = ['fees', 'hours'];

interface TimeDetailedReportProps {
    tableData: IReportResponse[];
    selectedColumns: string[];
    selectedMetrics: string[];
    selectedBurstReports: string[];
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    onSort: Function;
}

const TimeDetailedReport: React.FC<TimeDetailedReportProps> = ({ tableData, selectedMetrics, selectedColumns, selectedBurstReports, sortBy, sortOrder, onSort }) => {
    const [formattedData, setFormattedData] = React.useState([] as any);
    const [selectedMetricsProps, setSelectedMetricsProps] = React.useState([] as string[]);

    React.useEffect(() => {
        let selectedMetricsPropsList = [...selectedMetrics];
        if (selectedMetrics.includes('all')) {
            selectedMetricsPropsList = Object.keys(METRICS_DICT).filter((d) => d !== 'all');
        }
        // let selectedMetricsPropsList = [];
        // const metadataKeys = Object.keys(REPORT_METADATA_DICT);
        // if (selectedMetrics.includes('all')) {
        //     selectedMetricsPropsList = Object.keys(METRICS_DICT).filter((d) => !metadataKeys.includes(d) && d !== 'all');
        // } else {
        //     selectedMetricsPropsList = selectedMetrics.filter((d) => !metadataKeys.includes(d));
        // }
        setSelectedMetricsProps(selectedMetricsPropsList);
        console.log(selectedMetricsPropsList);
        buildData();
    }, []);

    const buildData = () => {
        let parentArray = [];
        tableData.forEach((row) => {
            let childArray = [];
            if (row.data) {
                row.data.forEach((table, tableIndex) => {
                    if (table.groupByProp) {
                        const tableRowSpan = { [`${table.groupByProp}RowSpan`]: table.count };
                        const tableRowSpanZero = { [`${table.groupByProp}RowSpan`]: table.count > 1 ? 0 : 1 };
                        table.values.forEach((table2, table2Index) => {
                            if (table2.groupByProp) {
                                const table2RowSpan = { ...tableRowSpan, [`${table2.groupByProp}RowSpan`]: table2.count };
                                const table2RowSpanZero = { ...tableRowSpanZero, [`${table2.groupByProp}RowSpan`]: table2.groupByProp && table2.count > 1 ? 0 : 1 };
                                table2.values.forEach((table3, table3Index) => {
                                    if (table3.groupByProp) {
                                        const table3RowSpan = { ...table2RowSpan, [`${table3.groupByProp}RowSpan`]: table3.count };
                                        const table3RowSpanZero = { ...table2RowSpanZero, [`${table3.groupByProp}RowSpan`]: table3.groupByProp && table3.count > 1 ? 0 : 1 };
                                        table3.values.forEach((table4, table4Index) => {
                                            if (table4.groupByProp) {
                                                const table4RowSpan = { ...table3RowSpan, [`${table4.groupByProp}RowSpan`]: table4.count };
                                                const table4RowSpanZero = { ...table3RowSpanZero, [`${table4.groupByProp}RowSpan`]: table4.groupByProp && table4.count > 1 ? 0 : 1 };
                                                table4.values.forEach((table5, table5Index) => {
                                                    if (table5.groupByProp) {

                                                    } else {
                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0) {
                                                            childArray.push({ ...table5, ...table4RowSpan });
                                                        } else {
                                                            let obj = { ...table4RowSpanZero };
                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0) {
                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                            } else if (table4Index === 0 && table5Index === 0) {
                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                            } else if (table5Index === 0) {
                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                            }
                                                            childArray.push({ ...table5, ...obj });
                                                        }
                                                    }
                                                })
                                            } else {
                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0) {
                                                    childArray.push({ ...table4, ...table3RowSpan });
                                                } else {
                                                    let obj = { ...table3RowSpanZero };
                                                    if (table3Index === 0 && table4Index === 0) {
                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                    } else if (table4Index === 0) {
                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                    }
                                                    childArray.push({ ...table4, ...obj });
                                                }
                                            }
                                        })
                                    } else {
                                        if (table2Index === 0 && table3Index === 0) {
                                            childArray.push({ ...table3, ...table2RowSpan });
                                        } else {
                                            let obj = { ...table2RowSpanZero };
                                            if (table3Index === 0) {
                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                            }
                                            childArray.push({ ...table3, ...obj });
                                        }
                                    }
                                })
                            } else {
                                if (table2Index === 0) {
                                    childArray.push({ ...table2, ...tableRowSpan });
                                } else {
                                    childArray.push({ ...table2, ...tableRowSpanZero });

                                }
                            }
                        })
                    } else {
                        row.burstReportHeaders.forEach((header) => {
                            table = { ...table, [`${header.headerProperty}RowSpan`]: 1 };
                        })
                        childArray.push(table);
                    }
                })
            }
            console.log("CHILD", childArray);
            parentArray.push({ ...row, data: childArray });
        });
        setFormattedData(parentArray);
        console.log("PARENT", parentArray);
    };

    // const getReportRow = (headers: IReportHeader[], ele: IReportDetails, reportTable: IRportGroupBy, reportIndex: number, index: number, firstGroupBy?: IRportGroupBy, secondGroupBy?: IRportGroupBy) => {
    //     // console.log("reportTable", reportTable)
    //     return (
    //         <StyledTableRow key={`report_${reportIndex}_index_${index}`}>
    //             {firstGroupBy && ((reportIndex === 0 && !secondGroupBy) || (reportIndex === 0 && index === 0 && secondGroupBy)) && (
    //                 <StyledTableCell rowSpan={firstGroupBy.count || 1}>
    //                     {ele[firstGroupBy.groupByProp]}
    //                 </StyledTableCell>
    //             )}
    //             {(reportIndex === 0 && secondGroupBy) && (
    //                 <StyledTableCell rowSpan={secondGroupBy.count || 1}>
    //                     {ele[secondGroupBy.groupByProp]}
    //                 </StyledTableCell>
    //             )}
    //             {headers.map(header => (
    //                 <>
    //                     {(selectedMetrics.includes('all') || selectedMetrics.includes(header.headerName)) && reportTable?.groupByProp !== header.headerProperty && secondGroupBy?.groupByProp !== header.headerProperty && firstGroupBy?.groupByProp !== header.headerProperty && (
    //                         <>
    //                             <StyledTableCell>
    //                                 {ele[header.headerProperty]}
    //                             </StyledTableCell>
    //                         </>
    //                     )}
    //                 </>
    //             ))}
    //             {(selectedMetrics.includes('all') || selectedMetrics.includes('resources')) && (
    //                 <>
    //                     <StyledTableCell align="right">{convertToFixed(ele.resourceCount)}</StyledTableCell>
    //                     <StyledTableCell align="right">
    //                         {convertToFixed(ele.resourcePercent)}
    //                     </StyledTableCell>
    //                 </>
    //             )}
    //             {(selectedMetrics.includes('all') || selectedMetrics.includes('hours')) && (
    //                 <>
    //                     <StyledTableCell align="right">
    //                         {numberFormat(convertToFixed(ele.totalHoursCount))}
    //                     </StyledTableCell>
    //                     <StyledTableCell align="right">
    //                         {convertToFixed(ele.totalHoursPercent)}
    //                     </StyledTableCell>
    //                 </>
    //             )}
    //             {(selectedMetrics.includes('all') || selectedMetrics.includes('fees')) && (
    //                 <>
    //                     <StyledTableCell align="right">
    //                         {currencyFormat(convertToFixed(ele.feesTotalCount))}
    //                     </StyledTableCell>
    //                     <StyledTableCell align="right">
    //                         {convertToFixed(ele.feesTotalPercent)}
    //                     </StyledTableCell>
    //                 </>
    //             )}
    //             {(selectedMetrics.includes('all') || selectedMetrics.includes('entries')) && (
    //                 <>
    //                     <StyledTableCell align="right">
    //                         {numberFormat(convertToFixed(ele.timeEntriesCount))}
    //                     </StyledTableCell>
    //                     <StyledTableCell align="right">
    //                         {convertToFixed(ele.timeEntriesPercent)}
    //                     </StyledTableCell>
    //                 </>
    //             )}
    //             {(selectedMetrics.includes('all') || selectedMetrics.includes('months')) && (
    //                 <>
    //                     <StyledTableCell align="right">
    //                         {convertToFixed(ele.monthCount)}
    //                     </StyledTableCell>
    //                     <StyledTableCell align="right">
    //                         {convertToFixed(ele.monthPercent)}
    //                     </StyledTableCell>
    //                 </>
    //             )}
    //             {(selectedMetrics.includes('all') || selectedMetrics.includes('hourly-rates')) && (
    //                 <>
    //                     <StyledTableCell align="right">
    //                         {currencyFormat(convertToFixed(ele.minHourlyRate))}
    //                     </StyledTableCell>
    //                     <StyledTableCell align="right">
    //                         {currencyFormat(convertToFixed(ele.avgHourlyRate))}
    //                     </StyledTableCell>
    //                     <StyledTableCell align="right">
    //                         {currencyFormat(convertToFixed(ele.maxHourlyRate))}
    //                     </StyledTableCell>
    //                 </>
    //             )}
    //         </StyledTableRow>
    //     )
    // }

    return (
        <Grid container spacing={2}>
            {formattedData.length > 0 ? (
                <>
                    {formattedData.map((reportData, reportDataIndex) => (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={`table_${reportDataIndex}`}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container spacing={2}>
                                        {selectedBurstReports.filter((d) => d !== 'Do Not Burst' && reportData[BURST_REPORTS_MAP_WITH_VALUE[d]]).map((d, index) => {
                                            let label = "", value = "";
                                            switch (d) {
                                                case "Firms":
                                                    label = "Firm:"
                                                    value = reportData.firm;
                                                    break;
                                                case "Resource":
                                                    label = "Resource:"
                                                    value = reportData.resource;
                                                    break;
                                                case "Time Category":
                                                    label = "Time Category:"
                                                    value = reportData.timeCategory;
                                                    break;
                                                case "Professional Level":
                                                    label = "Professional Level:"
                                                    value = reportData.professionalLevelName;
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return (
                                                <Grid item key={index}>
                                                    <Grid container alignItems='center'>
                                                        <Grid item>
                                                            <Typography variant='subtitle2' style={{ marginRight: 4 }}>{`${label} `}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant='body2'>{value}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    {/* <Typography variant="subtitle2"> */}
                                    {/* {[reportData.firm, reportData.resource, reportData.timeCategory, reportData.professionalLevelName].map((d, index) => { */}
                                    {/* {selectedBurstReports.map((d, index) => {
                                            let text = "";
                                            if (d !== 'Do Not Burst') {
                                                switch (d) {
                                                    case "Firms":
                                                        text = reportData.firm ? `Firm: ${reportData.firm}` : "";
                                                        break;
                                                    case "Resource":
                                                        text = reportData.resource ? `Resource: ${reportData.resource}` : "";
                                                        break;
                                                    case "Time Category":
                                                        text = reportData.timeCategory ? `Time Category: ${reportData.timeCategory}` : "";
                                                        break;
                                                    case "Professional Level":
                                                        text = reportData.professionalLevelName ? `Professional Level: ${reportData.professionalLevelName}` : "";
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }
                                            return text;
                                        }).filter(x => x).join(", ")}
                                    </Typography> */}
                                </Grid>
                                <React.Fragment>
                                    {reportData.data && reportData.data.length > 0 ? (
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TableContainer className="moz-table-pb report-table-container">
                                                <Table size="small" className="custom-table report-table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {/* {reportData.data[0].groupByProp && <StyledTableCell rowSpan={2} align="center">{REPORT_GROUP_BY_NAME[reportData.data[0].groupByProp] || ""}</StyledTableCell>} */}

                                                            {reportData.burstReportHeaders.map(header => (
                                                                <>
                                                                    {(selectedColumns.includes(header.headerName)) && (
                                                                        <StyledTableCell className={`cell-${header.headerProperty}`} align='left'>
                                                                            <TableSortLabel
                                                                                active={sortBy === header.headerName}
                                                                                direction={sortOrder}
                                                                                onClick={() => {
                                                                                    onSort(header.headerName);
                                                                                }}
                                                                            >
                                                                                {header.headerName}
                                                                            </TableSortLabel>
                                                                        </StyledTableCell>
                                                                    )}
                                                                    {/* {(selectedColumns.includes(header.headerName)) && <StyledTableCell align="center">{header.headerName}</StyledTableCell>} */}
                                                                    {/* {(selectedMetrics.includes('all') || selectedMetrics.includes(header.headerName)) && <StyledTableCell rowSpan={2} align="center">{header.headerName}</StyledTableCell>} */}
                                                                </>
                                                            ))}
                                                            {selectedMetricsProps.map((metrics) => (
                                                                <StyledTableCell align='right' className={`cell-${metrics}`}>
                                                                    <TableSortLabel
                                                                        active={sortBy === metrics}
                                                                        direction={sortOrder}
                                                                        onClick={() => {
                                                                            onSort(metrics);
                                                                        }}
                                                                    >
                                                                        {METRICS_DICT[metrics]}
                                                                    </TableSortLabel>
                                                                </StyledTableCell>
                                                                // <StyledTableCell align="center">{METRICS_DICT[metrics]}</StyledTableCell>
                                                                // <StyledTableCell colSpan={metrics === 'hourly-rates' ? 3 : 2} align="center">{METRICS_DICT[metrics]}</StyledTableCell>
                                                            ))}
                                                            {/* {(selectedMetrics.includes('all') || selectedMetrics.includes('resources')) && (<StyledTableCell colSpan={2} align="center">Resources</StyledTableCell>)}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('hours')) && (<StyledTableCell colSpan={2} align="center">Hours</StyledTableCell>)}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('fees')) && (<StyledTableCell colSpan={2} align="center">Fees</StyledTableCell>)}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('entries')) && (<StyledTableCell colSpan={2} align="center">Time Entries</StyledTableCell>)}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('months')) && (<StyledTableCell colSpan={2} align="center">Months</StyledTableCell>)}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('hourly-rates')) && (<StyledTableCell colSpan={3} align="center">Hourly Rate</StyledTableCell>)} */}
                                                        </TableRow>
                                                        {/* <TableRow> */}
                                                        {/* {selectedMetricsProps.map((metrics) => metrics === 'hourly-rates' ? (
                                                                <>
                                                                    <StyledTableCell>High</StyledTableCell>
                                                                    <StyledTableCell>Avg</StyledTableCell>
                                                                    <StyledTableCell>Low</StyledTableCell>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <StyledTableCell>#</StyledTableCell>
                                                                    <StyledTableCell>%</StyledTableCell>
                                                                </>
                                                            ))} */}
                                                        {/* {(selectedMetrics.includes('all') || selectedMetrics.includes('resources')) && (
                                                                <>
                                                                    <StyledTableCell>#</StyledTableCell>
                                                                    <StyledTableCell>%</StyledTableCell>
                                                                </>
                                                            )}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('hours')) && (
                                                                <>
                                                                    <StyledTableCell>#</StyledTableCell>
                                                                    <StyledTableCell>%</StyledTableCell>
                                                                </>
                                                            )}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('fees')) && (
                                                                <>
                                                                    <StyledTableCell>#</StyledTableCell>
                                                                    <StyledTableCell>%</StyledTableCell>
                                                                </>
                                                            )}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('entries')) && (
                                                                <>
                                                                    <StyledTableCell>#</StyledTableCell>
                                                                    <StyledTableCell>%</StyledTableCell>
                                                                </>
                                                            )}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('months')) && (
                                                                <>
                                                                    <StyledTableCell>#</StyledTableCell>
                                                                    <StyledTableCell>%</StyledTableCell>
                                                                </>
                                                            )}
                                                            {(selectedMetrics.includes('all') || selectedMetrics.includes('hourly-rates')) && (
                                                                <>
                                                                    <StyledTableCell>High</StyledTableCell>
                                                                    <StyledTableCell>Avg</StyledTableCell>
                                                                    <StyledTableCell>Low</StyledTableCell>
                                                                </>
                                                            )} */}
                                                        {/* </TableRow> */}
                                                    </TableHead>
                                                    <TableBody>
                                                        {reportData.data.map((reportTable, reportTableIndex) => (
                                                            <StyledTableRow key={`report_${reportTableIndex}`}>
                                                                {reportData.burstReportHeaders.map(header => (
                                                                    <>
                                                                        {(reportTable[`${header.headerProperty}RowSpan`] > 0 && selectedColumns.includes(header.headerName)) && <StyledTableCell className={`cell-${header.headerProperty}`} rowSpan={reportTable[`${header.headerProperty}RowSpan`]} align='left'>{reportTable[header.headerProperty]}</StyledTableCell>}
                                                                        {/* {(reportTable[`${header.headerProperty}RowSpan`] > 0 && (selectedMetrics.includes('all') || selectedMetrics.includes(header.headerName))) && <StyledTableCell rowSpan={reportTable[`${header.headerProperty}RowSpan`]}>{reportTable[header.headerProperty]}</StyledTableCell>} */}
                                                                    </>
                                                                ))}
                                                                {selectedMetricsProps.map((metrics) => (
                                                                    <StyledTableCell className={`cell-${metrics}`} align={'right'}>
                                                                        {['fees', 'max-hourly-rates', 'min-hourly-rates', 'avg-hourly-rates'].includes(metrics) ? currencyFormat(convertToFixed(reportTable[TIME_SUMMARY_METRICS_MAP_WITH_VALUE[metrics]])) : ['resources', 'entries', 'months'].includes(metrics) ? numberFormat(convertToFixed(reportTable[TIME_SUMMARY_METRICS_MAP_WITH_VALUE[metrics]])) : ['hours'].includes(metrics) ? hoursFormat(reportTable[TIME_SUMMARY_METRICS_MAP_WITH_VALUE[metrics]]) : convertToFixed(reportTable[TIME_SUMMARY_METRICS_MAP_WITH_VALUE[metrics]]) + "%"}
                                                                    </StyledTableCell>
                                                                ))}
                                                                {/* {selectedMetricsProps.map((metrics) => metrics === 'hourly-rates' ? (
                                                                    <>
                                                                        <StyledTableCell align="right">
                                                                            {currencyFormat(convertToFixed(reportTable.maxHourlyRate))}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {currencyFormat(convertToFixed(reportTable.avgHourlyRate))}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {currencyFormat(convertToFixed(reportTable.minHourlyRate))}
                                                                        </StyledTableCell>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <StyledTableCell align="right">{metrics === 'fees' ? currencyFormat(convertToFixed(reportTable[TIME_SUMMARY_METRICS_MAP_WITH_VALUE[metrics]?.count])) : numberFormat(convertToFixed(reportTable[TIME_SUMMARY_METRICS_MAP_WITH_VALUE[metrics]?.count]))}</StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {convertToFixed(reportTable[TIME_SUMMARY_METRICS_MAP_WITH_VALUE[metrics]?.percentage])}
                                                                        </StyledTableCell>
                                                                    </>
                                                                ))} */}
                                                                {/* {(selectedMetrics.includes('all') || selectedMetrics.includes('resources')) && (
                                                                    <>
                                                                        <StyledTableCell align="right">{convertToFixed(reportTable.resourceCount)}</StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {convertToFixed(reportTable.resourcePercent)}
                                                                        </StyledTableCell>
                                                                    </>
                                                                )}
                                                                {(selectedMetrics.includes('all') || selectedMetrics.includes('hours')) && (
                                                                    <>
                                                                        <StyledTableCell align="right">
                                                                            {numberFormat(convertToFixed(reportTable.totalHoursCount))}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {convertToFixed(reportTable.totalHoursPercent)}
                                                                        </StyledTableCell>
                                                                    </>
                                                                )}
                                                                {(selectedMetrics.includes('all') || selectedMetrics.includes('fees')) && (
                                                                    <>
                                                                        <StyledTableCell align="right">
                                                                            {currencyFormat(convertToFixed(reportTable.feesTotalCount))}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {convertToFixed(reportTable.feesTotalPercent)}
                                                                        </StyledTableCell>
                                                                    </>
                                                                )}
                                                                {(selectedMetrics.includes('all') || selectedMetrics.includes('entries')) && (
                                                                    <>
                                                                        <StyledTableCell align="right">
                                                                            {numberFormat(convertToFixed(reportTable.timeEntriesCount))}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {convertToFixed(reportTable.timeEntriesPercent)}
                                                                        </StyledTableCell>
                                                                    </>
                                                                )}
                                                                {(selectedMetrics.includes('all') || selectedMetrics.includes('months')) && (
                                                                    <>
                                                                        <StyledTableCell align="right">
                                                                            {convertToFixed(reportTable.monthCount)}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {convertToFixed(reportTable.monthPercent)}
                                                                        </StyledTableCell>
                                                                    </>
                                                                )}
                                                                {(selectedMetrics.includes('all') || selectedMetrics.includes('hourly-rates')) && (
                                                                    <>
                                                                        <StyledTableCell align="right">
                                                                            {currencyFormat(convertToFixed(reportTable.maxHourlyRate))}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {currencyFormat(convertToFixed(reportTable.avgHourlyRate))}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="right">
                                                                            {currencyFormat(convertToFixed(reportTable.minHourlyRate))}
                                                                        </StyledTableCell>
                                                                    </>
                                                                )} */}
                                                            </StyledTableRow >
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    ) : (
                                        <Grid item lg={12}>
                                            <p className="text-center">No Data found</p>
                                        </Grid>
                                    )}
                                </React.Fragment>
                                <Grid item xs={12} sm={12} md={12} lg={12} className="pie-chart-main-container">
                                    {reportData.chartData && reportData.chartData.length > 0 && (
                                        <>
                                            {[reportData.chartData.slice(0, reportData.chartData.length / 2), reportData.chartData.slice(reportData.chartData.length / 2)].map(reportChatData => (
                                                <Grid container spacing={2} className="pie-chart-container">
                                                    {reportChatData.map(charData => (
                                                        <div className="pie-chart-card">
                                                            <div>
                                                                <p className="report-chart-title">{charData.name}</p>
                                                                <div style={{ height: 250 }}>
                                                                    <PieChart enableArcLabels={false} data={charData.data} showLegends={false} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Grid>
                                            ))}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </>
            ) : (
                <Grid item lg={12}>
                    <p className="text-center">No Data found</p>
                </Grid>
            )}
        </Grid>
        // <Grid container spacing={2}>
        //     {tableData.length > 0 ? (
        //         <>
        //             {tableData.map(reportData => (
        //                 <Grid item xs={12} sm={12} md={12} lg={12}>
        //                     <Grid container spacing={1}>
        //                         <Grid item xs={12} sm={12} md={12} lg={12}>
        //                             <Typography variant="subtitle2">
        //                                 {[reportData.firm, reportData.resource, reportData.timeCategory, reportData.professionalLevel].map((d, index) => {
        //                                     let text = "";
        //                                     if (d) {
        //                                         switch (index) {
        //                                             case 0:
        //                                                 text = `Firm: ${d}`
        //                                                 break;
        //                                             case 1:
        //                                                 text = `Resource: ${d}`
        //                                                 break;
        //                                             case 2:
        //                                                 text = `Time Category: ${d}`
        //                                                 break;
        //                                             case 3:
        //                                                 text = `Professional Level: ${d}`
        //                                                 break;
        //                                             default:
        //                                                 break;
        //                                         }
        //                                     }
        //                                     return text;
        //                                 }).filter(x => x).join(", ")}
        //                             </Typography>
        //                         </Grid>
        //                         <React.Fragment>
        //                             {reportData.data && reportData.data.length > 0 ? (
        //                                 <Grid item xs={12} sm={12} md={12} lg={12}>
        //                                     <TableContainer className="moz-table-pb report-table-container">
        //                                         <Table size="small" className="custom-table report-table">
        //                                             <TableHead>
        //                                                 <TableRow>
        //                                                     {reportData.data[0].groupByProp && <StyledTableCell rowSpan={2} align="center">{REPORT_GROUP_BY_NAME[reportData.data[0].groupByProp] || ""}</StyledTableCell>}

        //                                                     {reportData.burstReportHeaders.map(header => (
        //                                                         <>
        //                                                             {(selectedMetrics.includes('all') || selectedMetrics.includes(header.headerName)) && reportData.data[0].groupByProp !== header.headerProperty && <StyledTableCell rowSpan={2} align="center">{header.headerName}</StyledTableCell>}
        //                                                         </>
        //                                                     ))}
        //                                                     {/* {(selectedMetrics.includes('all') || selectedMetrics.includes('resourceName')) && reportData.data[0].groupByProp !== "resourceName" && (<StyledTableCell rowSpan={2} align="center" className="report-details-table-column-width">Resource Name</StyledTableCell>)}
        //                                                             {(selectedMetrics.includes('all') || selectedMetrics.includes('professionalLevels')) && reportData.data[0].groupByProp !== "professionalLevelName" && (<StyledTableCell rowSpan={2} align="center" className="report-details-table-column-width">Professional Level</StyledTableCell>)}
        //                                                             {(selectedMetrics.includes('all') || selectedMetrics.includes('timeCategory')) && reportData.data[0].groupByProp !== "timeCategoryCode" && (<StyledTableCell rowSpan={2} align="center" className="report-details-table-column-width">Time Category</StyledTableCell>)} */}

        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('resources')) && (<StyledTableCell colSpan={2} align="center">Resources</StyledTableCell>)}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('hours')) && (<StyledTableCell colSpan={2} align="center">Hours</StyledTableCell>)}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('fees')) && (<StyledTableCell colSpan={2} align="center">Fees</StyledTableCell>)}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('entries')) && (<StyledTableCell colSpan={2} align="center">Time Entries</StyledTableCell>)}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('months')) && (<StyledTableCell colSpan={2} align="center">Months</StyledTableCell>)}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('hourly-rates')) && (<StyledTableCell colSpan={3} align="center">Hourly Rate</StyledTableCell>)}
        //                                                 </TableRow>
        //                                                 <TableRow>
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('resources')) && (
        //                                                         <>
        //                                                             <StyledTableCell>#</StyledTableCell>
        //                                                             <StyledTableCell>%</StyledTableCell>
        //                                                         </>
        //                                                     )}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('hours')) && (
        //                                                         <>
        //                                                             <StyledTableCell>#</StyledTableCell>
        //                                                             <StyledTableCell>%</StyledTableCell>
        //                                                         </>
        //                                                     )}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('fees')) && (
        //                                                         <>
        //                                                             <StyledTableCell>#</StyledTableCell>
        //                                                             <StyledTableCell>%</StyledTableCell>
        //                                                         </>
        //                                                     )}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('entries')) && (
        //                                                         <>
        //                                                             <StyledTableCell>#</StyledTableCell>
        //                                                             <StyledTableCell>%</StyledTableCell>
        //                                                         </>
        //                                                     )}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('months')) && (
        //                                                         <>
        //                                                             <StyledTableCell>#</StyledTableCell>
        //                                                             <StyledTableCell>%</StyledTableCell>
        //                                                         </>
        //                                                     )}
        //                                                     {(selectedMetrics.includes('all') || selectedMetrics.includes('hourly-rates')) && (
        //                                                         <>
        //                                                             <StyledTableCell>High</StyledTableCell>
        //                                                             <StyledTableCell>Avg</StyledTableCell>
        //                                                             <StyledTableCell>Low</StyledTableCell>
        //                                                         </>
        //                                                     )}
        //                                                 </TableRow>
        //                                             </TableHead>
        //                                             <TableBody>
        //                                                 {reportData.data.map((reportTable, reportTableIndex) => (
        //                                                     <>
        //                                                         {reportTable.groupByProp ? (
        //                                                             <>
        //                                                                 {reportTable.values.map((firstGroupBy, firstGroupByIndex) => (
        //                                                                     <>
        //                                                                         {firstGroupBy.groupByProp ? (
        //                                                                             <>
        //                                                                                 {firstGroupBy.values.map((secondGroupBy, secondGroupByIndex) => (
        //                                                                                     <>
        //                                                                                         {secondGroupBy.groupByProp ? (
        //                                                                                             <>
        //                                                                                             </>
        //                                                                                         ) : (
        //                                                                                             <>
        //                                                                                                 {getReportRow(reportData.burstReportHeaders, secondGroupBy, firstGroupBy, secondGroupByIndex, firstGroupByIndex, reportTable, firstGroupBy)}
        //                                                                                             </>
        //                                                                                         )}
        //                                                                                     </>
        //                                                                                 ))}
        //                                                                             </>
        //                                                                         ) : (
        //                                                                             <>
        //                                                                                 {getReportRow(reportData.burstReportHeaders, firstGroupBy, reportTable, firstGroupByIndex, 0, reportTable)}
        //                                                                             </>
        //                                                                         )}
        //                                                                     </>
        //                                                                 ))}
        //                                                             </>
        //                                                         ) : (
        //                                                             <>
        //                                                                 {getReportRow(reportData.burstReportHeaders, reportTable, undefined, 0, 0, undefined)}
        //                                                             </>
        //                                                         )}
        //                                                     </>
        //                                                 ))}
        //                                             </TableBody>
        //                                         </Table>
        //                                     </TableContainer>
        //                                 </Grid>
        //                             ) : (
        //                                 <p className="text-center">No Data found</p>
        //                             )}
        //                         </React.Fragment>
        //                         <Grid item xs={12} sm={12} md={12} lg={12} className="pie-chart-main-container">
        //                             {reportData.chartData && reportData.chartData.length > 0 && (
        //                                 <>
        //                                     {[reportData.chartData.slice(0, reportData.chartData.length / 2), reportData.chartData.slice(reportData.chartData.length / 2)].map(reportChatData => (
        //                                         <Grid container spacing={2} className="pie-chart-container">
        //                                             {reportChatData.map(charData => (
        //                                                 <div className="pie-chart-card">
        //                                                     <div>
        //                                                         <p className="report-chart-title">{charData.name}</p>
        //                                                         <div style={{ height: 250 }}>
        //                                                             <PieChart enableArcLabels={false} data={charData.data} showLegends={false} />
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                             ))}
        //                                         </Grid>
        //                                     ))}
        //                                 </>
        //                             )}
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>
        //             ))}
        //         </>
        //     ) : (
        //         <Grid item lg={12}>
        //             <p className="text-center">No Data found</p>
        //         </Grid>
        //     )}
        // </Grid>
    );
}

export default TimeDetailedReport;