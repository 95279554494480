import * as React from 'react';
import {
    Checkbox,
    Grid, IconButton, Menu, MenuItem, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { currencyFormat, hoursFormat, numberFormat, StyledTableCell, StyledTableRow } from "../../services/UtilService";
import moment from "moment";
import { IAttentionTimesheetDetails, ITimeEntry } from '../../vm';
import { TIMESHEET_ALL_STATUS } from '../../Constant';
import { DotsVertical, ExclamationThick, Pencil, TrashCanOutline } from 'mdi-material-ui';
interface AttentionTimesheetsTableProps {
    timeEntries: IAttentionTimesheetDetails[];
    isAllSelected?: boolean;
    from: "timesheets" | "notes" | "timesheets-split" | "timesheets-split-header" | "timesheets-details" | "attention-timesheets-details";
    selectAll?: Function
    handleSelectChange?: Function;
    handleViewLogsDialog?: Function;
    handleRuleFailedDialogOpen?: Function;
    handleAddDialogOpen?: Function;
    deleteEntry?: Function;
    selectedTimeEntries?: number[];
    unselectedTimeEntries?: number[];
    handleSplitDialogOpen?: Function;
    selectedIndex?: number;
    canSortHeader?: boolean
    sortBy?: string;
    sortOrder?: "desc" | "asc";
    onSort?: Function;
    handleApproveTimesheet?: Function;
    handleDisApproveTimesheet?: Function;
    isReadOnly: boolean;
}

const AttentionTimesheetsTable: React.FC<AttentionTimesheetsTableProps> = (props) => {
    const [state, setState] = React.useState({
        selectedItemIndex: -1,
        selectedItemDetailIndex: -1,
        anchorElMenu: null,
    } as {
        selectedItemIndex: number;
        selectedItemDetailIndex: number;
        anchorElMenu: any;
    });

    const handleClickMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        index: number,
        detailIndex: number
    ) => {
        setState({
            ...state,
            selectedItemIndex: index,
            selectedItemDetailIndex: detailIndex,
            anchorElMenu: event.currentTarget,
        });
    };

    const handleCloseMenu = () => {
        setState({ ...state, selectedItemIndex: -1, anchorElMenu: null });
    };

    const getTableHeader = (label, sortProp) => {
        return props.canSortHeader ? (<TableSortLabel
            active={props.sortBy === sortProp}
            direction={props.sortOrder}
            onClick={() => {
                props.onSort(sortProp);
            }}
        >
            {label}
        </TableSortLabel>) : label
    }



    return (
        <section>
            <TableContainer className={props.from === "timesheets" ? "detailed-timesheet-table moz-table-pb" : ["timesheets-details", "attention-timesheets-details"].includes(props.from) ? "timesheets-details-table-container moz-table-pb" : "moz-table-pb"}>
                <Table size="small" className="custom-table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                {/* <Checkbox
                                    size="small"
                                    color='secondary'
                                    checked={props.isAllSelected}
                                    indeterminate={!props.isAllSelected && (props.selectedTimeEntries?.length || props.unselectedTimeEntries?.length) ? true : false}
                                    value={props.isAllSelected}
                                    onClick={() => {
                                        props.selectAll();
                                    }}
                                /> */}
                            </StyledTableCell>
                            <StyledTableCell>{getTableHeader('Attention Name', 'attentionName')}</StyledTableCell>
                            <StyledTableCell>{getTableHeader('Attention Description', 'attentionDescription')}</StyledTableCell>
                            <StyledTableCell>{getTableHeader('Firm', 'firmName')}</StyledTableCell>
                            <StyledTableCell>{getTableHeader('Resource', 'personName')}</StyledTableCell>
                            <StyledTableCell>
                                {getTableHeader('Service Date', 'serviceDate')}
                            </StyledTableCell>
                            <StyledTableCell>{getTableHeader('Professional Level', 'professionalLevelName')}</StyledTableCell>
                            {/* <StyledTableCell>Category Code</StyledTableCell> */}
                            <StyledTableCell>{getTableHeader('Category Name', 'categoryName')}</StyledTableCell>
                            <StyledTableCell className="time-entry-description">
                                {getTableHeader('Detailed Description of Work performed', 'detailedDescription')}
                            </StyledTableCell>
                            <StyledTableCell>{getTableHeader('Approved by', 'approvedBy')}</StyledTableCell>
                            <StyledTableCell align="center">{getTableHeader('Time', 'timeSpent')}</StyledTableCell>
                            <StyledTableCell align="center">
                                {getTableHeader('Billing rate', 'billingRate')}
                            </StyledTableCell>
                            <StyledTableCell align="center">{getTableHeader('Fees Total', 'feesTotal')}</StyledTableCell>
                            <StyledTableCell align="center">{getTableHeader('Class Work', 'classActionWorkIndicator')}</StyledTableCell>
                            {/* <StyledTableCell>Escape Attention Validation</StyledTableCell> */}
                            <StyledTableCell>{getTableHeader('Status', 'status')}</StyledTableCell>
                            <StyledTableCell align="center">{getTableHeader('Version', 'version')}</StyledTableCell>
                            {["timesheets", "timesheets-split", "timesheets-details", "attention-timesheets-details"].includes(props.from) && (
                                <StyledTableCell>Actions</StyledTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.timeEntries.length > 0 ? (
                            props.timeEntries.map((timeEntry, index) => (
                                <React.Fragment>
                                    {timeEntry.results.map((ele, detailIndex) => (
                                        <StyledTableRow key={`attention_table_${index}_${detailIndex}`} className={`${index % 2 === 0 ? 'attention_table_even' : 'attention_table_odd'} ${props.selectedIndex === index ? 'selected-split-entry' : ''}`}>
                                            {["timesheets", "timesheets-details", "attention-timesheets-details"].includes(props.from) && (
                                                <StyledTableCell>
                                                    <Checkbox
                                                        size="small"
                                                        checked={ele.isSelected ? true : false}
                                                        onClick={() => {
                                                            props.handleSelectChange(index, detailIndex);
                                                        }}
                                                    />
                                                </StyledTableCell>
                                            )}
                                            {detailIndex === 0 && (
                                                <>
                                                    <StyledTableCell rowSpan={timeEntry.results.length}>{ele.attentionName}</StyledTableCell>
                                                    <StyledTableCell rowSpan={timeEntry.results.length}>{ele.attentionDescription}</StyledTableCell>
                                                </>
                                            )}
                                            <StyledTableCell>{ele.firmName}</StyledTableCell>
                                            <StyledTableCell>{ele.personName}</StyledTableCell>
                                            <StyledTableCell>
                                                {ele.serviceDate ? moment(ele.serviceDate).format("MM/DD/YYYY") : ""}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {ele.professionalLevelName}
                                            </StyledTableCell>
                                            {/* <StyledTableCell>{ele.categoryCode}</StyledTableCell> */}
                                            <StyledTableCell>{ele.categoryName}</StyledTableCell>
                                            <StyledTableCell className="time-entry-description">
                                                {ele.detailedDescription}
                                            </StyledTableCell>
                                            <StyledTableCell>{ele.approvedBy}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                {hoursFormat(ele?.timeSpent || 0)}
                                                {/* {numberFormat(ele?.timeSpent || 0)} */}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {currencyFormat(ele?.billingRate || 0)}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {currencyFormat(ele?.feesTotal || 0)}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {ele.classActionWorkIndicator}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {TIMESHEET_ALL_STATUS[ele.status || ""]}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {["timesheets", "timesheets-details", "attention-timesheets-details"].includes(props.from) ? (
                                                    <React.Fragment>
                                                        {ele.version > 1 ? (
                                                            <Typography
                                                                className="MuiLink-underlineHover pointer"
                                                                component="a"
                                                                align="center"
                                                                color="primary"
                                                                onClick={() => props.handleViewLogsDialog(true, index, detailIndex)}
                                                            >
                                                                {ele.version}
                                                            </Typography>
                                                        ) : (
                                                            ele.version
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {ele.version}
                                                    </React.Fragment>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction="row"
                                                    wrap="nowrap"
                                                    className="d-flex flex-row"
                                                >
                                                    <Grid item>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => handleClickMenu(e, index, detailIndex)}
                                                        >
                                                            <DotsVertical />
                                                        </IconButton>
                                                    </Grid>
                                                    {ele.hasAttention && (
                                                        <Grid item>
                                                            <Tooltip
                                                                title="View Rules Failed"
                                                                placement="bottom"
                                                            >
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() =>
                                                                        props.handleRuleFailedDialogOpen(true, index, detailIndex)
                                                                    }
                                                                >
                                                                    <ExclamationThick color="error" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </React.Fragment>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align="center" colSpan={12}>
                                    No Entries found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Menu
                id="simple-menu"
                anchorEl={state.anchorElMenu}
                keepMounted
                open={Boolean(state.anchorElMenu)}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    onClick={() => {
                        props.handleAddDialogOpen(true, state.selectedItemIndex, state.selectedItemDetailIndex);
                        handleCloseMenu();
                    }}
                    disabled={props.isReadOnly}
                >
                    Edit Entry
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        props.handleSplitDialogOpen(true, state.selectedItemIndex, state.selectedItemDetailIndex);
                        handleCloseMenu();
                    }}
                    disabled={props.isReadOnly}
                >
                    Split Entry
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        props.handleApproveTimesheet(state.selectedItemIndex, state.selectedItemDetailIndex);
                        handleCloseMenu();
                    }}
                    disabled={props.isReadOnly}
                >
                    Approve
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        props.handleDisApproveTimesheet(state.selectedItemIndex, state.selectedItemDetailIndex);
                        handleCloseMenu();
                    }}
                    disabled={props.isReadOnly}
                >
                    Disapprove
                </MenuItem>
            </Menu>
        </section>
    );
}

export default AttentionTimesheetsTable;