import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography} from "@material-ui/core";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight} from "mdi-material-ui";
import * as React from "react";
import {
    StyledTableCell,
    StyledTableRow,
    getCeilValueForTotalCount,
} from "../../services/UtilService";
import { IConfirmDialog, ILoginHistory } from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import { LOGIN_HISTORY, MAX_PAGINATION_OPTIONS } from "../../Constant";
import moment from "moment";
import { getLoginHistory } from "../../services/UserService";

export interface LoginsHistoryProps extends IConfirmDialog { }

const LoginsHistory: React.FC<LoginsHistoryProps> = ({ confirmDialog }) => {
    const [searchObj, setSearchObj] = React.useState({
        from: 0,
        size: 10,
    } as {
        from: number;
        size: number;
    });

    const [state, setState] = React.useState({
        loginHistoryList: [],
        rowsList: [10],
        totalCount: 0,
    } as {
        loginHistoryList: ILoginHistory[];
        rowsList: number[];
        totalCount: number;
    });
    
    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await fetchLoginDetailsList(undefined, true);
            setLoading(false);
        };
        fetchData();
    }, []);

    const fetchLoginDetailsList = async (fetchSearchObj?: any, isDefaultCall = false) => {
        let tempSearch = { ...searchObj };
        if (fetchSearchObj) {
            tempSearch = { ...tempSearch, ...fetchSearchObj };
        }
        setLoading(true);
        let searchData = {
            from: tempSearch.from,
            size: tempSearch.size,
        }
        const result = await getLoginHistory(searchData);
        if (result?.isSuccess) {
            let menuItems = [];
            for (let index = 10; index <= result.data.count; index = index + 10) {
                menuItems.push(index);
            }
            if (menuItems[menuItems.length - 1] < result.data.count) {
                menuItems.push(getCeilValueForTotalCount(result.data.count));
            }
            if (menuItems.length > MAX_PAGINATION_OPTIONS) {
                menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
            }
            setState({
                ...state,
                totalCount: result.data?.count || 0,
                loginHistoryList: result.data?.results,
                rowsList: [...menuItems],
            });
            setSearchObj({ ...tempSearch },)
        } else {
            showToast(
                result?.message ? result.message : "Error while fetching history details",
                "error"
            );
        }
        if (!isDefaultCall) {
            setLoading(false);
        }
    };

    const onSearch = async (searchObj?: any) => {
        let tempSearch = { ...searchObj };
        if (searchObj) {
            tempSearch = { ...searchObj, ...searchObj };
        }
        await fetchLoginDetailsList(tempSearch);
    };

    const TablePaginationActions = (props: TablePaginationActionsProps) => {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className="d-flex">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <ChevronDoubleLeft />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ChevronRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <ChevronDoubleRight />
                </IconButton>
            </div>
        );
    };


    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">Logins History</Typography>
                </div>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TableContainer className="notes-table-container moz-table-pb">
                                            <Table stickyHeader size="small" className="custom-table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>User</StyledTableCell>
                                                        <StyledTableCell>Type</StyledTableCell>
                                                        <StyledTableCell>Timestamp</StyledTableCell>
                                                        <StyledTableCell>Host Address</StyledTableCell>
                                                        {/* <StyledTableCell>Logout Type</StyledTableCell> */}
                                                        <StyledTableCell>OS</StyledTableCell>
                                                        <StyledTableCell>Browser</StyledTableCell>
                                                        <StyledTableCell>Browser Version</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {state.loginHistoryList.length > 0 ? (
                                                        state.loginHistoryList.map((ele, index: number) => (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell>{ele.userName}</StyledTableCell>
                                                                <StyledTableCell>{LOGIN_HISTORY.type[ele.type]||""}</StyledTableCell>
                                                                <StyledTableCell>{moment(ele.when).format("MM/DD/YYYY hh:mm:A")}</StyledTableCell>
                                                                <StyledTableCell>{ele.hostAddress}</StyledTableCell>
                                                                {/* <StyledTableCell>{LOGIN_HISTORY.logoutType[ele.logoutType]||""}</StyledTableCell> */}
                                                                <StyledTableCell>{ele.os}</StyledTableCell>
                                                                <StyledTableCell>{ele.browser}</StyledTableCell>
                                                                <StyledTableCell>{ele.browserVersion}</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={7}>
                                                                No details found
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={state.rowsList}
                                            colSpan={4}
                                            component="div"
                                            count={state.totalCount}
                                            rowsPerPage={searchObj.size}
                                            page={
                                                searchObj.from === 0
                                                    ? 0
                                                    : searchObj.from / searchObj.size
                                            }
                                            SelectProps={{
                                                inputProps: { "aria-label": "rows per page" },
                                                native: true,
                                            }}
                                            onPageChange={(event: any, newPage: number) => {
                                                let search = { ...searchObj };
                                                search.from =
                                                    newPage === 0
                                                        ? 0
                                                        : (newPage * searchObj.size);
                                                onSearch(search);
                                            }}
                                            onRowsPerPageChange={(event: any) => {
                                                let value = event.target.value;
                                                let search = { ...searchObj };
                                                search.size = value ? Number(value) : 10;
                                                search.from = 0;
                                                onSearch(search);
                                            }}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        </React.Fragment>
    );
};

export default LoginsHistory;
