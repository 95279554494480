import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow
} from "@material-ui/core";
import * as React from 'react';
import { ITimeSummaryCrossReportHeaders, ITimeSummaryCrossReportRows, ITimeSummaryCrossTableReport, ITimeSummaryCrossTableReportColumns } from '../../vm';
import { StyledTableCell, convertToFixed, currencyFormat, generateRandomUUID, hoursFormat, numberFormat } from "../../services/UtilService";
import { Minus, Plus } from "mdi-material-ui";

interface TimeSummaryCrossTableReportProps {
    timeSummaryCrossTableReportData: ITimeSummaryCrossTableReport;
    selectedMetrics: string[];
}

const TimeSummaryCrossTableReport: React.FunctionComponent<TimeSummaryCrossTableReportProps> = (props) => {

    const [headers, setHeaders] = React.useState([] as any[]);
    const [expandCollapseRow, setExpandCollapseRow] = React.useState({} as any);


    React.useEffect(() => {
        let headersToBuild = [[], [], [], [], []];
        headersToBuild = buildHeaders(0, headersToBuild, props.timeSummaryCrossTableReportData.columns);
        headersToBuild = headersToBuild.filter(x => x.length > 0);
        console.log(headersToBuild);
        setHeaders(headersToBuild);

    }, []);

    const onExpandOrCollapse = (key) => {
        setExpandCollapseRow({
            ...expandCollapseRow,
            [key]: expandCollapseRow[key] ? false : true
        })
    }

    const buildHeaders = (index: number, headers: any, data: ITimeSummaryCrossTableReportColumns[]) => {
        data.forEach(ele => {
            if (ele.values?.length > 0) {
                ele.count = ele.values.map(x => x.count).reduce((acc, ele) => acc + ele, 0)
            }
            headers[index].push(ele);
            if (ele.values?.length > 0) {
                headers = buildHeaders(index + 1, headers, ele.values);
            }
        });
        return headers
    }

    const buildTableRowCellValue = (data: ITimeSummaryCrossReportRows, column: ITimeSummaryCrossReportHeaders, isHour = false) => {
        let value = ""
        if (data.rowsData[column.name]) {
            switch (column.type) {
                case "string":
                    value = data.rowsData[column.name]
                    break;
                case "currency":
                    value = currencyFormat(convertToFixed(data.rowsData[column.name]));
                    break;
                case "number":
                    value = isHour ? hoursFormat(data.rowsData[column.name]) : numberFormat(convertToFixed(data.rowsData[column.name]))
                    break;
            }
        }
        return value
    }

    const buildTableRow = (data: ITimeSummaryCrossReportRows[], index: string, paddingIndex: number) => {
        return (
            <>
                {data.map((row, rowIndex) => {
                    return (
                        <>
                            <TableRow className={`${row.values && row.values.length > 0 ? 'grouped-cross-table-row' : ""}`} key={`main_index_${index}_row_${row.id}`}>
                                <StyledTableCell className={`row-label-padding-${paddingIndex} cross-table-row-label`}>
                                    {row.values && row.values.length > 0 && (
                                        <span className="expand-collapse-icon" onClick={() => onExpandOrCollapse(row.id)}>
                                            {expandCollapseRow[row.id] ? <Plus /> : <Minus />}
                                        </span>
                                    )}
                                    {row.rowLabel}
                                </StyledTableCell>
                                {props.timeSummaryCrossTableReportData.columnHeaders.map((column, columnIndex) => (
                                    <StyledTableCell key={`main_row_${row.id}_row_index_${rowIndex}_column_${columnIndex}`} align={column.type === "string" ? "left" : "right"}>
                                        {buildTableRowCellValue(row, column, column.label === 'Hours')}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                            {(row.values && row.values.length > 0 && !expandCollapseRow[row.id]) && (
                                <>
                                    {buildTableRow(row.values, `${index}_sub_row`, paddingIndex + 1)}
                                </>
                            )}
                        </>
                    )
                }

                )}
            </>
        )

    }


    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            {props.timeSummaryCrossTableReportData.rows.length > 0 ? (
                <TableContainer className="moz-table-pb report-table-container cross-tab-table-container">
                    <Table size="small" className="custom-table report-table">
                        <TableHead>
                            {headers.map((header, headerIndex) => (
                                <TableRow className={`crosstab-row-${headerIndex+1}`} key={`header_row_${headerIndex}`}>
                                    <StyledTableCell className={`crosstab-row-label crosstab-row-label-${headerIndex+1}`}></StyledTableCell>
                                    <>
                                        {header.map(column => {
                                            return (
                                                <StyledTableCell key={`header_${headerIndex}_cell_${column.id}_${column.headerProp.replace(/ /g, "_")}`} colSpan={column.count}>
                                                    {column.headerName}
                                                </StyledTableCell>
                                            )
                                        })}
                                    </>
                                </TableRow>
                            ))}
                            <TableRow className={`crosstab-row-${headers.length+1}`}>
                                <StyledTableCell className={`crosstab-row-label crosstab-row-label-${headers.length+1}`}> Row Label</StyledTableCell>
                                {props.timeSummaryCrossTableReportData.columnHeaders.map((columnsHeader, columnsHeaderIndex) => (
                                    <StyledTableCell key={`columnHeader_${columnsHeaderIndex}`} align={columnsHeader.type === "string" ? "left" : "right"}>{columnsHeader.label}</StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buildTableRow(props.timeSummaryCrossTableReportData.rows, `rows_0`, 1)}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography align="center" variant="h4">No Data found</Typography>
            )}

        </Grid>
    );
}

export default TimeSummaryCrossTableReport;