import * as React from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
    StyledTableCell,
    StyledTableRow,
    arrayUniqueByKey,
    onlyUnique,
} from "../../services/UtilService";
import { IConfirmDialog, ICmoProtocols, ITimeFilter } from "../../vm";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import AddFilterDialog from "./AddFilterDialog";
import CmoTextDialog from "./CmoTextDialog";
import ProtocolTypeCard from "./ProtocolTypeCard";
import { ToastContext } from "../common/ToastProvider";
import { getManagementDashboardCmoFilters, getManagementDashboardCmoStats } from "../../services/ManagementDashboardService";
import { CMO_DIC } from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import CMOComplianceHeader from "./CMOComplianceHeader";
import { Close } from "mdi-material-ui";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

const getToFixedNumber = (number: any, isPercentage: boolean) => {
    number = Number(number || 0);
    return isPercentage ? number.toFixed(2) : Math.round(number * 100) / 100;
};

export interface ICmoProtocolTimeFilter extends ITimeFilter {
    // timeFilterName: string;
    // timeFilterId: number;
}

interface ICmoProtocolSearch {
    firms: string[];
    persons: string[];
    statuses: string[];
    startDate?: Date;
    endDate?: Date;
    // sortBy?: string;
    // sortOrder?: "asc" | "desc";
    keyword: string[];
    categoryCode: string[];
    workType: string[];
    professionalLevel: string[];
    min?: any;
    max?: any;
    minWordCount?: any;
    maxWordCount?: any;
    showDisapproved?: boolean;
    descriptionSearchFilters: Array<{ search: string, isExcluded: boolean }>
    // showMultipleBillingRates: boolean;
    approvedBy: string[];
    attentionNames: string[];
    showOnlyDisapproved?: boolean;
}

export interface CmoProtocolMainProps extends IConfirmDialog { }

const CmoProtocolMain: React.FC<CmoProtocolMainProps> = ({
    confirmDialog,
}) => {
    let initialSearch: ICmoProtocolSearch = {
        statuses: [],
        firms: [],
        persons: [],
        startDate: undefined,
        endDate: undefined,
        // sortBy: "",
        // sortOrder: "asc",
        keyword: [],
        min: "",
        max: "",
        minWordCount: "",
        maxWordCount: "",
        showDisapproved: false,
        descriptionSearchFilters: [],
        categoryCode: [],
        workType: [],
        professionalLevel: [],
        // showMultipleBillingRates: false,
        approvedBy: [],
        attentionNames: [],
        showOnlyDisapproved: false
    };
    const [searchObj, setSearchObj] = React.useState({ ...initialSearch } as ICmoProtocolSearch);
    const [state, setState] = React.useState({
        timeKeepingProtocols: [],
        clientSpecificWorks: [],
        depositions: [],
        periodicCommittees: [],
        periodicStatuses: [],
        identifications: [],
        attendances: [],
        discoveryAndDocuments: [],
        reviewOfFilings: [],
        emailAndCorrespondences: [],
        travelTimes: [],
    } as {
        timeKeepingProtocols: Array<ICmoProtocolTimeFilter>;
        clientSpecificWorks: Array<ICmoProtocolTimeFilter>;
        depositions: Array<ICmoProtocolTimeFilter>;
        periodicCommittees: Array<ICmoProtocolTimeFilter>;
        periodicStatuses: Array<ICmoProtocolTimeFilter>;
        identifications: Array<ICmoProtocolTimeFilter>;
        attendances: Array<ICmoProtocolTimeFilter>;
        discoveryAndDocuments: Array<ICmoProtocolTimeFilter>;
        reviewOfFilings: Array<ICmoProtocolTimeFilter>;
        emailAndCorrespondences: Array<ICmoProtocolTimeFilter>;
        travelTimes: Array<ICmoProtocolTimeFilter>;
    });
    const [cmoStats, setCmoStats] = React.useState([] as ICmoProtocols[]);
    const [isLoading, setLoading] = React.useState(false);
    const [manageAddFilterDialog, setManageAddFilterDialog] = React.useState({
        isOpen: false,
        type: undefined,
        data: []
    } as {
        isOpen: boolean;
        type?: string;
        data: Array<ICmoProtocolTimeFilter>;
    });
    const [manageRemoveFilterDialog, setManageRemoveFilterDialog] = React.useState({
        isOpen: false,
        type: undefined,
        data: []
    } as {
        isOpen: boolean;
        type?: string;
        data: Array<ICmoProtocolTimeFilter>;
    });
    const [manageCmoTextDialog, setManageCmoTextDialog] = React.useState({
        isOpen: false,
        type: undefined,
    } as {
        isOpen: boolean;
        type?: string;
    });
    const [isRedirectDialogOpen, setRedirectDialogOpen] = React.useState(false as boolean);
    const [selectedProtocols, setSelectedProtocols] = React.useState({} as any);
    const history = useHistory();
    const { showToast } = React.useContext(ToastContext);
    const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

    React.useEffect(() => {
        const fetchDetails = async () => {
            setLoading(true);
            await getCmoFilters();
            await getCmoStats();
        };
        fetchDetails();
    }, []);

    const getCmoStats = async (searchObject?: ICmoProtocolSearch) => {
        let search = { ...searchObj };
        if (searchObject) {
            setLoading(true);
            search = { ...search, ...searchObject };
        };

        const result = await getManagementDashboardCmoStats(search);
        if (result?.isSuccess) {
            setCmoStats(result.data);
            setSearchObj(search);
        } else {
            showToast(
                result?.message ? result.message : "Error while getting cmo stats",
                "error"
            );
        }
        setLoading(false);
    };

    const getCmoFilters = async () => {
        const result = await getManagementDashboardCmoFilters();
        if (result?.isSuccess) {
            const timeProtocolObj = result.data;
            if (timeProtocolObj) {
                setState({
                    ...state,
                    timeKeepingProtocols: timeProtocolObj[CMO_DIC["time-keeping-protocol"]] || [],
                    clientSpecificWorks: timeProtocolObj[CMO_DIC["client-specific-work"]] || [],
                    attendances: timeProtocolObj[CMO_DIC["attendance-at-seminars"]] || [],
                    depositions: timeProtocolObj[CMO_DIC.deposition] || [],
                    discoveryAndDocuments: timeProtocolObj[CMO_DIC["discovery-and-document-review-or-production"]] || [],
                    emailAndCorrespondences: timeProtocolObj[CMO_DIC["emails-and-correspondences"]] || [],
                    identifications: timeProtocolObj[CMO_DIC["identification-and-work-up-of-experts"]] || [],
                    periodicCommittees: timeProtocolObj[CMO_DIC["periodic-committee-conference-calls"]] || [],
                    periodicStatuses: timeProtocolObj[CMO_DIC["periodic-status-conferences"]] || [],
                    reviewOfFilings: timeProtocolObj[CMO_DIC["review-of-filing-and-orders"]] || [],
                    travelTimes: timeProtocolObj[CMO_DIC["travel-time"]] || [],
                })
            }
        } else {
            showToast(
                result?.message ? result.message : "Error while getting cmo filters",
                "error"
            );
        }
        setManageRemoveFilterDialog({
            isOpen: false,
            type: undefined,
            data: []
        });
        setManageAddFilterDialog({
            isOpen: false,
            type: undefined,
            data: []
        });
    };

    const handleAddFilterDialogOpen = (type: "time-keeping-protocol" | "client-specific-work" | "deposition" | "periodic-committee-conference-calls" | "periodic-status-conferences" | "identification-and-work-up-of-experts" | "attendance-at-seminars" | "discovery-and-document-review-or-production" | "review-of-filing-and-orders" | "emails-and-correspondences" | "travel-time", isRemovedFilterAction = false) => {
        let data = [];
        switch (type) {
            case "time-keeping-protocol":
                data = [...state.timeKeepingProtocols];
                break;
            case "client-specific-work":
                data = [...state.clientSpecificWorks];
                break;
            case "deposition":
                data = [...state.depositions];
                break;
            case "periodic-committee-conference-calls":
                data = [...state.periodicCommittees];
                break;
            case "periodic-status-conferences":
                data = [...state.periodicStatuses];
                break;
            case "identification-and-work-up-of-experts":
                data = [...state.identifications];
                break;
            case "attendance-at-seminars":
                data = [...state.attendances];
                break;
            case "discovery-and-document-review-or-production":
                data = [...state.discoveryAndDocuments];
                break;
            case "review-of-filing-and-orders":
                data = [...state.reviewOfFilings];
                break;
            case "emails-and-correspondences":
                data = [...state.emailAndCorrespondences];
                break;
            case "travel-time":
                data = [...state.travelTimes];
                break;
            default:
                break;
        };

        if (isRemovedFilterAction) {
            setManageRemoveFilterDialog({
                isOpen: true,
                type,
                data
            });
        } else {
            setManageAddFilterDialog({
                isOpen: true,
                type,
                data
            });
        }
    };

    const handleAddFilterDialogClose = async (data?: Array<ICmoProtocolTimeFilter>, isRemovedFilterAction = false) => {
        let stateData = { ...state };
        const type = isRemovedFilterAction ? manageRemoveFilterDialog.type : manageAddFilterDialog.type
        if (data) {
            setSelectedProtocols({});
            await getCmoFilters();
            await getCmoStats({ ...initialSearch });
        } else {
            if (isRemovedFilterAction) {
                setManageRemoveFilterDialog({
                    isOpen: false,
                    type: undefined,
                    data: []
                });
            } else {
                setManageAddFilterDialog({
                    isOpen: false,
                    type: undefined,
                    data: []
                });
            }
        }

    };

    // const handleCmoTextDialog = (isOpen: boolean, type?: "time-keeping-protocol" | "client-specific-work" | "deposition" | "periodic-committee-conference-calls" | "periodic-status-conferences" | "identification-and-work-up-of-experts" | "attendance-at-seminars" | "discovery-and-document-review-or-production" | "review-of-filing-and-orders" | "emails-and-correspondences" | "travel-time") => {
    const handleCmoTextDialog = (isOpen: boolean, type?: string) => {
        setManageCmoTextDialog({
            isOpen,
            type
        });
    }

    const handleCheckBoxChange = async (data: ICmoProtocolTimeFilter, type: "time-keeping-protocol" | "client-specific-work" | "deposition" | "periodic-committee-conference-calls" | "periodic-status-conferences" | "identification-and-work-up-of-experts" | "attendance-at-seminars" | "discovery-and-document-review-or-production" | "review-of-filing-and-orders" | "emails-and-correspondences" | "travel-time") => {
        let selectedProtocolList = { ...selectedProtocols };
        let isSearchObjOverlapping = false;
        if (selectedProtocolList[type] == null) selectedProtocolList[type] = [];
        const index = selectedProtocolList[type].findIndex((d) => d.timeFilterId === data.timeFilterId);
        if (index !== -1) {
            selectedProtocolList[type].splice(index, 1);
        } else {
            const search = { ...searchObj };
            const filter = JSON.parse(data.filters);
            if (search.startDate && filter.startDate && search.startDate !== filter.startDate) isSearchObjOverlapping = true;
            if (search.endDate && filter.endDate && search.endDate !== filter.endDate) isSearchObjOverlapping = true;
            if (search.min && filter.min && search.min !== filter.min) isSearchObjOverlapping = true;
            if (search.max && filter.max && search.max !== filter.max) isSearchObjOverlapping = true;
            if (search.minWordCount && filter.minWordCount && search.minWordCount !== filter.minWordCount) isSearchObjOverlapping = true;
            if (search.maxWordCount && filter.maxWordCount && search.maxWordCount !== filter.maxWordCount) isSearchObjOverlapping = true;
            // if (search.showDisapproved !== filter.showDisapproved) isSearchObjOverlapping = true;

            if (isSearchObjOverlapping) {
                showToast("Search Objects are overlapping, please select other options", "error")
            } else {
                selectedProtocolList[type].push(data);
            }
        }
        if (!isSearchObjOverlapping) {
            setSelectedProtocols(selectedProtocolList);
            // need to make a backend call to get data with latest filter
            let search = { ...initialSearch };
            Object.keys(selectedProtocolList).forEach((d) => {
                const filterArray = selectedProtocolList[d];
                if (filterArray.length > 0) {
                    filterArray.forEach((ele: ICmoProtocolTimeFilter) => {
                        let filter = JSON.parse(ele.filters);
                        filter.showMultipleBillingRates !== null && delete filter.showMultipleBillingRates;
                        filter.sortBy !== null && delete filter.sortBy;
                        filter.sortOrder !== null && delete filter.sortOrder;

                        search = {
                            ...search,
                            ...filter,
                            showDisapproved: search.showDisapproved || filter.showDisapproved,
                            firms: [...search.firms, ...filter.firms].filter(onlyUnique),
                            persons: [...search.persons, ...filter.persons].filter(onlyUnique),
                            statuses: [...search.statuses, ...filter.statuses].filter(onlyUnique),
                            keyword: [...search.keyword, ...filter.keyword].filter(onlyUnique),
                            categoryCode: [...search.categoryCode, ...filter.categoryCode].filter(onlyUnique),
                            workType: [...search.workType, ...filter.workType].filter(onlyUnique),
                            professionalLevel: filter.professionalLevel ? [...search.professionalLevel, ...filter.professionalLevel].filter(onlyUnique) : [...search.professionalLevel],
                            descriptionSearchFilters: filter.descriptionSearchFilters ? [...search.descriptionSearchFilters, ...filter.descriptionSearchFilters] : [...search.descriptionSearchFilters],
                            approvedBy: filter.approvedBy ? [...search.approvedBy, ...filter.approvedBy].filter(onlyUnique) : [...search.approvedBy],
                            attentionNames: filter.attentionNames ? [...search.attentionNames, ...filter.attentionNames].filter(onlyUnique) : [...search.attentionNames],
                        };
                        if (search.descriptionSearchFilters) {
                            search.descriptionSearchFilters = arrayUniqueByKey(search.descriptionSearchFilters.filter(x => x.search), "search");

                        }
                    })
                }
            });
            await getCmoStats(search);
        }
    };

    const getSelectedFilterNames = () => {
        let filterNames = [];
        Object.keys(selectedProtocols).forEach((key) => {
            selectedProtocols[key].forEach((filter: any) => {
                filterNames.push(filter.timeFilterName);
            });
        });
        return filterNames;
    };

    const pushToTimesheetsDetailsPage = (search?: any) => {
        const filterNames = getSelectedFilterNames();
        let tempSearch = search ? { ...search } : { ...searchObj };
        tempSearch.filterName = filterNames.join(", ");
        history.push({
            pathname: `/timesheets-details`,
            state: tempSearch,
        });
    };

    const pushToAttentionTimesheetsDetailsPage = () => {
        const filterNames = getSelectedFilterNames();
        history.push({
            pathname: `/attention-timesheets-details`,
            state: { ...searchObj, filterName: filterNames.join(", ") },
        });
    };

    const onTableValueClick = (type, showOnlyDisapproved) => {
        let search = { ...searchObj };
        if (type) {
            if (type === "disapproved") {
                if (showOnlyDisapproved) {
                    search.showOnlyDisapproved = true;
                    search.statuses = [];
                } else {
                    search.showDisapproved = true;
                    search.statuses = [];
                }
            } else {
                search.statuses = [type];
            }
        }
        if (search.attentionNames && search.attentionNames.length > 0) {
            setRedirectDialogOpen(true);
        } else {
            pushToTimesheetsDetailsPage(search);
        }
        setSearchObj(search);
    };

    const getTimeKeepingCheckboxWithLabel = (data: ITimeFilter) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        checked={selectedProtocols["time-keeping-protocol"] && selectedProtocols["time-keeping-protocol"].find((ele) => ele.timeFilterId === data.timeFilterId) ? true : false}
                        onChange={() =>
                            handleCheckBoxChange(data, "time-keeping-protocol")
                        }
                    />
                }
                label={data.timeFilterName}
            />
        );
    }

    const getTimeKeepingProtocolListUI = (data: ITimeFilter) => {
        const protocolHavingCMOText = ["Entries with Over Staffing Risk based on Keywords", "Entries with UNKNOWN Time Category", "Entries with No Description or only One Word Descriptions"];
        if (protocolHavingCMOText.includes(data.timeFilterName)) {
            return (
                <Grid container alignItems="center">
                    <Grid item>
                        {getTimeKeepingCheckboxWithLabel(data)}
                    </Grid>
                    <Grid item>
                        <Typography
                            className="MuiLink-underlineHover pointer"
                            component="a"
                            color="primary"
                            onClick={() => handleCmoTextDialog(true, data.timeFilterName)}
                        >
                            (show CMO text)
                        </Typography>
                    </Grid>
                </Grid>
            );
        };
        return getTimeKeepingCheckboxWithLabel(data);
    }

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">CMO Protocols for Common Benefit Work</Typography>
                </div>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CMOComplianceHeader showDisapproved={searchObj.showDisapproved || false} cmoDetails={cmoStats.length > 0 ? cmoStats[0] : undefined} onTableValueClick={onTableValueClick} showOnlyDisapproved={searchObj.showOnlyDisapproved} />
                        <br />
                        {/* <TableContainer className="moz-table-pb">
                            <Table size="small" className="custom-table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Entries</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Firms</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Resources</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" style={{ minWidth: 314 }}>Professional Levels</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Time Categories</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" style={{ minWidth: 314 }}>Days with Entries</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">New</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Attention</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Reviewed</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Adjusted</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Submitted</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Granted</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Not Granted</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Disapproved</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Fees</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center" className="cmo-table-column-width">Hours</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell># of all entries</StyledTableCell>
                                        <StyledTableCell>% of all entries</StyledTableCell>
                                        <StyledTableCell># of all firms</StyledTableCell>
                                        <StyledTableCell>% of all firms</StyledTableCell>
                                        <StyledTableCell># of all resources</StyledTableCell>
                                        <StyledTableCell>% of all resources</StyledTableCell>
                                        <StyledTableCell># of all professional levels</StyledTableCell>
                                        <StyledTableCell>% of all professional levels</StyledTableCell>
                                        <StyledTableCell># of all categories</StyledTableCell>
                                        <StyledTableCell>% of all categories</StyledTableCell>
                                        <StyledTableCell># of all days with entries</StyledTableCell>
                                        <StyledTableCell>% of all days with entries</StyledTableCell>
                                        <StyledTableCell># of all statuses</StyledTableCell>
                                        <StyledTableCell>% of all statuses</StyledTableCell>
                                        <StyledTableCell># of all statuses</StyledTableCell>
                                        <StyledTableCell>% of all statuses</StyledTableCell>
                                        <StyledTableCell># of all statuses</StyledTableCell>
                                        <StyledTableCell>% of all statuses</StyledTableCell>
                                        <StyledTableCell># of all statuses</StyledTableCell>
                                        <StyledTableCell>% of all statuses</StyledTableCell>
                                        <StyledTableCell># of all statuses</StyledTableCell>
                                        <StyledTableCell>% of all statuses</StyledTableCell>
                                        <StyledTableCell># of all statuses</StyledTableCell>
                                        <StyledTableCell>% of all statuses</StyledTableCell>
                                        <StyledTableCell># of all statuses</StyledTableCell>
                                        <StyledTableCell>% of all statuses</StyledTableCell>
                                        <StyledTableCell># of all statuses</StyledTableCell>
                                        <StyledTableCell>% of all statuses</StyledTableCell>
                                        <StyledTableCell>$ of all fees</StyledTableCell>
                                        <StyledTableCell>% of all fees</StyledTableCell>
                                        <StyledTableCell># of all hours</StyledTableCell>
                                        <StyledTableCell>% of all hours</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: 118 }}>Avg Rate ($)</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: 119 }}>Low Rate ($)</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: 124 }}>High Rate ($)</StyledTableCell>
                                        <StyledTableCell className="cmo-table-hours-column-width">Avg Hours per day (#)</StyledTableCell>
                                        <StyledTableCell className="cmo-table-hours-column-width">Low Hours per day (#)</StyledTableCell>
                                        <StyledTableCell className="cmo-table-hours-column-width">High Hours per day (#)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cmoStats.length > 0 ? (
                                        cmoStats.map((ele, index: number) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.timeEntries)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{getClickableTableValue(ele.timeEntryPercent, true)}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.lawFirmCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.lawFirmsPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.resourceCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.resourcePercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.professionalLevelCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.professionalPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.categoryCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.categoryPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.daysEntriesCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.daysEntriesPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.newStatusCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.newStatusPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.attentionStatusCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.attentionStatusPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.reviewedStatusCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.reviewedStatusPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.adjustedStatusCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.adjustedStatusPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.submittedStatusCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.submittedStatusPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.grantedStatusCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.grantedStatusPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.notGrantedStatusCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.notGrantedStatusPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.disApprovedStatusCount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.disApprovedStatusPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.feesSum)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.feesPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.hours)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.hoursPercent, true)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.avgRates)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.minRates)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.maxRates)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.avgHours)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.minHours)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {getClickableTableValue(ele.maxHours)}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell align="center" colSpan={38}>
                                                No data found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="protocol-type-container">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} style={{ padding: 16 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="h6">Time Keeping Protocol</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isReadOnly}
                                                            onClick={() => {
                                                                handleAddFilterDialogOpen("time-keeping-protocol");
                                                            }}
                                                        >
                                                            Add Saved Filter
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                handleAddFilterDialogOpen("time-keeping-protocol", true);
                                                            }}
                                                            disabled={state.timeKeepingProtocols.length === 0 || isReadOnly}
                                                        >
                                                            Remove Saved Filter
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container spacing={1}>
                                            {state.timeKeepingProtocols.map((d, index) => (
                                                <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                                    {getTimeKeepingProtocolListUI(d)}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ProtocolTypeCard title="Client-Specific Work" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="client-specific-work" selectedProtocols={selectedProtocols} protocolList={state.clientSpecificWorks} />
                            <ProtocolTypeCard title="Depositions" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="deposition" selectedProtocols={selectedProtocols} protocolList={state.depositions} />
                            <ProtocolTypeCard title="Periodic Committee Conference Calls and Liaison Counsel Meetings" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="periodic-committee-conference-calls" selectedProtocols={selectedProtocols} protocolList={state.periodicCommittees} />
                            <ProtocolTypeCard title="Periodic Status Conferences" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="periodic-status-conferences" selectedProtocols={selectedProtocols} protocolList={state.periodicStatuses} />
                            <ProtocolTypeCard title="Identification and Work-Up of Experts" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="identification-and-work-up-of-experts" selectedProtocols={selectedProtocols} protocolList={state.identifications} />
                            <ProtocolTypeCard title="Attendance at Seminars" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="attendance-at-seminars" selectedProtocols={selectedProtocols} protocolList={state.attendances} />
                            <ProtocolTypeCard title="Discovery and Document Review or Production" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="discovery-and-document-review-or-production" selectedProtocols={selectedProtocols} protocolList={state.discoveryAndDocuments} />
                            <ProtocolTypeCard title="Review of Filings and Orders" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="review-of-filing-and-orders" selectedProtocols={selectedProtocols} protocolList={state.reviewOfFilings} />
                            <ProtocolTypeCard title="Emails and Correspondence" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="emails-and-correspondences" selectedProtocols={selectedProtocols} protocolList={state.emailAndCorrespondences} />
                            <ProtocolTypeCard title="Travel Time" handleAddFilterDialogOpen={handleAddFilterDialogOpen} handleCmoTextDialog={handleCmoTextDialog} handleCheckBoxChange={handleCheckBoxChange} type="travel-time" selectedProtocols={selectedProtocols} protocolList={state.travelTimes} />
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            {manageAddFilterDialog.isOpen && (
                <AddFilterDialog
                    data={manageAddFilterDialog.data}
                    type={manageAddFilterDialog.type}
                    handleDialogClose={(data?: ICmoProtocolTimeFilter[]) => handleAddFilterDialogClose(data)}
                    updateStatus={updateStatus}
                />
            )}
            {manageRemoveFilterDialog.isOpen && (
                <AddFilterDialog
                    data={manageRemoveFilterDialog.data}
                    type={manageRemoveFilterDialog.type}
                    handleDialogClose={(data?: ICmoProtocolTimeFilter[]) => handleAddFilterDialogClose(data, true)}
                    isRemovedFilter={true}
                    updateStatus={updateStatus}
                />
            )}
            {manageCmoTextDialog.isOpen && (
                <CmoTextDialog
                    type={manageCmoTextDialog.type}
                    handleDialogClose={() => handleCmoTextDialog(false, undefined)}
                />
            )}
            {isRedirectDialogOpen && (
                <CustomDrawer
                    onClose={() => setRedirectDialogOpen(false)}
                >
                    <section style={{ padding: "24px 24px 24px 24px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid container justify="space-between" style={{ flexWrap: "nowrap" }} spacing={2}>
                                    <Grid item>
                                        <Typography variant="h6">This saved filter includes one or more attention filters. Select the window you would like to view the results?</Typography>
                                    </Grid>
                                    <Grid item style={{ paddingTop: 12 }}>
                                        <div
                                            className="pointer"
                                            onClick={() => setRedirectDialogOpen(false)}
                                        >
                                            <Close />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography
                                            className="MuiLink-underlineHover pointer"
                                            component="a"
                                            color="primary"
                                            onClick={() => pushToTimesheetsDetailsPage()}
                                        >
                                            Time Details Window
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            (Attention filter will not be applied)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography
                                            className="MuiLink-underlineHover pointer"
                                            component="a"
                                            color="primary"
                                            onClick={pushToAttentionTimesheetsDetailsPage}
                                        >
                                            Time Attention Window
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            (Attention filter will be applied)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </section>
                </CustomDrawer>
            )}
        </React.Fragment>
    );
};

export default withConfirmDialogContext(CmoProtocolMain);
