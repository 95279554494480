import {
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from "@material-ui/core";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight,
    DotsVertical,
    Magnify,
} from "mdi-material-ui";
import moment from "moment";
import * as React from "react";
import { getAllResourcesList } from "../../services/ResourceService";
import {
    StyledTableCell,
    StyledTableRow,
    getCeilValueForTotalCount,
} from "../../services/UtilService";
import { IConfirmDialog, IResourceSearch, IResource } from "../../vm";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import AddAliasDialog from "./AddAliasDialog";
import ManageViewAliasDialog from "./ManageViewAliasDialog";
import ReactSelect from "react-select";
import { getAllFirms } from "../../services/FirmsService";
import { FirmContext } from "../common/FirmProvider";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";
import { MAX_PAGINATION_OPTIONS } from "../../Constant";

export interface AccessManagementMainProps extends IConfirmDialog { }

const ResourcesMain: React.FC<AccessManagementMainProps> = ({
    confirmDialog,
}) => {
    const [state, setState] = React.useState({
        search: {
            searchName: "",
            lawFirmCode: "",
            lawFirmCodeDetails: undefined,
            from: 0,
            size: 10,
            sortOrder: "asc",
            sortBy: "resourceName"
        },
        resourcesList: [],
        selectedItemIndex: -1,
        selectedIndex: -1,
        anchorElMenu: null,
        manageViewAliasDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
            type: "newAlias",
            isNewResource: false
        },
        manageEditResourceDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
            isNewResource: false
        },
        rowsList: [10],
        resourcesCount: 0,
    } as {
        search: IResourceSearch;
        resourcesList: IResource[];
        selectedItemIndex: number;
        selectedIndex: number;
        anchorElMenu: any;
        manageViewAliasDialog: {
            isOpen: boolean;
            index?: number;
            data?: IResource;
            isNewResource: boolean;
            type: "viewResourceAlias" | "newAlias"
        };
        manageEditResourceDialog: {
            isOpen: boolean;
            index?: number;
            data?: IResource;
            isNewResource: boolean;
        };

        rowsList: number[];
        resourcesCount: number;
    });

    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);
    const [firmList, setFirmList] = React.useState([]);
    const firmProvider = React.useContext(FirmContext);
    const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

    React.useEffect(() => {
        const fetchDetails = async () => {
            await fetchResourcesList();
            await getAllFirmsList();
        };
        fetchDetails();
    }, []);

    const getAllFirmsList = async () => {
        setLoading(true);
        let userFirms = firmProvider.userFirms;
        if (userFirms.length === 0) {
            userFirms = await firmProvider.getFirmsList();
        }
        let list: any = [];
        userFirms.forEach((ele) => {
            let firm = { value: ele.lawFirmCode, label: ele.lawFirmShortName };
            list.push(firm);
        });
        setFirmList(list);
        setLoading(false);
        // setLoading(true);
        // let result = await getAllFirms();
        // setLoading(false);
        // if (result?.isSuccess) {
        //     let list: any = [];
        //     result.data.forEach((ele) => {
        //         let firm = { value: ele.lawFirmCode, label: ele.lawFirmShortName };
        //         list.push(firm);
        //     });
        //     setFirmList(list);

        // } else {
        //     showToast(result.message || "Error while getting firms", "error")
        // }
    };


    const fetchResourcesList = async (searchObj?: any) => {
        let tempSearch = { ...state.search };
        if (searchObj) {
            tempSearch = { ...tempSearch, ...searchObj };
        }
        setLoading(true);
        if (!tempSearch.searchName) {
            delete tempSearch.searchName;
        }
        if (!tempSearch.lawFirmCode) {
            delete tempSearch.lawFirmCode;
        }

        const result = await getAllResourcesList(tempSearch);
        if (result?.isSuccess) {
            let menuItems = [];
            for (let index = 10; index <= result.data.count; index = index + 10) {
                menuItems.push(index);
            }
            if (menuItems[menuItems.length - 1] < result.data.count) {
                menuItems.push(getCeilValueForTotalCount(result.data.count));
                // menuItems.push(result.data.count);
            }
            if (menuItems.length > MAX_PAGINATION_OPTIONS) {
                menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
            }
            // else {
            //     let totalCount = menuItems.pop();
            //     if (totalCount) {
            //         menuItems.unshift({ label: "All", value: totalCount });
            //     }
            // }
            setState({
                ...state,
                resourcesCount: result.data?.count || 0,
                resourcesList: result.data?.employeeResources,
                search: { ...state.search, ...tempSearch },
                rowsList: [...menuItems],
                manageViewAliasDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined,
                    type: "newAlias",
                    isNewResource: false
                },
                manageEditResourceDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined,
                    isNewResource: false
                },

            });
        } else {
            showToast(
                result?.message ? result.message : "Error while fetching resources list",
                "error"
            );
        }
        setLoading(false);
    };

    const handleClickMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        index: number
    ) => {
        setState({
            ...state,
            selectedItemIndex: index,
            selectedIndex: index,
            anchorElMenu: event.currentTarget,
        });
    };

    const handleCloseMenu = () => {
        setState({ ...state, selectedItemIndex: -1, anchorElMenu: null });
    };


    const handleAddDialogOpen = (isOpen: boolean, editIndex?: number, type: "viewResourceAlias" | "newAlias" = "viewResourceAlias") => {
        let data: any = undefined;
        if (editIndex !== undefined) {
            data = state.resourcesList[editIndex];
        }
        setState({
            ...state,
            manageViewAliasDialog: {
                isOpen: isOpen,
                data: data,
                index: editIndex,
                type,
                isNewResource: data ? false : true
            },
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
    };
    const handleEditResourceDialogOpen = (isOpen: boolean, editIndex?: number) => {
        let data: any = undefined;
        if (editIndex !== undefined) {
            data = state.resourcesList[editIndex];
        }
        setState({
            ...state,
            manageEditResourceDialog: {
                isOpen: isOpen,
                data: data,
                index: editIndex,
                isNewResource: data ? false : true
            },
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
    };



    const handleAddDialogClose = (data?: boolean) => {
        if (data) {
            fetchResourcesList();
        } else {
            setState({
                ...state,
                manageViewAliasDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined,
                    isNewResource: false,
                    type: "newAlias"
                },
            });
        }
    };
    const handleResourceDialogClose = (data?: boolean) => {
        if (data) {
            fetchResourcesList();
        } else {
            setState({
                ...state,
                manageEditResourceDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined,
                    isNewResource: false
                },
            });
        }
    };

    const onSearch = async (searchObj?: any) => {
        let tempSearch = { ...state.search };
        if (searchObj) {
            tempSearch = { ...state.search, ...searchObj };
        }
        await fetchResourcesList(tempSearch);
    };

    const onSort = async (sortBy) => {
        let search = { ...state.search };
        if (sortBy === search.sortBy) {
            search.sortOrder = search.sortOrder === "asc" ? "desc" : "asc";
        } else {
            search.sortBy = sortBy;
            search.sortOrder = "asc";
        }
        search.from = 0;
        await fetchResourcesList(search);
    };


    const onKeyDown = async (e: any) => {
        if (e.keyCode === 13) {
            await onSearch({ ...state.search, from: 0 });
        }
    };

    const TablePaginationActions = (props: TablePaginationActionsProps) => {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className="d-flex">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <ChevronDoubleLeft />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ChevronRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <ChevronDoubleRight />
                </IconButton>
            </div>
        );
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">Resource Management</Typography>
                </div>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="space-between">
                            <Grid item lg={6}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item lg={5}>
                                        <TextField fullWidth
                                            className="flex-grow"
                                            name="searchName"
                                            value={state.search.searchName}
                                            onChange={(event) => {
                                                let value = event.target.value;
                                                setState({
                                                    ...state,
                                                    search: { ...state.search, searchName: value },
                                                });
                                            }}
                                            onKeyDown={(e) => {
                                                onKeyDown(e);
                                            }}
                                            placeholder="Search"
                                        />
                                    </Grid>
                                    <Grid item lg={5}>
                                        <ReactSelect
                                            className="react-select"
                                            placeholder="Firm"
                                            isClearable
                                            value={state.search.lawFirmCodeDetails}
                                            onChange={(data) =>
                                                setState({
                                                    ...state,
                                                    search: {
                                                        ...state.search,
                                                        lawFirmCode: data ? data.value : "",
                                                        lawFirmCodeDetails: data
                                                    },
                                                })
                                            }
                                            options={firmList}
                                        />
                                    </Grid>
                                    <Grid item lg={2}>
                                        <IconButton
                                            onClick={() => onSearch({ ...state.search, from: 0 })}
                                        >
                                            <Magnify />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isReadOnly}
                                    onClick={() => {
                                        handleEditResourceDialogOpen(true, undefined);
                                    }}
                                >
                                    Add new Resource
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TableContainer className="resources-table-container moz-table-pb">
                                            <Table stickyHeader size="small" className="custom-table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell style={{ minWidth: 120, maxWidth: 120, width: 120 }}>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "lawFirmName"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("lawFirmName");
                                                                }}
                                                            >
                                                                Firm
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{ minWidth: 170, maxWidth: 170, width: 170 }}>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "resourceName"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("resourceName");
                                                                }}
                                                            >
                                                                Name
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "nameSuffix"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("nameSuffix");
                                                                }}
                                                            >
                                                                Suffix
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "firstName"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("firstName");
                                                                }}
                                                            >
                                                                First Name
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "lastName"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("lastName");
                                                                }}
                                                            >
                                                                Last Name
                                                            </TableSortLabel>
                                                        </StyledTableCell> */}
                                                        <StyledTableCell style={{ minWidth: 190, maxWidth: 190, width: 190 }}>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "aliasNameList"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("aliasNameList");
                                                                }}
                                                            >
                                                                Alias Names
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "workStatus"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("workStatus");
                                                                }}
                                                            >
                                                                Work Status
                                                            </TableSortLabel>
                                                        </StyledTableCell> */}
                                                        <StyledTableCell style={{ minWidth: 180, maxWidth: 180, width: 180 }}>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "jobTitleClass"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("jobTitleClass");
                                                                }}
                                                            >
                                                                Professional Characterization
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "partnerEligibleDate"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("partnerEligibleDate");
                                                                }}
                                                            >
                                                                Partner Eligible Date
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "pscMemberCode"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("pscMemberCode");
                                                                }}
                                                            >
                                                                Committee Membership
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "authorizedCbDiscDocRevProd"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("authorizedCbDiscDocRevProd");
                                                                }}
                                                            >
                                                                Authorized Doc Review/Production
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={state.search.sortBy === "barAdmissionDate"}
                                                                direction={state.search.sortOrder}
                                                                onClick={() => {
                                                                    onSort("barAdmissionDate");
                                                                }}
                                                            >
                                                                Admission Date
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>Actions</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {state.resourcesList.length > 0 ? (
                                                        state.resourcesList.map((ele, index: number) => (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell>{ele.lawFirmName}</StyledTableCell>
                                                                <StyledTableCell>{ele.firstName || ""} {ele.lastName || ""} {ele.nameSuffix || ""}</StyledTableCell>
                                                                {/* <StyledTableCell>{ele.nameSuffix}</StyledTableCell>
                                                                <StyledTableCell>{ele.firstName}</StyledTableCell>
                                                                <StyledTableCell>{ele.lastName}</StyledTableCell> */}
                                                                <StyledTableCell>{ele.aliasNameList}</StyledTableCell>
                                                                {/* <StyledTableCell>{ele.workStatus}</StyledTableCell> */}
                                                                <StyledTableCell>{ele.jobTitleClassName}</StyledTableCell>
                                                                <StyledTableCell>{ele.partnerEligibleDate ? moment(ele.partnerEligibleDate).format("MM/DD/YYYY") : ""}</StyledTableCell>
                                                                <StyledTableCell>{ele.pscMemberCode}</StyledTableCell>
                                                                <StyledTableCell>{ele.authorizedCbDiscDocRevProd}</StyledTableCell>
                                                                <StyledTableCell>{ele.barAdmissionDate ? moment(ele.barAdmissionDate).format("MM/DD/YYYY") : ""}</StyledTableCell>
                                                                <StyledTableCell>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={(e) => handleClickMenu(e, index)}
                                                                    >
                                                                        <DotsVertical />
                                                                    </IconButton>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={9}>
                                                                No resources found{" "}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={state.rowsList}
                                            colSpan={5}
                                            component="div"
                                            count={state.resourcesCount}
                                            rowsPerPage={state.search.size}
                                            page={
                                                state.search.from === 0
                                                    ? 0
                                                    : state.search.from / state.search.size
                                            }
                                            SelectProps={{
                                                inputProps: { "aria-label": "rows per page" },
                                                native: true,
                                            }}
                                            onPageChange={(event: any, newPage: number) => {
                                                console.log("event", event);
                                                console.log("newPage", newPage);
                                                let search = { ...state.search };
                                                search.from =
                                                    newPage === 0
                                                        ? 0
                                                        : (newPage * search.size);
                                                onSearch(search);
                                            }}
                                            onRowsPerPageChange={(event: any) => {
                                                let value = event.target.value;
                                                let search = { ...state.search };
                                                search.size = value ? Number(value) : 10;
                                                search.from = 0;
                                                onSearch(search);
                                            }}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            <Menu
                id="simple-menu"
                anchorEl={state.anchorElMenu}
                keepMounted
                open={Boolean(state.anchorElMenu)}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    onClick={() => {
                        handleAddDialogOpen(true, state.selectedItemIndex, "viewResourceAlias");
                    }}
                >
                    View Aliases
                </MenuItem>
                <MenuItem
                    disabled={isReadOnly}
                    onClick={() => {
                        handleEditResourceDialogOpen(true, state.selectedItemIndex);
                    }}
                >
                    Edit
                </MenuItem>
            </Menu>
            {(state.manageViewAliasDialog.isOpen && state.manageViewAliasDialog.type === "viewResourceAlias") && (
                <ManageViewAliasDialog
                    data={state.manageViewAliasDialog.data}
                    handleDialogClose={(data?: boolean) => handleAddDialogClose(data)}
                    updateStatus={updateStatus}
                />
            )}
            {(state.manageEditResourceDialog.isOpen) && (
                <AddAliasDialog
                    data={state.manageEditResourceDialog.data}
                    handleDialogClose={(data?: any) => handleResourceDialogClose(data)}
                    isNewResource={state.manageEditResourceDialog.isNewResource}
                    handleViewAlias={() => handleAddDialogOpen(true, state.selectedIndex, "viewResourceAlias")}
                    updateStatus={updateStatus}
                />
            )}
        </React.Fragment>
    );
};

export default withConfirmDialogContext(ResourcesMain);
