import * as React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableSortLabel,
  Grid,
  Button,
} from "@material-ui/core";
import {
  convertToFixed,
  currencyFormat,
  hoursFormat,
  numberFormat,
  StyledTableCell,
} from "../../services/UtilService";
import { ICategoryHourStats } from "../../vm";
import "./dashboard.css";
import SecondaryLoading from "../common/SecondaryLoading";
export interface CategoryHourStatsProps {
  categoryHourStats: Array<ICategoryHourStats>;
  label?: string;
  onSort: Function;
  sortBy?: any;
  onExport: Function;
  sortOrder?: any;
  isLoading: boolean;
}

const CategoryHourStats: React.FC<CategoryHourStatsProps> = (props) => {
  const onSort = (sortBy?: any) => {
    props.onSort(sortBy);
  };
  const onExport = () => {
    props.onExport();
  };
  return (
    <section className="position-relative">
      {/* {props.isLoading && <SecondaryLoading />} */}
      <Grid container spacing={2} justify="space-between" className="py-16">
        <Grid item>
          <Typography variant="h6">
            {props.label ? props.label : "Time Category"}
          </Typography>
        </Grid>
        {props?.categoryHourStats?.length > 0 && !props.isLoading &&
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onExport();
              }}
            >
              Export
            </Button>
          </Grid>
        }
      </Grid>
      {props.isLoading ? <SecondaryLoading /> :
        <TableContainer className="time-category-dashboard-table-container moz-table-pb">
          <Table size="small" className="custom-table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  width="150px"
                  className="head-header fixed-header-column"
                  align="center"
                >
                  <TableSortLabel
                    active={props.sortBy === "CATEGORY_CODE"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("CATEGORY_CODE");
                    }}
                  >
                    Category
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "Total_Fees"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Total_Fees");
                    }}
                  >
                    Total Fees
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "Avg_Hourly_Rate"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Avg_Hourly_Rate");
                    }}
                  >
                    Avg Hourly Rate
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "High_Hourly_Rate"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("High_Hourly_Rate");
                    }}
                  >
                    High Hourly Rate
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "Low_Hourly_Rate"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Low_Hourly_Rate");
                    }}
                  >
                    Low Hourly Rate
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "Total_Hours"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Total_Hours");
                    }}
                  >
                    Total # Hours
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "Avg_Hours"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Avg_Hours");
                    }}
                  >
                    Avg # Hours
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "High_Hours"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("High_Hours");
                    }}
                  >
                    High # Hours
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "Low_Hours"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Low_Hours");
                    }}
                  >
                    Low # Hours
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "Time_Entries"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Time_Entries");
                    }}
                  >
                    # Time Entries
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "Employees"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Employees");
                    }}
                  >
                    # Resources
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="center" className="fixed-header-column">
                  <TableSortLabel
                    active={props.sortBy === "Per_Total_Time"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Per_Total_Time");
                    }}
                  >
                    % of Total Time
                  </TableSortLabel>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.categoryHourStats?.length > 0 ? (
                <React.Fragment>
                  {props.categoryHourStats.map((categoryHour, index) => (
                    <TableRow key={`categoryStats${index}`}>
                      <TableCell className="head-column">
                        {categoryHour.categoryName}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(categoryHour.totalFees))}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(categoryHour.avgHourlyRate)
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(categoryHour.highHourlyRate)
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(categoryHour.lowHourlyRate)
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(categoryHour.totalHours)}
                        {/* {convertToFixed(categoryHour.totalHours)} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(categoryHour.avgHours)}
                        {/* {convertToFixed(categoryHour.avgHours)} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(categoryHour.highHours)}
                        {/* {convertToFixed(categoryHour.highHours)} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(categoryHour.lowHours)}
                        {/* {convertToFixed(categoryHour.lowHours)} */}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(categoryHour.timeEntries))}
                      </TableCell>
                      <TableCell align="right">
                        {convertToFixed(categoryHour.employees)}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(categoryHour.perTotalTime))}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No Data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </section>
  );
};

export default CategoryHourStats;
