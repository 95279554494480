import { AppBar, Tab, Tabs } from "@material-ui/core";
import * as React from "react";
import { ToastContext } from "../common/ToastProvider";
import ExpenseDashboardMain from "../expense-dashboard/ExpenseDashboardMain";
import FirmsDashboardMain from "./FirmsDashboardMain";
import TimesheetDashboardMain from "./TimesheetDashboardMain";
import UsersDashboardMain from "./UsersDashboardMain";

export interface DashboardMainProps {}

const DashboardMain: React.FC<DashboardMainProps> = () => {
  const { showToast } = React.useContext(ToastContext);
  const [state, setState] = React.useState({
    tabValue: 0,
  } as {
    tabValue: number;
  });

  const handleTabValueChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setState({ ...state, tabValue: newValue });
  };

  return (
    <React.Fragment>
      <AppBar position="static" color="default">
        <Tabs value={state.tabValue} onChange={handleTabValueChange}>
          <Tab label="Timesheets" />
          <Tab label="Expenses" />
          <Tab label="Firms" />
          {/* <Tab label="Expense Firms" /> */}
          {/* <Tab label="Users" /> */}
        </Tabs>
      </AppBar>
      <section>
        {state.tabValue === 0 && <TimesheetDashboardMain isFirm={false} />}
        {state.tabValue === 1 && <ExpenseDashboardMain isFirm={false} />}
        {state.tabValue === 2 && <FirmsDashboardMain />}
        {/* {state.tabValue === 3 && <ExpenseDashboardMain isFirm={true} />} */}
        {/* {state.tabValue===4&&(<UsersDashboardMain/>)} */}
      </section>
    </React.Fragment>
  );
};

export default DashboardMain;
