import {
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    TableSortLabel,
    FormControl,
    TextField
} from "@material-ui/core";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight,
    DotsVertical
} from "mdi-material-ui";
import * as React from "react";
import { deleteNote, getAllNotes } from "../../services/NoteService";
import {
    StyledTableCell,
    StyledTableRow,
    getCeilValueForTotalCount,
} from "../../services/UtilService";
import { IConfirmDialog, INote, IReactSelect, IUser } from "../../vm";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import Select from "react-select";
import { MAX_PAGINATION_OPTIONS, NOTE_TYPES } from "../../Constant";
import { getNoteUsers } from "../../services/UserService";
import NoteEntriesDialog from "./NoteEntriesDialog";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

export interface NotesMainProps extends IConfirmDialog { }

const NotesMain: React.FC<NotesMainProps> = ({ confirmDialog }) => {
    const history = useHistory();
    const [searchObj, setSearchObj] = React.useState({
        from: 0,
        size: 10,
        noteName: "",
        sortBy: "createdDate",
        sortOrder: "desc",
        type: { label: "All", value: "all" },
        userIds: [],
    } as {
        from: number;
        size: number;
        type: IReactSelect,
        noteName: string,
        userIds: IReactSelect[];
        sortBy: string;
        sortOrder: "desc" | "asc";
    });

    const [state, setState] = React.useState({
        notesList: [],
        rowsList: [10],
        totalNotesCount: 0,
    } as {
        notesList: INote[];
        rowsList: number[];
        totalNotesCount: number;
    });
    const [usersList, setUsersList] = React.useState([] as IUser[]);

    const [notesDialogData, setNotesDialogData] = React.useState({
        selectedItemIndex: -1,
        anchorElMenu: null,
        manageNoteDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
        },

    } as {
        selectedItemIndex: number;
        anchorElMenu: any;
        manageNoteDialog: {
            isOpen: boolean;
            index?: number;
            data?: INote;
        };
    });

    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);
    const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await fetchNotesList(undefined, true);
            await fetchUsersList(true);
            setLoading(false);
        };
        fetchData();
    }, []);

    const fetchNotesList = async (fetchSearchObj?: any, isDefaultCall = false) => {
        let tempSearch = { ...searchObj };
        if (fetchSearchObj) {
            tempSearch = { ...tempSearch, ...fetchSearchObj };
        }
        setLoading(true);
        let searchData = {
            from: tempSearch.from,
            size: tempSearch.size,
            type: undefined,
            userIds: [],
            sortBy: tempSearch.sortBy,
            sortOrder: tempSearch.sortOrder,
            noteName: tempSearch.noteName
        }
        if (tempSearch.type?.value && tempSearch.type?.value !== "all") {
            searchData.type = tempSearch.type?.value
        }
        if (tempSearch.userIds?.length > 0) {
            searchData.userIds = tempSearch.userIds.map(x => x.value);
        }
        const result = await getAllNotes(searchData);
        if (result?.isSuccess) {
            let menuItems = [];
            for (let index = 10; index <= result.data.count; index = index + 10) {
                menuItems.push(index);
            }
            if (menuItems[menuItems.length - 1] < result.data.count) {
                menuItems.push(getCeilValueForTotalCount(result.data.count));
                // menuItems.push(result.data.count);
            }
            if (menuItems.length > MAX_PAGINATION_OPTIONS) {
                menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
            }
            // else {
            //     let totalCount = menuItems.pop();
            //     if (totalCount) {
            //         menuItems.unshift({ label: "All", value: totalCount });
            //     }
            // }
            setState({
                ...state,
                totalNotesCount: result.data?.count || 0,
                notesList: result.data?.results,
                rowsList: [...menuItems],
            });
            setSearchObj({ ...tempSearch },)
        } else {
            showToast(
                result?.message ? result.message : "Error while fetching notes list",
                "error"
            );
        }
        if (!isDefaultCall) {
            setLoading(false);
        }
    };

    const onSort = async (sortBy) => {
        let search = { ...searchObj };
        if (sortBy === search.sortBy) {
            search.sortOrder = search.sortOrder === "asc" ? "desc" : "asc";
        } else {
            search.sortBy = sortBy;
            search.sortOrder = "asc";
        }
        search.from = 0;
        await fetchNotesList(search);
    };

    const fetchUsersList = async (isDefaultCall = false) => {
        setLoading(true);
        const result = await getNoteUsers();
        if (result?.isSuccess) {
            setUsersList(result.data);
        } else {
            showToast(
                result?.message ? result.message : "Error while fetching users list",
                "error"
            );
        }
        if (!isDefaultCall) {
            setLoading(false);
        }
    };

    const onSearch = async (searchObj?: any) => {
        let tempSearch = { ...searchObj };
        if (searchObj) {
            tempSearch = { ...searchObj, ...searchObj };
        }
        await fetchNotesList(tempSearch);
    };

    const TablePaginationActions = (props: TablePaginationActionsProps) => {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className="d-flex">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <ChevronDoubleLeft />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ChevronRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <ChevronDoubleRight />
                </IconButton>
            </div>
        );
    };

    const updateSearchFiled = (field, value) => {
        setSearchObj({
            ...searchObj,
            [field]: value
        })
    }

    const handleClickMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        index: number
    ) => {
        setNotesDialogData({
            ...notesDialogData,
            selectedItemIndex: index,
            anchorElMenu: event.currentTarget,
        });
    };

    const handleCloseMenu = () => {
        setNotesDialogData({ ...notesDialogData, selectedItemIndex: -1, anchorElMenu: null });
    };

    const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
        let data: any = undefined;
        if (editIndex !== undefined) {
            data = state.notesList[editIndex];
        }
        setNotesDialogData({
            ...notesDialogData,
            manageNoteDialog: {
                isOpen: isOpen,
                data: data,
                index: editIndex,
            },
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
    }

    const handleDelete = (index: number) => {
        setNotesDialogData({
            ...notesDialogData,
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
        confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
            setLoading(true);
            let notesList = [...state.notesList];
            const result = await deleteNote(notesList[index].id);
            if (result?.isSuccess) {
                if (result?.isReadOnly) {
                    showToast(result.message, "error");
                    updateStatus(result.isReadOnly);
                } else {
                    await fetchNotesList();
                    showToast("Note deleted successfully", "success");
                }
            } else {
                showToast(result?.message || "Error while deleting note", "error");
                setLoading(false);
            }
        });
    };

    const redirectToTimesheetDetailsPage = (index: number) => {
        let noteId = "";
        if (index !== undefined && index !== -1) {
            noteId = state.notesList[index]?.id as any || "";
        }

        history.push({
            pathname: `/timesheets-details`,
            state: { noteId },
        });
    }

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">Notes</Typography>
                </div>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="noteName"
                                                name="noteName"
                                                label="Note Name"
                                                onChange={eve => {
                                                    updateSearchFiled("noteName", eve.target.value);
                                                }}
                                                value={searchObj.noteName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={3} md={6} sm={6} xs={12}>
                                        <Select
                                            className="react-select"
                                            placeholder="Users"
                                            value={searchObj.userIds}
                                            isMulti={true}
                                            onChange={(data) =>
                                                updateSearchFiled("userIds", data)
                                            }
                                            options={usersList.map(user => {
                                                return { label: user.userName, value: user.id } as any
                                            })}
                                        />
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <Select
                                            className="react-select"
                                            placeholder="Type"
                                            value={searchObj.type}
                                            onChange={(data) =>
                                                updateSearchFiled("type", data)
                                            }
                                            options={Object.keys(NOTE_TYPES).map(key => {
                                                return { label: NOTE_TYPES[key], value: key } as any
                                            })}
                                        />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={6} xs={12}>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                let search = { ...searchObj };
                                                search.from = 0;
                                                onSearch(search);
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TableContainer className="notes-table-container moz-table-pb">
                                            <Table stickyHeader size="small" className="custom-table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "createdDate"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("createdDate");
                                                                }}
                                                            >
                                                                Created
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "user"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("user");
                                                                }}
                                                            >
                                                                User
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "Type"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("type");
                                                                }}
                                                            >
                                                                Type
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "description"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("description");
                                                                }}
                                                            >
                                                                Note
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {state.notesList.length > 0 ? (
                                                        state.notesList.map((ele, index: number) => (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell>{moment(ele.createdTimestamp).format("DD-MM-YYYY")}</StyledTableCell>
                                                                <StyledTableCell>{ele.userName}</StyledTableCell>
                                                                <StyledTableCell>{NOTE_TYPES[ele.type]}</StyledTableCell>
                                                                <StyledTableCell>{ele.description}</StyledTableCell>
                                                                <StyledTableCell>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={(e) => handleClickMenu(e, index)}
                                                                    >
                                                                        <DotsVertical />
                                                                    </IconButton>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={5}>
                                                                No notes found
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={state.rowsList}
                                            colSpan={4}
                                            component="div"
                                            count={state.totalNotesCount}
                                            rowsPerPage={searchObj.size}
                                            page={
                                                searchObj.from === 0
                                                    ? 0
                                                    : searchObj.from / searchObj.size
                                            }
                                            SelectProps={{
                                                inputProps: { "aria-label": "rows per page" },
                                                native: true,
                                            }}
                                            onPageChange={(event: any, newPage: number) => {
                                                console.log("event", event);
                                                console.log("newPage", newPage);
                                                let search = { ...searchObj };
                                                search.from =
                                                    newPage === 0
                                                        ? 0
                                                        : (newPage * searchObj.size);
                                                onSearch(search);
                                            }}
                                            onRowsPerPageChange={(event: any) => {
                                                let value = event.target.value;
                                                let search = { ...searchObj };
                                                search.size = value ? Number(value) : 10;
                                                search.from = 0;
                                                onSearch(search);
                                            }}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            <Menu
                id="simple-menu"
                anchorEl={notesDialogData.anchorElMenu}
                keepMounted
                open={Boolean(notesDialogData.anchorElMenu)}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    onClick={() => {
                        // handleAddDialogOpen(true, notesDialogData.selectedItemIndex);
                        redirectToTimesheetDetailsPage(notesDialogData.selectedItemIndex)
                    }}
                >
                    View Entries
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleDelete(notesDialogData.selectedItemIndex);
                    }}
                    disabled={isReadOnly}
                >
                    Delete
                </MenuItem>
            </Menu>
            {notesDialogData.manageNoteDialog.isOpen && (
                <NoteEntriesDialog data={notesDialogData.manageNoteDialog.data} handleDialogClose={(res) => {
                    handleAddDialogOpen(false);
                }} />
            )}
        </React.Fragment>
    );
};

export default withConfirmDialogContext(NotesMain);
