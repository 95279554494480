import React from "react";
import {
    Drawer,
    Theme,
    AppBar,
    Toolbar,
    Grid,
    Typography,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import { Close } from "mdi-material-ui";

export interface CustomDialogDrawerProps {
    title?: any;
    handleOpen?: boolean;
    class?: string;
    onClose: Function;
    variant?: "fullDrawer" | "medium" | "large" | "small";
}

const CustomDialogDrawer: React.FC<CustomDialogDrawerProps> = (props) => {
    const classes = styles();
    return (
        <Drawer
            anchor="right"
            // disableBackdropClick={true}
            disableEscapeKeyDown={true}
            disableEnforceFocus={true}
            className={`drawer ${props.variant ? `${props.variant}-drawer` : "small-drawer"
                } ${props.class} ${classes.drawer}`}
            open={props.handleOpen === false ? false : true}
            // onClose={() => props.onClose()}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    props.onClose();
                }
            }}
        >
            {props.title && (
                <AppBar color="default" position="absolute" className={classes.appBar}>
                    <Toolbar variant="dense">
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={`${classes.title} fw-semi-bold drawer-title`}
                        >
                            {props.title}
                        </Typography>

                        <Grid>
                            <div
                                className="pointer mt-8"
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                <Close fontSize="small" />
                            </div>
                        </Grid>
                    </Toolbar>
                </AppBar>
            )}
            <section className="p-16 drawer-dialog-body">
                {props.children}
                <br />
            </section>
        </Drawer>
    );
};

const styles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            flexGrow: 1,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            boxShadow: "none",
            background: "#fff",
            borderBottom: "1px solid #ddd",
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarSpacer: {
            height: 48,
        },
        drawer: {
            zIndex: theme.zIndex.drawer + 1,
        },
    })
);

export default CustomDialogDrawer;
