import React from "react";
import { IResourceAlias, IResource, IConfirmDialog } from "../../vm";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Button,
    Grid, IconButton, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import { Close } from "mdi-material-ui";
import { getResourceAliasList, getResourceDetailsAPI } from "../../services/ResourceService";
import AddResourceAlias from "./AddResourceAlias";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import DeleteResourceConfirmDialog from "./DeleteResourceConfirmDialog";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";
export interface IManageViewAliasDialogProps extends IConfirmDialog {
    data?: IResource;
    handleDialogClose: any;
    updateStatus: any;
}

const ManageViewAliasDialog: React.FC<IManageViewAliasDialogProps> = ({
    data,
    handleDialogClose,
    confirmDialog,
    updateStatus
}) => {
    let shouldReload = React.useRef(false);
    let resourceDetails = React.useRef(data);
    // state
    const [state, setState] = React.useState({
        aliasList: [],
        manageViewAliasDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
            type: "newResourceAlias"
        },
    } as {
        aliasList: IResourceAlias[]
        manageViewAliasDialog: {
            isOpen: boolean;
            index?: number;
            data?: any;
            type: "newResourceAlias" | "newAlias" | "deleteResource"
        };
    });
    const [isLoading, setLoading] = React.useState(false);
    React.useEffect(() => {
        getResourceAlias();
    }, []);


    const currentResourceData = React.useRef(data);

    const getResourceDetails = async (resourceName) => {
        setLoading(true);
        let details: IResource = undefined;
        let result = await getResourceDetailsAPI(resourceName, data.lawFirmCode);
        console.log(result);
        if (result?.isSuccess) {
            details = result.data;
        } else {
            showToast(result.message || "Error while resource details", "error")
        }
        setLoading(false)
        return details
    }

    const getResourceAlias = async (updatedName: string = "") => {
        setLoading(true);
        if (updatedName) {
            currentResourceData.current.resourceName = updatedName;
            let details = await getResourceDetails(updatedName);
            resourceDetails.current = details;
        }
        let result = await getResourceAliasList({ lawFirmCode: data.lawFirmCode, resourceName: updatedName || currentResourceData.current.resourceName });
        setLoading(false);
        if (result?.isSuccess) {
            let list: any = result.data.filter(alias => alias.resourceName !== alias.resourceAliasName);
            setState({
                ...state,
                aliasList: list,
                manageViewAliasDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined,
                    type: "newAlias"
                },
            });

        } else {
            showToast(result.message || "Error while getting aliases", "error")
        }
    };

    // custom hooks
    const { showToast } = React.useContext(ToastContext);
    const { isReadOnly } = React.useContext(IsReadOnlyContext);

    const closeAddDialog = () => {
        handleDialogClose(shouldReload.current);
    };

    const handleAddDialogOpen = (isOpen: boolean, editIndex?: number, type?: "newResourceAlias" | "newAlias") => {
        setState({
            ...state,
            manageViewAliasDialog: {
                isOpen: isOpen,
                data: data,
                index: editIndex,
                type,
            },
        });
    };

    const handleAddDialogClose = (data?: any) => {
        if (data && data.isSuccess) {
            shouldReload.current = true;
            getResourceAlias(data.changedName);
        } else {
            setState({
                ...state,
                manageViewAliasDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined,
                    type: "newAlias"
                },
            });
        }
    };

    const handleDeleteDialogClose = async (data) => {
        if (data) {
            shouldReload.current = true;
            getResourceAlias();
        } else {
            setState({
                ...state,
                manageViewAliasDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined,
                    type: "newAlias"
                },
            });
        }
    }

    const deleteResourceAlias = async (index: number) => {
        let data = JSON.parse(JSON.stringify(state.aliasList[index]));
        data.previousResourceName = data.resourceName;
        data.resourceName = data.resourceAliasName;
        setState({
            ...state,
            manageViewAliasDialog: {
                isOpen: true,
                data: data,
                index: index,
                type: "deleteResource",
            },
        });
    }


    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                variant="medium"
                title={"Alias List"}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <Grid container direction="column" spacing={2} >
                    <Grid item lg={12} md={12} sm={12}>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isReadOnly}
                                    onClick={() => {
                                        handleAddDialogOpen(true, undefined, "newResourceAlias");
                                    }}
                                >
                                    Add as Alias To
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Grid container>
                                    <Grid item>
                                        <strong>Firm:</strong>
                                    </Grid>
                                    <Grid item className="resource-field-value">
                                        <label>{resourceDetails.current?.lawFirmName}</label>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Grid container>
                                    <Grid item>
                                        <strong>Name:</strong>
                                    </Grid>
                                    <Grid item className="resource-field-value">
                                        <label>{resourceDetails.current?.firstName || ""} {resourceDetails.current?.lastName || ""} {resourceDetails.current?.nameSuffix || ""}</label>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <TableContainer className="moz-table-pb">
                            <Table stickyHeader size="small" className="custom-table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Firm</StyledTableCell>
                                        <StyledTableCell>Resource Name</StyledTableCell>
                                        <StyledTableCell>Resource Alias</StyledTableCell>
                                        <StyledTableCell>Suffix</StyledTableCell>
                                        <StyledTableCell>First Name</StyledTableCell>
                                        <StyledTableCell>Last Name</StyledTableCell>
                                        <StyledTableCell>Version</StyledTableCell>
                                        <StyledTableCell>Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.aliasList.length > 0 ? (
                                        state.aliasList.map((ele, index: number) => (
                                            <React.Fragment>
                                                {ele.resourceName !== ele.resourceAliasName && (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell>{ele.lawFirmName}</StyledTableCell>
                                                        <StyledTableCell>{ele.resourceName || ""}</StyledTableCell>
                                                        <StyledTableCell>{ele.resourceAliasName || ""}</StyledTableCell>
                                                        <StyledTableCell>{ele.nameSuffix}</StyledTableCell>
                                                        <StyledTableCell>{ele.firstName}</StyledTableCell>
                                                        <StyledTableCell>{ele.lastName}</StyledTableCell>
                                                        <StyledTableCell>{ele.version}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <Tooltip title="Remove Alias" placement="bottom">

                                                                <IconButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        // handleAddDialogOpen(true, index,"newAlias")
                                                                        deleteResourceAlias(index)
                                                                    }}
                                                                    disabled={isReadOnly}
                                                                >
                                                                    <Close />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell align="center" colSpan={8}>
                                                {" "}
                                                No Alias found{" "}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {(state.manageViewAliasDialog.isOpen && state.manageViewAliasDialog.type === "newResourceAlias") && (
                    <AddResourceAlias
                        data={state.manageViewAliasDialog.data}
                        handleDialogClose={(data?: any) => handleAddDialogClose(data)}
                        updateStatus={updateStatus}
                    />
                )}
                {(state.manageViewAliasDialog.isOpen && state.manageViewAliasDialog.type === "deleteResource") && (
                    <DeleteResourceConfirmDialog
                        confirmDialog={confirmDialog}
                        data={state.manageViewAliasDialog.data}
                        handleDialogClose={(data?: any) => handleDeleteDialogClose(data)}
                        updateStatus={updateStatus}
                    />
                )}
            </CustomDialogDrawer>
        </React.Fragment>
    );
};

export default withConfirmDialogContext(ManageViewAliasDialog);
