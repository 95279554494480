import {
    Grid,
    Typography, Card, CardContent
} from "@material-ui/core";
import * as React from "react";
import "./rules.css"
export interface RulesMainProps { }

const RulesMain: React.FC<RulesMainProps> = ({
}) => {

    return (
        <React.Fragment>
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">Rules</Typography>
                </div>
                <br />
                <Card >
                    <CardContent className="rules-content">
                        <ol>
                            <li>
                                Time Rules
                                <ol>
                                    <li>
                                        Time Spent is not a valid number
                                        <ol>
                                            <li>Replace Time Spent value with: 0.0</li>
                                            <li>Apply Attention status to entry: No (default)</li>
                                        </ol>
                                    </li>
                                    <li>
                                        Time Spent more than one decimal place
                                        <ol>
                                            <li>Round up to nearest tenth place (or)</li>
                                            <li>Round down to the nearest tenth place (or)</li>
                                            <li>Truncate to the tenth place (or)</li>
                                            <li>Accept entry with more than one decimal place
                                                <ol>
                                                    <li>Apply Attention status to entry: Yes (default)</li>

                                                </ol>
                                            </li>

                                            <li>Apply Disapproved status to entry: No (default)</li>
                                        </ol>
                                    </li>

                                    <li>Hours in a Day Exceeds 24
                                        <ol>

                                            <li>When the sum of Time Spent hours for a single Resource on a single Service Date is greater than 24.0 Apply Attention status to entry: Yes (default)</li>
                                        </ol>
                                    </li>
                                    <li>Service Date Missing or Invalid
                                        <ol>

                                            <li>Effective date for compensable fees: June 16, 2016 (default)
                                                <ol>

                                                    <li>Entries with Service Dates prior to compensable fees Effective date: apply Attention Status (default) or Disapprove Status</li>
                                                </ol>
                                            </li>

                                            <li>When Service Date is missing or invalid date value
                                                <ol>
                                                    <li>Replace with: January 01, 1900 (default)</li>
                                                    <li>Apply Attention status to entry: Yes (default)</li>

                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>New or Missing Resources
                                        <ol>
                                            <li>Allow/Disallow new Resources during import</li>
                                            <li>If Disallowed
                                                <ol>
                                                    <li>Replace missing or invalid Resource with: “Unknown, Unknown” (or)
                                                        <ol>
                                                            <li>Apply Attention status to entry: Yes (default)</li>

                                                        </ol>
                                                    </li>
                                                    <li>Reject sheet on import</li>

                                                </ol>
                                            </li>

                                        </ol>
                                    </li>
                                    <li>New or Missing Professional Level
                                        <ol>
                                            <li>Replace missing or invalid Professional Level with: “Unknown”</li>
                                            <li>Apply Attention status to entry: Yes (default)</li>
                                        </ol>
                                    </li>

                                    <li>New or Missing Time Categories
                                        <ol>
                                            <li>Replace missing or invalid Professional Level with: “Unknown”</li>
                                            <li>Apply Attention status to entry: Yes (default)</li>

                                        </ol>
                                    </li>
                                    <li>Approved By Missing/Invalid
                                        <ol>
                                            <li>Replace missing or invalid Approved by with: “Unknown”</li>
                                            <li>Apply Attention status to entry: Yes (default)</li>
                                        </ol>
                                    </li>
                                    <li>Keyword Match
                                        <ol>

                                            <li>When more than one Resource in one Law Firm has the same Keyword match on the service date in their time entry descriptions.</li>
                                            <li>Apply Attention status to entry: Yes (default)</li>
                                            <li>Exclude Disapproved status entries: Yes (default)</li>
                                        </ol>
                                    </li>

                                </ol>
                            </li>
                            <li>Expense Rules
                                <ol>
                                    <li>New or Missing Expense Categories
                                        <ol>

                                            <li>Replace missing or invalid Professional Level with: “Unknown”</li>
                                            <li>Apply Attention status to entry: No (default)</li>
                                        </ol>
                                    </li>

                                    <li>Amount more than two decimal places
                                        <ol>

                                            <li>Round up to nearest hundredths place</li>
                                            <li>Round down to the nearest hundredths place</li>
                                            <li>Truncate to the hundredths place</li>
                                            <li>Apply Disapproved status to entry: No (default)</li>
                                        </ol>
                                    </li>

                                    <li>Approved By Missing/Invalid
                                        <ol>

                                            <li>Replace missing or invalid Approved by with: “Unknown”</li>
                                            <li>Apply Attention status to entry: No (default)</li>
                                        </ol>
                                    </li>
                                    <li>mount is not a valid number
                                        <ol>

                                            <li>Replace Amount value with: 0.0</li>
                                            <li>Apply Attention status to entry: No (default)</li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </CardContent>
                </Card>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            </section>
        </React.Fragment>
    );
};

export default RulesMain;
