import * as React from 'react';
import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    Typography,
    TableBody,
    TableRow,
    TablePagination,
    IconButton,
} from "@material-ui/core";
import {
    IReportHeader,
    IReportResponse,
    IRportGroupBy
} from "../../vm";
import {
    StyledTableCell, StyledTableRow, convertToFixed, currencyFormat, hoursFormat, numberFormat,
} from "../../services/UtilService";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./report.css"
import {
    BURST_REPORTS_MAP_WITH_VALUE_FOR_TIME_DETAILED_REPORT,
    BUST_REPORT_MAP_FOR_CHANGED_TIME_ENTRIES,
    BUST_REPORT_MAP_FOR_SPLIT_ENTRIES,
    CHANGED_TIME_ENTRIES_METADATA_DICT,
    CHANGED_TIME_ENTRY_COLUMN_MAP_DIC,
    DATE_COLUMNS,
    IMPORTED_TIME_ENTRIES_REPORT_METRICS_DICT,
    SPLIT_ENTRIES_METADATA_DICT,
    TIME_DETAIL_METRICS_MAP_WITH_VALUE,
    TIME_DETAIL_REPORT_METRICS_DICT
} from '../../Constant';
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight,
} from "mdi-material-ui";
import moment from 'moment';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';

const BURST_REPORT_MAP_WITH_PROPS_DICT_BY_REPORT_ID = {
    1: BURST_REPORTS_MAP_WITH_VALUE_FOR_TIME_DETAILED_REPORT,
    7: BUST_REPORT_MAP_FOR_CHANGED_TIME_ENTRIES,
    8: BUST_REPORT_MAP_FOR_SPLIT_ENTRIES,
}

const METRICS_DICT_BY_REPORT_ID = {
    1: TIME_DETAIL_REPORT_METRICS_DICT,
    7: CHANGED_TIME_ENTRIES_METADATA_DICT,
    8: SPLIT_ENTRIES_METADATA_DICT,
}

interface TimeSummaryReportViewProps {
    tableData: IReportResponse[];
    selectedMetrics: string[];
    selectedColumns: string[];
    selectedBurstReports: string[];
    reportId: string;
    lastRowPerPageDict: any;
    setLastRowPerPageDict: any;
}

const TimeSummaryReportView: React.FC<TimeSummaryReportViewProps> = ({ tableData, selectedMetrics, selectedColumns, selectedBurstReports, reportId, lastRowPerPageDict, setLastRowPerPageDict }) => {

    const [formattedData, setFormattedData] = React.useState([] as any);

    const reportRowsPerPage = React.useRef([]);

    const subTotalMergeColumns = React.useRef({})

    const [selectedHeaderProps, setSelectedHeaderProps] = React.useState([] as string[]);
    React.useEffect(() => {
        reportRowsPerPage.current = []
        for (let i = 10; i <= 3000; i = i + 10) {
            reportRowsPerPage.current.push(i);
        }
        let selectedHeaderPropsList = [...selectedMetrics];
        if (selectedMetrics.includes('all')) {
            let metricsObj: { [key: string]: string } = { ...TIME_DETAIL_REPORT_METRICS_DICT };
            switch (reportId) {
                // case "3":
                //     metricsObj = { ...IMPORTED_TIME_ENTRIES_REPORT_METRICS_DICT }
                //     break;
                case "7":
                    metricsObj = { ...CHANGED_TIME_ENTRIES_METADATA_DICT }
                    break;
                case "8":
                    metricsObj = { ...SPLIT_ENTRIES_METADATA_DICT }
                    break;
                default:
                    break;
            }
            selectedHeaderPropsList = Object.keys(metricsObj).filter((d) => d !== 'all');
        }
        setSelectedHeaderProps(selectedHeaderPropsList);
        console.log(selectedHeaderPropsList);
        subTotalMergeColumns.current = {}
        buildData();
    }, []);

    React.useEffect(() => {
        console.log(formattedData);
    }, [formattedData])

    const addSubTotalRow = (childArray, currentTable, selectedHeaders, keysToCheck) => {
        childArray = childArray.map((d) => {
            Object.keys(d).forEach((key) => {
                if (!keysToCheck.includes(key) && key.includes("RowSpan") && d[key] > 0) {
                    d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, currentTable);
                }
            })
            return d;
        });
        return childArray
    }

    const buildData = () => {
        let parentArray = [];
        tableData.forEach((row, rowIndex) => {
            let childParentArray = [];
            if (row.data) {
                row.data.forEach((table, tableIndex) => {
                    let childArray = [];
                    let selectedHeaders = [];
                    const tableRowSpan = { [`${table.groupByProp}RowSpan`]: table.count };
                    const tableRowSpanZero = { [`${table.groupByProp}RowSpan`]: table.count > 1 ? 0 : 1 };
                    if (table.groupByProp) {
                        // adding this value to table
                        !selectedHeaders.includes(table.groupByProp) && selectedHeaders.push(table.groupByProp);
                        table.values.forEach((table2, table2Index) => {
                            const table2RowSpan = { ...tableRowSpan, [`${table2.groupByProp}RowSpan`]: table2.count };
                            const table2RowSpanZero = { ...tableRowSpanZero, [`${table2.groupByProp}RowSpan`]: table2.groupByProp && table2.count > 1 ? 0 : 1 };
                            if (table2.groupByProp) {
                                // adding this value to table
                                !selectedHeaders.includes(table2.groupByProp) && selectedHeaders.push(table2.groupByProp);
                                table2.values.forEach((table3, table3Index) => {
                                    const table3RowSpan = { ...table2RowSpan, [`${table3.groupByProp}RowSpan`]: table3.count };
                                    const table3RowSpanZero = { ...table2RowSpanZero, [`${table3.groupByProp}RowSpan`]: table3.groupByProp && table3.count > 1 ? 0 : 1 };
                                    if (table3.groupByProp) {
                                        // adding this value to table
                                        !selectedHeaders.includes(table3.groupByProp) && selectedHeaders.push(table3.groupByProp);
                                        table3.values.forEach((table4, table4Index) => {
                                            const table4RowSpan = { ...table3RowSpan, [`${table4.groupByProp}RowSpan`]: table4.count };
                                            const table4RowSpanZero = { ...table3RowSpanZero, [`${table4.groupByProp}RowSpan`]: table4.groupByProp && table4.count > 1 ? 0 : 1 };
                                            if (table4.groupByProp) {
                                                // adding this value to table
                                                !selectedHeaders.includes(table4.groupByProp) && selectedHeaders.push(table4.groupByProp);
                                                table4.values.forEach((table5, table5Index) => {
                                                    const table5RowSpan = { ...table4RowSpan, [`${table5.groupByProp}RowSpan`]: table5.count };
                                                    const table5RowSpanZero = { ...table4RowSpanZero, [`${table5.groupByProp}RowSpan`]: table5.groupByProp && table5.count > 1 ? 0 : 1 };
                                                    if (table5.groupByProp) {
                                                        // adding this value to table
                                                        !selectedHeaders.includes(table5.groupByProp) && selectedHeaders.push(table5.groupByProp);
                                                        table5.values.forEach((table6, table6Index) => {
                                                            const table6RowSpan = { ...table5RowSpan, [`${table6.groupByProp}RowSpan`]: table6.count };
                                                            const table6RowSpanZero = { ...table5RowSpanZero, [`${table6.groupByProp}RowSpan`]: table6.groupByProp && table6.count > 1 ? 0 : 1 };

                                                            if (table6.groupByProp) {

                                                                !selectedHeaders.includes(table6.groupByProp) && selectedHeaders.push(table6.groupByProp);
                                                                table6.values.forEach((table7, table7Index) => {
                                                                    const table7RowSpan = { ...table6RowSpan, [`${table7.groupByProp}RowSpan`]: table7.count };
                                                                    const table7RowSpanZero = { ...table6RowSpanZero, [`${table7.groupByProp}RowSpan`]: table7.groupByProp && table7.count > 1 ? 0 : 1 };

                                                                    if (table7.groupByProp) {

                                                                        !selectedHeaders.includes(table7.groupByProp) && selectedHeaders.push(table7.groupByProp);
                                                                        table7.values.forEach((table8, table8Index) => {
                                                                            const table8RowSpan = { ...table7RowSpan, [`${table8.groupByProp}RowSpan`]: table8.count };
                                                                            const table8RowSpanZero = { ...table7RowSpanZero, [`${table8.groupByProp}RowSpan`]: table8.groupByProp && table8.count > 1 ? 0 : 1 };

                                                                            if (table8.groupByProp) {

                                                                                !selectedHeaders.includes(table8.groupByProp) && selectedHeaders.push(table8.groupByProp);
                                                                                table8.values.forEach((table9, table9Index) => {
                                                                                    const table9RowSpan = { ...table8RowSpan, [`${table9.groupByProp}RowSpan`]: table9.count };
                                                                                    const table9RowSpanZero = { ...table8RowSpanZero, [`${table9.groupByProp}RowSpan`]: table9.groupByProp && table9.count > 1 ? 0 : 1 };

                                                                                    if (table9.groupByProp) {

                                                                                        !selectedHeaders.includes(table9.groupByProp) && selectedHeaders.push(table9.groupByProp);
                                                                                        table9.values.forEach((table10, table10Index) => {
                                                                                            const table10RowSpan = { ...table9RowSpan, [`${table10.groupByProp}RowSpan`]: table10.count };
                                                                                            const table10RowSpanZero = { ...table9RowSpanZero, [`${table10.groupByProp}RowSpan`]: table10.groupByProp && table10.count > 1 ? 0 : 1 };

                                                                                            if (table10.groupByProp) {


                                                                                                !selectedHeaders.includes(table10.groupByProp) && selectedHeaders.push(table10.groupByProp);
                                                                                                table10.values.forEach((table11, table11Index) => {
                                                                                                    const table11RowSpan = { ...table10RowSpan, [`${table11.groupByProp}RowSpan`]: table11.count };
                                                                                                    const table11RowSpanZero = { ...table10RowSpanZero, [`${table11.groupByProp}RowSpan`]: table11.groupByProp && table11.count > 1 ? 0 : 1 };

                                                                                                    if (table11.groupByProp) {



                                                                                                        !selectedHeaders.includes(table11.groupByProp) && selectedHeaders.push(table11.groupByProp);
                                                                                                        table11.values.forEach((table12, table12Index) => {
                                                                                                            const table12RowSpan = { ...table11RowSpan, [`${table12.groupByProp}RowSpan`]: table12.count };
                                                                                                            const table12RowSpanZero = { ...table11RowSpanZero, [`${table12.groupByProp}RowSpan`]: table12.groupByProp && table12.count > 1 ? 0 : 1 };

                                                                                                            if (table12.groupByProp) {
                                                                                                                !selectedHeaders.includes(table12.groupByProp) && selectedHeaders.push(table12.groupByProp);
                                                                                                                table12.values.forEach((table13, table13Index) => {
                                                                                                                    const table13RowSpan = { ...table12RowSpan, [`${table13.groupByProp}RowSpan`]: table13.count };
                                                                                                                    const table13RowSpanZero = { ...table12RowSpanZero, [`${table13.groupByProp}RowSpan`]: table13.groupByProp && table13.count > 1 ? 0 : 1 };

                                                                                                                    if (table13.groupByProp) {

                                                                                                                        !selectedHeaders.includes(table13.groupByProp) && selectedHeaders.push(table13.groupByProp);
                                                                                                                        table13.values.forEach((table14, table14Index) => {
                                                                                                                            const table14RowSpan = { ...table13RowSpan, [`${table14.groupByProp}RowSpan`]: table14.count };
                                                                                                                            const table14RowSpanZero = { ...table13RowSpanZero, [`${table14.groupByProp}RowSpan`]: table14.groupByProp && table14.count > 1 ? 0 : 1 };

                                                                                                                            if (table14.groupByProp) {
                                                                                                                                !selectedHeaders.includes(table14.groupByProp) && selectedHeaders.push(table14.groupByProp);
                                                                                                                                table14.values.forEach((table15, table15Index) => {
                                                                                                                                    const table15RowSpan = { ...table14RowSpan, [`${table15.groupByProp}RowSpan`]: table15.count };
                                                                                                                                    const table15RowSpanZero = { ...table14RowSpanZero, [`${table15.groupByProp}RowSpan`]: table15.groupByProp && table15.count > 1 ? 0 : 1 };

                                                                                                                                    if (table15.groupByProp) {
                                                                                                                                        !selectedHeaders.includes(table15.groupByProp) && selectedHeaders.push(table15.groupByProp);
                                                                                                                                        table15.values.forEach((table16, table16Index) => {
                                                                                                                                            const table16RowSpan = { ...table15RowSpan, [`${table16.groupByProp}RowSpan`]: table16.count };
                                                                                                                                            const table16RowSpanZero = { ...table15RowSpanZero, [`${table16.groupByProp}RowSpan`]: table16.groupByProp && table16.count > 1 ? 0 : 1 };
                                                                                                                                            if (table16.groupByProp) {
                                                                                                                                                !selectedHeaders.includes(table16.groupByProp) && selectedHeaders.push(table16.groupByProp);
                                                                                                                                                table16.values.map((table17, table17Index) => {
                                                                                                                                                    const table17RowSpan = { ...table16RowSpan, [`${table17.groupByProp}RowSpan`]: table17.count };
                                                                                                                                                    const table17RowSpanZero = { ...table16RowSpanZero, [`${table17.groupByProp}RowSpan`]: table17.groupByProp && table17.count > 1 ? 0 : 1 };
                                                                                                                                                    if (table17.groupByProp) {
                                                                                                                                                        !selectedHeaders.includes(table17.groupByProp) && selectedHeaders.push(table17.groupByProp);
                                                                                                                                                        table17.values.forEach((table18, table18Index) => {
                                                                                                                                                            if (table18.groupByProp) {

                                                                                                                                                            } else {
                                                                                                                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table18, ...table17RowSpan });
                                                                                                                                                                } else {
                                                                                                                                                                    let obj = { ...table17RowSpanZero };
                                                                                                                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                                                    } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                                    } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                                    } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                                    } else if (table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                    } else if (table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                    } else if (table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                    } else if (table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                    } else if (table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                    } else if (table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                    } else if (table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                    } else if (table15Index === 0 && table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                    } else if (table16Index === 0 && table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                    } else if (table17Index === 0 && table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                        obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                    } else if (table18Index === 0) {
                                                                                                                                                                        obj[`${table17.groupByProp}RowSpan`] = table17.count;
                                                                                                                                                                    }
                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table18, ...obj });

                                                                                                                                                                }
                                                                                                                                                                // total values logic
                                                                                                                                                                let arr = {};
                                                                                                                                                                if (table17.values.length - 1 === table18Index) {
                                                                                                                                                                    arr[`${table17.groupByProp}RowSpan`] = 1;
                                                                                                                                                                    updateTableRowSpan(childArray, table18, table17, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table17, ...arr, [table17.groupByProp]: getSubTotalLabel(table17[table17.groupByProp], table17.groupByProp), isSubTotal: true });

                                                                                                                                                                    if (table16.values.length - 1 === table17Index) {
                                                                                                                                                                        arr[`${table16.groupByProp}RowSpan`] = 1;
                                                                                                                                                                        updateTableRowSpan(childArray, table17, table16, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table16, ...arr, [table16.groupByProp]: getSubTotalLabel(table16[table16.groupByProp], table16.groupByProp), isSubTotal: true });

                                                                                                                                                                        if (table15.values.length - 1 === table16Index) {
                                                                                                                                                                            arr[`${table15.groupByProp}RowSpan`] = 1;
                                                                                                                                                                            updateTableRowSpan(childArray, table16, table15, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table15, ...arr, [table15.groupByProp]: getSubTotalLabel(table15[table15.groupByProp], table15.groupByProp), isSubTotal: true });

                                                                                                                                                                            if (table14.values.length - 1 === table15Index) {
                                                                                                                                                                                arr[`${table14.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                updateTableRowSpan(childArray, table15, table14, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table14, ...arr, [table14.groupByProp]: getSubTotalLabel(table14[table14.groupByProp], table14.groupByProp), isSubTotal: true });

                                                                                                                                                                                if (table13.values.length - 1 === table14Index) {
                                                                                                                                                                                    arr[`${table13.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                    updateTableRowSpan(childArray, table14, table13, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table13, ...arr, [table13.groupByProp]: getSubTotalLabel(table13[table13.groupByProp], table13.groupByProp), isSubTotal: true });
                                                                                                                                                                                    if (table12.values.length - 1 === table13Index) {
                                                                                                                                                                                        arr[`${table12.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                        updateTableRowSpan(childArray, table13, table12, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table12, ...arr, [table12.groupByProp]: getSubTotalLabel(table12[table12.groupByProp], table12.groupByProp), isSubTotal: true });
                                                                                                                                                                                        if (table11.values.length - 1 === table12Index) {
                                                                                                                                                                                            arr[`${table11.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                            updateTableRowSpan(childArray, table12, table11, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table11, ...arr, [table11.groupByProp]: getSubTotalLabel(table11[table11.groupByProp], table11.groupByProp), isSubTotal: true });
                                                                                                                                                                                            if (table10.values.length - 1 === table11Index) {
                                                                                                                                                                                                arr[`${table10.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                updateTableRowSpan(childArray, table11, table10, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table10, ...arr, [table10.groupByProp]: getSubTotalLabel(table10[table10.groupByProp], table10.groupByProp), isSubTotal: true });
                                                                                                                                                                                                if (table9.values.length - 1 === table10Index) {
                                                                                                                                                                                                    arr[`${table9.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                    updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table9, ...arr, [table9.groupByProp]: getSubTotalLabel(table9[table9.groupByProp], table9.groupByProp), isSubTotal: true });
                                                                                                                                                                                                    if (table8.values.length - 1 === table9Index) {
                                                                                                                                                                                                        arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                        updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                                                                                                                                        if (table7.values.length - 1 === table8Index) {
                                                                                                                                                                                                            arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                            updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                                                                                                                            if (table6.values.length - 1 === table7Index) {
                                                                                                                                                                                                                arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                                updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                                                                                                                                if (table5.values.length - 1 === table6Index) {
                                                                                                                                                                                                                    arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                                    updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                                                                                                                                    if (table4.values.length - 1 === table5Index) {
                                                                                                                                                                                                                        arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                                        updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                                                                                                                                        if (table3.values.length - 1 === table4Index) {
                                                                                                                                                                                                                            arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                                            updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                                                                                                                            if (table2.values.length - 1 === table3Index) {
                                                                                                                                                                                                                                arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                                                updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                                                                                                                                if (table.values.length - 1 === table2Index) {
                                                                                                                                                                                                                                    arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                                                    updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }

                                                                                                                                                                                                                    }
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        })

                                                                                                                                                    } else {
                                                                                                                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table17, ...table16RowSpan });
                                                                                                                                                        } else {
                                                                                                                                                            let obj = { ...table16RowSpanZero };
                                                                                                                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                                                                            } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                                            } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                            } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                            } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                            } else if (table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                            } else if (table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                            } else if (table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                            } else if (table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                            } else if (table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                            } else if (table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                            } else if (table14Index === 0 && table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                            } else if (table15Index === 0 && table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                            } else if (table16Index === 0 && table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                                obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                            } else if (table17Index === 0) {
                                                                                                                                                                obj[`${table16.groupByProp}RowSpan`] = table16.count;
                                                                                                                                                            }
                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table17, ...obj });

                                                                                                                                                        }
                                                                                                                                                        // total values logic
                                                                                                                                                        let arr = {};
                                                                                                                                                        if (table16.values.length - 1 === table17Index) {
                                                                                                                                                            arr[`${table16.groupByProp}RowSpan`] = 1;
                                                                                                                                                            updateTableRowSpan(childArray, table17, table16, row.burstReportHeaders, parentArray.length);
                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table16, ...arr, [table16.groupByProp]: getSubTotalLabel(table16[table16.groupByProp], table16.groupByProp), isSubTotal: true });

                                                                                                                                                            if (table15.values.length - 1 === table16Index) {
                                                                                                                                                                arr[`${table15.groupByProp}RowSpan`] = 1;
                                                                                                                                                                updateTableRowSpan(childArray, table16, table15, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table15, ...arr, [table15.groupByProp]: getSubTotalLabel(table15[table15.groupByProp], table15.groupByProp), isSubTotal: true });

                                                                                                                                                                if (table14.values.length - 1 === table15Index) {
                                                                                                                                                                    arr[`${table14.groupByProp}RowSpan`] = 1;
                                                                                                                                                                    updateTableRowSpan(childArray, table15, table14, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table14, ...arr, [table14.groupByProp]: getSubTotalLabel(table14[table14.groupByProp], table14.groupByProp), isSubTotal: true });

                                                                                                                                                                    if (table13.values.length - 1 === table14Index) {
                                                                                                                                                                        arr[`${table13.groupByProp}RowSpan`] = 1;
                                                                                                                                                                        updateTableRowSpan(childArray, table14, table13, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table13, ...arr, [table13.groupByProp]: getSubTotalLabel(table13[table13.groupByProp], table13.groupByProp), isSubTotal: true });
                                                                                                                                                                        if (table12.values.length - 1 === table13Index) {
                                                                                                                                                                            arr[`${table12.groupByProp}RowSpan`] = 1;
                                                                                                                                                                            updateTableRowSpan(childArray, table13, table12, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table12, ...arr, [table12.groupByProp]: getSubTotalLabel(table12[table12.groupByProp], table12.groupByProp), isSubTotal: true });
                                                                                                                                                                            if (table11.values.length - 1 === table12Index) {
                                                                                                                                                                                arr[`${table11.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                updateTableRowSpan(childArray, table12, table11, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table11, ...arr, [table11.groupByProp]: getSubTotalLabel(table11[table11.groupByProp], table11.groupByProp), isSubTotal: true });
                                                                                                                                                                                if (table10.values.length - 1 === table11Index) {
                                                                                                                                                                                    arr[`${table10.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                    updateTableRowSpan(childArray, table11, table10, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table10, ...arr, [table10.groupByProp]: getSubTotalLabel(table10[table10.groupByProp], table10.groupByProp), isSubTotal: true });
                                                                                                                                                                                    if (table9.values.length - 1 === table10Index) {
                                                                                                                                                                                        arr[`${table9.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                        updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table9, ...arr, [table9.groupByProp]: getSubTotalLabel(table9[table9.groupByProp], table9.groupByProp), isSubTotal: true });
                                                                                                                                                                                        if (table8.values.length - 1 === table9Index) {
                                                                                                                                                                                            arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                            updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                                                                                                                            if (table7.values.length - 1 === table8Index) {
                                                                                                                                                                                                arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                                                                                                                if (table6.values.length - 1 === table7Index) {
                                                                                                                                                                                                    arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                    updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                                                                                                                    if (table5.values.length - 1 === table6Index) {
                                                                                                                                                                                                        arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                        updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                                                                                                                        if (table4.values.length - 1 === table5Index) {
                                                                                                                                                                                                            arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                            updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                                                                                                                            if (table3.values.length - 1 === table4Index) {
                                                                                                                                                                                                                arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                                updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                                                                                                                if (table2.values.length - 1 === table3Index) {
                                                                                                                                                                                                                    arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                                    updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                                                                                                                    if (table.values.length - 1 === table2Index) {
                                                                                                                                                                                                                        arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                                        updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                                                                                                                    }
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }

                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            } else {
                                                                                                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table16, ...table15RowSpan });
                                                                                                                                                } else {
                                                                                                                                                    let obj = { ...table15RowSpanZero };
                                                                                                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                                    } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                    } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                    } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                    } else if (table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                    } else if (table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                    } else if (table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                    } else if (table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                    } else if (table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                    } else if (table13Index === 0 && table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                    } else if (table14Index === 0 && table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                    } else if (table15Index === 0 && table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                        obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                    } else if (table16Index === 0) {
                                                                                                                                                        obj[`${table15.groupByProp}RowSpan`] = table15.count;
                                                                                                                                                    }
                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table16, ...obj });

                                                                                                                                                }
                                                                                                                                                // total values logic
                                                                                                                                                let arr = {};
                                                                                                                                                if (table15.values.length - 1 === table16Index) {
                                                                                                                                                    arr[`${table15.groupByProp}RowSpan`] = 1;
                                                                                                                                                    updateTableRowSpan(childArray, table16, table15, row.burstReportHeaders, parentArray.length);
                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table15, ...arr, [table15.groupByProp]: getSubTotalLabel(table15[table15.groupByProp], table15.groupByProp), isSubTotal: true });

                                                                                                                                                    if (table14.values.length - 1 === table15Index) {
                                                                                                                                                        arr[`${table14.groupByProp}RowSpan`] = 1;
                                                                                                                                                        updateTableRowSpan(childArray, table15, table14, row.burstReportHeaders, parentArray.length);
                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table14, ...arr, [table14.groupByProp]: getSubTotalLabel(table14[table14.groupByProp], table14.groupByProp), isSubTotal: true });

                                                                                                                                                        if (table13.values.length - 1 === table14Index) {
                                                                                                                                                            arr[`${table13.groupByProp}RowSpan`] = 1;
                                                                                                                                                            updateTableRowSpan(childArray, table14, table13, row.burstReportHeaders, parentArray.length);
                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table13, ...arr, [table13.groupByProp]: getSubTotalLabel(table13[table13.groupByProp], table13.groupByProp), isSubTotal: true });
                                                                                                                                                            if (table12.values.length - 1 === table13Index) {
                                                                                                                                                                arr[`${table12.groupByProp}RowSpan`] = 1;
                                                                                                                                                                updateTableRowSpan(childArray, table13, table12, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table12, ...arr, [table12.groupByProp]: getSubTotalLabel(table12[table12.groupByProp], table12.groupByProp), isSubTotal: true });
                                                                                                                                                                if (table11.values.length - 1 === table12Index) {
                                                                                                                                                                    arr[`${table11.groupByProp}RowSpan`] = 1;
                                                                                                                                                                    updateTableRowSpan(childArray, table12, table11, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table11, ...arr, [table11.groupByProp]: getSubTotalLabel(table11[table11.groupByProp], table11.groupByProp), isSubTotal: true });
                                                                                                                                                                    if (table10.values.length - 1 === table11Index) {
                                                                                                                                                                        arr[`${table10.groupByProp}RowSpan`] = 1;
                                                                                                                                                                        updateTableRowSpan(childArray, table11, table10, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table10, ...arr, [table10.groupByProp]: getSubTotalLabel(table10[table10.groupByProp], table10.groupByProp), isSubTotal: true });
                                                                                                                                                                        if (table9.values.length - 1 === table10Index) {
                                                                                                                                                                            arr[`${table9.groupByProp}RowSpan`] = 1;
                                                                                                                                                                            updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table9, ...arr, [table9.groupByProp]: getSubTotalLabel(table9[table9.groupByProp], table9.groupByProp), isSubTotal: true });
                                                                                                                                                                            if (table8.values.length - 1 === table9Index) {
                                                                                                                                                                                arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                                                                                                                if (table7.values.length - 1 === table8Index) {
                                                                                                                                                                                    arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                    updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                                                                                                    if (table6.values.length - 1 === table7Index) {
                                                                                                                                                                                        arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                        updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                                                                                                        if (table5.values.length - 1 === table6Index) {
                                                                                                                                                                                            arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                            updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                                                                                                            if (table4.values.length - 1 === table5Index) {
                                                                                                                                                                                                arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                                                                                                                if (table3.values.length - 1 === table4Index) {
                                                                                                                                                                                                    arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                    updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                                                                                                    if (table2.values.length - 1 === table3Index) {
                                                                                                                                                                                                        arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                        updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                                                                                                        if (table.values.length - 1 === table2Index) {
                                                                                                                                                                                                            arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                            updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }

                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        })

                                                                                                                                    } else {

                                                                                                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                            childArray.push({ uuid: Math.random(), ...table15, ...table14RowSpan });
                                                                                                                                        } else {
                                                                                                                                            let obj = { ...table14RowSpanZero };
                                                                                                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                                                            } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                            } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                            } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                            } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                            } else if (table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                            } else if (table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                            } else if (table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                            } else if (table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                            } else if (table12Index === 0 && table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                            } else if (table13Index === 0 && table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                            } else if (table14Index === 0 && table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                                obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                            } else if (table15Index === 0) {
                                                                                                                                                obj[`${table14.groupByProp}RowSpan`] = table14.count;
                                                                                                                                            }
                                                                                                                                            childArray.push({ uuid: Math.random(), ...table15, ...obj });

                                                                                                                                        }
                                                                                                                                        // total values logic
                                                                                                                                        let arr = {};
                                                                                                                                        if (table14.values.length - 1 === table15Index) {
                                                                                                                                            arr[`${table14.groupByProp}RowSpan`] = 1;
                                                                                                                                            updateTableRowSpan(childArray, table15, table14, row.burstReportHeaders, parentArray.length);
                                                                                                                                            childArray.push({ uuid: Math.random(), ...table14, ...arr, [table14.groupByProp]: getSubTotalLabel(table14[table14.groupByProp], table14.groupByProp), isSubTotal: true });

                                                                                                                                            if (table13.values.length - 1 === table14Index) {
                                                                                                                                                arr[`${table13.groupByProp}RowSpan`] = 1;
                                                                                                                                                updateTableRowSpan(childArray, table14, table13, row.burstReportHeaders, parentArray.length);
                                                                                                                                                childArray.push({ uuid: Math.random(), ...table13, ...arr, [table13.groupByProp]: getSubTotalLabel(table13[table13.groupByProp], table13.groupByProp), isSubTotal: true });
                                                                                                                                                if (table12.values.length - 1 === table13Index) {
                                                                                                                                                    arr[`${table12.groupByProp}RowSpan`] = 1;
                                                                                                                                                    updateTableRowSpan(childArray, table13, table12, row.burstReportHeaders, parentArray.length);
                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table12, ...arr, [table12.groupByProp]: getSubTotalLabel(table12[table12.groupByProp], table12.groupByProp), isSubTotal: true });
                                                                                                                                                    if (table11.values.length - 1 === table12Index) {
                                                                                                                                                        arr[`${table11.groupByProp}RowSpan`] = 1;
                                                                                                                                                        updateTableRowSpan(childArray, table12, table11, row.burstReportHeaders, parentArray.length);
                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table11, ...arr, [table11.groupByProp]: getSubTotalLabel(table11[table11.groupByProp], table11.groupByProp), isSubTotal: true });
                                                                                                                                                        if (table10.values.length - 1 === table11Index) {
                                                                                                                                                            arr[`${table10.groupByProp}RowSpan`] = 1;
                                                                                                                                                            updateTableRowSpan(childArray, table11, table10, row.burstReportHeaders, parentArray.length);
                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table10, ...arr, [table10.groupByProp]: getSubTotalLabel(table10[table10.groupByProp], table10.groupByProp), isSubTotal: true });
                                                                                                                                                            if (table9.values.length - 1 === table10Index) {
                                                                                                                                                                arr[`${table9.groupByProp}RowSpan`] = 1;
                                                                                                                                                                updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table9, ...arr, [table9.groupByProp]: getSubTotalLabel(table9[table9.groupByProp], table9.groupByProp), isSubTotal: true });
                                                                                                                                                                if (table8.values.length - 1 === table9Index) {
                                                                                                                                                                    arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                                                                                                    updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                                                                                                    if (table7.values.length - 1 === table8Index) {
                                                                                                                                                                        arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                                                                                        updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                                                                                        if (table6.values.length - 1 === table7Index) {
                                                                                                                                                                            arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                                                                                            updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                                                                                            if (table5.values.length - 1 === table6Index) {
                                                                                                                                                                                arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                                                                                                if (table4.values.length - 1 === table5Index) {
                                                                                                                                                                                    arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                    updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                                                                                                    if (table3.values.length - 1 === table4Index) {
                                                                                                                                                                                        arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                        updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                                                                                        if (table2.values.length - 1 === table3Index) {
                                                                                                                                                                                            arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                            updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                                                                                            if (table.values.length - 1 === table2Index) {
                                                                                                                                                                                                arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                                updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }

                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }

                                                                                                                                    }
                                                                                                                                });

                                                                                                                            } else {

                                                                                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                    childArray.push({ uuid: Math.random(), ...table14, ...table13RowSpan });
                                                                                                                                } else {
                                                                                                                                    let obj = { ...table13RowSpanZero };
                                                                                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                    } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                    } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                    } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                    } else if (table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                    } else if (table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                    } else if (table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                    } else if (table11Index === 0 && table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                    } else if (table12Index === 0 && table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                    } else if (table13Index === 0 && table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                        obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                    } else if (table14Index === 0) {
                                                                                                                                        obj[`${table13.groupByProp}RowSpan`] = table13.count;
                                                                                                                                    }
                                                                                                                                    childArray.push({ uuid: Math.random(), ...table14, ...obj });

                                                                                                                                }
                                                                                                                                // total values logic
                                                                                                                                let arr = {};

                                                                                                                                if (table13.values.length - 1 === table14Index) {
                                                                                                                                    arr[`${table13.groupByProp}RowSpan`] = 1;
                                                                                                                                    updateTableRowSpan(childArray, table14, table13, row.burstReportHeaders, parentArray.length);
                                                                                                                                    childArray.push({ uuid: Math.random(), ...table13, ...arr, [table13.groupByProp]: getSubTotalLabel(table13[table13.groupByProp], table13.groupByProp), isSubTotal: true });
                                                                                                                                    if (table12.values.length - 1 === table13Index) {
                                                                                                                                        arr[`${table12.groupByProp}RowSpan`] = 1;
                                                                                                                                        updateTableRowSpan(childArray, table13, table12, row.burstReportHeaders, parentArray.length);
                                                                                                                                        childArray.push({ uuid: Math.random(), ...table12, ...arr, [table12.groupByProp]: getSubTotalLabel(table12[table12.groupByProp], table12.groupByProp), isSubTotal: true });
                                                                                                                                        if (table11.values.length - 1 === table12Index) {
                                                                                                                                            arr[`${table11.groupByProp}RowSpan`] = 1;
                                                                                                                                            updateTableRowSpan(childArray, table12, table11, row.burstReportHeaders, parentArray.length);
                                                                                                                                            childArray.push({ uuid: Math.random(), ...table11, ...arr, [table11.groupByProp]: getSubTotalLabel(table11[table11.groupByProp], table11.groupByProp), isSubTotal: true });
                                                                                                                                            if (table10.values.length - 1 === table11Index) {
                                                                                                                                                arr[`${table10.groupByProp}RowSpan`] = 1;
                                                                                                                                                updateTableRowSpan(childArray, table11, table10, row.burstReportHeaders, parentArray.length);
                                                                                                                                                childArray.push({ uuid: Math.random(), ...table10, ...arr, [table10.groupByProp]: getSubTotalLabel(table10[table10.groupByProp], table10.groupByProp), isSubTotal: true });
                                                                                                                                                if (table9.values.length - 1 === table10Index) {
                                                                                                                                                    arr[`${table9.groupByProp}RowSpan`] = 1;
                                                                                                                                                    updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table9, ...arr, [table9.groupByProp]: getSubTotalLabel(table9[table9.groupByProp], table9.groupByProp), isSubTotal: true });
                                                                                                                                                    if (table8.values.length - 1 === table9Index) {
                                                                                                                                                        arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                                                                                        updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                                                                                        if (table7.values.length - 1 === table8Index) {
                                                                                                                                                            arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                                                                            updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                                                                            if (table6.values.length - 1 === table7Index) {
                                                                                                                                                                arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                                                                                updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                                                                                if (table5.values.length - 1 === table6Index) {
                                                                                                                                                                    arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                                                                                    updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                                                                                    if (table4.values.length - 1 === table5Index) {
                                                                                                                                                                        arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                                                                                        updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                                                                                        if (table3.values.length - 1 === table4Index) {
                                                                                                                                                                            arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                                                                            updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                                                                            if (table2.values.length - 1 === table3Index) {
                                                                                                                                                                                arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                                                                                if (table.values.length - 1 === table2Index) {
                                                                                                                                                                                    arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                                                                                    updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }

                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }


                                                                                                                            }
                                                                                                                        });
                                                                                                                    } else {

                                                                                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                            childArray.push({ uuid: Math.random(), ...table13, ...table12RowSpan });
                                                                                                                        } else {
                                                                                                                            let obj = { ...table12RowSpanZero };
                                                                                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                                            } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                            } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                            } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                            } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                            } else if (table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                            } else if (table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                            } else if (table10Index === 0 && table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                            } else if (table11Index === 0 && table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                            } else if (table12Index === 0 && table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                                obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                            } else if (table13Index === 0) {
                                                                                                                                obj[`${table12.groupByProp}RowSpan`] = table12.count;
                                                                                                                            }
                                                                                                                            childArray.push({ uuid: Math.random(), ...table13, ...obj });

                                                                                                                        }
                                                                                                                        // total values logic
                                                                                                                        let arr = {};
                                                                                                                        if (table12.values.length - 1 === table13Index) {
                                                                                                                            arr[`${table12.groupByProp}RowSpan`] = 1;
                                                                                                                            updateTableRowSpan(childArray, table13, table12, row.burstReportHeaders, parentArray.length);
                                                                                                                            childArray.push({ uuid: Math.random(), ...table12, ...arr, [table12.groupByProp]: getSubTotalLabel(table12[table12.groupByProp], table12.groupByProp), isSubTotal: true });
                                                                                                                            if (table11.values.length - 1 === table12Index) {
                                                                                                                                arr[`${table11.groupByProp}RowSpan`] = 1;
                                                                                                                                updateTableRowSpan(childArray, table12, table11, row.burstReportHeaders, parentArray.length);
                                                                                                                                childArray.push({ uuid: Math.random(), ...table11, ...arr, [table11.groupByProp]: getSubTotalLabel(table11[table11.groupByProp], table11.groupByProp), isSubTotal: true });
                                                                                                                                if (table10.values.length - 1 === table11Index) {
                                                                                                                                    arr[`${table10.groupByProp}RowSpan`] = 1;
                                                                                                                                    updateTableRowSpan(childArray, table11, table10, row.burstReportHeaders, parentArray.length);
                                                                                                                                    childArray.push({ uuid: Math.random(), ...table10, ...arr, [table10.groupByProp]: getSubTotalLabel(table10[table10.groupByProp], table10.groupByProp), isSubTotal: true });
                                                                                                                                    if (table9.values.length - 1 === table10Index) {
                                                                                                                                        arr[`${table9.groupByProp}RowSpan`] = 1;
                                                                                                                                        updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                                                        childArray.push({ uuid: Math.random(), ...table9, ...arr, [table9.groupByProp]: getSubTotalLabel(table9[table9.groupByProp], table9.groupByProp), isSubTotal: true });
                                                                                                                                        if (table8.values.length - 1 === table9Index) {
                                                                                                                                            arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                                                                            updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                                                            childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                                                                            if (table7.values.length - 1 === table8Index) {
                                                                                                                                                arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                                                                updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                                                                childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                                                                if (table6.values.length - 1 === table7Index) {
                                                                                                                                                    arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                                                                    updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                                                                    if (table5.values.length - 1 === table6Index) {
                                                                                                                                                        arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                                                                        updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                                                                        if (table4.values.length - 1 === table5Index) {
                                                                                                                                                            arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                                                                            updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                                                                            if (table3.values.length - 1 === table4Index) {
                                                                                                                                                                arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                                                                updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                                                                if (table2.values.length - 1 === table3Index) {
                                                                                                                                                                    arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                                                                    updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                                                                    if (table.values.length - 1 === table2Index) {
                                                                                                                                                                        arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                                                                        updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }

                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }

                                                                                                                    }
                                                                                                                });
                                                                                                            } else {

                                                                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0) {
                                                                                                                    childArray.push({ uuid: Math.random(), ...table12, ...table11RowSpan });
                                                                                                                } else {
                                                                                                                    let obj = { ...table11RowSpanZero };
                                                                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                    } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                    } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                    } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                    } else if (table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                    } else if (table9Index === 0 && table10Index === 0 && table11Index === 0 && table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                    } else if (table10Index === 0 && table11Index === 0 && table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                    } else if (table11Index === 0 && table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                        obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                    } else if (table12Index === 0) {
                                                                                                                        obj[`${table11.groupByProp}RowSpan`] = table11.count;
                                                                                                                    }
                                                                                                                    childArray.push({ uuid: Math.random(), ...table12, ...obj });

                                                                                                                }
                                                                                                                // total values logic
                                                                                                                let arr = {};
                                                                                                                if (table11.values.length - 1 === table12Index) {
                                                                                                                    arr[`${table11.groupByProp}RowSpan`] = 1;
                                                                                                                    updateTableRowSpan(childArray, table12, table11, row.burstReportHeaders, parentArray.length);
                                                                                                                    childArray.push({ uuid: Math.random(), ...table11, ...arr, [table11.groupByProp]: getSubTotalLabel(table11[table11.groupByProp], table11.groupByProp), isSubTotal: true });
                                                                                                                    if (table10.values.length - 1 === table11Index) {
                                                                                                                        arr[`${table10.groupByProp}RowSpan`] = 1;
                                                                                                                        updateTableRowSpan(childArray, table11, table10, row.burstReportHeaders, parentArray.length);
                                                                                                                        childArray.push({ uuid: Math.random(), ...table10, ...arr, [table10.groupByProp]: getSubTotalLabel(table10[table10.groupByProp], table10.groupByProp), isSubTotal: true });
                                                                                                                        if (table9.values.length - 1 === table10Index) {
                                                                                                                            arr[`${table9.groupByProp}RowSpan`] = 1;
                                                                                                                            updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                                            childArray.push({ uuid: Math.random(), ...table9, ...arr, [table9.groupByProp]: getSubTotalLabel(table9[table9.groupByProp], table9.groupByProp), isSubTotal: true });
                                                                                                                            if (table8.values.length - 1 === table9Index) {
                                                                                                                                arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                                                                updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                                                childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                                                                if (table7.values.length - 1 === table8Index) {
                                                                                                                                    arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                                                    updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                                                    childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                                                    if (table6.values.length - 1 === table7Index) {
                                                                                                                                        arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                                                        updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                                        childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                                                        if (table5.values.length - 1 === table6Index) {
                                                                                                                                            arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                                                            updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                                            childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                                                            if (table4.values.length - 1 === table5Index) {
                                                                                                                                                arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                                                                updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                                                childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                                                                if (table3.values.length - 1 === table4Index) {
                                                                                                                                                    arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                                                    updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                                                    childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                                                    if (table2.values.length - 1 === table3Index) {
                                                                                                                                                        arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                                                        updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                                        childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                                                        if (table.values.length - 1 === table2Index) {
                                                                                                                                                            arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                                                            updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                                            childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }

                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }


                                                                                                            }
                                                                                                        });
                                                                                                    } else {

                                                                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0) {
                                                                                                            childArray.push({ uuid: Math.random(), ...table11, ...table10RowSpan });
                                                                                                        } else {
                                                                                                            let obj = { ...table10RowSpanZero };
                                                                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0) {
                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                            } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0) {
                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                            } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0) {
                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                            } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0) {
                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                            } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0) {
                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                            } else if (table8Index === 0 && table9Index === 0 && table10Index === 0 && table11Index === 0) {
                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                            } else if (table9Index === 0 && table10Index === 0 && table11Index === 0) {
                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                            } else if (table10Index === 0 && table11Index === 0) {
                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                                obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                            } else if (table11Index === 0) {
                                                                                                                obj[`${table10.groupByProp}RowSpan`] = table10.count;
                                                                                                            }
                                                                                                            childArray.push({ uuid: Math.random(), ...table11, ...obj });

                                                                                                        }
                                                                                                        // total values logic
                                                                                                        let arr = {};
                                                                                                        if (table10.values.length - 1 === table11Index) {
                                                                                                            arr[`${table10.groupByProp}RowSpan`] = 1;
                                                                                                            updateTableRowSpan(childArray, table11, table10, row.burstReportHeaders, parentArray.length);
                                                                                                            childArray.push({ uuid: Math.random(), ...table10, ...arr, [table10.groupByProp]: getSubTotalLabel(table10[table10.groupByProp], table10.groupByProp), isSubTotal: true });
                                                                                                            if (table9.values.length - 1 === table10Index) {
                                                                                                                arr[`${table9.groupByProp}RowSpan`] = 1;
                                                                                                                updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                                childArray.push({ uuid: Math.random(), ...table9, ...arr, [table9.groupByProp]: getSubTotalLabel(table9[table9.groupByProp], table9.groupByProp), isSubTotal: true });
                                                                                                                if (table8.values.length - 1 === table9Index) {
                                                                                                                    arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                                                    updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                                    childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                                                    if (table7.values.length - 1 === table8Index) {
                                                                                                                        arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                                        updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                                        childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                                        if (table6.values.length - 1 === table7Index) {
                                                                                                                            arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                                            updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                            childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                                            if (table5.values.length - 1 === table6Index) {
                                                                                                                                arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                                                updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                                childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                                                if (table4.values.length - 1 === table5Index) {
                                                                                                                                    arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                                                    updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                                    childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                                                    if (table3.values.length - 1 === table4Index) {
                                                                                                                                        arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                                        updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                                        childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                                        if (table2.values.length - 1 === table3Index) {
                                                                                                                                            arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                                            updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                            childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                                            if (table.values.length - 1 === table2Index) {
                                                                                                                                                arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                                                updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                                childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }

                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                });
                                                                                            } else {

                                                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                    childArray.push({ uuid: Math.random(), ...table10, ...table9RowSpan });
                                                                                                } else {
                                                                                                    let obj = { ...table9RowSpanZero };
                                                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                    } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                    } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                    } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                    } else if (table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                    } else if (table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                    } else if (table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                    }
                                                                                                    childArray.push({ uuid: Math.random(), ...table10, ...obj });

                                                                                                }
                                                                                                // total values logic
                                                                                                let arr = {};
                                                                                                if (table9.values.length - 1 === table10Index) {
                                                                                                    arr[`${table9.groupByProp}RowSpan`] = 1;
                                                                                                    updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                    childArray.push({ uuid: Math.random(), ...table9, ...arr, [table9.groupByProp]: getSubTotalLabel(table9[table9.groupByProp], table9.groupByProp), isSubTotal: true });
                                                                                                    if (table8.values.length - 1 === table9Index) {
                                                                                                        arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                                        updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                        childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                                        if (table7.values.length - 1 === table8Index) {
                                                                                                            arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                            updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                            childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                            if (table6.values.length - 1 === table7Index) {
                                                                                                                arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                                updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                                if (table5.values.length - 1 === table6Index) {
                                                                                                                    arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                                    updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                    childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                                    if (table4.values.length - 1 === table5Index) {
                                                                                                                        arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                                        updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                        childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                                        if (table3.values.length - 1 === table4Index) {
                                                                                                                            arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                            updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                            childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                            if (table2.values.length - 1 === table3Index) {
                                                                                                                                arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                                updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                                if (table.values.length - 1 === table2Index) {
                                                                                                                                    arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                                    updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                    childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                                }
                                                                                                                            }
                                                                                                                        }

                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                            }
                                                                                        });
                                                                                    } else {

                                                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                            childArray.push({ uuid: Math.random(), ...table9, ...table8RowSpan });
                                                                                        } else {
                                                                                            let obj = { ...table8RowSpanZero };
                                                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                            } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                            } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                            } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                            } else if (table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                            } else if (table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                            } else if (table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                            }
                                                                                            childArray.push({ uuid: Math.random(), ...table9, ...obj });

                                                                                        }
                                                                                        // total values logic
                                                                                        let arr = {};
                                                                                        if (table8.values.length - 1 === table9Index) {
                                                                                            arr[`${table8.groupByProp}RowSpan`] = 1;
                                                                                            updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                            childArray.push({ uuid: Math.random(), ...table8, ...arr, [table8.groupByProp]: getSubTotalLabel(table8[table8.groupByProp], table8.groupByProp), isSubTotal: true });

                                                                                            if (table7.values.length - 1 === table8Index) {
                                                                                                arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                                updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                                if (table6.values.length - 1 === table7Index) {
                                                                                                    arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                                    updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                    childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                                    if (table5.values.length - 1 === table6Index) {
                                                                                                        arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                                        updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                        childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                                        if (table4.values.length - 1 === table5Index) {
                                                                                                            arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                            updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                            childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                            if (table3.values.length - 1 === table4Index) {
                                                                                                                arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                                updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                                if (table2.values.length - 1 === table3Index) {
                                                                                                                    arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                                    updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                    childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                                    if (table.values.length - 1 === table2Index) {
                                                                                                                        arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                                        updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                        childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                });
                                                                            } else {

                                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                    childArray.push({ uuid: Math.random(), ...table8, ...table7RowSpan });
                                                                                } else {
                                                                                    let obj = { ...table7RowSpanZero };
                                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                    } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                    } else if (table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                    } else if (table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                    } else if (table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                    }
                                                                                    childArray.push({ uuid: Math.random(), ...table8, ...obj });

                                                                                }
                                                                                // total values logic
                                                                                let arr = {};


                                                                                if (table7.values.length - 1 === table8Index) {
                                                                                    arr[`${table7.groupByProp}RowSpan`] = 1;
                                                                                    updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                    childArray.push({ uuid: Math.random(), ...table7, ...arr, [table7.groupByProp]: getSubTotalLabel(table7[table7.groupByProp], table7.groupByProp), isSubTotal: true });
                                                                                    if (table6.values.length - 1 === table7Index) {
                                                                                        arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                                        updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                        childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                                        if (table5.values.length - 1 === table6Index) {
                                                                                            arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                            updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                            childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                            if (table4.values.length - 1 === table5Index) {
                                                                                                arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                                updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                                if (table3.values.length - 1 === table4Index) {
                                                                                                    arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                                    updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                    childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                                    if (table2.values.length - 1 === table3Index) {
                                                                                                        arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                                        updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                        childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                                        if (table.values.length - 1 === table2Index) {
                                                                                                            arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                            updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                            childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                            }
                                                                        });
                                                                    } else {

                                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0) {
                                                                            childArray.push({ uuid: Math.random(), ...table7, ...table6RowSpan });
                                                                        } else {
                                                                            let obj = { ...table6RowSpanZero };
                                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                            } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                            } else if (table5Index === 0 && table6Index === 0 && table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                            } else if (table6Index === 0 && table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                            } else if (table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                            }
                                                                            childArray.push({ uuid: Math.random(), ...table7, ...obj });

                                                                        }
                                                                        // total values logic
                                                                        let arr = {};
                                                                        if (table6.values.length - 1 === table7Index) {
                                                                            arr[`${table6.groupByProp}RowSpan`] = 1;
                                                                            updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                            childArray.push({ uuid: Math.random(), ...table6, ...arr, [table6.groupByProp]: getSubTotalLabel(table6[table6.groupByProp], table6.groupByProp), isSubTotal: true });
                                                                            if (table5.values.length - 1 === table6Index) {
                                                                                arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                                updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                                if (table4.values.length - 1 === table5Index) {
                                                                                    arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                                    updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                    childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                                    if (table3.values.length - 1 === table4Index) {
                                                                                        arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                                        updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                        childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                                        if (table2.values.length - 1 === table3Index) {
                                                                                            arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                            updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                            childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                            if (table.values.length - 1 === table2Index) {
                                                                                                arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                                updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                            }
                                                                                        }
                                                                                    }

                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                });

                                                            } else {

                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0) {
                                                                    childArray.push({ uuid: Math.random(), ...table6, ...table5RowSpan });
                                                                } else {
                                                                    let obj = { ...table5RowSpanZero };
                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0) {
                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0) {
                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                    } else if (table5Index === 0 && table6Index === 0) {
                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                    } else if (table6Index === 0) {
                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                    }
                                                                    childArray.push({ uuid: Math.random(), ...table6, ...obj });

                                                                }
                                                                // total values logic
                                                                let arr = {}
                                                                if (table5.values.length - 1 === table6Index) {
                                                                    arr[`${table5.groupByProp}RowSpan`] = 1;
                                                                    updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                    childArray.push({ uuid: Math.random(), ...table5, ...arr, [table5.groupByProp]: getSubTotalLabel(table5[table5.groupByProp], table5.groupByProp), isSubTotal: true });

                                                                    if (table4.values.length - 1 === table5Index) {
                                                                        arr[`${table4.groupByProp}RowSpan`] = 1;
                                                                        updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                        childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                                        if (table3.values.length - 1 === table4Index) {
                                                                            arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                            updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                            childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                            if (table2.values.length - 1 === table3Index) {
                                                                                arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                                updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                                if (table.values.length - 1 === table2Index) {
                                                                                    arr[`${table.groupByProp}RowSpan`] = 1;
                                                                                    updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                    childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                                }
                                                                            }
                                                                        }

                                                                    }

                                                                }
                                                            }
                                                        });

                                                    } else {
                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0) {
                                                            childArray.push({ uuid: Math.random(), ...table5, ...table4RowSpan });
                                                        } else {
                                                            let obj = { ...table4RowSpanZero };
                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0) {
                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                            } else if (table4Index === 0 && table5Index === 0) {
                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                            } else if (table5Index === 0) {
                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                            }
                                                            childArray.push({ uuid: Math.random(), ...table5, ...obj });

                                                        }
                                                        // total values logic
                                                        let arr = [];
                                                        if (table4.values.length - 1 === table5Index) {
                                                            arr[`${table4.groupByProp}RowSpan`] = 1;
                                                            updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                            childArray.push({ uuid: Math.random(), ...table4, ...arr, [table4.groupByProp]: getSubTotalLabel(table4[table4.groupByProp], table4.groupByProp), isSubTotal: true });

                                                            if (table3.values.length - 1 === table4Index) {
                                                                arr[`${table3.groupByProp}RowSpan`] = 1;
                                                                updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                                if (table2.values.length - 1 === table3Index) {
                                                                    arr[`${table2.groupByProp}RowSpan`] = 1;
                                                                    updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                    childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                                    if (table.values.length - 1 === table2Index) {
                                                                        arr[`${table.groupByProp}RowSpan`] = 1;
                                                                        updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                        childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                                    }
                                                                }
                                                            }

                                                        }

                                                    }
                                                });
                                            } else {
                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0) {
                                                    childArray.push({ uuid: Math.random(), ...table4, ...table3RowSpan });
                                                } else {
                                                    let obj = { ...table3RowSpanZero };
                                                    // if (table3Index === 0 && table4Index === 0) {
                                                    //     obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                    //     obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                    // }

                                                    if (table3Index === 0 && table4Index === 0) {
                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                    } else if (table4Index === 0) {
                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                    }

                                                    childArray.push({ uuid: Math.random(), ...table4, ...obj });

                                                }
                                                // total values logic
                                                let arr = []
                                                if (table3.values.length - 1 === table4Index) {
                                                    arr[`${table3.groupByProp}RowSpan`] = 1;
                                                    updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                    childArray.push({ uuid: Math.random(), ...table3, ...arr, [table3.groupByProp]: getSubTotalLabel(table3[table3.groupByProp], table3.groupByProp), isSubTotal: true });

                                                    if (table2.values.length - 1 === table3Index) {
                                                        arr[`${table2.groupByProp}RowSpan`] = 1;
                                                        updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                        childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                                        if (table.values.length - 1 === table2Index) {
                                                            arr[`${table.groupByProp}RowSpan`] = 1;
                                                            updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                            childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                                        }
                                                    }

                                                }
                                            }
                                        });
                                    } else {
                                        if (table2Index === 0 && table3Index === 0) {
                                            childArray.push({ uuid: Math.random(), ...table3, ...table2RowSpan });
                                        } else {
                                            let obj = { ...table2RowSpanZero };
                                            if (table3Index === 0) {
                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                            }
                                            childArray.push({ uuid: Math.random(), ...table3, ...obj });

                                        }

                                        // total values logic
                                        let arr = [];
                                        if (table2.values.length - 1 === table3Index) {
                                            arr[`${table2.groupByProp}RowSpan`] = 1;
                                            updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                            childArray.push({ uuid: Math.random(), ...table2, ...arr, [table2.groupByProp]: getSubTotalLabel(table2[table2.groupByProp], table2.groupByProp), isSubTotal: true });
                                            if (table.values.length - 1 === table2Index) {
                                                arr[`${table.groupByProp}RowSpan`] = 1;
                                                updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                            }
                                        }

                                    }
                                });
                            } else {
                                if (table2Index === 0) {
                                    childArray.push({ uuid: Math.random(), ...table2, ...tableRowSpan });
                                } else {
                                    childArray.push({ uuid: Math.random(), ...table2, ...tableRowSpanZero });

                                }
                                // total values logic
                                let arr = [];
                                if (table.values.length - 1 === table2Index) {
                                    arr[`${table.groupByProp}RowSpan`] = 1;
                                    updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                    childArray.push({ uuid: Math.random(), ...table, ...arr, [table.groupByProp]: getSubTotalLabel(table[table.groupByProp], table.groupByProp), isSubTotal: true });

                                }
                            }
                        });
                    } else {
                        row.burstReportHeaders.forEach((header) => {
                            table = { ...table, [`${header.headerProperty}RowSpan`]: 1 };
                        })
                        childArray.push(table);
                    }
                    childParentArray = [...childParentArray, ...childArray]
                });
                let mergedColumnNames = row.burstReportHeaders.map(x => x.headerProperty);
                childParentArray.forEach((ele, index) => {
                    let key = "";
                    if (index === 0) {
                        mergedColumnNames.forEach(mergedColumnName => {
                            key += ele[mergedColumnName]
                            ele[mergedColumnName + "RowSpan_merge_table"] = ele[mergedColumnName + "RowSpan"] + (subTotalMergeColumns.current[parentArray.length + 1] && subTotalMergeColumns.current[parentArray.length + 1][key] ? subTotalMergeColumns.current[parentArray.length + 1][key] : 0)
                        });
                    } else {
                        mergedColumnNames.forEach(mergedColumnName => {
                            key += ele[mergedColumnName];
                            if (ele[mergedColumnName + "RowSpan"] > 0) {
                                ele[mergedColumnName + "RowSpan_merge_table"] = ele[mergedColumnName + "RowSpan"] + (subTotalMergeColumns.current[parentArray.length + 1] && subTotalMergeColumns.current[parentArray.length + 1][key] ? subTotalMergeColumns.current[parentArray.length + 1][key] : 0);
                            } else {
                                ele[mergedColumnName + "RowSpan_merge_table"] = childParentArray[index - 1][mergedColumnName + "RowSpan_merge_table"] - 1;
                            }
                        })
                    }
                });
                const key = getLastRowPerPageDictKey(row);
                parentArray.push({ ...row, uuid: Math.random(), data: childParentArray, from: 0, size: lastRowPerPageDict[key] ? lastRowPerPageDict[key] : 10 });
            }
            console.log("CHILD", childParentArray);
        });
        console.log("sub total", subTotalMergeColumns);
        setFormattedData(parentArray);
        console.log("PARENT", parentArray);
    }

    const updateTableRowSpan = (childArray: any[], tableData, tableParentData, headers: IReportHeader[], parentArrayIndex: number) => {
        let canAddRowSpan = true;
        let lastAddedRow = childArray[childArray.length - 1];
        // console.log("groupByProp", tableParentData.groupByProp);

        let rowSpanColumnKey = '';
        headers.forEach((header, headerIndex) => {
            if (tableParentData.groupByProp === header.headerProperty) {
                canAddRowSpan = false;
            }
            // console.log("lastAddedRow[header.headerProperty]", lastAddedRow[header.headerProperty])
            let ele = lastAddedRow[header.headerProperty];


            // if (!lastAddedRow[header.headerProperty] && lastAddedRow[tableParentData.groupByProp] && lastAddedRow[tableParentData.groupByProp].includes("Sub Totals")) {
            //     let notSubTotalIndex = childArray.findLastIndex(x => !x.isSubTotal);
            //     ele = childArray[notSubTotalIndex][header.headerProperty];
            // }
            if (!lastAddedRow[header.headerProperty] && lastAddedRow[lastAddedRow.groupByProp] && lastAddedRow[lastAddedRow.groupByProp].includes("Sub Totals")) {
                let notSubTotalIndex = childArray.findLastIndex(x => !x.isSubTotal);
                ele = childArray[notSubTotalIndex][header.headerProperty];
            }
            if (canAddRowSpan && (ele)) {
                rowSpanColumnKey += ele
                if (!subTotalMergeColumns.current[parentArrayIndex + 1]) {
                    subTotalMergeColumns.current[parentArrayIndex + 1] = {}
                }
                if (!subTotalMergeColumns.current[parentArrayIndex + 1][rowSpanColumnKey]) {
                    subTotalMergeColumns.current[parentArrayIndex + 1][rowSpanColumnKey] = 0
                }
                subTotalMergeColumns.current[parentArrayIndex + 1][rowSpanColumnKey] += 1
            }
        });
        // console.log("groupByProp", JSON.stringify(subTotalMergeColumns.current, null, 4))
    }

    const increaseRowSpanForSubTotalRow = (key: string, selectedHeaders: string[], row: any, table: IRportGroupBy) => {
        const propsName = key.replace("RowSpan", "");
        let findIndex = selectedHeaders.findIndex((d) => d === propsName);
        if (findIndex !== -1) {
            let shouldAddOneToRowspan = true;
            while (shouldAddOneToRowspan && findIndex >= 0) {
                const selectedHeaderName = selectedHeaders[findIndex];
                if (row[selectedHeaderName] !== table[selectedHeaderName]) {
                    shouldAddOneToRowspan = false;
                }
                findIndex -= 1;
            }

            if (shouldAddOneToRowspan) row[key] += 1;
        }

        return row;
    }

    const getSubTotalLabel = (value: string, propsName: string) => {
        // let value = tableData[propsName];
        value = DATE_COLUMNS.indexOf(propsName) === -1 ? value : moment(value).format("MM/DD/YYYY");
        return `${value||""}: Sub Totals`;
    }

    const getLastRowPerPageDictKey = (reportData: any) => {
        let key = "";
        if (selectedBurstReports.includes('Do Not Burst')) {
            key = 'Do Not Burst';
        } else {
            ['Service Date', ...selectedBurstReports].filter((d) => reportData[BURST_REPORT_MAP_WITH_PROPS_DICT_BY_REPORT_ID[reportId][d]]).map((d) => {
                switch (d) {
                    case "Firms":
                    case "Firm":
                        key += key ? `#${reportData.firm}` : reportData.firm;
                        break;
                    case "Resource":
                        key += key ? `#${reportData.resource}` : reportData.resource;
                        break;
                    case "Time Category":
                        key += key ? `#${reportData.timeCategory}` : reportData.timeCategory;
                        break;
                    case "Professional Level":
                        key += key ? `#${reportData.professionalLevelName}` : reportData.professionalLevelName;
                        break;
                    case "Firm Long Name":
                        key += key ? `#${reportData.firmLongName}` : reportData.firmLongName;
                        break;
                    case "Imported Resource":
                        key += key ? `#${reportData.importedResource}` : reportData.importedResource;
                        break;
                    case "Imported Time Category":
                        key += key ? `#${reportData.importedTimeCategory}` : reportData.importedTimeCategory;
                        break;
                    case "Imported Professional Level":
                        key += key ? `#${reportData.importedProfessionalLevel}` : reportData.importedProfessionalLevel;
                        break;
                    case "Import Source":
                        key += key ? `#${reportData.importSource}` : reportData.importSource;
                        break;
                    case "Current Resource":
                        key += key ? `#${reportData.currentResource}` : reportData.currentResource;
                        break;
                    case "Current Time Category":
                        key += key ? `#${reportData.currentTimeCategory}` : reportData.currentTimeCategory;
                        break;
                    case "Current Professional Level":
                        key += key ? `#${reportData.currentProfessionalLevel}` : reportData.currentProfessionalLevel;
                        break;
                    case "Split Entry Resource":
                        key += key ? `#${reportData.splitEntryResource}` : reportData.splitEntryResource;
                        break;
                    case "Split Entry Time Category":
                        key += key ? `#${reportData.splitEntryTimeCategory}` : reportData.splitEntryTimeCategory;
                        break;
                    case "Split Entry Professional Level":
                        key += key ? `#${reportData.splitEntryProfessionalLevel}` : reportData.splitEntryProfessionalLevel;
                        break;
                    case "Service Date":
                        key += key ? `#${moment(reportData.serviceDate).format("MM/DD/YYYY")}` : moment(reportData.serviceDate).format("MM/DD/YYYY");
                        break;
                    default:
                        break;
                }
            });
        }
        return key;
    };

    const TablePaginationActions = (props: TablePaginationActionsProps) => {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className="d-flex">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <ChevronDoubleLeft />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ChevronRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <ChevronDoubleRight />
                </IconButton>
            </div>
        );
    };



    return (
        <Grid container spacing={2}>
            {formattedData.length > 0 ? (
                <>
                    {formattedData.map((reportData, reportDataIndex) => (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container spacing={2}>
                                        {['Service Date', ...selectedBurstReports].filter((d) => d !== 'Do Not Burst' && reportData[BURST_REPORT_MAP_WITH_PROPS_DICT_BY_REPORT_ID[reportId][d]]).map((d, index) => {
                                            let label = "", value = "";
                                            switch (d) {
                                                case "Firms":
                                                case "Firm":
                                                    label = "Firm:"
                                                    value = reportData.firm;
                                                    break;
                                                case "Resource":
                                                    label = "Resource:"
                                                    value = reportData.resource;
                                                    break;
                                                case "Time Category":
                                                    label = "Time Category:"
                                                    value = reportData.timeCategory;
                                                    break;
                                                case "Professional Level":
                                                    label = "Professional Level:"
                                                    value = reportData.professionalLevelName;
                                                    break;
                                                case "Firm Long Name":
                                                    label = "Firm Long Name:"
                                                    value = reportData.firmLongName;
                                                    break;
                                                case "Imported Resource":
                                                    label = "Imported Resource:"
                                                    value = reportData.importedResource;
                                                    break;
                                                case "Imported Time Category":
                                                    label = "Imported Time Category:"
                                                    value = reportData.importedTimeCategory;
                                                    break;
                                                case "Imported Professional Level":
                                                    label = "Imported Professional Level:"
                                                    value = reportData.importedProfessionalLevel;
                                                    break;
                                                case "Import Source":
                                                    label = "Import Source:"
                                                    value = reportData.importSource;
                                                    break;
                                                case "Current Resource":
                                                    label = "Current Resource:"
                                                    value = reportData.currentResource;
                                                    break;
                                                case "Current Time Category":
                                                    label = "Current Time Category:"
                                                    value = reportData.currentTimeCategory;
                                                    break;
                                                case "Current Professional Level":
                                                    label = "Current Professional Level:"
                                                    value = reportData.currentProfessionalLevel;
                                                    break;
                                                case "Split Entry Resource":
                                                    label = "Split Entry Resource:"
                                                    value = reportData.splitEntryResource;
                                                    break;
                                                case "Split Entry Time Category":
                                                    label = "Split Entry Time Category:"
                                                    value = reportData.splitEntryTimeCategory;
                                                    break;
                                                case "Split Entry Professional Level":
                                                    label = "Split Entry Professional Level:"
                                                    value = reportData.splitEntryProfessionalLevel;
                                                    break;
                                                case "Service Date":
                                                    label = "Service Date:"
                                                    value = moment(reportData.serviceDate).format("MM/DD/YYYY");
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return (
                                                <Grid item key={index}>
                                                    <Grid container alignItems='center'>
                                                        <Grid item>
                                                            <Typography variant='subtitle2' style={{ marginRight: 4 }}>{`${label} `}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant='body2'>{value}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                <React.Fragment>
                                    {reportData.data && reportData.data.length > 0 ? (
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TableContainer className="moz-table-pb">
                                                <Table size="small" className="custom-table report-table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {reportData.burstReportHeaders.map(header => (
                                                                <>
                                                                    {(selectedColumns.includes(header.headerName)) && <StyledTableCell className={`cell-${header.headerProperty}`} key={`burst_header_${header.headerProperty}`} align="left">{header.headerName}</StyledTableCell>}
                                                                </>
                                                            ))}
                                                            {selectedHeaderProps.map((metrics, index) => {
                                                                return (
                                                                    <StyledTableCell align={["description", "importedDescription", "currentDescription", "originalEntryDescription", "splitEntryDescription"].indexOf(metrics) === -1 ? "right" : "left"} className={`cell-${metrics}`} key={`header_metrics_${index}`}>
                                                                        {METRICS_DICT_BY_REPORT_ID[reportId][metrics]}
                                                                    </StyledTableCell>
                                                                )
                                                            }
                                                            )}
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        {reportData.data.slice(reportData.from, reportData.from + reportData.size).map((reportTable, reportTableIndex) => (
                                                            <StyledTableRow key={`report_${reportTable.id}}`} className={reportTable.isSubTotal && 'time-detail-table-row'}>
                                                                {reportData.burstReportHeaders.map((header, headerIndex) => (
                                                                    <>
                                                                        {((reportTable[`${header.headerProperty}RowSpan`] > 0 || (reportTableIndex === 0 && reportTable[`${header.headerProperty}RowSpan_merge_table`] > 0)) && selectedColumns.includes(header.headerName)) && (
                                                                            <StyledTableCell className={`cell-${header.headerProperty}`} rowSpan={reportTable[header.headerProperty] && reportTable[header.headerProperty].includes("Sub Totals") ? 1 : (
                                                                                reportTable[`${header.headerProperty}RowSpan`] > 0 ?
                                                                                    (reportTable[`${header.headerProperty}RowSpan_merge_table`])
                                                                                    : (reportTableIndex === 0 && reportTable[`${header.headerProperty}RowSpan_merge_table`] > 0) ? (reportTable[`${header.headerProperty}RowSpan_merge_table`]) : 1
                                                                            )} align='left'>
                                                                                {["serviceDate", "importedServiceDate", "currentServiceDate"].includes(header.headerProperty) ? isNaN(Date.parse(reportTable[header.headerProperty])) ? reportTable[header.headerProperty] : moment(reportTable[header.headerProperty]).format("MM/DD/YYYY") : reportTable[header.headerProperty]}</StyledTableCell>
                                                                        )}
                                                                        {/* {(reportTable[`${header.headerProperty}RowSpan`] > 0 && (selectedMetrics.includes('all') || selectedMetrics.includes(header.headerName))) && <StyledTableCell rowSpan={reportTable[`${header.headerProperty}RowSpan`]}>{reportTable[header.headerProperty]}</StyledTableCell>} */}
                                                                    </>
                                                                ))}

                                                                {selectedHeaderProps.map((metrics) => {
                                                                    return (
                                                                        <StyledTableCell className={`cell-${metrics}`} align={["description", "importedDescription", "currentDescription"].indexOf(metrics) === -1 ? "right" : "left"}>
                                                                            {reportId === "7" ? (
                                                                                <React.Fragment>
                                                                                    {["importedBillingRate", "currentBillingRate", "billingRateDifference"].indexOf(metrics) > -1 && reportTable.isSubTotal ? (
                                                                                        <React.Fragment></React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            {['importedBillingRate', 'importedFeesTotal', 'currentBillingRate', 'currentFeesTotal', 'billingRateDifference', 'feesTotalDifference'].includes(metrics) ?
                                                                                                currencyFormat(convertToFixed(reportTable[metrics]))
                                                                                                : ['importedHours', 'currentHours', 'hoursDifference'].includes(metrics) ?
                                                                                                    hoursFormat(reportTable[metrics])
                                                                                                    : convertToFixed(reportTable[metrics])}
                                                                                        </React.Fragment>
                                                                                    )}

                                                                                </React.Fragment>
                                                                            ) : reportId === "1" ? (
                                                                                <React.Fragment>
                                                                                    {['rate', 'amount'].includes(metrics) ?
                                                                                        <React.Fragment>
                                                                                            {metrics === "rate" && reportTable.isSubTotal ? "" : currencyFormat(convertToFixed(reportTable[TIME_DETAIL_METRICS_MAP_WITH_VALUE[metrics]]))}
                                                                                        </React.Fragment>
                                                                                        : ['time'].includes(metrics) ?
                                                                                            hoursFormat(reportTable[TIME_DETAIL_METRICS_MAP_WITH_VALUE[metrics]])
                                                                                            : convertToFixed(reportTable[TIME_DETAIL_METRICS_MAP_WITH_VALUE[metrics]])}
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    {['originalEntryRate', 'originalEntryAmount', 'splitEntryRate', 'splitEntryAmount', 'originalVsAllSplitAmountDifference'].includes(metrics) ?
                                                                                        currencyFormat(convertToFixed(reportTable[metrics]))
                                                                                        : ['originalEntryHours', 'splitEntryHours', 'originalVsAllSplitHoursDifference'].includes(metrics) ?
                                                                                            hoursFormat(reportTable[metrics])
                                                                                            : reportTable[metrics]}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </StyledTableCell>
                                                                    )
                                                                }
                                                                )}
                                                            </StyledTableRow >
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={reportRowsPerPage.current}
                                                component="div"
                                                count={reportData.data.length}
                                                rowsPerPage={reportData.size}
                                                page={
                                                    reportData.from === 0
                                                        ? 0
                                                        : reportData.from / reportData.size
                                                }
                                                SelectProps={{
                                                    inputProps: { "aria-label": "rows per page" },
                                                    native: true,
                                                }}
                                                onPageChange={(event: any, newPage: number) => {
                                                    console.log("event", event);
                                                    console.log("newPage", newPage);
                                                    let formattedDataList = [...formattedData]
                                                    let reportSearchData = { ...formattedDataList[reportDataIndex] };
                                                    reportSearchData.from =
                                                        newPage === 0
                                                            ? 0
                                                            : (newPage * reportSearchData.size)
                                                    formattedDataList[reportDataIndex] = reportSearchData
                                                    setFormattedData(formattedDataList);
                                                }}
                                                onRowsPerPageChange={(event: any) => {
                                                    let value = event.target.value;
                                                    let formattedDataList = [...formattedData]
                                                    let reportSearchData = { ...formattedDataList[reportDataIndex] };
                                                    let lastRowPerPageDic = { ...lastRowPerPageDict };
                                                    reportSearchData.size = value ? Number(value) : 500;
                                                    const key = getLastRowPerPageDictKey(reportData);
                                                    lastRowPerPageDic[key] = reportSearchData.size;
                                                    reportSearchData.from = 0;
                                                    formattedDataList[reportDataIndex] = reportSearchData
                                                    setFormattedData(formattedDataList);
                                                    setLastRowPerPageDict(lastRowPerPageDic);
                                                }}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item lg={12}>
                                            <p className="text-center">No Data found</p>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    ))
                    }
                </>
            ) : (
                <Grid item lg={12}>
                    <p className="text-center">No Data found</p>
                </Grid>
            )}
        </Grid >
    );
}

export default TimeSummaryReportView;