import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Formik } from "formik";
import * as React from "react";

export interface ExportDialogProps {
  exportFor: string;
  handleDialogClose: Function;
}

const ExportDialog: React.FC<ExportDialogProps> = ({
  handleDialogClose,
  exportFor,
}) => {
  const [exportForm, setExportForm] = React.useState({
    exportType: "csv",
    includeChangeLog: false,
    exportSeparately: false,
    useExcelTemplate: true
  } as {
    exportType: "csv" | "pdf" | "excel";
    includeChangeLog: boolean;
    exportSeparately: boolean;
    useExcelTemplate: boolean;
  });

  const closeAddDialog = (data?: any) => {
    handleDialogClose(data);
  };

  return (
    <React.Fragment>
      <Dialog
        open={true}
        onClose={() => closeAddDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{exportFor === 'submitToCourt' ? 'Submit To Court' : 'Export'}</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={exportForm}
          onSubmit={async (
            values: {
              exportType: "csv" | "pdf" | "excel";
              includeChangeLog: boolean;
              exportSeparately: boolean;
              useExcelTemplate: boolean
            },
            { setSubmitting }
          ) => {
            const obj = { ...values };
            closeAddDialog(obj);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent className="pt-8">
                <Grid container spacing={2}>
                  {exportFor !== 'submitToCourt' && (
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name="exportType"
                          value={values.exportType}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="csv"
                            control={<Radio color="secondary" />}
                            label="As CSV"
                            labelPlacement="end"
                          />
                          {/* <FormControlLabel
                            value="pdf"
                            disabled={["timesheetOrExpenseDashboardMetrics"].indexOf(exportFor) > -1}
                            control={<Radio color="secondary" />}
                            label="As PDF"
                          /> */}

                          {
                            exportFor === "grantedNotGranted" && (
                              <FormControlLabel
                                value="excel"
                                onChange={() => {
                                  setFieldValue("includeChangeLog", false);
                                }}
                                control={<Radio color="secondary" />}
                                label="Use SoCal Gas Leak Excel Templates"
                              />
                            )
                          }
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {/* {(exportFor === 'detailedTimesheetOrExpense' || exportFor === "grantedNotGranted") && ( */}
                    {(exportFor==='detailedTimesheetOrExpense' || exportFor==='timesheetOrExpense' || exportFor === "grantedNotGranted") && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.includeChangeLog}
                            onChange={handleChange}
                            name="includeChangeLog"
                          />
                        }
                        disabled={exportFor === "grantedNotGranted" && values.exportType == "excel" ? true : false}
                        label="Include Change-log"
                      />
                    </Grid>
                  )}
                  {(["timesheetAttentionMain","timesheetSummary", "timesheetOrExpense", "grantedNotGranted", "timesheetOrExpenseDashboard", "timesheetOrExpenseDashboardMetrics"].indexOf(exportFor) > -1) && (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.exportSeparately}
                              onChange={handleChange}
                              name="exportSeparately"
                            />
                          }
                          label="Export each firm separately"
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.useExcelTemplate}
                                onChange={handleChange}
                                name="useExcelTemplate"
                              />
                            }
                            label="Use SoCal Gas Leak Excel Templates"
                          />
                        </Grid> */}
                    </React.Fragment>
                  )}
                  {exportFor === 'submitToCourt' && (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.exportSeparately}
                              onChange={handleChange}
                              name="exportSeparately"
                            />
                          }
                          label="Also create separate CSVs for each firm"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.includeChangeLog}
                              onChange={handleChange}
                              name="includeChangeLog"
                            />
                          }
                          label="Include Change-log"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.useExcelTemplate}
                              onChange={handleChange}
                              name="useExcelTemplate"
                            />
                          }
                          label="Use SoCal Gas Leak Excel Templates"
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions className="p-16">
                <Button onClick={() => closeAddDialog()} color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {exportFor === 'submitToCourt' ? 'Submit' : 'Export'}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

export default ExportDialog;
