import { Card, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { abbreviateNumber } from "../../services/UtilService";
import { IDashboardFirm, IDashboardTime, IKeyValue } from "../../vm";
import SecondaryLoading from "../common/SecondaryLoading";

export interface TimeEntriesStatsForAllFirmsProps {
  timesheetCountDic: IKeyValue<number>;
  allFirmsTimesheetCountDic: IKeyValue<number>;
  hoursAndFeeCount: { hours: number; fee: number };
  allFirmsHoursAndFeeCount: { hours: number; fee: number };
  navigateToTimeSheets: Function;
  search: IDashboardFirm | IDashboardTime;
  isLoading?: boolean;
}

const TimeEntriesStatsForAllFirms: React.SFC<TimeEntriesStatsForAllFirmsProps> = ({
  allFirmsHoursAndFeeCount,
  allFirmsTimesheetCountDic,
  hoursAndFeeCount,
  navigateToTimeSheets,
  timesheetCountDic,
  search,
  isLoading
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={2} className="position-relative">
        {isLoading && <SecondaryLoading />}
        <Grid item xs={12} sm={12} md={12} lg={9} className="dashed-border">
          <Typography variant="h6">Time Entries Count</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={3}>
              <Card className="p-16 count-card">
                <Grid container spacing={2} justify="space-around">
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      All
                    </Typography>
                    <Typography variant="h5" className="fw-600">
                      {abbreviateNumber(allFirmsTimesheetCountDic?.total || 0)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Selected
                    </Typography>
                    <Typography variant="h5" className="fw-600">
                      {abbreviateNumber(timesheetCountDic?.total || 0)}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="h6">Total</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8} lg={9}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("new")}
                  >
                    <Grid container spacing={2} justify="space-around">
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          All
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(
                            allFirmsTimesheetCountDic?.new || 0
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          Selected
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(timesheetCountDic?.new || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="h6">New</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("attention")}
                  >
                    <Grid container spacing={2} justify="space-around">
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          All
                        </Typography>
                        <Typography
                          variant="h5"
                          className="fw-600"
                          color="secondary"
                        >
                          {abbreviateNumber(
                            allFirmsTimesheetCountDic?.attention || 0
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          Selected
                        </Typography>
                        <Typography
                          variant="h5"
                          className="fw-600"
                          color="secondary"
                        >
                          {abbreviateNumber(timesheetCountDic?.attention || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="h6">Attention</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("reviewed")}
                  >
                    <Grid container spacing={2} justify="space-around">
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          All
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(
                            allFirmsTimesheetCountDic?.reviewed || 0
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          Selected
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(timesheetCountDic?.reviewed || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="h6">Reviewed</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("adjusted")}
                  >
                    <Grid container spacing={2} justify="space-around">
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          All
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(
                            allFirmsTimesheetCountDic?.adjusted || 0
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          Selected
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(timesheetCountDic?.adjusted || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="h6">Adjusted</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("approved")}
                  >
                    <Grid container spacing={2} justify="space-around">
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          All
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(
                            allFirmsTimesheetCountDic?.approved || 0
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          Selected
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(timesheetCountDic?.approved || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="h6">Approved</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className={`p-16 count-card ${(search.showDisapproved || search.showOnlyDisapproved) ? 'pointer' : ''}`}
                    onClick={() => search.showDisapproved ? navigateToTimeSheets("disapproved", true) : search.showOnlyDisapproved ? navigateToTimeSheets("disapproved", false, true) : null}
                  >
                    <Grid container spacing={2} justify="space-around">
                      {(search.showDisapproved || search.showOnlyDisapproved) ? (
                        <React.Fragment>
                          <Grid item>
                            <Typography variant="subtitle1" color="textSecondary">
                              All
                            </Typography>
                            <Typography variant="h5" className="fw-600">
                              {abbreviateNumber(
                                allFirmsTimesheetCountDic?.disapproved || 0
                              )}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1" color="textSecondary">
                              Selected
                            </Typography>
                            <Typography variant="h5" className="fw-600">
                              {abbreviateNumber(timesheetCountDic?.disapproved || 0)}
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item>
                          <Typography variant="subtitle1" className="fw-600">
                            Not shown
                          </Typography>
                        </Grid>
                      )}

                    </Grid>
                    <Typography variant="h6">Disapproved</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("submitted")}
                  >
                    <Grid container spacing={2} justify="space-around">
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          All
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(
                            allFirmsTimesheetCountDic?.submitted || 0
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          Selected
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(timesheetCountDic?.submitted || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="h6">Submitted</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("granted")}
                  >
                    <Grid container spacing={2} justify="space-around">
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          All
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(
                            allFirmsTimesheetCountDic?.granted || 0
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          Selected
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(timesheetCountDic?.granted || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="h6">Granted</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("not-granted")}
                  >
                    <Grid container spacing={2} justify="space-around">
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          All
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(
                            allFirmsTimesheetCountDic["not-granted"] || 0
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                          Selected
                        </Typography>
                        <Typography variant="h5" className="fw-600">
                          {abbreviateNumber(timesheetCountDic["not-granted"] || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="h6">Not Granted</Typography>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} className="dashed-border">
          <Grid container spacing={2} className="mt-8 h-100">
            <Grid item xs={12} sm={12} lg={12}>
              <Card className="p-16 count-card">
                <Grid container spacing={2} justify="space-around">
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      All
                    </Typography>
                    <Typography variant="h5" className="fw-600">
                      {abbreviateNumber(allFirmsHoursAndFeeCount.hours)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Selected
                    </Typography>
                    <Typography variant="h5" className="fw-600">
                      {abbreviateNumber(hoursAndFeeCount.hours)}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="h6">Hours</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Card className="p-16 count-card">
                <Grid container spacing={2} justify="space-around">
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      All
                    </Typography>
                    <Typography variant="h5" className="fw-600">
                      ${abbreviateNumber(allFirmsHoursAndFeeCount.fee)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Selected
                    </Typography>
                    <Typography variant="h5" className="fw-600">
                      ${abbreviateNumber(hoursAndFeeCount.fee)}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="h6">Fees</Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TimeEntriesStatsForAllFirms;
