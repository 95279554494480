import * as React from "react";
import moment from "moment";
import CustomDrawer from "../common/CustomDrawer";
import { Formik } from "formik";
import { Button, FormControl, Grid, TextField } from "@material-ui/core";

export interface CustomDateDialogProps {
  startDate?: Date;
  endDate?: Date;
  handleDialogClose: Function;
}

const CustomDateDialog: React.FC<CustomDateDialogProps> = ({
  startDate,
  endDate,
  handleDialogClose,
}) => {
  const [state, setState] = React.useState({
    customDates: {
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
    },
  } as { customDates: { startDate: Date; endDate: Date } });

  const closeAddDialog = (data?: any) => {
    handleDialogClose(data);
  };
  return (
    <React.Fragment>
      <CustomDrawer title={""} onClose={() => closeAddDialog()}>
        <section className="p-16">
          <Formik
            enableReinitialize
            initialValues={state.customDates}
            validate={() => {}}
            onSubmit={async (values: any, { setSubmitting }) => {
              const obj = { ...values };
              obj.startDate=moment(obj.startDate).toISOString();
              obj.endDate=moment(obj.endDate).toISOString();
              closeAddDialog(obj);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        type="date"
                        onChange={handleChange}
                        error={
                          errors.startDate && touched.startDate ? true : false
                        }
                        helperText={
                          errors.startDate &&
                          touched.startDate &&
                          errors.startDate
                        }
                        onBlur={handleBlur}
                        value={moment(values.startDate).format("YYYY-MM-DD")}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        type="date"
                        onChange={handleChange}
                        error={errors.endDate && touched.endDate ? true : false}
                        helperText={
                          errors.endDate && touched.endDate && errors.endDate
                        }
                        onBlur={handleBlur}
                        value={moment(values.endDate).format("YYYY-MM-DD")}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default CustomDateDialog;
