import {
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import {
    DotsVertical,
} from "mdi-material-ui";
import * as React from "react";
import { deleteFirmConfiguration, getFirmConfigurationList } from "../../services/FirmsService";
import {
    StyledTableCell,
    StyledTableRow,
} from "../../services/UtilService";
import { IConfirmDialog, IFilter, IFirmDetails, IUser } from "../../vm";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import ManageConfigureDialog from "./ManageConfigureDialog";
import { FIRM_CONFIGURE_TYPES, FIRM_CONFIGURE_FILE_FORMAT } from "./../../Constant"
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

export interface ConfigureFirmsProps extends IConfirmDialog { }

const ConfigureFirms: React.FC<ConfigureFirmsProps> = ({
    confirmDialog,
}) => {
    const [configuredFirms, setConfiguredFirms] = React.useState([] as any[]);
    const [firmDialogData, setFirmDialogData] = React.useState({
        selectedItemIndex: -1,
        anchorElMenu: null,
        manageConfiguredFirmDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
        },

    } as {
        selectedItemIndex: number;
        anchorElMenu: any;
        filterData: IFilter[];
        manageConfiguredFirmDialog: {
            isOpen: boolean;
            index?: number;
            data?: IFirmDetails;
        };
    });

    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);

    const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

    React.useEffect(() => {
        const fetchFirms = async () => {
            await getConfiguredFirms();
        };
        fetchFirms();
    }, []);

    const getConfiguredFirms = async () => {
        setLoading(true);
        let result = await getFirmConfigurationList();
        setLoading(false);
        if (result?.isSuccess) {
            setConfiguredFirms(result.data);
        } else {
            showToast(result.message || "Error while getting configured firms", "error")
        }
    };



    const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
        let data: any = undefined;
        if (editIndex !== undefined) {
            data = configuredFirms[editIndex];
        }
        setFirmDialogData({
            ...firmDialogData,
            manageConfiguredFirmDialog: {
                isOpen: isOpen,
                data: data,
                index: editIndex,
            },
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
    };

    const handleClickMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        index: number
    ) => {
        setFirmDialogData({
            ...firmDialogData,
            selectedItemIndex: index,
            anchorElMenu: event.currentTarget,
        });
    };

    const handleCloseMenu = () => {
        setFirmDialogData({ ...firmDialogData, selectedItemIndex: -1, anchorElMenu: null });
    };

    const handleDelete = (index: number) => {
        setFirmDialogData({
            ...firmDialogData,
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
        confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
            setLoading(true);
            let configuredFirmsList = [...configuredFirms];
            const result = await deleteFirmConfiguration(configuredFirmsList[index].id as number);
            if (result?.isSuccess) {
                if (result?.isReadOnly) {
                    showToast(result.message, "error");
                    updateStatus(result.isReadOnly);
                } else {
                    configuredFirmsList.splice(index, 1);
                    setConfiguredFirms(configuredFirmsList);
                    showToast("Configured firm deleted successfully", "success");
                }
            } else {
                showToast(result?.message || "Error while deleting configured firm", "error");
            }
            setLoading(false);
        });
    };



    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">Firms Configured</Typography>
                </div>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container spacing={1} justify="flex-end">
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleAddDialogOpen(true);
                                                    }}
                                                    disabled={isReadOnly}
                                                >
                                                    Add Firm Configuration
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <TableContainer className="users-table-container moz-table-pb">
                                            <Table stickyHeader size="small" className="custom-table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Firm</StyledTableCell>
                                                        <StyledTableCell>Type</StyledTableCell>
                                                        <StyledTableCell>Format</StyledTableCell>
                                                        <StyledTableCell width={80}>Actions</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {configuredFirms.length > 0 ? (
                                                        configuredFirms.map((ele, index: number) => (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell>
                                                                    {ele.lawFirmName || ""}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {FIRM_CONFIGURE_TYPES[ele.type] || ""}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {FIRM_CONFIGURE_FILE_FORMAT[ele.fileFormat] || ""}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={(e) => handleClickMenu(e, index)}
                                                                    >
                                                                        <DotsVertical />
                                                                    </IconButton>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={4}>
                                                                No details found{" "}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            <Menu
                id="simple-menu"
                anchorEl={firmDialogData.anchorElMenu}
                keepMounted
                open={Boolean(firmDialogData.anchorElMenu)}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    onClick={() => {
                        handleAddDialogOpen(true, firmDialogData.selectedItemIndex);
                    }}
                    disabled={isReadOnly}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleDelete(firmDialogData.selectedItemIndex);
                    }}
                    disabled={isReadOnly}
                >
                    Delete
                </MenuItem>
            </Menu>


            {firmDialogData.manageConfiguredFirmDialog.isOpen && (
                <ManageConfigureDialog data={firmDialogData.manageConfiguredFirmDialog.data} handleDialogClose={(res) => {
                    handleAddDialogOpen(false);
                    if (res) {
                        getConfiguredFirms();
                    }
                }}
                    updateStatus={updateStatus}
                />
            )}
        </React.Fragment>
    );
};

export default withConfirmDialogContext(ConfigureFirms);
