import {
    IStandardAPIResponse,
    IExpensesSubmitToCourt,
    IExpenseObj,
    IExpense,
    IProfessionalHourStats,
    IPerson,
    IExpenseApprovedBy,
    IApproveListTimeEntry,
} from "../vm";
import moment from "moment";
import { httpClient, httpDownloadCSV, httpDownloadPDF } from "./UtilService";

export async function getAllExpenses(
    searchObj: any
): Promise<IStandardAPIResponse<IExpenseObj>> {
    try {
        let res = await httpClient<IExpenseObj>(`expenses`, "POST", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getExpensesOfExpense(
    searchObj: any
): Promise<IStandardAPIResponse<Array<IExpense>>> {
    try {
        let res = await httpClient<Array<IExpense>>(
            `expenses/expense`,
            "POST",
            searchObj
        );
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
export async function editExpense(
    obj: any
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(
            `expenses/edit`,
            "PATCH",
            obj
        );
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getExpensesLogs(
    timesheetId: number
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(
            `expenses/change-logs/${timesheetId}`,
            "GET"
        );
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function expensesReviewed(
    obj: any
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(
            `expenses/reviewed`,
            "PATCH",
            obj
        );
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
export async function approveExpenses(
    obj: any
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(
            `expenses/approved`,
            "PATCH",
            obj
        );
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getExpensesFiltersCount(obj: any,isGrantedNotGranted?:boolean): Promise<IStandardAPIResponse<any>> {
    try {
        let requestObj={
            from: 0,
            size: 10,
            statuses: isGrantedNotGranted?[...obj.statuses]:[],
            firms: obj.lawFirmCode&&typeof obj.lawFirmCode ==="string"?[obj.lawFirmCode]:[],
            lawFirmCode: [],
            persons: [],
            startDate: undefined,
            endDate: undefined,
            sortBy: "",
            sortOrder: "asc",
            keyword: [],
            min: "",
            max: "",
            amount: "",
            professionalLevel: [],
            categoryCodeForTime: [],
            categoryCodeForExpense: [],
            workType: [],
          }
        if(isGrantedNotGranted && requestObj.statuses.length<3){
            requestObj.statuses = [];
        }
        let res = await httpClient<any>(`expenses/counts`, "POST", requestObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getExpensePersons(): Promise<IStandardAPIResponse<Array<IPerson>>> {
    try {
        let res = await httpClient<Array<IPerson>>(`timesheets/persons`, "GET");
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getExpenseFirmEmployeeListDetails(
    searchObj: any
): Promise<IStandardAPIResponse<Array<IProfessionalHourStats>>> {
    try {
        let res = await httpClient<Array<IProfessionalHourStats>>(
            `timesheets/employee-hours`,
            "POST",
            searchObj
        );
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getExpensesToSubmitCourt(
    searchObj: any
): Promise<IStandardAPIResponse<IExpensesSubmitToCourt>> {
    try {
        let res = await httpClient<IExpensesSubmitToCourt>(`expenses/submit-court`, "POST", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function submitExpenseToCourt(
    searchObj: any
): Promise<IStandardAPIResponse<IExpensesSubmitToCourt>> {
    try {
        let res = await httpClient<IExpensesSubmitToCourt>(`expenses/submit-to-court`, "PATCH", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
export async function submitGrantedNotGrantedExpenseToCourt(
    searchObj: any
): Promise<IStandardAPIResponse<IExpensesSubmitToCourt>> {
    try {
        let res = await httpClient<IExpensesSubmitToCourt>(`expenses/granted`, "PATCH", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getAllApprovedListForExpenses(
): Promise<IStandardAPIResponse<Array<IExpenseApprovedBy>>> {
    try {
        let res = await httpClient<Array<IExpenseApprovedBy>>(`expenses/approved-by`, "GET");
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getExpensesAttentionDetails(
    data: any
): Promise<IStandardAPIResponse<IExpense[]>> {
    try {
        let res = await httpClient<IExpense[]>(`expenses/attention-details`, "POST", data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function generateExportForExpenseSubmitForCourt(
    timesheetFilters: any, timesheetIds: any, includeChangeLog: boolean, includeCSVExport: boolean,obj?:any
): Promise<IStandardAPIResponse<IExpense[]>> {
    try {
        let res;
        let body :any ={
            isSaveOnServer: true,
            expensesIds:timesheetIds,
            includeChangeLog,
            includeCSVExport,
            timesheetFilters,
            isZip:includeCSVExport,
            localTime:moment().format("YYYYMMDDHHmmss"),
            type:"submitted",
        }
        if(obj){
            body.searchQuery=obj.searchQuery;
            body.categoryList=obj.categoryList;
            body.firmList=obj.firmList;
        }
        // if (includeCSVExport) {
            res = await httpDownloadCSV(`timesheets/generate-csv/expense-submit`, "POST", body, false);
        // }
        // res = await httpDownloadPDF<IExpense[]>(`timesheets/generate-pdf-submit`, "POST", {
        //     isSaveOnServer: true,
        //     timesheetIds,
        //     includeChangeLog,
        //     includeCSVExport,
        //     timesheetFilters,
        //     localTime:moment().format("YYYYMMDDHHmmss")
        // }, false);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getAllCategoriesForExpenses(): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`expenses/category`, "GET");
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}