import {
    Grid,
    Typography, Card, CardContent
} from "@material-ui/core";
import * as React from "react";
import { APP_VERSION } from "../../Constant";
export interface AboutMainProps { }

const AboutMain: React.FC<AboutMainProps> = ({
}) => {

    return (
        <React.Fragment>
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">About</Typography>
                </div>
                <br />
                <Card >
                    <CardContent className="rules-content">
                        <Typography variant="h6" align="center">Version {APP_VERSION}</Typography>
                        <br/> 
                        <Typography variant="subtitle1" align="center">© Copyright {new Date().getFullYear()} Locus Solutions, Inc</Typography> 
                    </CardContent>
                </Card>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            </section>
        </React.Fragment>
    );
};

export default AboutMain;
