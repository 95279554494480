import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {
  convertToFixed,
  currencyFormat,
  numberFormat,
  StyledTableCell,
} from "../../services/UtilService";
import { ITopExpenseCategory, ITopExpenseFirm } from "../../vm";
import SecondaryLoading from "../common/SecondaryLoading";

export interface TopFirmsAndCategoryForExpenseProps {
  topFirms: ITopExpenseFirm[];
  topCategories: ITopExpenseCategory[];
  isLoading: boolean;
}

const TopFirmsAndCategoryForExpense: React.SFC<TopFirmsAndCategoryForExpenseProps> = (
  props
) => {
  return (
    <section className="position-relative">
      <br />
      {props.isLoading ? <SecondaryLoading /> :
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TableContainer className="moz-table-pb">
              <Table size="small" className="custom-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      width="150px"
                      className="head-header"
                      align="center"
                    >
                      Top Firms
                    </StyledTableCell>
                    <StyledTableCell align="center">Entries</StyledTableCell>
                    <StyledTableCell align="center">Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.topFirms?.length > 0 ? (
                    <React.Fragment>
                      {props.topFirms.map((item, index) => (
                        <TableRow key={`topFirms${index}`}>
                          <TableCell className="head-column">
                            {item?.lawFirmName || ""}
                          </TableCell>
                          <TableCell align="right">
                            {numberFormat(convertToFixed(item.expenseEntries))}
                          </TableCell>
                          <TableCell align="right">
                            {currencyFormat(convertToFixed(item.expenseAmount))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No Data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableContainer className="moz-table-pb">
              <Table size="small" className="custom-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      width="150px"
                      className="head-header"
                      align="center"
                    >
                      Top Categories
                    </StyledTableCell>
                    <StyledTableCell align="center">Entries</StyledTableCell>
                    <StyledTableCell align="center">Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.topCategories?.length > 0 ? (
                    <React.Fragment>
                      {props.topCategories.map((item, index) => (
                        <TableRow key={`topCategories${index}`}>
                          <TableCell className="head-column">
                            {item?.expenseCategoryName || ""}
                          </TableCell>
                          <TableCell align="right">
                            {numberFormat(convertToFixed(item.expenseEntries))}
                          </TableCell>
                          <TableCell align="right">
                            {currencyFormat(convertToFixed(item.expenseAmount))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No Data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      }
    </section>
  );
};

export default TopFirmsAndCategoryForExpense;
