import {
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from "@material-ui/core";
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight,
    DotsVertical,
    Refresh
} from "mdi-material-ui";
import * as React from "react";
import { deleteFirmConfiguration, getUploadedTimeSheets, startImportProcessAPI } from "../../services/FirmsService";
import {
    StyledTableCell,
    StyledTableRow,
} from "../../services/UtilService";
import { IConfirmDialog, IFilter, IFirmDetails, IFirmImport } from "../../vm";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import ManageImportDialog from "./ManageImportDialog";
import { FIRM_CONFIGURE_TYPES, FIRM_IMPORT_STATUS } from "./../../Constant"
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

export interface ImportListProps extends IConfirmDialog { }

const ImportList: React.FC<ImportListProps> = ({
    confirmDialog,
}) => {
    const [importList, setImportList] = React.useState([] as IFirmImport[]);
    const [totalResults, setTotalResults] = React.useState(0);
    const [searchObj, setSearchObj] = React.useState({ from: 0, size: 10, sortBy: "fileImportedDate", sortOrder: "desc", rowsList: [10], } as {
        from: number;
        size: number;
        sortBy: string;
        sortOrder: "desc" | "asc";
        rowsList: number[];
    });
    const [importDialogData, setImportDialogData] = React.useState({
        selectedItemIndex: -1,
        anchorElMenu: null,
        manageImportDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
        },

    } as {
        selectedItemIndex: number;
        anchorElMenu: any;
        filterData: IFilter[];
        manageImportDialog: {
            isOpen: boolean;
            index?: number;
            data?: IFirmDetails;
        };
    });

    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);
    const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

    React.useEffect(() => {
        const fetchImportedList = async () => {
            await getImportedList();
        };
        fetchImportedList();
    }, []);

    const getImportedList = async (search?) => {
        setLoading(true);
        let tempSearch = { ...searchObj };
        if (search) {
            tempSearch = { ...tempSearch, ...search }
        }
        let result = await getUploadedTimeSheets(tempSearch);
        setLoading(false);
        if (result?.isSuccess) {
            if (result?.isReadOnly) {
                showToast(result.message, "error");
                updateStatus(result.isReadOnly);
            } else {
                let menuItems = [];
                for (let index = searchObj.size; index <= result.data.count; index = index + searchObj.size) {
                    menuItems.push(index);
                }
                if (menuItems[menuItems.length - 1] < result.data.count) {
                    menuItems.push(result.data.count);
                }
                setTotalResults(result.data.count);
                setSearchObj({
                    ...searchObj,
                    rowsList: menuItems
                });
                setImportList(result.data.results);
            }
        } else {
            showToast(result.message || "Error while getting list", "error")
        }
        if (search) {
            setSearchObj({
                ...searchObj,
                ...search
            })
        }
    };

    const onSearch = async (searchObj?: any) => {
        let tempSearch = { ...searchObj };
        if (searchObj) {
            tempSearch = { ...searchObj, ...searchObj };
        }
        await getImportedList(tempSearch);
    };

    const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
        let data: any = undefined;
        if (editIndex !== undefined) {
            data = importList[editIndex];
        }
        setImportDialogData({
            ...importDialogData,
            manageImportDialog: {
                isOpen: isOpen,
                data: data,
                index: editIndex,
            },
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
    };

    const handleClickMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        index: number
    ) => {
        setImportDialogData({
            ...importDialogData,
            selectedItemIndex: index,
            anchorElMenu: event.currentTarget,
        });
    };

    const handleCloseMenu = () => {
        setImportDialogData({ ...importDialogData, selectedItemIndex: -1, anchorElMenu: null });
    };

    const handleDelete = (index: number) => {
        setImportDialogData({
            ...importDialogData,
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
        confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
            setLoading(true);
            let configuredFirmsList = [...importList];
            const result = await deleteFirmConfiguration(configuredFirmsList[index].id as number);
            if (result?.isSuccess) {
                configuredFirmsList.splice(index, 1);
                setImportList(configuredFirmsList);
                showToast("Deleted successfully", "success");
            } else {
                showToast(result?.message || "Error while deleting configured firm", "error");
            }
            setLoading(false);
        });
    };


    const onSort = async (sortBy) => {
        let search = { ...searchObj };
        if (sortBy === search.sortBy) {
            search.sortOrder = search.sortOrder === "asc" ? "desc" : "asc";
        } else {
            search.sortBy = sortBy;
            search.sortOrder = "asc";
        }
        search.from = 0;
        await getImportedList(search);
    };

    const startImportProcess = (index: number) => {
        setImportDialogData({
            ...importDialogData,
            selectedItemIndex: -1,
            anchorElMenu: null,
        });
        confirmDialog.show("Are you sure", "Do you want to start?", async () => {
            setLoading(true);
            let configuredFirmsList = [...importList];
            const result = await startImportProcessAPI(configuredFirmsList[index].id);
            if (result?.isSuccess) {
                if (result?.isReadOnly) {
                    showToast(result.message, "error");
                    updateStatus(result.isReadOnly);
                } else {
                    showToast("Started successfully", "success");
                    getImportedList();
                }
            } else {
                showToast(result?.message || "Error while starting", "error");
                setLoading(false);
            }
        });
    };

    const TablePaginationActions = (props: TablePaginationActionsProps) => {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className="d-flex">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <ChevronDoubleLeft />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ChevronRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <ChevronDoubleRight />
                </IconButton>
            </div>
        );
    };



    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">Import</Typography>
                </div>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container spacing={2} justify="flex-end">
                                            <Grid item>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => {
                                                        getImportedList();
                                                    }}
                                                    disabled={isReadOnly}
                                                >
                                                    <Refresh /> Refresh
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleAddDialogOpen(true);
                                                    }}
                                                    disabled={isReadOnly}
                                                >
                                                    Upload
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <TableContainer className="users-table-container moz-table-pb">
                                            <Table stickyHeader size="small" className="custom-table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "fileName"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("fileName");
                                                                }}
                                                            >
                                                                File Name
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "firm"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("firm");
                                                                }}
                                                            >
                                                                Firm
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "type"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("type");
                                                                }}
                                                            >
                                                                Type
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "status"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("status");
                                                                }}
                                                            >
                                                                Status
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "error"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("error");
                                                                }}
                                                            >
                                                                Error
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "fileImportedDate"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("fileImportedDate");
                                                                }}
                                                            >
                                                                Imported Date
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TableSortLabel
                                                                active={searchObj.sortBy === "processedDate"}
                                                                direction={searchObj.sortOrder}
                                                                onClick={() => {
                                                                    onSort("processedDate");
                                                                }}
                                                            >
                                                                Processed Date
                                                            </TableSortLabel>
                                                        </StyledTableCell>
                                                        <StyledTableCell width={80}>Actions</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {importList.length > 0 ? (
                                                        importList.map((ele, index: number) => (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell>
                                                                    {ele.fileName || ""}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {ele.lawFirmName || ""}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {FIRM_CONFIGURE_TYPES[ele.type] || ""}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {FIRM_IMPORT_STATUS[ele.status]}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {ele.errorMessage}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {ele.fileImportedDate ? ele.fileImportedDate.split("T")[0] : ""}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {ele.processedDate ? ele.processedDate.split("T")[0] : ""}
                                                                </StyledTableCell>

                                                                <StyledTableCell>
                                                                    {(FIRM_IMPORT_STATUS[ele.status] === FIRM_IMPORT_STATUS.start || FIRM_IMPORT_STATUS[ele.status] === FIRM_IMPORT_STATUS.error) && (
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={(e) => handleClickMenu(e, index)}
                                                                        >
                                                                            <DotsVertical />
                                                                        </IconButton>
                                                                    )}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={8}>
                                                                No details found
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={searchObj.rowsList}
                                            colSpan={5}
                                            component="div"
                                            count={totalResults}
                                            rowsPerPage={searchObj.size}
                                            page={
                                                searchObj.from === 0
                                                    ? 0
                                                    : searchObj.from / searchObj.size
                                            }
                                            SelectProps={{
                                                inputProps: { "aria-label": "rows per page" },
                                                native: true,
                                            }}
                                            onPageChange={(event: any, newPage: number) => {
                                                console.log("event", event);
                                                console.log("newPage", newPage);
                                                let search = { ...searchObj };
                                                search.from =
                                                    newPage === 0
                                                        ? 0
                                                        : (newPage * searchObj.size);
                                                onSearch(search);
                                            }}
                                            onRowsPerPageChange={(event: any) => {
                                                let value = event.target.value;
                                                let search = { ...searchObj };
                                                search.size = value ? Number(value) : 10;
                                                search.from = 0;
                                                onSearch(search);
                                            }}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            <Menu
                id="simple-menu"
                anchorEl={importDialogData.anchorElMenu}
                keepMounted
                open={Boolean(importDialogData.anchorElMenu)}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    onClick={() => {
                        startImportProcess(importDialogData.selectedItemIndex);
                    }}
                    disabled={isReadOnly}
                >
                    {importList[importDialogData.selectedItemIndex] && FIRM_IMPORT_STATUS[importList[importDialogData.selectedItemIndex].status] === FIRM_IMPORT_STATUS.error ? "Restart" : "Start"}
                </MenuItem>
                {/* <MenuItem
                    onClick={() => {
                        handleDelete(importDialogData.selectedItemIndex);
                    }}
                >
                    Delete
                </MenuItem> */}
            </Menu>


            {importDialogData.manageImportDialog.isOpen && (
                <ManageImportDialog data={importDialogData.manageImportDialog.data} handleDialogClose={(res) => {
                    handleAddDialogOpen(false);
                    if (res) {
                        getImportedList();
                    }
                }}
                    updateStatus={updateStatus}
                />
            )}
        </React.Fragment>
    );
};

export default withConfirmDialogContext(ImportList);
