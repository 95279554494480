import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import * as React from "react";
import { IReactSelect, IExpense } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import moment from "moment";
import {
  getAllProfessionalLevels,
  getAllCategories,
  getAllWorkTypes
} from "../../services/TimesheetService";
import {
  getAllApprovedListForExpenses,
  getAllCategoriesForExpenses,
} from "../../services/ExpenseService";
import { ToastContext } from "../common/ToastProvider";
import DatePicker from "react-date-picker";
export interface ManageExpenseDialogProps {
  data?: IExpense;
  handleDialogClose: Function;
}

const ManageExpenseDialog: React.FC<ManageExpenseDialogProps> = ({
  data,
  handleDialogClose,
}) => {
  const [state, setState] = React.useState({
    expense: {
      // approvedBy: "",
      // billingRate: "",
      // categoryCode: "",
      // categoryName: "",
      // detailedDescription: "",
      // professionalLevel: "",
      // reason: "",
      // serviceDate: new Date(),
      // timeSpent: "",
      // feesTotal: "",
      // isEdit: false,
      comments: "",
      resourceName: "",
      classActionWork: "",
      ...data,
      receiptYesNo: data.receiptYesNo ? data.receiptYesNo.toLowerCase() : "",
      classActionWorkIndicator: data?.classActionWorkIndicator === "Y" ? data?.classActionWorkIndicator : "N"
    },
  } as {
    expense: IExpense;
  });
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  const [professionalLevelList, setProfessionalLevels] = React.useState([]);
  const [approversList, setApproversList] = React.useState(
    [] as Array<IReactSelect>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    [] as { label: string; value: string }[]
  );
  const [categoriesList, setCategories] = React.useState([]);

  React.useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await getAllProfessionalLevelsList();
      await getAllCategoriesList();
      await getAllApprovedList();
      await getAllWorkTypesList();
      setLoading(false);
    };
    fetch();
  }, []);

  const getAllApprovedList = async () => {
    const result = await getAllApprovedListForExpenses();
    if (result?.isSuccess) {
      let approversList: Array<IReactSelect> = [];
      if (result?.data) {
        approversList = result.data.map((x) => {
          return {
            label: x.expenseApprovedByName,
            value: x.expenseApprovedByCode,
          };
        });
      }
      if (state.expense.approvedBy) {
        let isTimeEntryAvailable = approversList.find(
          (x) => x.value === state.expense.approvedBy
        );
        if (!isTimeEntryAvailable) {
          setState({
            ...state,
            expense: {
              ...state.expense,
              approvedBy: undefined,
            },
          });
        }
      }
      setApproversList(approversList || []);
    } else {
      showToast(
        result.message || "Error while fetching professional levels",
        "error"
      );
    }
  };

  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(data);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };

  const getAllProfessionalLevelsList = async () => {
    const result = await getAllProfessionalLevels();
    if (result?.isSuccess) {
      setProfessionalLevels(result?.data || []);
    } else {
      showToast(
        result.message || "Error while fetching professional levels",
        "error"
      );
    }
  };
  const getAllCategoriesList = async () => {
    const result = await getAllCategoriesForExpenses();
    if (result?.isSuccess) {
      setCategories(result?.data || []);
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
  };

  const closeAddDialog = (data?: IExpense) => {
    handleDialogClose(data);
  };

  return (
    <React.Fragment>
      <CustomDrawer title={"Manage Expense"} onClose={() => closeAddDialog()}>
        <section className="p-16">
          <Formik
            enableReinitialize
            initialValues={state.expense}
            validate={(values) => {
              let errors: FormikErrors<IExpense> = {};

              if (!values.expenseCategoryCode) {
                errors.expenseCategoryCode = "Required";
              }
              if (!values.detailedDescription) {
                errors.detailedDescription = "Required";
              }
              if (!values.expenseApprovedBy) {
                errors.expenseApprovedBy = "Required";
              }
              if (!values.expenseAmount) {
                errors.expenseAmount = "Required";
              }
              if (!values.receiptYesNo) {
                errors.receiptYesNo = "Required";
              }
              // if (!values.receiptExplain) {
              //   errors.receiptExplain = "Required";
              // }
              if (!values.comments) {
                errors.comments = "Required";
              }
              // if (!values.classActionWork) {
              //   errors.classActionWork = "Required";
              // }
              if (!values.classActionWorkIndicator) {
                errors.classActionWorkIndicator = "Required";
              }
              return errors;
            }}
            onSubmit={async (values: IExpense, { setSubmitting }) => {
              const obj = { ...values };
              let previousData = { ...data };
              let reason = obj.comments || "";
              delete obj.comments;
              delete previousData.comments;
              obj.isEdit = false;
              let valuesToCompare = [
                "expenseDate",
                "expenseCategoryCode",
                "detailedDescription",
                "expenseApprovedBy",
                "expenseAmount",
                "receiptYesNo",
                "receiptExplain",
                "classActionWorkIndicator",
                // "classActionWork"
              ];
              valuesToCompare.forEach((prop) => {
                if (obj[prop] !== previousData[prop]) {
                  obj.isEdit = true;
                }
              });
              obj.comments = reason;
              closeAddDialog(obj);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth className="position-relative">
                      {/* <InputLabel className="custom-react-select-label date-label" htmlFor="role">
                        Expense Date
                      </InputLabel>
                      <DatePicker
                        className={errors.expenseDate && touched.expenseDate ? 'react-date-error' : ''}
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        value={values.expenseDate ? moment(values.expenseDate).toDate() : undefined}
                        onChange={(newValue: any) => {
                          setFieldValue("expenseDate", newValue);
                        }}
                      />
                      {errors.expenseDate && touched.expenseDate && (
                        <FormHelperText className="text-danger">{errors.expenseDate}</FormHelperText>
                      )} */}
                      <InputLabel className="custom-react-select-label date-label" htmlFor="role">
                        Expense Date
                      </InputLabel>
                      <TextField
                        id="expenseDate"
                        name="expenseDate"
                        label="Expense Date"
                        type="date"
                        onChange={handleChange}
                        error={
                          errors.expenseDate && touched.expenseDate
                            ? true
                            : false
                        }
                        helperText={
                          errors.expenseDate &&
                          touched.expenseDate &&
                          errors.expenseDate
                        }
                        onBlur={handleBlur}
                        value={moment(values.expenseDate).format("YYYY-MM-DD")}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel className="select-label" htmlFor="role">
                        Category
                      </InputLabel>
                      <Select
                        value={values.expenseCategoryCode}
                        name="expenseCategoryCode"
                        error={
                          errors && errors.expenseCategoryCode ? true : false
                        }
                        onChange={(event: any) => {
                          let value = event.target.value;
                          setFieldValue("expenseCategoryCode", value);
                          setFieldValue(
                            "categoryName",
                            categoriesList.find(
                              (ele) => ele.expenseCategoryCode === value
                            )?.expenseCategoryName || ""
                          );
                        }}
                        onBlur={handleBlur}
                      >
                        {categoriesList.map((ele, index) => (
                          <MenuItem value={ele.expenseCategoryCode} key={index}>
                            {ele.expenseCategoryName}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors && errors.expenseCategoryCode && (
                        <FormHelperText className="text-danger">
                          {errors.expenseCategoryCode}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id="description"
                        name="detailedDescription"
                        label="Detailed Description"
                        onChange={handleChange}
                        error={
                          errors.detailedDescription &&
                            touched.detailedDescription
                            ? true
                            : false
                        }
                        helperText={
                          errors.detailedDescription &&
                          touched.detailedDescription &&
                          errors.detailedDescription
                        }
                        onBlur={handleBlur}
                        value={values.detailedDescription}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel className="select-label" htmlFor="role">
                        Approved By
                      </InputLabel>
                      <Select
                        value={values.expenseApprovedBy || ""}
                        name="expenseApprovedBy"
                        error={
                          errors && errors.expenseApprovedBy ? true : false
                        }
                        onChange={(event: any) => {
                          let value = event.target.value;
                          setFieldValue("expenseApprovedBy", value);
                        }}
                        onBlur={handleBlur}
                      >
                        {approversList.map((ele, index) => (
                          <MenuItem
                            value={ele.value}
                            key={`approved_by_${index}`}
                          >
                            {ele.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors && errors.expenseApprovedBy && (
                        <FormHelperText className="text-danger">
                          {errors.expenseApprovedBy}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id="expenseAmount"
                        name="expenseAmount"
                        label="Expense Amount"
                        onChange={handleChange}
                        error={
                          errors.expenseAmount && touched.expenseAmount
                            ? true
                            : false
                        }
                        helperText={
                          errors.expenseAmount &&
                          touched.expenseAmount &&
                          errors.expenseAmount
                        }
                        onBlur={handleBlur}
                        value={values.expenseAmount}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl
                      component="fieldset"
                      className="ml-8"
                      error={
                        errors.receiptYesNo && touched.receiptYesNo
                          ? true
                          : false
                      }
                    >
                      <FormLabel component="legend">Receipt provided</FormLabel>
                      <RadioGroup
                        row
                        name="receiptYesNo"
                        value={values.receiptYesNo}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="unknown"
                          control={<Radio color="primary" />}
                          label="Unknown"
                        />
                      </RadioGroup>
                      {errors.receiptYesNo && touched.receiptYesNo && (
                        <FormHelperText>{errors.receiptYesNo}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id="receiptExplain"
                        name="receiptExplain"
                        label="Receipt Description"
                        multiline
                        rows={4}
                        onChange={handleChange}
                        error={
                          errors.receiptExplain && touched.receiptExplain
                            ? true
                            : false
                        }
                        helperText={
                          errors.receiptExplain &&
                          touched.receiptExplain &&
                          errors.receiptExplain
                        }
                        onBlur={handleBlur}
                        value={values.receiptExplain}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel className="select-label" htmlFor="role">
                        Class Action Work Indicator
                      </InputLabel>
                      <Select
                        value={values.classActionWorkIndicator || ""}
                        name="Class Action Work Indicator"
                        error={errors && errors.classActionWorkIndicator ? true : false}
                        onChange={(event: any) => {
                          let value = event.target.value;
                          setFieldValue("classActionWorkIndicator", value);
                        }}
                        onBlur={handleBlur}
                      >
                        {initialWorkTypes.map((ele, index) => (
                          <MenuItem
                            value={ele.value}
                            key={`workTypeCode${index}`}
                          >
                            {ele.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors && errors.classActionWorkIndicator && (
                        <FormHelperText className="text-danger">
                          {errors.classActionWorkIndicator}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id="classActionWork"
                        name="classActionWork"
                        label="Class Action Work"
                        onChange={handleChange}
                        error={
                          errors.classActionWork  && touched.classActionWork  ? true : false
                        }
                        helperText={
                          errors.classActionWork  && touched.classActionWork  && errors.classActionWork 
                        }
                        onBlur={handleBlur}
                        value={values.classActionWork }
                      />
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id="reason"
                        name="comments"
                        label="Reason"
                        multiline
                        rows={4}
                        onChange={handleChange}
                        error={
                          errors.comments && touched.comments ? true : false
                        }
                        helperText={
                          errors.comments && touched.comments && errors.comments
                        }
                        onBlur={handleBlur}
                        value={values.comments}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item>
                        <Button variant="text" onClick={() => closeAddDialog()}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ManageExpenseDialog;
