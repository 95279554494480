import { IRequestAccess, IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";

export async function requestAccessAPI(
  data: IRequestAccess
): Promise<IStandardAPIResponse<IRequestAccess[]>> {
  try {
    let res = await httpClient<IRequestAccess[]>(
      `access-request`,
      "POST",
      data
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
