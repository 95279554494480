import * as React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

interface ILoadingProps {
  message?: string;
}

export const Loading: React.FC<ILoadingProps> = ({ message }) => {
  return (
    <Dialog
      disableBackdropClick={true}
      open={true}
      className='loading-dialog'
      aria-labelledby='loading'>
      <DialogContent>
        <div className='text-center'>
          <CircularProgress />
        </div>
        <Typography variant="subtitle2" className='loading my-2'>{message || 'Loading Data. Please stand by..'}</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default Loading;
