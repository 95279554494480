import {
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import * as React from "react";
import CustomDrawer from "../common/CustomDrawer";
import "./resources.css"
export interface ConfirmViewAliasDialogProps {
    handleDialogClose: Function;
}

const ConfirmViewAliasDialog: React.FC<ConfirmViewAliasDialogProps> = ({
    handleDialogClose,
}) => {

    return (
        <React.Fragment>
            <CustomDrawer
                title={"Are you sure"}
                onClose={() => handleDialogClose(undefined)}
            >
                <section className="p-16 view-confirm-dialog">
                    <Grid item xs={12} className="confirm-header">
                        <Typography variant="subtitle1">Do you want to update the details first?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item>
                                <Button variant="text" onClick={() => handleDialogClose(undefined)}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={() => handleDialogClose(false)}>
                                    No
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleDialogClose(true)}
                                >
                                    Yes
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
            </CustomDrawer>
        </React.Fragment>
    );
};

export default ConfirmViewAliasDialog;
