import * as React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

interface AcceptableUsePolicyMainProps {}

const AcceptableUsePolicyMain: React.FunctionComponent<
  AcceptableUsePolicyMainProps
> = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <section className="p-16">
        <main className={classes.main}>
          <img src="new-logo.png" alt="" className={classes.logo} />
          <Typography variant="h4" align="center">
            Acceptable Use Policy (“AUP”)
          </Typography>
        </main>
        <br />
        <Typography align="center">Locus Solutions, Inc</Typography>
        <br />
        <Grid container justifyContent="center">
          <Grid item xs={11} sm={10} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: 700 }}>
                  Introduction
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  Locus Solutions, Inc. (“Locus”) provides administrative
                  support services and solutions to the legal industry. Locus
                  often works with its clients and courts to facilitate the
                  collection of confidential information regarding a specific
                  legal matter. The data collected by Locus is generally
                  obtained from the client, a party to the settlement, the
                  claimant,or an authorized representative of the claimant.
                  Locus utilizes LegalFees.app application (“LegalFees.app”), a
                  proprietary application developed and owned by Locus to
                  collect, organize, store, and protect information submitted in
                  connection with Court approved cases for which Locus is
                  engaged. The data stored in LegalFees.app may be sensitive,
                  confidential, Personally Identifiable Information (“PII”)
                  and/or Protected Health Information (“PHI”). Locus is at all
                  times committed to complying with all applicable State and
                  Federal laws governing the collection, use, protection, and
                  destruction of this information. Locus’s AUP and LegalFees.app
                  Privacy Notice are designed to help achieve these goals by
                  ensuring that users of LegalFees.app take responsibility for
                  the protection and appropriate use of information in
                  accordance with such policies.
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  By using the LegalFees.app, users and entities on whose behalf
                  users are acting (collectively “Users”) agree to comply with
                  this AUP and all such entities shall be responsible for
                  assuring compliance with the AUP by their respective
                  employees, agents, independent contractors and the like. Locus
                  reserves the right to change or modify the terms of this AUP
                  at any time, effective when posted on the LegalFees.app
                  website at. Users’ usage of the LegalFees.app after changes to
                  this AUP are posted shall constitute acceptance by such Users
                  of any changed or additional terms even if Users have not
                  reviewed the changes.
                </Typography>
                <br />
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: 700 }}>
                  Purpose
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  The purpose of this AUP is to provide guidance for the
                  acceptable use of the LegalFees.app. Users of the
                  LegalFees.app, including Locus employees, independent
                  contractors, and service providers, are granted different
                  levels of access to LegalFees.app and datasets within the
                  application depending on their roles within the particular
                  case on which they are assigned. Inappropriate use exposes
                  Locus to risks such as data loss, data corruption, unplanned
                  service outage, unauthorized access to sensitive data, and
                  potential legal issues. As such, all persons who access
                  LegalFees.app must agree to the terms set forth in this AUP
                  and Locus’s LegalFees.app Privacy Notice.
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  Scope of AUP Use of LegalFees.app constitutes Users’
                  acceptance of and agreement to be bound by the terms of this
                  AUP.
                </Typography>
                <br />
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 700 }}
                  className="mb-1"
                >
                  User Accounts and Access Rights
                </Typography>
                <Box component="ul">
                  <Typography variant="subtitle1" component="li">
                    Users are responsible for the security of data, accounts,
                    and systems under their control, including their credentials
                    for accessing LegalFees.app.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users shall keep their usernames and passwords secure and
                    shall not share account or password information with anyone.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users agree that they will immediately notify the
                    LegalFees.app system administrator pursuant to the Incident
                    Reporting and Notification section of this AUP if login,
                    password, or account information becomes compromised or if
                    there is a suspicion that LegalFees.app data may have been
                    compromised in any way.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Providing access to another individual, either deliberately
                    or through failure to secure one’s credentials, is a
                    violation of this AUP which can result in the suspension of
                    the right to access or use LegalFees.app (See Restrictions
                    on Use section below).
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Locus’s authorization to access data within LegalFees.app
                    grants Users a limited, non-exclusive, non-transferable,
                    non-assignable, and terminable right to edit, modify,
                    update, and use the data located in LegalFees.app in
                    accordance with this AUP and Locus’s LegalFees.app Privacy
                    Notice solely for the purpose of evaluation/analysis of the
                    data, internal testing, and use in connection with an
                    approved case. Users are granted access only to the datasets
                    applicable to the case on which they are engaged.No access
                    or rights are granted for any other purpose.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users should immediately contact the LegalFees.app system
                    administrator pursuant to the Incident Reporting and
                    Notification section of this AUP if Users believe that
                    access has been granted to datasets outside of their
                    engagement needs.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Locus will not ask for your LegalFees.app access credentials
                    (username, password, etc.) in an unsolicited phone call,
                    email, or other form of communication.
                  </Typography>
                </Box>
                <br />
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 700 }}
                  className="mb-1"
                >
                  Prohibited Uses of LegalFees.app
                </Typography>
                <Typography variant="subtitle1">
                  The following activities are prohibited:
                </Typography>
                <Box component="ul">
                  <Typography variant="subtitle1" component="li">
                    Engaging in activities that are illegal under local, state,
                    federal, or international law.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Engaging in activities or taking action that is unlawful,
                    harmful to, or in any way interferes with the use of
                    LegalFees.app or the data within LegalFees.app.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Unauthorized copying of copyrighted materials including
                    digitization and distribution of photographs from magazines,
                    books, or other copyrighted software for which Locus does
                    not have an active license.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Intentionally introducing malicious programs into the
                    network or server (e.g., viruses, worms, Trojan horses,
                    spyware, ad ware, e-mail bombs, etc.).
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Hacking, attacking, gaining access to, breaching,
                    circumventing, or testing the vulnerability of the user
                    authentication or security of LegalFees.app without express
                    authorization of Locus.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Unauthorized monitoring, scanning, or probing of the network
                    or system or any other action aimed at the unauthorized
                    interception or harvesting of any data contained in
                    LegalFees.app.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Providing guidance, information or assistance with respect
                    to causing damage or security breach to Locus’s network or
                    to LegalFees.app, or to the network of any other network.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Revealing LegalFees.app access credentials to others or
                    allowing unauthorized use of LegalFees.app access
                    credentials by others.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Effecting security breaches or disruptions of network
                    communications. Security breaches include accessing data of
                    which Users are not an intended recipient or logging into a
                    server or account that Users are not expressly authorized to
                    access, unless these duties are within the scope of regular
                    duties. For purposes of this section, “disruption” includes,
                    but is not limited to, degrading the performance, depriving
                    authorized access, disabling or degrading security
                    configurations.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Unauthorized disclosure of non-anonymized data which
                    breaches the privacy rights of individuals and/or
                    organizations identified within the data by making public
                    PII or PHI other than for the use intended or approved by
                    the supervising court or other authorized client that owns
                    or controls the data.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Attempting to reverse engineer, decrypt, de-anonymize,
                    derive or otherwise re-identify anonymized information.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Distributing, disclosing, transferring, or otherwise making
                    available the datasets to any person other than those
                    employed by the same service provider who have also accepted
                    the terms of this AUP and are assisting or collaborating
                    with Users.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Failing to take reasonable security precautions to help
                    prevent violations of this AUP.
                  </Typography>
                </Box>
                <br />
                <Typography variant="subtitle1">
                  The listed activities are not exhaustive, but illustrate the
                  types of activities which fall into the category of
                  unacceptable use. To the extent Users are unsure whether a
                  particular use is acceptable, Users should contact the
                  LegalFees.app system administrator pursuant to the Incident
                  Reporting and Notification section of this AUP for
                  clarification and authorization.
                </Typography>
                <br />
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: 700 }}>
                  Restrictions on Use
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  Locus has the authority and reserves the right, in its sole
                  discretion, to refuse or limit access to datasets or
                  discontinue further access and use to Users at any time and
                  for any reason. Users should send a message to the
                  LegalFees.app system administrator pursuant to the Incident
                  Reporting and Notification section of this AUP if Users feel
                  that access has been inappropriately denied.
                </Typography>
                <br />
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 700 }}
                  className="mb-1"
                >
                  Responsibilities of LegalFees.app Users
                </Typography>
                <Box component="ul">
                  <Typography variant="subtitle1" component="li">
                    Users acknowledge that they have reviewed and understand
                    this AUP and Locus’s LegalFees.app Privacy Notice which
                    governs the collection, use, disclosure, and destruction of
                    confidential information, PHI and PII in LegalFees.app.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    By using LegalFees.app, Users agree to comply and be bound
                    by the terms of this AUP and Locus’s LegalFees.app Privacy
                    Notice.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users agree that they shall not at any time during their
                    employment, contract, association, or appointment with Locus
                    use information in LegalFees.app except as may be required
                    in the course and scope of their duties and responsibilities
                    provided such use is in accordance with applicable law, this
                    AUP, and Locus’s LegalFees.app Privacy Notice.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users agree that they will not use, access, or attempt to
                    access information in LegalFees.app after the termination of
                    their employment, contract, association, or appointment with
                    Locus.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    The failure of Locus to insist upon strict compliance with
                    any provision of, or to assert any right under, this AUP
                    shall not be deemed to be a waiver of such provision or
                    right or of any other provision or right under this AUP. Any
                    violation of this AUP or Locus’s LegalFees.app Privacy
                    Notice, may result in the immediate suspension or
                    termination of Users’ access, termination of the service
                    agreement with the service provider, and/or other action
                    entitled by law such as injunctive or equitable relief.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users agree that by utilizing LegalFees.app, Users’ rights
                    and obligations, as well as the rights and obligations of
                    Locus, shall be governed, at Locus’s sole discretion, by the
                    laws of the State of California
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users agree that they will notify Locus immediately of any
                    known or suspected violation of this AUP or Locus’s
                    LegalFees.app Privacy Notice.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users understand and agree that their obligations outlined
                    herein will continue even after termination of employment,
                    contract, association, or appointment with Locus.
                  </Typography>
                </Box>
                <br />
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: 700 }}>
                  Limitation of Liability
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  In no event shall Locus be liable to Users or any third party
                  for any direct, indirect, incidental, consequential, special
                  or punitive damages (including, but not limited to, damages
                  arising from the disallowance of a potential claim against a
                  client of Locus or damages to business reputation, lost
                  business, or lost profits), whether foreseeable or
                  unforeseeable, and however caused, even if Locus is advised of
                  the possibility of such damages, as a result of using
                  LegalFees.app and/or the information within LegalFees.app.
                  Users have the sole responsibility for the adequate protection
                  and backup of data and equipment used in connection with
                  LegalFees.app and Users will not make a claim against Locus
                  for lost data, re-run time, inaccurate output, work delays, or
                  lost profits resulting from the use of materials provided on
                  LegalFees.app. All Users agree to hold Locus harmless from,
                  and agree not to sue Locus for, any claims based on or related
                  to the use of LegalFees.app.
                </Typography>
                <br />
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: 700 }}>
                  Incident Reporting and Notification
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  Users shall notify the LegalFees.app system administrator at
                  TEM-Support@LocusSolution.com of all relevant details if:
                </Typography>
                <Box component="ul">
                  <Typography variant="subtitle1" component="li">
                    Logon or account information is compromised.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Confidentiality or privacy of data is or is believed to be
                    compromised.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users receive any legal, investigatory, or other government
                    demand to reverse engineer, decrypt, de-anonymize, or
                    disclose any data in LegalFees.app
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Access has been granted to datasets outside of their
                    engagement needs.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    Users are unsure whether a particular use is acceptable
                    under this AUP.
                  </Typography>
                  <Typography variant="subtitle1" component="li">
                    User’s access has been improperly denied.
                  </Typography>
                </Box>
                <br />
                <Typography variant="subtitle1">
                  Any notification that Locus sends to its Users pursuant to
                  this AUP will be sent via e-mail to the e-mail address on file
                  with Locus, or may be in writing to Users addresses of record.
                  It is the responsibility of the Users to promptly notify Locus
                  of any change of contact information
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  By using LegalFees.app, Users agree to comply with this AUP
                  and Locus’s LegalFees.app Privacy Notice.
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  Last Updated: September 6, 2023
                </Typography>
                <br />
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 320,
    margin: "16px 0",
  },
});

export default AcceptableUsePolicyMain;
