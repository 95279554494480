import {
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import CustomDrawer from "../common/CustomDrawer";
import moment from "moment";

export interface ViewOnSubmissionDialogProps {
  data?: any;
  handleDialogClose: Function;
}

const ViewOnSubmissionDialog: React.FC<ViewOnSubmissionDialogProps> = ({
  data,
  handleDialogClose,
}) => {
  const [state, setState] = React.useState({
    isLoading: false,
    obj: { ...data },
  } as {
    obj: { type: string; case: string; firm: string; period: Date };
    isLoading: boolean;
  });

  const closeDialog = () => {
    handleDialogClose();
  };

  return (
    <React.Fragment>
      <CustomDrawer
        title={""}
        fullWidth={false}
        onClose={() => closeDialog()}
      >
        <section className="p-16">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {state.obj.type==="timesheet"?"Timesheet":"Expense"} submission file has been generated for the following:
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Case :{" "}
                <Typography variant="h6" component="span">{state.obj.case || ""}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Firm :{" "}
                <Typography variant="h6" component="span">{state.obj.firm || ""}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Month :{" "}
                <Typography variant="h6" component="span">{state.obj.period? moment(state.obj.period).format("MMMM YYYY") : ""}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
                <br/>
              <Typography variant="h5">
                Please download the file
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} justify="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => closeDialog()}
                  >
                    Ok
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ViewOnSubmissionDialog;
