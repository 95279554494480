import * as React from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { ICmoProtocolTimeFilter } from "./CmoProtocolMain";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";
// import { ITimeFilter } from "../../vm";

interface IProtocolTypeCardProps {
    handleAddFilterDialogOpen: Function;
    protocolList: ICmoProtocolTimeFilter[];
    selectedProtocols: any;
    type: "time-keeping-protocol" | "client-specific-work" | "deposition" | "periodic-committee-conference-calls" | "periodic-status-conferences" | "identification-and-work-up-of-experts" | "attendance-at-seminars" | "discovery-and-document-review-or-production" | "review-of-filing-and-orders" | "emails-and-correspondences" | "travel-time";
    handleCheckBoxChange: Function;
    title: string;
    handleCmoTextDialog: Function;
}

const ProtocolTypeCard: React.FC<IProtocolTypeCardProps> = ({ handleAddFilterDialogOpen, protocolList, selectedProtocols, type, handleCheckBoxChange, title, handleCmoTextDialog }) => {
    
    const { isReadOnly } = React.useContext(IsReadOnlyContext);
    
    return (
        <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} style={{ padding: 16, marginTop: 16 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            handleCmoTextDialog(true, type);
                                        }}
                                    >
                                        Show CMO Text
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isReadOnly}
                                        onClick={() => {
                                            handleAddFilterDialogOpen(type);
                                        }}
                                    >
                                        Add Saved Filter
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            handleAddFilterDialogOpen(type, true);
                                        }}
                                        disabled={protocolList.length === 0 || isReadOnly}
                                    >
                                        Remove Saved Filter
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1}>
                        {protocolList.map((d, index) => (
                            <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={selectedProtocols[type] && selectedProtocols[type].find((ele) => ele.timeFilterId === d.timeFilterId) ? true : false}
                                            onChange={() =>
                                                handleCheckBoxChange(d, type)
                                            }
                                        />
                                    }
                                    label={d.timeFilterName}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default ProtocolTypeCard;

