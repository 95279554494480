import {
    Button,
    FormControl,
    Grid,
    TextField,
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import * as React from "react";
import { ITimeCategoryManagement } from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import { createATimeCategory, updateATimeCategory } from "../../services/TimesheetService";
import CustomDialogDrawer from "../common/CustomDialogDrawer";

export interface AddTimeCategoryDialogProps {
    data: ITimeCategoryManagement;
    handleDialogClose: Function;
    updateStatus: Function;
}

const AddTimeCategoryDialog: React.FC<AddTimeCategoryDialogProps> = ({
    data,
    handleDialogClose,
    updateStatus
}) => {
    const [state, setState] = React.useState({
        timeCategory: {
            timeCategoryName: "",
            timeCategoryCode: "",
            timeCategorySort: "",
            ...data
        },
    } as {
        timeCategory: ITimeCategoryManagement;
    });
    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);

    const closeAddDialog = (data?: ITimeCategoryManagement) => {
        handleDialogClose(data);
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                variant="small"
                title={state.timeCategory.id ? "Edit Time Category" : "Create Time Category"}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <section style={{ marginTop: -16 }}>
                    <Formik
                        enableReinitialize
                        initialValues={state.timeCategory}
                        validate={(values) => {
                            let errors: FormikErrors<ITimeCategoryManagement> = {};
                            if (!values.timeCategoryName) {
                                errors.timeCategoryName = "Required";
                            }
                            if (!values.timeCategoryCode) {
                                errors.timeCategoryCode = "Required";
                            }
                            return errors;
                        }}
                        onSubmit={async (values: ITimeCategoryManagement, { setSubmitting }) => {
                            setLoading(true);
                            let obj = { ...values };
                            let res;
                            if (obj.id) {
                                res = await updateATimeCategory(obj);
                            } else {
                                res = await createATimeCategory(obj);
                            }
                            setLoading(false);
                            if (res.isSuccess) {
                                if (res?.isReadOnly) {
                                    showToast(res.message, "error");
                                    closeAddDialog();
                                    updateStatus(res.isReadOnly);
                                } else {
                                    closeAddDialog(res.data);
                                    showToast(
                                        `Time category ${values.id ? "updated" : "created"} successfully`,
                                        "success"
                                    );
                                }
                            } else {
                                showToast(
                                    res.message
                                        ? res.message
                                        : `Error while ${values.id ? "updating" : "creating"} time category`,
                                    "error"
                                );
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="timeCategoryName"
                                                name="timeCategoryName"
                                                label="Name"
                                                onChange={handleChange}
                                                error={
                                                    errors.timeCategoryName && touched.timeCategoryName ? true : false
                                                }
                                                helperText={
                                                    errors.timeCategoryName && touched.timeCategoryName && errors.timeCategoryName
                                                }
                                                onBlur={handleBlur}
                                                value={values.timeCategoryName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="timeCategoryCode"
                                                name="timeCategoryCode"
                                                label="Code"
                                                onChange={handleChange}
                                                error={
                                                    errors.timeCategoryCode && touched.timeCategoryCode ? true : false
                                                }
                                                helperText={
                                                    errors.timeCategoryCode && touched.timeCategoryCode && errors.timeCategoryCode
                                                }
                                                onBlur={handleBlur}
                                                value={values.timeCategoryCode}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} justify="flex-end">
                                            <Grid item>
                                                <Button variant="text" onClick={() => closeAddDialog()}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {values.id ? "Update" : "Add"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </section>
            </CustomDialogDrawer>
        </React.Fragment>
    );
};

export default AddTimeCategoryDialog;
