import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { DotsVertical, Eye } from "mdi-material-ui";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import { IBusinessRule } from "../../vm";

export interface BusinessRulesMainProps { }

const BusinessRulesMain: React.FC<BusinessRulesMainProps> = () => {
  const history = useHistory();

  const [state, setState] = React.useState({
    rules: [
      {
        name: "Time Entry Description",
        description: "Description must be greater than 50 characters",
        type: "User Defined",
        isEnabled: true,
      },
      {
        name: "Time Entry Description",
        description:
          "For each timesheet row there should be no more than one timesheet entry code or more than one type of description for that category",
        type: "Pre Defined",
        isEnabled: true,
      },
      {
        name: "Billing Rate Violation",
        description:
          "Standard billing rates must be enforced. If a billing rate is entered that falls outside of expected rates then the timesheet record is fagged for verification",
        type: "Pre Defined",
        isEnabled: false,
      },
      {
        name: "Duplicate Entries",
        description:
          "Firm timesheets will be scanned to identify if there are any entries or billings that occur for the same date for similar descriptions",
        type: "Pre Defined",
        isEnabled: true,
      },
    ],
    selectedItemIndex: -1,
    anchorElMenu: null,
  } as { rules: IBusinessRule[]; selectedItemIndex: number; anchorElMenu: any });

  const handleCheck = (index: number) => {
    let rules = [...state.rules];
    rules[index].isEnabled = !rules[index].isEnabled;

    setState({ ...state, rules });
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setState({
      ...state,
      selectedItemIndex: index,
      anchorElMenu: event.currentTarget,
    });
  };

  const handleCloseMenu = () => {
    setState({ ...state, selectedItemIndex: -1, anchorElMenu: null });
  };

  return (
    <React.Fragment>
      <section className="p-16">
        <div className="text-center">
          <Typography variant="h5">Business Rules</Typography>
        </div>
        <br />
        <TableContainer className="moz-table-pb">
          <Table size="small" className="custom-table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Enabled</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.rules.length > 0 ? (
                state.rules.map((ele, index: number) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{ele.name || ""}</StyledTableCell>
                    <StyledTableCell>{ele.description || ""}</StyledTableCell>
                    <StyledTableCell>{ele.type || ""}</StyledTableCell>
                    <StyledTableCell>
                      <Switch
                        checked={ele.isEnabled}
                        onChange={() => handleCheck(index)}
                        name="isEnabled"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Grid container spacing={1} className="d-flex flex-row">
                        <Grid item>
                          <IconButton size="small">
                            <Eye />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton size="small" onClick={(e) => handleClickMenu(e, index)}>
                            <DotsVertical />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    {" "}
                    No rules found{" "}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
      <Menu
        id="simple-menu"
        anchorEl={state.anchorElMenu}
        keepMounted
        open={Boolean(state.anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenu();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default BusinessRulesMain;
