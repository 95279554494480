import React from "react";
import { Grid, Button } from "@material-ui/core";
import type { RuleGroupType, Field, RuleType } from "react-querybuilder";
import { QueryBuilder, defaultOperators, formatQuery } from "react-querybuilder";
// import { fields } from "./fields";
import "react-querybuilder/dist/query-builder.css";
import "./styles.css";
import Loading from "../Loading";

// import { createTheme } from "@material-ui/core/styles";
// import { ThemeProvider } from "@material-ui/core";
// import { QueryBuilderMaterial } from "@react-querybuilder/material";
// import {
//     Button,
//     Checkbox,
//     FormControl,
//     FormControlLabel,
//     Input,
//     ListSubheader,
//     MenuItem,
//     Radio,
//     RadioGroup,
//     Select,
//     Switch,
//     TextareaAutosize,
// } from "@material-ui/core";
// import { DragIndicator } from "@material-ui/icons";

// const muiTheme = createTheme();

// const muiComponents = {
//     Button,
//     Checkbox,
//     DragIndicator,
//     FormControl,
//     FormControlLabel,
//     Input,
//     ListSubheader,
//     MenuItem,
//     Radio,
//     RadioGroup,
//     Select,
//     Switch,
//     TextareaAutosize,
// };

const validator = (r: RuleType) => !!r.value;

const fields: Field[] = [
    {
        name: 'descriptionSearchFilters',
        label: 'Description',
        placeholder: 'Enter description',
        operators: defaultOperators.filter((op) => ["contains", "doesNotContain"].includes(op.name)),
        validator,
        defaultOperator: "contains"
    }
];

const initialQuery: RuleGroupType = { combinator: "and", rules: [] };

interface IReactQueryBuilderProps {
    onApplyQuery: Function;
    data?: RuleGroupType;
    onClearQueryBuilder: Function;
}

const ReactQueryBuilder: React.FC<IReactQueryBuilderProps> = ({ onApplyQuery, data, onClearQueryBuilder }) => {
    const [query, setQuery] = React.useState(data || initialQuery);
    const [isLoading, setLoading] = React.useState(false as boolean);

    const onSave = () => {
        // to do saved to backend
        const sqlQuery = formatQuery(query, 'sql');
        onApplyQuery({ jsonData: query, sqlQuery });
    };

    console.log("Query SQL", formatQuery(query, 'sql'));
    console.log("Query JSON", query);
    console.log("Query Mongo", formatQuery(query, 'mongodb'));

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <QueryBuilder
                        fields={fields}
                        query={query}
                        onQueryChange={(q) => setQuery(q)}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2} justify="flex-end">
                        {query.rules.length > 0 && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    onClick={() => onClearQueryBuilder()}
                                    color="primary"
                                >
                                    Clear
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button variant="contained"
                                onClick={() => onSave()}
                                color="primary"
                                disabled={query.rules.length === 0}
                            >
                                Save
                            </Button>
                        </Grid>
                        {/* <Grid item>
                            <Button variant="contained" color="primary" onClick={() => onSave(true)}>
                                Save & Run
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ReactQueryBuilder;
