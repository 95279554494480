import {
  Button,
  Grid,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {
  IFilter,
  IFirmDetails,
  IKeyValue,
  IPerson,
  IExpenseSubmitCourt,
  ICustomDialogData,
} from "../../vm";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
} from "mdi-material-ui";
import { useHistory, useLocation } from "react-router-dom";
import Filter from "../common/Filter";
import { ToastContext } from "../common/ToastProvider";
import { getAllCategories, getAllWorkTypes } from "../../services/TimesheetService";
import {
  getExpensesToSubmitCourt,
  getExpensesFiltersCount,
  submitExpenseToCourt,
  submitGrantedNotGrantedExpenseToCourt,
  getAllCategoriesForExpenses,
  generateExportForExpenseSubmitForCourt,
} from "../../services/ExpenseService";
import Loading from "../common/Loading";
import { FirmContext } from "../common/FirmProvider";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import {
  StyledTableCell,
  StyledTableRow,
  currencyFormat,
  getCeilValueForTotalCount,
  getFileName,
  getSubDomain,
  getToken,
  isUserAllowed,
} from "../../services/UtilService";
import { TIMESHEET_STATUS, GRANTED_NOT_GRANTED_STATUS, TIME_ENTRY_KEY_WORDS, API_URL, RECEIPT_TYPES, styles, MAX_PAGINATION_OPTIONS } from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import moment from "moment";
import LogDialog from "../expenses/LogDialog";
import ExportDialog from "../common/ExportDialog";
import ToggleFilterSection from "../dashboard/ToggleFilter";

export interface ExpenseGrantedNotGrantedProps { }

const ExpenseGrantedNotGranted: React.FC<ExpenseGrantedNotGrantedProps> = () => {
  const location = useLocation();
  const history = useHistory();
  let searchObj: any = {};
  if (location.state) {
    let searchState = { ...(location.state as any) };
    if (searchState.status) {
      searchObj.statuses = [searchState.status];
    }
    if (searchState.startDate) {
      searchObj.startDate = searchState.startDate;
    }
    if (searchState.endDate) {
      searchObj.endDate = searchState.endDate;
    }
    if (searchState.selectedFirm?.length > 0) {
      searchObj.firms = searchState.selectedFirm;
    }
    if (searchState.professionalLevel?.length > 0) {
      searchObj.professionalLevel = searchState.professionalLevel;
    }
    if (searchState.categoryCode?.length > 0) {
      searchObj.categoryCode = searchState.categoryCode;
    }
    if (searchState.workType?.length > 0) {
      searchObj.workType = searchState.workType;
    }
    if (searchState.keyword?.length > 0) {
      searchObj.keyword = searchState.keyword;
    }
    if (searchState.min) {
      searchObj.min = searchState.min;
    }
    if (searchState.max) {
      searchObj.max = searchState.max;
    }
  }
  const [state, setState] = React.useState({
    expensesList: [],
    search: {
      from: 0,
      size: 10,
      statuses: searchObj?.statuses ? searchObj.statuses : [],
      firms: searchObj.firms ? searchObj.firms : [],
      persons: [],
      startDate: searchObj.startDate ? searchObj.startDate : undefined,
      endDate: searchObj.endDate ? searchObj.endDate : undefined,
      sortBy: "",
      sortOrder: "asc",
      keyword: searchObj?.keyword || [],
      min: searchObj.min || "",
      max: searchObj.max || "",
      categoryCode: searchObj?.categoryCode ? searchObj.categoryCode : [],
      workType: searchObj?.workType ? searchObj.workType : [],
      professionalLevel: searchObj?.professionalLevel
        ? searchObj.professionalLevel
        : [],
      createSeparateCSVForFirm: true,
      includeChangeLog: false,
    },
    viewLogsDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    isAllSelected: false,
    totalExpensesCount: 0,
    filterData: [],
    rowsList: [10],
  } as {
    search: {
      from: number;
      size: number;
      firms: string[];
      persons: string[];
      statuses: string[];
      startDate?: Date;
      endDate?: Date;
      sortBy?:
      | ""
      | "firm"
      | "person"
      | "new"
      | "reviewed"
      | "adjusted"
      | "attention"
      | "approved"
      | "submitted";
      sortOrder?: "asc" | "desc";
      keyword: string[];
      categoryCode: any;
      workType: any;
      professionalLevel: any;
      min?: any;
      max?: any;
      createSeparateCSVForFirm?: boolean;
      includeChangeLog?: boolean;
    };
    expensesList: IExpenseSubmitCourt[];
    totalExpensesCount: number;
    isAllSelected: boolean;
    filterData: IFilter[];
    rowsList: number[];
    options: { value: string; label: string }[];
    viewLogsDialog: ICustomDialogData;
  });

  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [isFilterOpen, setFilterOpen] = React.useState(true);
  const [isExportDialogOpenMain, setExportDialogOpenMain] = React.useState(
    false
  );
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [exportDialogType, setExportDialogType] = React.useState('');
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});
  const [isLoading, setLoading] = React.useState(false);
  const [firmsDic, setFirmsDic] = React.useState({} as IKeyValue<IFirmDetails>);

  const [personsDic] = React.useState({} as IKeyValue<IPerson>);
  const { showToast } = React.useContext(ToastContext);
  const firmProvider = React.useContext(FirmContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [initialFirms, setInitialFirms] = React.useState(
    {} as IKeyValue<IFirmDetails>
  );
  const [initialProfessionalLevels] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [] = React.useState([]);
  const [, setCategories] = React.useState([]);
  const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] = React.useState({
    minDate: undefined,
    maxDate: undefined
  } as {
    minDate?: Date;
    maxDate?: Date
  })

  React.useEffect(() => {
    if (isUserAllowed()) {
      getAllRecordsFromStarting();
    } else {
      history.push("/not-authorized");
    }
  }, []);

  const getAllRecordsFromStarting = async (search?) => {
    setLoading(true);
    let professionalLevelsDic = {};
    let categoriesCodeDic = await getAllCategoriesList();
    let workTypesDic = await getAllWorkTypesList();
    await getUserFirms({ professionalLevelsDic, categoriesCodeDic, search, workTypesDic });
    setLoading(false);
  };

  const getAllCategoriesList = async () => {
    const result = await getAllCategoriesForExpenses();
    let categoryCodeDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return {
            label: x.expenseCategoryName,
            value: x.expenseCategoryCode,
          };
        });
        categoryCodeDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialCategoryCodes(categoryCodeDic);
        setCategories(data || []);
      }
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
    return categoryCodeDic;
  };

  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(workTypesDic);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };

  const getExpenseFilters = async (
    search?: any,
    firmsDic?: any,
    personsDic?: any,
    changedProperty?: any,
    professionCategoriesData?: any
  ) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
    if (professionCategoriesData?.professionalLevelsDic) {
      initialProfessionalLevelTemp = {
        ...initialProfessionalLevels,
        ...professionCategoriesData.professionalLevelsDic,
      };
    }
    let initialCategoryCodeTemp = { ...initialCategoryCodes };
    if (professionCategoriesData?.categoriesCodeDic) {
      initialCategoryCodeTemp = {
        ...initialCategoryCodes,
        ...professionCategoriesData.categoriesCodeDic,
      };
    }
    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (professionCategoriesData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...professionCategoriesData.workTypesDic,
      };
    }
    let searchForFilter = { ...tempSearch };
    let keyWords = [...keyWordsList];
    // if (changedProperty) {
    //   switch (changedProperty) {
    //     case "firmCounts":
    //       searchForFilter.statuses = [];
    //       break;
    //     case "statusCounts":
    //       searchForFilter.firms = [];
    //       break;
    //     default:
    //       break;
    //   }
    // }
    if (searchForFilter.statuses.length === 0) {
      searchForFilter.statuses = ["submitted", "granted", "not-granted"]
    }
    delete tempSearch.size;
    delete tempSearch.from;
    setLoading(true);
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {
        firmCounts: {},
        statusCounts: {},
        categoryCodeCounts: {},
        workTypeCounts: {},
        keywordCounts: {},
      } as any,
    };
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      result = await getExpensesFiltersCount(searchForFilter, true);
    }
    setLoading(false);

    if (result?.isSuccess) {
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          categoryCodeCounts: "categoryCode",
          workTypeCounts: "workType",
          firmCounts: "firmCode",
          keywordCounts: "keyword",
          statusCounts: "status",
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            categoryCodeCounts: {},
            workTypeCounts: {},
            firmCounts: {},
            keywordCounts: {},
            statusCounts: {},
          }
        );
        if (result.data?.startDate) {
          tempSearch.startDate = result.data.startDate
        }
        if (result.data?.endDate) {
          tempSearch.endDate = result.data.endDate
        }
        setFilterCountsDic(filterCountDic);
      }
      console.log("result", result.data);
      let data = { ...result.data };
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });

      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      if (!tempSearch.max) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      let filter: IFilter[] = [];
      let initialFirmsTemp = { ...initialFirms };
      if (firmsDic) {
        initialFirmsTemp = {
          ...initialFirmsTemp,
          ...firmsDic,
        };
      }
      if (
        Object.keys(initialFirmsTemp).length === 0 ||
        professionCategoriesData?.search
      ) {
        let firmCounts = data["firmCounts"] || [];
        initialFirmsTemp = firmCounts.reduce((acc: any, ele) => {
          acc[ele.firmCode] = firmsDic[ele.firmCode] || {};
          return acc;
        }, {});
      }
      let filterOrder = [
        "workTypeCounts",
        "time",
        "statusCounts",
        "firmCounts",
        "categoryCodeCounts",
        "keyword",
        "min-max",
      ];
      let customFilters = ["time", "keyword", "min-max"];
      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time").isHidden) ||
                  false,
                items: [
                  {
                    type: 'date-range',
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: 'selection'
                      }
                    ],
                    label: ""
                  }
                ]
                // items: [
                //   {
                //     value: tempSearch.startDate,
                //     type: "date",
                //     label: "Start Date",
                //     name: "startDate",
                //   },
                //   {
                //     value: tempSearch.endDate,
                //     type: "date",
                //     label: "End Date",
                //     name: "endDate",
                //   },
                // ],
              });
              break;
            case "keyword":
              break;
            case "min-max":
              filter.push({
                header: "Amount Greater Than",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max").isHidden) ||
                  false,
                items: [
                  {
                    label: "Amount",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  },
                ],
              });
              break;
            default:
              break;
          }
        }
        else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(GRANTED_NOT_GRANTED_STATUS).forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses?tempSearch.statuses.includes(eds):false,
                  label: GRANTED_NOT_GRANTED_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                });
              });
              break;
            case "firmCounts":
              a.header = "Firm";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let firmCounts = data["firmCounts"] || [];
              Object.keys(initialFirmsTemp).forEach((element: any) => {
                a.items.push({
                  label: firmsDic[element]
                    ? firmsDic[element].lawFirmShortName
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.firms.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : firmCounts.find((e) => e.firmCode === element)?.count ||
                        0
                      : filterCountDic?.["firmCounts"]?.[element]?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.firms?tempSearch.firms.includes(element):false,
                });
              });
              // sorting based on label i.e. firm short name
              a.items.sort((a, b) => a.label > b.label ? 1 : -1);
              break;
            case "professionalCounts":
              a.header = "Professional Level";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let professionalCounts = data["professionalCounts"] || [];
              Object.keys(initialProfessionalLevelTemp).forEach((element) => {
                a.items.push({
                  label: professionCategoriesData.professionalLevelsDic[element]
                    ? professionCategoriesData.professionalLevelsDic[element]
                      .label
                    : "",
                  type: "checkbox",
                  count:
                    changedProperty !== undefined &&
                      changedProperty === ele &&
                      tempSearch.professionalLevel.length > 0
                      ? filterData
                        .find((ed) => ed.name === ele)
                        ?.items.find((ed) => ed.name === element)?.count || 0
                      : professionalCounts.find(
                        (e) => e.professionalLevel === element
                      )?.count || 0,
                  name: element,
                  isSelected: tempSearch.professionalLevel?tempSearch.professionalLevel.includes(element):false,
                });
              });
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.workTypesDic[element]
                    ? professionCategoriesData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : workTypeCounts.find(
                          (e) => e.workType === element
                        )?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.workType?tempSearch.workType.includes(element):false,
                });
              });
              break;
            case "categoryCodeCounts":
              a.header = "Expense Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let categoryCodeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp).sort((a, b) => initialCategoryCodeTemp[a].label > initialCategoryCodeTemp[b].label ? 1 : -1).forEach((element) => {
                a.items.push({
                  label: professionCategoriesData.categoriesCodeDic[element]
                    ? professionCategoriesData.categoriesCodeDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.categoryCode.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : categoryCodeCounts.find(
                          (e) => e.categoryCode === element
                        )?.count || 0
                      : filterCountDic?.["categoryCodeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.categoryCode?tempSearch.categoryCode.includes(element):false,
                });
              });
              break;
            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });

      // filter.push({
      //   header: "Keywords",
      //   name: "keyword",
      //   isHidden:
      //     (filterData.length > 0 &&
      //       filterData.find((e) => e.name === "keyword").isHidden) ||
      //     false,
      //   items: keyWords.map((ele) => {
      //     return {
      //       isSelected: tempSearch.keyword.includes(ele.value),
      //       label: ele.label,
      //       type: "checkbox",
      //       name: ele.value,
      //     };
      //   }),
      // });



      // filter.push({
      //   header: "",
      //   name: "extra-filters",
      //   isHidden: false,
      //   items: [
      //     {
      //       isSelected: tempSearch.createSeparateCSVForFirm,
      //       label: "Also create separate CSVs for each firm",
      //       type: "checkbox",
      //       name: "createSeparateCSVForFirm",
      //     },
      //     {
      //       isSelected: tempSearch.includeChangeLog,
      //       label: "Include Change Log",
      //       type: "checkbox",
      //       name: "includeChangeLog",
      //     },
      //   ],
      // });
      setCountsLoaded(true);
      setFilterData(filter);
      setInitialFirms(initialFirmsTemp);
      tempSearch.from = 0;
      tempSearch.size = 10;

      await getExpensesToSubmitCourtList(tempSearch);
    } else {
      showToast(
        result.message || "Error while fetching expenses counts",
        "error"
      );
    }
  };

  const getUserFirms = async (professionCategoriesData) => {
    let userFirms = firmProvider.userFirms;
    if (userFirms.length === 0) {
      userFirms = await firmProvider.getFirmsList();
    }
    let firmsDic = userFirms.reduce((acc: any, ele) => {
      acc[ele.lawFirmCode] = ele;
      return acc;
    }, {});
    setFirmsDic(firmsDic);
    // await getPersonsList(firmsDic, professionCategoriesData);
    await getExpenseFilters(
      professionCategoriesData?.search || undefined,
      firmsDic,
      {},
      undefined,
      professionCategoriesData
    );
  };

  const getExpensesToSubmitCourtList = async (search?: any) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let searchObj = { ...tempSearch };
    if (searchObj.statuses.length === 0) {
      searchObj.statuses = ["submitted", "granted", "not-granted"]
    }
    setLoading(true);
    const result = await getExpensesToSubmitCourt(searchObj);
    if (result?.isSuccess) {
      let menuItems = [];
      for (let index = 10; index <= result.data.count; index = index + 10) {
        menuItems.push(index);
      }
      if (menuItems[menuItems.length - 1] < result.data.count) {
        menuItems.push(getCeilValueForTotalCount(result.data.count));
        // menuItems.push(result.data.count);
      }
      if (menuItems.length > MAX_PAGINATION_OPTIONS) {
        menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
      }
      // else {
      //   let totalCount = menuItems.pop();
      //   if (totalCount) {
      //     menuItems.unshift({ label: "All", value: totalCount });
      //   }
      // }
      setState({
        ...state,
        totalExpensesCount: result.data.count,
        expensesList: [...result.data.expensesViewVMs],
        rowsList: [...menuItems],
        search: { ...state.search, ...tempSearch },
        isAllSelected: false,
      });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching expenses",
        "error"
      );
    }
    setLoading(false);
  };

  const onSearch = async (searchObj?: any) => {
    let tempSearch = { ...state.search };
    if (searchObj) {
      tempSearch = { ...state.search, ...searchObj };
    }
    await getExpensesToSubmitCourtList(tempSearch);
  };

  const isExpenseSelected = () => {
    let selected = false;
    // if (state.isAllSelected === true) {
    //   return true;
    // } else {
    state.expensesList.forEach((ele) => {
      if (ele.isSelected === true) {
        selected = true;
      }
    });
    return selected;
    // }
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    let search = { ...state.search };
    search.from = 0;
    let statusArray =
      data.find((ele) => ele.name === "statusCounts")?.items || [];
    let professionalLevelArray =
      data.find((ele) => ele.name === "professionalCounts")?.items || [];
    let categoryCodeArray =
      data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
    let workTypesArray =
      data.find((ele) => ele.name === "workTypeCounts")?.items || [];

    let personArray =
      data.find((ele) => ele.name === "personCounts")?.items || [];
    let firmArray = data.find((ele) => ele.name === "firmCounts")?.items || [];
    let timeArray = data.find((ele) => ele.name === "time")?.items || [];
    let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
    let extraFilters =
      data.find((ele) => ele.name === "extra-filters")?.items || [];
    search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
    search.startDate =
      timeArray[0].value[0].startDate || undefined;
    // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
    search.endDate =
      timeArray[0].value[0].endDate || undefined;
    // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;
    search.statuses = statusArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.persons =
      personArray.length > 0
        ? personArray[0].selectedItems.length > 0
          ? personArray[0].selectedItems.map((ele) => ele.value)
          : []
        : [];
    search.firms = firmArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        if (e.name) {
          return e.name;
        }
        return undefined;
      })
      .filter((x) => x !== undefined);
    search.professionalLevel = professionalLevelArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.categoryCode = categoryCodeArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    search.workType = workTypesArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    let keywordsArray = data.find((ele) => ele.name === "keyword")?.items || [];
    search.keyword = keywordsArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return e.name;
      });
    if (extraFilters.length > 0) {
      search.createSeparateCSVForFirm = extraFilters[0].isSelected;
      search.includeChangeLog = extraFilters[1].isSelected;
    }
    if (changedProperty !== "extra-filters") {
      await getExpenseFilters(search, firmsDic, personsDic, changedProperty, {
        professionalLevelsDic: { ...initialProfessionalLevels },
        categoriesCodeDic: { ...initialCategoryCodes },
        workTypesDic: { ...initialWorkTypes }
      });
    } else {
      let extraFiltersIndex = filterData.findIndex(
        (x) => x.name === "extra-filters"
      );
      if (extraFiltersIndex >= -1) {
        filterData[extraFiltersIndex].items[0].isSelected =
          search.createSeparateCSVForFirm;
        filterData[extraFiltersIndex].items[1].isSelected =
          search.includeChangeLog;
        setFilterData(filterData);
        setState({
          ...state,
          search: { ...state.search, ...search },
        });
      }
    }

    console.log("search", search);
  };

  const handleOpenExportDialog = (type?: string) => {
    setExportDialogOpen(true);
    setExportDialogType(type || '');
  };

  const handleCloseExportDialog = async (data?: {
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      // if(exportDialogType){
      //   await submitGrantedNotGrantedToCourt(exportDialogType,data?.includeChangeLog,data?.exportSeparately)
      // }else{
      await submitToCourt(data?.includeChangeLog, data?.exportSeparately);
      // }
    }
    setExportDialogOpen(false);
  };

  const submitGrantedNotGrantedToCourt = async (type: string, includeChangeLog?: boolean, createSeparateCSVForFirm?: boolean) => {
    let selectedExpenseIds = [...state.expensesList].reduce((acc, ele) => {
      if (ele.isSelected) {
        acc.push(ele.expenseEntryId);
      }
      return acc;
    }, []);
    if (selectedExpenseIds.length === 0) {
      showToast(`Please select entries to ${type}`, "error");
      return;
    }
    let selectedRecords = state.expensesList.filter((ele) => ele.isSelected === true);
    if (selectedRecords.length > 0) {
      let sameWorkTypeIncators = selectedRecords.filter(x => x.classActionWorkIndicator === "Y");
      if (!(sameWorkTypeIncators.length === 0 || sameWorkTypeIncators.length === selectedRecords.length)) {
        showToast(`Class and Common Benefit Expenses must be granted/not granted separately.`, "error");
        return;
      } else {
        setLoading(true);
        let result = await submitGrantedNotGrantedExpenseToCourt({
          expensesIds: selectedExpenseIds,
          includeChangeLog: includeChangeLog || false,
          includeCSVExport: createSeparateCSVForFirm || false,
          type: type
        });
        if (result.isSuccess) {
          showToast(`Entries ${type} by court successfully`, "success");
          //   await generateExportForExpenseSubmitForCourt(
          //     state.search,
          //     selectedExpenseIds,
          //     state.search.includeChangeLog,
          //     state.search.createSeparateCSVForFirm
          //   );
          await getExpenseFilters(state.search, firmsDic, personsDic, "clear-filters", {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes }
          });
        } else {
          setLoading(false);
          showToast("Error while submitting to court", "error");
        }
      }
    } else {
      showToast("Please select records to export", "error");
    }
  };

  const submitToCourt = async (includeChangeLog: boolean, createSeparateCSVForFirm: boolean) => {
    let selectedExpenseIds = [...state.expensesList].reduce((acc, ele) => {
      if (ele.isSelected) {
        acc.push(ele.expenseEntryId);
      }
      return acc;
    }, []);
    if (selectedExpenseIds.length === 0) {
      showToast("Please select entries to submit", "error");
      return;
    }
    setLoading(true);
    let result = await submitExpenseToCourt({
      expensesIds: selectedExpenseIds,
      includeChangeLog: includeChangeLog,
      includeCSVExport: createSeparateCSVForFirm,
    });
    if (result.isSuccess) {
      showToast("Entries submitted to court successfully", "success");
      await generateExportForExpenseSubmitForCourt(
        state.search,
        selectedExpenseIds,
        includeChangeLog,
        createSeparateCSVForFirm
      );
      await getExpenseFilters(state.search, firmsDic, personsDic, undefined, {
        professionalLevelsDic: { ...initialProfessionalLevels },
        categoriesCodeDic: { ...initialCategoryCodes },
        workTypesDic: { ...initialWorkTypes }
      });
    } else {
      setLoading(false);
      showToast("Error while submitting to court", "error");
    }
  };

  const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className="d-flex">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <ChevronDoubleLeft />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <ChevronRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <ChevronDoubleRight />
        </IconButton>
      </div>
    );
  };

  const onSort = async (sortBy) => {
    let search = { ...state.search };
    if (sortBy === search.sortBy) {
      search.sortOrder = search.sortOrder === "asc" ? "desc" : "asc";
    } else {
      search.sortBy = sortBy;
      search.sortOrder = "asc";
    }
    search.from = 0;
    await getExpensesToSubmitCourtList(search);
  };

  const selectAll = () => {
    let expensesList = [...state.expensesList];
    expensesList.map((ele) => {
      if (state.isAllSelected === false) {
        ele.isSelected = true;
      } else {
        ele.isSelected = false;
      }
      return ele;
    });
    setState({
      ...state,
      expensesList: expensesList,
      isAllSelected: !state.isAllSelected,
    });
  };

  const handleSelectChange = (index: number) => {
    let expensesList = state.expensesList;
    let expense = expensesList[index];
    expense.isSelected = !expense.isSelected;
    expensesList[index] = expense;
    let isAllSelected = true;
    if (expense.isSelected === false) {
      isAllSelected = false;
    } else {
      expensesList.forEach((ele) => {
        if (!ele.isSelected) {
          isAllSelected = false;
        }
      });
    }
    setState({ ...state, expensesList: expensesList, isAllSelected });
  };

  const handleViewLogsDialog = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (isOpen) {
      let expense = { ...state.expensesList[editIndex as number] };
      if (editIndex !== undefined) {
        data = expense.expenseEntryId;
      }
    }
    setState({
      ...state,
      viewLogsDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };

  const handleOpenExportDialogMain = () => {
    let selectedRecords = state.expensesList.filter((ele) => ele.isSelected === true);
    if (selectedRecords.length > 0) {
      let sameWorkTypeIncators = selectedRecords.filter(x => x.classActionWorkIndicator === "Y");
      if (!(sameWorkTypeIncators.length === 0 || sameWorkTypeIncators.length === selectedRecords.length)) {
        showToast(`Class and Common Benefit Expenses must be exported separately.`, "error");
        return;
      } else {
        setExportDialogOpenMain(true);
      }
    } else {
      showToast("Please select records to export", "error");
    }
  };
  const handleCloseExportDialogMain = async (data?: {
    exportType: "csv" | "pdf" | "excel";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv" || data.exportType === "excel") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.includeChangeLog, data?.exportSeparately, data.exportType);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.includeChangeLog, data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpenMain(false);
  };

  const handleDownload = async (includeChangeLog?: boolean, exportSeparately?: boolean) => {
    let searchObj = { ...state.search };
    let selectedExpenseIds = [...state.expensesList].reduce((acc, ele) => {
      if (ele.isSelected) {
        acc.push(ele.expenseEntryId);
      }
      return acc;
    }, []);
    let obj = {
      searchQuery: {
        firms: searchObj.firms,
        persons: searchObj.persons,
        statuses: searchObj?.statuses.length === 0 ? ["submitted", "granted", "not-granted"] : searchObj.statuses,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        from: 0,
        size: state.totalExpensesCount,
        sortBy: searchObj.sortBy,
        sortOrder: searchObj.sortOrder,
        keyword: searchObj.keyword,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        professionalLevel: searchObj.professionalLevel,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        showMultipleBillingRates: true,
        isShowAll: true,
      },
      workType: searchObj.workType,
      expensesIds: [...selectedExpenseIds],
      includeChangeLog: includeChangeLog ? true : false,
      includeCSVExport: false,
      isSaveOnServer: false,
      categoryList: searchObj.categoryCode.map((ele) => initialCategoryCodes[ele].value),
      firmList: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
      pdfTitle: 'Court Expense Granted / Not Granted',
      type: "granted",
      localTime: moment().format("YYYYMMDDHHmmss"),
      isZip: exportSeparately
    };
    let result = await fetch(`${API_URL}timesheets/generate-pdf/expense-submit`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], { type: "application/pdf" });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(
      pdf.type === "application/zip" ? "zip" : "pdf",
      "case"
    );
    a.click();
  };
  const handleDownloadAsCsv = async (includeChangeLog?: boolean, exportSeparately?: boolean, exportType?: "csv" | "excel") => {
    let searchObj = { ...state.search };
    let selectedExpenseIds = [...state.expensesList].reduce((acc, ele) => {
      if (ele.isSelected) {
        acc.push(ele.expenseEntryId);
      }
      return acc;
    }, []);
    let obj = {
      searchQuery: {
        firms: searchObj.firms,
        persons: searchObj.persons,
        statuses: searchObj?.statuses.length === 0 ? ["submitted", "granted", "not-granted"] : searchObj.statuses,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        from: 0,
        size: state.totalExpensesCount,
        sortBy: searchObj.sortBy,
        sortOrder: searchObj.sortOrder,
        keyword: searchObj.keyword,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        professionalLevel: searchObj.professionalLevel,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        showMultipleBillingRates: true,
        isShowAll: true,
      },
      workType: searchObj.workType,
      expensesIds: [...selectedExpenseIds],
      includeChangeLog: includeChangeLog ? true : false,
      includeCSVExport: false,
      isSaveOnServer: false,
      categoryList: searchObj.categoryCode.map((ele) => initialCategoryCodes[ele].value),
      firmList: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
      pdfTitle: 'Court Expense Granted / Not Granted',
      type: "granted",
      localTime: moment().format("YYYYMMDDHHmmss"),
      isZip: exportSeparately
    };
    let result
    if (exportType === "csv") {
      result = await fetch(`${API_URL}timesheets/generate-csv/expense-submit`, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + (getToken() || ""),
          dbName: getSubDomain(),
        },
      });
      let pdf = await result.blob();
      const pdfFile = await new Blob([pdf], {
        type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
      });
      const pdfFileURL = await URL.createObjectURL(pdfFile);
      var a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      a.href = pdfFileURL;
      a.download = await getFileName(
        pdf.type === "application/zip" ? "zip" : "csv",
        "case"
      );
      a.click();
    } else if (exportType === "excel") {
      let body: any = {
        isSaveOnServer: true,
        expensesIds: selectedExpenseIds,
        includeChangeLog: includeChangeLog ? true : false,
        includeCSVExport: false,
        timesheetFilters: state.search,
        isZip: false,
        localTime: moment().format("YYYYMMDDHHmmss"),
        type: "case",
      }
      if (obj) {
        body.searchQuery = obj.searchQuery;
        body.categoryList = obj.categoryList;
        body.firmList = obj.firmList;
      }
      let result = await fetch(`${API_URL}timesheets/generate-excel/expense-submit`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + (getToken() || ""),
          dbName: getSubDomain(),
        },
      });
      let pdf = await result.blob();
      const pdfFile = await new Blob([pdf], { type: "application/pdf" });
      const pdfFileURL = await URL.createObjectURL(pdfFile);
      var a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      a.href = pdfFileURL;
      a.download = await getFileName(
        "zip",
        "case"
      );
      a.click();
    }
  };
  const classes = styles();

  return (
    <React.Fragment>
      {isLoading && <Loading message={loadingMessage || ""} />}
      <section className="p-16">
        <div className="text-center">
          <Typography variant="h5">Court Expense Granted / Not Granted</Typography>
        </div>
        <br />
        <Grid className={`${classes.main8}`}>
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={2}
              className={`timesheet-filters-height ${isFilterOpen ? classes.filterOpenContainer : classes.filterCloseContainer}`}
            >
              {filterData.length > 0 && (
                <Filter
                  data={[...filterData]}
                  options={state.options || []}
                  onChange={(data: IFilter[], changedProperty?: string) => {
                    onFilterChange(data, changedProperty);
                  }}
                  dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                />
              )}
            </Grid>
          </Hidden>
          <ToggleFilterSection showFilterClass='toggle-filters-timesheet-container' classes={classes} toggleFilter={() => setFilterOpen(!isFilterOpen)} isFilterOpen={isFilterOpen} />
          <Grid className={`${isFilterOpen ? classes.mainOpenContainer : classes.mainCloseContainer}`}>

            <Grid container spacing={2} justify="flex-end">
              <Hidden lgUp>
                <Grid item>
                  {filterData.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setFilterDialogOpen(true)}
                    >
                      Filters
                    </Button>
                  )}
                </Grid>
              </Hidden>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={2} justify="flex-end">
                  {state.expensesList && state.expensesList.length > 0 &&
                    <Grid item>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleOpenExportDialogMain();
                        }}
                      >
                        Export
                      </Button>
                    </Grid>}
                  {isExpenseSelected() && (
                    <React.Fragment>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => submitGrantedNotGrantedToCourt('granted')}
                        >
                          Granted by Court
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => submitGrantedNotGrantedToCourt('not-granted')}
                        >
                          Not granted by Court
                        </Button>
                      </Grid>
                      {/* <Grid item>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleOpenExportDialog()}
                          >
                            Submit To Court
                          </Button>
                        </Grid> */}
                    </React.Fragment>
                  )}
                </Grid>
                <br />
                <TableContainer className="timesheets-table-container moz-table-pb">
                  <Table size="small" className="custom-table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          <Checkbox
                            size="small"
                            checked={state.isAllSelected}
                            value={state.isAllSelected}
                            onClick={() => {
                              selectAll();
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell sortDirection="desc">
                          <TableSortLabel
                            active={state.search.sortBy === "firm"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("firm");
                            }}
                          >
                            Firm
                          </TableSortLabel>
                        </StyledTableCell>

                        <StyledTableCell>Expense Date</StyledTableCell>
                        <StyledTableCell>Category Name</StyledTableCell>
                        <StyledTableCell width="200px">
                          Detailed Description of Work performed
                        </StyledTableCell>
                        <StyledTableCell>Approved by</StyledTableCell>
                        <StyledTableCell align="right">Amount</StyledTableCell>
                        <StyledTableCell>Receipt Provided</StyledTableCell>
                        <StyledTableCell>Receipt Description</StyledTableCell>
                        <StyledTableCell align="center">Class Work</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell align="center">
                          Version
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.expensesList.length > 0 ? (
                        state.expensesList.map((ele, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              <Checkbox
                                size="small"
                                checked={ele.isSelected ? true : false}
                                // value={ele.isSelected}
                                onClick={() => {
                                  handleSelectChange(index);
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {ele.lawFirmShortName}
                            </StyledTableCell>

                            <StyledTableCell>
                              {moment(ele.expenseDate).format("MM/DD/YYYY")}
                            </StyledTableCell>
                            <StyledTableCell>
                              {ele.expenseCategoryName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {ele.detailedDescription}
                            </StyledTableCell>
                            <StyledTableCell>
                              {ele.expenseApprovedByName}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {currencyFormat(ele?.expenseAmount || 0)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {ele?.receiptYesNo ? RECEIPT_TYPES[ele.receiptYesNo.toLowerCase()] : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {ele.receiptExplain}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele.classActionWorkIndicator}
                            </StyledTableCell>
                            <StyledTableCell>
                              {TIMESHEET_STATUS[ele.status || ""]}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele.version > 1 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  component="a"
                                  align="center"
                                  color="primary"
                                  onClick={() =>
                                    handleViewLogsDialog(true, index)
                                  }
                                >
                                  {/* {ele.version}&nbsp;<History /> */}
                                  {ele.version}
                                </Typography>
                              ) : (
                                ele.version
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={13}>
                            No Entries found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={state.rowsList}
                  component="div"
                  count={state.totalExpensesCount}
                  rowsPerPage={state.search.size}
                  page={
                    state.search.from === 0
                      ? 0
                      : state.search.from / state.search.size
                  }
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={(event: any, newPage: number) => {
                    console.log("event", event);
                    console.log("newPage", newPage);
                    let search = { ...state.search };
                    search.from =
                      newPage === 0
                        ? 0
                        : (Number(newPage) *
                          state.search.size);
                    onSearch(search);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    let value = event.target.value;
                    let search = { ...state.search };
                    search.size = value ? Number(value) : 10;
                    search.from = 0;
                    onSearch(search);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      {state.viewLogsDialog.isOpen && (
        <LogDialog
          timesheetId={state.viewLogsDialog.data}
          handleDialogClose={() => handleViewLogsDialog(false)}
        />
      )}

      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
      {isExportDialogOpen && (
        <ExportDialog
          exportFor={'submitToCourt'}
          handleDialogClose={(data?) => handleCloseExportDialog(data)}
        />
      )}
      {isExportDialogOpenMain && (
        <ExportDialog
          exportFor={'grantedNotGranted'}
          handleDialogClose={(data?) => handleCloseExportDialogMain(data)}
        />
      )}
    </React.Fragment>
  );
};

export default ExpenseGrantedNotGranted;
