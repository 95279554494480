import {
    Button,
    FormControl,
    Grid,
    TextField,
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import * as React from "react";
import { IProfessionLevelManagement } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import { createAProfessionalLevel, updateAProfessionalLevel } from "../../services/TimesheetService";
import CustomDialogDrawer from "../common/CustomDialogDrawer";

export interface AddProfessionalLevelDialogProps {
    data: IProfessionLevelManagement;
    handleDialogClose: Function;
    updateStatus: Function;
}

const AddProfessionalLevelDialog: React.FC<AddProfessionalLevelDialogProps> = ({
    data,
    handleDialogClose,
    updateStatus
}) => {
    const [state, setState] = React.useState({
        professionalLevel: {
            professionalLevelName: "",
            professionalLevelCode: "",
            professionalLevelSort: "",
            timeProfessionalLevelBillingRate: 0,
            ...data
        },
    } as {
        professionalLevel: IProfessionLevelManagement;
    });
    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);

    const closeAddDialog = (data?: IProfessionLevelManagement) => {
        handleDialogClose(data);
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                variant="small"
                title={state.professionalLevel.id ? "Edit Professional Level" : "Create Professional Level"}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <section style={{ marginTop: -16 }}>
                    <Formik
                        enableReinitialize
                        initialValues={state.professionalLevel}
                        validate={(values) => {
                            let errors: FormikErrors<IProfessionLevelManagement> = {};
                            if (!values.professionalLevelName) {
                                errors.professionalLevelName = "Required";
                            }
                            if (!values.professionalLevelCode) {
                                errors.professionalLevelCode = "Required";
                            }
                            if (!values.timeProfessionalLevelBillingRate) {
                                errors.timeProfessionalLevelBillingRate = "Required";
                            }
                            return errors;
                        }}
                        onSubmit={async (values: IProfessionLevelManagement, { setSubmitting }) => {
                            setLoading(true);
                            let obj = { ...values };
                            let res;
                            if (obj.id) {
                                res = await updateAProfessionalLevel(obj);
                            } else {
                                res = await createAProfessionalLevel(obj);
                            }
                            setLoading(false);
                            if (res.isSuccess) {
                                if (res?.isReadOnly) {
                                    showToast(res.message, "error");
                                    closeAddDialog();
                                    updateStatus(res.isReadOnly);
                                } else {
                                    closeAddDialog(res.data);
                                    showToast(
                                        `Professional Level ${values.id ? "updated" : "created"} successfully`,
                                        "success"
                                    );
                                }
                            } else {
                                showToast(
                                    res.message
                                        ? res.message
                                        : `Error while ${values.id ? "updating" : "creating"} professional level`,
                                    "error"
                                );
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="professionalLevelName"
                                                name="professionalLevelName"
                                                label="Name"
                                                onChange={handleChange}
                                                error={
                                                    errors.professionalLevelName && touched.professionalLevelName ? true : false
                                                }
                                                helperText={
                                                    errors.professionalLevelName && touched.professionalLevelName && errors.professionalLevelName
                                                }
                                                onBlur={handleBlur}
                                                value={values.professionalLevelName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="professionalLevelCode"
                                                name="professionalLevelCode"
                                                label="Code"
                                                onChange={handleChange}
                                                error={
                                                    errors.professionalLevelCode && touched.professionalLevelCode ? true : false
                                                }
                                                helperText={
                                                    errors.professionalLevelCode && touched.professionalLevelCode && errors.professionalLevelCode
                                                }
                                                onBlur={handleBlur}
                                                value={values.professionalLevelCode}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="timeProfessionalLevelBillingRate"
                                                name="timeProfessionalLevelBillingRate"
                                                label="Billing Rate"
                                                onChange={handleChange}
                                                type="number"
                                                error={
                                                    errors.timeProfessionalLevelBillingRate && touched.timeProfessionalLevelBillingRate ? true : false
                                                }
                                                helperText={
                                                    errors.timeProfessionalLevelBillingRate && touched.timeProfessionalLevelBillingRate && errors.timeProfessionalLevelBillingRate
                                                }
                                                onBlur={handleBlur}
                                                value={values.timeProfessionalLevelBillingRate}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} justify="flex-end">
                                            <Grid item>
                                                <Button variant="text" onClick={() => closeAddDialog()}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {values.id ? "Update" : "Add"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </section>
            </CustomDialogDrawer>
        </React.Fragment>
    );
};

export default AddProfessionalLevelDialog;
