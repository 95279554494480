import { IResourceResponse, IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";

export async function saveTimeFilter(data,isCommitteeReport:boolean): Promise<IStandardAPIResponse<IResourceResponse>> {
    try {
        let url = `management-dashboard/save-filters`;
        let type = "POST";
        if (data.id) {
            url = `management-dashboard/update-filters`;
            type = "PATCH";
            data.saveFilterId = data.id;
        }
        if(isCommitteeReport){
            url = `burst-reports/save-exhibits-report-filters`;
            type = "POST";
        }
        let res = await httpClient<IResourceResponse>(url, type, data, false, isCommitteeReport?false:true);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}