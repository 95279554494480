import React from "react";
import { INote, INoteEntriesResponse } from "../../vm";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Grid, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { getNoteEntriesList } from "../../services/NoteService";
import { currencyFormat, numberFormat, StyledTableCell, StyledTableRow } from "../../services/UtilService";
import moment from "moment";
import { RECEIPT_TYPES, TIMESHEET_STATUS } from "../../Constant";
import ViewTimesheetTable from "../timesheets/ViewTimesheetTable";
import ViewExpenseTable from "../expenses/ViewExpenseTable";

export interface INoteEntriesDialogProps {
    data?: INote;
    handleDialogClose: any;
}

const NoteEntriesDialog: React.FC<INoteEntriesDialogProps> = ({
    data,
    handleDialogClose,
}) => {
    // state
    const [state, setState] = React.useState({
        expenseEntries: [],
        timeEntries: []
    } as INoteEntriesResponse);
    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);

    React.useEffect(() => {
        getNoteEntries();
    }, []);

    const getNoteEntries = async () => {
        if (data.id) {
            setLoading(true);
            let result = await getNoteEntriesList(data.id, data.type);
            setLoading(false);
            if (result?.isSuccess) {
                setState({
                    timeEntries: result.data.timeEntries,
                    expenseEntries: result.data.expenseEntries,
                })
            } else {
                showToast(result.message || "Error while getting firms", "error")
            }
        }
    };

    const closeAddDialog = (data?: boolean) => {
        handleDialogClose(data);
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                variant="large"
                title={`${data.type === "timesheets" ? "Timesheet" : "Expense"} Entries`}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <Grid container direction="column" spacing={2} className="firm-configure-dialog">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {data.type === "timesheets" && (
                            <ViewTimesheetTable
                                from="notes"
                                timeEntries={state.timeEntries}
                            />
                        )}
                        {data.type === "expenses" && (
                            <ViewExpenseTable
                                from="notes"
                                expensesList={state.expenseEntries}
                            />
                        )}
                    </Grid>
                </Grid>
            </CustomDialogDrawer>
        </React.Fragment>
    );
};

export default NoteEntriesDialog;
