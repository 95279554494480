import * as React from "react";
import { Typography, Paper, Theme, Fab, withStyles, createStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Home from "mdi-material-ui/Home";
import { ClassProps } from "../../vm";
const MyLink = (props: any) => <Link to="/" {...props} />;

export interface NotAuthorizedPageProps extends ClassProps { }

export interface NotAuthorizedPageState { }

class NotAuthorizedPage extends React.Component<
  NotAuthorizedPageProps,
  NotAuthorizedPageState
> {
  constructor(props: NotAuthorizedPageProps) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Typography variant="h2" gutterBottom>
          <span role="img" aria-labelledby="lost" style={{ color: "red" }}>
            🚫
          </span>
        </Typography>
        <Typography variant="h5" gutterBottom>
          Account is not authorized for this page.
        </Typography>
        <br />
        <Fab component={MyLink} color="primary" aria-label="home">
          <Home />
        </Fab>
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      padding: theme.spacing(4),
      width: "400px",
      margin: "16px auto",
    },
    paper: {},
    button: {},
  });

export default withStyles(styles)(NotAuthorizedPage);
