import * as React from "react";
import { getAllFirms, getUserFirms } from "../../services/FirmsService";
import { parseJwt } from "../../services/UtilService";
import { IFirmDetails } from "../../vm";
import {
  getAllProfessionalLevels,
  getAllCategories,
  getAllWorkTypes,
} from "../../services/TimesheetService";
import { getAllCategoriesForExpenses } from "../../services/ExpenseService";
import { URL_PATHS } from "../../Constant";
interface IDispatch {
  firms?: Array<IFirmDetails>;
  professionalLevels?: Array<any>;
  categoriesList?: Array<any>;
  categoriesListForExpenses?: Array<any>;
  isFirmsLoaded?: boolean;
  professionalLevelDic?: any;
  categoriesListDic?: any;
  workTypesListDic?: any;
  categoriesListDicForExpenses?: any;
  firmsDic?: any;
  type: string;
  workTypesList?:Array<any>
}
interface dispatchFunc {
  (params: IDispatch): void;
}

interface getFirmsFunc {
  (): Array<IFirmDetails>;
}
interface getProfessionalLevelsFunc {
  (): Array<IFirmDetails>;
}
interface getCategoriesFunc {
  (): Array<IFirmDetails>;
}
interface IState {
  firms: Array<IFirmDetails>;
  isFirmsLoaded?: boolean;
  professionalLevels?: Array<any>;
  categoriesList?: Array<any>;
  workTypesList?: Array<any>;
  categoriesListForExpenses?: Array<any>;
  professionalLevelDic?: any;
  categoriesListDic?: any;
  workTypesListDic?: any;
  categoriesListDicForExpenses?: any;
  firmsDic?: any;
}

export interface IFirm {
  getFirmsList: getFirmsFunc;
  getAllProfessionalLevelsList: getProfessionalLevelsFunc;
  getCategoriesList: getCategoriesFunc;
  getCategoriesListForExpenses: getCategoriesFunc;
  getWorkTypesListForExpenses: getCategoriesFunc;
  userFirms: Array<IFirmDetails>;
  professionalLevelDic: any;
  categoriesListDic: any;
  workTypesListDic: any;
  professionalLevels: Array<any>;
  categoriesList: Array<any>;
  workTypesList: Array<any>;
  firmsDic: any;
}

export const FirmContext = React.createContext<IFirm>({
  getFirmsList: (): any => {
    return [];
  },
  getAllProfessionalLevelsList: (): any => {
    return [];
  },
  getCategoriesList: (): any => {
    return [];
  },
  getCategoriesListForExpenses: (): any => {
    return [];
  },
  getWorkTypesListForExpenses: (): any => {
    return [];
  },
  userFirms: [],
  professionalLevelDic: {},
  categoriesListDic: {},
  workTypesListDic: {},
  firmsDic: {},
  professionalLevels: [],
  categoriesList: [],
  workTypesList: [],
});

export const FirmProvider: React.FC<any> = (props) => {
  const [state, dispatch]: [IState, dispatchFunc] = React.useReducer(
    (prevState: any, action: IDispatch) => {
      let obj: any = {};
      switch (action.type) {
        case "firms":
          obj = {
            firms: action.firms,
            firmsDic: action.firmsDic,
            isFirmsLoaded: action.isFirmsLoaded || false,
          };
          break;
        case "professionalLevels":
          obj = {
            professionalLevels: action.professionalLevels,
            professionalLevelDic: action.professionalLevelDic,
          };
          break;
        case "categoriesList":
          obj = {
            categoriesList: action.categoriesList,
            categoriesListDic: action.categoriesListDic,
          };
          break;
        case "workTypesList":
          obj = {
            workTypesList: action.workTypesList,
            workTypesListDic: action.workTypesListDic,
          };
          break;
        case "categoriesListForExpenses":
          obj = {
            categoriesListForExpenses: action.categoriesList,
            categoriesListDicForExpenses: action.categoriesListDic,
          };
          break;

        default:
          break;
      }
      return {
        ...prevState,
        ...obj,
      };
    },
    {
      firms: [],
      isFirmsLoaded: false,
      firmsDic: {},
      professionalLevels: [],
      professionalLevelDic: {},
      categoriesList: [],
      categoriesListDic: {},
      workTypesList: [],
      workTypesListDic: {},
      categoriesListForExpenses: [],
      categoriesListDicForExpenses: {},
    }
  );

  const getUserFirmsList = async () => {
    let userData = parseJwt();
    if (userData) {
      let result;
      if (userData.Role === "2") {
        result = await getUserFirms(Number(userData.CustomerId));
      } else {
        result = await getAllFirms();
      }
      if (result?.isSuccess) {
        let firmsDic = result.data.reduce((acc: any, ele) => {
          acc[ele.lawFirmCode] = ele;
          return acc;
        }, {});
        dispatch({
          firms: result.data,
          isFirmsLoaded: true,
          type: "firms",
          firmsDic,
        });
      }
      return result.data;
    } else {
      dispatch({ firms: [], isFirmsLoaded: true, type: "firms" });
      return [];
    }
  };
  const getAllProfessionalLevelsList = async () => {
    let userData = parseJwt();
    if (userData) {
      let result = await getAllProfessionalLevels();
      if (result?.isSuccess) {
        let professionalLevelDic = result.data.reduce((acc: any, ele) => {
          acc[ele.professionalLevelCode] = ele;
          return acc;
        }, {});
        dispatch({
          professionalLevels: result.data,
          type: "professionalLevels",
          professionalLevelDic,
        });
      }
      return result.data;
    } else {
      dispatch({
        professionalLevels: [],
        professionalLevelDic: {},
        type: "professionalLevels",
      });
      return [];
    }
  };

  const getCategoriesList = async () => {
    let userData = parseJwt();
    if (userData) {
      let result = await getAllCategories();
      if (result?.isSuccess) {
        let categoriesListDic = result.data.reduce((acc: any, ele) => {
          acc[ele.timeCategoryCode] = ele;
          return acc;
        }, {});
        dispatch({
          categoriesList: result.data,
          type: "categoriesList",
          categoriesListDic,
        });
      }
      return result.data;
    } else {
      dispatch({
        categoriesList: [],
        type: "categoriesList",
        categoriesListDic: {},
      });
      return [];
    }
  };
  const getCategoriesListForExpenses = async () => {
    let userData = parseJwt();
    if (userData) {
      let result = await getAllCategoriesForExpenses();
      if (result?.isSuccess) {
        let categoriesListDicForExpenses = result.data.reduce(
          (acc: any, ele) => {
            acc[ele.timeCategoryCode] = ele;
            return acc;
          },
          {}
        );
        dispatch({
          categoriesListForExpenses: result.data,
          type: "categoriesListForExpenses",
          categoriesListDicForExpenses,
        });
      }
      return result.data;
    } else {
      dispatch({
        categoriesList: [],
        type: "categoriesListForExpenses",
        categoriesListDic: {},
      });
      return [];
    }
  };
  const getWorkTypesListForExpenses = async () => {
    let userData = parseJwt();
    if (userData) {
      let result = await getAllWorkTypes();
      if (result?.isSuccess) {
        let workTypesListDic = result.data.reduce(
          (acc: any, ele) => {
            acc[ele.workTypeCode] = ele;
            return acc;
          },
          {}
        );
        dispatch({
          workTypesList: result.data,
          type: "workTypesList",
          workTypesListDic,
        });
      }
      return result.data;
    } else {
      dispatch({
        workTypesList: [],
        type: "workTypesList",
        workTypesListDic: {},
      });
      return [];
    }
  };

  React.useEffect(() => {
    const fetchTimeSheetReport = async () => {
      if(window.location.pathname.indexOf(URL_PATHS.DB_DOES_NOT_EXISTS)===-1){
        await getUserFirmsList();
      }
    };
    fetchTimeSheetReport();
  }, []);

  const firmContext = React.useMemo(
    () => ({
      getFirmsList: async () => {
        let userFirms = await getUserFirmsList();
        return userFirms;
      },
      getAllProfessionalLevelsList: async () => {
        let data = await getAllProfessionalLevelsList();
        return data;
      },
      getCategoriesList: async () => {
        let data = await getCategoriesList();
        return data;
      },
      getCategoriesListForExpenses: async () => {
        let data = await getCategoriesListForExpenses();
        return data;
      },
      getWorkTypesListForExpenses: async () => {
        let data = await getWorkTypesListForExpenses();
        return data;
      },
      userFirms: state.firms,
      firmsDic: state.firmsDic || {},
      professionalLevelDic: state.professionalLevelDic || {},
      professionalLevels: state.professionalLevels || [],
      categoriesListDic: state.categoriesListDic || {},
      categoriesList: state.categoriesList || [],
      workTypesList: state.workTypesList || [],
      workTypesListDic: state.workTypesListDic || {},
      categoriesListDicForExpenses: state.categoriesListDic || {},
      categoriesListForExpenses: state.categoriesList || [],
    }),
    []
  );
  return (
    <FirmContext.Provider value={firmContext as any}>
      {props.children}
    </FirmContext.Provider>
  );
};

export const withFirmContext = (ChildComponent: any) => (props: any) => {
  let firmProvider = React.useContext(FirmContext);
  return (
    <ChildComponent
      {...props}
      firmProvider={{
        ...firmProvider,
        userFirms: firmProvider.userFirms,
        categoriesList: firmProvider.categoriesList,
        workTypesList: firmProvider.workTypesList,
      }}
    />
  );
};
