import * as React from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableSortLabel,
    Grid,
    Button,
} from "@material-ui/core";
import {
    convertToFixed,
    currencyFormat,
    numberFormat,
    StyledTableCell,
} from "../../services/UtilService";
import { IFirmTimesheetMetrics } from "../../vm";
import moment from "moment";
import SecondaryLoading from "../common/SecondaryLoading";
export interface FirmTimesheetMetricsProps {
    firmTimesheetMetrics: Array<IFirmTimesheetMetrics>;
    label?: string;
    onSort: Function;
    onExport: Function;
    sortBy?: any;
    sortOrder?: any;
    firmTimesheetMetricsMonths: string[];
    isLoading: boolean;

}

const FirmTimesheetMetrics: React.FC<FirmTimesheetMetricsProps> = (
    props
) => {
    const onSort = (sortBy?: any) => {
        props.onSort(sortBy);
    };
    const onExport = () => {
        props.onExport();
    };
    return (
        <section className="position-relative">
            {/* {props.isLoading && <SecondaryLoading />} */}
            <Grid container spacing={2} justify="space-between" className="py-16">
                <Grid item>
                    <Typography variant="h6">
                        {props.label ? props.label : "Firm Timesheet Metrics"}
                    </Typography>
                </Grid>
                {props.firmTimesheetMetrics?.length > 0 && !props.isLoading &&
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onExport();
                            }}
                        >
                            Export
                        </Button>
                    </Grid>
                }
            </Grid>
            {props.isLoading ? <SecondaryLoading /> :
                <TableContainer className="timesheet-metric-table moz-table-pb">
                    <Table size="small" className="custom-table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell className="sticky-column sticky-column-header firm-column fixed-header-column" align="center"></StyledTableCell>
                                <StyledTableCell colSpan={2} className="sticky-column sticky-column-header time-entries-column fixed-header-column" align="center">Time Entries</StyledTableCell>
                                <StyledTableCell colSpan={2} className="sticky-column sticky-column-header month-entries-column fixed-header-column" align="center">Months With Entries</StyledTableCell>
                                {props.firmTimesheetMetricsMonths.map((month, index) => (
                                    <StyledTableCell key={`month_header${index}`} colSpan={3} align="center" className="sticky-column sticky-column-header fixed-header-column" style={{ zIndex: 99 }}>{moment(month, "M-YYYY").format("MM/YYYY")}</StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell className="sticky-column-2 sticky-column-header firm-column">
                                    <TableSortLabel
                                        active={props.sortBy === "firmName"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("firmName");
                                        }}
                                    >
                                        Firm
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    className="sticky-column-2 sticky-column-header time-all-data-column"
                                >
                                    <TableSortLabel
                                        active={props.sortBy === "timeEntriesSum"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("timeEntriesSum");
                                        }}
                                    >
                                        All
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="sticky-column-2 sticky-column-header time-percentage-data-column firms-percentage-column">
                                    <TableSortLabel
                                        active={props.sortBy === "timeEntriesPercentage"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("timeEntriesPercentage");
                                        }}
                                    >
                                        % of all firms
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="sticky-column-2 sticky-column-header months-all-data-column">
                                    <TableSortLabel
                                        active={props.sortBy === "monthWithEntriesSum"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("monthWithEntriesSum");
                                        }}
                                    >
                                        All
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="sticky-column-2 sticky-column-header months-percentage-data-column firms-percentage-column">
                                    <TableSortLabel
                                        active={props.sortBy === "monthWithEntriesPercentage"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("monthWithEntriesPercentage");
                                        }}
                                    >
                                        % of all firms
                                    </TableSortLabel>
                                </StyledTableCell>
                                {props.firmTimesheetMetricsMonths.map(month => (
                                    <React.Fragment>
                                        <StyledTableCell align="center" className="sticky-column-2 sticky-column-header" style={{ zIndex: 99 }}>
                                            Entries
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className="sticky-column-2 sticky-column-header" style={{ zIndex: 99 }}>
                                            Days
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className="sticky-column-2 sticky-column-header" style={{ zIndex: 99 }}>
                                            Resources
                                        </StyledTableCell>
                                    </React.Fragment>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.firmTimesheetMetrics?.length > 0 ? (
                                <React.Fragment>
                                    {props.firmTimesheetMetrics.map((firmTimesheetMetric, index) => (
                                        <React.Fragment>
                                            <TableRow key={`firmTimesheetMetric${index}`}>
                                                <TableCell className="sticky-column-body firm-column">
                                                    {firmTimesheetMetric.firmName}
                                                </TableCell>
                                                <TableCell align="right" className="sticky-column-body time-all-data-column">
                                                    {convertToFixed(firmTimesheetMetric.timeEntriesSum)}
                                                </TableCell>
                                                <TableCell align="right" className="sticky-column-body time-percentage-data-column">
                                                    {convertToFixed(firmTimesheetMetric.timeEntriesPercentage)}
                                                </TableCell>
                                                <TableCell align="right" className="sticky-column-body months-all-data-column">
                                                    {convertToFixed(firmTimesheetMetric.monthWithEntriesSum)}
                                                </TableCell>
                                                <TableCell align="right" className="sticky-column-body months-percentage-data-column">
                                                    {convertToFixed(firmTimesheetMetric.monthWithEntriesPercentage)}
                                                </TableCell>
                                                {props.firmTimesheetMetricsMonths.map(month => (
                                                    <React.Fragment>
                                                        <TableCell align="right">{firmTimesheetMetric[month] ? firmTimesheetMetric[month].entries : 0}</TableCell>
                                                        <TableCell align="right">{firmTimesheetMetric[month] ? firmTimesheetMetric[month].daysService : 0}</TableCell>
                                                        <TableCell align="right">{firmTimesheetMetric[month] ? firmTimesheetMetric[month].resourceCount : 0}</TableCell>
                                                    </React.Fragment>
                                                ))}
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        No Data found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </section>
    );
};

export default FirmTimesheetMetrics;
