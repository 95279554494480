import * as React from "react";
import { useState, useEffect } from "react";
import Loading from "../common/Loading";
import { getQueryParams, parseJwt, setToken } from "../../services/UtilService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withIsReadOnlyContext } from "../common/IsReadOnlyProvider";
import { checkIsReadOnlyApi } from "../../services/UserService";

interface VerifyMainProps extends RouteComponentProps {
  updateStatus:any
}

const VerifyMain: React.FunctionComponent<VerifyMainProps> = (props) => {

  useEffect(() => {
    const params = getQueryParams();
    let token = params['token'];
    if(token){
        setToken(token);
        checkReadOnly();
        
    }else{
        props.history.push("/");
    }
  }, []);

  const checkReadOnly = async()=>{
    let readOnlyRes = await checkIsReadOnlyApi();
    if(readOnlyRes.isSuccess){
      props.updateStatus(readOnlyRes.data.isReadonly);
      props.history.push("/dashboard");
    }
  }

  return <React.Fragment>
    <Loading/>
  </React.Fragment>;
};

export default withRouter(withIsReadOnlyContext(VerifyMain));
