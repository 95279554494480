import * as React from 'react';
import { IReportResponse } from '../../vm';
import { SUMMARY_VISUALIZATION_METADATA_DICT } from '../../Constant';
import { Typography, Grid } from '@material-ui/core';
import BarChart from '../common/charts/BarChart';
import { convertToFixed, currencyFormat, numberFormat } from '../../services/UtilService';
interface SummaryVisualizationReportProps {
    tableData: IReportResponse[];
    selectedColumns: string[];
    selectedMetrics: string[];
    selectedBurstReports: string[];
}

const SummaryVisualizationReport: React.FC<SummaryVisualizationReportProps> = ({ selectedBurstReports, selectedColumns, selectedMetrics, tableData }) => {
    const [selectedMetricsProps, setSelectedMetricsProps] = React.useState([] as string[]);
    const [colorCode, setColorCode] = React.useState<Array<string>>(["#98AFC7", "#728FCE", "#00BFFF", "#B0E0E6", "#E3E4FA", "#AAF0D1", "#50C878", "#CCFB5D", "#C3FDB8", "#DBF9DB", "#F0FFF0", "#F3E5AB", "#ECC5C0", "#FDD7E4", "#FFC0CB", "#CCCCFF", "#E0B0FF", "#DFD3E3", "#E9E4D4", "#F5F5DC", "#FFFDD0", "#CCFF99", "#CCFFCC", "#CCFFFF", "#FF99CC", "#FFCCCC", "#FFFFCC", "#F5F5F5", "#FFF5EE", "#FAAFBE", "#F98B88", "#FF8C00", "#827839", "#D4A017", "#FFF380", "#045D5D"]);
    const [stackedTimeCategoryData, setStackedTimeCategoryData] = React.useState([]);

    React.useEffect(() => {
        let selectedMetricsPropsList = [...selectedMetrics];
        if (selectedMetrics.includes('all')) {
            selectedMetricsPropsList = Object.keys(SUMMARY_VISUALIZATION_METADATA_DICT).filter((d) => d !== 'all');
        }
        setSelectedMetricsProps(selectedMetricsPropsList);
        console.log(selectedMetricsPropsList);
        buildData();
    }, []);

    const buildData = () => {
        if (selectedColumns.length === 2) {
            let graphData = [];
            tableData.forEach(table => {
                let data = table.data.reduce((acc: any, ele) => {
                    let data: any = {};
                    if (ele.groupByProp) {
                        ele.values.forEach((value: any) => {
                            if (value.groupByProp) {
                                let prop: string = "timeCategoryName"
                                value.values.forEach((newData: any) => {
                                    acc.xAxisLabel = table.burstReportHeaders[0].headerProperty
                                    prop = table.burstReportHeaders[1].headerProperty;

                                    table.visualizationAxisBins.forEach(bin=>{
                                        data[bin.prop+newData[prop]] = newData[bin.prop];
                                        data[bin.prop+newData[prop] + "formattedValue"] = currencyFormat(convertToFixed(newData[bin.prop]));
                                    })


                                    if (prop) {
                                        if (!acc.colorCode[newData[prop]]) {
                                            acc.colorCodeIndex++;
                                            acc.colorCode[newData[prop]] = colorCode[acc.colorCodeIndex];
                                        }
                                        table.visualizationAxisBins.forEach(bin=>{
                                            data[bin.prop+newData[prop] + "CLR_code"] = acc.colorCode[newData[prop]];
                                            if(!acc.keys[bin.prop]){
                                                acc.keys[bin.prop]=[]
                                            }
                                            if (acc.keys[bin.prop].indexOf(bin.prop+newData[prop]) === -1) {
                                                acc.keys[bin.prop].push(bin.prop+newData[prop]);
                                            }
                                        })
                                        
                                        
                                    }
                                    data = {
                                        ...newData,
                                        ...data,
                                        isStacked: true
                                    }
                                })
                            }
                        });
                    }
                    acc.data.push(data);
                    acc.visualizationAxisBins = table.visualizationAxisBins;
                    return acc;
                }, { data: [], colorCode: {}, colorCodeIndex: 0, keys: {}, xAxisLabel: "", visualizationAxisBins: [] });
                graphData.push(data);
            })
            setStackedTimeCategoryData(graphData);
        }
    }


    return (
        <div>
            {selectedColumns.length === 1 ? (
                <React.Fragment>
                    {tableData.map((graphData,graphDataIndex) => (
                        <div key={`graph_row${graphDataIndex}`}>
                            {graphData.visualizationAxisBins?.map((bin,binIndex) => (
                                <div key={`graph_row${graphDataIndex}_bin_${binIndex}`}>
                                    <Typography variant='subtitle2' style={{ marginRight: 4 }}>{`${bin.label} `}</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} className={`chart-container-${Math.floor(bin.ranges.length/10)}x`}>
                                            <BarChart
                                                data={graphData.data.sort((a, b) => a[bin.prop] > b[bin.prop] ? -1 : 1).map((x, index) => {
                                                    let y: any = { ...x }
                                                    y.formattedValue = numberFormat(convertToFixed(x.totalHoursCount));
                                                    y.colorCode = colorCode[index];
                                                    return y;
                                                })}
                                                keys={[bin.prop]}
                                                indexBy={graphData.burstReportHeaders[0].headerProperty}
                                                getYAxisValueFormatter={(data: number) => {
                                                    return numberFormat(convertToFixed(data,true))
                                                }}
                                                axisBottomTickRotation={-45}
                                                ranges={bin.ranges}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    ))}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {stackedTimeCategoryData.map((data,dataIndex) => (
                        <div key={`graph_row${dataIndex}`}>
                            {data.visualizationAxisBins?.map((bin,binIndex) => (
                                <div  key={`graph_row${dataIndex}_bin_${binIndex}`}>
                                    <Typography variant='subtitle2' style={{ marginRight: 4 }}>{`${bin.label} `}</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} className={`chart-container-${Math.floor(bin.ranges.length/10)}x`}>
                                            <BarChart
                                                data={data.data}
                                                keys={data.keys[bin.prop]} 
                                                indexBy={data.xAxisLabel}
                                                getYAxisValueFormatter={(data: number) => {
                                                    let num= numberFormat(convertToFixed(data,true));
                                                    return num;
                                                }}
                                                axisBottomTickRotation={-35}
                                                ranges={bin.ranges}
                                                binName={bin.prop}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}

                        </div>
                    ))}
                </React.Fragment>
            )}
        </div>
    );
}

export default SummaryVisualizationReport;