import * as React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableSortLabel,
  Grid,
  Button,
} from "@material-ui/core";
import {
  convertToFixed,
  currencyFormat,
  numberFormat,
  StyledTableCell,
} from "../../services/UtilService";
import { ICategoryAmountStats } from "../../vm";
import SecondaryLoading from "../common/SecondaryLoading";
export interface CategoryAmountStatsForFirmsProps {
  categoryHourStats: Array<ICategoryAmountStats>;
  allCategoryHourStats: Array<ICategoryAmountStats>;
  label?: string;
  onSort: Function;
  sortBy?: any;
  onExport: Function;
  sortOrder?: any;
  isLoading?: boolean;
}

const CategoryAmountStatsForFirms: React.FC<CategoryAmountStatsForFirmsProps> = (
  props
) => {
  let allFirmsCategoryHourStatsDic = props.allCategoryHourStats.reduce(
    (acc: any, ele) => {
      acc[ele.expenseCategoryName] = ele;
      return acc;
    },
    {}
  );
  const onSort = (sortBy?: any) => {
    props.onSort(sortBy);
  };
  const onExport = () => {
    props.onExport();
  };
  return (
    <section className="position-relative">
      <Grid container spacing={2} justify="space-between" className="py-16">
        <Grid item>
          <Typography variant="h6">
            {props.label ? props.label : "Category"}
          </Typography>
        </Grid>
        {props?.categoryHourStats?.length > 0 && !props.isLoading &&
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onExport();
              }}
            >
              Export
            </Button>
          </Grid>
        }
      </Grid>
      {props.isLoading ? <SecondaryLoading /> :
        <TableContainer className="moz-table-pb">
          <Table size="small" className="custom-table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  rowSpan={2}
                  className="border text-center head-header"
                  align="center"
                >
                  <TableSortLabel
                    active={props.sortBy === "EXPENSE_CATEGORY_CODE"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("EXPENSE_CATEGORY_CODE");
                    }}
                  >
                    Expenses
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  className="border text-center"
                  align="center"
                >
                  <TableSortLabel
                    active={props.sortBy === "Total_Expenses"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Total_Expenses");
                    }}
                  >
                    Total Expenses
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  className="border text-center"
                  align="center"
                >
                  <TableSortLabel
                    active={props.sortBy === "Avg_Amount"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Avg_Amount");
                    }}
                  >
                    Avg Amount
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  className="border text-center"
                  align="center"
                >
                  <TableSortLabel
                    active={props.sortBy === "High_Amount"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("High_Amount");
                    }}
                  >
                    High Amount
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  className="border text-center"
                  align="center"
                >
                  <TableSortLabel
                    active={props.sortBy === "Low_Amount"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Low_Amount");
                    }}
                  >
                    Low Amount
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  className="border text-center"
                  align="center"
                >
                  <TableSortLabel
                    active={props.sortBy === "Expense_Entries"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Expense_Entries");
                    }}
                  >
                    # Expense Entries
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  className="border text-center"
                  align="center"
                >
                  <TableSortLabel
                    active={props.sortBy === "Per_Total_Expenses"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Per_Total_Expenses");
                    }}
                  >
                    % Of Total
                  </TableSortLabel>
                </StyledTableCell>
              </TableRow>

              <TableRow>
                <StyledTableCell className="border" align="center">
                  All
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  Selected
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  All
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  Selected
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  All
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  Selected
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  All
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  Selected
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  All
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  Selected
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  All
                </StyledTableCell>
                <StyledTableCell className="border" align="center">
                  Selected
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.categoryHourStats?.length > 0 ? (
                <React.Fragment>
                  {props.categoryHourStats.map((categoryHour, index) => (
                    <TableRow key={`categoryStats${index}`}>
                      <TableCell className="head-column">
                        {categoryHour.expenseCategoryName}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(
                            allFirmsCategoryHourStatsDic[
                              categoryHour.expenseCategoryName
                            ]?.totalExpenses
                          )
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(categoryHour.totalExpenses))}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(
                            allFirmsCategoryHourStatsDic[
                              categoryHour.expenseCategoryName
                            ]?.avgAmount
                          )
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(categoryHour.avgAmount)
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(
                            allFirmsCategoryHourStatsDic[
                              categoryHour.expenseCategoryName
                            ]?.highAmount
                          )
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(categoryHour.highAmount)
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(
                            allFirmsCategoryHourStatsDic[
                              categoryHour.expenseCategoryName
                            ]?.lowAmount
                          )
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(
                          convertToFixed(categoryHour.lowAmount)
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(
                          convertToFixed(
                            allFirmsCategoryHourStatsDic[
                              categoryHour.expenseCategoryName
                            ]?.expenseEntries
                          )
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(categoryHour.expenseEntries))}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(
                          convertToFixed(
                            allFirmsCategoryHourStatsDic[
                              categoryHour.expenseCategoryName
                            ]?.perTotalExpenses
                          )
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(categoryHour.perTotalExpenses))}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ) : (
                <TableRow>
                  <TableCell colSpan={13} align="center">
                    No Data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </section>
  );
};

export default CategoryAmountStatsForFirms;
