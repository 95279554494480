
import * as React from "react";
import { useState } from "react";

interface IIsReadOnly {
  isReadOnly: boolean;
  updateStatus: Function;
  userIP: string;
  setUserIP: Function;
}


export const IsReadOnlyContext = React.createContext<IIsReadOnly>({
  isReadOnly: false,
  updateStatus: () => { },
  userIP: "",
  setUserIP: () => { },
});

const IsReadOnlyProvider: React.FunctionComponent<any> = (props) => {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [userIP, setUserIP] = useState("");

  const isReadOnlyContext = React.useMemo(
    () => ({
      isReadOnly: isReadOnly,
      updateStatus: (isReadOnly: boolean) => {
        setIsReadOnly(isReadOnly);
      },
      userIP: userIP,
      setUserIP: (ip: string) => {
        localStorage.setItem("userIp",ip)
        setUserIP(ip);
      },
    }),
    [isReadOnly, userIP]
  );
  return (
    <IsReadOnlyContext.Provider value={isReadOnlyContext}>
      {props.children}
    </IsReadOnlyContext.Provider>
  );
};

export default IsReadOnlyProvider;

export const withIsReadOnlyContext = (ChildComponent: any) => (props: any) => {
  const { isReadOnly, updateStatus, setUserIP, userIP } = React.useContext(IsReadOnlyContext);
  return <ChildComponent {...props} isReadOnly={isReadOnly} updateStatus={updateStatus} userIP={userIP} setUserIP={setUserIP} />;
};