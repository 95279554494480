import React from "react";
import { IReactSelect, IResource, IResourceAliasAssign } from "../../vm";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import { addNewResourceAlias, getResourceDetailsAPI, getResourcesListForAlias } from "../../services/ResourceService";
import ReactSelect from "react-select";
import { RESOURCE_APPLICABLE_STATUS } from "../../Constant";
import AddResourceAliasDetails from "./AddResourceAliasDetails";
import AddAliasDialog from "./AddAliasDialog";
import "./resources.css"
export interface IAddResourceAliasProps {
    data?: IResource;
    handleDialogClose: any;
    updateStatus: any;
}

const AddResourceAlias: React.FC<IAddResourceAliasProps> = ({
    data,
    handleDialogClose,
    updateStatus
}) => {
    // state
    const [state, setState] = React.useState({
        details: {
            resource: null,
            applicableStatues: [RESOURCE_APPLICABLE_STATUS[0]]
        },
        resourceDetails: undefined,
        isResourceEdit: false,
        manageEditAliasDialog: {
            isOpen: false,
            data: undefined,
        },

    } as {
        details: any;
        resourceDetails: IResource;
        isResourceEdit: boolean;
        manageEditAliasDialog: {
            isOpen: boolean;
            data?: any;
        };
    });

    const [resourcesList, setResourcesList] = React.useState([] as IReactSelect[]);

    React.useEffect(() => {
        fetchResourcesList();
    }, [])

    const fetchResourcesList = async () => {
        setLoading(true);
        const result = await getResourcesListForAlias(data.lawFirmCode);
        if (result?.isSuccess) {
            let resources: IReactSelect[] = result.data.map(resource => {
                return {
                    label: resource,
                    value: resource
                } as IReactSelect
            })
            resources = resources.filter(resource => resource.value !== data.resourceName);
            setResourcesList(resources);
        } else {
            showToast(
                result?.message ? result.message : "Error while fetching resources list",
                "error"
            );
        }
        setLoading(false);
    }


    const [isLoading, setLoading] = React.useState(false);

    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    const closeAddDialog = (data?: boolean) => {
        handleDialogClose(data);
    };

    const getResourceDetails = async (resourceName, lawFirmCode) => {
        setLoading(true);
        let result = await getResourceDetailsAPI(resourceName, lawFirmCode);
        if (result?.isSuccess) {
            setState({
                ...state,
                resourceDetails: {
                    ...result.data
                },
                isResourceEdit: true,
                manageEditAliasDialog: {
                    isOpen: false,
                    data: undefined,
                },
            });

        } else {
            showToast(result.message || "Error while getting resource details", "error")
        }
        setLoading(false)
    }

    const handleEditResource = () => {
        setState({
            ...state,
            manageEditAliasDialog: {
                isOpen: true,
                data: state.resourceDetails,
            },
        });
    }

    const handleAddDialogClose = (isUpdated?: boolean) => {
        if (isUpdated) {
            getResourceDetails(state.resourceDetails.resourceName, state.resourceDetails.lawFirmCode)
        } else {
            setState({
                ...state,
                manageEditAliasDialog: {
                    isOpen: false,
                    data: undefined,
                },
            });
        }
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                variant="medium"
                title={"Add Resource Alias"}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <Grid container direction="column" spacing={2} className="firm-configure-dialog">
                    <Grid item lg={12} md={12} sm={12}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={state.details}
                            validate={(values) => {
                                let errors: FormikErrors<any> = {};
                                if (!values.resource) {
                                    errors["resource"] = "Required";
                                }
                                if (!values.applicableStatues || values.applicableStatues.length === 0) {
                                    errors["applicableStatues"] = "Required";
                                }
                                return errors;
                            }}
                            onSubmit={async (values: any, { setSubmitting }) => {
                                setLoading(true);
                                let obj: IResourceAliasAssign = {
                                    prevResourceName: data.resourceName,
                                    newResourceName: values.resource.value,
                                    lawFirmCode: data.lawFirmCode
                                };
                                obj.applicableStatues = values.applicableStatues.map(x => x.value).join("");
                                let result = await addNewResourceAlias(obj);
                                setLoading(false);
                                setSubmitting(false);
                                if (result?.isSuccess) {
                                    if (result?.isReadOnly) {
                                        showToast(result.message, "error");
                                        handleDialogClose();
                                        updateStatus(result.isReadOnly);
                                    } else {
                                        showToast(`Alias added successfully`, "success");
                                        handleDialogClose({ isSuccess: true, changedName: values.resource.value });
                                    }
                                } else {
                                    showToast(
                                        result.message || `Error while adding alias`,
                                        "error"
                                    );
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className="position-relative">
                                            <InputLabel className="custom-react-select-label">
                                                Resource
                                            </InputLabel>
                                            <ReactSelect
                                                className={`react-select ${errors && errors.resource ? 'react-select-field-error' : ''}`}
                                                placeholder="Resource"
                                                value={values.resource}
                                                onChange={(resource) => {
                                                    setFieldValue("resource", resource);
                                                    if (resource) {
                                                        getResourceDetails(resource.value, data.lawFirmCode)
                                                    }
                                                }}
                                                options={resourcesList}
                                                error={
                                                    errors && errors.resource ? true : false
                                                }
                                            />
                                            {errors && errors.resource && (
                                                <FormHelperText className="text-danger">
                                                    {errors.resource}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        {values.resource && (
                                            <React.Fragment>
                                                <Grid item lg={12}>
                                                    <FormControl margin="normal" required fullWidth>
                                                        <InputLabel className="custom-react-select-label react-select-label">
                                                            Applicable Status
                                                        </InputLabel>
                                                        <ReactSelect
                                                            className={`react-select ${errors && errors.applicableStatues ? 'react-select-field-error' : ''}`}
                                                            placeholder="Applicable Status"
                                                            value={values.applicableStatues}
                                                            onChange={(data) => {
                                                                if (data && data.length > 1) {
                                                                    let isAllEntriesSelected = data.find(x => x.value === "12345");
                                                                    if (isAllEntriesSelected) {
                                                                        showToast("You cannot select all entries and others at a time", "error")
                                                                    } else {
                                                                        setFieldValue("applicableStatues", data);
                                                                    }
                                                                } else {
                                                                    setFieldValue("applicableStatues", data);
                                                                }
                                                            }}
                                                            isMulti
                                                            options={RESOURCE_APPLICABLE_STATUS}
                                                            error={
                                                                errors && errors.applicableStatues ? true : false
                                                            }
                                                        />
                                                        {errors && errors.applicableStatues && (
                                                            <FormHelperText className="text-danger">
                                                                {errors.applicableStatues}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                        {state.isResourceEdit && (
                                            <Grid item lg={12} className="resource-details-container">
                                                <AddResourceAliasDetails data={state.resourceDetails}
                                                    handleDialogClose={handleEditResource}
                                                    isNewResource={false}
                                                    isReadOnly={true}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} justify="flex-end">
                                                <Grid item>
                                                    {" "}
                                                    <Button variant="outlined"
                                                        onClick={() => handleDialogClose()}
                                                        color="primary"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" type="submit" color="primary">
                                                        Add
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </CustomDialogDrawer>
            {(state.manageEditAliasDialog.isOpen) && (
                <AddAliasDialog
                    isNewResource={false}
                    data={{
                        ...state.manageEditAliasDialog.data,
                        professionalCharacterizationHistories: state.manageEditAliasDialog.data.professionalCharacterizationHistories && state.manageEditAliasDialog.data.professionalCharacterizationHistories.length > 0 ? state.manageEditAliasDialog.data.professionalCharacterizationHistories : [{
                            timeProfessionalCharacterizationCode: "",
                            timeProfessionalCharacterizationCurrentIndicator: "n",
                            timeProfessionalCharacterizationStartDate: undefined,
                            timeProfessionalCharacterizationEndDate: undefined
                        }]
                    }}
                    handleDialogClose={(data?: any) => handleAddDialogClose(data)}
                    updateStatus={updateStatus}
                />
            )}
        </React.Fragment>
    );
};

export default AddResourceAlias;
