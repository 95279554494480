import {
    IStandardAPIResponse,
    IReport,
    IReportAPIDetails, IReportChart, IReportResponse, ITimeSummaryReport, ITimeSummaryCrossTableReport
} from "../vm";
import { httpClient } from "./UtilService";

export async function getAllReportsListAPI(): Promise<IStandardAPIResponse<IReport[]>> {
    try {
        let res = await httpClient<IReport[]>(`cognos-reports`, "GET");
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getAllReportAPIDetails(reportId): Promise<IStandardAPIResponse<IReportAPIDetails[]>> {
    try {
        let res = await httpClient<IReportAPIDetails[]>(`cognos-reports/routes/${reportId}`, "GET");
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getGraphDataForFilter(url, search, httpType): Promise<IStandardAPIResponse<IReportChart[]>> {
    try {
        let res = await httpClient<IReportChart[]>(url, httpType || "POST", search);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getTimeDetailReportDetails(search): Promise<IStandardAPIResponse<IReportResponse[]>> {
    try {
        let res = await httpClient<IReportResponse[]>(`burst-reports/summary`, "POST", search);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getTimeSummaryReportDetails(search): Promise<IStandardAPIResponse<IReportResponse[]>> {
    try {
        let res = await httpClient<IReportResponse[]>(`burst-reports/detail`, "POST", search);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
export async function getTimeSummaryCrossTabDetails(search): Promise<IStandardAPIResponse<ITimeSummaryCrossTableReport>> {
    try {
        let res = await httpClient<ITimeSummaryCrossTableReport>(`burst-reports/cross-tab-report`, "POST", search);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getChangedTimeEntriesReportDetails(search): Promise<IStandardAPIResponse<IReportResponse[]>> {
    try {
        let res = await httpClient<IReportResponse[]>(`burst-reports/changed-time-entries`, "POST", search);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getImportedTimeEntryReportDetails(search): Promise<IStandardAPIResponse<IReportResponse[]>> {
    try {
        let res = await httpClient<IReportResponse[]>(`burst-reports/detail2`, "POST", search);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getSplitEntriesReportDetails(search): Promise<IStandardAPIResponse<IReportResponse[]>> {
    try {
        let res = await httpClient<IReportResponse[]>(`burst-reports/split-entries-report`, "POST", search);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}