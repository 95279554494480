import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper, Typography } from '@material-ui/core';

interface ISecondaryLoadingProps {
    message?: string;
}

const SecondaryLoading: React.FC<ISecondaryLoadingProps> = ({ message }) => {
    return (
        <div className='secondary-loading'>
            <div className='text-center'>
                <CircularProgress />
            </div>
            {/* <Typography variant="subtitle2" className='loading my-2'>{message || 'Loading Data. Please stand by..'}</Typography> */}
        </div>
    );
};

export default SecondaryLoading;
