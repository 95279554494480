import {
    Button,
    Grid,
} from "@material-ui/core";
import * as React from "react";
import { ITimeEntry } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import { ToastContext } from "../common/ToastProvider";
import ViewTimesheetTable from "./ViewTimesheetTable";
import ManageTimeEntry from "./ManageTimeEntry";
import { splitTimeEntries } from "../../services/TimesheetService";
import Loading from "../common/Loading";

export interface ManageSplitTimeEntryDialogProps {
    data?: ITimeEntry;
    handleDialogClose: Function;
    updateStatus: Function;
}

const ManageSplitTimeEntryDialog: React.FC<ManageSplitTimeEntryDialogProps> = ({
    data,
    handleDialogClose,
    updateStatus
}) => {
    const [state, setState] = React.useState({
        timeEntry: {
            ...data,
        },
        splitEntries: [],
        selectedIndex: -1,
        updatedRandomNumber: null
    } as {
        timeEntry: ITimeEntry;
        splitEntries: ITimeEntry[];
        selectedIndex: number;
        updatedRandomNumber: number;
    });
    const { showToast } = React.useContext(ToastContext);
    const [isLoading, setLoading] = React.useState(false);

    const closeAddDialog = (data?: ITimeEntry[]) => {
        handleDialogClose(data);
    };

    const handleTimeEntryEdit = (index: number) => {
        const timeEntryObj = state.splitEntries[index];
        setState({ ...state, timeEntry: timeEntryObj, selectedIndex: index, updatedRandomNumber: Math.random() });
    };

    const deleteEntry = (index: number) => {
        const splitEntries = [...state.splitEntries];
        splitEntries.splice(index, 1);
        let selectedIndex = state.selectedIndex;
        if (selectedIndex === index) {
            selectedIndex = -1;
        }
        setState({ ...state, splitEntries, selectedIndex: selectedIndex });
    };


    const updateSplitEntries = (updatedData) => {
        const list = [...state.splitEntries];
        if (state.selectedIndex !== -1) {
            list[state.selectedIndex] = updatedData;
        } else {
            list.push(updatedData);
        }
        setState({
            ...state,
            timeEntry: {
                ...data,
            },
            splitEntries: list,
            selectedIndex: -1,
            updatedRandomNumber: Math.random()
        });
    }

    const saveSplitEntries = async () => {
        if (state.splitEntries.length) {
            let requestObj = {
                timeCommonBenefitId: data.timeCommonBenefitId,
                splitEntries: state.splitEntries
            }
            setLoading(true);
            const result = await splitTimeEntries(requestObj);
            if (result?.isSuccess) {
                if (result?.isReadOnly) {
                    showToast(result.message, "error");
                    handleDialogClose();
                    updateStatus(result.isReadOnly);
                } else {
                    showToast("Split successfully done", "success");
                    handleDialogClose(true);
                }
            } else {
                showToast(result.message || "Error while saving split entries", "error");
                setLoading(false);
            }
        } else {
            showToast("Please add time sheets to split", "error");
        }

    }

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDrawer title={"Split Time Entry"} onClose={() => closeAddDialog()} fullScreen={true}>
                <section className="p-16">
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={4} sm={12}>
                            <ManageTimeEntry isDataChanged={state.updatedRandomNumber} selectedIndex={state.selectedIndex} editType="splitTime" handleDialogClose={updateSplitEntries} data={state.timeEntry} />
                        </Grid>
                        <Grid item lg={8} md={8} sm={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12}>
                                    <p><strong>Original Time Entry</strong></p>
                                    <ViewTimesheetTable
                                        from="timesheets-split-header"
                                        timeEntries={[{ ...data }]}
                                    />
                                </Grid>
                                <Grid item lg={12} style={{ paddingTop: 24 }}>
                                    <p><strong>Split Time Entries</strong></p>
                                    <ViewTimesheetTable
                                        from="timesheets-split"
                                        timeEntries={state.splitEntries}
                                        handleAddDialogOpen={handleTimeEntryEdit}
                                        deleteEntry={deleteEntry}
                                        selectedIndex={state.selectedIndex}
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <Grid container spacing={2} justify="flex-end">
                                        <Grid item>
                                            <Button variant="text" onClick={() => closeAddDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={state.splitEntries.length === 0}
                                                onClick={() => saveSplitEntries()}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
            </CustomDrawer>
        </React.Fragment>
    );
};

export default ManageSplitTimeEntryDialog;
