import * as React from 'react';
import {
    Checkbox,
    Grid, IconButton, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { currencyFormat, StyledTableCell, StyledTableRow } from "../../services/UtilService";
import moment from "moment";
import { IExpense } from '../../vm';
import { RECEIPT_TYPES, TIMESHEET_STATUS } from '../../Constant';
import { ExclamationThick, Pencil } from 'mdi-material-ui';
interface ViewExpenseTableProps {
    from: "expenses" | "notes";
    expensesList: IExpense[];
    isAllSelected?: boolean;
    selectAll?: Function;
    handleSelectChange?: Function;
    handleViewLogsDialog?: Function;
    handleAddDialogOpen?: Function;
    handleRuleFailedDialogOpen?: Function;
}

const ViewExpenseTable: React.FC<ViewExpenseTableProps> = (props) => {
    return (
        <TableContainer className={props.from === "expenses" ? "expense-detailed-timesheet-table moz-table-pb" : "moz-table-pb"}>
            <Table size="small" className="custom-table">
                <TableHead>
                    <TableRow>
                        {props.from === "expenses" && (
                            <StyledTableCell>
                                <Checkbox
                                    size="small"
                                    checked={props.isAllSelected}
                                    value={props.isAllSelected}
                                    onClick={() => {
                                        props.selectAll();
                                    }}
                                />
                            </StyledTableCell>
                        )}
                        <StyledTableCell>Expense Date</StyledTableCell>
                        <StyledTableCell>Category Name</StyledTableCell>
                        <StyledTableCell width="200px">
                            Detailed Description of Work performed
                        </StyledTableCell>
                        <StyledTableCell>Amount</StyledTableCell>
                        <StyledTableCell>Approved by</StyledTableCell>
                        <StyledTableCell>Receipt Provided</StyledTableCell>
                        <StyledTableCell>Receipt Description</StyledTableCell>
                        <StyledTableCell align="center">Class Work</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell align="center">Version</StyledTableCell>
                        {props.from === "expenses" && (
                            <StyledTableCell>Actions</StyledTableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.expensesList.length > 0 ? (
                        props.expensesList.map((ele, index) => (
                            <StyledTableRow key={index}>
                                {props.from === "expenses" && (
                                    <StyledTableCell>
                                        <Checkbox
                                            size="small"
                                            checked={ele.isSelected ? true : false}
                                            // value={ele.isSelected}
                                            onClick={() => {
                                                props.handleSelectChange(index);
                                            }}
                                        />
                                    </StyledTableCell>
                                )}
                                <StyledTableCell>
                                    {moment(ele.expenseDate).format("MM/DD/YYYY")}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {ele.expenseCategoryName}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {ele.detailedDescription}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {currencyFormat(ele.expenseAmount)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {ele.expenseApprovedByName}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {ele?.receiptYesNo ? RECEIPT_TYPES[ele.receiptYesNo.toLowerCase()] : ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {ele.receiptExplain}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {ele.classActionWorkIndicator}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {TIMESHEET_STATUS[ele.status || ""]}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {props.from === "expenses" ? (
                                        <React.Fragment>
                                            {ele.version > 1 ? (
                                                <Typography
                                                    className="MuiLink-underlineHover pointer"
                                                    component="a"
                                                    align="center"
                                                    color="primary"
                                                    onClick={() => props.handleViewLogsDialog(true, index)}
                                                >
                                                    {/* {ele.version}&nbsp;<History /> */}
                                                    {ele.version}
                                                </Typography>
                                            ) : (
                                                ele.version
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {ele.version}
                                        </React.Fragment>
                                    )}
                                </StyledTableCell>
                                {props.from === "expenses" && (
                                    <StyledTableCell>
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            wrap="nowrap"
                                            className="d-flex flex-row"
                                        >
                                            <Grid item>
                                                <Tooltip title="Edit Entry" placement="bottom">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            props.handleAddDialogOpen(true, index)
                                                        }
                                                    >
                                                        <Pencil />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {ele.hasAttention && (
                                                <Grid item>
                                                    <Tooltip
                                                        title="View Rules Failed"
                                                        placement="bottom"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                props.handleRuleFailedDialogOpen(true, index)
                                                            }
                                                        >
                                                            <ExclamationThick color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </StyledTableCell>
                                )}
                            </StyledTableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell align="center" colSpan={10}>
                                No Entries found
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ViewExpenseTable;