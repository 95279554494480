import * as React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { ChevronDown, ChevronUp } from "mdi-material-ui";

export interface ToggleFilterSectionProps {
    toggleFilter: Function;
    classes:any;
    isFilterOpen: boolean;
    showFilterClass?:string;
}

const ToggleFilterSection: React.FC<ToggleFilterSectionProps> = (
    props
) => {
    return (
        <Hidden mdDown>
            <Grid className={`${props.classes.toggleFilterSection} ${props.showFilterClass||''}`} onClick={()=>props.toggleFilter()}>
                <div className={props.classes.toggleContent}>
                    {props.isFilterOpen ? "Hide" : "Show"} Filters {props.isFilterOpen ? <ChevronDown /> : <ChevronUp />}
                </div>
            </Grid>
        </Hidden>
    );
}

export default ToggleFilterSection;