import * as React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableSortLabel,
  Grid,
  Button,
} from "@material-ui/core";
import { convertToFixed, currencyFormat, hoursFormat, numberFormat, StyledTableCell } from "../../services/UtilService";
import { ICategoryHourStats } from "../../vm";
import SecondaryLoading from "../common/SecondaryLoading";
export interface CategoryHourStatsForFirmsProps {
  categoryHourStats: Array<ICategoryHourStats>;
  allCategoryHourStats: Array<ICategoryHourStats>;
  label?: string;
  onSort: Function;
  sortBy?: any;
  onExport: Function;
  sortOrder?: any;
  isLoading?: boolean;
}

const CategoryHourStatsForFirms: React.FC<CategoryHourStatsForFirmsProps> = (props) => {
  let allFirmsCategoryHourStatsDic = props.allCategoryHourStats.reduce((acc: any, ele) => {
    acc[ele.categoryName] = ele;
    return acc;
  }, {});
  const onSort = (sortBy?: any) => {
    props.onSort(sortBy);
  };
  const onExport = () => {
    props.onExport();
  };
  return (
    <section className="position-relative">
      <Grid container spacing={2} justify="space-between" className="py-16">
        <Grid item>
          <Typography variant="h6">
            {props.label ? props.label : "Category"}
          </Typography>
        </Grid>
        {props?.categoryHourStats?.length > 0 && !props.isLoading &&
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onExport();
              }}
            >
              Export
            </Button>
          </Grid>
        }
      </Grid>
      {props.isLoading ? <SecondaryLoading /> :
        <TableContainer className="moz-table-pb">
          <Table size="small" className="custom-table">
            <TableHead>
              <TableRow>
                <StyledTableCell rowSpan={2} className="border text-center head-header" align="center">
                  <TableSortLabel
                    active={props.sortBy === "CATEGORY_CODE"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("CATEGORY_CODE");
                    }}
                  >
                    Category
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "Total_Fees"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Total_Fees");
                    }}
                  >
                    Total Fees
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "Avg_Hourly_Rate"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Avg_Hourly_Rate");
                    }}
                  >
                    Avg Hourly Rate
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "High_Hourly_Rate"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("High_Hourly_Rate");
                    }}
                  >
                    High Hourly Rate
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "Low_Hourly_Rate"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Low_Hourly_Rate");
                    }}
                  >
                    Low Hourly Rate
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "Total_Hours"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Total_Hours");
                    }}
                  >
                    Total # Hours
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "Avg_Hours"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Avg_Hours");
                    }}
                  >
                    Avg # Hours
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "High_Hours"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("High_Hours");
                    }}
                  >
                    High # Hours
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "Low_Hours"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Low_Hours");
                    }}
                  >
                    Low # Hours
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "Time_Entries"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Time_Entries");
                    }}
                  >
                    # Time Entries
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "Employees"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Employees");
                    }}
                  >
                    # Resources
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell colSpan={2} className="border text-center" align="center">
                  <TableSortLabel
                    active={props.sortBy === "Per_Total_Time"}
                    direction={props.sortOrder}
                    onClick={() => {
                      onSort("Per_Total_Time");
                    }}
                  >
                    % of Total Time
                  </TableSortLabel>
                </StyledTableCell>
              </TableRow>

              <TableRow>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
                <StyledTableCell className="border" align="center">All</StyledTableCell>
                <StyledTableCell className="border" align="center">Selected</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.categoryHourStats?.length > 0 ? (
                <React.Fragment>
                  {props.categoryHourStats.map((categoryHour, index) => (
                    <TableRow key={`categoryStats${index}`}>
                      <TableCell className="head-column">{categoryHour.categoryName}</TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.totalFees))}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(categoryHour.totalFees))}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.avgHourlyRate))}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(categoryHour.avgHourlyRate))}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.highHourlyRate))}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(categoryHour.highHourlyRate))}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.lowHourlyRate))}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(convertToFixed(categoryHour.lowHourlyRate))}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.totalHours)}
                        {/* {numberFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.totalHours))} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(categoryHour.totalHours)}
                        {/* {numberFormat(convertToFixed(categoryHour.totalHours))} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.avgHours)}
                        {/* {numberFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.avgHours))} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(categoryHour.avgHours)}
                        {/* {numberFormat(convertToFixed(categoryHour.avgHours))} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.highHours)}
                        {/* {numberFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.highHours))} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(categoryHour.highHours)}
                        {/* {numberFormat(convertToFixed(categoryHour.highHours))} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.lowHours)}
                        {/* {numberFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.lowHours))} */}
                      </TableCell>
                      <TableCell align="right">
                        {hoursFormat(categoryHour.lowHours)}
                        {/* {numberFormat(convertToFixed(categoryHour.lowHours))} */}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.timeEntries))}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(categoryHour.timeEntries))}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.employees))}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(categoryHour.employees))}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(allFirmsCategoryHourStatsDic[categoryHour.categoryName]?.perTotalTime))}
                      </TableCell>
                      <TableCell align="right">
                        {numberFormat(convertToFixed(categoryHour.perTotalTime))}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ) : (
                <TableRow>
                  <TableCell colSpan={23} align="center">No Data found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </section>
  );
};

export default CategoryHourStatsForFirms;
