import * as React from "react";
import { ITimeEntry } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import ManageTimeEntry from "./ManageTimeEntry";

export interface ManageTimeEntryDialogProps {
  data?: ITimeEntry;
  handleDialogClose: Function;
  isBulkEdit?: boolean;
}

const ManageTimeEntryDialog: React.FC<ManageTimeEntryDialogProps> = ({
  data,
  handleDialogClose,
  isBulkEdit
}) => {

  return (
    <React.Fragment>
      <CustomDrawer title={isBulkEdit ? "Bulk Edit" : "Edit Time Entry"} onClose={() => handleDialogClose()}>
        <ManageTimeEntry editType={isBulkEdit?"bulkEdit":"timeEntry"} handleDialogClose={handleDialogClose} data={data} />
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ManageTimeEntryDialog;
