import * as React from "react";
import { useState } from "react";
import CustomDrawer from "../common/CustomDrawer";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { ILogin } from "../../vm";

interface ManageAuthorizedCasesDialogProps {
  data?: {
    userValues: ILogin;
    caseNamesList: string[];
  };
  handleDialogClose: Function;
}

const ManageAuthorizedCasesDialog: React.FunctionComponent<
  ManageAuthorizedCasesDialogProps
> = ({ data, handleDialogClose }) => {
  const [selectedCaseName, setSelectedCaseName] = useState<string>(data.caseNamesList[0]);

  const closeAddDialog = (caseName?: string) => {
    handleDialogClose(caseName);
  };

  return (
    <React.Fragment>
      <CustomDrawer title={"Choose a Case"} onClose={() => closeAddDialog()}>
        <section className="p-16">
          <Grid container spacing={1}>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <List component="nav" aria-label="secondary mailbox folder">
                {data.caseNamesList.map((caseName: string, index: number) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedCaseName === caseName}
                    onClick={(event) => setSelectedCaseName(caseName)}
                  >
                    <ListItemText primary={caseName} />
                  </ListItem>
                ))}
              </List>
            </Grid> */}
            <Grid item xs={12}>
              <RadioGroup value={selectedCaseName}>
                {data.caseNamesList.map((caseName: string, index: number) => (
                  <FormControlLabel
                    value={caseName}
                    control={<Radio onClick={(event) => setSelectedCaseName(caseName)}/>}
                    label={caseName}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justify="flex-end">
                <Grid item>
                  <Button variant="text" onClick={() => closeAddDialog()}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!selectedCaseName}
                    onClick={() => closeAddDialog(selectedCaseName)}
                  >
                    {"Continue"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ManageAuthorizedCasesDialog;
