import { Button, Grid, Hidden } from "@material-ui/core";
import * as React from "react";
import { getFileName, getSubDomain, getToken } from "../../services/UtilService";
import {
  getExpenseTotalAmountFeeReport,
  getExpenseStatusCountReport,
  getExpenseCategoryAmountStatsDetails,
  getExpenseTopFirmsCategory
} from "../../services/ExpenseDashboardService";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import { FirmContext } from "../common/FirmProvider";
import Filter from "../common/Filter";
import {
  IFilter,
  ICategoryHourStats,
  ICategoryAmountStats,
  IKeyValue,
  IFirmDetails,
  IPerson,
  ITopExpenseFirm,
  ITopExpenseCategory,
  ITimeFilter,
} from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import { useHistory } from "react-router-dom";
import { getAllCategories, getAllWorkTypes } from "../../services/TimesheetService";
import {
  getAllCategoriesForExpenses,
  getExpensesFiltersCount,
} from "../../services/ExpenseService";
import {
  API_URL,
  styles,
  TIMESHEET_STATUS,
  TIME_ENTRY_KEY_WORDS,
} from "../../Constant";
import ExpenseStatusStats from "./ExpenseStatusStats";
import ExpenseStatsForAllFirms from "./ExpenseStatsForAllFirms";
import ExportDialog from "../common/ExportDialog";
import CategoryAmountStats from "./CategoryAmountStats";
import CategoryAmountStatsForFirms from "./CategoryAmountStatsForFirms";
import TopFirmsAndCategoryForExpense from "./TopFirmsAndCategoryForExpense";
import moment from "moment";
import ToggleFilterSection from "../dashboard/ToggleFilter";

export interface ExpenseDashboardMainProps {
  isFirm: boolean;
}

const ExpenseDashboardMain: React.FC<ExpenseDashboardMainProps> = (props) => {
  const history = useHistory();
  const [state, setState] = React.useState({
    searchObj: {
      from: 0,
      size: 10,
      statuses: [],
      firms: [],
      lawFirmCode: [],
      persons: [],
      startDate: undefined,
      endDate: undefined,
      sortBy: "",
      sortOrder: "asc",
      professionalLevelSortBy: "PROFESSIONAL_LEVEL_SORT",
      professionalLevelSortOrder: "asc",
      categoryHourSortBy: "EXPENSE_CATEGORY_CODE",
      categoryHourSortOrder: "asc",
      keyword: [],
      min: "",
      max: "",
      categoryCode: [],
      workType: [],
      professionalLevel: [],
    },
    firmsList: [],
  } as {
    searchObj: {
      from: number;
      size: number;
      firms: string[];
      lawFirmCode: string[];
      persons: string[];
      statuses: string[];
      startDate?: Date;
      endDate?: Date;
      sortOrder?: string;
      sortBy?: string;
      professionalLevelSortBy?:
      | ""
      | "PROFESSIONAL_LEVEL_SORT"
      | "Total_Fees"
      | "Avg_Hourly_Rate"
      | "High_Hourly_Rate"
      | "Low_Hourly_Rate"
      | "Total_Hours"
      | "Avg_Hours"
      | "High_Hours"
      | "Low_Hours"
      | "Employees"
      | "Per_Total_Time"
      | "Time_Entries";
      professionalLevelSortOrder?: "asc" | "desc";
      categoryHourSortBy?:
      | ""
      | "EXPENSE_CATEGORY_CODE"
      | "Total_Expenses"
      | "Avg_Amount"
      | "High_Amount"
      | "Low_Amount"
      | "Expense_Entries"
      | "Per_Total_Expenses";
      categoryHourSortOrder?: "asc" | "desc";
      keyword: string[];
      categoryCode: any;
      workType: any;
      professionalLevel: any;
      min?: any;
      max?: any;
    };
    firmsList: { value: any; label: any }[];
  });

  const [
    isExportDialogOpenForAllFirms,
    setExportDialogOpenForAllFirms,
  ] = React.useState(false);
  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [exportFor, setExportFor] = React.useState("");
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [firmsDic, setFirmsDic] = React.useState({} as IKeyValue<IFirmDetails>);

  const [categoryCodeDic, setCategoryCodeDic] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [personsDic] = React.useState({} as IKeyValue<IPerson>);
  const { showToast } = React.useContext(ToastContext);
  const firmProvider = React.useContext(FirmContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [initialFirms, setInitialFirms] = React.useState(
    {} as IKeyValue<IFirmDetails>
  );

  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [, setCategories] = React.useState([]);
  const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);

  const [timesheetCountDic, setTimesheetCountDic] = React.useState(
    {} as IKeyValue<number>
  );
  const [
    allFirmsTimesheetCountDic,
    setAllFirmsTimesheetCountDic,
  ] = React.useState({} as IKeyValue<number>);
  const [, setTimesheetCount] = React.useState(
    [] as { count: number; status: string }[]
  );
  const [, setAllFirmsTimesheetCount] = React.useState(
    [] as { count: number; status: string }[]
  );
  const [amountCount, setAmountCount] = React.useState({
    totalAmount: 0,
  });
  const [allFirmsAmountCount, setAllFirmsAmountCount] = React.useState({
    totalAmount: 0,
  });

  const [categoryHoursStatsData, setCategoryHoursStatsData] = React.useState(
    [] as Array<ICategoryAmountStats>
  );
  const [topFirmsData, setTopFirmsData] = React.useState([] as Array<ITopExpenseFirm>)
  const [topCategoriesData, setTopCategoriesData] = React.useState([] as Array<ITopExpenseCategory>)
  const [allFirmsCategoryHoursStatsData, setAllFirmsCategoryHoursStatsData] = React.useState(
    [] as Array<ICategoryAmountStats>
  );
  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});
  const [isFilterOpen, setFilterOpen] = React.useState(true);
  const [expenseCountLoading, setExpenseCountLoading] = React.useState(false);
  const [expenseTopFirmsLoading, setExpenseTopFirmLoading] = React.useState(false);
  const [expenseCategoryLoading, setExpenseCategoryLoading] = React.useState(false);
  const [loadedFilter, setLoadedFilter] = React.useState(undefined as ITimeFilter);
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] = React.useState({
    minDate: undefined,
    maxDate: undefined
  } as {
    minDate?: Date;
    maxDate?: Date
  })

  React.useEffect(() => {
    const fetchTimeSheetReport = async () => {
      setLoading(true);
      let categoriesCodeDic = await getAllCategoriesList();
      let workTypesDic = await getAllWorkTypesList();

      await getUserFirms({ categoriesCodeDic, workTypesDic });
      setLoading(false);
    };
    fetchTimeSheetReport();
  }, []);

  const getAllCategoriesList = async () => {
    const result = await getAllCategoriesForExpenses();
    let categoryCodeDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.expenseCategoryName, value: x.expenseCategoryCode };
        });
        categoryCodeDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setCategoryCodeDic(categoryCodeDic);
        setCategories(data || []);
      }
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
    return categoryCodeDic;
  };

  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(workTypesDic);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };


  const getUserFirms = async (dicData) => {
    let userFirms = firmProvider.userFirms;
    if (userFirms.length === 0) {
      userFirms = await firmProvider.getFirmsList();
    }
    let firmsDic = userFirms.reduce((acc: any, ele) => {
      acc[ele.lawFirmCode] = ele;
      return acc;
    }, {});
    setFirmsDic(firmsDic);
    setInitialFirms(firmsDic);
    await getTimesheetFilters(
      undefined,
      firmsDic,
      {},
      undefined,
      dicData
    );
  };

  const getTimesheetFilters = async (
    search?: any,
    firmsDic?: any,
    personsDic?: any,
    changedProperty?: any,
    dicData?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (search) {
      tempSearch = { ...state.searchObj, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let searchForFilter: any = { ...tempSearch };
    let keyWords = [...keyWordsList];
    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (dicData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...dicData.workTypesDic,
      };
    }
    // if (changedProperty) {
    //   switch (changedProperty) {
    //     case "firmCounts":
    //       searchForFilter.statuses = [];
    //       break;
    //     case "statusCounts":
    //       searchForFilter.firms = [];
    //       break;
    //     default:
    //       break;
    //   }
    // }
    delete tempSearch.size;
    delete tempSearch.from;
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {} as any,
    };
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      result = await getExpensesFiltersCount(searchForFilter);
    }
    if (result?.isSuccess) {
      console.log("result", result.data);
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          categoryCodeCounts: "categoryCode",
          workTypeCounts: "workType",
          firmCounts: "firmCode",
          keywordCounts: "keyword",
          statusCounts: "status",
          professionalCounts: "professionalLevel",
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            categoryCodeCounts: {},
            workTypeCounts: {},
            firmCounts: {},
            keywordCounts: {},
            statusCounts: {},
            professionalCounts: {},
          }
        );
        setFilterCountsDic(filterCountDic);
      }
      let data = { ...result.data };
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });

      let filter: IFilter[] = [];
      let initialFirmsTemp = { ...initialFirms };
      if (firmsDic) {
        initialFirmsTemp = {
          ...initialFirms,
          ...firmsDic,
        };
      }
      let initialCategoryCodeTemp = { ...initialCategoryCodes };
      if (Object.keys(initialFirmsTemp).length === 0) {
        let firmCounts = data["firmCounts"] || [];
        initialFirmsTemp = firmCounts.reduce((acc: any, ele) => {
          acc[ele.firmCode] = firmsDic[ele.firmCode] || {};
          return acc;
        }, {});
      }

      if (Object.keys(initialCategoryCodeTemp).length === 0) {
        let categoryCodeCounts = data["categoryCodeCounts"] || [];
        initialCategoryCodeTemp = categoryCodeCounts.reduce((acc: any, ele) => {
          acc[ele.categoryCode] = dicData.categoriesCodeDic[ele.categoryCode] || {};
          return acc;
        }, {});
      }

      let filterOrder = [
        "workTypeCounts",
        "time",
        "statusCounts",
        "firmCounts",
        "categoryCodeCounts",
        // "keyword",
        "min-max",
      ];
      let customFilters = ["time", "min-max"];
      delete data["personCounts"];
      if (!tempSearch.max && data?.maxTimeSpentHours) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time").isHidden) ||
                  false,
                items: [
                  {
                    type: 'date-range',
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: 'selection'
                      }
                    ],
                    label: ""
                  }
                ]
                // items: [
                //   {
                //     value: tempSearch.startDate
                //       ? tempSearch.startDate
                //       : data?.startDate,
                //     type: "date",
                //     label: "Start Date",
                //     name: "startDate",
                //   },
                //   {
                //     value: tempSearch.endDate
                //       ? tempSearch.endDate
                //       : data?.endDate,
                //     type: "date",
                //     label: "End Date",
                //     name: "endDate",
                //   },
                // ],
              });
              break;
            case "keyword":
              filter.push({
                header: "Keywords",
                name: "keyword",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "keyword").isHidden) ||
                  false,
                items: keyWords.map((ele) => {
                  return {
                    isSelected: tempSearch.keyword?tempSearch.keyword.includes(ele.value):false,
                    label: ele.label,
                    type: "checkbox",
                    name: ele.value,
                  };
                }),
              });
              break;
            case "min-max":
              // filter.push({
              //   header: "Total Time Spent for Day",
              //   name: "min-max",
              //   isHidden:
              //     (filterData.length > 0 &&
              //       filterData.find((e) => e.name === "min-max").isHidden) ||
              //     false,
              //   items: [
              //     {
              //       label: "Min",
              //       type: "number",
              //       name: "min",
              //       value: tempSearch.min,
              //     },
              //     {
              //       label: "Max",
              //       type: "number",
              //       name: "max",
              //       value: tempSearch.max,
              //     },
              //   ],
              // });
              filter.push({
                header: "Expense Amount Greater Than",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max").isHidden) ||
                  false,
                items: [
                  {
                    label: "Amount",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  }
                ],
              });
              break;
            default:
              break;
          }
        } else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "firmCounts":
              a.header = "Firm";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let firmCounts = data["firmCounts"] || [];

              Object.keys(initialFirmsTemp).map((element) => {
                a.items.push({
                  label: firmsDic[element]
                    ? firmsDic[element].lawFirmShortName
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.firms.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : firmCounts.find((e) => e.firmCode === element)
                          ?.count || 0
                      : filterCountDic?.["firmCounts"]?.[element]?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.firms?tempSearch.firms.includes(element):false,
                });
              });
              // sorting based on label i.e. firm short name
              a.items.sort((a, b) => a.label > b.label ? 1 : -1);
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: dicData.workTypesDic[element]
                    ? dicData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : workTypeCounts.find(
                          (e) => e.workType === element
                        )?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.workType?tempSearch.workType.includes(element):false,
                });
              });
              break;
            case "categoryCodeCounts":
              a.header = "Expense Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let categoryCodeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp).map((element) => {
                a.items.push({
                  label: dicData.categoriesCodeDic[element]
                    ? dicData.categoriesCodeDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.categoryCode.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : categoryCodeCounts.find(
                          (e) => e.categoryCode === element
                        )?.count || 0
                      : filterCountDic?.["categoryCodeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.categoryCode?tempSearch.categoryCode.includes(element):false,
                });
              });
              break;
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).map((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses?tempSearch.statuses.includes(eds):false,
                  label: TIMESHEET_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                });
              });
              break;
            case "personCounts":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let personsCount = data["personCounts"] || [];
              let options = [];
              let selectedItems = [];
              personsCount.map((ele) => {
                if (
                  tempSearch.persons.length > 0 &&
                  tempSearch.persons.includes(ele.personId)
                ) {
                  selectedItems.push({
                    value: ele.personId,
                    label: personsDic[ele.personId].name,
                  });
                }
                options.push({
                  value: ele.personId,
                  label: personsDic[ele.personId].name,
                });
              });
              a.items = [
                {
                  label: "Resources",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedItems || [],
                  options:
                    changedProperty !== undefined &&
                      changedProperty === ele &&
                      tempSearch.persons.length > 0
                      ? filterData.find((ed) => ed.name === ele)?.items[0]
                        .options
                      : options,
                },
              ];
              break;

            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });
      setCountsLoaded(true);
      setFilterData(filter);
      setInitialFirms(initialFirmsTemp);
      setInitialCategoryCodes(initialCategoryCodeTemp);
      tempSearch.from = 0;
      tempSearch.size = 10;
      setLoading(false);
      // set loading state as true for below methods
      setExpenseCountLoading(true);
      setExpenseTopFirmLoading(true);
      setExpenseCategoryLoading(true);

      // if (props.isFirm !== true) {
      await getDashTimesheetReport(tempSearch);
      // }
      await getTopFirmsAndCategories(tempSearch, initialFirmsTemp, initialCategoryCodeTemp);
      await getCategoryAmountDetails(tempSearch);
      if (props.isFirm) {
        await getAllFirmsCategoryAmountDetails(tempSearch);
        await getAllFirmsDashTimesheetReport(tempSearch);
      }
    } else {
      showToast(
        result.message || "Error while fetching timesheets counts",
        "error"
      );
    }
  };

  const getTopFirmsAndCategories = async (searchObj?: any, firmsDicTemp?: any, categoriesDicTemp?: any) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.categoryHourSortBy;
    tempSearch.sortOrder = tempSearch.categoryHourSortOrder;
    const result = await getExpenseTopFirmsCategory(tempSearch);
    if (result?.isSuccess) {
      let topFirms: ITopExpenseFirm[] = [...result?.data?.topFirms];
      let topCategories: ITopExpenseCategory[] = [...result?.data?.topCategories];
      topFirms.map(ele => {
        ele.lawFirmName = firmsDicTemp[ele.lawFirmCode].lawFirmShortName
      })
      topCategories.map(ele => {
        ele.expenseCategoryName = categoriesDicTemp[ele.expenseCategoryCode].label
      })
      setTopFirmsData(topFirms)
      setTopCategoriesData(topCategories)
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching top firms and category for expense",
        "error"
      );
    }
    // setLoading(false);
    setExpenseTopFirmLoading(false);
  };
  const getCategoryAmountDetails = async (searchObj?: any, firmsList?: any) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.categoryHourSortBy;
    tempSearch.sortOrder = tempSearch.categoryHourSortOrder;
    const result = await getExpenseCategoryAmountStatsDetails(tempSearch);
    if (result?.isSuccess) {
      setCategoryHoursStatsData(result.data);
      setState({
        ...state,
        searchObj: tempSearch,
      });
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching category level amount stats",
        "error"
      );
    }
    // setLoading(false);
    setExpenseCategoryLoading(false);
  };

  const getAllFirmsCategoryAmountDetails = async (
    searchObj?: any,
    firmsList?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    setLoading(true);
    tempSearch.lawFirmCode = [];
    tempSearch.sortBy = tempSearch.categoryHourSortBy;
    tempSearch.sortOrder = tempSearch.categoryHourSortOrder;
    const result = await getExpenseCategoryAmountStatsDetails(tempSearch);
    if (result?.isSuccess) {
      setAllFirmsCategoryHoursStatsData(result.data);
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching category level amount stats",
        "error"
      );
    }
    setLoading(false);
  };

  const getDashTimesheetReport = async (searchObj?: any, firmsList?: any) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      delete tempSearch.lawFirmCode;
    }

    const result = await getExpenseStatusCountReport(tempSearch);
    if (result?.isSuccess) {
      let timesheetDic = result.data.reduce(
        (acc, obj) => {
          let key = obj["status"];
          acc[key] = obj.count;
          return acc;
        },
        {
          total: result.data.reduce((acc, val) => acc + val.count, 0),
        }
      );
      setTimesheetCount(result.data);
      setTimesheetCountDic(timesheetDic);
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
        searchObj: tempSearch,
      });
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching time entries count",
        "error"
      );
    }
    const result2 = await getExpenseTotalAmountFeeReport(tempSearch);
    if (result2?.isSuccess) {
      let totalAmountCount = { ...amountCount };
      totalAmountCount.totalAmount = result2.data || 0;
      setAmountCount({ ...totalAmountCount });
    } else {
      showToast(
        result2?.message
          ? result2.message
          : "Error while fetching hours and fees count",
        "error"
      );
    }
    // setLoading(false);
    setExpenseCountLoading(false);
  };
  const getAllFirmsDashTimesheetReport = async (searchObj?: any) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    setLoading(true);
    tempSearch.firms = [];

    const result = await getExpenseStatusCountReport(tempSearch);
    if (result?.isSuccess) {
      let timesheetDic = result.data.reduce(
        (acc, obj) => {
          let key = obj["status"];
          acc[key] = obj.count;
          return acc;
        },
        {
          total: result.data.reduce((acc, val) => acc + val.count, 0),
        }
      );
      setAllFirmsTimesheetCount(result.data);
      setAllFirmsTimesheetCountDic(timesheetDic);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching time entries count",
        "error"
      );
    }
    const result2 = await getExpenseTotalAmountFeeReport(tempSearch);
    if (result2?.isSuccess) {
      let totalAmountCount = { ...amountCount };
      totalAmountCount.totalAmount = result2.data || 0;
      setAllFirmsAmountCount({ ...totalAmountCount });
    } else {
      showToast(
        result2?.message
          ? result2.message
          : "Error while fetching hours and fees count",
        "error"
      );
    }
    setLoading(false);
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    setLoading(true);
    if (changedProperty === "clear-filters") {
      setLoadedFilter(undefined);
    }
    if (changedProperty === "load-filters") {
      let details = JSON.parse(JSON.stringify(data));
      setLoadedFilter(details);
      await getTimesheetFilters(
        details.filters,
        firmsDic,
        personsDic,
        changedProperty,
        { categoriesCodeDic: categoryCodeDic, workTypesDic: { ...initialWorkTypes } }
      );
    } else {
      let search = { ...state.searchObj };
      search.from = 0;
      let statusArray =
        data.find((ele) => ele.name === "statusCounts")?.items || [];
      let professionalLevelArray =
        data.find((ele) => ele.name === "professionalCounts")?.items || [];
      let categoryCodeArray =
        data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
      let workTypesArray =
        data.find((ele) => ele.name === "workTypeCounts")?.items || [];
      let personArray =
        data.find((ele) => ele.name === "personCounts")?.items || [];
      let firmArray = data.find((ele) => ele.name === "firmCounts")?.items || [];
      let timeArray = data.find((ele) => ele.name === "time")?.items || [];
      let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
      search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
      // search.max = minMaxArray.find((ele) => ele.name === "max")?.value || "";
      // if (search.min && search.max && search.min > search.max) {
      //   showToast("Max value cannot be less than min value", "error");
      // }
      search.startDate =
        timeArray[0].value[0].startDate || undefined;
      // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
      search.endDate =
        timeArray[0].value[0].endDate || undefined;
      // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;
      search.statuses = statusArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.persons =
        personArray.length > 0
          ? personArray[0].selectedItems.length > 0
            ? personArray[0].selectedItems.map((ele) => ele.value)
            : []
          : [];
      search.firms = firmArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          if (e.name) {
            return e.name;
          }
        });
      search.professionalLevel = professionalLevelArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.categoryCode = categoryCodeArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.workType = workTypesArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let keywordsArray = data.find((ele) => ele.name === "keyword")?.items || [];
      search.keyword = keywordsArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });

      await getTimesheetFilters(
        search,
        firmsDic,
        personsDic,
        changedProperty,
        { categoriesCodeDic: categoryCodeDic, workTypesDic: { ...initialWorkTypes } }
      );
    }
    // await getProfessionalLevelDetails(search);
    // await getCategoryAmountDetails(search);
    // if (props.isFirm) {
    //   await getAllFirmsProfessionalLevelDetails();
    // await getAllFirmsCategoryAmountDetails();
    // }
  };

  const navigateToTimeSheets = (status) => {
    history.push({
      pathname: `/expenses`,
      state: {
        status: status,
        startDate: state.searchObj.startDate,
        endDate: state.searchObj.endDate,
        selectedFirm: state.searchObj.firms,
        professionalLevel: state.searchObj.professionalLevel,
        categoryCode: state.searchObj.categoryCode,
        keyword: state.searchObj.keyword,
        min: state.searchObj.min,
        max: state.searchObj.max,
      },
    });
  };

  const handleDownloadAsCsv = async (isZip?: boolean) => {
    let searchObj = { ...state.searchObj };
    let obj = {
      searchQuery: {
        ...searchObj,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        firms: searchObj.firms,
        statuses: searchObj.statuses,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        keyword: searchObj.keyword,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        sortBy: searchObj.categoryHourSortBy,
        sortOrder: searchObj.categoryHourSortOrder,
        showMultipleBillingRates: false,
        isShowAll: true,
      },
      categoryList: searchObj.categoryCode.map((ele) => initialCategoryCodes[ele].label),
      firmList: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
      workType: searchObj.workType,
      isZip: isZip ? true : false,
      localTime: moment().format("YYYYMMDDHHmmss")
    };
    let result = await fetch(
      `${API_URL}timesheets/generate-csv/dashboard-expense-category`,
      {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + (getToken() || ""),
          dbName: getSubDomain(),
        },
      }
    );
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "csv", "case");
    // pdf.type === "application/zip"
    //   ? `${
    //       exportFor === "category"
    //         ? "Timesheet Category.zip"
    //         : "Timesheet Professional Level.zip"
    //     }`
    //   : `${
    //       exportFor === "category"
    //         ? "Timesheet Category.csv"
    //         : "Timesheet Professional Level.csv"
    //     }`;
    a.click();
  };
  const handleDownload = async (isZip?: boolean) => {
    let searchObj = { ...state.searchObj };
    let obj = {
      searchQuery: {
        ...searchObj,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        firms: searchObj.firms,
        statuses: searchObj.statuses,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        keyword: searchObj.keyword,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        sortBy: searchObj.categoryHourSortBy,
        sortOrder: searchObj.categoryHourSortOrder,
        showMultipleBillingRates: false,
        isShowAll: true,
      },
      categoryList: searchObj.categoryCode.map((ele) => initialCategoryCodes[ele].label),
      firmList: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
      workType: searchObj.workType,
      isZip: isZip ? true : false,
      localTime: moment().format("YYYYMMDDHHmmss")
    };
    let result = await fetch(
      `${API_URL}timesheets/generate-pdf/dashboard-expense-category`,
      {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + (getToken() || ""),
          dbName: getSubDomain(),
        },
      }
    );
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/pdf",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "pdf", "case");
    // pdf.type === "application/zip"
    //   ? `${
    //       exportFor === "category"
    //         ? "Timesheet Category.zip"
    //         : "Timesheet Professional Level.zip"
    //     }`
    //   : `${
    //       exportFor === "category"
    //         ? "Timesheet Category.pdf"
    //         : "Timesheet Professional Level.pdf"
    //     }`;
    a.click();
  };

  const handleCloseExportDialog = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpen(false);
  };

  const handleCloseExportDialogForAllFirms = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpenForAllFirms(false);
  };

  const onSortCategoryHour = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.categoryHourSortBy) {
      search.categoryHourSortOrder =
        search.categoryHourSortOrder === "asc" ? "desc" : "asc";
    } else {
      search.categoryHourSortBy = sortBy;
      search.categoryHourSortOrder = "asc";
    }
    search.from = 0;
    setExpenseCategoryLoading(true);
    await getCategoryAmountDetails(search);
    //as we are making allFirmsCategoryHours as dictionary those will be automatically sorted
    // if (props.isFirm) {
    //   await getAllFirmsCategoryAmountDetails(search);
    // }
  };
  const onExportDialogOpen = async (type: string) => {
    setExportFor(type);
    setExportDialogOpen(true);
  };
  const onExportDialogOpenForAllFirms = async (type: string) => {
    setExportFor(type);
    setExportDialogOpenForAllFirms(true);
  };

  const classes = styles();

  return (
    <React.Fragment>
      {isLoading && <Loading message={loadingMessage} />}
      <section className="p-16 pr-0 pb-0">
        <br />
        <Grid className={`${classes.main}`}>
          <Grid className={`${isFilterOpen ? classes.filterOpenContainer : classes.filterCloseContainer}`}>
            <Hidden mdDown>
              <Grid container spacing={2}>
                <Grid item xs={12} className="dashboard-filters-height">
                  {filterData.length > 0 && (
                    <Filter
                      data={[...filterData]}
                      onChange={(data: IFilter[], changedProperty?: string) => {
                        onFilterChange(data, changedProperty);
                      }}
                      handleFilterHide={() => setFilterOpen(false)}
                      typeOfFilters="timesheets"
                      selectedFilters={state.searchObj}
                      loadedFilter={loadedFilter}
                      dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                    />
                  )}
                </Grid>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid container justify="flex-end">
                <Grid item>
                  {filterData.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setFilterDialogOpen(true)}
                    >
                      Filters
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <ToggleFilterSection showFilterClass="mt--8" classes={classes} toggleFilter={() => setFilterOpen(!isFilterOpen)} isFilterOpen={isFilterOpen} />
          {props.isFirm !== true && (
            <Grid className={`dashboard-timesheet-main-container ${isFilterOpen ? `${classes.mainOpenContainer}` : classes.mainCloseContainer}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ExpenseStatusStats
                    amountCount={amountCount}
                    timesheetCountDic={timesheetCountDic}
                    navigateToTimeSheets={(status: any) => {
                      navigateToTimeSheets(status);
                    }}
                    isLoading={expenseCountLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TopFirmsAndCategoryForExpense topCategories={topCategoriesData} topFirms={topFirmsData} isLoading={expenseTopFirmsLoading} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CategoryAmountStats
                    categoryHourStats={categoryHoursStatsData}
                    onSort={(sortBy?: any) => onSortCategoryHour(sortBy)}
                    onExport={() => onExportDialogOpen("category")}
                    sortBy={state.searchObj.categoryHourSortBy}
                    sortOrder={state.searchObj.categoryHourSortOrder}
                    isLoading={expenseCategoryLoading}
                  ></CategoryAmountStats>
                </Grid>
              </Grid>
            </Grid>
          )}
          {props.isFirm === true && (
            <Grid item xs={12} md={12} lg={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ExpenseStatsForAllFirms
                    allFirmsAmountCount={allFirmsAmountCount}
                    allFirmsTimesheetCountDic={allFirmsTimesheetCountDic}
                    amountCount={amountCount}
                    timesheetCountDic={timesheetCountDic}
                    navigateToTimeSheets={(status: any) =>
                      navigateToTimeSheets(status)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TopFirmsAndCategoryForExpense topCategories={topCategoriesData} topFirms={topFirmsData} isLoading={expenseTopFirmsLoading} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CategoryAmountStatsForFirms
                    allCategoryHourStats={allFirmsCategoryHoursStatsData}
                    categoryHourStats={categoryHoursStatsData}
                    label={"Expense Category"}
                    onSort={(sortBy?: any) => onSortCategoryHour(sortBy)}
                    onExport={() => onExportDialogOpenForAllFirms("category")}
                    sortBy={state.searchObj.categoryHourSortBy}
                    sortOrder={state.searchObj.categoryHourSortOrder}
                  ></CategoryAmountStatsForFirms>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </section>
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              typeOfFilters="timesheets"
              selectedFilters={state.searchObj}
              loadedFilter={loadedFilter}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
      {isExportDialogOpen && (
        <ExportDialog
          exportFor={'timesheetOrExpense'}
          handleDialogClose={(data?) => handleCloseExportDialog(data)}
        />
      )}
      {isExportDialogOpenForAllFirms && (
        <ExportDialog
          exportFor={'timesheetOrExpense'}
          handleDialogClose={(data?) =>
            handleCloseExportDialogForAllFirms(data)
          }
        />
      )}
    </React.Fragment>
  );
};

export default ExpenseDashboardMain;
