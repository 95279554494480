import { Button, Card, Grid, Hidden, Typography } from "@material-ui/core";
import * as React from "react";
import { abbreviateNumber, getFileName, getSubDomain, getToken, removeNulls } from "../../services/UtilService";
import {
  getTimesheetHoursFeeReport,
  getTimesheetReport,
  getProfessionalLevelHourDetails,
  getCategoryHourStatsDetails,
  getFirmServiceFees,
  getFirmServiceHours,
  getFirmTimesheetMetrics,
} from "../../services/DasahboardService";
import moment from "moment";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import CustomDateDialog from "./CustomDateDialog";
import { FirmContext } from "../common/FirmProvider";
import Filter from "../common/Filter";
import {
  IFilter,
  IProfessionalHourStats,
  ICategoryHourStats,
  IKeyValue,
  IFirmDetails,
  ITimesheets,
  IPerson,
  IDashboardTime,
  IFirmTotalFees,
  IFirmTotalHours,
  IFirmTimesheetMetrics,
  ITimeFilter,
  IConfirmDialog,
} from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import ProfessionalLevelHourStats from "./ProfessionalLevelHourStats";
import ProfessionalLevelHourStatsForFirms from "./ProfessionalLevelHourStatsForFirms";
import CategoryHourStats from "./CategoryHourStats";
import { useHistory } from "react-router-dom";
import CategoryHourStatsForFirms from "./CategoryHourStatsForFirms";
import {
  getTimesheetPersons,
  getAllTimesheets,
  getTimesheetFiltersCount,
  getAllProfessionalLevels,
  getAllCategories,
  getAllWorkTypes,
  getAllApprovedListForTimeEntry
} from "../../services/TimesheetService";
import {
  API_URL,
  DESCRIPTION_FILTER_ENTRY,
  styles,
  TIMESHEET_STATUS,
  TIME_ENTRY_KEY_WORDS,
  TREE_YEAR_DATE_FORMAT,
  YEAR_ARRAY,
} from "../../Constant";
import TimeEntriesStats from "./TimeEntriesStats";
import TimeEntriesStatsForAllFirms from "./TimeEntriesStatsForAllFirms";
import ExportDialog from "../common/ExportDialog";
import FirmServiceFees from "./FirmServiceFees";
import FirmServiceHours from "./FirmServiceHours";
import FirmTimesheetMetrics from "./FirmTimesheetMetrics";
import ToggleFilterSection from "./ToggleFilter";
import { getAllNotes } from "../../services/NoteService";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";

export interface TimesheetDashboardMainProps extends IConfirmDialog {
  isFirm: boolean;
}

const TimesheetDashboardMain: React.FC<TimesheetDashboardMainProps> = (
  props
) => {
  const history = useHistory();
  const [state, setState] = React.useState({
    searchObj: {
      from: 0,
      size: 10,
      statuses: [],
      firms: [],
      lawFirmCode: [],
      persons: [],
      // startDate: moment("2017-01-01", "YYYY-MM-DD")
      //   .subtract(1, "year")
      //   .toDate(),
      // endDate: moment().toDate(),
      startDate: undefined,
      endDate: undefined,
      sortBy: "",
      sortOrder: "asc",
      professionalLevelSortBy: "PROFESSIONAL_LEVEL_SORT",
      professionalLevelSortOrder: "asc",
      categoryHourSortBy: "CATEGORY_CODE",
      categoryHourSortOrder: "asc",
      firmServiceFeesSortBy: "firmName",
      firmServiceFeesSortOrder: "asc",
      firmServiceHoursSortBy: "firmName",
      firmServiceHoursSortOrder: "asc",
      firmTimesheetMetricsSortBy: "firmName",
      firmTimesheetMetricsSortOrder: "asc",
      keyword: [],
      min: "",
      max: "",
      categoryCode: [],
      workType: [],
      professionalLevel: [],
      minWordCount: "",
      maxWordCount: "",
      showDisapproved: false,
      descriptionSearchFilters: [],
      timePeriod: [],
      approvedBy: [],
      showOnlyDisapproved: false,
      noteId: "",
    },
    firmsList: [],
  } as {
    searchObj: IDashboardTime;
    firmsList: { value: any; label: any }[];
  });
  const exportForType = React.useRef("");
  const [
    isExportDialogOpenForAllFirms,
    setExportDialogOpenForAllFirms,
  ] = React.useState(false);
  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [exportFor, setExportFor] = React.useState("");
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [firmsDic, setFirmsDic] = React.useState({} as IKeyValue<IFirmDetails>);
  const [professionalLevelDic, setProfessionalLevelDic] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [categoryCodeDic, setCategoryCodeDic] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialApprovedBy, setInitialApprovedBy] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [noteList, setNoteList] = React.useState(
    [] as Array<{ label: string; value: string }>
  );
  const [personsDic, setPersonsDic] = React.useState({} as IKeyValue<IPerson>);
  const { showToast } = React.useContext(ToastContext);
  const firmProvider = React.useContext(FirmContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [initialFirms, setInitialFirms] = React.useState(
    {} as IKeyValue<IFirmDetails>
  );
  const [
    initialProfessionalLevels,
    setInitialProfessionalLevels,
  ] = React.useState({} as IKeyValue<{ label: string; value: string }>);
  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [, setProfessionalLevels] = React.useState([]);
  const [, setCategories] = React.useState([]);
  const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);

  const [isCustomDateOpen, setCustomDialog] = React.useState(false);
  const [timesheetCountDic, setTimesheetCountDic] = React.useState(
    {} as IKeyValue<number>
  );
  const [
    allFirmsTimesheetCountDic,
    setAllFirmsTimesheetCountDic,
  ] = React.useState({} as IKeyValue<number>);
  const [timesheetCount, setTimesheetCount] = React.useState(
    [] as { count: number; status: string }[]
  );
  const [allFirmsTimesheetCount, setAllFirmsTimesheetCount] = React.useState(
    [] as { count: number; status: string }[]
  );
  const [hoursAndFeeCount, setHoursAndFeeCount] = React.useState({
    hours: 0,
    fee: 0,
  });
  const [
    allFirmsHoursAndFeeCount,
    setAllFirmsHoursAndFeeCount,
  ] = React.useState({
    hours: 0,
    fee: 0,
  });

  const [
    professionalHoursStatsData,
    setProfessionalHoursStatsData,
  ] = React.useState([] as Array<IProfessionalHourStats>);
  const [
    allFirmsProfessionalHoursStatsData,
    setAllFirmsProfessionalHoursStatsData,
  ] = React.useState([] as Array<IProfessionalHourStats>);

  const [categoryHoursStatsData, setCategoryHoursStatsData] = React.useState(
    [] as Array<ICategoryHourStats>
  );
  const [
    allFirmsCategoryHoursStatsData,
    setAllFirmsCategoryHoursStatsData,
  ] = React.useState([] as Array<ICategoryHourStats>);


  const [
    firmServiceFees,
    setFirmServiceFees,
  ] = React.useState([] as Array<IFirmTotalFees>);
  const [
    firmServiceHours,
    setFirmServiceHours,
  ] = React.useState([] as Array<IFirmTotalHours>);
  const [
    firmTimesheetMetrics,
    setFirmTimesheetMetrics,
  ] = React.useState([] as Array<IFirmTimesheetMetrics>);
  const [
    firmTimesheetMetricsMonths,
    setFirmTimesheetMetricsMonths,
  ] = React.useState([] as Array<string>);

  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});
  const [isAllDetailsLoaded, setAllDetailsLoaded] = React.useState(false);
  const [isFilterOpen, setFilterOpen] = React.useState(true);
  const [timeEntriesLoading, setTimeEntriesLoading] = React.useState(false);
  const [firmServiceFeesLoading, setFirmServiceFeesLoading] = React.useState(false);
  const [professionalLevelLoading, setProfessionalLevelLoading] = React.useState(false);
  const [timeCategoryLoading, setTimeCategoryLoading] = React.useState(false);
  const [firmServiceHourLoading, setFirmServiceHourLoading] = React.useState(false);
  const [firmTimesheetLoading, setFirmTimesheetLoading] = React.useState(false);
  const [loadedFilter, setLoadedFilter] = React.useState(undefined as ITimeFilter);
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] = React.useState({
    minDate: undefined,
    maxDate: undefined
  } as {
    minDate?: Date;
    maxDate?: Date
  });

  React.useEffect(() => {
    const fetchTimeSheetReport = async () => {
      setLoading(true);
      let professionalLevelsDic = await getAllProfessionalLevelsList();
      let categoriesCodeDic = await getAllCategoriesList();
      let workTypesDic = await getAllWorkTypesList();
      let approvedByDic = await getAllApprovedByList();
      let noteList = await getAllNoteList();
      await getUserFirms({ professionalLevelsDic, categoriesCodeDic, workTypesDic, approvedByDic, noteList });
      setLoading(false);
    };
    fetchTimeSheetReport();
  }, []);

  const getAllProfessionalLevelsList = async () => {
    const result = await getAllProfessionalLevels();
    let professionalLevelDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return {
            label: x.professionalLevelName,
            value: x.professionalLevelCode,
          };
        });
        professionalLevelDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setProfessionalLevelDic(professionalLevelDic);
        setProfessionalLevels(data || []);
      }
    } else {
      showToast(
        result.message || "Error while fetching professional levels",
        "error"
      );
    }
    return professionalLevelDic;
  };
  const getAllCategoriesList = async () => {
    const result = await getAllCategories();
    let categoryCodeDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeCategoryName, value: x.timeCategoryCode };
        });
        categoryCodeDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setCategoryCodeDic(categoryCodeDic);
        setCategories(data || []);
      }
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
    return categoryCodeDic;
  };

  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(workTypesDic);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };

  const getAllApprovedByList = async () => {
    const result = await getAllApprovedListForTimeEntry();
    let approvedByDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeApprovedByName, value: x.timeApprovedByCode };
        });
        approvedByDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialApprovedBy(approvedByDic);
      }
    } else {
      showToast(result.message || "Error while fetching approved by", "error");
    }
    return approvedByDic;
  };

  const getAllNoteList = async () => {
    const result = await getAllNotes({});
    let noteList = [];
    if (result?.isSuccess) {
      if (result?.data) {
        noteList = result.data.results.map((x) => {
          return { label: x.description, value: x.id };
        });
        setNoteList(noteList);
      }
    } else {
      showToast(result.message || "Error while fetching notes", "error");
    }
    return noteList;
  };

  const getUserFirms = async (professionCategoriesData) => {
    let userFirms = firmProvider.userFirms;
    if (userFirms.length === 0) {
      userFirms = await firmProvider.getFirmsList();
    }
    let firmsDic = userFirms.reduce((acc: any, ele) => {
      acc[ele.lawFirmCode] = ele;
      return acc;
    }, {});
    setFirmsDic(firmsDic);
    setInitialFirms(firmsDic);
    await getPersonsList(firmsDic, professionCategoriesData);
  };

  const getPersonsList = async (firmsDic, professionCategoriesData) => {
    let result = await getTimesheetPersons();
    if (result?.isSuccess) {
      if (result.data.length > 0) {
        let personsDic = result.data.reduce((acc: any, ele: any) => {
          acc[ele.personId] = {
            personId: ele.personId,
            name:
              (ele.personFirstName || "") + " " + (ele.personLastName || ""),
          };
          return acc;
        }, {});
        setPersonsDic(personsDic);
        await getTimesheetFilters(
          undefined,
          firmsDic,
          personsDic,
          undefined,
          professionCategoriesData
        );
      }
    }
  };

  const getTimesheetFilters = async (
    search?: any,
    firmsDic?: any,
    personsDic?: any,
    changedProperty?: any,
    professionCategoriesData?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (search) {
      tempSearch = { ...state.searchObj, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let searchForFilter: any = { ...tempSearch };
    // if(searchForFilter.endDate){
    //   searchForFilter.endDate=moment(searchForFilter.endDate).format("YYYY-MM-DD")
    // }
    // if(searchForFilter.startDate){
    //   searchForFilter.startDate=moment(searchForFilter.startDate).format("YYYY-MM-DD")
    // }
    let keyWords = [...keyWordsList];
    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (professionCategoriesData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...professionCategoriesData.workTypesDic,
      };
    }
    let initialApprovedByTemp = { ...initialApprovedBy };
    if (professionCategoriesData?.approvedByDic) {
      initialApprovedByTemp = {
        ...initialApprovedBy,
        ...professionCategoriesData.approvedByDic,
      };
    }
    let initialNoteListTemp = [...noteList];
    if (professionCategoriesData?.noteList) {
      initialNoteListTemp = [
        ...initialNoteListTemp,
        ...professionCategoriesData.noteList,
      ];
    }
    // if (changedProperty) {
    //   switch (changedProperty) {
    //     case "firmCounts":
    //       searchForFilter.statuses = [];
    //       break;
    //     case "statusCounts":
    //       searchForFilter.firms = [];
    //       break;
    //     default:
    //       break;
    //   }
    // }
    delete tempSearch.size;
    delete tempSearch.from;
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {} as any,
    };
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      result = await getTimesheetFiltersCount(searchForFilter);
    }
    if (result?.isSuccess) {
      console.log("result", result.data);
      let data = { ...result.data };
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          categoryCodeCounts: "categoryCode",
          workTypeCounts: "workType",
          firmCounts: "firmCode",
          keywordCounts: "keyword",
          statusCounts: "status",
          professionalCounts: "professionalLevel",
          approvedByCounts: "timeApprovedByCode"
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            categoryCodeCounts: {},
            workTypeCounts: {},
            firmCounts: {},
            keywordCounts: {},
            statusCounts: {},
            professionalCounts: {},
            approvedByCounts: {}
          }
        );
        setFilterCountsDic(filterCountDic);
      }
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });

      let filter: IFilter[] = [];
      let initialFirmsTemp = { ...initialFirms };
      if (firmsDic) {
        initialFirmsTemp = {
          ...initialFirms,
          ...firmsDic,
        };
      }
      let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
      let initialCategoryCodeTemp = { ...initialCategoryCodes };
      if (Object.keys(initialFirmsTemp).length === 0) {
        let firmCounts = data["firmCounts"] || [];
        initialFirmsTemp = firmCounts.reduce((acc: any, ele) => {
          acc[ele.firmCode] = firmsDic[ele.firmCode] || {};
          return acc;
        }, {});
      }
      if (Object.keys(initialProfessionalLevelTemp).length === 0) {
        let professionalCounts = data["professionalCounts"] || [];
        initialProfessionalLevelTemp = professionalCounts.reduce(
          (acc: any, ele) => {
            acc[ele.professionalLevel] =
              professionCategoriesData.professionalLevelsDic[
              ele.professionalLevel
              ] || {};
            return acc;
          },
          {}
        );
      }
      if (Object.keys(initialCategoryCodeTemp).length === 0) {
        let categoryCodeCounts = data["categoryCodeCounts"] || [];
        initialCategoryCodeTemp = categoryCodeCounts.reduce((acc: any, ele) => {
          acc[ele.categoryCode] =
            professionCategoriesData.categoriesCodeDic[ele.categoryCode] || {};
          return acc;
        }, {});
      }

      let filterOrder = [
        "descriptionSearchFilters",
        "workTypeCounts",
        "time",
        // "tree",
        "statusCounts",
        "showDisapproved",
        "showOnlyDisapproved",
        "firmCounts",
        // "personCounts",
        "professionalCounts",
        "categoryCodeCounts",
        "approvedBy",
        "noteId",
        "keyword",
        "min-max",
        "word-counts",
      ];
      let customFilters = [
        "time",
        // "tree",
        "keyword", "min-max", "descriptionSearchFilters", "word-counts", "showDisapproved", "showOnlyDisapproved"
      ];
      delete data["personCounts"];
      if (!tempSearch.max && data?.maxTimeSpentHours) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time").isHidden) ||
                  false,
                items: [
                  {
                    type: 'date-range',
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: 'selection'
                      }
                    ],
                    label: ""
                  }
                ]
                // items: [
                //   {
                //     value: tempSearch.startDate
                //       ? tempSearch.startDate
                //       : data?.startDate,
                //     type: "date",
                //     label: "Start Date",
                //     name: "startDate",
                //   },
                //   {
                //     value: tempSearch.endDate
                //       ? tempSearch.endDate
                //       : data?.endDate,
                //     type: "date",
                //     label: "End Date",
                //     name: "endDate",
                //   },
                // ],
              });
              break;
            case "tree":
              const options = YEAR_ARRAY.map((d) => ({ label: d, value: moment(d).format(TREE_YEAR_DATE_FORMAT) }));
              filter.push({
                header: "Time Period",
                name: "tree",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "tree").isHidden) ||
                  false,
                items: [
                  {
                    type: "tree",
                    label: "",
                    options,
                    name: "startDate",
                    // value: tempSearch.startDate
                    selectedItems: tempSearch.timePeriod.map((d) => ({ label: d, value: d }))
                  }
                ],

              });
              break;
            case "keyword":
              filter.push({
                header: "Keywords",
                name: "keyword",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "keyword").isHidden) ||
                  false,
                items: keyWords.map((ele) => {
                  return {
                    isSelected: tempSearch.keyword ? tempSearch.keyword.includes(ele.value) : false,
                    label: ele.label,
                    type: "checkbox",
                    name: ele.value,
                    // count:
                    //   changedProperty !== undefined &&
                    //   changedProperty === ele.label &&
                    //   tempSearch.statuses.length > 0
                    //     ? filterData
                    //         .find((ed) => ed.name === 'professionalLevel')
                    //         ?.items.find((ed) => ed.name === ele.label)?.count || 0
                    //     : statusCounts.find((e) => e.status === eds)?.count || 0,
                  };
                }),
              });
              break;
            case "min-max":
              filter.push({
                header: "Total Time Spent for Day",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max").isHidden) ||
                  false,
                items: [
                  {
                    label: "Min",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  },
                  {
                    label: "Max",
                    type: "number",
                    name: "max",
                    value: tempSearch.max,
                  },
                ],
              });
              break;
            case "descriptionSearchFilters":
              filter.push({
                header: "Descriptions",
                name: "descriptionSearchFilters",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "descriptionSearchFilters").isHidden) ||
                  false,
                items: tempSearch.descriptionSearchFilters.length > 0 ? tempSearch.descriptionSearchFilters.map((d) => {
                  let itemObj = {
                    type: "text-checkbox",
                    value: d.search,
                    isSelected: d.isExcluded,
                    name: "description",
                    label: ""
                  };
                  return itemObj;
                }) : [{ ...DESCRIPTION_FILTER_ENTRY }],
              });
              break;
            case "word-counts":
              filter.push({
                header: "Word Count",
                name: "word-counts",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "word-counts")?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Min Words",
                    type: "number",
                    name: "minWordCount",
                    value: tempSearch.minWordCount,
                  },
                  {
                    label: "Max Words",
                    type: "number",
                    name: "maxWordCount",
                    value: tempSearch.maxWordCount,
                  },
                ],
              });
              break;
            case "showDisapproved":
              filter.push({
                className: "disapproved-section",
                header: "",
                name: "showDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showDisapproved")?.isHidden) ||
                  false,
                items: [{
                  isSelected: tempSearch.showDisapproved,
                  label: "Show Disapproved",
                  type: "switch",
                  name: "showDisapproved",
                  isDisabled: tempSearch.showOnlyDisapproved
                }],
              });
              break;
            case "showOnlyDisapproved":
              filter.push({
                className: "only-disapproved-section",
                header: "",
                name: "showOnlyDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showOnlyDisapproved")?.isHidden) ||
                  false,
                items: [{
                  isSelected: tempSearch.showOnlyDisapproved,
                  label: "Show Only Disapproved",
                  type: "switch",
                  name: "showOnlyDisapproved",
                }],
              });
              break;
            default:
              break;
          }
        } else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "firmCounts":
              a.header = "Firm";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let firmCounts = data["firmCounts"] || [];
              Object.keys(initialFirmsTemp).map((element) => {
                a.items.push({
                  label: firmsDic[element]
                    ? firmsDic[element].lawFirmShortName
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.firms.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : firmCounts.find((e) => e.firmCode === element)
                          ?.count || 0
                      : filterCountDic?.["firmCounts"]?.[element]?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.firms ? tempSearch.firms.includes(element) : false,
                });
              });
              // sorting based on label i.e. firm short name
              a.items.sort((a, b) => a.label > b.label ? 1 : -1);
              break;
            case "professionalCounts":
              a.header = "Professional Level";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let professionalCounts = data["professionalCounts"] || [];
              Object.keys(initialProfessionalLevelTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.professionalLevelsDic[element]
                    ? professionCategoriesData.professionalLevelsDic[element]
                      .label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.professionalLevel.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === element)?.count ||
                        0
                        : professionalCounts.find(
                          (e) => e.professionalLevel === element
                        )?.count || 0
                      : filterCountDic?.["professionalCounts"]?.[element]
                        ?.count || 0,
                  name: element,
                  isSelected: tempSearch.professionalLevel ? tempSearch.professionalLevel.includes(element) : false,
                });
              });
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.workTypesDic[element]
                    ? professionCategoriesData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : workTypeCounts.find(
                          (e) => e.workType === element
                        )?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.workType ? tempSearch.workType.includes(element) : false,
                });
              });
              break;
            case "categoryCodeCounts":
              a.header = "Time Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let categoryCodeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp).sort((a, b) => initialCategoryCodeTemp[a].label > initialCategoryCodeTemp[b].label ? 1 : -1).map((element) => {
                a.items.push({
                  label: professionCategoriesData.categoriesCodeDic[element]
                    ? professionCategoriesData.categoriesCodeDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.categoryCode.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : categoryCodeCounts.find(
                          (e) => e.categoryCode === element
                        )?.count || 0
                      : filterCountDic?.["categoryCodeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.categoryCode ? tempSearch.categoryCode.includes(element) : false,
                });
              });
              break;
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).map((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses ? tempSearch.statuses.includes(eds) : false,
                  label: TIMESHEET_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                  isDisabled: tempSearch.showOnlyDisapproved
                });
              });
              break;
            case "personCounts":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let personsCount = data["personCounts"] || [];
              let options = [];
              let selectedItems = [];
              personsCount.map((ele) => {
                if (
                  tempSearch.persons.length > 0 &&
                  tempSearch.persons.includes(ele.personId)
                ) {
                  selectedItems.push({
                    value: ele.personId,
                    label: personsDic[ele.personId].name,
                  });
                }
                options.push({
                  value: ele.personId,
                  label: personsDic[ele.personId].name,
                });
              });
              a.items = [
                {
                  label: "Resources",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedItems || [],
                  options:
                    changedProperty !== undefined &&
                      changedProperty === ele &&
                      tempSearch.persons.length > 0
                      ? filterData.find((ed) => ed.name === ele)?.items[0]
                        .options
                      : options,
                },
              ];
              break;
            case "approvedBy":
              a.header = "Approved By";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let approvedByCounts = data["approvedByCounts"] || [];
              Object.keys(initialApprovedByTemp).map((element) => {
                a.items.push({
                  label: initialApprovedByTemp[element]
                    ? initialApprovedByTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.approvedBy.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : approvedByCounts.find(
                          (e) => e.approvedBy === element
                        )?.count || 0
                      : filterCountDic?.["approvedByCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.approvedBy.includes(element),
                });
              });
              break;
            case "noteId":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let noteOptions = [];
              let selectedNoteItems = [];
              initialNoteListTemp.map((ele) => {
                if (tempSearch.noteId === ele.value) {
                  selectedNoteItems.push(ele);
                }
                noteOptions.push(ele);
              });
              a.items = [
                {
                  label: "Notes",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedNoteItems || [],
                  options:
                    changedProperty !== undefined &&
                      changedProperty === ele &&
                      tempSearch.noteId
                      ? filterData.find((ed) => ed.name === ele)?.items[0].options
                      : noteOptions,
                  isMulti: false
                },
              ];
              break;
            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });
      setCountsLoaded(true);
      setFilterData(filter);
      setInitialFirms(initialFirmsTemp);
      setInitialProfessionalLevels(initialProfessionalLevelTemp);
      setInitialCategoryCodes(initialCategoryCodeTemp);
      if (changedProperty !== "add-descriptions") {
        tempSearch.from = 0;
        tempSearch.size = 10;
        setLoading(false);
        // setLoading(true);

        //change loading state for below calls
        setTimeEntriesLoading(true);
        setProfessionalLevelLoading(true);
        setTimeCategoryLoading(true);
        setFirmServiceFeesLoading(true);
        setFirmServiceHourLoading(true);
        setFirmTimesheetLoading(true);

        // if (props.isFirm !== true) {
        // }
        await getProfessionalLevelDetails(tempSearch, undefined, true);
        await getCategoryHourDetails(tempSearch, undefined, true);
        await getFirmServiceFeeDetails(tempSearch, true);
        await getFirmServiceHourDetails(tempSearch, true);
        await getFirmTimesheetMetricDetails(tempSearch, true);
        if (props.isFirm) {
          if (isAllDetailsLoaded === false) {
            await getAllFirmsProfessionalLevelDetails(tempSearch);
            await getAllFirmsCategoryHourDetails(tempSearch);
            await getAllFirmsDashTimesheetReport(tempSearch);
            setAllDetailsLoaded(true);
          }
        }
        await getDashTimesheetReport(tempSearch, undefined, true, changedProperty, filter);
        // setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      showToast(
        result.message || "Error while fetching timesheets counts",
        "error"
      );
    }
  };

  const onSortProfessionalLevel = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.professionalLevelSortBy) {
      search.professionalLevelSortOrder =
        search.professionalLevelSortOrder === "asc" ? "desc" : "asc";
    } else {
      search.professionalLevelSortBy = sortBy;
      search.professionalLevelSortOrder = "asc";
    }
    search.from = 0;
    await getProfessionalLevelDetails(search);
    //as we are making allFirmProfessionalLevel as dictionary those will be automatically sorted
    // if (props.isFirm) {
    //   await getAllFirmsProfessionalLevelDetails(search);
    // }
  };
  const onSortFirmServiceFee = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.firmServiceFeesSortBy) {
      search.firmServiceFeesSortOrder =
        search.firmServiceFeesSortOrder === "asc" ? "desc" : "asc";
    } else {
      search.firmServiceFeesSortBy = sortBy;
      search.firmServiceFeesSortOrder = "asc";
    }
    search.from = 0;
    await getFirmServiceFeeDetails(search);
  };
  const onSortFirmServiceHour = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.firmServiceHoursSortBy) {
      search.firmServiceHoursSortOrder =
        search.firmServiceHoursSortOrder === "asc" ? "desc" : "asc";
    } else {
      search.firmServiceHoursSortBy = sortBy;
      search.firmServiceHoursSortOrder = "asc";
    }
    search.from = 0;
    await getFirmServiceHourDetails(search);
  };
  const onSortFirmTimesheetMetric = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.firmTimesheetMetricsSortBy) {
      search.firmTimesheetMetricsSortOrder =
        search.firmTimesheetMetricsSortOrder === "asc" ? "desc" : "asc";
    } else {
      search.firmTimesheetMetricsSortBy = sortBy;
      search.firmTimesheetMetricsSortOrder = "asc";
    }
    search.from = 0;
    await getFirmTimesheetMetricDetails(search);
  };

  const onSortCategoryHour = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.categoryHourSortBy) {
      search.categoryHourSortOrder =
        search.categoryHourSortOrder === "asc" ? "desc" : "asc";
    } else {
      search.categoryHourSortBy = sortBy;
      search.categoryHourSortOrder = "asc";
    }
    search.from = 0;
    await getCategoryHourDetails(search);
    //as we are making allFirmsCategoryHours as dictionary those will be automatically sorted
    // if (props.isFirm) {
    //   await getAllFirmsCategoryHourDetails(search);
    // }
  };

  const getProfessionalLevelDetails = async (
    searchObj?: any,
    firmsList?: any,
    isDefaultCall = false
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    if (!isDefaultCall) {
      // setLoading(true);
      setProfessionalLevelLoading(true);
    }
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.professionalLevelSortBy;
    tempSearch.sortOrder = tempSearch.professionalLevelSortOrder;
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result = await getProfessionalLevelHourDetails(tempSearch);
    if (result?.isSuccess) {
      setProfessionalHoursStatsData(result.data);
      setState({
        ...state,
        searchObj: tempSearch,
      });
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching professional level hour stats",
        "error"
      );
    }
    // if (!isDefaultCall) {
    //   setLoading(false);
    // }
    setProfessionalLevelLoading(false);
  };




  const getFirmServiceFeeDetails = async (
    searchObj?: any, isDefaultCall = false) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    if (!isDefaultCall) {
      //   setLoading(true);
      setFirmServiceFeesLoading(true);
    }
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.firmServiceFeesSortBy;
    tempSearch.sortOrder = tempSearch.firmServiceFeesSortOrder;
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result = await getFirmServiceFees(tempSearch);
    if (result?.isSuccess) {
      setFirmServiceFees(result.data);
      setState({
        ...state,
        searchObj: tempSearch,
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching firm service fees",
        "error"
      );
    }
    // if (!isDefaultCall) {
    //   setLoading(false);
    // }
    setFirmServiceFeesLoading(false);
  };

  const getFirmServiceHourDetails = async (
    searchObj?: any, isDefaultCall = false
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    if (!isDefaultCall) {
      //   setLoading(true);
      setFirmServiceHourLoading(true);
    }
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.firmServiceHoursSortBy;
    tempSearch.sortOrder = tempSearch.firmServiceHoursSortOrder;
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result = await getFirmServiceHours(tempSearch);
    if (result?.isSuccess) {
      setFirmServiceHours(result.data);
      setState({
        ...state,
        searchObj: tempSearch,
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching firm service hours",
        "error"
      );
    }
    // if (!isDefaultCall) {
    //   setLoading(false);
    // }
    setFirmServiceHourLoading(false);
  };

  const getFirmTimesheetMetricDetails = async (
    searchObj?: any, isDefaultCall = false
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    if (!isDefaultCall) {
      //   setLoading(true);
      setFirmTimesheetLoading(true);
    }
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.firmTimesheetMetricsSortBy;
    tempSearch.sortOrder = tempSearch.firmTimesheetMetricsSortOrder;
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result = await getFirmTimesheetMetrics(tempSearch);
    if (result?.isSuccess) {
      let months = result.data.reduce((acc, ele) => {
        ele.monthDetails.forEach(monthDetail => {
          if (acc.indexOf(monthDetail.month) === -1) {
            acc.push(monthDetail.month);
          }
          ele[monthDetail.month] = monthDetail;
        });
        return acc;
      }, []);
      let monthsSorted = months.sort((a, b) => moment(a, "M-YYYY").valueOf() > moment(b, "M-YYYY").valueOf() ? 1 : -1)
      setFirmTimesheetMetricsMonths(monthsSorted)
      setFirmTimesheetMetrics(result.data);
      setState({
        ...state,
        searchObj: tempSearch,
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching firm timesheet metrics",
        "error"
      );
    }
    // if (!isDefaultCall) {
    //   setLoading(false);
    // }
    setFirmTimesheetLoading(false);
  };




  const getAllFirmsProfessionalLevelDetails = async (
    searchObj?: any,
    firmsList?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }

    setLoading(true);
    tempSearch.lawFirmCode = [];
    tempSearch.sortBy = tempSearch.professionalLevelSortBy;
    tempSearch.sortOrder = tempSearch.professionalLevelSortOrder;
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result = await getProfessionalLevelHourDetails(tempSearch);
    if (result?.isSuccess) {
      setAllFirmsProfessionalHoursStatsData(result.data);
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching professional level hour stats",
        "error"
      );
    }
    setLoading(false);
  };
  const getCategoryHourDetails = async (searchObj?: any, firmsList?: any, isDefaultCall = false) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    if (!isDefaultCall) {
      //   setLoading(true);
      setTimeCategoryLoading(true);
    }
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.categoryHourSortBy;
    tempSearch.sortOrder = tempSearch.categoryHourSortOrder;
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result = await getCategoryHourStatsDetails(tempSearch);
    if (result?.isSuccess) {
      setCategoryHoursStatsData(result.data);
      setState({
        ...state,
        searchObj: tempSearch,
      });
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching category level hour stats",
        "error"
      );
    }
    // if (!isDefaultCall) {
    //   setLoading(false);
    // }
    setTimeCategoryLoading(false);
  };
  const getAllFirmsCategoryHourDetails = async (
    searchObj?: any,
    firmsList?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    setLoading(true);
    tempSearch.lawFirmCode = [];
    tempSearch.sortBy = tempSearch.categoryHourSortBy;
    tempSearch.sortOrder = tempSearch.categoryHourSortOrder;
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result = await getCategoryHourStatsDetails(tempSearch);
    if (result?.isSuccess) {
      setAllFirmsCategoryHoursStatsData(result.data);
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching category level hour stats",
        "error"
      );
    }
    setLoading(false);
  };

  const getDashTimesheetReport = async (searchObj?: any, firmsList?: any, isDefaultCall = false, changedProperty?: string, filter?: IFilter[]) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    if (!isDefaultCall) {
      // setLoading(true);
      setTimeEntriesLoading(true);
    }
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      delete tempSearch.lawFirmCode;
    }
    // if(tempSearch.endDate){
    //   tempSearch.endDate=moment(tempSearch.endDate).format("YYYY-MM-DD") as any
    // }
    // if(tempSearch.startDate){
    //   tempSearch.startDate=moment(tempSearch.startDate).format("YYYY-MM-DD") as any
    // }
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result = await getTimesheetReport(tempSearch);
    if (result?.isSuccess) {
      let timesheetDic = result.data.reduce(
        (acc, obj) => {
          let key = obj["status"];
          acc[key] = obj.count;
          return acc;
        },
        {
          total: result.data.reduce((acc, val) => acc + val.count, 0),
        }
      );
      setTimesheetCount(result.data);
      setTimesheetCountDic(timesheetDic);
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
        searchObj: tempSearch,
      });
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching time entries count",
        "error"
      );
    }
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result2 = await getTimesheetHoursFeeReport(tempSearch);
    if (result2?.isSuccess) {
      let horusFeeCount = { ...hoursAndFeeCount };
      horusFeeCount.hours = result2.data?.totalTimeSpent || 0;
      horusFeeCount.fee = result2.data?.totalFees || 0;
      if ((changedProperty === "noteId") && result2.data.statuses && tempSearch.noteId) {
        tempSearch.statuses = result2.data.statuses;
        let statusIndexInFilter = filter.findIndex(x => x.name === "statusCounts");
        if (statusIndexInFilter > -1) {
          filter[statusIndexInFilter].items.forEach(item => {
            item.isSelected = tempSearch.statuses ? tempSearch.statuses.includes(item.name) : false
          })
        }
        if (result2.data.statuses.includes("disapproved") && !tempSearch.showDisapproved) {
          let showDisapprovedIndexInFilter = filter.findIndex(x => x.name === "showDisapproved");
          if (showDisapprovedIndexInFilter > -1) {
            filter[showDisapprovedIndexInFilter].items[0].isSelected = result2.data.statuses ? result2.data.statuses.includes("disapproved") : false
          }
          tempSearch.showDisapproved = true;
          props.confirmDialog.show("Disapproved", "Show Disapproved enabled", undefined, undefined, undefined, undefined, true);
          setState({
            ...state,
            searchObj: tempSearch
          })
        }
        setFilterData(filter);
      }
      setHoursAndFeeCount({ ...horusFeeCount });
    } else {
      showToast(
        result2?.message
          ? result2.message
          : "Error while fetching hours and fees count",
        "error"
      );
    }
    // if (!isDefaultCall) {
    //   setLoading(false);
    // }
    setTimeEntriesLoading(false);
  };
  const getAllFirmsDashTimesheetReport = async (
    searchObj?: any,
    firmsList?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    setLoading(true);
    tempSearch.lawFirmCode = [];
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result = await getTimesheetReport(tempSearch);
    if (result?.isSuccess) {
      let timesheetDic = result.data.reduce(
        (acc, obj) => {
          let key = obj["status"];
          acc[key] = obj.count;
          return acc;
        },
        {
          total: result.data.reduce((acc, val) => acc + val.count, 0),
        }
      );
      setAllFirmsTimesheetCount(result.data);
      setAllFirmsTimesheetCountDic(timesheetDic);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching time entries count",
        "error"
      );
    }
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    const result2 = await getTimesheetHoursFeeReport(tempSearch);
    if (result2?.isSuccess) {
      let horusFeeCount = { ...hoursAndFeeCount };
      horusFeeCount.hours = result2.data?.totalTimeSpent || 0;
      horusFeeCount.fee = result2.data?.totalFees || 0;
      setAllFirmsHoursAndFeeCount({ ...horusFeeCount });
    } else {
      showToast(
        result2?.message
          ? result2.message
          : "Error while fetching hours and fees count",
        "error"
      );
    }
    setLoading(false);
  };

  const handleCloseCustomDate = async (data: any) => {
    let searchObj = { ...state.searchObj };
    searchObj.startDate = data.startDate;
    searchObj.endDate = data.endDate;
    setCustomDialog(false);
    setState({ ...state, searchObj: { ...searchObj } });
    // if (props.isFirm !== true) {
    // }
    await getProfessionalLevelDetails(searchObj);
    await getCategoryHourDetails(searchObj);
    await getFirmServiceFeeDetails(searchObj);
    await getFirmServiceHourDetails(searchObj);
    await getFirmTimesheetMetricDetails(searchObj);
    if (props.isFirm) {
      if (isAllDetailsLoaded === false) {
        await getAllFirmsProfessionalLevelDetails(searchObj);
        await getAllFirmsCategoryHourDetails(searchObj);
        await getAllFirmsDashTimesheetReport(searchObj);
        setAllDetailsLoaded(true);
      }
      await getDashTimesheetReport(searchObj);
    }
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    setLoading(true);
    if (changedProperty === "clear-filters") {
      setLoadedFilter(undefined);
    }
    if (changedProperty === "load-filters") {
      let details = JSON.parse(JSON.stringify(data));
      setLoadedFilter(details);
      await getTimesheetFilters(details.filters, firmsDic, personsDic, changedProperty, {
        professionalLevelsDic: professionalLevelDic,
        categoriesCodeDic: categoryCodeDic,
        workTypesDic: { ...initialWorkTypes }
      });
    } else {
      let search = { ...state.searchObj };
      search.from = 0;
      let statusArray =
        data.find((ele) => ele.name === "statusCounts")?.items || [];
      let professionalLevelArray =
        data.find((ele) => ele.name === "professionalCounts")?.items || [];
      let categoryCodeArray =
        data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
      let workTypesArray =
        data.find((ele) => ele.name === "workTypeCounts")?.items || [];
      let personArray =
        data.find((ele) => ele.name === "personCounts")?.items || [];
      let noteArray =
        data.find((ele) => ele.name === "noteId")?.items || [];
      let firmArray = data.find((ele) => ele.name === "firmCounts")?.items || [];
      let approvedByArray = data.find((ele) => ele.name === "approvedBy")?.items || [];
      let timeArray = data.find((ele) => ele.name === "time")?.items || [];
      let treeArray = data.find((ele) => ele.name === "tree")?.items || [];
      let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
      search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
      search.max = minMaxArray.find((ele) => ele.name === "max")?.value || "";
      let minMaxWordCountArray = data.find((ele) => ele.name === "word-counts")?.items || [];
      search.minWordCount = minMaxWordCountArray.find((ele) => ele.name === "minWordCount")?.value || "";
      search.maxWordCount = minMaxWordCountArray.find((ele) => ele.name === "maxWordCount")?.value || "";
      let descriptionArray = data.find((ele) => ele.name === "descriptionSearchFilters")?.items || [];
      search.descriptionSearchFilters = descriptionArray
        // .filter((ele) => ele.value)
        .map((ele) => {
          let d = {
            search: ele.value || "",
            isExcluded: ele.isSelected
          };
          return d;
        });
      let disapprovedStatus = data.find((ele) => ele.name === "showDisapproved")?.items || [];
      search.showDisapproved = disapprovedStatus.find(ele => ele.name === "showDisapproved").isSelected;
      let onlyDisapprovedStatus = data.find((ele) => ele.name === "showOnlyDisapproved")?.items || [];
      search.showOnlyDisapproved = onlyDisapprovedStatus.find(ele => ele.name === "showOnlyDisapproved").isSelected;
      if (search.min && search.max && search.min > search.max) {
        showToast("Max value cannot be less than min value", "error");
        return;
      }
      if (search.minWordCount && search.maxWordCount && search.minWordCount > search.maxWordCount) {
        showToast("Max word count value cannot be less than min word count value", "error");
        return;
      }
      search.startDate =
        timeArray[0].value[0].startDate || undefined;
      // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
      search.endDate =
        timeArray[0].value[0].endDate || undefined;
      // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;

      if (treeArray.length > 0) {
        search.timePeriod = treeArray[0].selectedItems.map((d) => d.value).sort((a, b) => a > b ? 1 : -1);
      }
      if (search.timePeriod.length > 0) {
        search.startDate = search.timePeriod[0] as any;
        search.endDate = moment(search.timePeriod[search.timePeriod.length - 1]).endOf("month").format(TREE_YEAR_DATE_FORMAT) as any;
      } else if (timeArray.length === 0) {
        search.startDate = undefined;
        search.endDate = undefined;
      }
      // search.startDate =
      //   timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
      // if (search.startDate) {
      //   if (changedProperty === 'time-period-year') {
      //     search.endDate = moment(search.startDate).endOf("year").format(TREE_YEAR_DATE_FORMAT) as any;
      //   }
      //   if (changedProperty === "time-period-month") {
      //     search.endDate = moment(search.startDate).endOf("month").format(TREE_YEAR_DATE_FORMAT) as any;
      //   }
      // } else {
      //   search.endDate = undefined;
      // }
      search.statuses = statusArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      // if show 'only dis approved' is true we need to make status array empty and 'dis approved' false
      if (search.showOnlyDisapproved) {
        search.showDisapproved = false;
        search.statuses = [];
      }
      search.persons =
        personArray.length > 0
          ? personArray[0].selectedItems.length > 0
            ? personArray[0].selectedItems.map((ele) => ele.value)
            : []
          : [];
      search.firms = firmArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          if (e.name) {
            return e.name;
          }
        });
      search.professionalLevel = professionalLevelArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.categoryCode = categoryCodeArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.workType = workTypesArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let keywordsArray = data.find((ele) => ele.name === "keyword")?.items || [];
      search.keyword = keywordsArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.approvedBy = approvedByArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.noteId =
        noteArray.length > 0
          ? noteArray[0].selectedItems.length > 0
            ? noteArray[0].selectedItems[0].value
            : ""
          : "";
      if (changedProperty !== "add-descriptions") {
        await getTimesheetFilters(search, firmsDic, personsDic, changedProperty, {
          professionalLevelsDic: professionalLevelDic,
          categoriesCodeDic: categoryCodeDic,
          workTypesDic: { ...initialWorkTypes }
        });
      } else {
        setLoading(false);
        setState({
          ...state,
          searchObj: {
            ...state.searchObj,
            ...search
          }
        })
      }
    }
    // await getProfessionalLevelDetails(search);
    // await getCategoryHourDetails(search);
    // if (props.isFirm) {
    //   await getAllFirmsProfessionalLevelDetails();
    //   await getAllFirmsCategoryHourDetails();
    // }
  };

  const navigateToTimeSheets = (status, isDisapproved?: boolean, isShowOnlyDisapproved?: boolean) => {
    history.push({
      pathname: `/timesheets-summary`,
      state: {
        status: (!isDisapproved && !isShowOnlyDisapproved) ? status : "",
        startDate: state.searchObj.startDate,
        endDate: state.searchObj.endDate,
        selectedFirm: state.searchObj.firms,
        professionalLevel: state.searchObj.professionalLevel,
        categoryCode: state.searchObj.categoryCode,
        keyword: state.searchObj.keyword,
        min: state.searchObj.min,
        max: state.searchObj.max,
        minWordCount: state.searchObj.minWordCount,
        maxWordCount: state.searchObj.maxWordCount,
        descriptionSearchFilters: state.searchObj.descriptionSearchFilters,
        showDisapproved: isDisapproved ? true : state.searchObj.showDisapproved,
        workType: state.searchObj.workType,
        showOnlyDisapproved: isShowOnlyDisapproved ? true : state.searchObj.showOnlyDisapproved,
        approvedBy: state.searchObj.approvedBy,
        noteId: state.searchObj.noteId
      },
    });
  };

  const handleDownloadAsCsv = async (isZip?: boolean) => {
    let searchObj = { ...state.searchObj };
    let sortBy, sortOrder, url;
    switch (exportFor) {
      case "category":
        sortBy = searchObj.categoryHourSortBy;
        sortOrder = searchObj.categoryHourSortOrder;
        url = `timesheets/generate-csv/category`;
        break;
      case "professional-level":
        sortBy = searchObj.professionalLevelSortBy;
        sortOrder = searchObj.professionalLevelSortOrder;
        url = `timesheets/generate-csv/professional`;
        break;
      case "firm-service-fees":
        sortBy = searchObj.firmServiceFeesSortBy;
        sortOrder = searchObj.firmServiceFeesSortOrder;
        url = `timesheets/generate-csv/firm-service-fees`;
        break;
      case "firm-service-hours":
        sortBy = searchObj.firmServiceHoursSortBy;
        sortOrder = searchObj.firmServiceHoursSortOrder;
        url = `timesheets/generate-csv/firm-service-hours`;
        break;
      case "firm-timesheet-metrics":
        sortBy = searchObj.firmTimesheetMetricsSortBy;
        sortOrder = searchObj.firmTimesheetMetricsSortOrder;
        url = `timesheets/generate-csv/firm-timesheet-metrics`;
        break;
    }
    if (url) {
      let obj = {
        dashboardHoursStatsVM: {
          ...searchObj,
          startDate: searchObj.startDate,
          endDate: searchObj.endDate,
          lawFirmCode: searchObj.firms,
          keyword: searchObj.keyword,
          categoryCode: searchObj.categoryCode,
          workType: searchObj.workType,
          professionalLevel: searchObj.professionalLevel,
          min: searchObj?.min || undefined,
          max: searchObj?.max || undefined,
          sortBy: sortBy,
          sortOrder: sortOrder,
          minWordCount: searchObj.minWordCount,
          maxWordCount: searchObj.maxWordCount,
          descriptionSearchFilters: searchObj.descriptionSearchFilters,
          showDisapproved: searchObj.showDisapproved,
          approvedBy: searchObj.approvedBy,
          noteId: searchObj.noteId,
          showOnlyDisapproved: searchObj.showOnlyDisapproved
        },
        statusList: searchObj.statuses.map((ele) => TIMESHEET_STATUS[ele]),
        workType: searchObj.workType,
        isZip: isZip ? true : false,
        localTime: moment().format("YYYYMMDDHHmmss")
      };
      obj = removeNulls(obj);
      let result = await fetch(
        `${API_URL}${url}`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + (getToken() || ""),
            dbName: getSubDomain(),
          },
        }
      );
      let pdf = await result.blob();
      const pdfFile = await new Blob([pdf], {
        type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
      });
      const pdfFileURL = await URL.createObjectURL(pdfFile);
      var a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      a.href = pdfFileURL;
      a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "csv", "case");
      // pdf.type === "application/zip"
      //   ? `${
      //       exportFor === "category"
      //         ? "Timesheet Category.zip"
      //         : "Timesheet Professional Level.zip"
      //     }`
      //   : `${
      //       exportFor === "category"
      //         ? "Timesheet Category.csv"
      //         : "Timesheet Professional Level.csv"
      //     }`;
      a.click();
    }
  };
  const handleDownload = async (isZip?: boolean) => {
    let searchObj = { ...state.searchObj };
    let sortBy, sortOrder, url;
    switch (exportFor) {
      case "category":
        sortBy = searchObj.categoryHourSortBy;
        sortOrder = searchObj.categoryHourSortOrder;
        url = `timesheets/generate-pdf/category`;
        break;
      case "professional-level":
        sortBy = searchObj.professionalLevelSortBy;
        sortOrder = searchObj.professionalLevelSortOrder;
        url = `timesheets/generate-pdf/professional`;
        break;
      case "firm-service-fees":
        sortBy = searchObj.firmServiceFeesSortBy;
        sortOrder = searchObj.firmServiceFeesSortOrder;
        url = `timesheets/generate-pdf/firm-service-fees`;
        break;
      case "firm-service-hours":
        sortBy = searchObj.firmServiceHoursSortBy;
        sortOrder = searchObj.firmServiceHoursSortOrder;
        url = `timesheets/generate-pdf/firm-service-hours`;
        break;
      case "firm-timesheet-metrics":
        sortBy = searchObj.firmTimesheetMetricsSortBy;
        sortOrder = searchObj.firmTimesheetMetricsSortOrder;
        url = `timesheets/generate-pdf/firm-timesheet-metrics`;
        break;
    }
    if (url) {
      let obj = {
        dashboardHoursStatsVM: {
          ...searchObj,
          startDate: searchObj.startDate,
          endDate: searchObj.endDate,
          lawFirmCode: searchObj.firms,
          keyword: searchObj.keyword,
          categoryCode: searchObj.categoryCode,
          workType: searchObj.workType,
          professionalLevel: searchObj.professionalLevel,
          min: searchObj?.min || undefined,
          max: searchObj?.max || undefined,
          sortBy: sortBy,
          sortOrder: sortOrder,
          minWordCount: searchObj.minWordCount,
          maxWordCount: searchObj.maxWordCount,
          descriptionSearchFilters: searchObj.descriptionSearchFilters,
          showDisapproved: searchObj.showDisapproved,
          approvedBy: searchObj.approvedBy,
          noteId: searchObj.noteId,
          showOnlyDisapproved: searchObj.showOnlyDisapproved
        },
        statusList: searchObj.statuses.map((ele) => TIMESHEET_STATUS[ele]),
        workType: searchObj.workType,
        isZip: isZip ? true : false,
        localTime: moment().format("YYYYMMDDHHmmss")
      };
      obj = removeNulls(obj);
      let result = await fetch(
        `${API_URL}${url}`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + (getToken() || ""),
            dbName: getSubDomain(),
          },
        }
      );
      let pdf = await result.blob();
      const pdfFile = await new Blob([pdf], {
        type: pdf.type === "application/zip" ? "octet/stream" : "application/pdf",
      });
      const pdfFileURL = await URL.createObjectURL(pdfFile);
      var a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      a.href = pdfFileURL;
      a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "pdf", "case");
      // pdf.type === "application/zip"
      //   ? `${
      //       exportFor === "category"
      //         ? "Timesheet Category.zip"
      //         : "Timesheet Professional Level.zip"
      //     }`
      //   : `${
      //       exportFor === "category"
      //         ? "Timesheet Category.pdf"
      //         : "Timesheet Professional Level.pdf"
      //     }`;
      a.click();
    }
  };

  const onExportDialogOpen = async (type: string) => {
    exportForType.current = type;
    setExportFor(type);
    setExportDialogOpen(true);
  };
  const onExportDialogOpenForAllFirms = async (type: string) => {
    setExportFor(type);
    setExportDialogOpenForAllFirms(true);
  };

  const handleCloseExportDialog = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpen(false);
  };

  const handleCloseExportDialogForAllFirms = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpenForAllFirms(false);
  };

  const classes = styles();

  return (
    <React.Fragment>
      {isLoading && <Loading message={loadingMessage} />}
      <section className="p-16 pr-0 pb-0">
        <br />
        <Grid className={`${classes.main}`}>
          <Grid className={`${isFilterOpen ? classes.filterOpenContainer : classes.filterCloseContainer}`}>
            <Hidden mdDown>
              <Grid container spacing={2}>
                <Grid item xs={12} className="dashboard-filters-height">
                  {filterData.length > 0 && (
                    <Filter
                      data={[...filterData]}
                      onChange={(data: IFilter[], changedProperty?: string) => {
                        onFilterChange(data, changedProperty);
                      }}
                      handleFilterHide={() => setFilterOpen(false)}
                      typeOfFilters="timesheets"
                      selectedFilters={state.searchObj}
                      loadedFilter={loadedFilter}
                      dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                    />
                  )}
                </Grid>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid container justify="flex-end">
                <Grid item>
                  {filterData.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setFilterDialogOpen(true)}
                    >
                      Filters
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <ToggleFilterSection showFilterClass="mt--8" classes={classes} toggleFilter={() => setFilterOpen(!isFilterOpen)} isFilterOpen={isFilterOpen} />
          {props.isFirm !== true && (
            <Grid className={`dashboard-timesheet-main-container ${isFilterOpen ? `${classes.mainOpenContainer}` : classes.mainCloseContainer}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TimeEntriesStats
                    search={state.searchObj}
                    hoursAndFeeCount={hoursAndFeeCount}
                    timesheetCountDic={timesheetCountDic}
                    navigateToTimeSheets={(status: any, isDisapproved, isShowOnlyDisapproved) => {
                      navigateToTimeSheets(status, isDisapproved, isShowOnlyDisapproved);
                    }}
                    isLoading={timeEntriesLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ProfessionalLevelHourStats
                    professionalHourStats={professionalHoursStatsData}
                    onSort={(sortBy?: any) => onSortProfessionalLevel(sortBy)}
                    onExport={() => onExportDialogOpen("professional-level")}
                    sortBy={state.searchObj.professionalLevelSortBy}
                    sortOrder={state.searchObj.professionalLevelSortOrder}
                    isLoading={professionalLevelLoading}
                  ></ProfessionalLevelHourStats>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CategoryHourStats
                    categoryHourStats={categoryHoursStatsData}
                    onSort={(sortBy?: any) => onSortCategoryHour(sortBy)}
                    onExport={() => onExportDialogOpen("category")}
                    sortBy={state.searchObj.categoryHourSortBy}
                    sortOrder={state.searchObj.categoryHourSortOrder}
                    isLoading={timeCategoryLoading}
                  ></CategoryHourStats>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FirmServiceFees
                    firmServiceFees={firmServiceFees}
                    onSort={(sortBy) => onSortFirmServiceFee(sortBy)}
                    onExport={() => onExportDialogOpen("firm-service-fees")}
                    sortBy={state.searchObj.firmServiceFeesSortBy}
                    sortOrder={state.searchObj.firmServiceFeesSortOrder}
                    isLoading={firmServiceFeesLoading}
                  ></FirmServiceFees>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FirmServiceHours
                    firmServiceHours={firmServiceHours}
                    onSort={(sortBy) => onSortFirmServiceHour(sortBy)}
                    onExport={() => onExportDialogOpen("firm-service-hours")}
                    sortBy={state.searchObj.firmServiceHoursSortBy}
                    sortOrder={state.searchObj.firmServiceHoursSortOrder}
                    isLoading={firmServiceHourLoading}
                  ></FirmServiceHours>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FirmTimesheetMetrics
                    firmTimesheetMetricsMonths={firmTimesheetMetricsMonths}
                    firmTimesheetMetrics={firmTimesheetMetrics}
                    onSort={(sortBy) => onSortFirmTimesheetMetric(sortBy)}
                    onExport={() => {
                      // exportForType.current = "firm-timesheet-metrics";
                      onExportDialogOpen("firm-timesheet-metrics")
                    }}
                    sortBy={state.searchObj.firmTimesheetMetricsSortBy}
                    sortOrder={state.searchObj.firmTimesheetMetricsSortOrder}
                    isLoading={firmTimesheetLoading}
                  ></FirmTimesheetMetrics>
                </Grid>
              </Grid>
            </Grid>
          )}
          {props.isFirm === true && (
            <Grid item xs={12} md={12} lg={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TimeEntriesStatsForAllFirms
                    search={state.searchObj}
                    allFirmsHoursAndFeeCount={allFirmsHoursAndFeeCount}
                    allFirmsTimesheetCountDic={allFirmsTimesheetCountDic}
                    hoursAndFeeCount={hoursAndFeeCount}
                    timesheetCountDic={timesheetCountDic}
                    navigateToTimeSheets={(status: any, isDisapproved, isShowOnlyDisapproved) =>
                      navigateToTimeSheets(status, isDisapproved, isShowOnlyDisapproved)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ProfessionalLevelHourStatsForFirms
                    label={"Professional Level"}
                    allProfessionalHourStats={
                      allFirmsProfessionalHoursStatsData
                    }
                    professionalHourStats={professionalHoursStatsData}
                    onSort={(sortBy?: any) => onSortProfessionalLevel(sortBy)}
                    onExport={() =>
                      onExportDialogOpenForAllFirms("professional-level")
                    }
                    sortBy={state.searchObj.professionalLevelSortBy}
                    sortOrder={state.searchObj.professionalLevelSortOrder}
                  ></ProfessionalLevelHourStatsForFirms>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CategoryHourStatsForFirms
                    allCategoryHourStats={allFirmsCategoryHoursStatsData}
                    categoryHourStats={categoryHoursStatsData}
                    label={"Time Category"}
                    onSort={(sortBy?: any) => onSortCategoryHour(sortBy)}
                    onExport={() => onExportDialogOpenForAllFirms("category")}
                    sortBy={state.searchObj.categoryHourSortBy}
                    sortOrder={state.searchObj.categoryHourSortOrder}
                  ></CategoryHourStatsForFirms>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </section>
      {isCustomDateOpen && (
        <CustomDateDialog
          startDate={state.searchObj.startDate}
          endDate={state.searchObj.endDate}
          handleDialogClose={(data?: any) => handleCloseCustomDate(data)}
        />
      )}
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              typeOfFilters="timesheets"
              selectedFilters={state.searchObj}
              loadedFilter={loadedFilter}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
      {isExportDialogOpen && (
        <ExportDialog
          exportFor={exportForType.current === "firm-timesheet-metrics" ? "timesheetOrExpenseDashboardMetrics" : 'timesheetOrExpenseDashboard'}
          handleDialogClose={(data?) => handleCloseExportDialog(data)}
        />
      )}
      {isExportDialogOpenForAllFirms && (
        <ExportDialog
          exportFor={'timesheetOrExpense'}
          handleDialogClose={(data?) =>
            handleCloseExportDialogForAllFirms(data)
          }
        />
      )}
    </React.Fragment>
  );
};

export default withConfirmDialogContext(TimesheetDashboardMain);
