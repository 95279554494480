import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import { Formik, FormikErrors } from "formik";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
  DotsVertical,
  EyeOffOutline,
  EyeOutline,
  Magnify,
} from "mdi-material-ui";
import * as React from "react";
import { USER_ROLES } from "../../Constant";
import { resetPassword } from "../../services/auth/AuthService";
import { getAllFirms } from "../../services/FirmsService";
import {
  getUserCounts,
  getUsersList,
  removeAUser,
  updateAUser,
  updateIsReadOnlyStatus,
} from "../../services/UserService";
import {
  parseJwt,
  StyledTableCell,
  StyledTableRow,
} from "../../services/UtilService";
import { IConfirmDialog, IFilter, IFirm, IFirmDetails, IKeyValue, IResetPassword, IUser } from "../../vm";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import CustomDrawer from "../common/CustomDrawer";
import Filter from "../common/Filter";
import { FirmContext } from "../common/FirmProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import ManageAccessManagementDialog from "./ManageAccessManagementDialog";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

export interface AccessManagementMainProps extends IConfirmDialog { }

const AccessManagementMain: React.FC<AccessManagementMainProps> = ({
  confirmDialog,
}) => {
  const [state, setState] = React.useState({
    search: {
      searchQuery: "",
      showOnlyActive: false,
      from: 0,
      size: 10,
      firmIds: [],
      roleIds: []
    },
    usersList: [],
    selectedItemIndex: -1,
    anchorElMenu: null,
    filterData: [
      {
        header: "Firm",
        items: [
          { count: 49, label: "Boucher", type: "checkbox", isSelected: false },
          {
            count: 15,
            label: "California Law",
            type: "checkbox",
            isSelected: false,
          },
          { count: 65, label: "Parris", type: "checkbox", isSelected: false },
        ],
      },
      {
        header: "User Role",
        items: [
          {
            count: 2,
            label: "Application Administrator",
            type: "checkbox",
            isSelected: false,
          },
          {
            count: 5,
            label: "Case Administrator",
            type: "checkbox",
            isSelected: false,
          },
          {
            count: 11,
            label: "Firm Administrator",
            type: "checkbox",
            isSelected: false,
          },
        ],
      },
    ],
    manageAccessManagementDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    resetPasswordDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    resetPassword: { userId: undefined, password: "" },
    tokenData: parseJwt(),
    rowsList: [10],
    usersCount: 0,
  } as {
    search: {
      searchQuery?: string;
      showOnlyActive?: boolean;
      from: number;
      size: number;
      firmIds: number[];
      roleIds: number[];
    };
    usersList: IUser[];
    selectedItemIndex: number;
    anchorElMenu: any;
    filterData: IFilter[];
    manageAccessManagementDialog: {
      isOpen: boolean;
      index?: number;
      data?: IUser;
    };
    resetPasswordDialog: {
      isOpen: boolean;
      index?: number;
      data?: IUser;
    };
    tokenData: any;
    resetPassword: IResetPassword;
    rowsList: number[];
    usersCount: number;
  });

  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const { showToast } = React.useContext(ToastContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const firmProvider = React.useContext(FirmContext);
  const [userFirms, setUserFirms] = React.useState([] as { value: number, label: string }[]);
  const [userDic, setUserDic] = React.useState({} as IKeyValue<IFirmDetails>);
  const [initialFirms, setInitialFirms] = React.useState(
    {} as IKeyValue<IFirmDetails>
  );
  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});

  const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

  React.useEffect(() => {
    const fetchUsers = async () => {
      await getFirmsList();
    };
    fetchUsers();
  }, []);

  const getFirmsList = async () => {
    setLoading(true);
    let result = await getAllFirms();
    setLoading(false);
    if (result?.isSuccess) {
      console.log("firms", userFirms);
      let list: any = [{ value: "all", label: "All" }];
      result.data.forEach((ele) => {
        list.push({ value: ele.lawFirmCode, label: ele.lawFirmShortName });
      });
      setUserFirms(list);
      let usersDic = result.data.reduce((acc: any, ele) => {
        acc[ele.lawFirmCode] = ele;
        return acc;
      }, {})
      setUserDic(usersDic);
      await getUserFilters(undefined, list);
    } else {
      showToast(result.message || "Error while getting firms", "error")
    }
  };

  const getUserFilters = async (search?: any, firmsList?: { value: number, label: string }[], changedProperty?: any) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
    }
    let searchForFilter = { ...tempSearch };
    // if (changedProperty) {
    //   switch (changedProperty) {
    //     case "firmUserCounts":
    //       searchForFilter.roleIds=[]
    //       break;
    //       case "userRoleCounts":
    //         searchForFilter.firmIds=[]
    //         break;
    //     default:
    //       break;
    //   }
    // }
    delete tempSearch.size;
    delete tempSearch.from;
    setLoading(true);
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {
        firmUserCounts: {},
        userRoleCounts: {}

      } as any,
    };
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      result = await getUserCounts(searchForFilter);
    }
    setLoading(false);
    if (result?.isSuccess) {
      let data = { ...result.data };
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          firmUserCounts: "firmId",
          userRoleCounts: "role"
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            firmUserCounts: {},
            userRoleCounts: {},
          }
        );
        setFilterCountsDic(filterCountDic);
      }
      let filter: IFilter[] = [];
      let firmsDic = firmsList.reduce((acc: any, ele) => {
        acc[ele.value] = ele;
        return acc;
      }, {});
      let initialFirmsTemp = { ...initialFirms };
      if (Object.keys(initialFirmsTemp).length === 0) {
        let firmCounts = data["firmUserCounts"] || [];
        initialFirmsTemp = firmCounts.reduce((acc: any, ele) => {
          acc[ele.firmId] = firmsDic[ele.firmId] || {};
          return acc;
        }, {});
      }
      Object.keys(data).map((element) => {
        let a: IFilter = { header: "", items: [], name: element };
        switch (element) {
          case 'firmUserCounts':
            a.header = "Firm";
            a.isHidden = filterData.length > 0 && filterData.find(e => e.name === element)?.isHidden || false
            let firmCounts = data["firmUserCounts"] || [];
            Object.keys(initialFirmsTemp).map((ele) => {
              a.items.push({
                label: firmsDic[ele]
                  ? firmsDic[ele].label
                  : "",
                type: "checkbox",
                count: isCountsLoaded === false
                  ?
                  (changedProperty !== undefined && changedProperty === element && tempSearch.firmIds.length > 0 ? filterData.find(ed => ed.name === element)?.items.find(ed => ed.name === String(ele))?.count || 0 : firmCounts.find((e) => e.firmId == Number(ele))
                    ?.counts || 0)
                  : filterCountDic?.["firmUserCounts"]?.[ele]
                    ?.counts || 0,
                name: String(ele),
                isSelected: tempSearch.firmIds.includes(Number(ele)),
              });
            });
            // sorting based on label i.e. firm short name
            a.items.sort((a, b) => a.label > b.label ? 1 : -1);
            break;
          case 'userRoleCounts':
            a.header = "User Role";
            a.isHidden = filterData.length > 0 && filterData.find(e => e.name === element)?.isHidden || false
            let userRoleCounts = data["userRoleCounts"] || [];
            Object.keys(USER_ROLES).map((eds: any) => {
              if (state.tokenData.Role == "2" && eds != 2) {
                console.log("role id")
              } else {
                a.items.push({
                  label: USER_ROLES[Number(eds)],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? (changedProperty !== undefined && changedProperty === element && tempSearch.roleIds.length > 0 ? filterData.find(ed => ed.name === element)?.items.find(ed => ed.name === eds)?.count || 0 : userRoleCounts.find((e) => e.role === Number(eds))?.counts || 0)
                      : filterCountDic?.["userRoleCounts"]?.[eds]
                        ?.counts || 0,
                  isSelected: tempSearch.roleIds.includes(Number(eds)),
                });
              }
            });
            break;

          default:
            a = undefined;
            break;
        }
        if (a) {
          filter.push(a);
        }
      })
      tempSearch.from = 0;
      tempSearch.size = 10;
      setCountsLoaded(true);
      setInitialFirms(initialFirmsTemp);
      setFilterData(filter);
      await fetchUsersList(tempSearch);
    } else {
      showToast(
        result?.message || "Error while fetching users counts",
        "error"
      );
    }
  }

  const fetchUsersList = async (searchObj?: any) => {
    let tempSearch = { ...state.search };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    setLoading(true);
    if (!tempSearch.searchQuery) {
      delete tempSearch.searchQuery;
    }
    if (!tempSearch.showOnlyActive) {
      delete tempSearch.showOnlyActive;
    }
    const result = await getUsersList(tempSearch);
    if (result?.isSuccess) {
      let menuItems = [];
      if (state.tokenData.Role == "2" && result?.data?.users) {
        result.data.users = result.data.users.filter(user => user.role == 2);
        result.data.count = result.data.users.length
      }
      for (let index = 10; index <= result.data.count; index = index + 10) {
        menuItems.push(index);
      }
      if (menuItems[menuItems.length - 1] < result.data.count) {
        menuItems.push(result.data.count);
      }
      setState({
        ...state,
        usersCount: result.data?.count || 0,
        usersList: result.data?.users || (result.data as any),
        search: { ...state.search, ...tempSearch },
        rowsList: [...menuItems],
      });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching users list",
        "error"
      );
    }
    setLoading(false);
  };

  const handleCheck = async (index: number) => {
    if ((state.tokenData.Role == 0 || state.tokenData.Role == 1) || (state.tokenData.Role === "2" && state.usersList[index].role === 2)) {
      let usersList = [...state.usersList];
      usersList[index].isEnabled = !usersList[index].isEnabled;
      let user = usersList[index];
      setLoading(true);
      let res = await updateUser(user);
      setLoading(false);
      if (res) {
        setState({ ...state, usersList });
      }
    }
  };

  const updateUser = async (user: IUser) => {
    let res = await updateAUser(user);
    if (res.isSuccess) {
      if (res?.isReadOnly) {
        showToast(res.message, "error");
        updateStatus(res.isReadOnly);
        return;
      } else {
        showToast("User updated successfully", "success");
        return res.data;
      }
    } else {
      showToast(res.message || "Error occurred while updating a user", "error");
      return;
    }
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setState({
      ...state,
      selectedItemIndex: index,
      anchorElMenu: event.currentTarget,
    });
  };

  const handleCloseMenu = () => {
    setState({ ...state, selectedItemIndex: -1, anchorElMenu: null });
  };


  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = state.usersList[editIndex];
    }
    setState({
      ...state,
      manageAccessManagementDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
      selectedItemIndex: -1,
      anchorElMenu: null,
    });
  };

  const handleDelete = (index: number) => {
    confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
      setLoading(true);
      let users = [...state.usersList];
      const result = await removeAUser(users[index].id as number);
      if (result?.isSuccess) {
        users.splice(index, 1);
        setState({
          ...state,
          usersList: users,
          selectedItemIndex: -1,
          anchorElMenu: null,
        });
        showToast("User deleted successfully", "success");
      } else {
        showToast(result?.message || "Error while removing user", "error");
      }
      setLoading(false);
    });
  };

  const handleAddDialogClose = (data?: IUser) => {
    let usersList = [...state.usersList];
    if (data) {
      if (state.manageAccessManagementDialog.index !== undefined) {
        usersList[state.manageAccessManagementDialog.index] = data;
        showToast("User updated successfully", "success");
      } else {
        usersList.unshift(data);
        showToast("User added successfully", "success");
      }
    }
    setState({
      ...state,
      usersList: usersList,
      manageAccessManagementDialog: {
        isOpen: false,
        data: undefined,
        index: undefined,
      },
    });
  };

  const onSearch = async (searchObj?: any) => {
    let tempSearch = { ...state.search };
    if (searchObj) {
      tempSearch = { ...state.search, ...searchObj };
    }
    await fetchUsersList(tempSearch);
  };

  const getUsersFrom = async (from: number) => {
    let search = { ...state.search };
    search.from = from;
    await fetchUsersList(search);
  };

  const onKeyDown = async (e: any) => {
    if (e.keyCode === 13) {
      await onSearch({ ...state.search, from: 0 });
    }
  };

  const handleResetPasswordDialog = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = state.usersList[editIndex];
    }
    setState({
      ...state,
      resetPasswordDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
      selectedItemIndex: -1,
      anchorElMenu: null,
    });
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    let search = { ...state.search };
    search.from = 0;
    let roleArray =
      data.find((ele) => ele.name === "userRoleCounts")?.items || [];
    let firmArray = data.find((ele) => ele.name === "firmUserCounts")?.items || [];
    search.firmIds = firmArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return Number(e.name);
      });
    search.roleIds = roleArray
      .filter((ele) => ele.isSelected === true)
      .map((e) => {
        return Number(e.name);
      });
    await getUserFilters(search, userFirms, changedProperty);
  };

  const saveReadyOnly = async (checked: boolean, index: number) => {
    let userList = [...state.usersList];
    const userId = userList[index].id;
    const result = await updateIsReadOnlyStatus(checked, userId);
    if (result?.isSuccess) {
      userList[index].isReadOnly = checked;
      setState({ ...state, usersList: userList });
      // update it when the logged in user and the user for which this is changed is same
      if (state.tokenData.CustomerId == userId) {
        updateStatus(checked);
      }
    } else {
      showToast(result?.message || 'Error while updating read only', 'error');
    }
  }

  const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className="d-flex">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <ChevronDoubleLeft />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <ChevronRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <ChevronDoubleRight />
        </IconButton>
      </div>
    );
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <section className="p-16">
        <div className="text-center">
          <Typography variant="h5">User Access Management</Typography>
        </div>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1} justify="flex-end">
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="d-flex align-items-center">
                  <TextField
                    className="flex-grow"
                    name="name"
                    value={state.search.searchQuery}
                    onChange={(event) => {
                      let value = event.target.value;
                      setState({
                        ...state,
                        search: { ...state.search, searchQuery: value },
                      });
                    }}
                    onKeyDown={(e) => {
                      onKeyDown(e);
                    }}
                    placeholder="Search by username or email"
                  />
                  &nbsp;&nbsp;
                  <IconButton
                    onClick={() => onSearch({ ...state.search, from: 0 })}
                  >
                    <Magnify />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} md={12} lg={3}>
                <Hidden mdDown>
                  {filterData.length > 0 && <Filter
                    data={filterData}
                    onChange={(data: IFilter[], changedProperty?: string) => {
                      onFilterChange(data, changedProperty);
                    }}
                  />}
                </Hidden>
                <Hidden lgUp>
                  <Grid container justify="flex-end">
                    <Grid item>
                      {filterData.length > 0 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setFilterDialogOpen(true)}
                        >
                          Filters
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
              <Grid item xs={12} md={12} lg={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} justify="flex-end" alignItems="center">
                      {/* {state.tokenData.Role == 0 && (
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                size="small"
                                checked={isReadOnly}
                                onChange={(event, checked) => saveReadyOnly(checked)}
                              />
                            }
                            label='Is Read Only'
                          />
                        </Grid>
                      )} */}
                      {(state.tokenData.Role == 0 || state.tokenData.Role == 1)&&<Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleAddDialogOpen(true);
                          }}
                          disabled={isReadOnly}
                        >
                          Add User
                        </Button>
                      </Grid>}
                    </Grid>
                    <br />
                    <TableContainer className="users-table-container moz-table-pb">
                      <Table stickyHeader size="small" className="custom-table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Username</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Access Role</StyledTableCell>
                            <StyledTableCell>Firms</StyledTableCell>
                            <StyledTableCell>Enabled</StyledTableCell>
                            {state.tokenData.Role == 0 && (
                              <StyledTableCell style={{ minWidth: 120 }}>Is Read Only</StyledTableCell>
                            )}
                            <StyledTableCell>Actions</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {state.usersList.length > 0 ? (
                            state.usersList.map((ele, index: number) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell>
                                  {ele.userName || ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {ele.email || ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {USER_ROLES[ele.role] || ""}
                                </StyledTableCell>
                                <StyledTableCell>{ele.userFirms ? ele.userFirms.map(element => (<Chip className="custom-chip" label={userDic && userDic[element] ? userDic[element].lawFirmShortName : ''}></Chip>)) : ""}</StyledTableCell>
                                <StyledTableCell>
                                  <Switch
                                    size="small"
                                    checked={ele.isEnabled}
                                    onChange={() => handleCheck(index)}
                                    disabled={(state.tokenData.Role == "2" && state.usersList[index].role != 2) || isReadOnly}
                                    name="isEnabled"
                                  />
                                </StyledTableCell>
                                {state.tokenData.Role == 0 && (
                                  <StyledTableCell>
                                    <Switch
                                      size="small"
                                      checked={ele.isReadOnly}
                                      onChange={(event, checked) => saveReadyOnly(checked, index)}
                                    />
                                  </StyledTableCell>
                                )}
                                <StyledTableCell>
                                  {((state.tokenData.Role == 0 || state.tokenData.Role == 1) || (state.tokenData.Role === "2" && ele.role === 2)) && (
                                    <IconButton
                                      size="small"
                                      onClick={(e) => handleClickMenu(e, index)}
                                    >
                                      <DotsVertical />
                                    </IconButton>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell align="center" colSpan={6}>
                                {" "}
                                No users found{" "}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={state.rowsList}
                      colSpan={5}
                      component="div"
                      count={state.usersCount}
                      rowsPerPage={state.search.size}
                      page={
                        state.search.from === 0
                          ? 0
                          : state.search.from / state.search.size
                      }
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={(event: any, newPage: number) => {
                        console.log("event", event);
                        console.log("newPage", newPage);
                        let search = { ...state.search };
                        search.from =
                          newPage === 0
                            ? 0
                            : (Number(newPage) *
                              state.search.size)
                        onSearch(search);
                      }}
                      onRowsPerPageChange={(event: any) => {
                        let value = event.target.value;
                        let search = { ...state.search };
                        search.size = value ? Number(value) : 10;
                        search.from = 0;
                        onSearch(search);
                      }}
                      ActionsComponent={TablePaginationActions}
                    />
                  </Grid>

                  {/* COMMENT THIS LATER */}
                  {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid
                      container
                      spacing={2}
                      justify="space-between"
                      className="padding-16"
                    >
                      <Grid item>
                        <Button
                          size="small"
                          color="secondary"
                          variant="outlined"
                          disabled={state.search.from <= 0 ? true : false}
                          onClick={() =>
                            getUsersFrom(state.search.from - state.search.size)
                          }
                        >
                          <ChevronLeft /> Previous
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          color="secondary"
                          variant="outlined"
                          disabled={
                            state.usersList.length !== state.search.size
                              ? true
                              : false
                          }
                          onClick={() =>
                            getUsersFrom(state.search.from + state.search.size)
                          }
                        >
                          Next <ChevronRight />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                 */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <Menu
        id="simple-menu"
        anchorEl={state.anchorElMenu}
        keepMounted
        open={Boolean(state.anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            handleResetPasswordDialog(true, state.selectedItemIndex);
          }}
          disabled={isReadOnly}
        >
          Reset Password
        </MenuItem>
        <MenuItem
          disabled={
            (state.selectedItemIndex !== -1 &&
              state.tokenData.Role === "2" &&
              state.tokenData.CustomerId ===
              String(state.usersList[state.selectedItemIndex].id)
              ? true
              : false) || isReadOnly
          }
          onClick={() => {
            handleAddDialogOpen(true, state.selectedItemIndex);
          }}
        >
          Edit User
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleDelete(state.selectedItemIndex);
          }}
        >
          Delete User
        </MenuItem> */}
      </Menu>
      {state.resetPasswordDialog.isOpen && (
        <Dialog
          open={true}
          onClose={() => handleResetPasswordDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
          <DialogContent>
            <Formik
              enableReinitialize={true}
              initialValues={state.resetPassword}
              validate={(values) => {
                let errors: FormikErrors<IResetPassword> = {};

                if (!values.password) {
                  errors.password = "Required";
                }
                if (values.password && values.password.length <= 4) {
                  errors.password = "Need to be more than 4 characters";
                }
                return errors;
              }}
              onSubmit={async (values: IResetPassword, { setSubmitting }) => {
                setLoading(true);
                let obj = { ...values };
                obj.userId = state.resetPasswordDialog.data.id;
                let result = await resetPassword(obj);
                setLoading(false);
                if (result?.isSuccess) {
                  if (result?.isReadOnly) {
                    showToast(result.message, "error");
                    updateStatus(result.isReadOnly);
                  } else {
                    showToast(`Password updated successfully`, "success");
                  }
                } else {
                  showToast(
                    result.message || `Error while updating user password`,
                    "success"
                  );
                }
                handleResetPasswordDialog(false);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          id="password"
                          name="password"
                          type={values.showPassword ? "text" : "password"}
                          label="Password"
                          onChange={handleChange}
                          error={
                            errors.password && touched.password ? true : false
                          }
                          helperText={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                          onBlur={handleBlur}
                          value={values.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  name="showPassword"
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    setFieldValue(
                                      "showPassword",
                                      !values.showPassword
                                    );
                                  }}
                                >
                                  {values.showPassword ? (
                                    <EyeOutline />
                                  ) : (
                                    <EyeOffOutline />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                          {" "}
                          <Button
                            onClick={() => handleResetPasswordDialog(false)}
                            color="primary"
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button type="submit" color="primary">
                            Update Password
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      )}
      {state.manageAccessManagementDialog.isOpen && (
        <ManageAccessManagementDialog
          data={state.manageAccessManagementDialog.data}
          handleDialogClose={(data?: IUser) => handleAddDialogClose(data)}
          updateStatus={updateStatus}
        />
      )}
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
            />
          </section>
        </CustomDrawer>
      )}
    </React.Fragment>
  );
};

export default withConfirmDialogContext(AccessManagementMain);
