import * as React from "react";
import Routes from "./Routes";
import { ConfirmDialogProvider } from "./components/common/ConfirmDialogProvider";
import { ToastProvider } from "./components/common/ToastProvider";
import { ThemeProvider } from "@material-ui/core";
import { THEME } from "./Styles";
import { FirmProvider } from "./components/common/FirmProvider";
import "./App.css";
import { NON_AUTHENTICATED_PAGES, SESSION_TIMEOUT_TIME_IN_SEC, URL_PATHS } from "./Constant";
import { isTokenExpired, removeToken } from "./services/UtilService";
import IsReadOnlyProvider from "./components/common/IsReadOnlyProvider";
import { setLoginHistory } from "./services/UserService";

const events = [
  // "load",
  "mousemove",
  // "mousedown",
  "click",
  // "scroll",
  // "keypress",
  "keydown",
];

const App: React.FC = () => {
  const timeOutRef = React.useRef(undefined);
  const timeIntervalRef = React.useRef(undefined);
  const isLoggedPerforming = React.useRef(false);
  React.useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        let hasTokenExpired = isTokenExpired();
        // if (hasTokenExpired && window.location.pathname !== "/") {
        if (hasTokenExpired && !NON_AUTHENTICATED_PAGES.includes(window.location.pathname)) {
          window.location.href = `/?redirect_to=${window.location.pathname}${window.location.search}${window.location.hash}`;
        }
      }
    });
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        updateExpiredTime();
        startInterval();
      });
    });
  }, []);

  const updateExpiredTime = () => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    timeOutRef.current = setTimeout(() => {
      localStorage.setItem(
        "boucherTemExpiredTime",
        (Date.now() + SESSION_TIMEOUT_TIME_IN_SEC * 1000).toString()
      );
    }, 300);
  };

  const startInterval = () => {
    timeIntervalRef.current = setInterval(async () => {
      const expiredTime = parseInt(
        localStorage.getItem("boucherTemExpiredTime") || "0",
        10
      );
      if (expiredTime && expiredTime < Date.now() && !isLoggedPerforming.current) {
        isLoggedPerforming.current = true;
        console.log("timeout occurred");
        await setLoginHistory({
          hostAddress: localStorage.getItem("userIp") || "UNKNOWN",
          type: "logout",
          logoutType: "auto"
        })
        logoutUser();
        cleanUp();
      }
    }, 5000);
  };

  const logoutUser = () => {
    let hasTokenExpired = isTokenExpired();
    if (!hasTokenExpired && window.location.pathname !== `/${URL_PATHS.DB_DOES_NOT_EXISTS}`) {
      removeToken();
      window.location.href = `/?redirect_to=${window.location.pathname}${window.location.search}${window.location.hash}`;
    } else {
      if (timeOutRef.current) clearTimeout(timeOutRef.current);
      if (timeIntervalRef.current) clearInterval(timeIntervalRef.current);
      localStorage.removeItem("boucherTemExpiredTime");
      if (!hasTokenExpired) removeToken(); // applicable only for /contact-us page
    }
  };

  const cleanUp = () => {
    localStorage.removeItem("boucherTemExpiredTime");
    clearInterval(timeIntervalRef.current);
    isLoggedPerforming.current = false;
    // Listener clean up. Removes the existing event listener from the window
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, updateExpiredTime);
    });
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={THEME}>
        <ConfirmDialogProvider>
          <IsReadOnlyProvider>
            <ToastProvider>
              <FirmProvider>
                <Routes />
              </FirmProvider>
            </ToastProvider>
          </IsReadOnlyProvider>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
