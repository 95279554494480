import * as React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

interface showConfirmDialogFunc {
  (title: string, content: string, successHandler: Function, cancelHandler?: Function): void;
}

export const ConfirmDialogContext = React.createContext<{
  showConfirmDialog: showConfirmDialogFunc;
}>({ showConfirmDialog: () => { } });

export const ConfirmDialogProvider: React.FC<any> = (props) => {
  const [state, setState] = React.useState({
    isOpen: false,
    successHandler: undefined,
    cancelHandler: undefined,
    title: "",
    content: "",
    confirmText: "",
    cancelText: "",
    shouldHideCancelBtn: false
  } as {
    isOpen: boolean;
    successHandler?: Function;
    cancelHandler?: Function;
    title: string;
    content: string;
    confirmText?: string;
    cancelText?: string;
    shouldHideCancelBtn?: boolean
  });
  const confirmDialogContext = React.useMemo(
    () => ({
      showConfirmDialog: (
        title: string,
        content: string,
        successHandler: Function,
        cancelHandler?: Function,
        confirmText?: string,
        cancelText?: string,
        shouldHideCancelBtn?: boolean
      ) => {
        setState({
          isOpen: true,
          successHandler,
          cancelHandler,
          title,
          content,
          confirmText,
          cancelText,
          shouldHideCancelBtn
        });
      },
    }),
    []
  );
  const closeDialog = () => {
    setState({
      ...state,
      isOpen: false,
    });
    // successHandler:undefined , title:"",content:""
  };
  return (
    <ConfirmDialogContext.Provider value={confirmDialogContext}>
      <>
        <Dialog open={state.isOpen} onClose={closeDialog}>
          <DialogTitle>{state.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{state.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            {!state.shouldHideCancelBtn && (
              <Button
                className="cancel-btn"
                onClick={() => {
                  if (state.cancelHandler) {
                    state.cancelHandler();
                  }
                  closeDialog();
                }}
                color="primary"
              >
                {state.cancelText || "Cancel"}
              </Button>
            )}
            <Button
              onClick={() => {
                if (state.successHandler) {
                  state.successHandler();
                }
                closeDialog();
              }}
              className="add-btn"
              variant="contained"
              color="primary"
              autoFocus
            >
              {state.confirmText || "Yes"}
            </Button>
          </DialogActions>
        </Dialog>
        {props.children}
      </>
    </ConfirmDialogContext.Provider>
  );
};

// HOC
export const withConfirmDialogContext = (ChildComponent: any) => (
  props: any
) => {
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);
  return (
    <ChildComponent {...props} confirmDialog={{ show: showConfirmDialog }} />
  );
};
