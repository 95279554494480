import React from "react";
import {
    Button,
    Grid,
    Typography
} from "@material-ui/core";
// import CustomDialogDrawer from "../common/CustomDialogDrawer";
import CustomDrawer from "../common/CustomDrawer";
import Loading from "../common/Loading";
import { getManagementDashboardCmoText } from "../../services/ManagementDashboardService";
import { ToastContext } from "../common/ToastProvider";
import { CMO_DIC } from "../../Constant";

// const CMO_TEXT_DICT = {
//     "time-keeping-protocol": " time",
//     "client-specific-work": "client",
//     "deposition": "deposition",
//     "periodic-committee-conference-calls": "periodic committee",
//     "periodic-status-conferences": "periodic status",
//     "identification-and-work-up-of-experts": "identification",
//     "attendance-at-seminars": "attendance",
//     "discovery-and-document-review-or-production": "discovery",
//     "review-of-filing-and-orders": "review",
//     "emails-and-correspondences": "emails",
//     "travel-time": "travel",
// }

export interface ICmoTextDialogProps {
    handleDialogClose: Function;
    type: string;
}

const CmoTextDialog: React.FC<ICmoTextDialogProps> = ({
    handleDialogClose,
    type,
}) => {
    const [isLoading, setLoading] = React.useState(false as boolean);
    const [cmoText, setCmoText] = React.useState("" as string);

    const { showToast } = React.useContext(ToastContext);

    React.useEffect(() => {
        const fetchData = async () => {
            await fetchCMOText();
        };
        fetchData();
    }, []);

    const fetchCMOText = async () => {
        setLoading(true);
        const result = await getManagementDashboardCmoText(CMO_DIC[type] || type);
        if (result?.isSuccess) {
            setCmoText(result.data);
        } else {
            showToast(
                result?.message ? result.message : "Error while getting cmo text",
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDrawer
                title={CMO_DIC[type] || type}
                onClose={() => handleDialogClose()}
                isMediumWidth={true}
                class="cmo-text-dialog"
            >
                <section style={{ padding: '32px 16px 16px' }}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12}>
                            <Typography>{cmoText}</Typography>
                        </Grid>
                        {/* <Grid item lg={12} md={12} sm={12}>
                            <Grid container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button type="button" variant="outlined"
                                        onClick={() => handleDialogClose()}
                                        color="primary"
                                    >
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </section>

            </CustomDrawer>
        </React.Fragment >
    );
};

export default CmoTextDialog;
