import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import * as React from "react";
import { INote } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import { addEntriesToNote, addNote, getAllNotes } from "../../services/NoteService";
import Select from "react-select";
import "./notes.css"

export interface ManageNotesDialogProps {
  entries: string[];
  type: "timesheets" | "expenses";
  filters?: any;
  handleDialogClose: Function;
  isSelectedAll: boolean;
  updateStatus?: Function;
  isAddToNote?: boolean;
}

const ManageNotesDialog: React.FC<ManageNotesDialogProps> = ({
  entries,
  type,
  isSelectedAll,
  filters,
  handleDialogClose,
  updateStatus,
  isAddToNote = false
}) => {
  const [state, setState] = React.useState({
    note: {
      description: "",
      type: type,
      entries: entries,
      isSelectedAll,
      selectedNoteId: ""
    },
  } as {
    note: INote;
  });
  const [isLoading, setLoading] = React.useState(false);
  const [notesList, setNotesList] = React.useState([]);
  const { showToast } = React.useContext(ToastContext);

  const closeAddDialog = (isAdded?: boolean) => {
    handleDialogClose(isAdded);
  };

  React.useEffect(() => {
    fetchNotesList();
  }, []);

  const fetchNotesList = async () => {
    if (isAddToNote) {
      let searchData = {
        from: 0,
        size: 1000,
        type: undefined
      }
      const result = await getAllNotes(searchData);
      if (result?.isSuccess) {
        if (result.data?.results) {
          let notes = result.data?.results.map(x => {
            return { label: x.description, value: x.id }
          })
          setNotesList(notes)
        }
      } else {
        showToast(
          result?.message ? result.message : "Error while fetching notes list",
          "error"
        );
      }
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDrawer
        title={isAddToNote ? "Add to note" : "Create Note"}
        onClose={() => closeAddDialog()}
        class="min-width-600"
      >
        <section className="p-16">
          <Formik
            enableReinitialize
            initialValues={state.note}
            validate={(values) => {
              let errors: FormikErrors<INote> = {};
              if (isAddToNote) {
                if (!values.selectedNoteId) {
                  errors.selectedNoteId = "Required";
                }
              } else {
                if (!values.description) {
                  errors.description = "Required";
                }
              }
              return errors;
            }}
            onSubmit={async (values: INote, { setSubmitting }) => {
              setLoading(true);
              console.log("save")
              let obj = { ...values };
              if (type === "timesheets" && filters) {
                obj = { ...obj, ...filters }
              }
              let res;
              if (isAddToNote) {
                delete obj.description;
                res = await addEntriesToNote(obj);
              } else {
                delete obj.selectedNoteId;
                res = await addNote(obj);
              }
              setLoading(false);
              if (res.isSuccess) {
                if (res?.isReadOnly) {
                  closeAddDialog();
                  showToast(res.message, "error");
                  if (updateStatus) {
                    updateStatus(res.isReadOnly);
                  }
                } else {
                  closeAddDialog(true);
                  showToast(
                    `Note ${isAddToNote ? "updated" : "created"} successfully`,
                    "success"
                  );
                }
              } else {
                showToast(
                  res.message
                    ? res.message
                    : `Error while ${isAddToNote ? "updating" : "creating"} note`,
                  "error"
                );
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  {isAddToNote ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} className="note-selection-container">
                      <Select
                        className="react-select"
                        placeholder="Note"
                        value={values.selectedNoteId ? notesList.find(x => x.value === values.selectedNoteId) : undefined}
                        onChange={(data) => {
                          setFieldValue("selectedNoteId", data?.value)
                        }}
                        options={notesList}
                      />
                      {errors.selectedNoteId && touched.selectedNoteId && (
                        <FormHelperText className="text-danger">{errors.selectedNoteId}</FormHelperText>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          id="description"
                          name="description"
                          label="Note"
                          multiline
                          rows={5}
                          onChange={handleChange}
                          error={
                            errors.description && touched.description ? true : false
                          }
                          helperText={
                            errors.description && touched.description && errors.description
                          }
                          onBlur={handleBlur}
                          value={values.description}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item>
                        <Button variant="text" onClick={() => closeAddDialog()}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isAddToNote ? "Update" : "Add"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ManageNotesDialog;
