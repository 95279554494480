import { IFirm, IStandardAPIResponse, IFirmDetails, IImportConfigure, IFirmImport, IProfessionalCharacterization } from "../vm";
import { httpClient } from "./UtilService";

export async function getAllFirms(
): Promise<IStandardAPIResponse<Array<IFirm>>> {
  try {
    let res = await httpClient<Array<IFirm>>(`firms`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getUserFirms(
  userId: number
): Promise<IStandardAPIResponse<Array<IFirmDetails>>> {
  try {
    let res = await httpClient<Array<IFirmDetails>>(`accounts/users/${userId}/firms`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getFirmsFields(lawFirmCode: string, type: string, fileFormat: string): Promise<IStandardAPIResponse<Array<IFirmDetails>>> {
  try {
    let res = await httpClient<Array<IFirmDetails>>(`firms-templates/fields`, "POST", {
      lawFirmCode,
      type,
      fileFormat
    });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function addFirmConfiguration(lawFirmCode: string, type: string, fileFormat: string, template: string, id?: number): Promise<IStandardAPIResponse<Array<IFirmDetails>>> {
  try {
    let res = await httpClient<Array<IFirmDetails>>(`firms-templates`, "PUT", {
      lawFirmCode,
      type,
      fileFormat,
      template,
      id
    }, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getFirmConfigurationList(): Promise<IStandardAPIResponse<Array<IFirmDetails>>> {
  try {
    let res = await httpClient<Array<IFirmDetails>>(`firms-templates`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function deleteFirmConfiguration(id: number): Promise<IStandardAPIResponse<Array<IFirmDetails>>> {
  try {
    let res = await httpClient<Array<IFirmDetails>>(`firms-templates/${id}`, "DELETE", undefined, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function uploadTimeSheets(details: IImportConfigure, formData: FormData): Promise<IStandardAPIResponse<Array<IFirmDetails>>> {
  try {
    let res = await httpClient<Array<IFirmDetails>>(`timesheets-import/add/${details.lawFirmCode.value}?type=${details.type.value}&timeReport=${details.timeReport.value}`, "POST", formData, true, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getUploadedTimeSheets(search): Promise<IStandardAPIResponse<{ count: number; results: Array<IFirmImport> }>> {
  try {
    let res = await httpClient<{ count: number; results: Array<IFirmImport> }>(`timesheets-import`, "POST", search, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function startImportProcessAPI(id: number): Promise<IStandardAPIResponse<Array<IFirmImport>>> {
  try {
    let res = await httpClient<Array<IFirmImport>>(`timesheets-import/${id}/start`, "PATCH", undefined, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getSelectedFileNameTabsAPI(formData: FormData): Promise<IStandardAPIResponse<Array<IFirmDetails>>> {
  try {
    let res = await httpClient<Array<IFirmDetails>>(`timesheets-import/files`, "POST", formData, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function uploadTimeSheetWithSheetNames(details): Promise<IStandardAPIResponse<Array<IFirmDetails>>> {
  try {
    let res = await httpClient<Array<IFirmDetails>>(`timesheets-import/update/sheet-names`, "PATCH", details);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getAllProfessionalCharacterizationAPI(
): Promise<IStandardAPIResponse<Array<IProfessionalCharacterization>>> {
  try {
    let res = await httpClient<Array<IProfessionalCharacterization>>(`resource-management/professional-characterizations`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}