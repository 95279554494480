import React from "react";
import { IFirmConfigure, IFirmDetails, IUser } from "../../vm";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import Select from "react-select";
import { Button, FormControl, Grid, TextField } from "@material-ui/core";
import { addFirmConfiguration, getAllFirms, getFirmsFields } from "../../services/FirmsService";
import { Formik, FormikErrors } from "formik";
import { FIRM_CONFIGURE_FILE_FORMAT, FIRM_CONFIGURE_TYPES } from "../../Constant";
import "./configure-firms.css"
import { FirmContext } from "../common/FirmProvider";
export interface IManageConfigureDialogProps {
    data?: IFirmDetails;
    handleDialogClose: any;
    updateStatus: any;
}

const ManageConfigureDialog: React.FC<IManageConfigureDialogProps> = ({
    data,
    handleDialogClose,
    updateStatus
}) => {
    // state
    const [state, setState] = React.useState({
        lawFirmCode: undefined,
        type: { label: FIRM_CONFIGURE_TYPES.timesheet, value: "timesheet" },
        fileFormat: { label: FIRM_CONFIGURE_FILE_FORMAT.excel, value: "excel" }
    } as IFirmConfigure);
    const [isLoading, setLoading] = React.useState(false);
    const [firmList, setFirmList] = React.useState([]);
    const [firmFields, setFirmFields] = React.useState([]);
    const [allFirmsFieldsFetched, setAllFirmsFieldsFetched] = React.useState(false);
    const firmProvider = React.useContext(FirmContext);

    React.useEffect(() => {
        const fetchFirms = async () => {
            await getAllFirmsList();
        };
        fetchFirms();
        if (data) {
            let fields = JSON.parse(data.template);
            setFirmFields(fields);
            setAllFirmsFieldsFetched(true)
        }
    }, []);

    React.useEffect(() => {
        getAllFormFields();
    }, [state.lawFirmCode, state.type, state.fileFormat]);

    const getAllFirmsList = async () => {
        setLoading(true);
        let userFirms = firmProvider.userFirms;
        if (userFirms.length === 0) {
            userFirms = await firmProvider.getFirmsList();
        }
        let list: any = [];
        let selectedFirm = undefined;
        userFirms.forEach((ele) => {
            let firm = { value: ele.lawFirmCode, label: ele.lawFirmShortName };
            if (data && data.lawFirmCode == ele.lawFirmCode) {
                selectedFirm = firm;
            }
            list.push(firm);
        });
        setFirmList(list);
        if (selectedFirm) {
            setState({
                ...state,
                lawFirmCode: selectedFirm
            })
        }
        setLoading(false);

        // setLoading(true);
        // let result = await getAllFirms();
        // setLoading(false);
        // if (result?.isSuccess) {
        //     let list: any = [];
        //     let selectedFirm = undefined;
        //     result.data.forEach((ele) => {
        //         let firm = { value: ele.lawFirmCode, label: ele.lawFirmShortName };
        //         if (data && data.lawFirmCode == ele.lawFirmCode) {
        //             selectedFirm = firm;
        //         }
        //         list.push(firm);
        //     });
        //     setFirmList(list);
        //     if (selectedFirm) {
        //         setState({
        //             ...state,
        //             lawFirmCode: selectedFirm
        //         })
        //     }

        // } else {
        //     showToast(result.message || "Error while getting firms", "error")
        // }
    };

    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    const closeAddDialog = (data?: boolean) => {
        handleDialogClose(data);
    };

    const updateSearchFiled = (field, value) => {
        setState({
            ...state,
            [field]: value
        })
    }

    const getAllFormFields = async () => {
        if (state.lawFirmCode && !data?.id) {
            console.log(state);
            setLoading(true);
            let result = await getFirmsFields(state.lawFirmCode.value, state.type.value, state.fileFormat.value);
            setLoading(false);
            if (result?.isSuccess) {
                let list = result.data.map(x => {
                    return {
                        column: x,
                        location: ""
                    }
                });
                setFirmFields(list);
            } else {
                showToast(result.message || "Error while getting firm fields", "error");
                setFirmFields([]);
            }
            setAllFirmsFieldsFetched(true);
        }
    }

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                title={data && data.id ? "Edit Configuration" : "Add Configuration"}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <Grid container direction="row" spacing={2} className="firm-configure-dialog">
                    <Grid item lg={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={6}>
                                <Select
                                    className="react-select"
                                    // isClearable={true}
                                    placeholder="Firm"
                                    value={state.lawFirmCode}
                                    onChange={(data) =>
                                        updateSearchFiled("lawFirmCode", data)
                                    }
                                    isDisabled={data && data.id ? true : false}
                                    options={firmList}
                                />
                            </Grid>
                            <Grid item lg={3}>
                                <Select
                                    className="react-select"
                                    placeholder="Type"
                                    value={state.type}
                                    onChange={(data) =>
                                        updateSearchFiled("type", data)
                                    }
                                    options={Object.keys(FIRM_CONFIGURE_TYPES).map(key => {
                                        return { label: FIRM_CONFIGURE_TYPES[key], value: key } as any
                                    })}
                                    isDisabled={data && data.id ? true : false}
                                />
                            </Grid>
                            <Grid item lg={3}>
                                <Select
                                    className="react-select"
                                    placeholder="File Format"
                                    value={state.fileFormat}
                                    onChange={(data) =>
                                        updateSearchFiled("fileFormat", data)
                                    }
                                    options={Object.keys(FIRM_CONFIGURE_FILE_FORMAT).map(key => {
                                        return { label: FIRM_CONFIGURE_FILE_FORMAT[key], value: key } as any
                                    })}
                                    isDisabled={data && data.id ? true : false}
                                />
                            </Grid>
                            {/* <Grid item lg={2}>
                                <Button
                                    className="w-100"
                                    color="secondary"
                                    variant="outlined"
                                    onClick={getAllFormFields}
                                >
                                    Get Fields
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item lg={12}>

                        <Formik
                            enableReinitialize={true}
                            initialValues={firmFields as any}
                            validate={(values) => {
                                let errors: FormikErrors<IFirmConfigure> = {};
                                values.forEach(field => {
                                    if (!field.location) {
                                        errors[field.column] = "Required";
                                    }
                                });
                                return errors;
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                setLoading(true);
                                let result = await addFirmConfiguration(state.lawFirmCode.value, state.type.value, state.fileFormat.value, JSON.stringify(firmFields), data?.id);
                                if (result?.isSuccess) {
                                    if (result?.isReadOnly) {
                                        showToast(result.message, "error");
                                        handleDialogClose();
                                        updateStatus(result.isReadOnly);
                                    } else {
                                        showToast(data && data.id ? `Firm configuration updated successfully` : `Firm configured added successfully`, "success");
                                        handleDialogClose(true)
                                    }
                                } else {
                                    setLoading(false);
                                    showToast(
                                        result.message || `Error while configuring firm`,
                                        "error"
                                    );
                                }
                                console.log("values", values)
                                setSubmitting(false);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        {firmFields.map((field, fieldIndex) => (
                                            <Grid item lg={12}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item lg={5}>
                                                        <label>{field.column}</label>
                                                    </Grid>
                                                    <Grid item lg={7}>
                                                        <FormControl margin="normal" required fullWidth>
                                                            <TextField
                                                                name={field.column}
                                                                label={field.column}
                                                                onChange={(e) => {
                                                                    let eve = { ...e };
                                                                    let fields = [...firmFields];
                                                                    fields[fieldIndex].location = eve.target.value;
                                                                    setFirmFields(fields);
                                                                }}
                                                                error={!field.location && errors[field.column] ? true : false}
                                                                helperText={
                                                                    !field.location && errors[field.column] && errors[field.column]
                                                                }
                                                                value={field.location}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                        {allFirmsFieldsFetched && firmFields.length === 0 && (
                                            <Grid item lg={12} alignItems="center">
                                                <p>No Fields to configure</p>
                                            </Grid>
                                        )}
                                        {allFirmsFieldsFetched && firmFields.length > 0 && (
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justify="flex-end">
                                                    <Grid item>
                                                        <Button variant="text" onClick={() => closeAddDialog()}>
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            {data?.id ? "Update" : "Add"}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </CustomDialogDrawer>
        </React.Fragment>
    );
};

export default ManageConfigureDialog;
