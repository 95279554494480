import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Switch,
    TextField,
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import * as React from "react";
import { IKeywordManagement } from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import { createAKeyword, updateAKeyword } from "../../services/TimesheetService";
import CustomDialogDrawer from "../common/CustomDialogDrawer";

export interface AddKeywordDialogProps {
    data: IKeywordManagement;
    handleDialogClose: Function;
    updateStatus: Function;
}

const AddKeywordDialog: React.FC<AddKeywordDialogProps> = ({
    data,
    handleDialogClose,
    updateStatus
}) => {
    const [state, setState] = React.useState({
        timeCategory: {
            keywordName: "",
            keywordGroup: "",
            keywordAttentionIndicator: 1,
            ...data
        },
    } as {
        timeCategory: IKeywordManagement;
    });
    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);

    const closeAddDialog = (data?: IKeywordManagement) => {
        handleDialogClose(data);
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                variant="small"
                title={state.timeCategory.id ? "Edit Keyword" : "Create Keyword"}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <section style={{ marginTop: -16 }}>
                    <Formik
                        enableReinitialize
                        initialValues={state.timeCategory}
                        validate={(values) => {
                            let errors: FormikErrors<IKeywordManagement> = {};
                            if (!values.keywordName) {
                                errors.keywordName = "Required";
                            }
                            if (!values.keywordGroup) {
                                errors.keywordGroup = "Required";
                            }
                            return errors;
                        }}
                        onSubmit={async (values: IKeywordManagement, { setSubmitting }) => {
                            setLoading(true);
                            let obj = { ...values };
                            let res;
                            if (obj.id) {
                                res = await updateAKeyword(obj);
                            } else {
                                res = await createAKeyword(obj);
                            }
                            setLoading(false);
                            if (res.isSuccess) {
                                if (res?.isReadOnly) {
                                    showToast(res.message, "error");
                                    closeAddDialog();
                                    updateStatus(res.isReadOnly);
                                } else {
                                    closeAddDialog(res.data);
                                    showToast(
                                        `Keyword ${values.id ? "updated" : "created"} successfully`,
                                        "success"
                                    );
                                }
                            } else {
                                showToast(
                                    res.message
                                        ? res.message
                                        : `Error while ${values.id ? "updating" : "creating"} keyword`,
                                    "error"
                                );
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="keywordName"
                                                name="keywordName"
                                                label="Name"
                                                onChange={handleChange}
                                                error={
                                                    errors.keywordName && touched.keywordName ? true : false
                                                }
                                                helperText={
                                                    errors.keywordName && touched.keywordName && errors.keywordName
                                                }
                                                onBlur={handleBlur}
                                                value={values.keywordName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                id="keywordGroup"
                                                name="keywordGroup"
                                                label="Group"
                                                onChange={handleChange}
                                                error={
                                                    errors.keywordGroup && touched.keywordGroup ? true : false
                                                }
                                                helperText={
                                                    errors.keywordGroup && touched.keywordGroup && errors.keywordGroup
                                                }
                                                onBlur={handleBlur}
                                                value={values.keywordGroup}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <InputLabel style={{ marginBottom: 8 }} htmlFor="keywordAttentionIndicator">
                                            Attention Indicator
                                        </InputLabel>
                                        <FormControlLabel
                                            control={
                                                <Switch size="small"
                                                    checked={values.keywordAttentionIndicator == 1 ? true : false}
                                                    onChange={(event) =>
                                                        setFieldValue("keywordAttentionIndicator", event.target.checked)
                                                    } />
                                            }
                                            label={
                                                values.keywordAttentionIndicator == 1
                                                    ? "True"
                                                    : "False"
                                            }
                                            style={{ marginLeft: -7 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} justify="flex-end">
                                            <Grid item>
                                                <Button variant="text" onClick={() => closeAddDialog()}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {values.id ? "Update" : "Add"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </section>
            </CustomDialogDrawer>
        </React.Fragment>
    );
};

export default AddKeywordDialog;
