import {
    Grid,
    Typography,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    FormControlLabel,
    Checkbox,
    Button,
    TextField,
    FormControl,
    Menu,
    MenuItem,
    Accordion, AccordionDetails, AccordionSummary
} from "@material-ui/core";

import {
    ChevronDown
} from "mdi-material-ui";
import * as React from "react";
import {
    StyledTableCell,
    StyledTableRow,
    getFileName,
    getHostDetails,
    getReportFileName,
    getSubDomain,
    getToken,
    removeNulls,
} from "../../services/UtilService";
import { IConfirmDialog } from "../../vm";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import ReactSelect from "react-select";
import { FirmContext } from "../common/FirmProvider";
import { API_URL, MAIN_CASE_NAME } from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import { Formik, FormikErrors } from "formik";
import SaveFilterDialog from "../common/SaveFilterDialog";
import LoadCommitteeFilter from "./LoadCommitteeFilter";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

export interface AccessManagementMainProps extends IConfirmDialog { }

const CommitteeReport: React.FC<AccessManagementMainProps> = ({
    confirmDialog,
}) => {
    const exhibitSectionsDic = {
        "Fees Summary": "Fees Summary",
        "by Time Category": "By Time Category",
        "by Professional Level": "By Professional Level",
        "by Resource": "By Resource",
        "Firm Resources": "Firm Resources",
        "Disapproved Entries": "Disapproved Entries",
        "Disapprovals By Category": "Disapprovals By Category"
    }

    const [state, setState] = React.useState({
        search: {
            searchName: "",
            lawFirmCodeDetails: undefined,
            from: 0,
            size: 10,
            statuses: [],
            firms: [],
            persons: [],
            startDate: undefined,
            endDate: undefined,
            burstReportSortBy: [],
            sortOrder: "asc",
            keyword: [],
            min: "",
            max: "",
            minWordCount: "",
            maxWordCount: "",
            showDisapproved: false,
            descriptionSearchFilters: [],
            categoryCode: [],
            workType: [],
            professionalLevel: [],
            showMultipleBillingRates: false,
            approvedBy: [],
            hideDetails: false,
            selectedBurstReports: ["Do Not Burst"],
            selectedColumns: [],
            showEntriesWhen: [],
            selectedRows: [],
            selectedBins: "20",
            selectedMetrics: [],
            showOnlyDisapproved: false,
            noteId: "",
            exhibitName: "D",
            exhibitSectionsDetails: {
                sections: [
                    {
                        "sectionName": "Fees Summary",
                        "isPDF": true,
                        "isImage": false,
                        "isExcel": false
                    },
                    {
                        "sectionName": "by Time Category",
                        "isPDF": true,
                        "isImage": false,
                        "isExcel": false
                    },
                    {
                        "sectionName": "by Professional Level",
                        "isPDF": true,
                        "isImage": false,
                        "isExcel": false
                    },
                    {
                        "sectionName": "by Resource",
                        "isPDF": true,
                        "isImage": false,
                        "isExcel": false
                    },
                    {
                        "sectionName": "Firm Resources",
                        "isPDF": true,
                        "isImage": false,
                        "isExcel": false
                    },
                    {
                        "sectionName": "Disapproved Entries",
                        "isPDF": true,
                        "isImage": false,
                        "isExcel": false,
                        hideImage: true
                    },
                    {
                        "sectionName": "Disapprovals By Category",
                        "isPDF": false,
                        "isImage": false,
                        "isExcel": false,
                        // hideExcel: true
                    }
                ],
                isAllPDFSelected: false,
                isAllImageAllSelected: false,
                isAllExcelSelected: false,
            },
            showCoverPage: false,
            coverPageName: "",
            headerName: "",
            showFooterPageNo: false,
            footerPageName: "",
            fileDownloadName: "",
        },
        manageFileDownloadNameDialog: {
            isOpen: false,
            data: undefined,
        }
    } as {
        search: any;
        manageFileDownloadNameDialog: {
            isOpen: boolean;
            data?: any;
        };
    });

    const [hostDetails, setHostDetails] = React.useState(getHostDetails())

    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);
    const [firmList, setFirmList] = React.useState([]);
    const [actionAnchorEl, setActionAnchorEl] = React.useState<null | HTMLElement>(null);
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<null | HTMLElement>(null);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const { updateStatus } = React.useContext(IsReadOnlyContext);
    const [loadFilterDialog, setLoadFilterDialog] = React.useState({
        isOpen: false,
    });
    const [saveFilterDialog, setSaveFilterDialog] = React.useState({
        isOpen: false
    });
    const firmProvider = React.useContext(FirmContext);

    React.useEffect(() => {
        const fetchDetails = async () => {
            await getAllFirmsList();
        };
        fetchDetails();
    }, []);

    const getAllFirmsList = async () => {
        setLoading(true);
        let userFirms = firmProvider.userFirms;
        if (userFirms.length === 0) {
            userFirms = await firmProvider.getFirmsList();
        }
        let list: any = [];
        userFirms.forEach((ele) => {
            let firm = { value: ele.lawFirmCode, label: ele.lawFirmLongName };
            list.push(firm);
        });
        setFirmList(list);
        setLoading(false);

    };


    const fetchCommitteeReportDetails = async (searchObj?: any) => {
        let tempSearch = { ...state.search };
        if (searchObj) {
            tempSearch = { ...tempSearch, ...searchObj };
        }
        if (!tempSearch.lawFirmCode) {
            delete tempSearch.lawFirmCode;
        }
        try {
            let canSearch = false;
            let dataToSend = JSON.parse(JSON.stringify(tempSearch));
            dataToSend.exhibitSectionsDetails.sections = dataToSend.exhibitSectionsDetails.sections.map(section => {
                if (!canSearch) {
                    canSearch = section.isExcel || section.isImage || section.isPDF;
                }
                section.isExcel = section.isExcel ? "true" : "false";
                section.isImage = section.isImage ? "true" : "false";
                section.isPDF = section.isPDF ? "true" : "false";
                return section;
            })
            if (canSearch) {
                setLoading(true);
                await downloadExhibitReport(removeNulls({
                    ...dataToSend,
                    exhibitSections: dataToSend.exhibitSectionsDetails.sections,

                }));
            } else {
                showToast("Please select at least one report type", "error");
            }
        } catch (error) {

        }
        setLoading(false);

    };

    const downloadExhibitReport = async (search) => {
        let result = await fetch(
            `${API_URL}timesheets/pdf-common-benefit-exhibit`,
            {
                method: "POST",
                body: JSON.stringify(search),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: "Bearer " + (getToken() || ""),
                    dbName: getSubDomain(),
                },
            }
        );
        let pdf = await result.blob();
        const pdfFile = await new Blob([pdf], {
            type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
        });
        const pdfFileURL = await URL.createObjectURL(pdfFile);
        var a: any = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display:none";
        a.href = pdfFileURL;
        a.download = await getReportFileName(search.fileDownloadName);
        a.click();

    }

    const onSearch = async (searchObj?: any) => {
        let tempSearch = { ...state.search };
        if (searchObj) {
            tempSearch = { ...state.search, ...searchObj };
        }
        if (tempSearch.showCoverPage && !tempSearch.coverPageName) {
            showToast("Please enter cover page name!", "error")
            return;
        }
        let canSearch = false
        let dataToSend = JSON.parse(JSON.stringify(tempSearch));
        dataToSend.exhibitSectionsDetails.sections = dataToSend.exhibitSectionsDetails.sections.map(section => {
            if (!canSearch) {
                canSearch = section.isExcel || section.isImage || section.isPDF;
            }
            section.isExcel = section.isExcel ? "true" : "false";
            section.isImage = section.isImage ? "true" : "false";
            section.isPDF = section.isPDF ? "true" : "false";
            return section;
        })
        if (canSearch) {
            setState({ ...state, manageFileDownloadNameDialog: { isOpen: true, data: tempSearch } });
        } else {
            showToast("Please select at least one report type", "error");
        }
        // await fetchCommitteeReportDetails(tempSearch);
    };

    const handleExhibitSectionCheckBox = (index, prop: "isPDF" | "isExcel" | "isImage") => {
        let searchDetails = { ...state.search };
        searchDetails.exhibitSectionsDetails.sections[index][prop] = !searchDetails.exhibitSectionsDetails.sections[index][prop];
        let isAllSelected = true;
        let key = "";
        switch (prop) {
            case "isPDF":
                key = "isAllPDFSelected"
                break;
            case "isExcel":
                key = "isAllExcelSelected"
                break;
            case "isImage":
                key = "isAllImageAllSelected"
                break;
            default:
                break;
        }
        searchDetails.exhibitSectionsDetails.sections.forEach(section => {
            if (!section[prop]) {
                if (!(prop === "isImage" && section.hideImage)) {
                    isAllSelected = false;
                }
            }
        });
        searchDetails.exhibitSectionsDetails[key] = isAllSelected;
        setState({
            ...state,
            search: { ...searchDetails }
        });
    }

    const handleExhibitSectionAllCheckBox = (prop: string) => {
        let searchDetails = { ...state.search };
        searchDetails.exhibitSectionsDetails[prop] = !searchDetails.exhibitSectionsDetails[prop];
        let key = "";
        switch (prop) {
            case "isAllPDFSelected":
                key = "isPDF";
                break;
            case "isAllExcelSelected":
                key = "isExcel";
                break;
            case "isAllImageAllSelected":
                key = "isImage";
                break;
            default:
                break;
        }
        if (key) {
            searchDetails.exhibitSectionsDetails.sections.forEach(section => {
                section[key] = (key === "isImage" && section.hideImage) ? false : searchDetails.exhibitSectionsDetails[prop];
            });
        }
        setState({
            ...state,
            search: { ...searchDetails }
        });
    }

    const manageFileDownloadNameDialog = async (isOpen: boolean, name?: string) => {
        if (name) {
            // let searchObj = state.manageFileDownloadNameDialog.data;
            await fetchCommitteeReportDetails({ ...state.search, fileDownloadName: `${(hostDetails.subdomain === 'localhost' || hostDetails.isIp) ? MAIN_CASE_NAME : hostDetails.subdomain}-${name}`})
        }
        setState({ ...state, manageFileDownloadNameDialog: { isOpen, data: undefined } })
    }

    const handleOpenActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setActionAnchorEl(event.currentTarget);
    };

    const handleCloseActionMenu = () => {
        setActionAnchorEl(null);
        if (popoverAnchorEl) {
            setPopoverAnchorEl(null);
        }
    };

    const handleOpenLoadFilter = () => {
        handleCloseActionMenu();
        setLoadFilterDialog({
            isOpen: true
        });
    }

    const handleOpenSaveFilter = () => {
        let canSearch = false
        let dataToSend = JSON.parse(JSON.stringify(state.search));
        dataToSend.exhibitSectionsDetails.sections.forEach(section => {
            if (!canSearch) {
                canSearch = section.isExcel || section.isImage || section.isPDF;
            }
        })
        if (canSearch) {
            handleCloseActionMenu();
            setSaveFilterDialog({
                isOpen: true,
            })
        } else {
            showToast("Please select at least one report type", "error");
        }
    }

    const handleCloseSaveFilterDialog = () => {
        setSaveFilterDialog({
            isOpen: false
        })
    }

    const handleCloseLoadFilterDialog = async (data) => {
        if (data) {
            if (data.filters) {
                setState({
                    ...state,
                    search: {
                        ...state.search,
                        ...data.filters
                    }
                })
            }
        }
        setLoadFilterDialog({
            isOpen: false
        })
    }

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="p-16 committee-report-section">
                <div className="text-center">
                    <Typography variant="h5">Committees Report</Typography>
                </div>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* <Card className="p-16"> */}
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <ReactSelect
                                    className="react-select"
                                    placeholder="Firm"
                                    isClearable
                                    isMulti
                                    value={state.search.lawFirmCodeDetails}
                                    onChange={(data) =>
                                        setState({
                                            ...state,
                                            search: {
                                                ...state.search,
                                                firms: data && data.length > 0 ? data.map(x => x.value) : "",
                                                lawFirmCodeDetails: data
                                            },
                                        })
                                    }
                                    options={firmList}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item>
                                        <Button
                                            id="action_menu_button"
                                            aria-controls="action_menu"
                                            aria-haspopup="true"
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleOpenActionMenu}
                                        >
                                            Actions  <ChevronDown />
                                        </Button>
                                        <Menu
                                            className="actions-menu"
                                            id="action_menu"
                                            anchorEl={actionAnchorEl}
                                            keepMounted
                                            open={Boolean(actionAnchorEl)}
                                            onClose={handleCloseActionMenu}
                                        >
                                            <MenuItem onClick={() => {
                                                handleOpenSaveFilter();
                                            }}
                                            >
                                                Save Report Config
                                            </MenuItem>
                                            <MenuItem id="action_load_filter" onClick={() => {
                                                handleOpenLoadFilter();
                                            }}>
                                                Load Report Config
                                            </MenuItem>
                                        </Menu>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                onSearch({ ...state.search })
                                            }}
                                        >
                                            Download Report
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>


                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ChevronDown />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className="accordion-header">
                                            Cover Page
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            checked={state.search.showCoverPage ? true : false}
                                                            onChange={() => {
                                                                setState({
                                                                    ...state,
                                                                    search: { ...state.search, showCoverPage: !state.search.showCoverPage,coverPageName:"" }
                                                                })
                                                            }}
                                                        />
                                                    }
                                                    label="Include Cover Page"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                {state.search.showCoverPage && (<FormControl margin="normal" required fullWidth>
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            maxLength: 10
                                                        }}
                                                        label="Cover Page Text"
                                                        onChange={(e) => {
                                                            setState({
                                                                ...state,
                                                                search: { ...state.search, coverPageName: e.target.value }
                                                            })
                                                        }}
                                                        value={state.search.coverPageName}
                                                    />
                                                </FormControl>)}
                                            </Grid>
                                        </Grid>

                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ChevronDown />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className="accordion-header">
                                            Header
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item md={4} xs={6}>
                                                <FormControl margin="normal" required fullWidth>
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            maxLength: 15
                                                        }}
                                                        label="Header Text"
                                                        onChange={(e) => {
                                                            setState({
                                                                ...state,
                                                                search: { ...state.search, headerName: e.target.value }
                                                            })
                                                        }}
                                                        value={state.search.headerName}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ChevronDown />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className="accordion-header">
                                            Footer
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            checked={state.search.showFooterPageNo ? true : false}
                                                            onChange={() => {
                                                                setState({
                                                                    ...state,
                                                                    search: { ...state.search, showFooterPageNo: !state.search.showFooterPageNo }
                                                                })
                                                            }}
                                                        />
                                                    }
                                                    label="Include Page No."
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item md={4} xs={6}>
                                                        <FormControl margin="normal" required fullWidth>
                                                            <TextField
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                inputProps={{
                                                                    maxLength: 15
                                                                }}
                                                                label="Footer Text"
                                                                onChange={(e) => {
                                                                    setState({
                                                                        ...state,
                                                                        search: { ...state.search, footerPageName: e.target.value }
                                                                    })
                                                                }}
                                                                value={state.search.footerPageName}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>


                            </Grid>
                        </Grid>
                        {/* </Card> */}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TableContainer className="resources-table-container moz-table-pb">
                                            <Table stickyHeader size="small" className="custom-table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            Sections
                                                        </StyledTableCell>
                                                        <StyledTableCell className="text-center" style={{ minWidth: 220, maxWidth: 220, width: 220 }}>
                                                            Export PDF
                                                        </StyledTableCell>
                                                        <StyledTableCell className="text-center" style={{ minWidth: 220, maxWidth: 220, width: 220 }}>
                                                            Export Excel

                                                        </StyledTableCell>
                                                        <StyledTableCell className="text-center" style={{ minWidth: 220, maxWidth: 220, width: 220 }}>
                                                            Export Image
                                                        </StyledTableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <StyledTableCell></StyledTableCell>
                                                        <StyledTableCell className="text-center" style={{ minWidth: 220, maxWidth: 220, width: 220 }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={state.search.exhibitSectionsDetails.isAllPDFSelected ? true : false}
                                                                        onChange={() =>
                                                                            handleExhibitSectionAllCheckBox("isAllPDFSelected")
                                                                        }
                                                                    />
                                                                }
                                                                label="Select All"
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell className="text-center" style={{ minWidth: 220, maxWidth: 220, width: 220 }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={state.search.exhibitSectionsDetails.isAllExcelSelected ? true : false}
                                                                        onChange={() =>
                                                                            handleExhibitSectionAllCheckBox("isAllExcelSelected")
                                                                        }
                                                                    />
                                                                }
                                                                label="Select All"
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell className="text-center" style={{ minWidth: 220, maxWidth: 220, width: 220 }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={state.search.exhibitSectionsDetails.isAllImageAllSelected ? true : false}
                                                                        onChange={() =>
                                                                            handleExhibitSectionAllCheckBox("isAllImageAllSelected")
                                                                        }
                                                                    />
                                                                }
                                                                label="Select All"
                                                            />
                                                        </StyledTableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {state.search.exhibitSectionsDetails.sections.map((section, sectionIndex: number) => (
                                                        <StyledTableRow key={sectionIndex}>
                                                            <StyledTableCell>{exhibitSectionsDic[section.sectionName]}</StyledTableCell>
                                                            <StyledTableCell className="text-center">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            checked={section.isPDF ? true : false}
                                                                            onChange={() =>
                                                                                handleExhibitSectionCheckBox(sectionIndex, "isPDF")
                                                                            }
                                                                        />
                                                                    }
                                                                    label=""
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell className="text-center">
                                                                {!section.hideExcel && (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                checked={section.isExcel ? true : false}
                                                                                onChange={() =>
                                                                                    handleExhibitSectionCheckBox(sectionIndex, "isExcel")
                                                                                }
                                                                            />
                                                                        }
                                                                        label=""
                                                                    />
                                                                )}
                                                            </StyledTableCell>
                                                            <StyledTableCell className="text-center">
                                                            {!section.hideImage && (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            checked={section.isImage ? true : false}
                                                                            onChange={() =>
                                                                                handleExhibitSectionCheckBox(sectionIndex, "isImage")
                                                                            }
                                                                        />
                                                                    }
                                                                    label=""
                                                                />
                                                            )}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>

            {saveFilterDialog.isOpen && (
                <SaveFilterDialog isCommitteeReport={true} selectedFilters={state.search} isSaveFilter={true} handleDialogClose={handleCloseSaveFilterDialog} updateStatus={updateStatus} />
            )}

            {loadFilterDialog.isOpen && (
                <LoadCommitteeFilter handleDialogClose={handleCloseLoadFilterDialog} />
            )}

            {state.manageFileDownloadNameDialog.isOpen && (
                <CustomDrawer
                    title={"Report File Name"}
                    onClose={() => manageFileDownloadNameDialog(false)}
                >
                    <section className="p-16">
                        <Grid container direction="column" spacing={2}>
                            <Grid item lg={12} md={12} sm={12}>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{ name: "" }}
                                    validate={(values) => {
                                        let errors: FormikErrors<{ name: string }> = {};
                                        if (!values.name || !values.name.replace(/\s+/g, "")) {
                                            errors.name = "Required";
                                        }
                                        return errors;
                                    }}
                                    onSubmit={async (values: any, { setSubmitting }) => {
                                        manageFileDownloadNameDialog(false, values.name);
                                        setSubmitting(false);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={2}>
                                                <Grid item lg={12}>
                                                    <Grid container spacing={2} alignItems="center" alignContent="stretch">
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                {(hostDetails.subdomain === 'localhost' || hostDetails.isIp) ? MAIN_CASE_NAME : hostDetails.subdomain} -
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className="flex-1">
                                                            <FormControl margin="normal" required fullWidth>
                                                                <TextField
                                                                    id="name"
                                                                    name="name"
                                                                    type="text"
                                                                    label="File Name"
                                                                    onChange={handleChange}
                                                                    error={
                                                                        errors.name && touched.name ? true : false
                                                                    }
                                                                    helperText={
                                                                        errors.name &&
                                                                        touched.name &&
                                                                        errors.name
                                                                    }
                                                                    onBlur={handleBlur}
                                                                    value={values.name}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2} justify="flex-end">
                                                        <Grid item>
                                                            <Button variant="outlined"
                                                                onClick={() => manageFileDownloadNameDialog(false)}
                                                                color="primary"
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button disabled={isSubmitting} variant="contained" type="submit" color="primary">
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </section>
                </CustomDrawer>
            )}
        </React.Fragment>
    );
};

export default withConfirmDialogContext(CommitteeReport);
