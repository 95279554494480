import { IResource, IResourceAlias, IResourceAliasAssign, IResourceAliasSearch, IResourceResponse, IResourceSearch, IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";

export async function getAllResourcesList(
  searchObj: IResourceSearch
): Promise<IStandardAPIResponse<IResourceResponse>> {
  try {
    let res = await httpClient<IResourceResponse>(`resource-management`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getResourceAliasList(
  searchObj: IResourceAliasSearch,
): Promise<IStandardAPIResponse<IResourceAlias[]>> {
  try {
    let res = await httpClient<IResourceAlias[]>(`resource-management/alias`, "GET", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function removeAliasFromList(
  data: IResourceAliasAssign
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`resource-management/delete-alias`, "POST", data, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function addNewAlias(
  data: IResource, isNewResource: boolean
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`resource-management/${isNewResource ? "add" : "edit"}`, isNewResource ? "PUT" : "PATCH", data, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function addNewResourceAlias(
  data: IResourceAliasAssign
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`resource-management/add-alias`, "POST", data, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getResourcesListForAlias(lawFirmCode: string): Promise<IStandardAPIResponse<string[]>> {
  try {
    let res = await httpClient<string[]>(`resource-management?lawFirmCode=${lawFirmCode}`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getResourceDetailsAPI(resourceName: string, lawFirmCode: string): Promise<IStandardAPIResponse<IResource>> {
  try {
    let res = await httpClient<IResource>(`resource-management/resource`, "GET", { resourceName, lawFirmCode });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
