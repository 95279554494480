import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Home from 'mdi-material-ui/Home';
import { Fab } from '@material-ui/core';
import { ClassProps } from '../../vm';
const MyLink = (props: any) => <Link to='/' {...props} />;

export interface Page404Props extends ClassProps {}

export interface Page404State {}

class Page404 extends React.Component<Page404Props, Page404State> {
  constructor(props: Page404Props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Typography variant='h3' gutterBottom>
          4
          <span role='img' aria-labelledby='lost' style={{ color: 'red' }}>
            🚫
          </span>
          4
        </Typography>
        <Typography variant='h5' gutterBottom>
          Looks like you are stranded... Lets go home
        </Typography>
        <br />
        <Fab component={MyLink} color='primary' aria-label='home'>
          <Home />
        </Fab>
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      padding: theme.spacing(4),
      width: '400px',
      margin: '16px auto',
    },
    paper: {},
    button: {},
  });

export default withStyles(styles)(Page404);
