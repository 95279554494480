import * as React from 'react';
import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    Typography,
    TableBody,
    TableRow,
    TablePagination,
    IconButton,
} from "@material-ui/core";
import {
    IReportHeader,
    IReportResponse,
    IRportGroupBy
} from "../../vm";
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight,
} from "mdi-material-ui";
import {
    StyledTableCell, StyledTableRow, convertToFixed, currencyFormat, hoursFormat, numberFormat,
} from "../../services/UtilService";
import "./report.css"
import { BURST_REPORTS_MAP_WITH_VALUE_FOR_IMPORTED_TIME_ENTRIES_REPORT, DATE_COLUMNS, IMPORTED_TIME_ENTRIES_METRICS_MAP_WITH_VALUE, IMPORTED_TIME_ENTRIES_REPORT_METRICS_DICT } from '../../Constant';
import moment from 'moment';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';

interface ImportedTimeEntriesReportProps {
    tableData: IReportResponse[];
    selectedMetrics: string[];
    selectedColumns: string[];
    selectedBurstReports: string[];
    hideDetails: boolean;
    lastRowPerPageDict: any;
    setLastRowPerPageDict: any;
}

const ImportedTimeEntriesReport: React.FC<ImportedTimeEntriesReportProps> = ({ tableData, selectedMetrics, selectedColumns, selectedBurstReports, hideDetails, lastRowPerPageDict, setLastRowPerPageDict }) => {
    const reportRowsPerPage = React.useRef([]);


    const [formattedData, setFormattedData] = React.useState([] as any);
    const [selectedHeaderProps, setSelectedHeaderProps] = React.useState([] as string[]);

    const subTotalMergeColumns = React.useRef({})

    React.useEffect(() => {
        reportRowsPerPage.current = [];
        for (let i = 10; i <= 3000; i = i + 10) {
            reportRowsPerPage.current.push(i);
        }
        let selectedHeaderPropsList = [...selectedMetrics];
        if (selectedMetrics.includes('all')) {
            selectedHeaderPropsList = Object.keys(IMPORTED_TIME_ENTRIES_REPORT_METRICS_DICT).filter((d) => d !== 'all');
        }
        if (hideDetails) {
            selectedHeaderPropsList = selectedHeaderPropsList.filter(x => x !== "description")
        }
        setSelectedHeaderProps(selectedHeaderPropsList);
        console.log(selectedHeaderPropsList);
        subTotalMergeColumns.current = {}
        buildData();
    }, []);

    const getSubTotalLabel = (tableData: any, propsName: string) => {
        let value = tableData[propsName];
        value = DATE_COLUMNS.indexOf(propsName) === -1 ? value : moment(value).format("MM/DD/YYYY");

        // value = propsName !== 'serviceDate' ? value : isNaN(Date.parse(value)) ? value : moment(value).format("MM/DD/YYYY");
        return `${value||""}: Sub Totals`;
    }

    const buildData = () => {
        let parentArray = [];
        tableData.forEach((row) => {
            let childParentArray = [];
            if (row.data) {
                row.data.forEach((table, tableIndex) => {
                    let childArray = [];
                    let selectedHeaders = [];
                    const tableRowSpan = { [`${table.groupByProp}RowSpan`]: table.count };
                    const tableRowSpanZero = { [`${table.groupByProp}RowSpan`]: table.count > 1 ? 0 : 1 };
                    if (table.groupByProp) {
                        // adding this value to table
                        !selectedHeaders.includes(table.groupByProp) && selectedHeaders.push(table.groupByProp);
                        table.values.forEach((table2, table2Index) => {
                            const table2RowSpan = { ...tableRowSpan, [`${table2.groupByProp}RowSpan`]: table2.count };
                            const table2RowSpanZero = { ...tableRowSpanZero, [`${table2.groupByProp}RowSpan`]: table2.groupByProp && table2.count > 1 ? 0 : 1 };
                            if (table2.groupByProp) {
                                // adding this value to table
                                !selectedHeaders.includes(table2.groupByProp) && selectedHeaders.push(table2.groupByProp);
                                table2.values.forEach((table3, table3Index) => {
                                    const table3RowSpan = { ...table2RowSpan, [`${table3.groupByProp}RowSpan`]: table3.count };
                                    const table3RowSpanZero = { ...table2RowSpanZero, [`${table3.groupByProp}RowSpan`]: table3.groupByProp && table3.count > 1 ? 0 : 1 };
                                    if (table3.groupByProp) {
                                        // adding this value to table
                                        !selectedHeaders.includes(table3.groupByProp) && selectedHeaders.push(table3.groupByProp);
                                        table3.values.forEach((table4, table4Index) => {
                                            const table4RowSpan = { ...table3RowSpan, [`${table4.groupByProp}RowSpan`]: table4.count };
                                            const table4RowSpanZero = { ...table3RowSpanZero, [`${table4.groupByProp}RowSpan`]: table4.groupByProp && table4.count > 1 ? 0 : 1 };
                                            if (table4.groupByProp) {
                                                // adding this value to table
                                                !selectedHeaders.includes(table4.groupByProp) && selectedHeaders.push(table4.groupByProp);
                                                table4.values.forEach((table5, table5Index) => {
                                                    const table5RowSpan = { ...table4RowSpan, [`${table5.groupByProp}RowSpan`]: table5.count };
                                                    const table5RowSpanZero = { ...table4RowSpanZero, [`${table5.groupByProp}RowSpan`]: table5.groupByProp && table5.count > 1 ? 0 : 1 };
                                                    if (table5.groupByProp) {
                                                        // adding this value to table
                                                        !selectedHeaders.includes(table5.groupByProp) && selectedHeaders.push(table5.groupByProp);
                                                        table5.values.forEach((table6, table6Index) => {
                                                            const table6RowSpan = { ...table5RowSpan, [`${table6.groupByProp}RowSpan`]: table6.count };
                                                            const table6RowSpanZero = { ...table5RowSpanZero, [`${table6.groupByProp}RowSpan`]: table6.groupByProp && table6.count > 1 ? 0 : 1 };
                                                            if (table6.groupByProp) {
                                                                // adding this value to table
                                                                !selectedHeaders.includes(table6.groupByProp) && selectedHeaders.push(table6.groupByProp);
                                                                table6.values.forEach((table7, table7Index) => {
                                                                    const table7RowSpan = { ...table6RowSpan, [`${table7.groupByProp}RowSpan`]: table7.count };
                                                                    const table7RowSpanZero = { ...table6RowSpanZero, [`${table7.groupByProp}RowSpan`]: table7.groupByProp && table7.count > 1 ? 0 : 1 };
                                                                    if (table7.groupByProp) {
                                                                        // adding this value to table
                                                                        !selectedHeaders.includes(table7.groupByProp) && selectedHeaders.push(table7.groupByProp);
                                                                        table7.values.forEach((table8, table8Index) => {
                                                                            const table8RowSpan = { ...table7RowSpan, [`${table8.groupByProp}RowSpan`]: table8.count };
                                                                            const table8RowSpanZero = { ...table7RowSpanZero, [`${table8.groupByProp}RowSpan`]: table8.groupByProp && table8.count > 1 ? 0 : 1 };
                                                                            if (table8.groupByProp) {
                                                                                // adding this value to table
                                                                                !selectedHeaders.includes(table8.groupByProp) && selectedHeaders.push(table8.groupByProp);
                                                                                table8.values.forEach((table9, table9Index) => {
                                                                                    const table9RowSpan = { ...table8RowSpan, [`${table9.groupByProp}RowSpan`]: table9.count };
                                                                                    const table9RowSpanZero = { ...table8RowSpanZero, [`${table9.groupByProp}RowSpan`]: table9.groupByProp && table9.count > 1 ? 0 : 1 };
                                                                                    if (table9.groupByProp) {
                                                                                        // adding this value to table
                                                                                        !selectedHeaders.includes(table9.groupByProp) && selectedHeaders.push(table9.groupByProp);
                                                                                        table9.values.forEach((table10, table10Index) => {
                                                                                            if (table10.groupByProp) {

                                                                                            } else {
                                                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                    childArray.push({ uuid: Math.random(), ...table10, ...table9RowSpan });
                                                                                                } else {
                                                                                                    let obj = { ...table9RowSpanZero };

                                                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                    } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                    } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                    } else if (table7Index === 0 && table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                    } else if (table8Index === 0 && table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                    } else if (table9Index === 0 && table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                        obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                    } else if (table10Index === 0) {
                                                                                                        obj[`${table9.groupByProp}RowSpan`] = table9.count;
                                                                                                    }

                                                                                                    childArray.push({ uuid: Math.random(), ...table10, ...obj });

                                                                                                }
                                                                                                // total values logic
                                                                                                if (table9.values.length - 1 === table10Index) {
                                                                                                    updateTableRowSpan(childArray, table10, table9, row.burstReportHeaders, parentArray.length);
                                                                                                    // childArray = childArray.map((d) => {
                                                                                                    //     Object.keys(d).forEach((key) => {
                                                                                                    //         if (key !== `${table9.groupByProp}RowSpan` && key.includes("RowSpan") && d[key] > 0) {
                                                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table10);
                                                                                                    //         }
                                                                                                    //     })
                                                                                                    //     return d;
                                                                                                    // })
                                                                                                    childArray.push({ uuid: Math.random(), ...table9, [`${table9.groupByProp}RowSpan`]: 1, [table9.groupByProp]: getSubTotalLabel(table10, table9.groupByProp), isSubTotal: true });

                                                                                                    if (table8.values.length - 1 === table9Index) {
                                                                                                        updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                                        // childArray = childArray.map((d) => {
                                                                                                        //     Object.keys(d).forEach((key) => {
                                                                                                        //         if (![`${table8.groupByProp}RowSpan`, `${table9.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table10);
                                                                                                        //         }
                                                                                                        //     })
                                                                                                        //     return d;
                                                                                                        // })
                                                                                                        childArray.push({ uuid: Math.random(), ...table8, [`${table9.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [table8.groupByProp]: getSubTotalLabel(table10, table8.groupByProp), isSubTotal: true });

                                                                                                        if (table7.values.length - 1 === table8Index) {
                                                                                                            updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                            // childArray = childArray.map((d) => {
                                                                                                            //     Object.keys(d).forEach((key) => {
                                                                                                            //         if (![`${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`, `${table9.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table10);
                                                                                                            //         }
                                                                                                            //     })
                                                                                                            //     return d;
                                                                                                            // })
                                                                                                            childArray.push({ uuid: Math.random(), ...table7, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [`${table9.groupByProp}RowSpan`]: 1, [table7.groupByProp]: getSubTotalLabel(table10, table7.groupByProp), isSubTotal: true });

                                                                                                            if (table6.values.length - 1 === table7Index) {
                                                                                                                // if (table6.count > 1 && table6.values.length > 1) {
                                                                                                                updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                                // childArray = childArray.map((d) => {
                                                                                                                //     Object.keys(d).forEach((key) => {
                                                                                                                //         if (![`${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`, `${table9.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table10);
                                                                                                                //         }
                                                                                                                //     })
                                                                                                                //     return d;
                                                                                                                // });
                                                                                                                childArray.push({ uuid: Math.random(), ...table6, [`${table9.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [table6.groupByProp]: getSubTotalLabel(table10, table6.groupByProp), isSubTotal: true });
                                                                                                                // }

                                                                                                                if (table5.values.length - 1 === table6Index) {
                                                                                                                    // if (table4.count > 1 && table4.values.length > 1) {
                                                                                                                    updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                                    // childArray = childArray.map((d) => {
                                                                                                                    //     Object.keys(d).forEach((key) => {
                                                                                                                    //         if (![`${table9.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table10);
                                                                                                                    //         }
                                                                                                                    //     })
                                                                                                                    //     return d;
                                                                                                                    // });
                                                                                                                    childArray.push({ uuid: Math.random(), ...table5, [`${table9.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [table5.groupByProp]: getSubTotalLabel(table10, table5.groupByProp), isSubTotal: true });
                                                                                                                    // }

                                                                                                                    if (table4.values.length - 1 === table5Index) {
                                                                                                                        // if (table3.count > 1 && table3.values.length > 1) {
                                                                                                                        updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                                        // childArray = childArray.map((d) => {
                                                                                                                        //     Object.keys(d).forEach((key) => {
                                                                                                                        //         if (![`${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`, `${table9.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table10);
                                                                                                                        //         }
                                                                                                                        //     })
                                                                                                                        //     return d;
                                                                                                                        // });
                                                                                                                        childArray.push({ uuid: Math.random(), ...table4, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [`${table9.groupByProp}RowSpan`]: 1, [table4.groupByProp]: getSubTotalLabel(table10, table4.groupByProp), isSubTotal: true });
                                                                                                                        // }

                                                                                                                        if (table3.values.length - 1 === table4Index) {
                                                                                                                            // if (table2.count > 1 && table2.values.length > 1) {
                                                                                                                            updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                            // childArray = childArray.map((d) => {
                                                                                                                            //     Object.keys(d).forEach((key) => {
                                                                                                                            //         if (![`${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`, `${table9.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table10);
                                                                                                                            //         }
                                                                                                                            //     })
                                                                                                                            //     return d;
                                                                                                                            // });
                                                                                                                            childArray.push({ uuid: Math.random(), ...table3, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [`${table9.groupByProp}RowSpan`]: 1, [table3.groupByProp]: getSubTotalLabel(table10, table3.groupByProp), isSubTotal: true });
                                                                                                                            // }

                                                                                                                            if (table2.values.length - 1 === table3Index) {
                                                                                                                                // if (table.count > 1 && table.values.length > 1) {
                                                                                                                                updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                                // childArray = childArray.map((d) => {
                                                                                                                                //     Object.keys(d).forEach((key) => {
                                                                                                                                //         if (![`${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`, `${table9.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table10);
                                                                                                                                //         }
                                                                                                                                //     })
                                                                                                                                //     return d;
                                                                                                                                // });
                                                                                                                                childArray.push({ uuid: Math.random(), ...table2, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [`${table9.groupByProp}RowSpan`]: 1, [table2.groupByProp]: getSubTotalLabel(table10, table2.groupByProp), isSubTotal: true });
                                                                                                                                // }

                                                                                                                                if (table.values.length - 1 === table2Index) {
                                                                                                                                    // if (table.count > 1 && table.values.length > 1) {
                                                                                                                                    updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                                    // childArray = childArray.map((d) => {
                                                                                                                                    //     Object.keys(d).forEach((key) => {
                                                                                                                                    //         if (![`${table.groupByProp}RowSpan`, `${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`, `${table9.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table10);
                                                                                                                                    //         }
                                                                                                                                    //     })
                                                                                                                                    //     return d;
                                                                                                                                    // });
                                                                                                                                    childArray.push({ uuid: Math.random(), ...table, [`${table.groupByProp}RowSpan`]: 1, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [`${table9.groupByProp}RowSpan`]: 1, [table.groupByProp]: getSubTotalLabel(table10, table.groupByProp), isSubTotal: true });
                                                                                                                                    // }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    } else {
                                                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                            childArray.push({ uuid: Math.random(), ...table9, ...table8RowSpan });
                                                                                        } else {
                                                                                            let obj = { ...table8RowSpanZero };

                                                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                            } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                            } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                            } else if (table6Index === 0 && table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                            } else if (table7Index === 0 && table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                            } else if (table8Index === 0 && table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                                obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                            } else if (table9Index === 0) {
                                                                                                obj[`${table8.groupByProp}RowSpan`] = table8.count;
                                                                                            }

                                                                                            childArray.push({ uuid: Math.random(), ...table9, ...obj });

                                                                                        }
                                                                                        // total values logic
                                                                                        if (table8.values.length - 1 === table9Index) {
                                                                                            updateTableRowSpan(childArray, table9, table8, row.burstReportHeaders, parentArray.length);
                                                                                            // childArray = childArray.map((d) => {
                                                                                            //     Object.keys(d).forEach((key) => {
                                                                                            //         if (key !== `${table8.groupByProp}RowSpan` && key.includes("RowSpan") && d[key] > 0) {
                                                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table9);
                                                                                            //         }
                                                                                            //     })
                                                                                            //     return d;
                                                                                            // })
                                                                                            childArray.push({ uuid: Math.random(), ...table8, [`${table8.groupByProp}RowSpan`]: 1, [table8.groupByProp]: getSubTotalLabel(table9, table8.groupByProp), isSubTotal: true });

                                                                                            if (table7.values.length - 1 === table8Index) {
                                                                                                updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                                // childArray = childArray.map((d) => {
                                                                                                //     Object.keys(d).forEach((key) => {
                                                                                                //         if (![`${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table9);
                                                                                                //         }
                                                                                                //     })
                                                                                                //     return d;
                                                                                                // })
                                                                                                childArray.push({ uuid: Math.random(), ...table7, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [table7.groupByProp]: getSubTotalLabel(table9, table7.groupByProp), isSubTotal: true });

                                                                                                if (table6.values.length - 1 === table7Index) {
                                                                                                    // if (table6.count > 1 && table6.values.length > 1) {
                                                                                                    updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                                    // childArray = childArray.map((d) => {
                                                                                                    //     Object.keys(d).forEach((key) => {
                                                                                                    //         if (![`${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table9);
                                                                                                    //         }
                                                                                                    //     })
                                                                                                    //     return d;
                                                                                                    // });
                                                                                                    childArray.push({ uuid: Math.random(), ...table6, [`${table8.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [table6.groupByProp]: getSubTotalLabel(table9, table6.groupByProp), isSubTotal: true });
                                                                                                    // }

                                                                                                    if (table5.values.length - 1 === table6Index) {
                                                                                                        // if (table4.count > 1 && table4.values.length > 1) {
                                                                                                        updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                                        // childArray = childArray.map((d) => {
                                                                                                        //     Object.keys(d).forEach((key) => {
                                                                                                        //         if (![`${table8.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table9);
                                                                                                        //         }
                                                                                                        //     })
                                                                                                        //     return d;
                                                                                                        // });
                                                                                                        childArray.push({ uuid: Math.random(), ...table5, [`${table8.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [table5.groupByProp]: getSubTotalLabel(table9, table5.groupByProp), isSubTotal: true });
                                                                                                        // }

                                                                                                        if (table4.values.length - 1 === table5Index) {
                                                                                                            // if (table3.count > 1 && table3.values.length > 1) {
                                                                                                            updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                            // childArray = childArray.map((d) => {
                                                                                                            //     Object.keys(d).forEach((key) => {
                                                                                                            //         if (![`${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table9);
                                                                                                            //         }
                                                                                                            //     })
                                                                                                            //     return d;
                                                                                                            // });
                                                                                                            childArray.push({ uuid: Math.random(), ...table4, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [table4.groupByProp]: getSubTotalLabel(table9, table4.groupByProp), isSubTotal: true });
                                                                                                            // }

                                                                                                            if (table3.values.length - 1 === table4Index) {
                                                                                                                // if (table2.count > 1 && table2.values.length > 1) {
                                                                                                                updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                                // childArray = childArray.map((d) => {
                                                                                                                //     Object.keys(d).forEach((key) => {
                                                                                                                //         if (![`${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table9);
                                                                                                                //         }
                                                                                                                //     })
                                                                                                                //     return d;
                                                                                                                // });
                                                                                                                childArray.push({ uuid: Math.random(), ...table3, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [table3.groupByProp]: getSubTotalLabel(table9, table3.groupByProp), isSubTotal: true });
                                                                                                                // }

                                                                                                                if (table2.values.length - 1 === table3Index) {
                                                                                                                    // if (table.count > 1 && table.values.length > 1) {
                                                                                                                    updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                                    // childArray = childArray.map((d) => {
                                                                                                                    //     Object.keys(d).forEach((key) => {
                                                                                                                    //         if (![`${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table9);
                                                                                                                    //         }
                                                                                                                    //     })
                                                                                                                    //     return d;
                                                                                                                    // });
                                                                                                                    childArray.push({ uuid: Math.random(), ...table2, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [table2.groupByProp]: getSubTotalLabel(table9, table2.groupByProp), isSubTotal: true });
                                                                                                                    // }

                                                                                                                    if (table.values.length - 1 === table2Index) {
                                                                                                                        // if (table.count > 1 && table.values.length > 1) {
                                                                                                                        updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                                        // childArray = childArray.map((d) => {
                                                                                                                        //     Object.keys(d).forEach((key) => {
                                                                                                                        //         if (![`${table.groupByProp}RowSpan`, `${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`, `${table8.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table9);
                                                                                                                        //         }
                                                                                                                        //     })
                                                                                                                        //     return d;
                                                                                                                        // });
                                                                                                                        childArray.push({ uuid: Math.random(), ...table, [`${table.groupByProp}RowSpan`]: 1, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [`${table8.groupByProp}RowSpan`]: 1, [table.groupByProp]: getSubTotalLabel(table9, table.groupByProp), isSubTotal: true });
                                                                                                                        // }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            } else {
                                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                    childArray.push({ uuid: Math.random(), ...table8, ...table7RowSpan });
                                                                                } else {
                                                                                    let obj = { ...table7RowSpanZero };

                                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                    } else if (table5Index === 0 && table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                    } else if (table6Index === 0 && table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                    } else if (table7Index === 0 && table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                        obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                    } else if (table8Index === 0) {
                                                                                        obj[`${table7.groupByProp}RowSpan`] = table7.count;
                                                                                    }

                                                                                    childArray.push({ uuid: Math.random(), ...table8, ...obj });

                                                                                }
                                                                                // total values logic
                                                                                if (table7.values.length - 1 === table8Index) {
                                                                                    updateTableRowSpan(childArray, table8, table7, row.burstReportHeaders, parentArray.length);
                                                                                    // childArray = childArray.map((d) => {
                                                                                    //     Object.keys(d).forEach((key) => {
                                                                                    //         if (key !== `${table7.groupByProp}RowSpan` && key.includes("RowSpan") && d[key] > 0) {
                                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table8);
                                                                                    //         }
                                                                                    //     })
                                                                                    //     return d;
                                                                                    // })
                                                                                    childArray.push({ uuid: Math.random(), ...table7, [`${table7.groupByProp}RowSpan`]: 1, [table7.groupByProp]: getSubTotalLabel(table8, table7.groupByProp), isSubTotal: true });

                                                                                    if (table6.values.length - 1 === table7Index) {
                                                                                        // if (table6.count > 1 && table6.values.length > 1) {
                                                                                        updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                                        // childArray = childArray.map((d) => {
                                                                                        //     Object.keys(d).forEach((key) => {
                                                                                        //         if (![`${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table8);
                                                                                        //         }
                                                                                        //     })
                                                                                        //     return d;
                                                                                        // });
                                                                                        childArray.push({ uuid: Math.random(), ...table6, [`${table7.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [table6.groupByProp]: getSubTotalLabel(table8, table6.groupByProp), isSubTotal: true });
                                                                                        // }

                                                                                        if (table5.values.length - 1 === table6Index) {
                                                                                            // if (table4.count > 1 && table4.values.length > 1) {
                                                                                            updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                            // childArray = childArray.map((d) => {
                                                                                            //     Object.keys(d).forEach((key) => {
                                                                                            //         if (![`${table7.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table8);
                                                                                            //         }
                                                                                            //     })
                                                                                            //     return d;
                                                                                            // });
                                                                                            childArray.push({ uuid: Math.random(), ...table5, [`${table7.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [table5.groupByProp]: getSubTotalLabel(table8, table5.groupByProp), isSubTotal: true });
                                                                                            // }

                                                                                            if (table4.values.length - 1 === table5Index) {
                                                                                                updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                                // if (table3.count > 1 && table3.values.length > 1) {
                                                                                                // childArray = childArray.map((d) => {
                                                                                                //     Object.keys(d).forEach((key) => {
                                                                                                //         if (![`${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table8);
                                                                                                //         }
                                                                                                //     })
                                                                                                //     return d;
                                                                                                // });
                                                                                                childArray.push({ uuid: Math.random(), ...table4, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [table4.groupByProp]: getSubTotalLabel(table8, table4.groupByProp), isSubTotal: true });
                                                                                                // }

                                                                                                if (table3.values.length - 1 === table4Index) {
                                                                                                    // if (table2.count > 1 && table2.values.length > 1) {
                                                                                                    updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                                    // childArray = childArray.map((d) => {
                                                                                                    //     Object.keys(d).forEach((key) => {
                                                                                                    //         if (![`${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table8);
                                                                                                    //         }
                                                                                                    //     })
                                                                                                    //     return d;
                                                                                                    // });
                                                                                                    childArray.push({ uuid: Math.random(), ...table3, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [table3.groupByProp]: getSubTotalLabel(table8, table3.groupByProp), isSubTotal: true });
                                                                                                    // }

                                                                                                    if (table2.values.length - 1 === table3Index) {
                                                                                                        // if (table.count > 1 && table.values.length > 1) {
                                                                                                        updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                                        // childArray = childArray.map((d) => {
                                                                                                        //     Object.keys(d).forEach((key) => {
                                                                                                        //         if (![`${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table8);
                                                                                                        //         }
                                                                                                        //     })
                                                                                                        //     return d;
                                                                                                        // });
                                                                                                        childArray.push({ uuid: Math.random(), ...table2, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [table2.groupByProp]: getSubTotalLabel(table8, table2.groupByProp), isSubTotal: true });
                                                                                                        // }

                                                                                                        if (table.values.length - 1 === table2Index) {
                                                                                                            // if (table.count > 1 && table.values.length > 1) {
                                                                                                            updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                            // childArray = childArray.map((d) => {
                                                                                                            //     Object.keys(d).forEach((key) => {
                                                                                                            //         if (![`${table.groupByProp}RowSpan`, `${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`, `${table7.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table8);
                                                                                                            //         }
                                                                                                            //     })
                                                                                                            //     return d;
                                                                                                            // });
                                                                                                            childArray.push({ uuid: Math.random(), ...table, [`${table.groupByProp}RowSpan`]: 1, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [`${table7.groupByProp}RowSpan`]: 1, [table.groupByProp]: getSubTotalLabel(table8, table.groupByProp), isSubTotal: true });
                                                                                                            // }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    } else {
                                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0) {
                                                                            childArray.push({ uuid: Math.random(), ...table7, ...table6RowSpan });
                                                                        } else {
                                                                            let obj = { ...table6RowSpanZero };

                                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                            } else if (table4Index === 0 && table5Index === 0 && table6Index === 0 && table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                            } else if (table5Index === 0 && table6Index === 0 && table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                            } else if (table6Index === 0 && table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                                obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                            } else if (table7Index === 0) {
                                                                                obj[`${table6.groupByProp}RowSpan`] = table6.count;
                                                                            }

                                                                            childArray.push({ uuid: Math.random(), ...table7, ...obj });

                                                                        }
                                                                        // total values logic
                                                                        if (table6.values.length - 1 === table7Index) {
                                                                            // if (table6.count > 1 && table6.values.length > 1) {
                                                                            updateTableRowSpan(childArray, table7, table6, row.burstReportHeaders, parentArray.length);
                                                                            // childArray = childArray.map((d) => {
                                                                            //     Object.keys(d).forEach((key) => {
                                                                            //         if (key !== `${table6.groupByProp}RowSpan` && key.includes("RowSpan") && d[key] > 0) {
                                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table7);
                                                                            //         }
                                                                            //     })
                                                                            //     return d;
                                                                            // });
                                                                            childArray.push({ uuid: Math.random(), ...table6, [`${table6.groupByProp}RowSpan`]: 1, [table6.groupByProp]: getSubTotalLabel(table7, table6.groupByProp), isSubTotal: true });
                                                                            // }

                                                                            if (table5.values.length - 1 === table6Index) {
                                                                                updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                                // if (table4.count > 1 && table4.values.length > 1) {
                                                                                // childArray = childArray.map((d) => {
                                                                                //     Object.keys(d).forEach((key) => {
                                                                                //         if (![`${table6.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table7);
                                                                                //         }
                                                                                //     })
                                                                                //     return d;
                                                                                // });
                                                                                childArray.push({ uuid: Math.random(), ...table5, [`${table6.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [table5.groupByProp]: getSubTotalLabel(table7, table5.groupByProp), isSubTotal: true });
                                                                                // }

                                                                                if (table4.values.length - 1 === table5Index) {
                                                                                    // if (table3.count > 1 && table3.values.length > 1) {
                                                                                    updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                                    // childArray = childArray.map((d) => {
                                                                                    //     Object.keys(d).forEach((key) => {
                                                                                    //         if (![`${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table7);
                                                                                    //         }
                                                                                    //     })
                                                                                    //     return d;
                                                                                    // });
                                                                                    childArray.push({ uuid: Math.random(), ...table4, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [table4.groupByProp]: getSubTotalLabel(table7, table4.groupByProp), isSubTotal: true });
                                                                                    // }

                                                                                    if (table3.values.length - 1 === table4Index) {
                                                                                        // if (table2.count > 1 && table2.values.length > 1) {
                                                                                        updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                                        // childArray = childArray.map((d) => {
                                                                                        //     Object.keys(d).forEach((key) => {
                                                                                        //         if (![`${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table7);
                                                                                        //         }
                                                                                        //     })
                                                                                        //     return d;
                                                                                        // });
                                                                                        childArray.push({ uuid: Math.random(), ...table3, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [table3.groupByProp]: getSubTotalLabel(table7, table3.groupByProp), isSubTotal: true });
                                                                                        // }

                                                                                        if (table2.values.length - 1 === table3Index) {
                                                                                            // if (table.count > 1 && table.values.length > 1) {
                                                                                            updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                            // childArray = childArray.map((d) => {
                                                                                            //     Object.keys(d).forEach((key) => {
                                                                                            //         if (![`${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table7);
                                                                                            //         }
                                                                                            //     })
                                                                                            //     return d;
                                                                                            // });
                                                                                            childArray.push({ uuid: Math.random(), ...table2, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [table2.groupByProp]: getSubTotalLabel(table7, table2.groupByProp), isSubTotal: true });
                                                                                            // }

                                                                                            if (table.values.length - 1 === table2Index) {
                                                                                                updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                                // if (table.count > 1 && table.values.length > 1) {
                                                                                                // childArray = childArray.map((d) => {
                                                                                                //     Object.keys(d).forEach((key) => {
                                                                                                //         if (![`${table.groupByProp}RowSpan`, `${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`, `${table6.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table7);
                                                                                                //         }
                                                                                                //     })
                                                                                                //     return d;
                                                                                                // });
                                                                                                childArray.push({ uuid: Math.random(), ...table, [`${table.groupByProp}RowSpan`]: 1, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [`${table6.groupByProp}RowSpan`]: 1, [table.groupByProp]: getSubTotalLabel(table7, table.groupByProp), isSubTotal: true });
                                                                                                // }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                            }
                                                                        }
                                                                    }
                                                                })
                                                            } else {

                                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0) {
                                                                    childArray.push({ uuid: Math.random(), ...table6, ...table5RowSpan });
                                                                } else {
                                                                    let obj = { ...table5RowSpanZero };
                                                                    // if (table5Index === 0 && table6Index === 0) {
                                                                    //     obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                    //     obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                    // }

                                                                    if (table3Index === 0 && table4Index === 0 && table5Index === 0 && table6Index === 0) {
                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                                    } else if (table4Index === 0 && table5Index === 0 && table6Index === 0) {
                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                    } else if (table5Index === 0 && table6Index === 0) {
                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                        obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                    } else if (table6Index === 0) {
                                                                        obj[`${table5.groupByProp}RowSpan`] = table5.count;
                                                                    }


                                                                    childArray.push({ uuid: Math.random(), ...table6, ...obj });

                                                                }
                                                                // total values logic
                                                                if (table5.values.length - 1 === table6Index) {
                                                                    // if (table5.count > 1 && table5.values.length > 1) {
                                                                    updateTableRowSpan(childArray, table6, table5, row.burstReportHeaders, parentArray.length);
                                                                    // childArray = childArray.map((d) => {
                                                                    //     Object.keys(d).forEach((key) => {
                                                                    //         if (key !== `${table5.groupByProp}RowSpan` && key.includes("RowSpan") && d[key] > 0) {
                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table6);
                                                                    //         }
                                                                    //     })
                                                                    //     return d;
                                                                    // });
                                                                    childArray.push({ uuid: Math.random(), ...table5, [`${table5.groupByProp}RowSpan`]: 1, [table5.groupByProp]: getSubTotalLabel(table6, table5.groupByProp), isSubTotal: true });
                                                                    // }

                                                                    if (table4.values.length - 1 === table5Index) {
                                                                        // if (table4.count > 1 && table4.values.length > 1) {
                                                                        updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                                        // childArray = childArray.map((d) => {
                                                                        //     Object.keys(d).forEach((key) => {
                                                                        //         if (![`${table5.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table6);
                                                                        //         }
                                                                        //     })
                                                                        //     return d;
                                                                        // });
                                                                        childArray.push({ uuid: Math.random(), ...table4, [`${table5.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [table4.groupByProp]: getSubTotalLabel(table6, table4.groupByProp), isSubTotal: true });
                                                                        // }

                                                                        if (table3.values.length - 1 === table4Index) {
                                                                            // if (table3.count > 1 && table3.values.length > 1) {
                                                                            updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                            // childArray = childArray.map((d) => {
                                                                            //     Object.keys(d).forEach((key) => {
                                                                            //         if (![`${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table6);
                                                                            //         }
                                                                            //     })
                                                                            //     return d;
                                                                            // });
                                                                            childArray.push({ uuid: Math.random(), ...table3, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [table3.groupByProp]: getSubTotalLabel(table6, table3.groupByProp), isSubTotal: true });
                                                                            // }

                                                                            if (table2.values.length - 1 === table3Index) {
                                                                                // if (table2.count > 1 && table2.values.length > 1) {
                                                                                updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                                // childArray = childArray.map((d) => {
                                                                                //     Object.keys(d).forEach((key) => {
                                                                                //         if (![`${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table6);
                                                                                //         }
                                                                                //     })
                                                                                //     return d;
                                                                                // });
                                                                                childArray.push({ uuid: Math.random(), ...table2, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [table2.groupByProp]: getSubTotalLabel(table6, table2.groupByProp), isSubTotal: true });
                                                                                // }

                                                                                if (table.values.length - 1 === table2Index) {
                                                                                    // if (table.count > 1 && table.values.length > 1) {
                                                                                    updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                                    // childArray = childArray.map((d) => {
                                                                                    //     Object.keys(d).forEach((key) => {
                                                                                    //         if (![`${table.groupByProp}RowSpan`, `${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`, `${table5.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table6);
                                                                                    //         }
                                                                                    //     })
                                                                                    //     return d;
                                                                                    // });
                                                                                    childArray.push({ uuid: Math.random(), ...table, [`${table.groupByProp}RowSpan`]: 1, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [`${table5.groupByProp}RowSpan`]: 1, [table.groupByProp]: getSubTotalLabel(table6, table.groupByProp), isSubTotal: true });
                                                                                    // }

                                                                                }
                                                                            }
                                                                        }

                                                                    }
                                                                }
                                                            }
                                                        });

                                                    } else {
                                                        if (table2Index === 0 && table3Index === 0 && table4Index === 0 && table5Index === 0) {
                                                            childArray.push({ uuid: Math.random(), ...table5, ...table4RowSpan });
                                                        } else {
                                                            let obj = { ...table4RowSpanZero };
                                                            if (table3Index === 0 && table4Index === 0 && table5Index === 0) {
                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                            } else if (table4Index === 0 && table5Index === 0) {
                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                                obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                            } else if (table5Index === 0) {
                                                                obj[`${table4.groupByProp}RowSpan`] = table4.count;
                                                            }
                                                            childArray.push({ uuid: Math.random(), ...table5, ...obj });

                                                        }
                                                        // total values logic
                                                        if (table4.values.length - 1 === table5Index) {
                                                            // if (table4.count > 1 && table4.values.length > 1) {
                                                            updateTableRowSpan(childArray, table5, table4, row.burstReportHeaders, parentArray.length);
                                                            // childArray = childArray.map((d) => {
                                                            //     Object.keys(d).forEach((key) => {
                                                            //         if (key !== `${table4.groupByProp}RowSpan` && key.includes("RowSpan") && d[key] > 0) {
                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table5);
                                                            //         }
                                                            //     })
                                                            //     return d;
                                                            // });
                                                            childArray.push({ uuid: Math.random(), ...table4, [`${table4.groupByProp}RowSpan`]: 1, [table4.groupByProp]: getSubTotalLabel(table5, table4.groupByProp), isSubTotal: true });
                                                            // }

                                                            if (table3.values.length - 1 === table4Index) {
                                                                // if (table3.count > 1 && table3.values.length > 1) {
                                                                updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                                // childArray = childArray.map((d) => {
                                                                //     Object.keys(d).forEach((key) => {
                                                                //         if (![`${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table5);
                                                                //         }
                                                                //     })
                                                                //     return d;
                                                                // });
                                                                childArray.push({ uuid: Math.random(), ...table3, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [table3.groupByProp]: getSubTotalLabel(table5, table3.groupByProp), isSubTotal: true });
                                                                // }

                                                                if (table2.values.length - 1 === table3Index) {
                                                                    // if (table2.count > 1 && table2.values.length > 1) {
                                                                    updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                                    // childArray = childArray.map((d) => {
                                                                    //     Object.keys(d).forEach((key) => {
                                                                    //         if (![`${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table5);
                                                                    //         }
                                                                    //     })
                                                                    //     return d;
                                                                    // });
                                                                    childArray.push({ uuid: Math.random(), ...table2, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [table2.groupByProp]: getSubTotalLabel(table5, table2.groupByProp), isSubTotal: true });
                                                                    // }

                                                                    if (table.values.length - 1 === table2Index) {
                                                                        // if (table.count > 1 && table.values.length > 1) {
                                                                        updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                                        // childArray = childArray.map((d) => {
                                                                        //     Object.keys(d).forEach((key) => {
                                                                        //         if (![`${table.groupByProp}RowSpan`, `${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`, `${table4.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table5);
                                                                        //         }
                                                                        //     })
                                                                        //     return d;
                                                                        // });
                                                                        childArray.push({ uuid: Math.random(), ...table, [`${table.groupByProp}RowSpan`]: 1, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [`${table4.groupByProp}RowSpan`]: 1, [table.groupByProp]: getSubTotalLabel(table5, table.groupByProp), isSubTotal: true });
                                                                        // }

                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }
                                                });
                                            } else {
                                                if (table2Index === 0 && table3Index === 0 && table4Index === 0) {
                                                    childArray.push({ uuid: Math.random(), ...table4, ...table3RowSpan });
                                                } else {
                                                    let obj = { ...table3RowSpanZero };
                                                    // if (table3Index === 0 && table4Index === 0) {
                                                    //     obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                    //     obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                    // }

                                                    if (table3Index === 0 && table4Index === 0) {
                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                        obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                                    } else if (table4Index === 0) {
                                                        obj[`${table3.groupByProp}RowSpan`] = table3.count;
                                                    }

                                                    childArray.push({ uuid: Math.random(), ...table4, ...obj });

                                                }
                                                // total values logic
                                                if (table3.values.length - 1 === table4Index) {
                                                    // if (table3.count > 1 && table3.values.length > 1) {
                                                    updateTableRowSpan(childArray, table4, table3, row.burstReportHeaders, parentArray.length);
                                                    // childArray = childArray.map((d) => {
                                                    //     Object.keys(d).forEach((key) => {
                                                    //         if (![`${table3.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table4);
                                                    //         }
                                                    //     })
                                                    //     return d;
                                                    // });
                                                    childArray.push({ uuid: Math.random(), ...table3, [`${table3.groupByProp}RowSpan`]: 1, [table3.groupByProp]: getSubTotalLabel(table4, table3.groupByProp), isSubTotal: true });
                                                    // }

                                                    if (table2.values.length - 1 === table3Index) {
                                                        // if (table2.count > 1 && table2.values.length > 1) {
                                                        updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                                        // childArray = childArray.map((d) => {
                                                        //     Object.keys(d).forEach((key) => {
                                                        //         if (![`${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                        //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table4);
                                                        //         }
                                                        //     })
                                                        //     return d;
                                                        // });
                                                        childArray.push({ uuid: Math.random(), ...table2, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [table2.groupByProp]: getSubTotalLabel(table4, table2.groupByProp), isSubTotal: true });
                                                        // }

                                                        if (table.values.length - 1 === table2Index) {
                                                            // if (table.count > 1 && table.values.length > 1) {
                                                            updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                            // childArray = childArray.map((d) => {
                                                            //     Object.keys(d).forEach((key) => {
                                                            //         if (![`${table.groupByProp}RowSpan`, `${table2.groupByProp}RowSpan`, `${table3.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table4);
                                                            //         }
                                                            //     })
                                                            //     return d;
                                                            // });
                                                            childArray.push({ uuid: Math.random(), ...table, [`${table.groupByProp}RowSpan`]: 1, [`${table2.groupByProp}RowSpan`]: 1, [`${table3.groupByProp}RowSpan`]: 1, [table.groupByProp]: getSubTotalLabel(table4, table.groupByProp), isSubTotal: true });
                                                            // }

                                                        }
                                                    }
                                                }
                                            }
                                        });
                                    } else {
                                        if (table2Index === 0 && table3Index === 0) {
                                            childArray.push({ uuid: Math.random(), ...table3, ...table2RowSpan });
                                        } else {
                                            let obj = { ...table2RowSpanZero };
                                            if (table3Index === 0) {
                                                obj[`${table2.groupByProp}RowSpan`] = table2.count;
                                            }
                                            childArray.push({ uuid: Math.random(), ...table3, ...obj });

                                        }
                                        // total values logic
                                        if (table2.values.length - 1 === table3Index) {
                                            // if (table2.count > 1 && table2.values.length > 1) {
                                            updateTableRowSpan(childArray, table3, table2, row.burstReportHeaders, parentArray.length);
                                            // childArray = childArray.map((d) => {
                                            //     Object.keys(d).forEach((key) => {
                                            //         if (![`${table2.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                            //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table3);
                                            //         }
                                            //     })
                                            //     return d;
                                            // });
                                            childArray.push({ uuid: Math.random(), ...table2, [`${table2.groupByProp}RowSpan`]: 1, [table2.groupByProp]: getSubTotalLabel(table3, table2.groupByProp), isSubTotal: true });
                                            // }

                                            if (table.values.length - 1 === table2Index) {
                                                // if (table.count > 1 && table.values.length > 1) {
                                                updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                                // childArray = childArray.map((d) => {
                                                //     Object.keys(d).forEach((key) => {
                                                //         if (![`${table.groupByProp}RowSpan`, `${table2.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                                //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table3);
                                                //         }
                                                //     })
                                                //     return d;
                                                // });
                                                childArray.push({ uuid: Math.random(), ...table, [`${table.groupByProp}RowSpan`]: 1, [`${table2.groupByProp}RowSpan`]: 1, [table.groupByProp]: getSubTotalLabel(table3, table.groupByProp), isSubTotal: true });
                                                // }

                                            }
                                        }
                                    }
                                });
                            } else {
                                if (table2Index === 0) {
                                    childArray.push({ uuid: Math.random(), ...table2, ...tableRowSpan });
                                } else {
                                    childArray.push({ uuid: Math.random(), ...table2, ...tableRowSpanZero });

                                }
                                // total values logic
                                if (table.values.length - 1 === table2Index) {
                                    // if (table.count > 1 && table.values.length > 1) {
                                    updateTableRowSpan(childArray, table2, table, row.burstReportHeaders, parentArray.length);
                                    // childArray = childArray.map((d) => {
                                    //     Object.keys(d).forEach((key) => {
                                    //         if (![`${table.groupByProp}RowSpan`].includes(key) && key.includes("RowSpan") && d[key] > 0) {
                                    //             d = increaseRowSpanForSubTotalRow(key, selectedHeaders, d, table2);
                                    //         }
                                    //     })
                                    //     return d;
                                    // });
                                    childArray.push({ uuid: Math.random(), ...table, [`${table.groupByProp}RowSpan`]: 1, [table.groupByProp]: getSubTotalLabel(table2, table.groupByProp), isSubTotal: true });
                                    // }

                                }
                            }
                        });
                    } else {
                        row.burstReportHeaders.forEach((header) => {
                            table = { ...table, [`${header.headerProperty}RowSpan`]: 1 };
                        })
                        childArray.push(table);
                    }
                    childParentArray = [...childParentArray, ...childArray]
                });
                let mergedColumnNames = row.burstReportHeaders.map(x => x.headerProperty);
                let childArrayDic:any = {}
                childParentArray.forEach((ele, index) => {
                    let key = "";
                    if (index === 0) {
                        mergedColumnNames.forEach(mergedColumnName => {
                            key += ele[mergedColumnName]
                            ele[mergedColumnName + "RowSpan_merge_table"] = ele[mergedColumnName + "RowSpan"] + (subTotalMergeColumns.current[parentArray.length + 1] && subTotalMergeColumns.current[parentArray.length + 1][key] ? subTotalMergeColumns.current[parentArray.length + 1][key] : 0)
                        });
                    } else {
                        mergedColumnNames.forEach(mergedColumnName => {
                            key += ele[mergedColumnName];
                            if (ele[mergedColumnName + "RowSpan"] > 0) {
                                ele[mergedColumnName + "RowSpan_merge_table"] = ele[mergedColumnName + "RowSpan"] + (subTotalMergeColumns.current[parentArray.length + 1] && subTotalMergeColumns.current[parentArray.length + 1][key] ? subTotalMergeColumns.current[parentArray.length + 1][key] : 0);
                            } else {
                                ele[mergedColumnName + "RowSpan_merge_table"] = childParentArray[index - 1][mergedColumnName + "RowSpan_merge_table"] - 1;
                            }
                        })
                    }
                    childArrayDic[ele.id]={
                        index,
                        ele
                    }
                });
                if (hideDetails) {
                    let childSubTotalParentArray = childParentArray.filter(x => x.isSubTotal);
                    console.log(childSubTotalParentArray);
                    childSubTotalParentArray.forEach(ele=>{
                        let groupByIndex = mergedColumnNames.indexOf(ele.groupByProp);
                        let currentObj = childArrayDic[ele.id]
                        let previousObj = childParentArray[currentObj.index -1]
                        mergedColumnNames.forEach((mergedColumnName,mergeColumnIndex) => {
                            if(mergeColumnIndex<groupByIndex){
                                ele[mergedColumnName] = previousObj[mergedColumnName];
                            }
                            ele[mergedColumnName+"RowSpan"]=undefined;
                            ele[mergedColumnName+"RowSpan_merge_table"]=undefined;
                        });
                    });
                    let obj={}
                    childSubTotalParentArray.forEach((ele,index)=>{
                        let groupByIndex = mergedColumnNames.indexOf(ele.groupByProp);
                        let key="";
                        mergedColumnNames.forEach((mergedColumnName,mergeColumnIndex) => {
                            if(mergeColumnIndex<=groupByIndex){
                                key += ele[mergedColumnName];
                                // if(Object.keys(subTotalMergeColumns.current).length===0){
                                //     ele[mergedColumnName+"RowSpan"] = 1;
                                //     ele[mergedColumnName+"RowSpan_merge_table"] = 1;
                                // }else{
                                    if(!obj[key] && subTotalMergeColumns.current[parentArray.length + 1] && subTotalMergeColumns.current[parentArray.length + 1][key]){
                                        obj[key]=true
                                        ele[mergedColumnName+"RowSpan"]=subTotalMergeColumns.current[parentArray.length + 1][key];
                                        ele[mergedColumnName+"RowSpan_merge_table"]=subTotalMergeColumns.current[parentArray.length + 1][key];
                                    }else{
                                        if(index>0){
                                            ele[mergedColumnName+"RowSpan_merge_table"]=(childSubTotalParentArray[index-1][mergedColumnName+"RowSpan_merge_table"]-1)
                                        }
                                    }
                                // }
                            }else{
                                ele[mergedColumnName+"RowSpan"]=1;
                                ele[mergedColumnName+"RowSpan_merge_table"]=1;
                            }
                        });
                        if(ele[ele.groupByProp]){
                            ele[ele.groupByProp+"RowSpan"]=1;
                            ele[ele.groupByProp+"RowSpan_merge_table"]=1;
                        }
                    });
                    console.log(childSubTotalParentArray);
                    childParentArray = childSubTotalParentArray;

                }
                const key = getLastRowPerPageDictKey(row);
                parentArray.push({ ...row, uuid: Math.random(), data: childParentArray, from: 0, size: lastRowPerPageDict[key] ? lastRowPerPageDict[key] : 10 });
            }
            console.log("CHILD", childParentArray);
        });
        console.log("sub total", subTotalMergeColumns);
        setFormattedData(parentArray);
        console.log("PARENT", parentArray);
    }

    const increaseRowSpanForSubTotalRow = (key: string, selectedHeaders: string[], row: any, table: IRportGroupBy) => {
        const propsName = key.replace("RowSpan", "");
        let findIndex = selectedHeaders.findIndex((d) => d === propsName);
        if (findIndex !== -1) {
            let shouldAddOneToRowspan = true;
            while (shouldAddOneToRowspan && findIndex >= 0) {
                const selectedHeaderName = selectedHeaders[findIndex];
                if (row[selectedHeaderName] !== table[selectedHeaderName]) shouldAddOneToRowspan = false;
                findIndex -= 1;
            }

            if (shouldAddOneToRowspan) row[key] += 1;
        }

        return row;
    }



    const updateTableRowSpan = (childArray: any[], tableData, tableParentData, headers: IReportHeader[], parentArrayIndex: number) => {
        let canAddRowSpan = true;
        let lastAddedRow = childArray[childArray.length - 1];
        // console.log("groupByProp", tableParentData.groupByProp);

        let rowSpanColumnKey = '';
        headers.forEach((header, headerIndex) => {
            if (tableParentData.groupByProp === header.headerProperty) {
                canAddRowSpan = false;
            }
            // console.log("lastAddedRow[header.headerProperty]", lastAddedRow[header.headerProperty])
            let ele = lastAddedRow[header.headerProperty];


            if (!lastAddedRow[header.headerProperty] && lastAddedRow[tableParentData.groupByProp] && lastAddedRow[tableParentData.groupByProp].includes("Sub Totals")) {
                let notSubTotalIndex = childArray.findLastIndex(x => !x.isSubTotal);
                ele = childArray[notSubTotalIndex][header.headerProperty];
            }
            if (!lastAddedRow[header.headerProperty] && lastAddedRow[lastAddedRow.groupByProp] && lastAddedRow[lastAddedRow.groupByProp].includes("Sub Totals")) {
                let notSubTotalIndex = childArray.findLastIndex(x => !x.isSubTotal);
                ele = childArray[notSubTotalIndex][header.headerProperty];
            }
            if (canAddRowSpan && (ele)) {
                rowSpanColumnKey += ele;
                if (!subTotalMergeColumns.current[parentArrayIndex + 1]) {
                    subTotalMergeColumns.current[parentArrayIndex + 1] = {}
                }
                if (!subTotalMergeColumns.current[parentArrayIndex + 1][rowSpanColumnKey]) {
                    subTotalMergeColumns.current[parentArrayIndex + 1][rowSpanColumnKey] = 0
                }
                subTotalMergeColumns.current[parentArrayIndex + 1][rowSpanColumnKey] += 1
            }
        });
        // console.log("groupByProp", JSON.stringify(subTotalMergeColumns.current, null, 4))
    }

    const getLastRowPerPageDictKey = (reportData: any) => {
        let key = "";
        if (selectedBurstReports.includes('Do Not Burst')) {
            key = 'Do Not Burst';
        } else {
            ['Service Date', ...selectedBurstReports].filter((d) => reportData[BURST_REPORTS_MAP_WITH_VALUE_FOR_IMPORTED_TIME_ENTRIES_REPORT[d]]).map((d) => {
                switch (d) {
                    case "Firms":
                        key += key ? `#${reportData.firm}` : reportData.firm;
                        break;
                    case "Resource":
                        key += key ? `#${reportData.resource}` : reportData.resource;
                        break;
                    case "Time Category":
                        key += key ? `#${reportData.timeCategory}` : reportData.timeCategory;
                        break;
                    case "Professional Level":
                        key += key ? `#${reportData.professionalLevelName}` : reportData.professionalLevelName;
                        break;
                    case "Firm Long Name":
                        key += key ? `#${reportData.firm}` : reportData.firm;
                        break;
                    case "Import Source":
                        key += key ? `#${reportData.importSource}` : reportData.importSource;
                        break;
                    case "Service Date":
                        key += key ? `#${moment(reportData.serviceDate).format("MM/DD/YYYY")}` : moment(reportData.serviceDate).format("MM/DD/YYYY");
                        break;
                    default:
                        break;
                }
            });
        }
        return key;
    };

    const TablePaginationActions = (props: TablePaginationActionsProps) => {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className="d-flex">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <ChevronDoubleLeft />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ChevronRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <ChevronDoubleRight />
                </IconButton>
            </div>
        );
    };


    return (
        <Grid container spacing={2}>
            {formattedData.length > 0 ? (
                <>
                    {formattedData.map((reportData, reportDataIndex) => (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container spacing={2}>
                                        {['Service Date', ...selectedBurstReports].filter((d) => d !== 'Do Not Burst' && reportData[BURST_REPORTS_MAP_WITH_VALUE_FOR_IMPORTED_TIME_ENTRIES_REPORT[d]]).map((d, index) => {
                                            let label = "", value = "";
                                            switch (d) {
                                                case "Firms":
                                                    label = "Firm:"
                                                    value = reportData.firm;
                                                    break;
                                                case "Firm Long Name":
                                                    label = "Firm Long Name:"
                                                    value = reportData.firm;
                                                    break;
                                                case "Resource":
                                                    label = "Resource:"
                                                    value = reportData.resource;
                                                    break;
                                                case "Time Category":
                                                    label = "Time Category:"
                                                    value = reportData.timeCategory;
                                                    break;
                                                case "Professional Level":
                                                    label = "Professional Level:"
                                                    value = reportData.professionalLevelName;
                                                    break;
                                                case "Service Date":
                                                    label = "Service Date:"
                                                    value = moment(reportData.serviceDate).format("MM/DD/YYYY");
                                                    break;
                                                case "Import Source":
                                                    label = "Import Source:"
                                                    value = reportData.importSource;
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return (
                                                <Grid item key={index}>
                                                    <Grid container alignItems='center'>
                                                        <Grid item>
                                                            <Typography variant='subtitle2' style={{ marginRight: 4 }}>{`${label} `}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant='body2'>{value}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                <React.Fragment>
                                    {reportData.data && reportData.data.length > 0 ? (
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TableContainer className="moz-table-pb">
                                                <Table size="small" className="custom-table report-table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {reportData.burstReportHeaders.map(header => (
                                                                <>
                                                                    {(selectedColumns.includes(header.headerName)) && <StyledTableCell className={`cell-${header.headerProperty}`} key={`burst_header_${header.headerProperty}`} align="left">{header.headerName}</StyledTableCell>}
                                                                </>
                                                            ))}
                                                            {selectedHeaderProps.map((metrics, index) => (
                                                                <StyledTableCell align={metrics === "description" ? "left" : 'right'} className={`cell-${metrics}`} key={`header_metrics_${index}`}>{IMPORTED_TIME_ENTRIES_REPORT_METRICS_DICT[metrics]}</StyledTableCell>
                                                            ))}
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        {reportData.data.slice(reportData.from, reportData.from + reportData.size).map((reportTable, reportTableIndex) => (
                                                            <StyledTableRow key={`report_${reportTable.id}}`} className={reportTable.isSubTotal && 'time-detail-table-row'}>
                                                                {reportData.burstReportHeaders.map(header => (
                                                                    <>
                                                                        {((reportTable[`${header.headerProperty}RowSpan`] > 0 || (reportTableIndex === 0 && reportTable[`${header.headerProperty}RowSpan_merge_table`] > 0)) && selectedColumns.includes(header.headerName)) && (
                                                                            <StyledTableCell className={`cell-${header.headerProperty}`} rowSpan={reportTable[header.headerProperty] && reportTable[header.headerProperty].includes("Sub Totals") ? 1 : (
                                                                                reportTable[`${header.headerProperty}RowSpan`] > 0 ?
                                                                                    (reportTable[`${header.headerProperty}RowSpan_merge_table`])
                                                                                    : (reportTableIndex === 0 && reportTable[`${header.headerProperty}RowSpan_merge_table`] > 0) ? (reportTable[`${header.headerProperty}RowSpan_merge_table`]) : 1
                                                                            )} align='left'>
                                                                                {header.headerProperty === "serviceDate" ? isNaN(Date.parse(reportTable.serviceDate)) ? reportTable.serviceDate : moment(reportTable.serviceDate).format("MM/DD/YYYY") : reportTable[header.headerProperty]}
                                                                            </StyledTableCell>
                                                                        )}
                                                                        {/* {(reportTable[`${header.headerProperty}RowSpan`] > 0 && (selectedMetrics.includes('all') || selectedMetrics.includes(header.headerName))) && <StyledTableCell rowSpan={reportTable[`${header.headerProperty}RowSpan`]}>{reportTable[header.headerProperty]}</StyledTableCell>} */}
                                                                    </>
                                                                ))}

                                                                {selectedHeaderProps.map((metrics) => (
                                                                    <StyledTableCell className={`cell-${metrics}`} align={metrics === "description" ? "left" : 'right'}>
                                                                        {['rate', 'amount'].includes(metrics) ?
                                                                        <React.Fragment>

                                                                            {metrics==="rate" &&  reportTable.isSubTotal  ?"":currencyFormat(convertToFixed(reportTable[IMPORTED_TIME_ENTRIES_METRICS_MAP_WITH_VALUE[metrics]]))}
                                                                        </React.Fragment>
                                                                            : ['hours'].includes(metrics) ?
                                                                                hoursFormat(reportTable[IMPORTED_TIME_ENTRIES_METRICS_MAP_WITH_VALUE[metrics]])
                                                                                : reportTable[IMPORTED_TIME_ENTRIES_METRICS_MAP_WITH_VALUE[metrics]]}
                                                                    </StyledTableCell>
                                                                ))}
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={reportRowsPerPage.current}
                                                component="div"
                                                count={reportData.data.length}
                                                rowsPerPage={reportData.size}
                                                page={
                                                    reportData.from === 0
                                                        ? 0
                                                        : reportData.from / reportData.size
                                                }
                                                SelectProps={{
                                                    inputProps: { "aria-label": "rows per page" },
                                                    native: true,
                                                }}
                                                onPageChange={(event: any, newPage: number) => {
                                                    console.log("event", event);
                                                    console.log("newPage", newPage);
                                                    let formattedDataList = [...formattedData]
                                                    let reportSearchData = { ...formattedDataList[reportDataIndex] };
                                                    reportSearchData.from =
                                                        newPage === 0
                                                            ? 0
                                                            : (newPage * reportSearchData.size)
                                                    formattedDataList[reportDataIndex] = reportSearchData
                                                    setFormattedData(formattedDataList);
                                                }}
                                                onRowsPerPageChange={(event: any) => {
                                                    let value = event.target.value;
                                                    let formattedDataList = [...formattedData]
                                                    let reportSearchData = { ...formattedDataList[reportDataIndex] };
                                                    let lastRowPerPageDic = { ...lastRowPerPageDict };
                                                    reportSearchData.size = value ? Number(value) : 500;
                                                    const key = getLastRowPerPageDictKey(reportData);
                                                    lastRowPerPageDic[key] = reportSearchData.size;
                                                    reportSearchData.from = 0;
                                                    formattedDataList[reportDataIndex] = reportSearchData
                                                    setFormattedData(formattedDataList);
                                                    setLastRowPerPageDict(lastRowPerPageDic);
                                                }}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item lg={12}>
                                            <p className="text-center">No Data found</p>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    ))
                    }
                </>
            ) : (
                <Grid item lg={12}>
                    <p className="text-center">No Data found</p>
                </Grid>
            )}
        </Grid >
    );
}

export default ImportedTimeEntriesReport;