import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Pencil } from "mdi-material-ui";
import * as React from "react";
import {
  currencyFormat,
  numberFormat,
  StyledTableCell,
  StyledTableRow,
} from "../../services/UtilService";
import { ITimeEntry } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import moment from "moment";
import {
  editTimeSheet,
  getTimesheetAttentionDetails,
} from "../../services/TimesheetService";
import { useHistory } from "react-router-dom";
import { ToastContext } from "../common/ToastProvider";
import ManageTimeEntryDialog from "./ManageTimeEntryDialog";
import Loading from "../common/Loading";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

export interface RuleFailedDialogProps {
  timeEntryData?: ITimeEntry;
  handleDialogClose: Function;
  updateStatus: Function;
}

const RuleFailedDialog: React.FC<RuleFailedDialogProps> = ({
  timeEntryData,
  handleDialogClose,
  updateStatus
}) => {
  const [state, setState] = React.useState({
    manageTimeEntryDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
  } as {
    manageTimeEntryDialog: {
      isOpen: boolean;
      data?: ITimeEntry;
      index?: number;
    };
  });

  const [attentionList, setAttentionList] = React.useState([] as ITimeEntry[]);
  const [isLoading, setLoading] = React.useState(false);
  const [isDetailsUpdated, setDetailsUpdated] = React.useState(false);
  const history = useHistory();
  const { showToast } = React.useContext(ToastContext);
  const { isReadOnly } = React.useContext(IsReadOnlyContext);

  React.useEffect(() => {
    const fetch = async () => {
      await getAttentionDetails();
    };
    fetch();
  }, []);

  const getAttentionDetails = async () => {
    setLoading(true);
    let data = {
      timeEntryId: timeEntryData?.timeCommonBenefitId || "",
      serviceDate: timeEntryData?.serviceDate || "",
      lawFirmCode: timeEntryData?.firmCode || "",
    };
    let result = await getTimesheetAttentionDetails(data);
    if (result?.isSuccess) {
      setAttentionList(result?.data || []);
    } else {
      showToast(
        result.message || "Error while fetching attention details",
        "error"
      );
    }
    setLoading(false);
  };

  const closeAddDialog = () => {
    handleDialogClose(isDetailsUpdated);
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = attentionList[editIndex];
    }
    setState({
      ...state,
      manageTimeEntryDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };

  const handleAddDialogClose = async (data?: ITimeEntry) => {
    setLoading(true);
    if (data) {
      if (state.manageTimeEntryDialog.index !== undefined) {
        if (data.isEdit) {
          let result = await editTimeSheet(data);
          if (result?.isSuccess) {
            if (result?.isReadOnly) {
              showToast(result.message, "error");
              updateStatus(result.isReadOnly);
            } else {
              // timeEntriesList[state.manageTimeEntryDialog.index] = result?.data;
              showToast("Entry updated successfully", "success");
              setDetailsUpdated(true);
              await getAttentionDetails();
            }
          }
        } else {
          showToast("Entry not updated as no value changed", "success");
        }
      }
    }
    setState({
      ...state,
      manageTimeEntryDialog: {
        isOpen: false,
        data: undefined,
        index: undefined,
      },
    });
    setLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDrawer
        title={"Review Attention Time Summary"}
        fullWidth={true}
        class='review-attention-dialog'
        onClose={() => closeAddDialog()}
      >
        <section className="p-16">
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Typography variant="body1">
                <Typography variant="h6" component="span">
                  Business Rule :{" "}
                </Typography>
                {state.rule.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <Typography variant="h6" component="span">
                  Status :{" "}
                </Typography>
                {state.rule.status}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <br />
              <TableContainer className="moz-table-pb review-attention-table-container">
                <Table size="small" className="custom-table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Attention Name</StyledTableCell>
                      <StyledTableCell>Attention Description</StyledTableCell>
                      <StyledTableCell>Resource Name</StyledTableCell>
                      <StyledTableCell>Service Date</StyledTableCell>
                      <StyledTableCell>Professional Level</StyledTableCell>
                      {/* <StyledTableCell>Category Code</StyledTableCell> */}
                      <StyledTableCell>Category Name</StyledTableCell>
                      <StyledTableCell>
                        Detailed Description of Work performed
                      </StyledTableCell>
                      <StyledTableCell align="center">Class Work</StyledTableCell>
                      <StyledTableCell>Approved by</StyledTableCell>
                      <StyledTableCell align="center">Time</StyledTableCell>
                      <StyledTableCell align="center">
                        Billing rate
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Fees Total
                      </StyledTableCell>
                      <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attentionList.length > 0 ? (
                      attentionList.map((ele, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {ele?.attentionName || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele?.attentionDescription || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele?.resourceName || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele.serviceDate
                              ? moment(ele.serviceDate).format("MM/DD/YYYY")
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele?.professionalLevel || ""}
                          </StyledTableCell>
                          {/* <StyledTableCell>{ele.categoryCode}</StyledTableCell> */}
                          <StyledTableCell>
                            {ele?.categoryName || ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ele.detailedDescription}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {ele.classActionWorkIndicator}
                          </StyledTableCell>
                          <StyledTableCell>{ele.approvedBy}</StyledTableCell>
                          <StyledTableCell align="center">
                            {numberFormat(ele?.timeSpent || 0)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {currencyFormat(ele?.billingRate || 0)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {currencyFormat(ele?.feesTotal || 0)}
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              size="small"
                              onClick={() => handleAddDialogOpen(true, index)}
                              disabled={isReadOnly}
                            >
                              <Pencil />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={11}>
                          No Entries found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} justify="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => closeAddDialog()}
                  >
                    Close
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onUpdate()}
                  >
                    Update
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </section>
      </CustomDrawer>
      {state.manageTimeEntryDialog.isOpen && (
        <ManageTimeEntryDialog
          data={state.manageTimeEntryDialog.data}
          handleDialogClose={(data?: ITimeEntry) => handleAddDialogClose(data)}
        />
      )}
    </React.Fragment>
  );
};

export default RuleFailedDialog;
