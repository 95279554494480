import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import { IFilter, ILog, ITimesheets } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import moment from "moment";
import Filter from "../common/Filter";

export interface ViewToSubmitToCourtDialogProps {
  data?: any;
  type?: string;
  handleDialogClose: Function;
}

const ViewToSubmitToCourtDialog: React.FC<ViewToSubmitToCourtDialogProps> = ({
  data,
  type,
  handleDialogClose,
}) => {
  const [state, setState] = React.useState({
    isLoading: false,
    isAllSelected: false,
    items: [
      {
        employee: "Caden Rogers",
        firm: "",
        id: 1,
        type: "Common Benefit",
        status: "Approved",
        period: new Date(),
        isSelected: false,
      },
      {
        employee: "John Doe",
        firm: "",
        id: 2,
        type: "Class Work",
        status: "Approved",
        period: new Date(),
        isSelected: false,
      },
      {
        employee: "Skylar Rogers",
        firm: "",
        id: 3,
        type: "Common Benefit",
        status: "Approved",
        period: new Date(),
        isSelected: false,
      },
      {
        employee: "Angel Edwards ",
        firm: "",
        id: 4,
        type: "Class Work",
        status: "Approved",
        period: new Date(),
        isSelected: false,
      },
      {
        employee: "Kris Hale",
        firm: "",
        id: 5,
        type: "Common Benefit",
        status: "Approved",
        period: new Date(),
        isSelected: false,
      },
    ],
    firm: "Boucher",
    period: new Date(),
    filterData: [
      {
        header: "Status",
        items: [
          { count: 40, label: "Approved", type: "checkbox", isSelected: false },
          {
            count: 0,
            label: "Submitted",
            type: "checkbox",
            isSelected: false,
          },
        ],
      },
      {
        header: "Type",
        items: [
          {
            count: 99,
            label: "Common Benefit",
            type: "checkbox",
            isSelected: false,
          },
          {
            count: 46,
            label: "Class Work",
            type: "checkbox",
            isSelected: false,
          },
        ],
      },
      {
        header: "",
        items: [{ value: undefined, type: "dropdown", label: "Employees" }],
      },
    ],
    employeeOptions: [
      { value: "Patrica Oliver", label: "Patrica Oliver" },
      { value: "John Doe", label: "John Doe" },
      { value: "John Silver", label: "John Silver" },
    ],
  } as {
    items: any[];
    isAllSelected: boolean;
    isLoading: boolean;
    firm: string;
    period: Date;
    employeeOptions: { label: string; value: string }[];
    filterData: IFilter[];
  });

  const closeAddDialog = (data?: any) => {
    handleDialogClose(data);
  };

  const selectAll = () => {
    let items = [...state.items];
    items.map((ele) => {
      if (state.isAllSelected === false) {
        ele.isSelected = true;
      } else {
        ele.isSelected = false;
      }
      return ele;
    });
    setState({
      ...state,
      items: items,
      isAllSelected: !state.isAllSelected,
    });
  };

  const handleSelectChange = (index: number) => {
    let items = state.items;
    let timesheet = items[index];
    timesheet.isSelected = !timesheet.isSelected;
    items[index] = timesheet;
    let isAllSelected = true;
    if (timesheet.isSelected === false) {
      isAllSelected = false;
    } else {
      items.forEach((ele) => {
        if (ele.isSelected === false) {
          isAllSelected = false;
        }
      });
    }
    setState({ ...state, items, isAllSelected });
  };

  const onFilterChange = (data: IFilter[]) => {
    console.log("data", data);
  };

  return (
    <React.Fragment>
      <CustomDrawer
        title={`Select the ${type}s to submit`}
        fullWidth={true}
        onClose={() => closeAddDialog()}
      >
        <section className="p-16">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3} justify="center">
                <Grid item>
                  <Typography>
                    <Typography variant="h6" component="span">
                      Firm :{" "}
                    </Typography>
                    {state.firm}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <Typography variant="h6" component="span">
                      Time Period :{" "}
                    </Typography>
                    {moment(state.period).format("MMMM YYYY")}
                  </Typography>
                </Grid>
              </Grid>

              <br />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} lg={2}>
                  <Hidden only="xs">
                    <Filter
                      data={state.filterData}
                      options={state.employeeOptions || []}
                      onChange={(data: IFilter[]) => {
                        onFilterChange(data);
                      }}
                    />
                  </Hidden>
                </Grid>
                <Grid item xs={12} sm={9} lg={10}>
                  <br />
                  <TableContainer className="moz-table-pb">
                    <Table size="small" className="custom-table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            <Checkbox
                              size="small"
                              checked={state.isAllSelected}
                              value={state.isAllSelected}
                              onClick={() => {
                                selectAll();
                              }}
                            ></Checkbox>
                          </StyledTableCell>
                          <StyledTableCell>Employee</StyledTableCell>
                          <StyledTableCell>Period</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                          {/* <StyledTableCell>Type</StyledTableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.items.length > 0 ? (
                          state.items.map((ele, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                <Checkbox
                                  size="small"
                                  checked={ele.isSelected}
                                  onClick={() => {
                                    handleSelectChange(index);
                                  }}
                                ></Checkbox>
                              </StyledTableCell>
                              <StyledTableCell>{ele.employee}</StyledTableCell>
                              <StyledTableCell>
                                {moment(ele.period).format("MM/DD/YYYY")}
                              </StyledTableCell>
                              <StyledTableCell>{ele.status}</StyledTableCell>
                              {/* <StyledTableCell>{ele.type}</StyledTableCell> */}
                            </StyledTableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              No {type}s found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <br />
              <br />
              <Grid container spacing={2} justify="flex-end">
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="text" onClick={() => closeAddDialog()}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      closeAddDialog({
                        type: type,
                        firm: "Boucher",
                        period: new Date(),
                        case: "South California Gas Leak",
                      })
                    }
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ViewToSubmitToCourtDialog;
